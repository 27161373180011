import React from 'react';
import {useDispatch} from 'react-redux';
import TextComponent from '../../../textComponent/TextComponent';

import useStyles from '../menuComponentStyles';
import firebase from 'firebase';
import {clearContactList} from 'rb-redux/actions/contactAction';
import LocalText from '../../../common/localText';
const {logOut} = require('rb-redux/actions/authAction');

const FooterLogoutComponent = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLogOut = async (e) => {
    e.preventDefault();
    dispatch(clearContactList());
    await firebase.auth().signOut();
    dispatch(logOut());
  };

  return (
    <div className={classes.footerContainer} onClick={handleLogOut}>
      <p className={classes.logout}>
        <LocalText>Logout</LocalText>
      </p>
    </div>
  );
};

export default FooterLogoutComponent;
