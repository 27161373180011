const IntegrationIcon = ({color}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 5.25V2.25H10.5V5.25H7.5V2.25H6V5.25C5.25 5.25 4.5 6 4.5 6.75V10.875L7.125 13.5V15.75H10.875V13.5L13.5 10.875V6.75C13.5 6 12.75 5.25 12 5.25ZM12 10.2525L9.8175 12.4425L9.5025 12.75H8.4975L8.19 12.4425L6 10.2525V6.8175C6 6.795 6.045 6.75 6.0675 6.75H11.94C11.9625 6.75 12 6.795 12 6.8175V10.2525Z"
        fill={color ? color : '#495A74'}
      />
    </svg>
  );
};
export default IntegrationIcon;
