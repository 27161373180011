import FirestoreDB from '../FirestoreDB';
import {BatchWriter} from '../BatchWriter';
import {
  firestoreServerTimestamp,
  getNewRowPropertiesObject,
} from '../../utils/utils';
import {isNil, omit, isNumber, isEmpty} from 'lodash';
import {captureError, captureInfo} from '../../imports';
import {TABLE_LIMITS} from '../../utils/constant';
import {backOff} from 'exponential-backoff';
import {getRowObjStructured} from '../../actions/actionHelpers/tableActionHelper';
import {
  solvePrevRowRefEqnForTable,
  mapHeaderData,
} from '../../utils/equationHelper';
import {
  checkAndConvertDocData,
  getLastFetchedTimestampData,
} from './DocumentMethodHelper';

export default class DocumentsMethods {
  static async getRowsData(data) {
    const {
      docId,
      lastFetchedTimestamp = null,
      lastFetchedIndex = null,
      isDescOrder = false,
      isLimited = true,
      limit = TABLE_LIMITS.INITIAL_FETCH_LIMIT,
      getVersionData = false,
      versionId = null,
      isForViewEntry = false,
      userIdForQuery = null,
      customSortingKey = null,
    } = data;
    let {
      lastFetchedDocRef = null, //rowId or docSnap
    } = data;
    try {
      const defaultSortingKey = 'index';
      const isCustomSorted =
        typeof customSortingKey === 'string' &&
        customSortingKey !== defaultSortingKey;
      const sortingKey = isCustomSorted ? customSortingKey : defaultSortingKey;
      const isBasedOnLastFetchedTimestamp = lastFetchedTimestamp > 0;
      let query = getVersionData
        ? FirestoreDB.documents.versionRowsCollectionRef(docId, versionId)
        : FirestoreDB.documents.rowsCollectionRef(docId);
      if (isBasedOnLastFetchedTimestamp) {
        query = query
          .orderBy('lastModifiedTimestamp', 'desc')
          .where('lastModifiedTimestamp', '>', lastFetchedTimestamp);
      } else if (lastFetchedDocRef && typeof lastFetchedDocRef === 'string') {
        //if rowId equivalent
        lastFetchedDocRef = await FirestoreDB.documents
          .rowsCollectionRef(docId)
          .doc(lastFetchedDocRef)
          .get();
      } else if (!isCustomSorted && isNumber(lastFetchedIndex)) {
        query = query.where(
          sortingKey,
          isDescOrder ? '<' : '>',
          lastFetchedIndex,
        );
      }
      if (!isBasedOnLastFetchedTimestamp) {
        query = query.where('isDeleted', '==', false);
      }
      if (isForViewEntry && userIdForQuery) {
        query = query.where(`rowProperties.createdByUID`, '==', userIdForQuery);
      }
      if (isLimited && isNumber(limit)) {
        query = query.limit(limit);
      }
      query = query.orderBy(sortingKey, isDescOrder ? 'desc' : 'asc');
      if (lastFetchedDocRef != null) {
        query = query.startAfter(lastFetchedDocRef);
      }
      const config = {
        startingDelay: 0,
        maxDelay: 10,
        numOfAttempts: 3,
      };
      return backOff(() => query.get(), config).catch((e) => {
        captureError(e);
        return null;
      });
    } catch (error) {
      captureInfo({data});
      captureError(error);
      return Promise.resolve(null);
    }
  }

  static async getAllRowsData(
    docId,
    lastFetchedIndex = null,
    doNotProcessDocs = false,
    getVersionData = false,
    versionId = null,
  ) {
    //recursive approach
    try {
      const tableData = [];
      const limit = TABLE_LIMITS.ROWS_BATCH_FETCH_SIZE;
      const getNextRows = (lastFetchedDocRef) =>
        this.getRowsData({
          docId,
          isLimited: true,
          limit,
          lastFetchedDocRef,
          lastFetchedIndex,
          getVersionData,
          versionId,
        }).then((res) => {
          lastFetchedIndex = null; //after first fetch, we don't need to use this
          res.docs.forEach((doc) => {
            const docData = doc.data();
            const rowId = doc.id;
            tableData.push(
              doNotProcessDocs
                ? doc
                : getRowObjStructured(
                    docData.rowObj,
                    rowId,
                    docData.index,
                    docData.rowProperties,
                    docData.sortKey,
                  ),
            );
          });
          if (res.docs.length === limit) {
            return getNextRows(res.docs[res.docs.length - 1]);
          }
          return tableData;
        });
      return getNextRows(null);
    } catch (error) {
      captureInfo({
        docId,
        lastFetchedIndex,
        doNotProcessDocs,
        getVersionData,
        versionId,
      });
      captureError(error);
      return [];
    }
  }

  static async getUserDocumentData(docId, fetchAllRows = true) {
    try {
      const [data, tableData] = await Promise.all([
        this.getUserDocumentDataWithoutRows(docId),
        fetchAllRows ? this.getAllRowsData(docId) : [],
      ]);
      const {headerData, footerData, fileObj, noOfRows} = data;
      const {prevRowRefEqnArr} = mapHeaderData(headerData);
      return {
        tableData: prevRowRefEqnArr.length
          ? solvePrevRowRefEqnForTable(tableData, prevRowRefEqnArr)
          : tableData,
        headerData,
        footerData,
        fileObj,
        noOfRows,
      };
    } catch (error) {
      captureInfo({docId});
      captureError(error);
      return null;
    }
  }

  static getAutoIncrementVals(docId, colId) {
    return FirestoreDB.documents
      .autoIncrementValsRef(docId)
      .get()
      .then((res) => (res.exists ? res.data()?.[colId]?.val || 0 : 0))
      .catch((error) => {
        captureError(error);
        return 0;
      });
  }

  static async getUserDocumentDataWithoutRows(docId) {
    try {
      const tableMainData = await backOff(
        () => FirestoreDB.documents.documentRef(docId).get(),
        {numOfAttempts: 3},
      );
      const data = tableMainData.exists
        ? Object.assign({}, tableMainData.data())
        : {};
      const headerData = Object.values(Object.assign({}, data.headerData));
      return {
        headerData,
        footerData: Object.assign({}, data.footerData),
        fileObj: Object.assign({}, data.fileObj),
        noOfRows: data.noOfRows,
      };
    } catch (error) {
      captureInfo({docId});
      captureError(error);
      return null;
    }
  }

  static async getUserDocumentsWithQuery(...args) {
    try {
      return await FirestoreDB.documents
        .queriedUserDocumentsCollectionRef(...args)
        .get();
    } catch (error) {
      captureError(error);
      return null;
    }
  }

  /**
   * all mutliple new rows
   */
  static addMultipleRows(
    activeDocId,
    rowObjArr,
    documentDataToUpdate = null,
    isDocDataSet = false,
    doNotCommit = false, //if using this method/function in combination with some other firestore call
    doNotIncrementRowsCount = false, //if rows are actually replaced instead of adding
  ) {
    try {
      const timestamp = firestoreServerTimestamp();
      //update row data
      rowObjArr.forEach((updatedRowObj) => {
        BatchWriter.set(
          FirestoreDB.documents.rowsRef(activeDocId, updatedRowObj.rowId),
          {
            rowObj: omit(updatedRowObj, TABLE_LIMITS.REQUIRED_ROW_KEYS),
            index: updatedRowObj.index,
            isDeleted: false,
            rowProperties: !isEmpty(updatedRowObj?.rowProperties)
              ? Object.assign({}, updatedRowObj?.rowProperties)
              : getNewRowPropertiesObject({}),
            lastModifiedTimestamp: timestamp,
            sortKey: updatedRowObj?.sortKey,
          },
        );
      });
      //update document data
      const DocDataMethod = (...args) =>
        isDocDataSet ? BatchWriter.set(...args) : BatchWriter.update(...args);
      DocDataMethod(
        FirestoreDB.documents.documentRef(activeDocId),
        checkAndConvertDocData(
          Object.assign(
            {},
            documentDataToUpdate,
            getLastFetchedTimestampData(timestamp, isDocDataSet),
            !doNotIncrementRowsCount
              ? {noOfRows: FirestoreDB.FieldValue().increment(rowObjArr.length)}
              : null,
          ),
        ),
      );

      return doNotCommit ? Promise.resolve() : BatchWriter.commit();
    } catch (error) {
      captureError(error);
    }
  }

  static updateMultipleRows(
    activeDocId,
    updatedRowObjArr,
    documentDataToUpdate = null,
    isSet = false,
    doNotCommit = false, //if using this method/function in combination with some other firestore call
    isOnlyRowSet = false,
  ) {
    try {
      let isErrored = false;
      const timestamp = firestoreServerTimestamp();
      //update row data
      updatedRowObjArr.forEach((updatedRowObj, interateeIndex) => {
        if (
          updatedRowObj?.rowId && isSet
            ? TABLE_LIMITS.MANDATORY_ROWS_KEYS.every(
                (key) => !isNil(updatedRowObj?.[key]),
              )
            : true
        ) {
          const rowUpdateParams = [
            FirestoreDB.documents.rowsRef(activeDocId, updatedRowObj.rowId),
            Object.assign(
              {},
              {
                rowObj: omit(updatedRowObj, TABLE_LIMITS.REQUIRED_ROW_KEYS),
                rowProperties: updatedRowObj?.rowProperties
                  ? Object.assign({}, updatedRowObj?.rowProperties)
                  : getNewRowPropertiesObject({}),
                lastModifiedTimestamp: timestamp,
              },
              isSet && {
                index: updatedRowObj.index,
                isDeleted: Boolean(updatedRowObj.isDeleted),
              },
            ),
          ];
          isSet
            ? BatchWriter.set(...rowUpdateParams)
            : BatchWriter.update(...rowUpdateParams);
        } else {
          isErrored = true;
          captureInfo(updatedRowObj);
        }
        if (isErrored && interateeIndex === updatedRowObjArr.length - 1) {
          captureError('Error in updateMultipleRows');
        }
      });
      //update document data
      const isDocDataSet = isSet && !isOnlyRowSet;
      const docUpdateParams = [
        FirestoreDB.documents.documentRef(activeDocId),
        checkAndConvertDocData(
          Object.assign(
            {},
            documentDataToUpdate,
            getLastFetchedTimestampData(timestamp, isDocDataSet),
          ),
        ),
      ];
      isDocDataSet
        ? BatchWriter.set(...docUpdateParams)
        : BatchWriter.update(...docUpdateParams);
      return doNotCommit ? Promise.resolve() : BatchWriter.commit();
    } catch (error) {
      captureError(error);
    }
  }

  static setNewPageData(docId, docData) {
    try {
      const tableData = docData.tableData; //array of rows
      const headerData = Object.assign({}, docData.headerData);
      const footerData = Object.assign({}, docData.footerData);
      const fileObj = Object.assign({}, docData.fileObj);
      return this.addMultipleRows(
        docId,
        tableData,
        {headerData, footerData, fileObj},
        true,
      );
    } catch (error) {
      captureError(error);
    }
  }

  static deleteOrRestoreMultipleRows(
    activeDocId,
    rowIdArr,
    documentDataToUpdate = null,
    doNotCommit = false, //if using this method/function in combination with some other firestore call
    isRestore = false, //is undeleting rows(redo delete)
  ) {
    try {
      const timestamp = firestoreServerTimestamp();
      //update row data
      rowIdArr.forEach((rowId) => {
        BatchWriter.update(FirestoreDB.documents.rowsRef(activeDocId, rowId), {
          isDeleted: Boolean(!isRestore),
          lastModifiedTimestamp: timestamp,
        });
      });
      //update document data
      BatchWriter.update(
        FirestoreDB.documents.documentRef(activeDocId),
        checkAndConvertDocData(
          Object.assign(
            {},
            documentDataToUpdate,
            getLastFetchedTimestampData(timestamp, false),
            {
              noOfRows: FirestoreDB.FieldValue().increment(
                rowIdArr.length * (isRestore ? 1 : -1),
              ),
            },
          ),
        ),
      );
      return doNotCommit ? Promise.resolve() : BatchWriter.commit();
    } catch (error) {
      captureError(error);
    }
  }

  static updateHeaderDataAtIndex(
    activeDocId,
    index,
    updatedHeaderObj,
    documentDataToUpdate = null,
  ) {
    try {
      //update document data
      const updateObj = {
        [`headerData.${index}`]: updatedHeaderObj,
      };

      return FirestoreDB.documents
        .documentRef(activeDocId)
        .update(
          Object.assign(
            {},
            updateObj,
            checkAndConvertDocData(documentDataToUpdate),
          ),
        );
    } catch (error) {
      captureError(error);
    }
  }

  static replaceHeaderData(
    activeDocId,
    headerData,
    documentDataToUpdate = null,
  ) {
    try {
      //update document data
      return FirestoreDB.documents
        .documentRef(activeDocId)
        .update(
          checkAndConvertDocData(
            Object.assign({}, {headerData}, documentDataToUpdate),
          ),
        );
    } catch (error) {
      captureError(error);
    }
  }

  static replaceFooterData(
    activeDocId,
    footerData,
    documentDataToUpdate = null,
  ) {
    try {
      //update document data
      return FirestoreDB.documents
        .documentRef(activeDocId)
        .update(
          checkAndConvertDocData(
            Object.assign({}, {footerData}, documentDataToUpdate),
          ),
        );
    } catch (error) {
      captureError(error);
    }
  }

  static addComment = (docId, rowId, commentObj) => {
    try {
      return FirestoreDB.documents
        .commentsCollectionRef(docId, rowId)
        .add(Object.assign({}, commentObj));
    } catch (err) {
      captureError(err);
    }
  };

  static editComment = (docId, rowId, commentId, commentObj) => {
    try {
      return FirestoreDB.documents
        .commentsCollectionRef(docId, rowId)
        .doc(commentId)
        .update(Object.assign({}, commentObj));
    } catch (err) {
      captureError(err);
    }
  };

  static deleteComment = (docId, rowId, commentId) => {
    try {
      return FirestoreDB.documents
        .commentsCollectionRef(docId, rowId)
        .doc(commentId)
        .delete();
    } catch (err) {
      captureError(err);
    }
  };

  static updateFooterPropertiesData = (
    docId,
    splitByUpdateObj,
    isUpdate = true,
  ) => {
    try {
      const docRef = FirestoreDB.documents.footerPropertiesRef(docId);
      if (isUpdate) {
        return docRef.update(splitByUpdateObj);
      } else {
        return docRef.set({
          splitByCalculation: splitByUpdateObj,
        });
      }
    } catch (err) {
      captureError(err);
    }
  };

  static getUniqueColumnDataForDoc = async (documentId) => {
    try {
      const uniqueColumnData = {};
      const response = await FirestoreDB.documents
        .uniqueColCollectionRef(documentId)
        .get();

      response?.docs.forEach((doc) => {
        const id = doc.id;
        const docData = doc.data();
        if (!docData.isDeleted) {
          Object.assign(uniqueColumnData, {[id]: docData.data});
        }
      });
      return uniqueColumnData;
    } catch (error) {
      captureError(error);
    }
    return null;
  };

  static setUniqueValuesDataForColumn = (
    documentId,
    columnId,
    uniqueValObj,
    isMerge = false,
  ) => {
    try {
      FirestoreDB.documents.uniqueColCollectionDocRef(documentId, columnId).set(
        {
          data: uniqueValObj,
          isDeleted: false,
        },
        isMerge ? {merge: true} : undefined,
      );
    } catch (error) {
      captureError(error);
    }
  };

  static updateUniqueValuesDataForColumn = (
    documentId,
    columnId,
    updateObj,
  ) => {
    try {
      FirestoreDB.documents
        .uniqueColCollectionDocRef(documentId, columnId)
        .update(updateObj);
    } catch (error) {
      captureError(error);
    }
  };

  static deleteUniqueValuesDataForColumn = (documentId, columnId) => {
    try {
      FirestoreDB.documents.uniqueColCollectionDocRef(documentId, columnId).set(
        {
          isDeleted: true,
        },
        {merge: true},
      );
    } catch (error) {
      captureError(error);
    }
  };
}
