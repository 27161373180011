import {nanoid} from 'nanoid';

export const generateRowId = (suffix) =>
  `R_${nanoid(10)}${typeof suffix === 'string' ? `_${suffix}` : ''}`;

export const generateRandomId = (size = 10, prefix, suffix) =>
  `${typeof prefix === 'string' ? `${prefix}_` : ''}${nanoid(size)}${
    typeof suffix === 'string' ? `_${suffix}` : ''
  }`;

export const generateDocId = (prefix, suffix, length = 14) =>
  `${typeof prefix === 'string' ? `${prefix}_` : ''}${nanoid(
    length > 0 ? parseInt(length) : 14,
  )}${typeof suffix === 'string' ? `_${suffix}` : ''}`;
