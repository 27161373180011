// import Agriculture from '../assets/img/businessDomains/leaf-outline.png';
import Recreation from '../assets/img/businessDomains/color-palette-outline.png';
import ConsumerProducts from '../assets/img/businessDomains/bag-handle-outline.png';
import Construction from '../assets/img/businessDomains/build-outline.png';
import Education from '../assets/img/businessDomains/school-outline.png';
import Finance from '../assets/img/businessDomains/cash-outline.png';
import Health from '../assets/img/businessDomains/medical-outline.png';
import Hotels from '../assets/img/businessDomains/bed-outline.png';
// import ICT from '../assets/img/businessDomains/layers-outline.png';
import Manufacturing from '../assets/img/businessDomains/hardware-chip-outline.png';
// import Mining from '../assets/img/businessDomains/diamond-outline.png';
import Other from '../assets/img/businessDomains/shapes-outline.png';
import ProfessionalServices from '../assets/img/businessDomains/briefcase-outline.png';
import PublicAdmin from '../assets/img/businessDomains/globe-outline.png';
// import RealEstate from '../assets/img/businessDomains/home-outline.png';
import Restaurants from '../assets/img/businessDomains/fast-food-outline.png';
// import Wholesale from '../assets/img/businessDomains/pricetags-outline.png';
import Transportation from '../assets/img/businessDomains/cube-outline.png';
import Software from '../assets/img/businessDomains/desktop-outline.png';

import Accounts from '../assets/img/businessUseCases/cash-outline.png';
// import Workflows from '../assets/img/businessUseCases/reader-outline.png';
import Crm from '../assets/img/businessUseCases/people-outline.png';
import Operations from '../assets/img/businessUseCases/pie-chart-outline.png';
import Sales from '../assets/img/businessUseCases/trending-up-outline.png';
import OtherUseCase from '../assets/img/businessUseCases/shapes-outline.png';
import {orderBy} from 'lodash';

export const MINI_APPS_BUSINESS_CATEGORY_IDS = {
  // AGRICULTURE: 'agriculture',
  ADVERTISING_MARKETING: 'advertising_marketing',
  // RECREATION: 'recreation',
  CONSTRUCTION: 'construction',
  D2C: 'D2C',
  // CONSUMER_PRODUCTS: 'consumer_product',
  EDUCATION: 'education',
  ENTERTAINMENT_MEDIA: 'entertainment_media',
  FINANCE_INSURANCE: 'finance_insurance',
  HEALTHCARE: 'healthcare',
  HOTELS: 'hotels',
  // ICT: 'ict',
  INFORMATION_TECHNOLOGY: 'information_technology',
  MANUFACTURING: 'manufacturing',
  // MINING: 'mining',
  OTHER: 'other_catg',
  PROFESSIONAL_SERVICES: 'professional_services',
  // PUBLIC_ADMINISTRATION: 'public_administration',
  NON_PROFIT_ORG: 'nonprofit_org',
  // REAL_ESTATE: 'real_estate',
  RESTAURANT: 'restaurant',
  // SOFTWARE: 'software',
  TECHNOLOGY_STARTUP: 'technology_startup',
  TRANSPORTATION: 'transportation',
  // WHOLESALE: 'wholesale',
};

export const MINI_APPS_BUSINESS_CATEGORY_DETAIL = {
  // [MINI_APPS_BUSINESS_CATEGORY_IDS.AGRICULTURE]: {
  //   display: true,
  //   icon: {
  //     image: Agriculture,
  //     iconName: 'leaf',
  //     iconGroup: 'MaterialCommunityIcons',
  //     color: 'rgba(68, 159, 53, 1)',
  //     background: 'rgba(51, 126, 237, 0.1)',
  //   },
  //   name: {
  //     EN: 'Agriculture & Forestry',
  //     HN: 'कृषि एवं वानिकी',
  //     MA: 'कृषी आणि वनीकरण',
  //     BE: 'কৃষি ও বনায়ন',
  //     TE: 'వ్యవసాయం & అటవీ',
  //     TA: 'விவசாயம் & வனவியல்',
  //     GU: 'કૃષિ અને વનસંવર્ધન',
  //     KN: 'ಕೃಷಿ ಮತ್ತು ಅರಣ್ಯ',
  //     ML: 'കൃഷി & വനം',
  //     UR: 'زراعت اور جنگلات',
  //     ID: 'Pertanian & Kehutanan',
  //     PT: 'Agricultura e Silvicultura',
  //     VN: 'Nông lâm nghiệp',
  //   },
  //   desc: {EN: ''},
  //   priority: 1,
  //   minVersion: '',
  //   id: MINI_APPS_BUSINESS_CATEGORY_IDS.AGRICULTURE,
  // },
  [MINI_APPS_BUSINESS_CATEGORY_IDS.ADVERTISING_MARKETING]: {
    display: true,
    icon: {
      image: OtherUseCase,
      iconName: 'shapes-outline',
      iconGroup: 'Ionicons',
      color: '#556A89',
    },
    name: {
      EN: 'Advertising and Marketing',
      HN: 'विज्ञापन और विपणन',
      MA: 'जाहिरात आणि विपणन',
      BE: 'বিজ্ঞাপন ও বিপনন',
      TE: 'ప్రకటనలు మరియు మార్కెటింగ్',
      TA: 'விளம்பரம் மற்றும் சந்தைப்படுத்தல்',
      GU: 'જાહેરાત અને માર્કેટિંગ',
      KN: 'ಜಾಹೀರಾತು ಮತ್ತು ಮಾರ್ಕೆಟಿಂಗ್',
      ML: 'പരസ്യവും മാർക്കറ്റിംഗും',
      UR: 'ایڈورٹائزنگ اور مارکیٹنگ',
      ID: 'Periklanan dan Pemasaran',
      PT: 'Publicidade e Marketing',
      VN: 'Quảng cáo và tiếp thị',
    },
    desc: {EN: ''},
    priority: 1,
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.ADVERTISING_MARKETING,
  },
  // [MINI_APPS_BUSINESS_CATEGORY_IDS.RECREATION]: {
  //   display: true,
  //   icon: {
  //     image: Recreation,
  //     iconName: 'palette-outline',
  //     iconGroup: 'MaterialCommunityIcons',
  //     color: 'rgba(121, 27, 238, 1)',
  //     background: 'rgba(51, 126, 237, 0.1)',
  //   },
  //   name: {
  //     EN: 'Art, Entertainment & Recreation',
  //     HN: 'कला, मनोरंजन और मनोरंजन',
  //     MA: 'कला, मनोरंजन आणि मनोरंजन',
  //     BE: 'শিল্প, বিনোদন এবং বিনোদন',
  //     TE: 'కళ, వినోదం & వినోదం',
  //     TA: 'கலை, பொழுதுபோக்கு & பொழுதுபோக்கு',
  //     GU: 'કલા, મનોરંજન અને મનોરંજન',
  //     KN: 'ಕಲೆ, ಮನರಂಜನೆ ಮತ್ತು ಮನರಂಜನೆ',
  //     ML: 'കല, വിനോദം & വിനോദം',
  //     UR: 'فن، تفریح اور تفریح',
  //     ID: 'Seni, Hiburan & Rekreasi',
  //     PT: 'Arte, entretenimento e recreação',
  //     VN: 'Nghệ thuật, Giải trí & Giải trí',
  //   },
  //   priority: 2,
  //   desc: {EN: ''},
  //   minVersion: '',
  //   id: MINI_APPS_BUSINESS_CATEGORY_IDS.RECREATION,
  // },
  [MINI_APPS_BUSINESS_CATEGORY_IDS.CONSTRUCTION]: {
    display: true,
    icon: {
      image: Construction,
      iconName: 'wrench-outline',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(8, 201, 229, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Construction and Real Estate',
      HN: 'निर्माण और रियल एस्टेट',
      MA: 'बांधकाम आणि रिअल इस्टेट',
      BE: 'নির্মাণ এবং রিয়েল এস্টেট',
      TE: 'నిర్మాణం మరియు రియల్ ఎస్టేట్',
      TA: 'கட்டுமானம் மற்றும் ரியல் எஸ்டேட்',
      GU: 'બાંધકામ અને રિયલ એસ્ટેટ',
      KN: 'ನಿರ್ಮಾಣ ಮತ್ತು ರಿಯಲ್ ಎಸ್ಟೇಟ್',
      ML: 'നിർമ്മാണവും റിയൽ എസ്റ്റേറ്റും',
      UR: 'تعمیرات اور رئیل اسٹیٹ',
      ID: 'Konstruksi dan Real Estat',
      PT: 'Construção e Imobiliário',
      VN: 'Xây dựng và Bất động sản',
    },
    desc: {EN: ''},
    priority: 2,
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.CONSTRUCTION,
  },
  [MINI_APPS_BUSINESS_CATEGORY_IDS.D2C]: {
    display: true,
    icon: {
      image: ConsumerProducts,
      iconName: 'basket-outline',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(224, 42, 42, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'D2C',
      HN: 'डी2सी',
      MA: 'D2C',
      BE: 'D2C',
      TE: 'D2C',
      TA: 'D2C',
      GU: 'D2C',
      KN: 'D2C',
      ML: 'D2C',
      UR: 'D2C',
      ID: 'D2C',
      PT: 'D2C',
      VN: 'D2C',
    },
    desc: {EN: ''},
    priority: 3,
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.D2C,
  },
  // [MINI_APPS_BUSINESS_CATEGORY_IDS.WHOLESALE]: {
  //   display: true,
  //   icon: {
  //     image: Wholesale,
  //     iconName: 'warehouse',
  //     iconGroup: 'MaterialCommunityIcons',
  //     color: 'rgba(224, 42, 42, 1)',
  //     background: 'rgba(51, 126, 237, 0.1)',
  //   },
  //   name: {
  //     EN: 'Wholesale & Retail',
  //     HN: 'थोक खुदरा',
  //     MA: 'घाऊक आणि किरकोळ',
  //     BE: 'পাইকারী খুচরা',
  //     TE: 'టోకు & రిటైల్',
  //     TA: 'மொத்த மற்றும் சில்லறை விற்பனை',
  //     GU: 'જથ્થાબંધ અને છૂટક',
  //     KN: 'ಸಗಟು & ಚಿಲ್ಲರೆ',
  //     ML: 'മൊത്തവും ചില്ലറയും',
  //     UR: 'تھوک اور پرچون',
  //     ID: 'Grosir & Eceran',
  //     PT: 'Atacado Varejo',
  //     VN: 'Bán sỉ bán lẻ',
  //   },
  //   desc: {EN: ''},
  //   priority: 19,
  //   minVersion: '',
  //   id: MINI_APPS_BUSINESS_CATEGORY_IDS.WHOLESALE,
  // },
  // [MINI_APPS_BUSINESS_CATEGORY_IDS.CONSUMER_PRODUCTS]: {
  //   display: true,
  //   icon: {
  //     image: ConsumerProducts,
  //     iconName: 'basket-outline',
  //     iconGroup: 'MaterialCommunityIcons',
  //     color: 'rgba(224, 42, 42, 1)',
  //     background: 'rgba(51, 126, 237, 0.1)',
  //   },
  //   name: {
  //     EN: 'Consumer Products',
  //     HN: 'उपभोक्ता उत्पादों',
  //     MA: 'ग्राहक उत्पादने',
  //     BE: 'ভোগ্যপণ্য',
  //     TE: 'వినియోగదారు ఉత్పత్తులు',
  //     TA: 'நுகர்வோர் பொருட்கள்',
  //     GU: 'ગ્રાહક ઉત્પાદનો',
  //     KN: 'ಗ್ರಾಹಕರ ಉತ್ಪನ್ನಗಳು',
  //     ML: 'ഉപഭോക്തൃ ഉൽപ്പന്നങ്ങൾ',
  //     UR: 'صارفی مصنوعات',
  //     ID: 'Produk konsumer',
  //     PT: 'Produtos de consumo',
  //     VN: 'Sản phẩm tiêu dùng',
  //   },
  //   desc: {EN: ''},
  //   priority: 4,
  //   minVersion: '',
  //   id: MINI_APPS_BUSINESS_CATEGORY_IDS.CONSUMER_PRODUCTS,
  // },
  [MINI_APPS_BUSINESS_CATEGORY_IDS.EDUCATION]: {
    display: true,
    icon: {
      image: Education,
      iconName: 'school-outline',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(241, 164, 47, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Education',
      HN: 'शिक्षा',
      MA: 'शिक्षण',
      BE: 'শিক্ষা',
      TE: 'చదువు',
      TA: 'கல்வி',
      GU: 'શિક્ષણ',
      KN: 'ಶಿಕ್ಷಣ',
      ML: 'വിദ്യാഭ്യാസം',
      UR: 'تعلیم',
      ID: 'Pendidikan',
      PT: 'Educação',
      VN: 'Giáo dục',
    },
    desc: {EN: ''},
    priority: 4,
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.EDUCATION,
  },
  [MINI_APPS_BUSINESS_CATEGORY_IDS.ENTERTAINMENT_MEDIA]: {
    display: true,
    icon: {
      image: Recreation,
      iconName: 'palette-outline',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(121, 27, 238, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Entertainment and Media',
      HN: 'मनोरंजन और मीडिया',
      MA: 'मनोरंजन आणि मीडिया',
      BE: 'বিনোদন এবং মিডিয়া',
      TE: 'వినోదం మరియు మీడియా',
      TA: 'பொழுதுபோக்கு மற்றும் ஊடகம்',
      GU: 'મનોરંજન અને મીડિયા',
      KN: 'ಮನರಂಜನೆ ಮತ್ತು ಮಾಧ್ಯಮ',
      ML: 'വിനോദവും മാധ്യമവും',
      UR: 'تفریح اور میڈیا',
      ID: 'Hiburan dan Media',
      PT: 'Entretenimento e mídia',
      VN: 'Giải trí và truyền thông',
    },
    priority: 5,
    desc: {EN: ''},
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.ENTERTAINMENT_MEDIA,
  },

  [MINI_APPS_BUSINESS_CATEGORY_IDS.FINANCE_INSURANCE]: {
    display: true,
    icon: {
      image: Finance,
      iconName: 'cash-multiple',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(85, 106, 137, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Financial Services',
      HN: 'वित्तीय सेवाएं',
      MA: 'आर्थिक सेवा',
      BE: 'অর্থনৈতিক সেবা সমূহ',
      TE: 'ఆర్థిక సేవలు',
      TA: 'நிதி சேவைகள்',
      GU: 'નાણાકીય સેવાઓ',
      KN: 'ಹಣಕಾಸು ಸೇವೆಗಳು',
      ML: 'സാമ്പത്തിക സേവനങ്ങൾ',
      UR: 'مالیاتی خدمات',
      ID: 'Layanan Keuangan',
      PT: 'Serviços financeiros',
      VN: 'Các dịch vụ tài chính',
    },
    desc: {EN: ''},
    priority: 6,
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.FINANCE_INSURANCE,
  },
  [MINI_APPS_BUSINESS_CATEGORY_IDS.HEALTHCARE]: {
    display: true,
    icon: {
      image: Health,
      iconName: 'hospital-box-outline',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(51, 126, 237, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Healthcare and Medical Services',
      HN: 'स्वास्थ्य देखभाल और चिकित्सा सेवाएँ',
      MA: 'आरोग्य सेवा आणि वैद्यकीय सेवा',
      BE: 'স্বাস্থ্যসেবা এবং চিকিৎসা সেবা',
      TE: 'ఆరోగ్య సంరక్షణ మరియు వైద్య సేవలు',
      TA: 'சுகாதார மற்றும் மருத்துவ சேவைகள்',
      GU: 'આરોગ્યસંભાળ અને તબીબી સેવાઓ',
      KN: 'ಆರೋಗ್ಯ ಮತ್ತು ವೈದ್ಯಕೀಯ ಸೇವೆಗಳು',
      ML: 'ആരോഗ്യ സംരക്ഷണവും മെഡിക്കൽ സേവനങ്ങളും',
      UR: 'صحت کی دیکھ بھال اور طبی خدمات',
      ID: 'Pelayanan Kesehatan dan Medis',
      PT: 'Cuidados de saúde e serviços médicos',
      VN: 'Dịch vụ chăm sóc sức khỏe và y tế',
    },
    desc: {EN: ''},
    priority: 7,
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.HEALTHCARE,
  },
  [MINI_APPS_BUSINESS_CATEGORY_IDS.HOTELS]: {
    display: true,
    icon: {
      image: Hotels,
      iconName: 'bed-outline',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(68, 159, 53, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Hospitality and Tourism',
      HN: 'आतिथ्य और पर्यटन',
      MA: 'आदरातिथ्य आणि पर्यटन',
      BE: 'আতিথেয়তা এবং পর্যটন',
      TE: 'హాస్పిటాలిటీ మరియు టూరిజం',
      TA: 'விருந்தோம்பல் மற்றும் சுற்றுலா',
      GU: 'આતિથ્ય અને પ્રવાસન',
      KN: 'ಆತಿಥ್ಯ ಮತ್ತು ಪ್ರವಾಸೋದ್ಯಮ',
      ML: 'ഹോസ്പിറ്റാലിറ്റിയും ടൂറിസവും',
      UR: 'مہمان نوازی اور سیاحت',
      ID: 'Perhotelan dan Pariwisata',
      PT: 'Hospitalidade e Turismo',
      VN: 'Sự mến khách và du lịch',
    },
    desc: {EN: ''},
    priority: 8,
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.HOTELS,
  },
  // [MINI_APPS_BUSINESS_CATEGORY_IDS.ICT]: {
  //   display: true,
  //   icon: {
  //     image: ICT,
  //     iconName: 'layers-triple-outline',
  //     iconGroup: 'MaterialCommunityIcons',
  //     color: 'rgba(121, 27, 238, 1)',
  //     background: 'rgba(51, 126, 237, 0.1)',
  //   },
  //   name: {
  //     EN: 'Information & Communication Technology (ICT)',
  //     HN: 'सूचना एवं संचार प्रौद्योगिकी (आईसीटी)',
  //     MA: 'माहिती आणि संप्रेषण तंत्रज्ञान (ICT)',
  //     BE: 'তথ্য ও যোগাযোগ প্রযুক্তি (আইসিটি)',
  //     TE: 'ఇన్ఫర్మేషన్ & కమ్యూనికేషన్ టెక్నాలజీ (ICT)',
  //     TA: 'தகவல் மற்றும் தொடர்பு தொழில்நுட்பம் (ICT)',
  //     GU: 'માહિતી અને સંચાર ટેકનોલોજી (ICT)',
  //     KN: 'ಮಾಹಿತಿ ಮತ್ತು ಸಂವಹನ ತಂತ್ರಜ್ಞಾನ (ICT)',
  //     ML: 'ഇൻഫർമേഷൻ & കമ്മ്യൂണിക്കേഷൻ ടെക്നോളജി (ICT)',
  //     UR: 'انفارمیشن اینڈ کمیونیکیشن ٹیکنالوجی (ICT)',
  //     ID: 'Teknologi Informasi & Komunikasi (TIK)',
  //     PT: 'Tecnologia da Informação e Comunicação (TIC)',
  //     VN: 'Công nghệ Thông tin & Truyền thông (ICT)',
  //   },
  //   desc: {EN: ''},
  //   priority: 9,
  //   minVersion: '',
  //   id: MINI_APPS_BUSINESS_CATEGORY_IDS.ICT,
  // },
  [MINI_APPS_BUSINESS_CATEGORY_IDS.INFORMATION_TECHNOLOGY]: {
    display: true,
    icon: {
      image: Software,
      iconName: 'cellphone-link',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(121, 27, 238, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Information Technology',
      HN: 'सूचान प्रौद्योगिकी',
      MA: 'माहिती तंत्रज्ञान',
      BE: 'তথ্য প্রযুক্তি',
      TE: 'ఇన్ఫర్మేషన్ టెక్నాలజీ',
      TA: 'தகவல் தொழில்நுட்பம்',
      GU: 'માહિતી ટેકનોલોજી',
      KN: 'ಮಾಹಿತಿ ತಂತ್ರಜ್ಞಾನ',
      ML: 'വിവരസാങ്കേതികവിദ്യ',
      UR: 'انفارمیشن ٹیکنالوجی',
      ID: 'Teknologi Informasi',
      PT: 'Tecnologia da Informação',
      VN: 'công nghệ thông tin',
    },
    desc: {EN: ''},
    priority: 9,
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.INFORMATION_TECHNOLOGY,
  },
  [MINI_APPS_BUSINESS_CATEGORY_IDS.MANUFACTURING]: {
    display: true,
    icon: {
      image: Manufacturing,
      iconName: 'cogs',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(8, 201, 229, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Manufacturing',
      HN: 'उत्पादन',
      MA: 'उत्पादन',
      BE: 'ম্যানুফ্যাকচারিং',
      TE: 'తయారీ',
      TA: 'உற்பத்தி',
      GU: 'ઉત્પાદન',
      KN: 'ತಯಾರಿಕೆ',
      ML: 'നിർമ്മാണം',
      UR: 'مینوفیکچرنگ',
      ID: 'Manufaktur',
      PT: 'Manufatura',
      VN: 'Chế tạo',
    },
    desc: {EN: ''},
    priority: 10,
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.MANUFACTURING,
  },
  // [MINI_APPS_BUSINESS_CATEGORY_IDS.MINING]: {
  //   display: true,
  //   icon: {
  //     image: Mining,
  //     iconName: 'diamond-stone',
  //     iconGroup: 'MaterialCommunityIcons',
  //     color: 'rgba(224, 42, 42, 1)',
  //     background: 'rgba(51, 126, 237, 0.1)',
  //   },
  //   name: {
  //     EN: 'Mining & Energy',
  //     HN: 'खनन एवं ऊर्जा',
  //     MA: 'खाणकाम आणि ऊर्जा',
  //     BE: 'খনি ও শক্তি',
  //     TE: 'మైనింగ్ & ఎనర్జీ',
  //     TA: 'சுரங்கம் & ஆற்றல்',
  //     GU: 'ખાણકામ અને ઊર્જા',
  //     KN: 'ಗಣಿಗಾರಿಕೆ ಮತ್ತು ಶಕ್ತಿ',
  //     ML: 'ഖനനവും ഊർജ്ജവും',
  //     UR: 'کان کنی اور توانائی',
  //     ID: 'Pertambangan & Energi',
  //     PT: 'Mineração e Energia',
  //     VN: 'Khai khoáng & Năng lượng',
  //   },
  //   desc: {EN: ''},
  //   priority: 11,
  //   minVersion: '',
  //   id: MINI_APPS_BUSINESS_CATEGORY_IDS.MINING,
  // },

  [MINI_APPS_BUSINESS_CATEGORY_IDS.PROFESSIONAL_SERVICES]: {
    display: true,
    icon: {
      image: ProfessionalServices,
      iconName: 'briefcase-outline',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(241, 164, 47, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Professional Services',
      HN: 'पेशेवर सेवाएं',
      MA: 'व्यावसायिक सेवा',
      BE: 'পেশাদারী সেবা',
      TE: 'వృత్తిపరమైన సేవలు',
      TA: 'தொழில்முறை சேவைகள்',
      GU: 'વ્યાવસાયિક સેવાઓ',
      KN: 'ವೃತ್ತಿಪರ ಸೇವೆಗಳು',
      ML: 'പ്രൊഫഷണൽ സേവനങ്ങൾ',
      UR: 'پیشہ ورانہ خدمات',
      ID: 'Layanan profesional',
      PT: 'Serviços profissionais',
      VN: 'Những dịch vụ chuyên nghiệp',
    },
    desc: {EN: ''},
    priority: 12,
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.PROFESSIONAL_SERVICES,
  },
  // [MINI_APPS_BUSINESS_CATEGORY_IDS.REAL_ESTATE]: {
  //   display: true,
  //   icon: {
  //     image: RealEstate,
  //     iconName: 'office-building-outline',
  //     iconGroup: 'MaterialCommunityIcons',
  //     color: 'rgba(51, 126, 237, 1)',
  //     background: 'rgba(51, 126, 237, 0.1)',
  //   },
  //   name: {
  //     EN: 'Real Estate',
  //     HN: 'रियल एस्टेट',
  //     MA: 'रिअल इस्टेट',
  //     BE: 'আবাসন',
  //     TE: 'రియల్ ఎస్టేట్',
  //     TA: 'மனை',
  //     GU: 'રિયલ એસ્ટેટ',
  //     KN: 'ರಿಯಲ್ ಎಸ್ಟೇಟ್',
  //     ML: 'റിയൽ എസ്റ്റേറ്റ്',
  //     UR: 'ریل اسٹیٹ کی',
  //     ID: 'Perumahan',
  //     PT: 'Imobiliária',
  //     VN: 'Địa ốc',
  //   },
  //   desc: {EN: ''},
  //   priority: 14,
  //   minVersion: '',
  //   id: MINI_APPS_BUSINESS_CATEGORY_IDS.REAL_ESTATE,
  // },
  [MINI_APPS_BUSINESS_CATEGORY_IDS.NON_PROFIT_ORG]: {
    display: true,
    icon: {
      image: PublicAdmin,
      iconName: 'web',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(85, 106, 137, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Nonprofit Organizations',
      HN: 'गैर - सरकारी संगठन',
      MA: 'नानफा संस्था',
      BE: 'অলাভজনক প্রতিষ্ঠান',
      TE: 'లాభాపేక్ష లేని సంస్థలు',
      TA: 'இலாப நோக்கற்ற நிறுவனங்கள்',
      GU: 'બિનનફાકારક સંસ્થાઓ',
      KN: 'ಲಾಭರಹಿತ ಸಂಸ್ಥೆಗಳು',
      ML: 'ലാഭേച്ഛയില്ലാത്ത ഓർഗനൈസേഷനുകൾ',
      UR: 'غیر منافع بخش تنظیمیں',
      ID: 'Organisasi Nirlaba',
      PT: 'Organizações sem fins lucrativos',
      VN: 'Các tổ chức phi lợi nhuận',
    },
    desc: {EN: ''},
    priority: 11,
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.NON_PROFIT_ORG,
  },
  [MINI_APPS_BUSINESS_CATEGORY_IDS.RESTAURANT]: {
    display: true,
    icon: {
      image: Restaurants,
      iconName: 'food-outline',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(224, 42, 42, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Restaurants and Food Services',
      HN: 'रेस्तरां और खाद्य सेवाएँ',
      MA: 'रेस्टॉरंट्स आणि अन्न सेवा',
      BE: 'রেস্তোরাঁ এবং খাদ্য পরিষেবা',
      TE: 'రెస్టారెంట్లు మరియు ఆహార సేవలు',
      TA: 'உணவகங்கள் மற்றும் உணவு சேவைகள்',
      GU: 'રેસ્ટોરાં અને ખાદ્ય સેવાઓ',
      KN: 'ಉಪಹಾರಗೃಹಗಳು ಮತ್ತು ಆಹಾರ ಸೇವೆಗಳು',
      ML: 'റെസ്റ്റോറന്റുകളും ഭക്ഷണ സേവനങ്ങളും',
      UR: 'ریستوراں اور کھانے کی خدمات',
      ID: 'Restoran dan Layanan Makanan',
      PT: 'Restaurantes e serviços de alimentação',
      VN: 'Nhà hàng và Dịch vụ ăn uống',
    },
    desc: {EN: ''},
    priority: 13,
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.RESTAURANT,
  },
  [MINI_APPS_BUSINESS_CATEGORY_IDS.TRANSPORTATION]: {
    display: true,
    icon: {
      image: Transportation,
      iconName: 'truck-fast-outline',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(8, 201, 229, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Transportation & Logistics',
      HN: 'परिवहन एवं रसद',
      MA: 'वाहतूक आणि लॉजिस्टिक',
      BE: 'পরিবহন এবং লজিস্টিক',
      TE: 'రవాణా & లాజిస్టిక్స్',
      TA: 'போக்குவரத்து & தளவாடங்கள்',
      GU: 'પરિવહન અને લોજિસ્ટિક્સ',
      KN: 'ಸಾರಿಗೆ ಮತ್ತು ಲಾಜಿಸ್ಟಿಕ್ಸ್',
      ML: 'ഗതാഗതവും ലോജിസ്റ്റിക്സും',
      UR: 'نقل و حمل اور لاجسٹکس',
      ID: 'Transportasi & Logistik',
      PT: 'Transporte e Logística',
      VN: 'Vận tải và hậu cần',
    },
    desc: {EN: ''},
    priority: 15,
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.TRANSPORTATION,
  },
  [MINI_APPS_BUSINESS_CATEGORY_IDS.TECHNOLOGY_STARTUP]: {
    display: true,
    icon: {
      image: Software,
      iconName: 'cellphone-link',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(121, 27, 238, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Technology Startups',
      HN: 'प्रौद्योगिकी स्टार्टअप',
      MA: 'तंत्रज्ञान स्टार्टअप',
      BE: 'প্রযুক্তি স্টার্টআপ',
      TE: 'టెక్నాలజీ స్టార్టప్‌లు',
      TA: 'தொழில்நுட்ப தொடக்கங்கள்',
      GU: 'ટેકનોલોજી સ્ટાર્ટઅપ્સ',
      KN: 'ಟೆಕ್ನಾಲಜಿ ಸ್ಟಾರ್ಟ್ಅಪ್ಗಳು',
      ML: 'ടെക്നോളജി സ്റ്റാർട്ടപ്പുകൾ',
      UR: 'ٹیکنالوجی اسٹارٹ اپس',
      ID: 'Startup Teknologi',
      PT: 'Startups de tecnologia',
      VN: 'Khởi nghiệp công nghệ',
    },
    desc: {EN: ''},
    priority: 14,
    minVersion: '',
    id: MINI_APPS_BUSINESS_CATEGORY_IDS.TECHNOLOGY_STARTUP,
  },
};

export const MINI_APPS_USECASE_CATEGORY_IDS = {
  // ACCOUNTS: 'accounts',
  FINANCE: 'finance', //TODO : new ID
  // WORKFLOWS: 'business_workflow',
  // CRM: 'crm',
  TEAM_MANAGEMENT: 'team_management', //TODO : new ID
  OPERATIONS: 'operation',
  SALES: 'sales',
  CUSTOMER_SERVICE: 'customer_service', //TODO : new ID
  //OTHERS: 'other_use_case',
};

export const MINI_APPS_USECASE_CATEGORY_DETAIL = {
  [MINI_APPS_USECASE_CATEGORY_IDS.FINANCE]: {
    display: true,
    icon: {
      image: Accounts,
      iconName: 'cash-outline',
      iconGroup: 'Ionicons',
      color: 'rgba(51, 126, 237, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Finance',
      HN: 'वित्त',
      MA: 'वित्त',
      BE: 'অর্থায়ন',
      TE: 'ఫైనాన్స్',
      TA: 'நிதி',
      GU: 'ફાઇનાન્સ',
      KN: 'ಹಣಕಾಸು',
      ML: 'ധനകാര്യം',
      UR: 'مالیات',
      ID: 'Keuangan',
      PT: 'Finança',
      VN: 'Tài chính',
    },
    priority: 1,
    desc: {EN: ''},
    minVersion: '',
    id: MINI_APPS_USECASE_CATEGORY_IDS.FINANCE,
  },
  // [MINI_APPS_USECASE_CATEGORY_IDS.WORKFLOWS]: {
  //   display: true,
  //   icon: {
  //     image: Workflows,
  //     iconName: 'reader-outline',
  //     iconGroup: 'Ionicons',
  //     color: 'rgba(121, 27, 238, 1)',
  //     background: 'rgba(121, 27, 238, 0.1)',
  //   },
  //   name: {
  //     EN: 'Business Workflows',
  //     HN: 'बिजनेस वर्कफ़्लोज़',
  //     MA: 'व्यवसाय कार्यप्रवाह',
  //     BE: 'ব্যবসায়িক কর্মপ্রবাহ',
  //     TE: 'వ్యాపార వర్క్‌ఫ్లోలు',
  //     TA: 'வணிக பணிப்பாய்வு',
  //     GU: 'બિઝનેસ વર્કફ્લો',
  //     KN: 'ವ್ಯಾಪಾರ ಕೆಲಸದ ಹರಿವುಗಳು',
  //     ML: 'ബിസിനസ് വർക്ക്ഫ്ലോകൾ',
  //     UR: 'کاروباری ورک فلو',
  //     ID: 'Alur Kerja Bisnis',
  //     PT: 'Fluxos de trabalho de negócios',
  //     VN: 'quy trình kinh doanh',
  //   },
  //   priority: 2,
  //   desc: {EN: ''},
  //   minVersion: '',
  //   id: MINI_APPS_USECASE_CATEGORY_IDS.WORKFLOWS,
  // },
  [MINI_APPS_USECASE_CATEGORY_IDS.TEAM_MANAGEMENT]: {
    display: true,
    icon: {
      image: Crm,
      iconName: 'people-outline',
      iconGroup: 'Ionicons',
      color: 'rgba(241, 164, 47, 1)',
      background: 'rgba(241, 164, 47, 0.1)',
    },
    name: {
      EN: 'Team Management ',
      HN: 'टीम प्रबंधन',
      MA: 'संघ व्यवस्थापन',
      BE: 'দল ব্যবস্থাপনা',
      TE: 'జట్టు నిర్వహణ',
      TA: 'குழு மேலாண்மை',
      GU: 'ટીમ મેનેજમેન્ટ',
      KN: 'ತಂಡ ನಿರ್ವಹಣೆ',
      ML: 'ടീം മാനേജ്മെന്റ്',
      UR: 'ٹیم مینجمنٹ',
      ID: 'Manajemen Tim',
      PT: 'Gerenciamento de equipe',
      VN: 'Quản lý nhóm',
    },
    priority: 4,
    desc: {EN: ''},
    minVersion: '',
    id: MINI_APPS_USECASE_CATEGORY_IDS.TEAM_MANAGEMENT,
  },
  [MINI_APPS_USECASE_CATEGORY_IDS.OPERATIONS]: {
    display: true,
    icon: {
      image: Operations,
      iconName: 'pie-chart-outline',
      iconGroup: 'Ionicons',
      color: 'rgba(224, 42, 42, 1)',
      background: 'rgba(224, 42, 42, 0.1)',
    },
    name: {
      EN: 'Operations',
      HN: 'संचालन',
      MA: 'ऑपरेशन्स',
      BE: 'অপারেশন',
      TE: 'కార్యకలాపాలు',
      TA: 'செயல்பாடுகள்',
      GU: 'કામગીરી',
      KN: 'ಕಾರ್ಯಾಚರಣೆ',
      ML: 'പ്രവർത്തനങ്ങൾ',
      UR: 'آپریشنز',
      ID: 'Operasi',
      PT: 'Operações',
      VN: 'Hoạt động',
    },
    priority: 2,
    desc: {EN: ''},
    minVersion: '',
    id: MINI_APPS_USECASE_CATEGORY_IDS.OPERATIONS,
  },
  [MINI_APPS_USECASE_CATEGORY_IDS.SALES]: {
    display: true,
    icon: {
      image: Sales,
      iconName: 'trending-up-outline',
      iconGroup: 'Ionicons',
      color: 'rgba(68, 159, 53, 1)',
      background: 'rgba(68, 159, 53, 0.1)',
    },
    name: {
      EN: 'Sales',
      HN: 'बिक्री',
      MA: 'विक्री',
      BE: 'বিক্রয়',
      TE: 'అమ్మకాలు',
      TA: 'விற்பனை',
      GU: 'વેચાણ',
      KN: 'ಮಾರಾಟ',
      ML: 'വിൽപ്പന',
      UR: 'سیلز',
      ID: 'Penjualan',
      PT: 'Vendas',
      VN: 'Việc bán hàng',
    },
    priority: 3,
    desc: {EN: ''},
    minVersion: '',
    id: MINI_APPS_USECASE_CATEGORY_IDS.SALES,
  },
  [MINI_APPS_USECASE_CATEGORY_IDS.CUSTOMER_SERVICE]: {
    display: true,
    icon: {
      image: Transportation,
      iconName: 'cube-outline',
      iconGroup: 'MaterialCommunityIcons',
      color: 'rgba(8, 201, 229, 1)',
      background: 'rgba(51, 126, 237, 0.1)',
    },
    name: {
      EN: 'Customer Service',
      HN: 'ग्राहक सेवा',
      MA: 'ग्राहक सेवा',
      BE: 'গ্রাহক সেবা',
      TE: 'వినియోగదారుల సేవ',
      TA: 'வாடிக்கையாளர் சேவை',
      GU: 'ગ્રાહક સેવા',
      KN: 'ಗ್ರಾಹಕ ಸೇವೆ',
      ML: 'കസ്റ്റമർ സർവീസ്',
      UR: 'کسٹمر سروس',
      ID: 'Pelayanan pelanggan',
      PT: 'Atendimento ao Cliente',
      VN: 'Dịch vụ khách hàng',
    },
    priority: 5,
    desc: {EN: ''},
    minVersion: '',
    id: MINI_APPS_USECASE_CATEGORY_IDS.CUSTOMER_SERVICE,
  },
};

const OTHER_BUSINESS_CATEGORY = {
  display: true,
  icon: {
    image: Other,
  },
  name: {
    EN: 'Other',
    HN: 'अन्य',
    MA: 'इतर',
    BE: 'অন্যান্য',
    TE: 'ఇతర',
    TA: 'மற்றவை',
    GU: 'અન્ય',
    KN: 'ಇತರೆ',
    ML: 'മറ്റുള്ളവ',
    UR: 'دیگر',
    ID: 'Lainnya',
    PT: 'Outro',
    VN: 'Khác',
  },
  desc: {EN: ''},
  priority: 999,
  minVersion: '',
  id: MINI_APPS_BUSINESS_CATEGORY_IDS.OTHER,
};

const OTHER_USECASE_CATRGORY = {
  display: true,
  icon: {
    image: OtherUseCase,
    iconName: 'shapes-outline',
    iconGroup: 'Ionicons',
    color: '#556A89',
  },
  name: {
    EN: 'Other',
    HN: 'अन्य',
    MA: 'इतर',
    BE: 'অন্যান্য',
    TE: 'ఇతర',
    TA: 'மற்றவை',
    GU: 'અન્ય',
    KN: 'ಇತರೆ',
    ML: 'മറ്റുള്ളവ',
    UR: 'دیگر',
    ID: 'Lainnya',
    PT: 'Outro',
    VN: 'Khác',
  },
  priority: 999,
  desc: {EN: ''},
  minVersion: '',
  id: MINI_APPS_USECASE_CATEGORY_IDS.OTHERS,
};

export const GET_BUSINESS_CATEGORY_DATA_ARR = () => {
  return [
    ...orderBy(Object.values(MINI_APPS_BUSINESS_CATEGORY_DETAIL), ['name.EN']),
    OTHER_BUSINESS_CATEGORY,
  ];
};

export const GET_USECASE_CATEGORY_DATA_ARR = () => {
  return [
    ...orderBy(Object.values(MINI_APPS_USECASE_CATEGORY_DETAIL), ['name.EN']),
    OTHER_USECASE_CATRGORY,
  ];
};
