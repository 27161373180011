import {forOwn} from 'lodash';
import {firebaseAuth, getReduxState} from '../../imports';

let uidCached = null,
  deviceIdCached = '';

export const getLastFetchedTimestampData = (
  lastFetchedTimestamp,
  isSet = false,
) => {
  const [uid, deviceUniqueId] = (() => {
    try {
      const {auth, persistedData} = getReduxState();
      const res = [auth.user.uid, persistedData.uniqueDeviceId];
      [uidCached, deviceIdCached] = res; //set in cache
      return res;
    } catch {}
    return [uidCached ?? firebaseAuth().currentUser.uid, deviceIdCached]; //if failed return from cache
  })();
  const key = 'lastFetchedUserObj';
  return isSet
    ? {
        [key]: {
          [uid]: {
            [deviceUniqueId]: lastFetchedTimestamp,
          },
        },
        lastFetchedTimestamp,
      }
    : {
        [`${key}.${uid}.${deviceUniqueId}`]: lastFetchedTimestamp,
        lastFetchedTimestamp,
      };
};

export const checkAndConvertDocData = (updateObj) => {
  const returnObj = {};
  forOwn(Object.assign({}, updateObj), (value, key) => {
    switch (key) {
      case 'headerData':
      case 'footerData':
      case 'fileObj': {
        //these must be object
        returnObj[key] = Object.assign({}, value);
        break;
      }
      default: {
        returnObj[key] = value;
      }
    }
  });
  return returnObj;
};
