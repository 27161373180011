import {NETINFO_ACTION} from '../actions/actionType';

const initialState = {
  netInfoState: {},
  netInfoCallbacks: {},
};

const netInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case NETINFO_ACTION.UPDATE_NETINFO_STATE: {
      return {
        ...state,
        netInfoState: action.payload,
      };
    }
    case NETINFO_ACTION.UPDATE_NETINFO_CALLBACKS: {
      return {
        ...state,
        netInfoCallbacks: action.payload,
      };
    }
    default:
      return state;
  }
};

export default netInfoReducer;
