import {isEmpty, omit} from 'lodash';
import {
  COLUMN_PROPERTIES,
  COLUMN_PROPERTY_DETAILS,
  COLUMN_PROPERTY_KEYS,
  DATA_FILTERS,
  DATE_FORMATS,
  FIELD_TYPE_ID,
  MINI_APPS,
  MINI_APPS_DATE_RECURRING_TYPES,
  MULTI_VALUE_FILTER_FIELDS,
  RANGE_FILTER_FIELDS,
  SETVALUE_COLUMN_VALUE_TYPES,
  TEXT_VALUE_TYPE_FILTER_FIELDS,
  TIME_BASED_RANGE_FILTER_FIELDS,
} from './constant';
import {
  convertCellDataToText,
  getDateFilterRangeForConditionCheck,
  getAssignTaskFilterFormat,
  getHeaderTypeMapping,
  getOriginalFieldType,
  convertCellToDifferentField,
  getDateTimeCellObj,
} from './utils';
import moment from 'moment';
import {AUTOMATION_COLUMN_VALUE_TYPES} from './automationConstants';
import {getMemberUsingUID} from './organistionUtils';
const {TRIGGER_CONDITIONS, HIDE_VALUE_FIELD_FOR_CONDITIONS} =
  MINI_APPS.AUTOMATION;
export default class ColumnUtility {
  /**
   * Function to check if column is mandatory or not.
   * @param {object} columnObject - Column Object
   * @returns
   */
  static isMandatory(columnObject) {
    const {columnProperties} = columnObject ?? {};
    return Boolean(columnProperties?.[COLUMN_PROPERTY_KEYS.MANDATORY]);
  }

  static getChecksForHeaderData(headerData) {
    headerData = Array.isArray(headerData) ? headerData : [];
    const isCreateInfoColPresent = headerData.some(
      (item) => item.fieldType === FIELD_TYPE_ID.CREATED_INFO,
    );
    const isAssignTaskColPresent = headerData.some(
      (item) => item.fieldType === FIELD_TYPE_ID.ASSIGN_TASK,
    );
    return {
      isCreateInfoColPresent,
      isAssignTaskColPresent,
    };
  }

  static getBooleanSummaryConfig(summaryConfig) {
    if (isEmpty(summaryConfig)) {
      return {};
    }
    const colIds = Object.keys(summaryConfig ?? {});
    const booleanSummaryConfig = {};
    colIds.forEach((colId) => {
      booleanSummaryConfig[colId] = {
        ...(booleanSummaryConfig[colId] ?? {}),
      };
      const opTypes = Object.keys(summaryConfig[colId]);
      opTypes.forEach((opType) => {
        booleanSummaryConfig[colId][opType] = true;
      });
    });
    return booleanSummaryConfig;
  }

  static getFilterIdsForListPrimaryCol(columnObject) {
    const {id} = columnObject ?? {};
    return id ? [id] : [];
  }

  static getAlreadyLinkedListIds(headerData) {
    headerData = headerData || [];
    const alreadyLinkedIds = [];

    headerData.forEach((columnObject) => {
      const {listConfig, fieldType} = columnObject ?? {};
      const {docId} = listConfig ?? {};
      if (!docId || fieldType !== FIELD_TYPE_ID.LIST) return;

      alreadyLinkedIds.push(docId);
    });

    return alreadyLinkedIds;
  }

  static isHiddenMandatory(columnObject, activeDocumentMeta) {
    const {columnProperties} = columnObject ?? {};
    const restrictions = activeDocumentMeta?.collab?.restrictions ?? {};
    return Boolean(
      columnProperties?.[COLUMN_PROPERTY_KEYS.MANDATORY] &&
        (restrictions?.[columnObject?.id]?.isHidden ||
          restrictions?.[columnObject?.id]?.isReadOnly),
    );
  }

  static extractRequiredColumnProperties(columnProperties, fieldType, subType) {
    if (!COLUMN_PROPERTY_DETAILS[fieldType]) {
      return {};
    }
    const fieldkeys = COLUMN_PROPERTY_DETAILS[fieldType].map(
      (item) => item.propertyName,
    );

    const rupeeProperties = [
      COLUMN_PROPERTIES[COLUMN_PROPERTY_KEYS.CURRENCY.toLowerCase()],
      COLUMN_PROPERTIES.CURRENCY,
    ];

    const subTypeProperties =
      fieldType === FIELD_TYPE_ID.TABLE && subType === FIELD_TYPE_ID.RUPEE
        ? rupeeProperties
        : [];

    const subTypeKeys = subTypeProperties.map((item) => item.propertyName);

    const keys = [...fieldkeys, ...subTypeKeys];
    const removeKeys = Object.keys(columnProperties).filter(
      (key) => !keys.includes(key),
    );
    return omit(columnProperties, removeKeys);
  }

  static hasRecurringDateTime(headerData) {
    if (!headerData) return false;
    return headerData.find(
      (obj) =>
        obj.fieldType === FIELD_TYPE_ID.DATE_TIME &&
        obj.columnProperties?.[COLUMN_PROPERTY_KEYS.RECURRING],
    );
  }

  static getColumnProperties(fieldType, subType) {
    const fieldTypePropData =
      COLUMN_PROPERTY_DETAILS?.[fieldType]?.slice() ?? [];
    const subTypePropData =
      subType === FIELD_TYPE_ID.ASSIGN_TASK
        ? COLUMN_PROPERTY_DETAILS?.[subType]?.slice()
        : [];
    const alreadyAdded = {};
    return [...fieldTypePropData, ...subTypePropData].filter((prop) => {
      if (alreadyAdded[prop.propertyName]) return false;
      alreadyAdded[prop.propertyName] = true;
      return true;
    });
  }

  static processFilterObject = (obj) => {
    const {WHEN, CONDITION, VALUE} = obj;
    const isCustom = VALUE?.type === SETVALUE_COLUMN_VALUE_TYPES.CUSTOM_VALUE;
    const isDefaultValue =
      VALUE?.type === SETVALUE_COLUMN_VALUE_TYPES.DEFAULT_CONST_VALUE;
    const toColObject = WHEN?.value;

    // if isCustom is false
    const fromColObject = VALUE?.value;

    // if isCustom is true
    const customOrDefaultValue = VALUE?.value;

    return {
      colId: toColObject?.id,
      val:
        isCustom || isDefaultValue ? customOrDefaultValue : fromColObject?.id,
      isCustom,
      isDefaultValue,
      filterType: CONDITION?.value,
      sourceColId: VALUE?.value?.id,
    };
  };

  /**
   * Convert all TABLE type with text values to their given SubTypes
   *
   * @param {*} colData
   * @param {*} cellObj
   * @param {*} userCountry
   * @param {*} timezone
   * @param {*} fileObj
   * @param {*} organisationData
   * @returns
   */
  static getColObjFromTableTypeWithTextValue = (
    colData,
    cellObj,
    userCountry,
    timezone,
    fileObj,
    organisationData,
  ) => {
    const toOriginalFieldType = getOriginalFieldType(colData);

    const textInputColumnsForConditions = [
      FIELD_TYPE_ID.SELECT_POP_UP,
      FIELD_TYPE_ID.LABEL,
      FIELD_TYPE_ID.ASSIGN_TASK,
      FIELD_TYPE_ID.CONTACT,
      FIELD_TYPE_ID.AUTO_INCREMENT_ID,
    ];

    const {fieldType, id} = colData;

    if (
      fieldType === FIELD_TYPE_ID.TABLE &&
      textInputColumnsForConditions.includes(toOriginalFieldType)
    ) {
      const fromColData = {
        id,
        val: 'Col',
        fieldType: FIELD_TYPE_ID.TEXT,
      };
      const toColData = colData;
      return convertCellToDifferentField(
        toColData,
        fromColData,
        cellObj,
        userCountry,
        timezone,
        fileObj,
        organisationData,
        true,
      );
    }

    return cellObj;
  };

  static getCurrentDATE_TIMECellObj = (tz) => {
    const getVal = () => {
      const now = moment().set({seconds: 0, milliseconds: 0}).utc();
      return now.toDate().toISOString().split('.')[0] + 'Z';
    };
    return {
      val: getVal(),
      timezone: tz,
      recurringType: MINI_APPS_DATE_RECURRING_TYPES.DOES_NOT_REPEAT,
    };
  };

  static getTypeBasedDefaultValueForConditions = ({
    type,
    colObj,
    timezone,
    userCountry,
    fileObj,
    uid,
    rowProperties,
    newUserUID,
    organisationData,
    formattedWithDateFormat = false,
  }) => {
    const {id} = colObj || {};

    const originalFieldType = getOriginalFieldType(colObj);

    if (
      [
        FIELD_TYPE_ID.DATE,
        FIELD_TYPE_ID.DATE_TIME,
        FIELD_TYPE_ID.TIME,
      ].includes(originalFieldType)
    ) {
      if (type === AUTOMATION_COLUMN_VALUE_TYPES.NOW) {
        const dateRowObject = {
          [id]: [FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.TIME].includes(
            originalFieldType,
          )
            ? getDateTimeCellObj(
                null,
                originalFieldType === FIELD_TYPE_ID.TIME,
                true,
                colObj.dateFormat,
              )
            : this.getCurrentDATE_TIMECellObj(timezone),
        };
        return convertCellDataToText(
          dateRowObject?.[colObj?.id],
          colObj,
          userCountry,
          {lang: 'EN'},
          fileObj,
          {},
          true,
          {},
          true,
          true,
          {getDisplayDate: !formattedWithDateFormat},
        );
      }
    }

    if (originalFieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
      if (type === AUTOMATION_COLUMN_VALUE_TYPES.LOGGED_IN_USER) {
        return uid;
      }
      if (type === AUTOMATION_COLUMN_VALUE_TYPES.ROW_CREATOR) {
        return rowProperties?.firstAddedByUID;
      }
    }

    // App Based Automation User data

    const orgMemberObject = getMemberUsingUID(organisationData, newUserUID);
    const {displayName, email, m_name, m_email, m_phone, phoneNumber} =
      orgMemberObject || {};

    if (originalFieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
      if (type === AUTOMATION_COLUMN_VALUE_TYPES.ASSIGN_USER) {
        return newUserUID;
      }
    }

    if (originalFieldType === FIELD_TYPE_ID.TEXT) {
      if (type === AUTOMATION_COLUMN_VALUE_TYPES.USER_CONTACT) {
        return m_phone?.length ? m_phone : phoneNumber || '';
      }
      if (type === AUTOMATION_COLUMN_VALUE_TYPES.USER_EMAIL) {
        return m_email?.length ? m_email : email || '';
      }
      if (type === AUTOMATION_COLUMN_VALUE_TYPES.USER_NAME) {
        return m_name?.length ? m_name : displayName || '';
      }
    }

    if (originalFieldType === FIELD_TYPE_ID.EMAIL) {
      if (type === AUTOMATION_COLUMN_VALUE_TYPES.USER_EMAIL) {
        return m_email?.length ? m_email : email || '';
      }
    }

    if (originalFieldType === FIELD_TYPE_ID.CONTACT) {
      if (type === AUTOMATION_COLUMN_VALUE_TYPES.USER_CONTACT) {
        return m_phone?.length ? m_phone : phoneNumber || '';
      }
    }

    return '';
  };

  static prepareFiltersForElastic = (
    docData,
    filterColToColMap,
    rowData,
    userCountry,
    sourceDocHeaderMapping,
    timezone = 'Asia/Kolkata',
    organisationData = {},
    newUserUID = null, // Optional for other cases
  ) => {
    const filterOptions = [];
    const {headerData, fileObj} = docData ?? {};
    const headerTypeMapping = getHeaderTypeMapping(headerData);
    const CASE_INSENSITIVE_FIELD_TYPES = [
      ...TEXT_VALUE_TYPE_FILTER_FIELDS,
      ...MULTI_VALUE_FILTER_FIELDS,
    ];

    (filterColToColMap || []).forEach((obj) => {
      //prettier-ignore
      const {colId, val, isCustom, isDefaultValue, filterType, sourceColId} = this.processFilterObject(obj);

      const col = headerTypeMapping?.[colId] ?? {};
      const fieldType = getOriginalFieldType(col);

      const valueCheckNotNeeded =
        HIDE_VALUE_FIELD_FOR_CONDITIONS.includes(filterType);
      //prettier-ignore
      const isColumnBasedCondition = !isCustom && !isDefaultValue && !valueCheckNotNeeded;

      //prettier-ignore
      const sourceCol = isColumnBasedCondition ? sourceDocHeaderMapping[sourceColId] : {};
      //prettier-ignore
      const sourceFieldType = isColumnBasedCondition ? getOriginalFieldType(sourceCol) : null;

      /// type - CUSTOM_VALUE / COLUMN (column id)
      /// val - custom value / column id (of automation doc)
      /// filterType - Handle Types like EQUAL, ANY, IS_EMPTY, etc...

      let customRowVal = val;

      if (isCustom) {
        // Convert all TABLE type with text values to their given SubTypes
        // prettier-ignore
        customRowVal = this.getColObjFromTableTypeWithTextValue(col, val, userCountry, timezone, fileObj, organisationData);
      }

      // prettier-ignore
      const customValue = isCustom ? convertCellDataToText(customRowVal, col, userCountry, {}, fileObj, {}, true, {}, true, true, {getDisplayDate: true}) : null;

      if (
        isCustom &&
        [FIELD_TYPE_ID.UNIT, FIELD_TYPE_ID.RUPEE].includes(fieldType)
      ) {
        customRowVal = Number(val?.val);
      }
      if (
        isCustom &&
        [
          FIELD_TYPE_ID.CHECKBOX,
          FIELD_TYPE_ID.SWITCH,
          FIELD_TYPE_ID.OTP,
        ].includes(fieldType)
      ) {
        customRowVal = Boolean(val?.val);
      }

      // prettier-ignore
      const getRowVal = () => convertCellDataToText(rowData?.[val], sourceCol, userCountry, {}, fileObj, rowData, true, {}, true, true, {getDisplayDate: true});

      const defaultValue = isDefaultValue
        ? this.getTypeBasedDefaultValueForConditions({
            type: val, // NOW , LOGGED_IN_USER, ROW_CREATOR
            colObj: col,
            userCountry,
            timezone,
            rowProperties: rowData?.rowProperties,
            fileObj,
            newUserUID,
            organisationData,
          })
        : null;

      const isConvertToDateTime_From_Date =
        fieldType === FIELD_TYPE_ID.DATE_TIME &&
        sourceFieldType === FIELD_TYPE_ID.DATE;

      const isConvertToDate_From_DateTime =
        fieldType === FIELD_TYPE_ID.DATE &&
        sourceFieldType === FIELD_TYPE_ID.DATE_TIME;

      const commonGreaterThan = [
        TRIGGER_CONDITIONS.GREATER_THAN,
        TRIGGER_CONDITIONS.GREATER_THAN_OR_EQUAL,
      ].includes(filterType);

      const commonLessThan = [
        TRIGGER_CONDITIONS.LESS_THAN,
        TRIGGER_CONDITIONS.LESS_THAN_OR_EQUAL,
      ].includes(filterType);

      const dateTimeStr = 'DD/MM/YYYY hh:mm A';
      const dateStr = 'DD/MM/YYYY';

      const filterValue =
        isConvertToDate_From_DateTime && commonLessThan
          ? moment(getRowVal(), dateTimeStr).add(1, 'day').format(dateStr)
          : isConvertToDate_From_DateTime && commonGreaterThan
          ? moment(getRowVal(), dateTimeStr).format(dateStr)
          : isConvertToDateTime_From_Date && commonLessThan
          ? moment(getRowVal(), dateStr).add(1, 'day').format(dateTimeStr)
          : isConvertToDateTime_From_Date && commonGreaterThan
          ? moment(getRowVal(), dateStr).format(dateTimeStr)
          : isCustom
          ? fieldType == FIELD_TYPE_ID.TEXT && typeof customValue === 'number'
            ? `${customValue}`
            : customValue
          : isDefaultValue
          ? defaultValue
          : getRowVal();

      // ! All cases where filterValue is `'', null` return without adding to filterOptions.
      if ((filterValue === '' || filterValue == null) && !valueCheckNotNeeded) {
        return;
      }

      const filter = {colId, fieldType};

      // prettier-ignore
      const __OPTIONAL__ = TIME_BASED_RANGE_FILTER_FIELDS.includes(fieldType) ? false : null;
      const isRangeBasedFields = [
        ...RANGE_FILTER_FIELDS,
        ...TIME_BASED_RANGE_FILTER_FIELDS,
      ].includes(fieldType);

      const [isCustomFormattedDate, dateFormat] = (() => {
        //get the date format of the column with higher range [year>quarter>month>date]
        const checkIfCustomFormattedDate = (fieldType, dateFormat) =>
          fieldType === FIELD_TYPE_ID.DATE &&
          dateFormat in DATE_FORMATS &&
          dateFormat !== DATE_FORMATS.DATE;
        const dateFormatHigherOrder = [
          DATE_FORMATS.YEAR,
          DATE_FORMATS.QUARTER,
          DATE_FORMATS.MONTH,
          DATE_FORMATS.DATE,
        ];

        // prettier-ignore
        const isCustomFormatted =
          checkIfCustomFormattedDate(fieldType, col?.dateFormat) ||
          (isColumnBasedCondition ? checkIfCustomFormattedDate(sourceFieldType, sourceCol?.dateFormat) : false);

        // prettier-ignore
        return [
          isCustomFormatted,
          isCustomFormatted
            ? dateFormatHigherOrder.find(
                (d) => d === col?.dateFormat || (isColumnBasedCondition ? d === sourceCol?.dateFormat : false),
              )
            : null,
        ];
      })();

      const getFormattedDateVal = (isEndDate = false) => {
        return getDateFilterRangeForConditionCheck(
          filterValue,
          dateFormat,
          isEndDate,
          timezone,
        );
      };

      const equalAndNotEqualOptionsFn = () => {
        if (isCustomFormattedDate) {
          return [getFormattedDateVal(), getFormattedDateVal(true)];
        }

        if (isConvertToDate_From_DateTime) {
          const startOrEnd = moment(filterValue, dateTimeStr).format(dateStr);
          return [startOrEnd, startOrEnd];
        }

        if (isConvertToDateTime_From_Date) {
          const dateTime = moment(filterValue, dateStr);
          const start = dateTime.format(dateTimeStr);
          const end = dateTime
            .add(1, 'day')
            .subtract(1, 'second')
            .format(dateTimeStr);

          return [start, end];
        }

        return isRangeBasedFields ? [filterValue, filterValue] : [filterValue];
      };

      switch (filterType) {
        case TRIGGER_CONDITIONS.EQUAL:
        case TRIGGER_CONDITIONS.NOT_EQUAL: {
          if (fieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
            filter.selectedOptions = getAssignTaskFilterFormat(filterValue);
          } else {
            filter.selectedOptions = equalAndNotEqualOptionsFn();
          }
          if (filterType === TRIGGER_CONDITIONS.NOT_EQUAL) {
            filter.notEqual = true;
          }
          break;
        }

        case TRIGGER_CONDITIONS.ANY_VALUE:
          filter.selectedOptions = [
            DATA_FILTERS.ANY_CELLS_ROWS,
            ...(isRangeBasedFields ? [null] : []),
          ];
          break;
        case TRIGGER_CONDITIONS.IS_EMPTY:
          filter.selectedOptions = [
            DATA_FILTERS.EMPTY_CELLS_ROWS,
            ...(isRangeBasedFields ? [null] : []),
          ];
          break;

        // Numeric or Date based filters
        case TRIGGER_CONDITIONS.GREATER_THAN: {
          // TODO : Ask sneh to handle on elastic cloud function
          filter.selectedOptions = [
            isCustomFormattedDate ? getFormattedDateVal(true) : filterValue,
            __OPTIONAL__,
          ];
          filter.ignoreEqual = true;
          break;
        }
        case TRIGGER_CONDITIONS.LESS_THAN: {
          // TODO : Ask sneh to handle on elastic cloud function
          filter.selectedOptions = [
            __OPTIONAL__,
            isCustomFormattedDate ? getFormattedDateVal() : filterValue,
          ];
          filter.ignoreEqual = true;
          break;
        }
        case TRIGGER_CONDITIONS.GREATER_THAN_OR_EQUAL: {
          filter.selectedOptions = [
            isCustomFormattedDate ? getFormattedDateVal() : filterValue,
            __OPTIONAL__,
          ];
          break;
        }
        case TRIGGER_CONDITIONS.LESS_THAN_OR_EQUAL: {
          filter.selectedOptions = [
            __OPTIONAL__,
            isCustomFormattedDate ? getFormattedDateVal(true) : filterValue,
          ];
          break;
        }
      }

      if (!isEmpty(filter)) {
        if (fieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
          filter.isCustom = true;
        }

        if (CASE_INSENSITIVE_FIELD_TYPES.includes(fieldType)) {
          filter.isCaseInsensitive = true;
        }

        filterOptions.push(filter);
      }
    });
    return filterOptions;
  };

  static getUniqueValColumnIds(colObj) {
    return (
      colObj?.columnProperties?.[COLUMN_PROPERTY_KEYS.UNIQUE_VALUES] &&
      (colObj?.fieldType === FIELD_TYPE_ID.TABLE
        ? colObj?.subType === FIELD_TYPE_ID.ASSIGN_TASK
        : true)
    );
  }

  static uniqueValidationUpdateObj(
    prevValidationObj,
    rowData,
    prevRowData,
    headerData,
    uniqueValColumnIds,
    uniqueKeySetColumns,
    userCountry,
    userPref,
    fileObj,
  ) {
    const getTextValue = (cellObj, colObj) => {
      return convertCellDataToText(
        cellObj,
        colObj,
        userCountry,
        userPref,
        fileObj,
        rowData,
        true,
        {},
        true,
      );
    };

    const newValidationObj = {};

    headerData?.forEach((colObj) => {
      const colId = colObj.id;
      const prevRowValue = getTextValue(prevRowData?.[colId] ?? {}, colObj);
      const currentRowValue = getTextValue(rowData?.[colId] ?? {}, colObj);

      if (
        uniqueValColumnIds?.includes?.(colId) &&
        prevRowValue !== currentRowValue &&
        !isEmpty(prevValidationObj[colId])
      ) {
        newValidationObj[colId] = false;
      }
      if (
        uniqueKeySetColumns.some((item) => item.colIds?.includes?.(colId)) &&
        prevRowValue !== currentRowValue &&
        !isEmpty(prevValidationObj[colId])
      ) {
        const colIdsOmitObj = uniqueKeySetColumns.reduce((acc, item) => {
          if (item.colIds?.includes?.(colId)) {
            item.colIds.forEach((id) => {
              acc[id] = false;
            });
          }
          return acc;
        }, {});
        Object.assign(newValidationObj, colIdsOmitObj);
      }
    });

    return newValidationObj;
  }

  static isDateOrTimeFormula(headerObj = {}) {
    const checkIfDateOrTimeFormulaExist = (eqnObj) => {
      return eqnObj?.isDateDiff || eqnObj?.isTimeDiff;
    };

    const isDateOrTimeConditionalFormulaEqnObj =
      Array.isArray(headerObj?.optionalEqn) &&
      headerObj.optionalEqn.some((optionalEqnObj) =>
        optionalEqnObj.formulaConfig.eqn.some(checkIfDateOrTimeFormulaExist),
      );

    const formulaEqnObj = headerObj?.eqn?.[0];

    return (
      isDateOrTimeConditionalFormulaEqnObj ||
      checkIfDateOrTimeFormulaExist(formulaEqnObj)
    );
  }
}
