import {CONTACTS_ACTION} from './actionType';
import {isArray, isEmpty, orderBy} from 'lodash';
import {
  getUserCallingCode,
  formatPhoneNumber,
  formatAndValidateEmail,
} from '../utils/utils';
import {ENV, captureError, captureInfo} from '../imports';
import {
  loadAllContactsHelper,
  uploadContactsToFirestore,
  getDisplayName,
} from './actionHelpers/contactActionHelper';

const getAllContacts =
  (uid = null) =>
  async (dispatch, getState) => {
    //mobile only
    const {auth} = getState();
    try {
      dispatch({
        type: CONTACTS_ACTION.FETCH_START,
      });
      const callingCode = getUserCallingCode(auth.userCountry ?? 'IN');

      const {Contacts, checkContactsReadPermission} = require('../imports');
      const ContactsVar = Contacts;
      const contactsPermission = await checkContactsReadPermission(
        auth.userPref,
      );

      if (contactsPermission) {
        const fetchedContacts = await ContactsVar().getAll();
        const contacts = [];
        const emails = [];
        const emailAndContacts = [];

        // Fetch call logs from phone and update call logs in redux
        // dispatch(getRecentCallLogs());

        fetchedContacts.forEach((contactObj) => {
          if (isEmpty(contactObj)) {
            return;
          }
          try {
            const commonContactObj = {
              displayName: getDisplayName(contactObj),
              hasThumbnail: contactObj.hasThumbnail,
              thumbnailPath: contactObj.thumbnailPath,
            };
            const set = new Set();
            const emailSet = new Set();
            const phoneNumbers = [];
            let obj;
            if (contactObj.phoneNumbers?.length > 0) {
              contactObj.phoneNumbers.forEach((phoneNumber) => {
                if (phoneNumber && phoneNumber.number) {
                  const number = formatPhoneNumber(
                    phoneNumber.number,
                    callingCode,
                  );
                  if (!set.has(number)) {
                    phoneNumbers.push(
                      phoneNumber.number.replace(/[^0-9|+]/g, ''),
                    );
                    set.add(number);
                  }
                }
              });
              if (phoneNumbers.length > 0) {
                obj = {
                  ...commonContactObj,
                  phoneNumbers,
                };
                contacts.push(obj);
              }
            }
            if (contactObj.emailAddresses?.length > 0) {
              contactObj.emailAddresses.forEach((emailObj) => {
                if (emailObj && emailObj.email) {
                  const [isValid, email] = formatAndValidateEmail(
                    emailObj.email,
                  );
                  if (isValid) {
                    emailSet.add(email);
                  }
                }
              });
              if (emailSet.size > 0) {
                obj = Object.assign({}, obj, {
                  ...commonContactObj,
                  emailAddresses: [...emailSet],
                });
                emails.push({
                  ...commonContactObj,
                  emailAddresses: [...emailSet],
                });
              }
            }
            if (!isEmpty(obj)) {
              emailAndContacts.push(obj);
            }
          } catch (e) {}
        });
        let payload;
        try {
          const contactInfoIndexMapping = {};
          const contactsAndEmails = orderBy(
            emailAndContacts,
            'displayName',
            'asc',
          );
          contactsAndEmails.forEach((obj, index) => {
            if (obj.emailAddresses?.length) {
              obj.emailAddresses.forEach((email) => {
                contactInfoIndexMapping[email] = index;
              });
            }
            if (obj.phoneNumbers?.length) {
              obj.phoneNumbers.forEach((phoneNumber) => {
                contactInfoIndexMapping[
                  formatPhoneNumber(phoneNumber, callingCode)
                ] = index;
              });
            }
          });
          payload = {
            contacts: orderBy(contacts, 'displayName', 'asc'),
            emails: orderBy(emails, 'displayName', 'asc'),
            contactsAndEmails,
            contactInfoIndexMapping,
          };
        } catch (error) {
          payload = {
            contacts,
            emails,
            contactsAndEmails: emailAndContacts,
          };
          captureInfo({error});
          captureError(new Error('Sorting contacts failed'));
        }
        if (uid) {
          await uploadContactsToFirestore(uid, payload);
        }
        dispatch({type: CONTACTS_ACTION.LOAD_CONTACTS, payload});
      }
    } catch (error) {
      captureError(error);
      dispatch({
        type: CONTACTS_ACTION.LOAD_CONTACTS,
        payload: {
          contacts: [],
          emails: [],
          contactsAndEmails: [],
        },
      });
    }
  };

const updateRecentlySharedContacts = (data) => (dispatch) => {
  dispatch({
    type: CONTACTS_ACTION.SET_RECENT_SHARED_CONTACT,
    payload: data,
  });
};

const formatRecentlySharedContacts = () => async (dispatch, getState) => {
  //mobile only
  try {
    const {contacts} = getState();
    const contactsArr = contacts.recentlySharedContacts.slice();
    const data = [];
    const contactsFetchedLen = contacts.contactsArr.length;
    let ContactsVar;
    if (ENV) {
      const {Contacts} = require('../imports');
      ContactsVar = Contacts;
    }
    const process = (contactNumber) =>
      new Promise((resolve) => {
        const fallback = () => {
          data.push({hasThumbnail: false, phoneNumbers: [contactNumber]});
          resolve();
        };
        if (contactsFetchedLen > 0 && ENV) {
          ContactsVar()
            .getContactsByPhoneNumber(contactNumber)
            .then((details) => {
              if (isArray(details) && details[0]) {
                const {hasThumbnail, thumbnailPath} = details[0];
                data.push({
                  displayName: getDisplayName(details[0]),
                  hasThumbnail,
                  thumbnailPath,
                  phoneNumbers: [contactNumber],
                });
                resolve();
              } else {
                fallback();
              }
            })
            .catch(() => {
              fallback();
            });
        } else {
          fallback();
        }
      });
    await Promise.all(contactsArr.map(process));
    dispatch({
      type: CONTACTS_ACTION.SET_RECENT_SHARED_FORMATTED_CONTACT,
      payload: data,
    });
  } catch (error) {
    captureError(error);
  }
};

const loadAllContacts = (uid) => async (dispatch) => {
  //used for web
  try {
    const contactArray = await loadAllContactsHelper(uid);
    if (contactArray) {
      dispatch({
        type: CONTACTS_ACTION.LOAD_ALL_CONTACTS,
        payload: contactArray,
      });
    }
  } catch (err) {
    captureError(err);
  }
};

const clearContactList = () => (dispatch) => {
  dispatch({
    type: CONTACTS_ACTION.RESET_STATE,
  });
};

export {
  loadAllContacts,
  getAllContacts,
  updateRecentlySharedContacts,
  formatRecentlySharedContacts,
  clearContactList,
};
