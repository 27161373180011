import {isEmpty} from 'lodash';
import {getOriginalColId} from './utils';

const changeColIdKeysToOriginalColId = (obj) => {
  return Object.keys(obj).reduce((prevValue, currentVal) => {
    return Object.assign(prevValue, {
      [getOriginalColId(currentVal)]:
        obj[getOriginalColId(currentVal)] ?? obj[currentVal] ?? undefined,
    });
  }, {});
};

const restrictHeaderData = (activeDocumentMeta, headerData) => {
  const restrictions = activeDocumentMeta?.collab?.restrictions ?? {};
  return !isEmpty(restrictions)
    ? headerData.filter(
        (colData) => !restrictions?.[getOriginalColId(colData?.id)]?.isHidden,
      )
    : headerData;
};

const restrictMiniAppsHeaderData = (restrictionObj, headerData) => {
  const restrictions = Object.assign({}, restrictionObj);
  return !isEmpty(restrictions)
    ? headerData.filter(
        (colData) => !restrictions?.[getOriginalColId(colData?.id)]?.isHidden,
      )
    : headerData;
};

export {
  restrictHeaderData,
  changeColIdKeysToOriginalColId,
  restrictMiniAppsHeaderData,
};
