import {
  mergeDashboardVal,
  mergeFilesVal,
  mergeSplitByVals,
  formatSingleLevelVals,
  formatDoubleLevelVals,
} from './mergeAggregatedData';
import {isEmpty, forOwn, isArray, isNil} from 'lodash';
import {formatDashboardDisplayValue} from '../formatDataHelper';
import {getOperationValue, solveEqnStr} from '../equationHelper';
import {captureError} from '../../imports';
import {
  FOOTER_OPERATION_TYPES,
  DASHBOARD_CONSTANTS,
  FIELD_TYPE_ID,
  DASHBOARD_ERRORS_MESSAGES,
} from '../constant';
import {getFormattedColumnName} from './getFormattedDashboardData';
import {
  convertObjToArrOfObj,
  sortedInsertionIndex,
  checkIfPlainText,
} from '../utils';

const {DEFAULT_PAGE_IDENTIFIER} = DASHBOARD_CONSTANTS;

export default class DashboardData {
  constructor(dashboardToProcess, userPref, userCountry) {
    this.dashboardVal = {}; //values to be shown on dashboard
    this.allDashboardValCols = new Set();
    this.splitByVal = {}; //values split-wise
    this.splitByCols = {}; //to determine which columns are split by
    this.filesVal = {}; //values file-wise
    this.pages = [];
    this.dashboardName = dashboardToProcess.dashboardName ?? ' ';
    this.dashboardId = dashboardToProcess.dashboardId;
    this.docs = [];
    this.showPages = true; //pages are required to be displayed or not
    this.isParentDashboard = !isEmpty(dashboardToProcess.childDashboards);

    // conversion of dashboard data and it's child into renderable format
    this.mergeDashboards(
      this.isParentDashboard
        ? dashboardToProcess.childDashboards
        : {[this.dashboardId]: dashboardToProcess},
      userPref,
      userCountry,
    );
  }

  getAllAvailableColumnsOnAPage(pageName) {
    /*
     * returns Array of string which contains names of
     * all the columns present on a page
     * and have split by cols added to dashboard
     */
    const filesValOnGivenPage = Object.assign({}, this.filesVal[pageName]);
    const columns = Object.keys(filesValOnGivenPage).map((columnName) => ({
      title: columnName,
      value: getFormattedColumnName(columnName),
    }));
    return columns.length ? columns : null;
  }

  getAllAvailableSplitByColumnsOnAPage(pageName) {
    /*
     * returns Array of string which contains names of
     * all the columns present on a page
     */
    const filesValOnGivenPage = Object.assign({}, this.filesVal[pageName]);
    const columns = Object.keys(filesValOnGivenPage)
      .filter((columnName) =>
        (this.splitByCols[pageName] ?? []).includes(columnName),
      )
      .map((columnName) => ({
        title: columnName,
        value: getFormattedColumnName(columnName),
      }));
    return columns.length ? columns : null;
  }

  getSplitByValsForSelectedPageAndColumn(pageName, colName) {
    /*
     * returns key val pairs containing splitbyvals
     * for selected page and column
     * or returns null
     */
    if (isNil(pageName) || isNil(colName)) {
      return null;
    }
    return this.splitByVal[pageName]?.[colName]
      ? convertObjToArrOfObj(this.splitByVal[pageName][colName])
      : null;
  }

  getFileValsForSelectedPageAndColumn(pageName, colName) {
    /*
     * returns key val pairs containing filesVal
     * for selected page and column
     * or returns null
     */
    if (isNil(pageName) || isNil(colName)) {
      return null;
    }
    const filesVal = Object.assign({}, this.filesVal[pageName]?.[colName]);
    const res = [];
    for (const docId in this.docs) {
      res.push({
        title: this.docs[docId],
        value: Object.prototype.hasOwnProperty.call(filesVal, docId)
          ? filesVal[docId]
          : '-',
      });
    }
    return res;
  }

  getDashboardValueForSelectedPage(pageName) {
    /*
     * returns array of [{key, val}] pair to show in dashboard
     * or returns null
     */
    const res = [];
    this.allDashboardValCols.forEach((columnName) => {
      const dashboardValObj = Object.assign({}, this.dashboardVal?.[pageName]);
      res.push({
        title: getFormattedColumnName(columnName),
        value:
          columnName in dashboardValObj ? dashboardValObj[columnName] : '-',
      });
    });
    return res;
  }
}

/*
    - parent dashboard values are set in constructor itself
    - merging all given dashboards by looping over them
*/
DashboardData.prototype.mergeDashboards = function (
  childDashboards,
  userPref,
  userCountry,
) {
  const dashboardVal = {};
  const splitByVal = {};
  const pagesNameTimestampMapping = {};
  const filesVal = {};
  const docs = new Map();
  const columnFieldTypeMapping = {};
  const pageIdCalcultionColDoneMapping = {};
  this.splitByCols = {};

  forOwn(childDashboards, (dashboard) => {
    try {
      const isSplitBy = dashboard.splitBy?.length ? true : false;
      const columnName = `${dashboard.operation}_${dashboard.columnName}`;
      docs.set(dashboard.originalDocumentId, dashboard.docName);

      dashboard.pages.forEach((page) => {
        const pageName =
          (dashboard.pagesEnabled ? page.name : null) ||
          DEFAULT_PAGE_IDENTIFIER;
        pagesNameTimestampMapping[pageName] =
          page.createdTimestamp || pagesNameTimestampMapping[pageName];

        const [dashboardValues, isError] = formatDashboardDisplayValue(
          dashboard,
          page,
          userPref,
        );

        if (isSplitBy) {
          this.splitByCols[pageName] = [
            ...(this.splitByCols[pageName] ?? []),
            columnName,
          ];
        }

        if (page.docId === dashboard.originalDocumentId) {
          //unit and symbols like currency handle
          const {unitSymbol} = page;
          const fileObj = checkIfPlainText(unitSymbol)
            ? {
                [dashboard.columnId]: {
                  UNIT: {unitSymbol},
                },
              }
            : {};
          const fieldArray = [
            columnFieldTypeMapping[columnName]?.fieldType,
            columnFieldTypeMapping[columnName]?.subType,
            page.fieldType,
            page.subType,
          ]; //old field types and new field types
          const fieldTypeMappingObj = {
            fieldType: page.fieldType,
            subType: page.subType,
            fileObj,
            operation: dashboard.operation,
          }; //default
          if (fieldArray.includes(FIELD_TYPE_ID.RUPEE)) {
            //Currency priority highest
            fieldTypeMappingObj.fieldType = FIELD_TYPE_ID.RUPEE;
            fieldTypeMappingObj.subType = null;
            columnFieldTypeMapping[columnName] = fieldTypeMappingObj;
          } else if (fieldArray.includes(FIELD_TYPE_ID.UNIT)) {
            //Unit Priority second highest
            fieldTypeMappingObj.fieldType = FIELD_TYPE_ID.UNIT;
            fieldTypeMappingObj.subType = null;
            fieldTypeMappingObj.extraMetaForUnit = {
              id: dashboard.columnId,
              unitDependentColumn: dashboard.columnId,
            };
            columnFieldTypeMapping[columnName] = fieldTypeMappingObj;
          } else if (!columnFieldTypeMapping[columnName]) {
            columnFieldTypeMapping[columnName] = fieldTypeMappingObj;
          }
        }

        let finalVal;

        if (isArray(dashboardValues)) {
          // adding split by vals here
          let totalString = '0';
          dashboardValues.forEach(({rawValue}, index) => {
            totalString += `+${rawValue}`;
            if (index % 19 === 0) {
              totalString = String(solveEqnStr(totalString));
            }
          });
          const total = solveEqnStr(totalString);

          finalVal = getOperationValue(
            dashboard.operation === FOOTER_OPERATION_TYPES.COUNT
              ? FOOTER_OPERATION_TYPES.TOTAL
              : dashboard.operation,
            {
              total,
              count: dashboardValues.length,
              invalidCount: 0,
            },
          );

          splitByVal[pageName] = mergeSplitByVals(
            splitByVal[pageName],
            columnName,
            dashboardValues,
          );
        } else {
          finalVal =
            isError && DASHBOARD_ERRORS_MESSAGES[dashboardValues]
              ? DASHBOARD_ERRORS_MESSAGES[dashboardValues]
              : dashboardValues;
        }

        if (
          !pageIdCalcultionColDoneMapping[page.docId]?.[dashboard.columnId]?.[
            dashboard.operation
          ]
        ) {
          pageIdCalcultionColDoneMapping[page.docId] = {
            [dashboard.columnId]: {
              [dashboard.operation]: true,
            },
          };
          this.allDashboardValCols.add(columnName);
          dashboardVal[pageName] = mergeDashboardVal(
            dashboardVal[pageName],
            columnName,
            finalVal,
          );

          filesVal[pageName] = mergeFilesVal(
            filesVal[pageName],
            columnName,
            dashboard.originalDocumentId,
            finalVal,
          );
        }
      });
    } catch (error) {
      //error processing child dashboard
      captureError(error);
    }
  });

  const processedPagesList = [];
  forOwn(pagesNameTimestampMapping, (timestamp, pageName) => {
    const index = sortedInsertionIndex(
      processedPagesList,
      !timestamp ? Infinity : timestamp,
    );
    processedPagesList.splice(index, 0, pageName);
  });

  this.dashboardVal = formatSingleLevelVals(
    dashboardVal,
    columnFieldTypeMapping,
    userPref,
    userCountry,
  );
  this.splitByVal = formatDoubleLevelVals(
    splitByVal,
    columnFieldTypeMapping,
    userPref,
    userCountry,
  );
  this.filesVal = formatDoubleLevelVals(
    filesVal,
    columnFieldTypeMapping,
    userPref,
    userCountry,
  );
  this.pages = processedPagesList;
  this.showPages =
    !processedPagesList.length ||
    (processedPagesList.length === 1 &&
      processedPagesList[0] === DASHBOARD_CONSTANTS.DEFAULT_PAGE_IDENTIFIER)
      ? false
      : true;
  this.docs = Object.fromEntries(docs);
};
