import TextFieldTypeIcon from '../../../assets/img/textFieldType.svg';
import NumberFieldTypeIcon from '../../../assets/img/numberFieldType.svg';
import RupeeFieldTypeIcon from '../../../assets/img/rupeeFieldType.svg';
import DateFieldTypeIcon from '../../../assets/img/dateFieldType.svg';
import SwitchFieldTypeIcon from '../../../assets/img/yesNoFieldType.svg';
import FormulaFieldTypeIcon from '../../../assets/img/formulaFieldType.svg';
import UnitFieldTypeIcon from '../../../assets/img/unitFieldType.svg';
import SelectFieldTypeIcon from '../../../assets/img/selectBoxFieldType.svg';
import ContactFieldTypeIcon from '../../../assets/img/mobileNumberFieldType.svg';
import TimeFieldTypeIcon from '../../../assets/img/timeFieldType.svg';
import CheckboxFieldTypeIcon from '../../../assets/img/checkBoxFieldType.svg';
import AudioFieldTypeIcon from '../../../assets/img/audioFieldType.svg';
import ReminderFieldTypeIcon from '../../../assets/img/reminderFieldType.svg';
import MapsFieldTypeIcon from '../../../assets/img/locationFieldType.svg';
import ImageFieldTypeIcon from '../../../assets/img/imageFieldType.svg';
import DocumentFieldTypeIcon from '../../../assets/img/attachmentFieldType.svg';
import LabelFieldTypeIcon from '../../../assets/img/labelFieldType.svg';
import AssignTaskFieldTypeIcon from '../../../assets/img/assignTaskFieldType.svg';
import URLFieldTypeIcon from '../../../assets/img/urlFieldType.svg';
import TableLinkFieldTypeIcon from '../../../assets/img/tableLinkFieldType.svg';
import BarcodeFieldTypeIcon from '../../../assets/img/barcodeFieldType.svg';
import MessageIcon from '../../../assets/img/messageIcon.svg';
import CreatedInfo from '../../../assets/img/createdInfo.svg';
import EmailFieldTypeIcon from '../../../assets/img/emailFieldTypeIcon.svg';
import VideoFieldTypeIcon from '../../../assets/img/videoFieldType.svg';
import UserColumnIcon from '../../..//assets/img/userColumnIcon.svg';
import plusOne from '../../../assets/img/numeric-positive-1.svg';
// starting from here are mini apps icon imports
import textFieldTypeIconMA from '../../../assets/img/miniAppsIcon/textFieldTypeIcon.svg';
import numberFieldTypeIconMA from '../../../assets/img/miniAppsIcon/numberFieldTypeIcon.svg';
import currencyFieldTypeIconMA from '../../../assets/img/miniAppsIcon/currencyFieldTypeIcon.svg';
import formulaFieldTypeIconMA from '../../../assets/img/miniAppsIcon/formulaFieldTypeIcon.svg';
import dateFieldTypeIconMA from '../../../assets/img/miniAppsIcon/dateFieldTypeIcon.svg';
import switchFieldTypeIconMA from '../../../assets/img/miniAppsIcon/switchFieldTypeIcon.svg';
import timeFieldTypeIconMA from '../../../assets/img/miniAppsIcon/timeFieldTypeIcon.svg';
import checkboxFieldTypeIconMA from '../../../assets/img/miniAppsIcon/checkboxFieldTypeIcon.svg';
import imageFieldTypeIconMA from '../../../assets/img/miniAppsIcon/imageFieldTypeIcon.svg';
import selectFieldTypeIconMA from '../../../assets/img/miniAppsIcon/selectFieldTypeIcon.svg';
import contactFieldTypeIconMA from '../../../assets/img/miniAppsIcon/contactFieldTypeIcon.svg';
import documentFieldTypeIconMA from '../../../assets/img/miniAppsIcon/documentFieldTypeIcon.svg';
import reminderFieldTypeIconMA from '../../../assets/img/miniAppsIcon/reminderFieldTypeIcon.svg';
import unitFieldTypeIconMA from '../../../assets/img/miniAppsIcon/unitFieldTypeIcon.svg';
import mapsFieldTypeIconMA from '../../../assets/img/miniAppsIcon/mapsFieldTypeIcon.svg';
import audioFieldTypeIconMA from '../../../assets/img/miniAppsIcon/audioFieldTypeIcon.svg';
import labelFieldTypeIconMA from '../../../assets/img/miniAppsIcon/labelFieldTypeIcon.svg';
import assignTaskFieldTypeIconMA from '../../../assets/img/miniAppsIcon/assignTaskFieldTypeIcon.svg';
import tableFieldTypeIconMA from '../../../assets/img/miniAppsIcon/tableFieldTypeIcon.svg';
import URLFieldTypeIconMA from '../../../assets/img/miniAppsIcon/URLFieldTypeIcon.svg';
import barcodeFieldTypeIconMA from '../../../assets/img/miniAppsIcon/barcodeFieldTypeIcon.svg';
import commentsFieldTypeIconMA from '../../../assets/img/miniAppsIcon/commentsFieldTypeIcon.svg';
import createdInfoFieldTypeIconMA from '../../../assets/img/miniAppsIcon/createdInfoFieldTypeIcon.svg';
import emailFieldTypeIconMA from '../../../assets/img/miniAppsIcon/emailFieldTypeIcon.svg';
import videoFieldTypeIconMA from '../../../assets/img/miniAppsIcon/videoFieldTypeIcon.svg';
import userInfoFieldTypeIconMA from '../../../assets/img/miniAppsIcon/userInfoFieldTypeIcon.svg';
import pdfFieldTypeIcon from '../../../assets/img/miniAppsIcon/pdfFieldTypeIcon.svg';
import ListFieldTypeIcon from '../../../assets/img/listFieldTypeIcon.svg';
import DateTimeIcon from '../../../assets/img/dateTimeIcon.svg';
import numericPositiveOne from '../../../assets/img/numeric-positive-1.svg';
import NumberOfDaysIcon from '../../../assets/img/NumberOfDaysIcon.svg';
import BlackMobileIcon from '../../../assets/img/blackMobileIcon.svg';
import OtpIcon from '../../../assets/img/otpIcon.svg';

export const FIELD_TYPE_ID = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  PERCENT: 'PERCENT',
  RUPEE: 'RUPEE',
  OPERATION: 'OPERATION',
  NUMBER_CONSTANT: 'NUMBER_CONSTANT',
  FORMULA: 'FORMULA',
  DATE: 'DATE',
  SWITCH: 'SWITCH',
  TIME: 'TIME',
  CHECKBOX: 'CHECKBOX',
  SELECT_POP_UP: 'SELECT_POP_UP',
  IMAGE: 'IMAGE',
  REMINDER: 'REMINDER',
  DOCUMENT: 'DOCUMENT',
  UNIT: 'UNIT',
  CONTACT: 'CONTACT',
  MAPS: 'MAPS',
  AUDIO: 'AUDIO',
  BARCODE: 'BARCODE',
  LABEL: 'LABEL',
  ASSIGN_TASK: 'ASSIGN_TASK',
  TABLE: 'TABLE',
  URL: 'URL',
  COMMENTS: 'COMMENT',
  CREATED_INFO: 'CREATED_INFO',
  EMAIL: 'EMAIL',
  VIDEO: 'VIDEO',
  USER_COLUMN: 'USER_COLUMN',
  PDF: 'PDF',
  AUTO_INCREMENT_ID: 'AUTO_INCREMENT_ID',
  LIST: 'LIST',
  DATE_TIME: 'DATE_TIME',
  NO_OF_DAYS: 'NO_OF_DAYS',
  STRING_FORMULA: 'STRING_FORMULA',
  OTP: 'OTP',
};

// export const FIELD_TYPE_DETAIL = [
//     {
//         colId: 'client',
//         headerName: 'Client',
//         field: 'client',
//         iconName: 'text',//subject
//         webIconName:'subject',
//         type: FIELD_TYPE_ID.TEXT,
//         color: '#ffd6a5',
//         egText: 'Bread'
//     },
//     {
//         colId: 'amount',
//         headerName: 'Amount',
//         field: 'amount',
//         iconName: 'numeric-10',
//         webIconName:'money',
//         type: FIELD_TYPE_ID.NUMBER,
//         color: '#a0c4ff',
//         // cellStyle: dynamicCellStyle,
//         egText: '1000'
//     },
//     {
//         colId: 'rupee',
//         field: 'rupee',
//         headerName: 'Rupee',
//         iconName: 'currency-usd',
//         webIconName:'money',
//         type: FIELD_TYPE_ID.RUPEE,
//         color: '#caffbf',
//         egText: '₹ 500'
//     },
//     {
//         colId: 'formula',
//         headerName: 'Formula',
//         field: 'formula',
//         iconName: 'code-tags',
//         webIconName:'code',
//         type: FIELD_TYPE_ID.FORMULA,
//         color: '#bdb2ff',
//         egText: 'Column A + Column B'
//     },
//     {
//         colId: 5,
//         headerName: 'Date',
//         iconName: 'calendar',
//         webIconName:'today',
//         type: FIELD_TYPE_ID.DATE,
//         field: 'date',
//         color: '#ffc6ff',
//         egText: '15/09/2020'
//     },
//     {
//         colId: 'toggle',
//         field: 'toggle',
//         headerName: 'Yes/No',
//         iconName: 'toggle-switch-outline',
//         webIconName:'dehaze',
//         type: FIELD_TYPE_ID.SWITCH,
//         color: '#9bf6ff',
//         egText: 'Yes/No'
//     },
//     {
//         colId: 'percentage',
//         field: 'percentage',
//         headerName: 'Percentage',
//         iconName: 'percent-outline',
//         webIconName:'dehaze',
//         type: FIELD_TYPE_ID.PERCENT,
//         color: '#ffadad',
//         egText: '20 %'
//     },
//     {
//         colId: 'time',
//         field: 'time',
//         headerName: 'Time',
//         iconName: 'clock-time-five-outline',
//         webIconName:'schedule',
//         type: FIELD_TYPE_ID.TIME,
//         color: '#ffadad',
//         egText: '2:00 PM'
//     },
//     {
//         colId: 'checkbox',
//         field: 'checkbox',
//         headerName: 'Checkbox',
//         iconName: 'checkbox-marked-outline',
//         webIconName:'checkbox',
//         type: FIELD_TYPE_ID.CHECKBOX,
//         color: '#fdffb6',
//         egText: 'Yes/No'
//     },
//     {
//         colId: 'image',
//         field: 'image',
//         headerName: 'Image',
//         iconName: 'image-outline',
//         webIconName:'image',
//         type: FIELD_TYPE_ID.IMAGE,
//         color: '#ffadad',
//         egText: 'Image'
//     },
//     {
//         colId: 'select',
//         field: 'select',
//         headerName: 'Select Box',
//         iconName: 'form-dropdown',
//         webIconName:'done-all',
//         type: FIELD_TYPE_ID.SELECT_POP_UP,
//         color: '#ffadad',
//         egText: 'Select'
//     },
//     {
//         colId: 'mobileNumber',
//         field: 'mobileNumber',
//         headerName: 'Mobile Number',
//         iconName: 'phone-outline',
//         webIconName:'call',
//         type: FIELD_TYPE_ID.CONTACT,
//         color: '#ffba08',
//         egText: '9987642310'
//     }
// ];

export const FIELD_TYPE_DETAIL = {
  TEXT: {
    text: 'Text',
    iconName: 'text',
    id: FIELD_TYPE_ID.TEXT,
    webIconName: 'subject',
    color: '#ffd6a5',
    egText: 'Enter Value',
    svgIconSrc: TextFieldTypeIcon,
    desc: 'Insert alpha numeric values in a cell',
    properties: {
      UNIQUE_VALUES: 'Unique Values',
    },
    placeholderText: 'Enter Value',
    miniAppsIconSrc: textFieldTypeIconMA,
  },
  NUMBER: {
    text: 'Number',
    iconName: 'numeric-10',
    id: FIELD_TYPE_ID.NUMBER,
    webIconName: 'numbers',
    color: '#a0c4ff',
    egText: '1000',
    svgIconSrc: NumberFieldTypeIcon,
    desc: 'Insert numbers in a cell',
    properties: {
      AUTO_INCREMENT: 'Auto Increment',
      UNIQUE_VALUES: 'Unique Values',
      POSITIVE_VALUE_GREEN: 'Show positive values in green',
      NEGATIVE_VALUE_RED: 'Show negative values in red',
    },
    placeholderText: '1000',
    miniAppsIconSrc: numberFieldTypeIconMA,
  },
  RUPEE: {
    text: 'Currency',
    iconName: 'currency-usd',
    id: FIELD_TYPE_ID.RUPEE,
    webIconName: 'currency_rupee',
    color: '#caffbf',
    egText: '₹ 500',
    svgIconSrc: RupeeFieldTypeIcon,
    desc: 'Format number to currency',
    properties: {
      CURRENCY: 'Currency',
      POSITIVE_VALUE_GREEN: 'Show positive values in green',
      NEGATIVE_VALUE_RED: 'Show negative values in red',
    },
    placeholderText: '₹ 500',
    miniAppsIconSrc: currencyFieldTypeIconMA,
  },
  FORMULA: {
    text: 'Formula',
    iconName: 'code-tags',
    id: FIELD_TYPE_ID.FORMULA,
    webIconName: 'code',
    color: '#bdb2ff',
    egText: 'Column A + Column B',
    svgIconSrc: FormulaFieldTypeIcon,
    desc: 'Create forumla for automatic calculation',
    properties: {
      UNIQUE_VALUES: 'Unique Values',
      POSITIVE_VALUE_GREEN: 'Show positive values in green',
      NEGATIVE_VALUE_RED: 'Show negative values in red',
    },
    placeholderText: 'Column A + Column B',
    miniAppsIconSrc: formulaFieldTypeIconMA,
  },
  STRING_FORMULA: {
    text: 'String Formula',
    iconName: 'function-variant',
    id: FIELD_TYPE_ID.STRING_FORMULA,
    webIconName: 'code',
    color: '#bdb2ff',
    egText: 'SPLIT(First Name,_,1)',
    svgIconSrc: FormulaFieldTypeIcon,
    desc: 'Create formula for string operations',
    properties: {},
    placeholderText: 'Column A + Column B',
    miniAppsIconSrc: formulaFieldTypeIconMA,
  },
  DATE: {
    text: 'Date',
    iconName: 'calendar',
    id: FIELD_TYPE_ID.DATE,
    webIconName: 'event',
    color: '#ffc6ff',
    egText: '15/09/2020',
    svgIconSrc: DateFieldTypeIcon,
    desc: 'Insert date to cell using calendar',
    properties: {
      AUTO_FILL_ON_ENTRY: 'Auto Fill on entry',
    },
    placeholderText: '15/09/2020',
    miniAppsIconSrc: dateFieldTypeIconMA,
  },
  SWITCH: {
    text: 'Yes/No',
    iconName: 'toggle-switch-outline',
    id: FIELD_TYPE_ID.SWITCH,
    webIconName: 'toggle_on',
    color: '#9bf6ff',
    egText: 'Yes/No',
    svgIconSrc: SwitchFieldTypeIcon,
    desc: 'Use on off switch in a cell',
    properties: {
      LIVE_LOCATION_ONLY: 'Live location only',
    },
    placeholderText: 'Yes/No',
    miniAppsIconSrc: switchFieldTypeIconMA,
  },
  TIME: {
    text: 'Time',
    iconName: 'clock-time-five-outline',
    id: FIELD_TYPE_ID.TIME,
    webIconName: 'schedule',
    color: '#ffadad',
    egText: '2:00 PM',
    svgIconSrc: TimeFieldTypeIcon,
    desc: 'Insert time to cell using a clock',
    properties: {
      AUTO_FILL_ON_ENTRY: 'Auto Fill on entry',
    },
    placeholderText: '2:00 PM',
    miniAppsIconSrc: timeFieldTypeIconMA,
  },
  CHECKBOX: {
    text: 'Checkbox',
    iconName: 'checkbox-marked-outline',
    id: FIELD_TYPE_ID.CHECKBOX,
    webIconName: 'check_box',
    color: '#fdffb6',
    egText: 'Yes/No',
    svgIconSrc: CheckboxFieldTypeIcon,
    desc: 'Use checkbox in a cell',
    properties: {
      AUTO_FILL_ON_ENTRY: 'Auto Fill on entry',
    },
    placeholderText: 'Checkbox',
    miniAppsIconSrc: checkboxFieldTypeIconMA,
  },
  IMAGE: {
    text: 'Image',
    iconName: 'image-outline',
    id: FIELD_TYPE_ID.IMAGE,
    webIconName: 'image',
    color: '#ffadad',
    egText: 'Image',
    svgIconSrc: ImageFieldTypeIcon,
    desc: 'Add multiple images in a cell',
    properties: {
      IMAGES_FROM_CAMERA_ONLY: 'Images from camera only',
    },
    placeholderText: 'Image',
    miniAppsIconSrc: imageFieldTypeIconMA,
  },
  SELECT_POP_UP: {
    text: 'Select Box',
    iconName: 'form-dropdown',
    id: FIELD_TYPE_ID.SELECT_POP_UP,
    webIconName: 'arrow_drop_down_circle',
    color: '#ffba08',
    egText: 'Select',
    svgIconSrc: SelectFieldTypeIcon,
    desc: 'Create a list of values and easily insert them',
    placeholderText: 'Select',
    miniAppsIconSrc: selectFieldTypeIconMA,
  },
  CONTACT: {
    text: 'Mobile Number',
    iconName: 'phone-outline',
    id: FIELD_TYPE_ID.CONTACT,
    webIconName: 'call',
    color: '#ffba08',
    egText: '9987642310',
    svgIconSrc: ContactFieldTypeIcon,
    desc: 'Insert phone number in a cell',
    placeholderText: '9619601744',
    miniAppsIconSrc: contactFieldTypeIconMA,
  },
  DOCUMENT: {
    text: 'Attachment',
    iconName: 'attachment',
    id: FIELD_TYPE_ID.DOCUMENT,
    webIconName: 'attachment',
    color: '#bef7c6',
    egText: 'Upload Document',
    svgIconSrc: DocumentFieldTypeIcon,
    desc: 'Upload one or more documents in a cell',
    placeholderText: 'Attachment',
    miniAppsIconSrc: documentFieldTypeIconMA,
  },
  REMINDER: {
    text: 'Reminder',
    iconName: 'bell-plus-outline',
    id: FIELD_TYPE_ID.REMINDER,
    webIconName: 'notification_add',
    color: '#ffd6a5',
    egText: '8:00 PM 30/03/2020',
    svgIconSrc: ReminderFieldTypeIcon,
    desc: "Add reminders so that you don't miss anything",
    placeholderText: '8:00 PM 30/03/2020 Reminder',
    miniAppsIconSrc: reminderFieldTypeIconMA,
  },
  UNIT: {
    text: 'Unit',
    iconName: 'ruler',
    id: FIELD_TYPE_ID.UNIT,
    webIconName: 'square_foot',
    color: '#ffedad',
    egText: '5 Kg',
    svgIconSrc: UnitFieldTypeIcon,
    desc: 'Add units like kg, cm in your data',
    placeholderText: '5 kg',
    miniAppsIconSrc: unitFieldTypeIconMA,
  },
  MAPS: {
    text: 'Location',
    iconName: 'map-marker',
    id: FIELD_TYPE_ID.MAPS,
    webIconName: 'fmd_good',
    color: '#ffba08',
    egText: 'Pune, India',
    svgIconSrc: MapsFieldTypeIcon,
    desc: 'Insert locaiton using google maps',
    properties: {
      LIVE_LOCATION_ONLY: 'Live location only',
    },
    placeholderText: 'Pune, India',
    miniAppsIconSrc: mapsFieldTypeIconMA,
  },
  AUDIO: {
    text: 'Audio',
    iconName: 'headphones',
    id: FIELD_TYPE_ID.AUDIO,
    webIconName: 'headphones',
    color: '#ffba08',
    egText: 'Select Audio',
    svgIconSrc: AudioFieldTypeIcon,
    desc: 'Record or upload voice notes',
    placeholderText: 'Audio',
    miniAppsIconSrc: audioFieldTypeIconMA,
  },
  LABEL: {
    text: 'Label',
    iconName: 'label',
    id: FIELD_TYPE_ID.LABEL,
    webIconName: 'label',
    color: '#ffba08',
    egText: 'Select',
    svgIconSrc: LabelFieldTypeIcon,
    desc: 'Create lables and add them to your row',
    placeholderText: 'Select',
    miniAppsIconSrc: labelFieldTypeIconMA,
  },
  ASSIGN_TASK: {
    text: 'Assign Task',
    iconName: 'label',
    id: FIELD_TYPE_ID.ASSIGN_TASK,
    webIconName: 'label',
    color: '#ffba08',
    egText: 'Select',
    svgIconSrc: AssignTaskFieldTypeIcon,
    desc: 'Assign task to your team members',
    placeholderText: 'Assign Task',
    miniAppsIconSrc: assignTaskFieldTypeIconMA,
  },
  TABLE: {
    text: 'Table',
    iconName: 'TABLE',
    id: FIELD_TYPE_ID.TABLE,
    webIconName: 'table',
    color: '#ffba08',
    egText: '',
    svgIconSrc: TableLinkFieldTypeIcon,
    desc: 'Get values from a different file',
    placeholderText: 'Table',
    miniAppsIconSrc: tableFieldTypeIconMA,
  },
  URL: {
    text: 'URL',
    iconName: 'link',
    id: FIELD_TYPE_ID.URL,
    webIconName: 'label',
    color: '#ffba08',
    egText: 'Select',
    svgIconSrc: URLFieldTypeIcon,
    desc: 'Add URL/links to a cell',
    placeholderText: 'https://lio.io',
    miniAppsIconSrc: URLFieldTypeIconMA,
  },
  BARCODE: {
    text: 'Barcode',
    iconName: 'code',
    id: FIELD_TYPE_ID.BARCODE,
    webIconName: 'label',
    color: '#ffba08',
    egText: 'Select',
    svgIconSrc: BarcodeFieldTypeIcon,
    desc: 'Scan barcode using phone camera',
    placeholderText: 'Barcode',
    miniAppsIconSrc: barcodeFieldTypeIconMA,
  },
  [FIELD_TYPE_ID.COMMENTS]: {
    text: 'Comments',
    iconName: 'link',
    id: FIELD_TYPE_ID.COMMENTS,
    webIconName: 'chat',
    color: '#ffba08',
    egText: 'Comments',
    svgIconSrc: MessageIcon,
    desc: 'Add comments to a cell',
    placeholderText: 'Comments',
    miniAppsIconSrc: commentsFieldTypeIconMA,
  },
  [FIELD_TYPE_ID.CREATED_INFO]: {
    text: 'Data Added By',
    iconName: 'text-account',
    id: FIELD_TYPE_ID.CREATED_INFO,
    webIconName: 'person',
    color: '#FED9A5',
    egText: 'Data Added By',
    svgIconSrc: CreatedInfo,
    desc: 'Auto-fill created user and time on the column.',
    placeholderText: 'Data Added By',
    miniAppsIconSrc: createdInfoFieldTypeIconMA,
  },
  [FIELD_TYPE_ID.EMAIL]: {
    text: 'Email',
    iconName: 'text-email',
    id: FIELD_TYPE_ID.EMAIL,
    webIconName: 'email',
    color: '#FED9A5',
    egText: 'abc@gmail.com',
    svgIconSrc: EmailFieldTypeIcon,
    desc: 'Insert email in a cell',
    properties: {
      UNIQUE_VALUES: 'Unique Values',
    },
    placeholderText: 'Email',
    miniAppsIconSrc: emailFieldTypeIconMA,
  },
  [FIELD_TYPE_ID.VIDEO]: {
    text: 'Video',
    iconName: 'video-outline',
    id: FIELD_TYPE_ID.VIDEO,
    webIconName: 'Videocam',
    color: '#ffadad',
    egText: 'Video',
    svgIconSrc: VideoFieldTypeIcon,
    desc: 'Upload or Record video to the field.',
    placeholderText: 'Video',
    miniAppsIconSrc: videoFieldTypeIconMA,
  },
  [FIELD_TYPE_ID.USER_COLUMN]: {
    text: 'User',
    iconName: 'user',
    id: FIELD_TYPE_ID.USER_COLUMN,
    webIconName: 'UserColumnIcon',
    color: '#ffadad',
    egText: 'User',
    svgIconSrc: UserColumnIcon,
    desc: 'Choose from list of org. members.',
    placeholderText: 'User',
    miniAppsIconSrc: userInfoFieldTypeIconMA,
  },
  [FIELD_TYPE_ID.PDF]: {
    text: 'PDF',
    iconName: 'pdf',
    id: FIELD_TYPE_ID.PDF,
    webIconName: 'PdfIcon',
    color: '#ffadad',
    egText: 'PDF',
    svgIconSrc: pdfFieldTypeIcon,
    desc: 'Create PDF by mapping columns',
    placeholderText: 'Pdf',
    miniAppsIconSrc: pdfFieldTypeIcon,
  },
  [FIELD_TYPE_ID.AUTO_INCREMENT_ID]: {
    text: 'Auto Increment',
    iconName: 'Auto Increment ID',
    id: FIELD_TYPE_ID.AUTO_INCREMENT_ID,
    webIconName: 'AutoIncrementID',
    color: '#791BEE',
    egText: 'Auto Increment',
    svgIconSrc: plusOne,
    desc: 'Generate sequence of numbers',
    placeholderText: 'Auto Increment',
    miniAppsIconSrc: numericPositiveOne,
  },
  [FIELD_TYPE_ID.LIST]: {
    text: 'List',
    iconName: 'list',
    id: FIELD_TYPE_ID.LIST,
    webIconName: 'ListColumnIcon',
    color: '#ffba08',
    egText: 'List',
    svgIconSrc: ListFieldTypeIcon,
    desc: 'Insert list of data from other files',
    placeholderText: 'List',
    miniAppsIconSrc: ListFieldTypeIcon,
  },
  [FIELD_TYPE_ID.DATE_TIME]: {
    text: 'Date & time',
    iconName: 'calendar',
    id: FIELD_TYPE_ID.DATE_TIME,
    webIconName: 'event',
    color: '#ffc6ff',
    egText: '01/01/1970',
    svgIconSrc: DateTimeIcon,
    desc: 'Insert Date and Time value',
    placeholderText: '01/01/1970 12:00 am',
    miniAppsIconSrc: DateTimeIcon,
  },
  [FIELD_TYPE_ID.NO_OF_DAYS]: {
    text: 'Number of Days',
    iconName: 'calculate',
    id: FIELD_TYPE_ID.NO_OF_DAYS,
    webIconName: 'calculate',
    color: '#ffba08',
    egText: 'Number of Days',
    svgIconSrc: NumberOfDaysIcon,
    desc: 'Number of Days between two dates',
    placeholderText: '10',
    miniAppsIconSrc: NumberOfDaysIcon,
  },
  [FIELD_TYPE_ID.OTP]: {
    text: 'OTP Verification',
    iconName: 'otp',
    id: FIELD_TYPE_ID.OTP,
    webIconName: 'otp',
    color: '#ffba08',
    egText: 'Number of Days',
    svgIconSrc: OtpIcon,
    desc: 'Verify OTP using Mobile Number or Email',
    placeholderText: '10',
    miniAppsIconSrc: BlackMobileIcon,
  },
};
