import {TASKS_ACTION} from '../actions/actionType';
import {ASSIGN_TASK_DETAILS} from '../utils/constant';
import {sortVisibleTaskArray} from '../actions/actionHelpers/tasksActionHelper';

const initialState = {
  allTask: {},
  isLoading: false,
  visibleTasks: [],
  isFromLocalStorage: false,
  isFailed: false, //can't fetch from server as well as local storage
  isFetched: false,
  isDocNameMapped: false,
  currentVisibleTasks: {},
  searchText: '',
  currentFilter: ASSIGN_TASK_DETAILS.FILTERS[0],
  loadingTasks: [],
};

const premiumReducer = (state = initialState, action) => {
  switch (action.type) {
    case TASKS_ACTION.GET_ALL_TASKS: {
      return {
        ...state,
        allTask: action.payload.allTasks,
      };
    }
    case TASKS_ACTION.SET_FETCH_META: {
      return {
        ...state,
        isFromLocalStorage: action.payload.isFromLocalStorage ? true : false,
        isFailed: action.payload.isFailed ? true : false,
        isFetched: true,
      };
    }
    case TASKS_ACTION.SET_DOC_NAME_MAPPED_STATE: {
      return {
        ...state,
        isDocNameMapped: action.payload ? true : false,
      };
    }
    case TASKS_ACTION.SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload ? true : false,
      };
    }
    case TASKS_ACTION.SET_VISIBLE_TASKS: {
      return {
        ...state,
        visibleTasks: sortVisibleTaskArray(action.payload?.slice?.() ?? []),
      };
    }
    case TASKS_ACTION.RESET_STATE: {
      return initialState;
    }
    case TASKS_ACTION.SET_VISIBLE_DOCUMENT_IDS: {
      return {
        ...state,
        currentVisibleTasks: Object.assign({}, action.payload),
      };
    }
    case TASKS_ACTION.UPADATE_TASK_DETAILS: {
      return {
        ...state,
        allTask: action.payload.allTask,
      };
    }
    case TASKS_ACTION.UPDATE_TASK_SEARCH_TEXT: {
      return {
        ...state,
        searchText: `${action.payload}`,
      };
    }
    case TASKS_ACTION.UPDATE_TASK_FILTER: {
      return {
        ...state,
        currentFilter: action.payload,
      };
    }
    case TASKS_ACTION.UPDATE_LOADING_TASKS: {
      return {
        ...state,
        loadingTasks: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default premiumReducer;
