import {
  formatCurrency,
  formatUnit,
  formatDateDiff,
  formatTimeDiff,
  getPrintTime,
  getLocalText,
  getPrintDate,
  checkIfPlainText,
  getFooterVisiblityAndType,
  removeAllSpecialCharactersFromString,
  decodeLabelEncodedString,
} from './utils';
import {
  FIELD_TYPE_ID,
  STRING_FORMATTING_NOT_ALLOWED_FIELDS,
  INVALID_INPUT,
  FOOTER_OPERATION_TYPES,
  DASHBOARD_ERRORS,
  TOTAL_NOT_ALLOWED,
  DASHBOARDS_CHART_COLORS,
  GRAPH_TYPES,
} from './constant';
import {processDashboardSplitByDisplayData} from '../actions/actionHelpers/dashboardActionHelper';
import {isEmpty, isArray, isNil, isNaN} from 'lodash';
import moment from 'moment';
import {captureError} from '../imports';

/**
 *
 * @param {*} footerObj : row @ headerId
 * @param {*} headerObj : headerObj @ index
 * @param {*} userPref
 * @param {*} userCountry
 * @param {*} fileObj
 * @returns null -> not to show footer (or) valid footer value as a string
 */
export const formatFooterCell = (
  footerObj,
  headerObj,
  userPref,
  userCountry = 'IN',
  fileObj = {},
) => {
  const {isTotalVisible, totalType} = getFooterVisiblityAndType(
    {id: footerObj},
    'id',
  );
  if (!isTotalVisible) {
    return null;
  }
  let footerValue = footerObj.val;
  if (totalType === FOOTER_OPERATION_TYPES.COUNT) {
    //if footertype is count then no need to format it
    return checkIfPlainText(footerValue) ? footerValue : INVALID_INPUT;
  }
  try {
    if (footerValue !== INVALID_INPUT && footerValue !== '') {
      if (
        headerObj.fieldType === FIELD_TYPE_ID.RUPEE ||
        ([FIELD_TYPE_ID.FORMULA, FIELD_TYPE_ID.TABLE].includes(
          headerObj.fieldType,
        ) &&
          headerObj.subType === FIELD_TYPE_ID.RUPEE)
      ) {
        footerValue =
          formatCurrency(footerValue, userCountry, headerObj) ?? INVALID_INPUT;
      } else if (
        headerObj.fieldType === FIELD_TYPE_ID.FORMULA &&
        (headerObj.subType === FIELD_TYPE_ID.TIME ||
          headerObj.subType === FIELD_TYPE_ID.DATE)
      ) {
        footerValue =
          formatDateDiff(
            footerValue,
            userPref,
            headerObj.subType === FIELD_TYPE_ID.TIME,
            true,
          ) ?? INVALID_INPUT;
      } else if (
        headerObj.fieldType === FIELD_TYPE_ID.UNIT ||
        ([FIELD_TYPE_ID.FORMULA, FIELD_TYPE_ID.TABLE].includes(
          headerObj.fieldType,
        ) &&
          headerObj.subType === FIELD_TYPE_ID.UNIT)
      ) {
        footerValue = formatUnit(
          footerValue,
          headerObj.subType === FIELD_TYPE_ID.UNIT
            ? headerObj.unitDependentColumn
              ? fileObj?.[headerObj.unitDependentColumn]
              : {}
            : fileObj?.[headerObj.id],
          userCountry,
        );
      }
    }
  } catch (err) {
    footerValue = INVALID_INPUT;
  }
  return checkIfPlainText(footerValue) ? footerValue : INVALID_INPUT;
};

export const formatFooterAsHeader = (
  footerValue,
  columnDetails,
  fileObj = {},
  userCountry = 'IN',
) => {
  try {
    const {type, fieldType, subType, id, unitDependentColumn} = columnDetails;
    const columnType = type ?? fieldType;
    if (
      columnType === FIELD_TYPE_ID.RUPEE ||
      (columnType === FIELD_TYPE_ID.FORMULA && subType === FIELD_TYPE_ID.RUPEE)
    ) {
      footerValue = formatCurrency(footerValue, userCountry, columnDetails);
    } else if (
      columnType === FIELD_TYPE_ID.UNIT ||
      (columnType === FIELD_TYPE_ID.FORMULA && subType === FIELD_TYPE_ID.UNIT)
    ) {
      footerValue = formatUnit(
        footerValue,
        subType === FIELD_TYPE_ID.UNIT
          ? unitDependentColumn
            ? fileObj?.[unitDependentColumn]
            : {}
          : fileObj?.[id],
        userCountry,
      );
    }
  } catch (e) {}
  return checkIfPlainText(footerValue) ? footerValue : INVALID_INPUT;
};

/**
 *
 * @param {*} cellObj : row @ headerId
 * @param {*} headerObj : headerObj @ index
 * @param {*} fileObj
 * @param {*} param3 : extra parameters
 * @returns formatted cellValue as string
 */
export const getFormattedCellValueAsString = (
  cellObj,
  headerObj,
  fileObj,
  {userCountry, userPref},
) => {
  let finalValue = '';
  try {
    if (isEmpty(cellObj) || !('val' in cellObj) || cellObj.val === '') {
      return finalValue;
    }
    const {val} = cellObj;
    const {fieldType, id, subType, unitDependentColumn} = headerObj;

    if (fieldType === FIELD_TYPE_ID.DATE) {
      finalValue = getPrintDate(val, headerObj.dateFormat);
    } else if (fieldType === FIELD_TYPE_ID.TIME) {
      finalValue = getPrintTime(val);
    } else if (fieldType === FIELD_TYPE_ID.RUPEE) {
      finalValue = formatCurrency(val, userCountry, headerObj);
    } else if (fieldType === FIELD_TYPE_ID.UNIT) {
      finalValue = formatUnit(val, fileObj?.[id], userCountry);
    } else if (fieldType === FIELD_TYPE_ID.FORMULA) {
      if (subType === FIELD_TYPE_ID.RUPEE) {
        finalValue = formatCurrency(val, userCountry, headerObj);
      } else if (subType === FIELD_TYPE_ID.DATE) {
        finalValue = formatDateDiff(val, userPref);
      } else if (subType === FIELD_TYPE_ID.TIME) {
        finalValue = formatTimeDiff(val, userPref);
      } else if (subType === FIELD_TYPE_ID.UNIT) {
        finalValue = formatUnit(
          val,
          unitDependentColumn ? fileObj?.[unitDependentColumn] : {},
          userCountry,
        );
      } else {
        finalValue = val;
      }
    } else if (fieldType === FIELD_TYPE_ID.SELECT_POP_UP) {
      if (cellObj.displayName) {
        finalValue =
          (cellObj.displayName[userPref?.lang]
            ? cellObj.displayName[userPref?.lang]
            : cellObj.displayName.EN) || '';
      }
    } else if (fieldType === FIELD_TYPE_ID.LABEL) {
      if (isArray(cellObj.val)) {
        finalValue = cellObj.val.map((item) => item.val).join(', ');
      }
    } else if (fieldType === FIELD_TYPE_ID.REMINDER) {
      finalValue = moment.unix(val).format('hh:mm A DD-MM-YYYY');
    } else if (
      [FIELD_TYPE_ID.CHECKBOX, FIELD_TYPE_ID.SWITCH].includes(fieldType)
    ) {
      finalValue = getLocalText(userPref, val ? 'Yes' : 'No');
    } else if (!STRING_FORMATTING_NOT_ALLOWED_FIELDS.includes(fieldType)) {
      finalValue = val;
    }
  } catch (e) {}
  return checkIfPlainText(finalValue) ? finalValue : '';
};

export const formatDashboardDisplayValue = (
  dashboardMeta,
  dashboardPageData,
  userPref,
  userCountry = 'IN',
) => {
  let displayVal = '';
  let isError = false;
  try {
    const getFormattedValue = (val) => {
      const res = (() => {
        if (val && val in DASHBOARD_ERRORS) {
          //check if val is any error code
          return [val, true];
        } else if (
          !isNil(dashboardPageData.fieldType) &&
          TOTAL_NOT_ALLOWED.includes(dashboardPageData.fieldType)
        ) {
          return [DASHBOARD_ERRORS.DASHBOARD_INVALID_FIELD, true];
        } else {
          const {unitSymbol} = dashboardPageData;
          const fileObj =
            unitSymbol && checkIfPlainText(unitSymbol)
              ? {
                  [dashboardMeta.columnId]: {
                    UNIT: {unitSymbol},
                  },
                }
              : {};
          const extraMetaForUnit = {};
          if (dashboardPageData.subType === FIELD_TYPE_ID.UNIT) {
            extraMetaForUnit.unitDependentColumn = dashboardMeta.columnId;
          }
          if (dashboardPageData.fieldType === FIELD_TYPE_ID.UNIT) {
            extraMetaForUnit.id = dashboardMeta.columnId;
          }
          return [
            formatFooterCell(
              {val, type: dashboardMeta.operation},
              {
                fieldType: dashboardPageData.fieldType,
                subType: dashboardPageData.subType,
                ...extraMetaForUnit,
              },
              userPref,
              userCountry,
              fileObj,
            ),
            false,
          ];
        }
      })();
      return [res[0], res[1] || res[0] === INVALID_INPUT ? true : false];
    };

    const getLocalisedTitle = (title) => {
      const localisedTitle =
        dashboardMeta.displayObj?.localisationData?.[title]?.[userPref?.lang];
      return !isNil(localisedTitle) ? localisedTitle : title;
    };

    if (isArray(dashboardMeta.splitBy) && dashboardMeta.splitBy.length > 0) {
      //with split
      let errMsg;
      displayVal = [];
      const splitByVal = Object.assign({}, dashboardPageData.splitByVal);
      const splitByValKeys = Object.keys(splitByVal);
      for (let i = 0; i < splitByValKeys.length; ++i) {
        const key = splitByValKeys[i];
        const val = splitByVal[key];
        const [formattedVal] = getFormattedValue(val);
        if (formattedVal && formattedVal in DASHBOARD_ERRORS) {
          //if any of value contains any error then simple render than error message
          errMsg = formattedVal;
          break;
        }
        const title =
          dashboardMeta.splitBy[0].fieldType === FIELD_TYPE_ID.DATE ||
          dashboardMeta.splitBy[0].subType === FIELD_TYPE_ID.DATE
            ? getPrintDate(key)
            : getLocalisedTitle(key);
        displayVal.push({
          title: decodeLabelEncodedString(title),
          value: formattedVal,
          rawValue: val,
        });
      }
      if (isNil(errMsg)) {
        displayVal = processDashboardSplitByDisplayData(
          displayVal,
          dashboardMeta.splitBy[0],
        );
      } else {
        displayVal = errMsg;
        isError = true;
      }
    } else {
      //without split
      [displayVal, isError] = getFormattedValue(dashboardPageData.val);
      displayVal = dashboardPageData.val;
    }
  } catch (e) {
    captureError(e);
  }
  return [displayVal, isError];
};

export const getGraphDataByType = (
  data,
  type,
  dashboardIndex = 0,
  activeIndex = null,
) => {
  let graphdata;
  try {
    const getColorByIndex = (index) => {
      index += dashboardIndex ?? 0;
      return DASHBOARDS_CHART_COLORS[
        (isNaN(index) ? 0 : index) % DASHBOARDS_CHART_COLORS.length
      ];
    };
    if (type === GRAPH_TYPES.BAR) {
      graphdata = {
        labels: isArray(data)
          ? data.map((dv, i) =>
              isNil(activeIndex) || activeIndex === i
                ? decodeLabelEncodedString(dv?.title)
                : '',
            )
          : [],
        datasets: [
          {
            data: isArray(data)
              ? data.map((dv, i) => {
                  const splitVal = `${dv?.value}`.split(' ');
                  let val;
                  if (splitVal.length > 1) {
                    val = splitVal[splitVal.length - 1];
                  } else {
                    val = dv?.value;
                  }
                  val = Number(removeAllSpecialCharactersFromString(`${val}`));
                  return isNaN(val) ? 0 : val;
                })
              : [],
            colors: isArray(data)
              ? data.map(
                  (dv, i) => () =>
                    isNil(activeIndex) || activeIndex === i
                      ? DASHBOARDS_CHART_COLORS[dashboardIndex ?? 14]
                      : DASHBOARDS_CHART_COLORS[22],
                )
              : [],
          },
        ],
      };
    } else if (type === GRAPH_TYPES.PIE) {
      graphdata =
        isArray(data) &&
        data.map((dv, i) => {
          const splitVal = `${dv?.value}`.split(' ');
          let val =
            splitVal.length > 1 ? splitVal[splitVal.length - 1] : dv?.value;

          val = Number(removeAllSpecialCharactersFromString(`${val}`));
          val = isNaN(val) ? 0 : val;
          return {
            name: decodeLabelEncodedString(dv?.title),
            value: val < 0 ? -1 * val : val,
            color: getColorByIndex(i),
            legendFontColor: '#7F7F7F',
            legendFontSize: 15,
            displayVal: dv?.value,
          };
        });
    } else if (type === GRAPH_TYPES.STACKED_BAR) {
      let maxLength = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].value.length > maxLength) {
          maxLength = data[i].value.length;
        }
      }
      const barColors = [];
      for (let index = 0; index < maxLength; index++) {
        barColors.push(getColorByIndex(index));
      }
      graphdata = {
        labels: isArray(data)
          ? data.map((dv, i) =>
              isNil(activeIndex) || activeIndex === i
                ? decodeLabelEncodedString(dv?.title)
                : '',
            )
          : [],
        legend: [],
        barColors,
        data: isArray(data)
          ? data.map((dv) => {
              return (isArray(dv?.value) ? dv.value : []).map((dvv) => {
                const splitVal = `${dvv?.value}`.split(' ');
                if (splitVal.length > 1) {
                  let val = Number(
                    removeAllSpecialCharactersFromString(
                      `${splitVal[splitVal.length - 1]}`,
                    ),
                  );
                  val = isNaN(val) ? 0 : val;
                  return val < 0 ? -1 * val : val;
                } else {
                  const val = Number(
                    removeAllSpecialCharactersFromString(`${dvv?.value}`),
                  );
                  return val < 0 ? -1 * val : val;
                }
              });
            })
          : [],
      };
      const isAllValZero = graphdata.data.every((arr) =>
        arr.every((val) => val == 0),
      );
      if (isAllValZero) {
        graphdata.data = [];
      }
    }
    return graphdata;
  } catch (error) {
    captureError(error);
    return graphdata;
  }
};
