import React from 'react';
import {ToastContainer, toast, cssTransition, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToastWrapperStyles.css';
import ErrorIcon from '../../assets/img/warningRed.svg';
import SuccessIcon from '../../assets/img/greenTick.svg';
import ProcessIcon from '../../assets/img/InfoBlueBg.svg';
import {isEmpty} from 'lodash';
import theme from '../../utilsWeb/designTheme';

let customToastAction = () => {};

const transition1 = cssTransition({
  enter: 'slide-in-left',
  exit: 'slide-out-left',
});
const transition2 = cssTransition({
  enter: 'flip-in-ver-right',
  exit: 'slide-out-left',
});
const transition3 = cssTransition({
  enter: 'flip-in-ver-right',
  exit: 'slide-out-right',
});

const getTitleAndSubtitleComponent = (title, subtitle) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  };

  const titleStyle = {
    fontFamily: "'Inter'",
    fontWeight: '500',
    fontSize: theme.fontSize.font16,
    lineHeight: theme.lineHeight.lineHeight20,
    color: theme.palette.white,
    margin: '0',
  };

  const subtitleStyle = {
    fontFamily: "'Inter'",
    fontWeight: '500',
    fontSize: theme.fontSize.font12,
    lineHeight: theme.lineHeight.lineHeight16,
    color: theme.palette.white,
    margin: '0',
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>{title}</h1>
      <h2 style={subtitleStyle}>{subtitle}</h2>
    </div>
  );
};

customToastAction = (params) => {
  const {
    toastType = 'INFO',
    message = '',
    position = 'bottom-left',
    autoClose = 2000,
    onClick = () => {},
    toastId,
    className,
    bodyClassName,
    icon,
    title = '',
    subtitle = '',
    isLoading,
  } = params;

  let messageToUse;
  if (!isEmpty(title) && !isEmpty(subtitle) && isEmpty(message)) {
    messageToUse = getTitleAndSubtitleComponent(title, subtitle);
  } else {
    messageToUse = message;
  }

  switch (toastType) {
    case 'INFO': {
      return toast.info(messageToUse, {
        toastId,
        position,
        autoClose,
        icon: icon ?? <img alt="info" src={ProcessIcon} height={36} />,
        onClick,
        isLoading: isLoading,
        ...(position === 'bottom-left' ? {transition: transition1} : {}),
        className: className ?? '',
        bodyClassName: bodyClassName ?? '',
        closeButton: false,
      });
    }
    case 'SUCCESS': {
      return toast.success(messageToUse, {
        toastId,
        position,
        autoClose,
        icon: icon ?? <img alt="success" src={SuccessIcon} height={32} />,
        onClick,
        ...(position === 'bottom-left' ? {transition: transition1} : {}),
        className: className ?? '',
        bodyClassName: bodyClassName ?? '',
        isLoading: isLoading,
      });
    }
    case 'ERROR': {
      return toast.error(messageToUse, {
        toastId,
        position,
        autoClose,
        icon: icon ?? <img alt="error" src={ErrorIcon} height={28} />,
        onClick,
        ...(position === 'bottom-left' ? {transition: transition1} : {}),
        className: className ?? '',
        bodyClassName: bodyClassName ?? '',
        isLoading: isLoading,
      });
    }
    case 'LOADING': {
      return toast.loading(messageToUse, {
        toastId,
        position,
        closeOnClick: false,
        draggable: false,
        hideProgressBar: true,
        className: className ?? '',
        bodyClassName: bodyClassName ?? '',
        isLoading: isLoading,
        icon: icon ?? null,
      });
    }
    case 'UPDATE': {
      return toast.update(params.toastId, {...params});
    }
    case 'UPDATE_INFO': {
      return toast.update(toastId, {
        position,
        autoClose,
        icon: icon ?? <img alt="info" src={ProcessIcon} height={36} />,
        onClick,
        render: messageToUse,
        transition: position === 'bottom-right' ? null : transition2,
        className: className ?? '',
        bodyClassName: bodyClassName ?? '',
        isLoading: isLoading,
      });
    }
    case 'UPDATE_SUCCESS': {
      return toast.update(toastId, {
        position,
        autoClose,
        icon: icon ?? <img alt="success" src={SuccessIcon} height={32} />,
        onClick,
        render: messageToUse,
        transition: position === 'bottom-right' ? transition3 : transition2,
        className: className ?? '',
        bodyClassName: bodyClassName ?? '',
        isLoading: isLoading,
      });
    }
    case 'UPDATE_FAILURE': {
      return toast.update(toastId, {
        position,
        autoClose,
        icon: icon ?? <img alt="error" src={ErrorIcon} height={28} />,
        onClick,
        render: messageToUse,
        transition: position === 'bottom-right' ? transition3 : transition2,
        className: className ?? '',
        bodyClassName: bodyClassName ?? '',
        isLoading: isLoading,
      });
    }
    default: {
      return toast(messageToUse, {
        toastId,
        position,
        autoClose,
        onClick,
        isLoading: isLoading,
      });
    }
  }
};
const CustomToastComp = () => {
  return (
    <ToastContainer
      toastClassName="toastClassName"
      bodyClassName="bodyClassName"
      hideProgressBar
    />
  );
};

const CustomToast = React.memo(CustomToastComp);
const showCustomToast = (...args) => {
  customToastAction(...args);
};

export {showCustomToast, CustomToast};
