import {firestore} from '../../imports';

export default class BusinessDB {
  collection(firestoreRef = null) {
    return (firestoreRef ?? firestore)().collection('businessProfile');
  }

  doc(profileId, firestoreRef = null) {
    return (firestoreRef ?? firestore)()
      .collection('businessProfile')
      .doc(profileId);
  }

  getCredits(profileId, firestoreRef = null) {
    return (firestoreRef ?? firestore)()
      .collection('lioCredits')
      .doc(profileId)
      .get();
  }
}
