import React, {useState, useEffect} from 'react';
import firebase from 'firebase';
import 'firebase/messaging';
import {bindActionCreators} from 'redux';
import {connect, useDispatch} from 'react-redux';
import {BrowserRouter, useHistory} from 'react-router-dom';
import theme from '../src/utilsWeb/designTheme';
//import storage from "redux-persist/lib/storage";
import {MuiThemeProvider} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AppRoutes from './routes';
import SmallScreen from './containers/smallScreen';
import * as Sentry from '@sentry/react';
import {version} from '../package.json';
//import { purgeStoredState } from "redux-persist";
import ReactPixel from 'react-facebook-pixel';
import {Toast} from './components/common/Toast';
import isMobile from './utilsWeb/isMobile';
import LioL from './assets/img/LioL.svg';
import {
  captureError,
  initializeUtilityFunctions,
  intiateFirestore,
  logAnalyticsEvent,
  remoteConfig,
} from 'rb-redux/imports';
import {toast} from 'react-toastify';

// import remoteConfig, {
//   initRemoteConfig,
//   REMOTE_CONFIG_KEYS,
// } from './remoteConfig';
import {initRemoteConfig, REMOTE_CONFIG_KEYS} from './remoteConfig';
import {isArray, isEmpty} from 'lodash';
import {RESPONSIVE_EXCEPTION_ROUTES} from './appConstants';
import mixpanel from 'mixpanel-browser';
import {setRestrictionData} from 'rb-redux/actions/remoteConfigAction';
// import {
//   CLOUD_FUNCTION_PATHS,
//   LIO_APP,
//   LIO_AUTH_SCHEME,
// } from 'rb-redux/utils/constant';
import {getUserOrganisationInfo} from 'rb-redux/actions/organisationAction';
import {routes} from './routes/routes';
import {setNotificationParamsForRedirectionWeb} from 'rb-redux/actions/automationAction';
import {encodeStringNew, getToastStyles} from './utilsWeb/utils';
import {LIO_APP} from 'rb-redux/utils/constant';
import {COOKIES_WEB} from './utilsWeb/constant';
import {generateRandomId} from 'rb-redux/utils/RandomGenerator';
import './App.css';
import {
  CustomToast,
  showCustomToast,
} from './components/CustomToast/CustomToastWrapper';
import Toastify from 'toastify-js';
import {singularSdk, SingularConfig} from 'singular-sdk';

import firebaseApp from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/storage';

const {loadSubscriptions} = require('rb-redux/actions/premiumAction');

const {
  getAndSetUniqueDeviceId,
  updateCustomCallbacks,
} = require('rb-redux/actions/persistedDataActions');
const {
  userDocsListener,
  clearHomeStateAndDeactivateListeners,
} = require('rb-redux/actions/homeAction');

const {
  loadUserAuth,
  setUserPref,
  setUserCountry,
  logOut,
  fetchUserObjFirebase,
  setFCMToken,
} = require('rb-redux/actions/authAction');
const {setModalClose} = require('rb-redux/actions/modalAction');

const {afterLogin, findUserCountry} = require('rb-redux/utils/utils.js');

const {
  activateMiniAppsListener,
  openApp,
  changeScreen,
} = require('rb-redux/actions/miniAppsAction');

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_REPORTING_KEY,
  environment:
    process.env.REACT_APP_SENTRY_MODE === 'development'
      ? 'web-dev'
      : 'web-prod',
  release: version,
  beforeSend(event, hint) {
    return hint.originalException === 'Timeout' ? null : event;
  },
});

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: true,
  ignore_dnt: true,
});

function mixpanelInit({uid}) {
  mixpanel.identify(uid);
  mixpanel.register({
    uid,
  });
  const timeout = setTimeout(function () {
    mixpanel.track('App Session');
    clearTimeout(timeout);
  }, 10000);
}

initializeUtilityFunctions({
  toast,
  _sentry: Sentry,
  RToastify: Toastify,
  reactPixel: ReactPixel,
  RMixpanel: mixpanel,
  RSingularSdk: singularSdk,
  RSingularConfig: SingularConfig,
});

intiateFirestore({
  firebaseAppFn: firebaseApp,
  firebaseFirestoreFn: firebaseApp.firestore,
  firebaseAuthFn: firebase.auth,
  firebaseDatabaseFn: firebase.database,
  firebaseFunctionsFn: firebase.functions,
  firebaseStorageFn: firebase.storage,
});

/**
 *
 * @param {object} customClaims : from getIdToken
 * @param {*} actions : {loadSubscriptions, fetchUserObjFirebase, setRestrictionData}
 */
const processRevenueCatSubscription = (customClaims, actions) => {
  const activeEntitlementsArr = isArray(customClaims?.revenueCatEntitlements)
    ? customClaims.revenueCatEntitlements
    : [];
  const subscriptionObj = {
    entitlements: {
      active: {},
    },
  };
  activeEntitlementsArr.forEach((entitlement) => {
    subscriptionObj.entitlements.active[entitlement] = {};
  });
  Promise.all([
    actions.loadSubscriptions(subscriptionObj, {
      fromWeb: true,
    }),
    actions.fetchUserObjFirebase(), // Get User Meta
  ]).then((res) => {
    const userObj = res?.[1];
    if (!isEmpty(userObj)) {
      actions.setRestrictionData(userObj, () => {
        try {
          let applyUserRestrictions = false,
            restrictionConfig = {};

          applyUserRestrictions = remoteConfig
            .getValue(REMOTE_CONFIG_KEYS.APPLY_RESTRICTIONS)
            .asBoolean();

          const restrictionConfigString = remoteConfig
            .getValue(REMOTE_CONFIG_KEYS.RESTRICTION_CONFIG)
            .asString();

          if (restrictionConfigString?.length) {
            restrictionConfig = JSON.parse(restrictionConfigString);
          }
          return {
            applyUserRestrictions,
            restrictionConfig,
          };
        } catch (error) {
          captureError(error);
        }
      });
    }
  });
};

function App(props) {
  const history = useHistory();
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  const [isScreenSmall, setIsScreenSmall] = useState(false);
  const [requestPermissionBool, setRequestPermission] = useState(false);
  const [isOrganisationModeFetched, setIsOrganisationModeFetched] =
    React.useState(false);

  function getToken() {
    try {
      const messaging = firebase.messaging();

      messaging
        .getToken({
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        })
        .then((currentToken) => {
          if (currentToken) {
            props.actions.setFCMToken(currentToken);

            messaging.onMessage((payload) => {
              // console.log('Message received. ', payload);
              const {title, ...options} = payload.notification;
              // ShowToast(title, props.auth.userPref);
              const notificationTitle = payload.notification.title;
              const notificationOptions = {
                body: payload.notification.body,
                icon: LioL,
              };
              //below code block might be needed for android
              // navigator.serviceWorker
              //   .getRegistration('/firebase-cloud-messaging-push-scope')
              //   .then((registration) => {
              //     registration.showNotification(
              //       payload.notification.title,
              //       payload.notification,
              //     );
              //     /* eslint-disable-next-line no-restricted-globals */
              //     self.addEventListener(
              //       'notificationclick',
              //       function (event) {
              //         // console.log('registered');
              //         event.notification.close();
              //         window.open('https://youtu.be/PAvHeRGZ_lA');
              //       },
              //       false,
              //     );
              //   });
              var notification = new Notification(
                notificationTitle,
                notificationOptions,
              );
              const {origin} = window.location;
              const {miniAppId, screenId, rowId} = payload?.data ?? {};
              const redirectionURL = `${origin}/app/?appId=${encodeStringNew(
                miniAppId,
              )}&screenId=${encodeStringNew(screenId)}&rowId=${encodeStringNew(
                rowId,
              )}`;
              notification.onclick = function (event) {
                handleNotificationCardClick(payload);

                event.preventDefault(); // prevent the browser from focusing the Notification's tab
                window.open(redirectionURL, '_blank');
                notification.close();
              };
            });

            // Send the token to your server and update the UI if necessary
            // ...
          } else {
            // Show permission request UI
            // console.log(
            //   'No registration token available. Request permission to generate one.',
            // );
            // ...
          }
        })
        .catch((err) => {
          captureError(err);
          // console.log('An error occurred while retrieving token. ', err);
          // ...
        });
    } catch (err) {
      captureError(err);
    }
  }

  function requestPermission() {
    // [START messaging_request_permission]
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        // setRequestPermission(true);
        getToken();

        // console.log('Notification permission granted.');
        // TODO(developer): Retrieve a registration token for use with FCM.
        // ...
      } else {
        // console.log('Unable to get permission to notify.');
      }
    });
    // [END messaging_request_permission]
  }
  // If not empty object ->  LOGIN_SUCCESS analytics is pending
  const [isLoginSuccessAnalyticsPending, setIsLoginSuccessAnalyticsPending] =
    React.useState({});

  const dispatch = useDispatch();
  const handleNotificationCardClick = async (notificationObj) => {
    await dispatch(
      setNotificationParamsForRedirectionWeb(notificationObj?.data ?? {}),
    );
    await dispatch(
      openApp(notificationObj?.data?.miniAppId, () => {
        dispatch(changeScreen(notificationObj?.data?.screenId));
      }),
    );
    // history.push(routes.APP);
  };

  const getDeviceIdFromCookies = (deviceKey) => {
    const cookies = document.cookie;
    let deviceId = null;
    cookies.split(';').forEach(function (el) {
      let [key, value] = el.split('=');
      if (deviceKey === key.trim() && value) {
        deviceId = value;
      }
    });
    return deviceId;
  };

  const setDeviceIdOnCookies = (deviceId = null) => {
    var date = new Date();
    date.setDate(date.getDate() + 9999);
    let updatedCookies = `${
      COOKIES_WEB.DEVICE_ID
    }=${deviceId}; expires=${date.toGMTString()};`;
    document.cookie = updatedCookies;
  };

  const getUniqueDeviceId = (key) => {
    let alreadyExistsDeviceId = getDeviceIdFromCookies(key);
    if (!alreadyExistsDeviceId) {
      const deviceId = `${generateRandomId(12, 'web')}`;
      setDeviceIdOnCookies(deviceId);
      alreadyExistsDeviceId = deviceId;
    }
    return alreadyExistsDeviceId;
  };

  const AutomationToastComponent = ({automationNamesArr}) => {
    return (
      <div className="autmation-toast-container">
        {automationNamesArr?.length === 1 ? (
          <div className="automation-list-container">
            <div
              key={`aut_` + automationNamesArr?.[0]}
              className={'automation-list-item'}>
              {automationNamesArr?.[0]}
            </div>
          </div>
        ) : (
          <div className="automation-list-container">
            {automationNamesArr.map((name) => {
              return (
                <div className={'automation-list-item'} key={`aut_` + name}>
                  {name}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (requestPermissionBool) {
      getToken();
    }
  }, [requestPermissionBool]);
  useEffect(() => {
    // to initially set isModalOpen to false
    const deviceId = getUniqueDeviceId(COOKIES_WEB.DEVICE_ID);
    props.actions.setModalClose();
    props.actions.getAndSetUniqueDeviceId(deviceId);
    props.actions.updateCustomCallbacks({
      getToastInstance: () => toast,
      renderToastComponent: (text, key, className) => (
        <div key={key} className={className ?? ''}>
          {text}
        </div>
      ),
      showFormulaRowsUpdating: (toastId) => {
        toast.loading(<div>Formula Updating Rows</div>, {
          toastId,
          position: 'bottom-right',
          closeOnClick: false,
          draggable: false,
          isLoading: true,
          hideProgressBar: true,
        });
      },
      showDeleteRowsUpdating: (toastId, rowStatus) => {
        // showDeleteRowToast(toastId, rowStatus);
      },
      showCustomToastWrapper: (params) => {
        showCustomToast({
          ...params,
          ...getToastStyles(params.service, params.toastType),
        });
      },
      dismissAllToasts: () => {
        toast.dismiss();
      },
    });
  }, []);

  // This useEffect will handle when to trigger 'LOGIN_SUCCESS' analytics event
  React.useEffect(() => {
    if (
      !isEmpty(isLoginSuccessAnalyticsPending) &&
      isOrganisationModeFetched &&
      ((props.isOrganisationMode &&
        props.isOrgDataFetched &&
        props.isOrgSubscriptionsFetched) ||
        !props.isOrganisationMode)
    ) {
      logAnalyticsEvent('LOGIN_SUCCESS', isLoginSuccessAnalyticsPending);
      setIsLoginSuccessAnalyticsPending({});
    }
  }, [
    isLoginSuccessAnalyticsPending,
    props.isOrganisationMode,
    isOrganisationModeFetched,
    props.isOrgDataFetched,
    props.isOrgSubscriptionsFetched,
  ]);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const from = urlParams.get('from');
    //to check if screen is small
    if (
      isMobile() &&
      (from === LIO_APP
        ? !RESPONSIVE_EXCEPTION_ROUTES.some((route) =>
            window.location.pathname.startsWith(route),
          )
        : true) // Render <SmallScreen/> if viewing on mobile and NOT redirected from lioapp
    ) {
      setIsScreenSmall(true);
    }
  }, [setIsScreenSmall]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        if (user && user?.uid && user?.uid !== '') {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const from = urlParams.get('from');
          // const isNewUser =
          //   user.metadata.creationTime === user.metadata.lastSignInTime;

          // if (
          //   from &&
          //   from === LIO_APP &&
          //   !isNewUser &&
          //   (window.location.pathname === '/signup' ||
          //     window.location.pathname === '/login-mobile')
          // ) {
          //   let data = {};
          //   if (user.phoneNumber) {
          //     data.phoneNumber = user.phoneNumber;
          //   } else {
          //     data = {
          //       providerId: user.providerData[0].providerId,
          //       email: user.email,
          //       user: user.toJSON(),
          //     };
          //   }
          //   const response = await firebase
          //     .functions()
          //     .httpsCallable(CLOUD_FUNCTION_PATHS.GET_LOGIN_TOKEN)(data);
          //   window.location.href = `${LIO_AUTH_SCHEME}?token=${response.data}`;
          // }

          const userCountry = findUserCountry();

          await afterLogin(
            user,
            userCountry ? userCountry : 'IN',
            props.actions.loadUserAuth,
            props.actions.setUserPref,
            props.actions.setUserCountry,
          );

          const isOrganisationMode =
            await props.actions.getUserOrganisationInfo(
              firebase.auth,
              null,
              (customClaims) =>
                processRevenueCatSubscription(customClaims, {
                  loadSubscriptions: props.actions.loadSubscriptions,
                  fetchUserObjFirebase: props.actions.fetchUserObjFirebase,
                  setRestrictionData: props.actions.setRestrictionData,
                }),
            );

          setIsOrganisationModeFetched(true);

          firebase.analytics().setUserId(user?.uid);
          ReactPixel.init(
            process.env.REACT_APP_FB_SDK,
            {external_id: user?.uid},
            options,
          );
          user?.uid && mixpanelInit({uid: user.uid});
          props.actions.userDocsListener();
          initRemoteConfig();
          if (isOrganisationMode) {
            props.actions.activateMiniAppsListener(null, () => {
              window.location.replace(routes.APPS);
            });
          }

          if (from !== LIO_APP) {
            requestPermission();
          }
        }
      } else {
        props.actions.logOut();
        props.persistor.purge();
        setIsOrganisationModeFetched(false);
      }
      return () => {
        props.actions.clearHomeStateAndDeactivateListeners();
      };
    });
  }, []);

  const {
    auth: {user},
  } = props;
  const isAuthenticated = () => Boolean(user && user?.uid && user?.uid !== '');

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <CustomToast />
          <Toast />
          {isScreenSmall ? (
            <SmallScreen />
          ) : (
            <AppRoutes
              user={props.auth.user}
              authenticated={isAuthenticated()}
              isOrganisationModeFetched={isOrganisationModeFetched}
              setIsLoginSuccessAnalyticsPending={
                setIsLoginSuccessAnalyticsPending
              }
            />
          )}
        </BrowserRouter>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

function mapStateToProps(state) {
  const {
    auth,
    remoteConfig: {isOrganisationMode},
    organisation: {isOrgDataFetched, isOrgSubscriptionsFetched},
    miniApps,
  } = state;
  return {
    auth,
    isOrganisationMode,
    isOrgDataFetched,
    isOrgSubscriptionsFetched,
    miniApps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({
        loadUserAuth,
        setUserPref,
        setUserCountry,
        logOut,
        setModalClose,
        userDocsListener,
        clearHomeStateAndDeactivateListeners,
        loadSubscriptions,
        getAndSetUniqueDeviceId,
        fetchUserObjFirebase,
        setRestrictionData,
        activateMiniAppsListener,
        getUserOrganisationInfo,
        setFCMToken,
        updateCustomCallbacks,
      }),
      dispatch,
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
