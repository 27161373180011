import {isEmpty, isString} from 'lodash';
import {captureError} from '../imports';
import {getLocalText} from './utils';

export const DEFAULT_PREMIUM_DISCOUNTED_MONTHS = 2;

export const AVAILABLE_ENTITLEMENTS = {
  // PERSONAL
  UPTO_7_DASHBOARDS: 'dashboards.personal',
  UPTO_50_TABLE_LINK_VALUES: 'tableLinking.personal',
  UPTO_5_IMAGES_IN_CELL: 'imagesInCell.personal',
  AUDIO_LENGTH_5: 'audioLength.personal',
  ASSIGN_TASK_WITHOUT_WHATSAPP: 'assignTask.personal',
  UPTO_20_DOCUMENT_VERSIONS: 'version.personal',
  UPTO_2_MULTILOGIN: 'multidevice.personal',

  // BUSINESS
  SHARE_PREMIUM_UPTO_2_MEMBERS: 'team.member.business',
  AUDIO_LENGTH_15: 'audioLength15',
  ONE_MONTH_VERSION: 'oneMonthVersion',
  MULTI_DEVICE_LOGIN: 'multiDeviceLogin', // Upto 2 per shared user.

  // PRO + BUSINESS
  CUSTOM_SHARING: 'customSharing',
  ASSIGN_TASK_COLUMN: 'assignTask',
  AUDIO_LENGTH_25: 'audioLength.pro',
  AUTOMATIONS: 'automations',
  MULTI_DEVICE_LOGIN_UNLIMITED: 'multiDeviceLogin.pro', // Unlimited and same for shared user.
  SHARE_PREMIUM_UPTO_20_MEMBERS: 'team.member.pro',
  TABLE_LINKING_COLUMN: 'tableLinking',
  UNLIMITED_DASHBOARDS: 'unlimitedDasboards',
  UNLIMITED_IMAGES_IN_CELL: 'unlimitedImagesInCell',
  UPTO_100_DOCUMENT_VERSIONS: 'version.pro',

  FLIPKART_INTEGRATION: 'integrations',
  SMART_TEMPLATES: 'smartTemplates',
  REMINDER_PREMIUM: 'reminderPremium',

  // NOT YET IMPLEMENTED
  // COLUMN_WISE_SHARING: 'columnWiseSharing',
  // ROW_EDIT_HISTORY: 'rowEditHistory',
  // WHATS_APP_CREDIT_10_PER_MONTH: 'whatsappCredit.personal', // personal
  // WHATS_APP_CREDIT_30_PER_MONTH: 'whatsappCredit.business', // business
  // WHATS_APP_CREDIT_100_PER_MONTH: 'whatsappCredit.pro', // business pro

  // COMMON
  NO_PDF_WATERMARK: 'noPDFWaterMark',
};

const PERSONAL_PLAN_ENTITLEMENTS = [
  AVAILABLE_ENTITLEMENTS.UPTO_7_DASHBOARDS,
  AVAILABLE_ENTITLEMENTS.UPTO_50_TABLE_LINK_VALUES,
  AVAILABLE_ENTITLEMENTS.UPTO_5_IMAGES_IN_CELL,
  AVAILABLE_ENTITLEMENTS.AUDIO_LENGTH_5,
  AVAILABLE_ENTITLEMENTS.ASSIGN_TASK_WITHOUT_WHATSAPP,
  AVAILABLE_ENTITLEMENTS.UPTO_20_DOCUMENT_VERSIONS,
  AVAILABLE_ENTITLEMENTS.UPTO_2_MULTILOGIN,
  AVAILABLE_ENTITLEMENTS.NO_PDF_WATERMARK,
  AVAILABLE_ENTITLEMENTS.REMINDER_PREMIUM,
];

const BUSINESS_PLAN_ENTITLEMENTS = [
  AVAILABLE_ENTITLEMENTS.AUTOMATIONS,
  AVAILABLE_ENTITLEMENTS.UNLIMITED_DASHBOARDS,
  AVAILABLE_ENTITLEMENTS.TABLE_LINKING_COLUMN,
  AVAILABLE_ENTITLEMENTS.SHARE_PREMIUM_UPTO_2_MEMBERS,
  AVAILABLE_ENTITLEMENTS.UNLIMITED_IMAGES_IN_CELL,
  AVAILABLE_ENTITLEMENTS.AUDIO_LENGTH_15,
  AVAILABLE_ENTITLEMENTS.ASSIGN_TASK_COLUMN,
  AVAILABLE_ENTITLEMENTS.ONE_MONTH_VERSION,
  AVAILABLE_ENTITLEMENTS.MULTI_DEVICE_LOGIN,
  AVAILABLE_ENTITLEMENTS.NO_PDF_WATERMARK,
  AVAILABLE_ENTITLEMENTS.REMINDER_PREMIUM,
  AVAILABLE_ENTITLEMENTS.CUSTOM_SHARING,
];

const BUSINESS_PRO_PLAN_ENTITLEMENTS = [
  AVAILABLE_ENTITLEMENTS.AUTOMATIONS,
  AVAILABLE_ENTITLEMENTS.UNLIMITED_DASHBOARDS,
  AVAILABLE_ENTITLEMENTS.TABLE_LINKING_COLUMN,
  AVAILABLE_ENTITLEMENTS.SHARE_PREMIUM_UPTO_20_MEMBERS,
  AVAILABLE_ENTITLEMENTS.UNLIMITED_IMAGES_IN_CELL,
  AVAILABLE_ENTITLEMENTS.AUDIO_LENGTH_25,
  AVAILABLE_ENTITLEMENTS.ASSIGN_TASK_COLUMN,
  AVAILABLE_ENTITLEMENTS.UPTO_100_DOCUMENT_VERSIONS,
  AVAILABLE_ENTITLEMENTS.MULTI_DEVICE_LOGIN_UNLIMITED,
  AVAILABLE_ENTITLEMENTS.NO_PDF_WATERMARK,
  AVAILABLE_ENTITLEMENTS.REMINDER_PREMIUM,
  AVAILABLE_ENTITLEMENTS.CUSTOM_SHARING,
];

const PREMIUM_GROUPS = {
  PREMIUM_AUG_22: 'premium.aug.22',
  BUSINESS_ONLY_DISCOUNTED_6_YEARLY: 'business.dicounted.6.yearly',
  ONLY_BUSINESS_PLAN_6_DISCOUNTED: 'onlyBusinessPlan',
};

export const PREMIUM_NEW_OFFERING = {
  GROUP: 'premium.aug.22',
  IDENTIFIERS: [
    'businessPro.499.yearly', // Priority - 1
    'businessPro.499.monthly', // Priority - 2
    'business.1990.yearly', // Priority - 3
    'business.199.monthly', // Priority - 4
    'personal.990.yearly', // Priority - 5
    'personal.99.monthly', // Priority - 6
  ],
  ENTITLEMENTS: {
    personal: PERSONAL_PLAN_ENTITLEMENTS,
    business: BUSINESS_PLAN_ENTITLEMENTS,
    businessPro: BUSINESS_PRO_PLAN_ENTITLEMENTS,
  },
};

export const PREMIUM_OLD_OFFERING = {
  GROUP: 'current',
  IDENTIFIERS: [
    'monthly.premium.199',
    'yearly.premium.1990',
    'monthly.premium.ios.199',
    'yearly.premium.ios.1999',
  ],
  ENTITLEMENTS: {
    personal: PERSONAL_PLAN_ENTITLEMENTS,
    business: BUSINESS_PLAN_ENTITLEMENTS,
    businessPro: BUSINESS_PRO_PLAN_ENTITLEMENTS,
  },
};

/**
 * !IMP : update-on-cloud-function-too
 */
//not to be used anymore
export const ENTITLEMENTS = {
  AUTOMATIONS: 'automations',
  FLIPKART_INTEGRATION: 'integrations',
  UNLIMITED_DASHBOARDS: 'unlimitedDasboards',
  NO_PDF_WATERMARK: 'noPDFWaterMark',
  AUDIO_LENGTH_15: 'audioLength15',
  UNLIMITED_IMAGES_IN_CELL: 'unlimitedImagesInCell',
  SMART_TEMPLATES: 'smartTemplates',
  MULTI_DEVICE_LOGIN: 'multiDeviceLogin',
  ONE_MONTH_VERSION: 'oneMonthVersion',
  TABLE_LINKING_COLUMN: 'tableLinking',
  ASSIGN_TASK_COLUMN: 'assignTask',
  REMINDER_PREMIUM: 'reminderPremium',
};

export const FREE_SUBSCRIPTIONS = {
  TABLE_LINKING_MAX_VALUES: 5,
};
export const PERSONAL_PLAN_LIMITS = {
  TABLE_LINKING_MAX_VALUES: 50,
};

export const FREE_TRIAL_ENTITLEMENT = 'premium.trial';

export const PLAN_MONTLY_IDENTYFY_KEY = '.monthly';
export const PLAN_YEARLY_IDENTYFY_KEY = '.yearly';

export const IS_SHARED_PLAN_IDENTIFY_KEY = '.member';

export const PERSONAL_PLAN_IDENTIFY_KEY = 'personal.';
export const BUSINESS_PLAN_IDENTIFY_KEY = 'business.';
/**
 * For business plan when checking in "product.identifier"
 * please user "toLowerCase" function.
 */
export const BUSINESS_PRO_PLAN_IDENTIFY_KEY = 'businessPro.';

export const PLANS_PRIORITY = [
  BUSINESS_PRO_PLAN_IDENTIFY_KEY,
  BUSINESS_PLAN_IDENTIFY_KEY,
  PERSONAL_PLAN_IDENTIFY_KEY,
];

export const PROMO_PREFIX = 'rc_promo';

export const TRIAL_DURATION = '7 Days';

export const LAST_ACTIVE_PLAN = {
  FREE: 'FREE',
  PAID: 'PAID',
};

export const PREMIUM_PLAN_TYPES = {
  PERSONAL: 'personal',
  BUSINESS: 'business',
  BUSINESS_PRO: 'businessPro',
  OLD_PREMIUM: 'premium_user',
  NON_PREMIUM: 'non_premium',
  TRIAL: FREE_TRIAL_ENTITLEMENT,
};

export const PREMIUM_PACKAGE_ENTITLEMENTS = [
  {
    name: 'Automation',
    description:
      'Smart automation includes auto-messages, reminders and many exciting features.',
    availability: {
      personal: false,
      business: true,
      businessPro: true,
    },
  },
  {
    name: 'Dashboards',
    description: 'Create a summary of your files for quick information.',
    availability: {
      personal: 'UPTO_STRING 7',
      business: 'Unlimited',
      businessPro: 'Unlimited',
    },
  },
  {
    name: 'Table Linking',
    description: 'Create interlinking between the tables.',
    availability: {
      personal: 'UPTO_STRING 50 VALUES_STRING',
      business: 'Unlimited',
      businessPro: 'Unlimited',
    },
  },
  {
    name: 'Premium Sharing',
    description: 'Keep billing at one place, and share premium with your team.',
    availability: {
      personal: '0',
      business: '2 ADDITIONAL_STRING',
      businessPro: '20 MEMBERS_STRING',
    },
  },
  {
    name: 'Images in a cell',
    description: 'Add multiple images in a cell.',
    availability: {
      personal: '5',
      business: 'Unlimited',
      businessPro: 'Unlimited',
    },
  },
  {
    name: 'Audio Length',
    description: 'Add longer audio notes in your table.',
    availability: {
      personal: '5 MINS_STRING',
      business: '15 MINS_STRING',
      businessPro: '25 MINS_STRING',
    },
  },
  {
    name: 'Task Assignment',
    description:
      'Assign tasks, receive notifications, keep everyone responsible.',
    availability: {
      personal: 'Yes (No WhatsApp reminder)',
      business: 'Yes',
      businessPro: 'Yes',
    },
  },
  // {
  //   name: 'WhatsApp Credit/Month',
  //   description: 'additional credit',
  //   availability: {
  //     personal: '10 - (INR 0.80)',
  //     business: '30 - (INR 0.70)',
  //     businessPro: '100 - (INR 0.60)',
  //   },
  // },
  {
    name: 'Versions',
    description: 'Automatic version creation as you edit your document.',
    availability: {
      personal: '20',
      business: '50',
      businessPro: '100',
    },
  },
  {
    name: 'Multi Device login',
    description: "Login to multiple devices, whether it's web or mobile.",
    availability: {
      personal: 'Upto 2 devices',
      business: '2 DEVICE_USER_STRING',
      businessPro: 'Unlimited',
    },
  },
  {
    name: 'Column wise sharing',
    description: 'Choose which column user can view/edit in a document.',
    availability: {
      personal: false,
      business: true,
      businessPro: true,
    },
  },
  // {
  //   name: 'Row edit history',
  //   description: 'History of each edit made in a row.',
  //   availability: {
  //     personal: false,
  //     business: true,
  //     businessPro: true,
  //   },
  // },
  {
    name: 'No Watermark on PDF',
    description: 'Remove the Lio watermark on the PDFs.',
    availability: {
      personal: true,
      business: true,
      businessPro: true,
    },
  },
];

export const getPlanTypeFromIdentifier = (identifier) => {
  const str = (identifier ?? '').toLowerCase();

  return str.includes(PERSONAL_PLAN_IDENTIFY_KEY.toLowerCase())
    ? PREMIUM_PLAN_TYPES.PERSONAL
    : str.includes(BUSINESS_PLAN_IDENTIFY_KEY.toLowerCase())
    ? PREMIUM_PLAN_TYPES.BUSINESS
    : str.includes(BUSINESS_PRO_PLAN_IDENTIFY_KEY.toLowerCase())
    ? PREMIUM_PLAN_TYPES.BUSINESS_PRO
    : '';
};

export const getNewPremiumPlanDuration = (identifier, userPref) => {
  const str = identifier ?? '';
  return str.includes(PLAN_MONTLY_IDENTYFY_KEY)
    ? getLocalText(userPref, 'Month')
    : str.includes(PLAN_YEARLY_IDENTYFY_KEY)
    ? getLocalText(userPref, 'Year')
    : '';
};

export const newMonthlyPackages = (item) => {
  const identifier = (item?.product?.identifier ?? '').toLowerCase();
  return (
    identifier.includes(PLAN_MONTLY_IDENTYFY_KEY) &&
    (identifier.includes(PERSONAL_PLAN_IDENTIFY_KEY) ||
      identifier.includes(BUSINESS_PLAN_IDENTIFY_KEY) ||
      identifier.includes(BUSINESS_PRO_PLAN_IDENTIFY_KEY.toLowerCase()))
  );
};

export const newYearlyPackages = (item) => {
  const identifier = (item?.product?.identifier ?? '').toLowerCase();
  return (
    identifier.includes(PLAN_YEARLY_IDENTYFY_KEY) &&
    (identifier.includes(PERSONAL_PLAN_IDENTIFY_KEY) ||
      identifier.includes(BUSINESS_PLAN_IDENTIFY_KEY) ||
      identifier.includes(BUSINESS_PRO_PLAN_IDENTIFY_KEY.toLowerCase()))
  );
};

export const premiumLowerToHigherPackages = (currentPack, nextPack) => {
  const currentIdentifier = (
    currentPack?.product?.identifier ?? ''
  ).toLowerCase();
  const nextIdentifier = (nextPack?.product?.identifier ?? '').toLowerCase();
  const isCurrentPersonal = currentIdentifier.includes(
    PERSONAL_PLAN_IDENTIFY_KEY,
  );

  const isCurrentBusiness = currentIdentifier.includes(
    BUSINESS_PLAN_IDENTIFY_KEY,
  );

  const isNextBusiness = nextIdentifier.includes(BUSINESS_PLAN_IDENTIFY_KEY);

  const isNextBusinessPro = nextIdentifier.includes(
    BUSINESS_PRO_PLAN_IDENTIFY_KEY.toLowerCase(),
  );

  if (
    (isCurrentPersonal && (isNextBusiness || isNextBusinessPro)) ||
    (isCurrentBusiness && isNextBusinessPro)
  ) {
    return -1;
  }
  return 0;
};

export const getPremiumPlanWiseMessage = (
  planType,
  messageType,
  options = {},
) => {
  let message = '';
  const {isAttachment = false} = options ?? {};
  switch (messageType) {
    case AVAILABLE_ENTITLEMENTS.UPTO_7_DASHBOARDS:
      if (planType === PREMIUM_PLAN_TYPES.PERSONAL) {
        message =
          'Upgrade to Business Plan to add unlimited dashboards. Your Current plan supports only MAX_DASHBOARD_COUNT dashboards.';
      }
      break;

    case AVAILABLE_ENTITLEMENTS.UPTO_50_TABLE_LINK_VALUES:
      if (planType === PREMIUM_PLAN_TYPES.PERSONAL) {
        message = 'Upgrade to Business Plan to view all table-link values.';
      }
      break;

    case AVAILABLE_ENTITLEMENTS.UPTO_5_IMAGES_IN_CELL:
      if (planType === PREMIUM_PLAN_TYPES.PERSONAL) {
        message = isAttachment
          ? 'Maximum MAX_LIMIT attachments can be uploaded in a cell. Upgrade to Business Plan to add unlimited attachments in cell.'
          : 'Maximum MAX_LIMIT images can be uploaded in a cell. Upgrade to Business Plan to add unlimited images in cell.';
      }
      break;

    case AVAILABLE_ENTITLEMENTS.AUDIO_LENGTH_5:
      if (planType === PREMIUM_PLAN_TYPES.PERSONAL) {
        message =
          'Upgrade to Business Plan to record audio upto MAX_DURATION minutes.';
      }
      break;

    case AVAILABLE_ENTITLEMENTS.ASSIGN_TASK_WITHOUT_WHATSAPP:
      if (planType === PREMIUM_PLAN_TYPES.PERSONAL) {
        message =
          'Upgrade to Business Plan to send whatsapp message(s) on assigning task to a number.';
      }
      break;

    case AVAILABLE_ENTITLEMENTS.UPTO_20_DOCUMENT_VERSIONS:
      if (planType === PREMIUM_PLAN_TYPES.PERSONAL) {
        message =
          'Upgrade to Business Plan to access upto MAX_VERSION_COUNT version history for the document.';
      }
      break;

    case AVAILABLE_ENTITLEMENTS.UPTO_2_MULTILOGIN:
      if (planType === PREMIUM_PLAN_TYPES.PERSONAL) {
        message =
          'Upgrade to Business Plan to get unlimited login. Plus upto 2 multi login/shared user.';
      }
      break;

    case AVAILABLE_ENTITLEMENTS.NO_PDF_WATERMARK:
      break;

    case AVAILABLE_ENTITLEMENTS.REMINDER_PREMIUM:
      break;

    case AVAILABLE_ENTITLEMENTS.SHARE_PREMIUM_UPTO_2_MEMBERS:
      if (planType === PREMIUM_PLAN_TYPES.BUSINESS) {
        message = 'Upgrade to Pro Plan to share premium with upto 20 members.';
      }
      break;

    case AVAILABLE_ENTITLEMENTS.AUDIO_LENGTH_15:
      if (planType === PREMIUM_PLAN_TYPES.BUSINESS) {
        message =
          'Upgrade to Pro Plan to record audio upto MAX_DURATION minutes.';
      }
      break;

    case AVAILABLE_ENTITLEMENTS.ONE_MONTH_VERSION:
      if (planType === PREMIUM_PLAN_TYPES.BUSINESS) {
        message =
          'Upgrade to Business Plan to access upto MAX_VERSION_COUNT version history for the document.';
      }
      break;

    case AVAILABLE_ENTITLEMENTS.MULTI_DEVICE_LOGIN:
      if (planType === PREMIUM_PLAN_TYPES.BUSINESS) {
        message =
          'Upgrade to Pro Plan to get unlimited login. And same for shared user(s).';
      }
      break;

    case AVAILABLE_ENTITLEMENTS.ASSIGN_TASK_COLUMN:
      break;

    case AVAILABLE_ENTITLEMENTS.AUDIO_LENGTH_25:
      break;

    case AVAILABLE_ENTITLEMENTS.AUTOMATIONS:
      if (planType === PREMIUM_PLAN_TYPES.PERSONAL) {
        message =
          'Upgrade to Business Plan to get access auto-reports and more exciting features';
      }
      break;

    case AVAILABLE_ENTITLEMENTS.MULTI_DEVICE_LOGIN_UNLIMITED:
      break;

    case AVAILABLE_ENTITLEMENTS.SHARE_PREMIUM_UPTO_20_MEMBERS:
      break;

    case AVAILABLE_ENTITLEMENTS.TABLE_LINKING_COLUMN:
      break;

    case AVAILABLE_ENTITLEMENTS.UNLIMITED_DASHBOARDS:
      break;

    case AVAILABLE_ENTITLEMENTS.UNLIMITED_IMAGES_IN_CELL:
      break;

    case AVAILABLE_ENTITLEMENTS.UPTO_100_DOCUMENT_VERSIONS:
      break;
  }

  return message;
};

export const getAllowedUsersFromPlanType = (planType) =>
  isString(planType)
    ? planType === PREMIUM_PLAN_TYPES.PERSONAL
      ? '1 User'
      : planType === PREMIUM_PLAN_TYPES.BUSINESS
      ? 'upto 3 Users'
      : planType === PREMIUM_PLAN_TYPES.BUSINESS_PRO
      ? 'upto 20 Users'
      : ''
    : '';

export const getNewPlanNameFromPlanType = (productPlanType, withIcon = false) =>
  isString(productPlanType)
    ? productPlanType === PREMIUM_PLAN_TYPES.PERSONAL
      ? 'Personal' + (withIcon ? ' 🎈' : '')
      : productPlanType === PREMIUM_PLAN_TYPES.BUSINESS
      ? 'Business' + (withIcon ? ' 💼' : '')
      : productPlanType === PREMIUM_PLAN_TYPES.BUSINESS_PRO
      ? 'Pro'
      : ''
    : '';

export const getNewPlanDurationFromIdentifier = (identifier) =>
  identifier.includes(PLAN_MONTLY_IDENTYFY_KEY)
    ? 'Monthly'
    : identifier.includes(PLAN_YEARLY_IDENTYFY_KEY)
    ? 'Annual'
    : '';

export const getPriceCurrenyForPremium = (priceString, isSign = false) => {
  try {
    const purchaseCurrency =
      (priceString || '').replace(/[0-9|+|-|.|,| ]/g, '') || '';
    return isSign && purchaseCurrency === '₹' ? 'INR' : purchaseCurrency;
  } catch (error) {
    captureError(error);
  }
  return '';
};

export const getNewAnalyticsObjectFromPackage = (packageObject, source) => {
  const price = packageObject?.product?.price ?? '';
  const selectedPlanCurrency = packageObject?.product?.currencyCode ?? '';

  const identifier = packageObject?.product?.identifier ?? '';

  const planType = getPlanTypeFromIdentifier(identifier); //personal/business/businessPro

  const planName = getNewPlanNameFromPlanType(planType);

  const duration = getNewPlanDurationFromIdentifier(identifier);

  return {
    source: source ?? '',
    selectedPlanType: duration,
    selectedPlanName: planName,
    selectedPlanAmount: price,
    selectedPlanCurrency,
  };
};

export const getPlanShareMemberCount = (
  activeEntitlements,
  subscriptions,
  isUserHavingPaidSubscription = false,
  isSharedUserPlan = true,
) => {
  try {
    if (!isUserHavingPaidSubscription || isSharedUserPlan) {
      return 0;
    }
    const PREMIUM_SHARING = {
      PREFIX: 'additional.shared.',
      SUFFIX: '.users',
    };
    const currCount = subscriptions.includes(
      AVAILABLE_ENTITLEMENTS.SHARE_PREMIUM_UPTO_20_MEMBERS,
    )
      ? 20
      : subscriptions.includes(
          AVAILABLE_ENTITLEMENTS.SHARE_PREMIUM_UPTO_2_MEMBERS,
        )
      ? 2
      : 0;
    const planShareEntitlement = Object.keys(activeEntitlements).find(
      (entitlement) =>
        entitlement.startsWith(PREMIUM_SHARING.PREFIX) &&
        entitlement.endsWith(PREMIUM_SHARING.SUFFIX),
    );
    if (planShareEntitlement?.length) {
      const count = parseInt(
        planShareEntitlement
          .replace(PREMIUM_SHARING.PREFIX, '')
          .replace(PREMIUM_SHARING.SUFFIX, ''),
      );
      if (count && !isNaN(count)) {
        return currCount + count;
      }
    }
    return currCount;
  } catch (err) {
    captureError(err);
    return 0;
  }
};

export const getPremiumPlanDisplayDetails = ({
  packageObj,
  offers,
  userPref,
}) => {
  const {
    product: {
      priceString,
      price, //actual price at which user will purchase this product
      identifier,
      currencyCode,
    },
    packageType,
  } = packageObj;

  const currency = getPriceCurrenyForPremium(priceString);
  const offer = Object.assign({}, offers?.[identifier]);
  const planType = getPlanTypeFromIdentifier(identifier);
  const planName = getNewPlanNameFromPlanType(planType);
  const allowedUsers = getAllowedUsersFromPlanType(planType);

  let yearlyBillingAmount =
    userPref?.country === 'IN' ? Math.floor(price / 12) : price / 12;
  yearlyBillingAmount = yearlyBillingAmount.toFixed(2);

  const updatedPriceString = parseFloat(price).toFixed(2);

  /** Discount Calculation */
  const parsedDiscount = !isEmpty(offer.discount)
    ? parseFloat(
        currencyCode?.length && !isNaN(offer.discount[currencyCode])
          ? offer.discount[currencyCode]
          : !isNaN(offer.discount.ALL)
          ? offer.discount.ALL
          : 0,
      )
    : 0;
  const discount =
    parsedDiscount > 0 && parsedDiscount < 100 ? parsedDiscount : 0;
  const originalPrice =
    discount > 0 ? Math.round(price / (1 - discount / 100)).toFixed(2) : null;

  /** Display Details */
  const originalPriceString = originalPrice
    ? `${currency}${originalPrice}`
    : null;

  const perDurationText = getNewPremiumPlanDuration(identifier, userPref);

  return {
    originalPriceString,
    perDurationText,
    planName,
    allowedUsers,
    updatedPriceString,
    yearlyBillingAmount,
    identifier,
    currency,
    planType,
  };
};

export const getAvailablePackages = (userOfferingGroup, offerings) => {
  const DEFAULT_OFFERING_GROUP =
    PREMIUM_GROUPS.BUSINESS_ONLY_DISCOUNTED_6_YEARLY; //default offering
  const getPackages = (group) => {
    const packages = offerings?.all?.[`${group}`]?.availablePackages;
    return Array.isArray(packages) ? packages : [];
  };

  let usedOfferingGroup = DEFAULT_OFFERING_GROUP;

  const packages = (() => {
    const userOfferingsPackages = getPackages(userOfferingGroup);
    if (userOfferingsPackages.length) {
      usedOfferingGroup = userOfferingGroup;
      return userOfferingsPackages;
    }
    return getPackages(usedOfferingGroup);
  })();

  const monthlyPackages = packages
    .filter(newMonthlyPackages)
    .sort(premiumLowerToHigherPackages);

  const annualPackages = packages
    .filter(newYearlyPackages)
    .sort(premiumLowerToHigherPackages);

  return {
    packages,
    monthlyPackages,
    annualPackages,
    offeringPromoText:
      offerings?.all?.[`${usedOfferingGroup}`]?.serverDescription ?? '',
  };
};
