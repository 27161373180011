import {firestore} from '../../imports';

/**---------------- MiniAppIntegrations Refs ----------------*/
export default class MiniAppIntegrationsDB {
  generateId(firestoreRef = null) {
    const documentRef = (firestoreRef ?? firestore)()
      .collection('miniAppIntegrations')
      .doc();
    return documentRef.id;
  }

  doc(documentId, firestoreRef = null) {
    return (firestoreRef ?? firestore)()
      .collection('miniAppIntegrations')
      .doc(documentId);
  }

  create(data, firestoreRef = null) {
    const docId = data.id;
    delete data.id;
    return (firestoreRef ?? firestore)()
      .collection('miniAppIntegrations')
      .doc(docId)
      .set(data);
  }

  update(data, firestoreRef = null) {
    const docId = data.id;
    delete data.id;
    return (firestoreRef ?? firestore)()
      .collection('miniAppIntegrations')
      .doc(docId)
      .update(data);
  }

  delete(docId, firestoreRef = null) {
    return (firestoreRef ?? firestore)()
      .collection('miniAppIntegrations')
      .doc(docId)
      .delete();
  }
}
