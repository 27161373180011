import {isEmpty} from 'lodash';
import {firestore, captureError} from '../../imports';

const setRestrictionDataFirestore = (remoteConfigObj, uid) => {
  try {
    if (!isEmpty(remoteConfigObj)) {
      firestore().collection('users').doc(uid).set(
        {
          applyUserRestrictions_v2: remoteConfigObj.applyUserRestrictions,
          restrictionConfig: remoteConfigObj.restrictionConfig,
        },
        {merge: true},
      );
    }
  } catch (error) {
    captureError(error);
  }
};

export {setRestrictionDataFirestore};
