/* eslint-disable eqeqeq */
/**
 *
 *
 * !IMP : Any change in any of below functions should also be made in cloud-functions if that function is used there
 *
 *
 */

import {
  checkIfConditionIsTrue,
  checkIfPlainText,
  getColumnFieldType,
  getDateTimePickType,
  serializeError,
} from './utils';
import {captureError, captureInfo, getReduxState} from '../imports';
import {
  checkAndUpdateDashboard,
  getDashboardFormattedVal,
} from '../actions/actionHelpers/dashboardActionHelper';
import {
  NUMBER_SORT_FIELD,
  FIELD_TYPE_ID,
  INVALID_INPUT,
  FOOTER_OPERATION_TYPES,
  DIV_ERR,
  SHARE_PERMISSION_TYPE,
  COLUMN_PROPERTY_KEYS,
  DATE_TIME_CALCULATION_TYPES,
  MINI_APPS_DATE_RECURRING_TYPES,
  MINI_APPS,
  DATE_FORMATS,
} from './constant';
import {
  forIn,
  isEmpty,
  isEqual,
  isNil,
  isPlainObject,
  forOwn,
  omit,
  isInteger,
} from 'lodash';
import moment from 'moment';
import {checkIfCellHasTextualData, getDateTimeCellObj} from '../utils/utils';
import {checkAndUpdatePrimaryColumnMapping} from './tableLinkUtils';
import ColumnUtility from './ColumnUtility';
import {evaluate} from 'mathjs';

const checkAndGetOriginalHeaderData = () => {
  const {home, table} = getReduxState();
  const isCustomPermission =
    home.activeDocumentMeta?.collab?.permission ===
    SHARE_PERMISSION_TYPE.CUSTOM;
  return isCustomPermission && table.originalHeaderData?.length
    ? table.originalHeaderData
    : null;
};

/**
 * function to calculate totalObj(total,count,invalidValsCount) of a column
 */
const calculateTotal = (
  tableData,
  colId,
  headerData,
  checkForDateTimeDiff = true,
) => {
  /**
   * checkForDateTimeDiff : (set false) when we are sure that this footer is not for
   *                        any of the date-time diff column [Optimization]
   */
  const originalHeaderData = checkAndGetOriginalHeaderData();
  if (originalHeaderData) {
    headerData = originalHeaderData;
  }
  let rowCount = tableData.length;
  let invalidCount = 0;
  if (checkForDateTimeDiff) {
    const obj = Object.assign(
      {},
      headerData.find((headerObj) => `${headerObj.id}` === `${colId}`),
    );
    if (isEqnColFormulaWithDateOrTime(obj)) {
      //check if current total of colId provided is for date-time diff
      const getUnitTotal = (isTime = false) => {
        let unitTotal = 0;
        for (let i = 0; i < tableData.length; ++i) {
          const valObj = Object.assign({}, tableData[i]?.[colId]?.val);
          if (
            isPlainObject(valObj) &&
            !isEmpty(valObj) &&
            !(valObj.err === true && valObj.val === '')
          ) {
            const unit = Number(parseInt(valObj[isTime ? 'time' : 'duration']));
            if (valObj.err === false && !isNaN(unit)) {
              unitTotal += unit;
            } else {
              ++invalidCount;
            }
          } else {
            --rowCount;
          }
        }
        return unitTotal;
      };
      if (obj.subType === FIELD_TYPE_ID.DATE) {
        const duration = getUnitTotal(false);
        let swap = false;
        const now = moment();
        let start = moment(now).add(duration);
        let end = moment(now);
        if (end > start) {
          [start, end] = [end, start];
          swap = true;
        }
        const values = dateDiff(start, end);
        return {
          total: {...values, duration, err: false, ago: swap},
          count: rowCount,
          invalidCount,
        };
      } else if (obj.subType === FIELD_TYPE_ID.TIME) {
        const time = getUnitTotal(true);
        const now = moment();
        const start = moment(now).add(time, 'minutes');
        const end = moment(now);
        const values = dateDiff(start, end, true);
        return {
          total: {...values, err: false, time, isTime: true},
          count: rowCount,
          invalidCount,
        };
      }
    }
  }
  let solvableEqn = '0';
  for (let i = 0; i < tableData.length; i++) {
    const rowObj = Object.assign({}, tableData[i]);
    const cellValue = rowObj[colId]?.val;
    if (checkIfCellHasTextualData(cellValue)) {
      if (!isNaN(cellValue)) {
        solvableEqn += `+(${Number(cellValue)})`;
        if (i % 19 === 0) {
          //solve equation after every 20th operand in the equation
          solvableEqn = `${solveEqnStr(solvableEqn)}`;
        }
      } else {
        ++invalidCount;
      }
    } else {
      --rowCount;
    }
  }
  return {
    total: solveEqnStr(solvableEqn),
    count: rowCount,
    invalidCount,
  };
};

/**
 * function to calculate split by total of a column
 */
const calculateSplitByTotal = (tableData, dashboardColId, splitByObj) => {
  try {
    const splitByTotalObj = {};
    for (let i = 0; i < tableData.length; i++) {
      const rowObj = tableData[i];
      const val = getDashboardFormattedVal(splitByObj, rowObj);
      if (isNil(val) || val === '') {
        continue;
      }

      if (isEmpty(splitByTotalObj[val])) {
        splitByTotalObj[val] = {
          total: 0,
          count: 0,
          invalidCount: 0,
        };
      }
      ++splitByTotalObj[val].count;
      const dashboardCellVal = rowObj[dashboardColId]?.val;
      if (checkIfCellHasTextualData(dashboardCellVal)) {
        if (!isNaN(dashboardCellVal)) {
          splitByTotalObj[val].total = solveEqnStr(
            `${splitByTotalObj[val].total}+(${Number(dashboardCellVal)})`,
          );
        } else {
          ++splitByTotalObj[val].invalidCount;
        }
      }
    }
    return splitByTotalObj;
  } catch (err) {
    captureError(err);
    return {};
  }
};

/**
 * function to calculate date or time difference between two dates or times
 */
const dateDiff = (date1, date2, timeReq = false) => {
  let years = date1.diff(date2, 'year');
  date2.add(years, 'years');

  let months = date1.diff(date2, 'months');
  date2.add(months, 'months');

  let days = date1.diff(date2, 'days');
  date2.add(days, 'days');

  let hours = date1.diff(date2, 'hours');
  date2.add(hours, 'hours');

  let minutes = date1.diff(date2, 'minutes');
  date2.add(minutes, 'minutes');

  if (hours === 23 && minutes === 59) {
    ++days;
    hours = 0;
    minutes = 0;
    if (days === 31) {
      days = 0;
      ++months;
      if (months === 12) {
        months = 0;
        ++years;
      }
    }
  }
  if (timeReq) {
    return {
      years,
      months,
      days,
      hours,
      minutes,
    };
  }
  return {years, months, days, hours};
};

/**
 * function to solve a date or time difference equation
 */
const solveDateTimeEqn = (
  valOne,
  valTwo,
  isTimeDiff = false,
  valOneFormatted = false,
  valTwoFormatted = false,
) => {
  /**
   * Date-Time Difference Return Obj
   * {
   *    err : boolean,(true when output either "" or INVALID)
   *    val : string,(output when err==true)
   *    minutes : int,(min in output when err==false)
   *    hours : int,(hours in output when err==false)
   *    days,months,years : int,(days,months,years in output when err==false)
   *    time : int,(difference in minutes for "TIME-diff-column-only")
   *    duration : timestamp,(diff in timestamp for "DATE-diff-column-only")
   *    ago : boolean,(whether a<b for a-b)
   * }
   */
  if (!(isNil(valOne) || isNil(valTwo) || valOne == '' || valTwo == '')) {
    try {
      if (!moment(valOne).isValid() || !moment(valTwo).isValid()) {
        //invalid-input(timestamp)
        return {err: true, val: INVALID_INPUT};
      }
      let result = {};
      if (isTimeDiff) {
        let minutesInValOne = 0,
          minutesInValTwo = 0;
        if (valOneFormatted) {
          minutesInValOne = valOne.time ?? 0;
        } else {
          valOne = moment.unix(valOne).format('HH:mm');
          minutesInValOne =
            parseInt(valOne.substring(0, 2)) * 60 +
            parseInt(valOne.substring(3, 5));
        }

        if (valTwoFormatted) {
          minutesInValTwo = valTwo.time ?? 0;
        } else {
          valTwo = moment.unix(valTwo).format('HH:mm');
          minutesInValTwo =
            parseInt(valTwo.substring(0, 2)) * 60 +
            parseInt(valTwo.substring(3, 5));
        }
        const diff = minutesInValOne - minutesInValTwo;
        const hours =
          diff > 0
            ? parseInt(diff / 60) % 24
            : (24 - Math.ceil(-diff / 60)) % 24;
        const minutes = diff > 0 ? diff % 60 : (60 - (-diff % 60)) % 60;
        const time = hours * 60 + minutes; //diff in min for showTotal
        result = {hours, minutes, time, err: false};
      } else {
        valOne = valOneFormatted ? moment(valOne) : moment.unix(valOne);
        valTwo = valTwoFormatted ? moment(valTwo) : moment.unix(valTwo);
        let swap = false;
        if (valTwo > valOne) {
          //swap
          [valOne, valTwo] = [valTwo, valOne];
          swap = true;
        }
        let duration = valOne.diff(valTwo); //diff as timestamp for showTotal
        duration = swap ? -duration : duration;
        const values = dateDiff(valOne, valTwo);
        result = {...values, duration, err: false, ago: swap};
      }
      return result;
    } catch (err) {
      return {err: true, val: INVALID_INPUT};
    }
  } else {
    return {err: true, val: ''};
  }
};

/**
 * function to calculate time multiply with constant
 */
const solveTimeNumMultiply = (valOne, valTwo) => {
  try {
    if (!(isEmpty(valOne) || isNil(valTwo) || valTwo === '')) {
      if (!valOne.err) {
        const minutes = valOne.time;
        const solvableEqn = `(${minutes}/60)*${valTwo}`;
        return solveEqnStr(solvableEqn);
      }
    }
  } catch (error) {
    return INVALID_INPUT;
  }
};

/**
 * function to calculate date multiply with constant
 */
const solveDateNumMultiply = (valOne, valTwo) => {
  try {
    // VAl ONE is always DATE and VAL TWO is always NUMBER OR RUPEE
    if (!(isEmpty(valOne) || isNil(valTwo) || valTwo === '')) {
      if (!valOne.err) {
        const duration = valOne.duration;
        const days = Math.abs(parseInt(duration));
        const solvableEqn = `${days}/86400000*${valTwo}`;
        return solveEqnStr(solvableEqn);
      }
    }
  } catch {
    return INVALID_INPUT;
  }
};

const isEqnColFormulaWithDateOrTime = (colData) =>
  colData?.fieldType === FIELD_TYPE_ID.FORMULA &&
  [FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.TIME].includes(colData?.subType);

const getFormulaColumnValue = (colData, rowData) => {
  const subType = colData.subType;
  const val = rowData[colData.colId]?.val;
  if (!val) {
    return '';
  } else {
    switch (subType) {
      case FIELD_TYPE_ID.DATE:
        return val.years > 0
          ? val.years
          : val.months > 0
          ? val.months
          : val.days > 0
          ? val.days
          : '';

      case FIELD_TYPE_ID.TIME:
        return val.hours > 0 ? val.hours : val.minutes > 0 ? val.minutes : '';

      default:
        return '';
    }
  }
};

/**
 * function to solve any kind of equation defined in FORMULA column
 */
const solveEqnNew = ({eqn, rowObj, prevRow, nullPrevRowValue, colObj}) => {
  let isValidValue = false;
  try {
    let solvableEqn = '';
    if (eqn?.length === 3) {
      if (eqn[0]?.hasDateDiffCal || eqn[2]?.hasDateDiffCal) {
        //if DateDifference * NumberConstant
        return solveDateNumMultiply(
          eqn[0].hasDateDiffCal
            ? rowObj?.[eqn[0].colId]?.val
            : rowObj?.[eqn[2].colId]?.val,
          eqn[0].hasDateDiffCal
            ? rowObj?.[eqn[2].colId]?.val
            : rowObj?.[eqn[0].colId]?.val,
        );
      }

      if (eqn[0]?.isTimeNumMultiply || eqn[2]?.isTimeNumMultiply) {
        //if TimeDifference * NumberConstant
        return solveTimeNumMultiply(
          eqn[0].isTimeNumMultiply
            ? rowObj?.[eqn[0].colId]?.val
            : rowObj?.[eqn[2].colId]?.val,
          eqn[0].isTimeNumMultiply
            ? rowObj?.[eqn[2].colId]?.val
            : rowObj?.[eqn[0].colId]?.val,
        );
      }

      if (
        eqn[0]?.isDateDiff &&
        (eqn[0]?.fieldType === FIELD_TYPE_ID.FORMULA ||
          eqn[2]?.fieldType === FIELD_TYPE_ID.FORMULA)
      ) {
        //if diff b/w any 2 formulas (having date Diff)
        return solveDateTimeEqn(
          rowObj?.[eqn[0].colId]
            ? eqn[0]?.fieldType === FIELD_TYPE_ID.FORMULA
              ? rowObj[eqn[0].colId].val?.duration
              : null
            : null,
          rowObj?.[eqn[2].colId]
            ? eqn[2]?.fieldType === FIELD_TYPE_ID.FORMULA
              ? rowObj[eqn[2].colId].val?.duration
              : null
            : null,
          false,
          eqn[0]?.fieldType === FIELD_TYPE_ID.FORMULA,
          eqn[2]?.fieldType === FIELD_TYPE_ID.FORMULA,
        );
      } else if (
        eqn[0]?.isTimeDiff &&
        (eqn[0]?.fieldType === FIELD_TYPE_ID.FORMULA ||
          eqn[2]?.fieldType === FIELD_TYPE_ID.FORMULA)
      ) {
        //if diff b/w any 2
        return solveDateTimeEqn(
          rowObj?.[eqn[0].colId] ? rowObj[eqn[0].colId].val : null,
          rowObj?.[eqn[2].colId] ? rowObj[eqn[2].colId].val : null,
          eqn[0].isTimeDiff,
          eqn[0]?.fieldType === FIELD_TYPE_ID.FORMULA,
          eqn[2]?.fieldType === FIELD_TYPE_ID.FORMULA,
        );
      } else if (eqn[0]?.isDateDiff || eqn[0]?.isTimeDiff) {
        //if diff b/w any 2 dates/times(set from FormulaContainer)
        return solveDateTimeEqn(
          rowObj?.[eqn[0].colId] ? rowObj[eqn[0].colId].val : null,
          rowObj?.[eqn[2].colId] ? rowObj[eqn[2].colId].val : null,
          eqn[0].isTimeDiff,
        );
      }
    }

    let variableOperandsCount = 0; //count of columns in formula
    let emptyOperandsCount = 0; //count of undefined columns in formula
    let isNumberConstantIncluded = false;
    let divisionIncluded = false;
    let somePrevRowRefExists = false;
    const colIdDataStore = {};

    for (let i = 0; i < eqn.length; i++) {
      const obj = eqn[i];

      if (obj?.colId) {
        colIdDataStore[obj.colId] = obj;
      }

      if (
        obj.fieldType === FIELD_TYPE_ID.OPERATION ||
        obj.fieldType === FIELD_TYPE_ID.NUMBER_CONSTANT
      ) {
        if (obj.val === '/') {
          divisionIncluded = true;
        }
        switch (obj.val) {
          case 'x':
            solvableEqn += '*';
            break;
          case '%':
            solvableEqn += '*0.01*';
            solvableEqn += i === eqn.length - 1 ? '1' : '';
            break;
          default:
            solvableEqn += `${obj.val}`;
        }
        if (obj.fieldType === FIELD_TYPE_ID.NUMBER_CONSTANT) {
          isNumberConstantIncluded = true;
        }
      } else {
        const colId = obj?.colId;
        if (obj.prev) {
          somePrevRowRefExists = true;
          if (!prevRow) {
            //1st row
            const value = nullPrevRowValue ? nullPrevRowValue : 0;
            solvableEqn += `${value}`;
          } else {
            const value =
              checkIfPlainText(prevRow?.[colId]?.val) &&
              prevRow[colId].val !== ''
                ? prevRow[colId].val
                : 0;
            solvableEqn += `${value}`;
          }
        } else {
          variableOperandsCount += 1;
          if (obj.default) {
            if (rowObj[colId] && rowObj[colId].val !== '') {
              let value = rowObj[colId].val;
              if (obj.onlyPositiveInput) {
                value = value < 0 ? value * -1 : value;
              }
              solvableEqn += `${value}`;
            } else {
              solvableEqn += `${obj.default}`;
            }
          } else {
            const valueCheck = isEqnColFormulaWithDateOrTime(
              colIdDataStore[colId],
            )
              ? getFormulaColumnValue(colIdDataStore[colId], rowObj)
              : rowObj?.[colId]?.val;
            if (
              !rowObj[colId] ||
              !checkIfPlainText(valueCheck) ||
              valueCheck === ''
            ) {
              emptyOperandsCount += 1;
              solvableEqn += '0';
            } else {
              let value = valueCheck;
              if (obj.onlyPositiveInput) {
                value = value < 0 ? value * -1 : value;
              }

              const isDisplayInPercentage =
                colIdDataStore?.[colId]?.columnProperties?.[
                  COLUMN_PROPERTY_KEYS.DISPLAY_IN_PERCENTAGE
                ];

              const fieldType = getColumnFieldType(colIdDataStore?.[colId]);

              if (isDisplayInPercentage) {
                if (fieldType === FIELD_TYPE_ID.NUMBER) {
                  value = `(${value})%`;
                }
                if (fieldType === FIELD_TYPE_ID.FORMULA) {
                  value = `(${value}/100)`;
                }
              }

              solvableEqn += value;
              isValidValue = true;
            }
          }
        }
      }
    }

    if (
      isValidValue &&
      colObj?.columnProperties?.[COLUMN_PROPERTY_KEYS.DISPLAY_IN_PERCENTAGE]
    ) {
      solvableEqn = `(${solvableEqn})*100`;
    }

    if (
      variableOperandsCount > 0 &&
      emptyOperandsCount === variableOperandsCount &&
      !isValidValue
    ) {
      //if all columns undefined
      return '';
    }
    const isSelfRowSerialNumberTypeFormula =
      somePrevRowRefExists &&
      variableOperandsCount === 0 &&
      emptyOperandsCount === variableOperandsCount &&
      !isValidValue;

    if (
      !isValidValue &&
      variableOperandsCount === 0 &&
      isNumberConstantIncluded
    ) {
      isValidValue = true;
    }

    return solveEqnStr(solvableEqn, {
      isDivisionIncluded: divisionIncluded,
      isRowFilled: isSelfRowSerialNumberTypeFormula || isValidValue,
      uptoDecimalPlaces: colObj?.uptoDecimalPlaces,
    });
  } catch (error) {
    return INVALID_INPUT;
  }
};

/**
 * return precision for the given upto decimal point input
 */

const getPrecision = (precision) =>
  isInteger(precision) ? Math.pow(10, precision) : 100;

/**
 * function to solve any equation inputed as a plain string
 * Return : if a valid equation, returns the result of the equation
 *          else returns INVALID_INPUT (or, DIV_ERR if division error (if isDivisionIncluded=true only))
 */
const solveEqnStr = (eqn, options = {}) => {
  const {
    isDivisionIncluded = false,
    isRowFilled = true,
    uptoDecimalPlaces = null,
  } = options ?? {};
  try {
    let precision = 100; // 100 represents precision till two decimal places

    if (isInteger(uptoDecimalPlaces)) {
      precision = getPrecision(uptoDecimalPlaces);
    }

    let ans = evaluate(eqn);
    ans = Math.round((ans + Number.EPSILON) * precision) / precision;
    if (isDivisionIncluded && (isNaN(ans) || !isFinite(ans))) {
      return DIV_ERR;
    }
    return isRowFilled ? (isNaN(ans) ? INVALID_INPUT : ans) : '';
  } catch {
    return INVALID_INPUT;
  }
};

/**
 * function to get date-time diff footer average
 */
const getDateTimeDiffFooterAverage = (totalObj) => {
  try {
    if (isEmpty(totalObj)) {
      throw new Error('totalObj is empty');
    }
    const total = Object.assign({}, totalObj.total);
    const count = parseInt(totalObj.count) - parseInt(totalObj.invalidCount);
    const isTimeDiff = total.isTime && true;
    if (isTimeDiff) {
      const time = count > 0 ? parseFloat(total.time / count) : 0;
      if (isNaN(time)) {
        return {err: true, val: INVALID_INPUT};
      }
      const now = moment();
      const start = moment(now).add(time, 'minutes');
      const end = moment(now);
      const values = dateDiff(start, end, true);
      return {
        ...values,
        err: false,
        time,
        isTime: true,
      };
    } else {
      const duration = count > 0 ? parseFloat(total.duration / count) : 0;
      if (isNaN(duration)) {
        return {err: true, val: INVALID_INPUT};
      }
      let swap = false;
      const now = moment();
      let start = moment(now).add(duration);
      let end = moment(now);
      if (end > start) {
        [start, end] = [end, start];
        swap = true;
      }
      const values = dateDiff(start, end);
      return {
        ...values,
        duration,
        err: false,
        ago: swap,
      };
    }
  } catch (e) {
    return {
      err: true,
      val: INVALID_INPUT,
      isTime: totalObj?.isTime,
    };
  }
};

/**
 * function to calculate average of any column
 * when its totalObj(keys : [count, total, invalidCount]) is passed
 */
const calculateAverage = (totalObj) => {
  if (isPlainObject(totalObj.total)) {
    return getDateTimeDiffFooterAverage(totalObj); //for date-time diff footer
  }
  if (
    isNaN(totalObj.total) ||
    isNaN(totalObj.count) ||
    isNaN(totalObj.invalidCount)
  ) {
    return INVALID_INPUT;
  }
  const count = totalObj.count - totalObj.invalidCount;
  return count > 0 ? solveEqnStr(`${totalObj.total}/${count}`) : 0;
};

/**
 * function to get totalObj for footerData
 */
const getTotalObject = (currentFooterObj, totalObj) => {
  /**
   * currentFooterObj : footerData[colId] -> current
   * totalObj : obj with updated total and count values
   *
   * returns : updated value to save at footerData[colId]
   */
  const resObj = Object.assign({}, totalObj, {isVisible: false, type: null});
  try {
    if (
      !isEmpty(currentFooterObj) &&
      [undefined, true].includes(currentFooterObj.isVisible)
    ) {
      const type =
        currentFooterObj.type in FOOTER_OPERATION_TYPES
          ? currentFooterObj.type
          : FOOTER_OPERATION_TYPES.TOTAL;
      const val = getOperationValue(type, totalObj);
      Object.assign(resObj, {
        type,
        isVisible: true,
        val,
      });
    }
  } catch (err) {
    captureError(err);
  }
  return resObj;
};

/**
 * function to value based on operation
 */
const getOperationValue = (operation, totalObj) => {
  let val;
  try {
    switch (operation) {
      case FOOTER_OPERATION_TYPES.TOTAL: {
        val = totalObj.total;
        break;
      }
      case FOOTER_OPERATION_TYPES.AVERAGE: {
        val = calculateAverage(totalObj);
        break;
      }
      case FOOTER_OPERATION_TYPES.COUNT: {
        val = totalObj.count;
        break;
      }
      default: {
        captureInfo({
          operation,
          totalObj: JSON.stringify(totalObj),
        });
        throw new Error('Invalid dashboard operation type : getOperationValue');
      }
    }
  } catch (err) {
    captureError(err);
  }
  return val;
};

/**
 * function which recalculates total in case of any kind of edit or a single row is deleted
 * return footerData (recalculated)
 */
const reCalculateFooter = (updatedRowObj, prevRowObj, options = {}) => {
  try {
    const {doNotUpdateDashboard = false, footerToUse = null} = Object.assign(
      {},
      options,
    );
    updatedRowObj = Object.assign({}, updatedRowObj);
    prevRowObj = Object.assign({}, prevRowObj);
    const {table, home} = getReduxState();
    const footerToLoop = Object.assign(
      {},
      footerToUse ? footerToUse : table.footerData,
    );
    let footer = Object.assign(
      {},
      footerToUse ? footerToUse : table.footerData,
    );
    let headerData = table.headerData.slice();
    const originalHeaderData = checkAndGetOriginalHeaderData();
    if (originalHeaderData) {
      headerData = originalHeaderData;
    }
    if (!isEmpty(footerToLoop)) {
      forOwn(footerToLoop, function (currentFooterObj, key) {
        if (isEmpty(currentFooterObj)) {
          //if footerObj is empty in any case remove this key from footer
          footer = omit(footer, [key]);
          return;
        }
        if (isEqual(prevRowObj[key], updatedRowObj[key])) {
          //no further processing required
          return;
        }
        const currTotal = currentFooterObj.total;
        const currCount = parseInt(currentFooterObj.count);
        const currInvalidCount = parseInt(currentFooterObj.invalidCount);

        const isDateTimeDiff =
          isPlainObject(currentFooterObj.val) ||
          isPlainObject(currentFooterObj.total);
        if (!isDateTimeDiff) {
          //check not date-time diff column's total
          //so : footer for Number/Text/Unit columns
          //subtract previous rowObj value(if any) and add new rowObj value(if any)
          const prevVal = prevRowObj[key]?.val;
          const newVal = updatedRowObj[key]?.val;
          const isValueAvailableBeforeEdit = checkIfCellHasTextualData(prevVal); //cell has some value before this edit
          const isValueValidBeforeEdit =
            isValueAvailableBeforeEdit && !isNaN(prevVal);
          const isValueAvailableAfterEdit = checkIfCellHasTextualData(newVal); //cell has some value after this edit
          const isValueValidAfterEdit =
            isValueAvailableAfterEdit && !isNaN(newVal);
          footer[key] = getTotalObject(currentFooterObj, {
            total: solveEqnStr(
              `${parseFloat(currTotal)}-(${
                isValueValidBeforeEdit ? Number(prevVal) : 0
              })+(${isValueValidAfterEdit ? Number(newVal) : 0})`,
            ),
            count:
              currCount -
              (isValueAvailableBeforeEdit ? 1 : 0) +
              (isValueAvailableAfterEdit ? 1 : 0),
            invalidCount:
              currInvalidCount -
              (isValueAvailableBeforeEdit
                ? isValueValidBeforeEdit
                  ? 0
                  : 1
                : 0) +
              (isValueAvailableAfterEdit ? (isValueValidAfterEdit ? 0 : 1) : 0),
          });
        } else {
          //date-time diff
          try {
            //subtract previous rowObj value(if any) and add new rowObj value(if any)
            const isTimeDiff = Boolean(currTotal.isTime);
            const unitKey = isTimeDiff ? 'time' : 'duration';
            const isValueAvailableBeforeEdit =
              isPlainObject(prevRowObj[key]?.val) &&
              !isEmpty(prevRowObj[key].val) &&
              !(
                prevRowObj[key].val.err === true &&
                prevRowObj[key].val.val === ''
              ); //cell has some value before this edit
            const isValueValidBeforeEdit =
              isValueAvailableBeforeEdit &&
              prevRowObj[key].val.err === false &&
              !isNaN(Number(parseInt(prevRowObj[key].val[unitKey])));
            const isValueAvailableAfterEdit =
              isPlainObject(updatedRowObj[key]?.val) &&
              !isEmpty(updatedRowObj[key].val) &&
              !(
                updatedRowObj[key].val.err === true &&
                updatedRowObj[key].val.val === ''
              ); //cell has some value after this edit
            const isValueValidAfterEdit =
              isValueAvailableAfterEdit &&
              updatedRowObj[key].val.err === false &&
              !isNaN(Number(parseInt(updatedRowObj[key].val[unitKey])));
            const currTotalVal = Number(parseInt(currTotal[unitKey]));
            const prevCellValue = isValueValidBeforeEdit
              ? Number(parseInt(prevRowObj[key].val[unitKey]))
              : 0;
            const newCellValue = isValueValidAfterEdit
              ? Number(parseInt(updatedRowObj[key].val[unitKey]))
              : 0;

            const updatedTotalObj = {};
            if (prevCellValue !== newCellValue) {
              //if total duration/time changed
              const newTotalDurationOrTime = parseInt(
                currTotalVal - prevCellValue + newCellValue,
              );
              if (isTimeDiff) {
                //recalculate time
                const now = moment();
                const start = moment(now).add(
                  newTotalDurationOrTime,
                  'minutes',
                );
                const end = moment(now);
                const values = dateDiff(start, end, true);
                Object.assign(updatedTotalObj, values, {
                  err: false,
                  time: newTotalDurationOrTime,
                  isTime: true,
                });
              } else {
                //recalculate date
                let swap = false;
                const now = moment();
                let start = moment(now).add(newTotalDurationOrTime);
                let end = moment(now);
                if (end > start) {
                  //swap
                  [start, end] = [end, start];
                  swap = true;
                }
                const values = dateDiff(start, end);
                Object.assign(updatedTotalObj, values, {
                  ago: swap,
                  err: false,
                  duration: newTotalDurationOrTime,
                });
              }
            } else {
              //only count changed
              Object.assign(updatedTotalObj, currTotal);
            }
            footer[key] = getTotalObject(currentFooterObj, {
              total: updatedTotalObj,
              count:
                currCount -
                (isValueAvailableBeforeEdit ? 1 : 0) +
                (isValueAvailableAfterEdit ? 1 : 0),
              invalidCount:
                currInvalidCount -
                (isValueAvailableBeforeEdit
                  ? isValueValidBeforeEdit
                    ? 0
                    : 1
                  : 0) +
                (isValueAvailableAfterEdit
                  ? isValueValidAfterEdit
                    ? 0
                    : 1
                  : 0),
            });
          } catch {
            const errorObj = {
              err: true,
              val: INVALID_INPUT,
              isTime: currentFooterObj.total?.isTime,
            };
            footer[key] = {
              val: errorObj,
              total: errorObj,
              isVisible: currentFooterObj.isVisible,
              type: currentFooterObj.type,
            };
          }
        }
      });
    }
    if (!doNotUpdateDashboard) {
      checkAndUpdateDashboard(
        home,
        headerData,
        table.splitByCalculation,
        footerToLoop,
        footer,
        [{prev: prevRowObj, updated: updatedRowObj}],
      );
    }
    return Object.assign({}, footer);
  } catch (error) {
    captureError(error);
    return {};
  }
};

/**
 * sort equation array based on the dependency(topological sort)
 */
const sortEquationArray = (eqnArray) => {
  try {
    //ref : https://leetcode.com/tag/topological-sort/discuss/1078072/Introduction-to-Topological-Sort
    const buildGraph = () => {
      const graph = {};
      const inDegree = {};
      const colIdIndexMap = {};
      for (let i = 0; i < eqnArray.length; i++) {
        const sourceColId = eqnArray[i].colId;
        colIdIndexMap[sourceColId] = i;
        for (let j = 0; j < eqnArray.length; j++) {
          const checkIfConditionalEqnIncluded = () =>
            (eqnArray[j].optionalEqn ?? []).some(
              (eqnObj) =>
                (eqnObj.formulaConfig?.eqnStr ?? '').includes(sourceColId) ||
                (eqnObj.conditions ?? []).some(
                  (conditionObj) =>
                    conditionObj?.WHEN?.value?.id == sourceColId,
                ),
            );
          if (
            i !== j &&
            ((eqnArray[j].eqnStr ?? '').includes(sourceColId) ||
              checkIfConditionalEqnIncluded())
          ) {
            const source = sourceColId;
            const destination = eqnArray[j].colId;
            //set source and destination in the graph obj as empty
            if (!graph[source]) {
              graph[source] = [];
            }
            if (!graph[destination]) {
              graph[destination] = [];
            }
            //set source and destination in the inDegree obj as 0
            if (!inDegree[source]) {
              inDegree[source] = 0;
            }
            if (!inDegree[destination]) {
              inDegree[destination] = 0;
            }
            //update source to destination relation and increment inDegree for this edge
            graph[source].push(destination);
            ++inDegree[destination];
          }
        }
      }
      return {graph, inDegree, colIdIndexMap};
    };
    const processGraph = ({graph, inDegree, colIdIndexMap}) => {
      const resultEqnArr = [];
      const columns = Object.keys(inDegree);
      const queue = columns.filter((key) => inDegree[key] === 0);
      let visitedVertices = 0;
      while (queue.length > 0) {
        const front = queue[0];
        queue.splice(0, 1); //dequeue
        resultEqnArr.push(eqnArray[colIdIndexMap[front]]);
        delete colIdIndexMap[front];
        for (let i = 0; i < graph[front].length; i++) {
          const current = graph[front][i];
          if (--inDegree[current] === 0) {
            queue.push(current);
          }
        }
        ++visitedVertices;
      }
      if (visitedVertices !== columns.length) {
        throw new Error('There exists a cycle in the graph');
      }
      const independentEqnArr = [];
      forOwn(colIdIndexMap, (colIndex) => {
        independentEqnArr.push(eqnArray[colIndex]);
      });
      return [...independentEqnArr, ...resultEqnArr];
    };
    return processGraph(buildGraph());
  } catch (e) {
    captureInfo({e: serializeError(e)});
    return eqnArray;
  }
};

/**
 * solves equation for a row
 *
 * params: {headerDataAsObj, fileObj, userCountry, uid, orgId}
 */
const solveRowAllEqns = (rowValues, eqnArrs, stringEqnArrs, params) => {
  const getEqnToUse = (eqnObj) => {
    let eqnToUse = eqnObj.eqn;
    (Array.isArray(eqnObj.optionalEqn) ? eqnObj.optionalEqn : []).some(
      (optionalEqnObj) => {
        if (
          checkIfConditionIsTrue(
            optionalEqnObj.conditions,
            optionalEqnObj.type,
            {
              rowObj: rowValues,
              headerDataAsObj: params?.headerDataAsObj,
              fileObj: params?.fileObj,
              userMetaObj: {userCountry: params?.userCountry ?? 'IN'},
              uid: params?.uid,
              orgId: params?.orgId,
            },
          )
        ) {
          eqnToUse = optionalEqnObj.formulaConfig.eqn;
          return true;
        }
        return false;
      },
    );
    return !eqnToUse?.length ? [] : eqnToUse;
  };

  (Array.isArray(eqnArrs) ? eqnArrs : []).forEach((eqnObj) => {
    const eqnVal = solveEqnNew({
      colObj: eqnObj.colObj,
      eqn: getEqnToUse(eqnObj),
      rowObj: rowValues,
      prevRow: null,
      nullPrevRowValue: null,
    });
    const colId = eqnObj.colId;
    const prevValNull = isNil(rowValues[colId]?.val);
    const calculatedValNull = isNil(eqnVal) || eqnVal === '';
    if (prevValNull && calculatedValNull) {
      return; //continue
    }
    const updatedCellObj = Object.assign({}, rowValues[colId], {
      val: calculatedValNull ? '' : eqnVal,
    });
    rowValues = Object.assign({}, rowValues, {
      [colId]: updatedCellObj,
    });
  });

  (Array.isArray(stringEqnArrs) ? stringEqnArrs : []).forEach((eqnObj) => {
    const eqnVal = require('./stringEquationHelper').solveStringEquation({
      colObj: eqnObj.colObj,
      eqn: getEqnToUse(eqnObj),
      rowObj: rowValues,
    });
    const colId = eqnObj.colId;
    const prevValNull = isNil(rowValues[colId]?.val);
    const calculatedValNull = isNil(eqnVal) || eqnVal === '';
    if (prevValNull && calculatedValNull) {
      return; //continue
    }
    const updatedCellObj = Object.assign({}, rowValues[colId], {
      val: calculatedValNull ? '' : eqnVal,
    });
    rowValues = Object.assign({}, rowValues, {
      [colId]: updatedCellObj,
    });
  });

  return rowValues;
};

/**
 * function to get calculated data for a single row for given eqns array and prefilled values
 */
const getEqnCalculatedData = (
  rowValues,
  headerDataToUse = null,
  ignoreSubEqnCalculation = false,
  params = {},
) => {
  const getTableReduxState = () => {
    const state = getReduxState();
    let restrictions, headerData, isUserHavingHiddenColumn;
    try {
      restrictions = Object.assign(
        {},
        state.home.activeDocumentMeta?.collab?.restrictions,
      );
      isUserHavingHiddenColumn = Boolean(
        state.table.originalHeaderData?.length &&
          Object.keys(restrictions).some(
            (colId) => restrictions[colId]?.isHidden,
          ),
      );
      headerData = isUserHavingHiddenColumn
        ? state.table.originalHeaderData
        : state.table.headerData;
      return Object.assign({}, state.table, {headerData});
    } catch (err) {
      captureInfo({isUserHavingHiddenColumn, restrictions, headerData});
      captureError(err);
      return state.table;
    }
  };
  const {
    headerMappedValues: {
      headerIdIndexMap,
      headerDataAsObj,
      preFilledValuesArr,
      numberSortFieldsArr,
      eqnArrs,
      stringEqnArrs,
      dateMathColIds,
      numOfDaysColIds,
    },
    headerData,
  } = Array.isArray(headerDataToUse)
    ? {
        headerData: headerDataToUse,
        headerMappedValues: mapHeaderData(
          headerDataToUse,
          {},
          ignoreSubEqnCalculation,
          rowValues,
        ),
      }
    : getTableReduxState();
  const {fileObj, userCountry, uid, timezone, orgId} = Object.assign(
    {},
    params,
  );
  const getHeaderObj = (headerId) => headerData[headerIdIndexMap[headerId]];

  //process number fields
  numberSortFieldsArr.forEach((headerId) => {
    const cellVal = rowValues[headerId]?.val;
    if (cellVal !== '' && checkIfPlainText(cellVal)) {
      let val = cellVal;
      if (cellVal === '.') {
        val = 0;
      }
      const updatedCellVal = Object.assign({}, rowValues[headerId], {val});
      rowValues = Object.assign({}, rowValues, {[headerId]: updatedCellVal});
    }
  });

  //process pre fill fields
  preFilledValuesArr.forEach((headerId) => {
    const cellVal = rowValues[headerId]?.val;
    if (!isNil(cellVal) && cellVal !== '') {
      const headerObj = getHeaderObj(headerId);
      const processPrefilledObj = (prefilledObj) => {
        forIn(prefilledObj, (val, key) => {
          if (isEmpty(rowValues[key]) || rowValues[key].val === '') {
            Object.assign(rowValues, {
              [key]: Object.assign(
                {},
                rowValues[key],
                val?.val === 'now()'
                  ? getDateTimeCellObj(
                      {},
                      headerObj.fieldType === FIELD_TYPE_ID.TIME,
                      true,
                      headerObj.dateFormat,
                    )
                  : val,
              ),
            });
          }
        });
      };
      if (cellVal >= 0 && !isEmpty(headerObj.preFilledValues.positive)) {
        processPrefilledObj(headerObj.preFilledValues.positive);
      }
      if (cellVal < 0 && !isEmpty(headerObj.preFilledValues.negative)) {
        processPrefilledObj(headerObj.preFilledValues.negative);
      }
      if (!isEmpty(headerObj.preFilledValues.all)) {
        processPrefilledObj(headerObj.preFilledValues.all);
      }
    }
  });

  rowValues = dateTimeMathForSingleRow(
    {dateTimeMathColIds: dateMathColIds, headerDataObj: headerDataAsObj},
    rowValues,
    {},
    rowValues?.rowProperties,
    {},
    params?.timezone,
  );

  rowValues = numOfDaysCalc({headerDataAsObj, numOfDaysColIds, rowValues});
  //solve equations without prev row ref
  return solveRowAllEqns(rowValues, eqnArrs, stringEqnArrs, {
    fileObj,
    userCountry,
    uid,
    orgId,
    timezone,
    headerDataAsObj,
  });
};

/**
 * function to get calculated data of prev row ref eqns for a single row
 */
const solvePrevRowRefEqnForRow = (rowValues, prevRowRefEqnArrs, prevRow) => {
  let rowObj = Object.assign({}, rowValues);
  const prevRowRefDataObj = {};
  if (prevRowRefEqnArrs?.length) {
    //solve equations with prev row ref
    prevRowRefEqnArrs.forEach((eqnObj) => {
      const eqnVal = solveEqnNew({
        eqn: eqnObj.eqn,
        rowObj: rowObj,
        prevRow,
        nullPrevRowValue: eqnObj.nullPrevRowValue,
      });
      const val = !isNil(eqnVal) ? eqnVal : '';
      rowObj = Object.assign({}, rowObj, {
        [eqnObj.colId]: Object.assign({}, rowObj[eqnObj.colId], {val}),
      });
      Object.assign(prevRowRefDataObj, {[eqnObj.colId]: {val}});
    });
  }
  return [rowObj, prevRowRefDataObj];
};

/**
 * function to get calculated data of prev row ref eqns for entire table
 */
const solvePrevRowRefEqnForTable = (tableData, prevRowRefEqnArrs) => {
  let prevRow = {};
  return prevRowRefEqnArrs?.length
    ? tableData.map((rowObj) => {
        const result = solvePrevRowRefEqnForRow(
          rowObj,
          prevRowRefEqnArrs,
          prevRow,
        );
        prevRow = result[1];
        return result[0];
      })
    : tableData;
};

/**
 * get pre computed/processed objects from headerData
 */
const mapHeaderData = (
  headerData,
  restrictions = {},
  ignoreSubEqnCalculation = false,
  rowObj = {},
) => {
  const headerIdIndexMap = {};
  const headerDataAsObj = {};
  const eqnArrs = []; //store's actual formula
  const stringEqnArrs = []; //store's actual string formula
  const prevRowRefEqnArr = []; //stores prev row ref formula
  const numberSortFieldsArr = []; //stores headerIds
  const preFilledValuesArr = []; //stores headerIds
  let isAnyHiddenMandatory = false;
  const mandatoryColumnObject = {};
  let primaryColumnMapping = {};
  const dateMathColIds = [];
  const numOfDaysColIds = [];
  try {
    (Array.isArray(headerData) ? headerData : []).forEach(
      (headerObj, headerIdIndex) => {
        const columnId = headerObj?.id;
        if (!columnId) {
          return;
        }

        const fieldType = getColumnFieldType(headerObj);

        if (headerObj.fieldType === FIELD_TYPE_ID.TABLE) {
          primaryColumnMapping = checkAndUpdatePrimaryColumnMapping(
            headerObj,
            primaryColumnMapping,
          );
        }

        headerIdIndexMap[columnId] = headerIdIndex;
        headerDataAsObj[columnId] = headerObj;

        const shouldCalculateFormula = headerObj?.columnProperties?.[
          COLUMN_PROPERTY_KEYS.OVERWRITE_VALUE
        ]
          ? !rowObj?.[headerObj?.id]?.isOverridden ||
            ColumnUtility.isDateOrTimeFormula(headerObj)
          : true;

        if (
          headerObj.subEqn?.length > 0 &&
          !ignoreSubEqnCalculation &&
          shouldCalculateFormula
        ) {
          //add subEqns
          eqnArrs.push({
            colObj: headerObj,
            colId: columnId,
            eqn: headerObj.subEqn,
            eqnStr: headerObj.subEqnStr,
          });
        }

        if (NUMBER_SORT_FIELD.includes(headerObj.fieldType)) {
          numberSortFieldsArr.push(columnId);
        }

        if (!isEmpty(headerObj.preFilledValues)) {
          preFilledValuesArr.push(columnId);
        }

        if (
          headerObj.fieldType === FIELD_TYPE_ID.FORMULA &&
          (headerObj.eqn?.length > 0 || !isEmpty(headerObj.optionalEqn)) &&
          shouldCalculateFormula
        ) {
          const optionalEqn = Object.values(
            Object.assign({}, headerObj.optionalEqn),
          );
          const obj = {
            colObj: headerObj,
            colId: columnId,
            eqn: headerObj.eqn,
            eqnStr: headerObj.eqnStr,
            nullPrevRowValue: headerObj.nullPrevRowValue,
            optionalEqn,
          };
          //add eqns
          headerObj.hasPrevRowRef
            ? prevRowRefEqnArr.push(obj)
            : eqnArrs.push(obj);
        }

        if (
          headerObj.fieldType === FIELD_TYPE_ID.STRING_FORMULA &&
          (headerObj.eqn?.length > 0 || !isEmpty(headerObj.optionalEqn))
        ) {
          const optionalEqn = Object.values(
            Object.assign({}, headerObj.optionalEqn),
          );
          const obj = {
            colObj: headerObj,
            colId: columnId,
            eqn: headerObj.eqn,
            eqnStr: headerObj.eqnStr,
            optionalEqn,
          };
          stringEqnArrs.push(obj);
        }

        if (headerObj.columnProperties?.[COLUMN_PROPERTY_KEYS.MANDATORY]) {
          //mandatory column
          mandatoryColumnObject[columnId] = headerObj;
          isAnyHiddenMandatory =
            isAnyHiddenMandatory ||
            restrictions?.[columnId]?.isHidden ||
            restrictions?.[columnId]?.isReadOnly;
        }

        if (
          [FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.DATE_TIME].includes(fieldType) &&
          headerObj.isDateMath
        ) {
          dateMathColIds.push(columnId);
        }

        if (fieldType === FIELD_TYPE_ID.NO_OF_DAYS && headerObj?.daysConfig) {
          numOfDaysColIds.push(columnId);
        }
      },
    );
  } catch (error) {
    captureError(error);
  }
  return {
    headerIdIndexMap,
    headerDataAsObj,
    numberSortFieldsArr,
    prevRowRefEqnArr: sortEquationArray(prevRowRefEqnArr),
    eqnArrs: sortEquationArray(eqnArrs),
    stringEqnArrs: sortEquationArray(stringEqnArrs),
    preFilledValuesArr,
    isAnyHiddenMandatory: Boolean(isAnyHiddenMandatory),
    mandatoryColumnObject,
    primaryColumnMapping,
    dateMathColIds,
    numOfDaysColIds,
  };
};

const getSortedDateMathColIds = (dateTimeMathColIds, headerDataObj) => {
  const eqnArrs = dateTimeMathColIds.map((colId) => {
    const dateMathConfig = headerDataObj[colId].dateMathConfig;
    let eqnStr = `${dateMathConfig.dateColId}`;
    switch (dateMathConfig.type) {
      //sign & constants doesn't matter for topological sort
      case DATE_TIME_CALCULATION_TYPES.D_A_C:
      case DATE_TIME_CALCULATION_TYPES.D_S_C: {
        eqnStr += `+${dateMathConfig.numberColId}`;
      }
    }
    return {colId, eqnStr};
  });
  return sortEquationArray(eqnArrs).map(({colId}) => colId);
};

const DateTimeCalculations = {
  addNumberToDate: function (startDate, number, unit, excludeWeekends = false) {
    number = Math.round(number);
    const unitString = Object.values(
      MINI_APPS.CALCULATE_DATE.DATE_TIME_UNIT_OPTIONS,
    ).includes(unit)
      ? `${unit}s`
      : 'days';
    if (unitString === 'days' ? !excludeWeekends : true) {
      return startDate.clone().add(number, unitString);
    }
    const checkIfWeekend = (date) => {
      const day = date.day();
      return day === 0 || day === 6;
    };
    const sign = number < 0 || Object.is(number, -0) ? -1 : 1;
    while (checkIfWeekend(startDate)) {
      startDate = startDate.clone().add(sign, unitString);
    }
    number = Math.abs(number);
    const endDate = startDate
      .clone()
      .add(Math.floor(number / 5) * 7 * sign, unitString);
    let remainingDays = number % 5;
    while (remainingDays) {
      endDate.add(sign, unitString);
      if (!checkIfWeekend(endDate)) {
        remainingDays--;
      }
    }
    return endDate;
  },
  subtractNumbersFromDate: function (
    startDate,
    number,
    unit,
    excludeWeekends = false,
  ) {
    return this.addNumberToDate(startDate, -number, unit, excludeWeekends);
  },
};

const dateTimeMathForSingleRow = (
  {dateTimeMathColIds, headerDataObj},
  rowObj,
  prevRowObj = {},
  rowProperties,
  prevRowProperties,
  timezone = 'Asia/Kolkata',
) => {
  const updatedRowObj = Object.assign({}, rowObj);
  try {
    //topologic sort for order of date math equations
    dateTimeMathColIds = getSortedDateMathColIds(
      dateTimeMathColIds,
      headerDataObj,
    );

    dateTimeMathColIds.forEach((colId) => {
      const headerObj = headerDataObj[colId];
      const {dateMathConfig, fieldType} = headerObj;
      const isDateMathColumnDateTime = fieldType === FIELD_TYPE_ID.DATE_TIME;
      let createdInfoKey;
      let dateColObj = headerDataObj[dateMathConfig.dateColId];
      if (
        dateMathConfig.dateColId ===
        MINI_APPS.CALCULATE_DATE.DATE_VALUE_OPTIONS.CREATED_DATE
      ) {
        dateColObj = {
          fieldType: FIELD_TYPE_ID.CREATED_INFO,
          id: FIELD_TYPE_ID.CREATED_INFO,
        };
        createdInfoKey = 'firstAddedTimestamp';
      }
      if (!dateColObj) {
        return;
      }
      const isCreatedTS =
        FIELD_TYPE_ID.CREATED_INFO === getColumnFieldType(dateColObj);
      const isDateTime =
        getColumnFieldType(dateColObj) === FIELD_TYPE_ID.DATE_TIME ||
        isCreatedTS;
      const dateCellVal = isCreatedTS
        ? rowProperties?.[createdInfoKey] ?? moment()
        : updatedRowObj[dateMathConfig.dateColId]?.val;
      const prevDateCellVal = isCreatedTS
        ? prevRowProperties?.[createdInfoKey]
        : prevRowObj?.[dateMathConfig.dateColId]?.val;
      const startDate =
        dateCellVal == null
          ? moment.invalid()
          : isDateTime
          ? moment(dateCellVal)
          : moment.unix(dateCellVal);

      const isColBasedDays = [
        DATE_TIME_CALCULATION_TYPES.D_A_C,
        DATE_TIME_CALCULATION_TYPES.D_S_C,
      ].includes(dateMathConfig.type);
      const numberForCalculation = parseFloat(
        isColBasedDays
          ? updatedRowObj[dateMathConfig.numberColId]?.val
          : dateMathConfig.number,
      );
      if (
        !(
          (
            startDate.isValid() && //date is valid
            !isNaN(numberForCalculation) && //number of days/months/years is valid
            (dateCellVal !== prevDateCellVal ||
              (isColBasedDays
                ? updatedRowObj[dateMathConfig.numberColId].val !==
                  prevRowObj?.[dateMathConfig.numberColId]?.val
                : true))
          ) //some change in date or number of days/months/years
        )
      ) {
        //nothing  to calculate
        if (updatedRowObj[colId]) {
          //is some value reset it
          Object.assign(updatedRowObj, {[colId]: {}});
        }
        return;
      }
      let resultMoment;
      const excludeWeekends = dateMathConfig.excludeWeekends === true;
      switch (dateMathConfig.type) {
        case DATE_TIME_CALCULATION_TYPES.D_A_C:
        case DATE_TIME_CALCULATION_TYPES.D_A_N: {
          resultMoment = DateTimeCalculations.addNumberToDate(
            startDate,
            numberForCalculation,
            dateMathConfig.unit,
            excludeWeekends,
          );
          break;
        }
        case DATE_TIME_CALCULATION_TYPES.D_S_C:
        case DATE_TIME_CALCULATION_TYPES.D_S_N: {
          resultMoment = DateTimeCalculations.subtractNumbersFromDate(
            startDate,
            numberForCalculation,
            dateMathConfig.unit,
            excludeWeekends,
          );
          break;
        }
      }
      const checkIfDateInsideBounds = (date) => {
        try {
          date.toISOString();
        } catch (err) {
          return !(err instanceof RangeError);
        }
        return true;
      };
      if (
        resultMoment &&
        resultMoment.isValid() &&
        checkIfDateInsideBounds(resultMoment)
      ) {
        const cellObj = isDateMathColumnDateTime
          ? {
              timezone: timezone ?? 'Asia/Kolkata',
              val: `${resultMoment.toISOString().substring(0, 19)}Z`,
              recurringType: MINI_APPS_DATE_RECURRING_TYPES.DOES_NOT_REPEAT,
            }
          : getDateTimeCellObj(
              {
                val: resultMoment
                  .startOf(
                    getDateTimePickType({dateFormat: headerObj.dateFormat}),
                  )
                  .unix(),
              },
              false,
              false,
              headerObj.dateFormat,
            );
        Object.assign(updatedRowObj, {
          [colId]: cellObj,
        });
      } else {
        Object.assign(updatedRowObj, {[colId]: {}});
      }
    });
  } catch (err) {
    captureError(err);
  }
  return updatedRowObj;
};

const numOfDaysCalc = ({headerDataAsObj, numOfDaysColIds, rowValues}) => {
  numOfDaysColIds?.forEach?.((colId) => {
    const {startDate, endDate, weekDaysToExclude, excludeFrom, excludeTo} =
      headerDataAsObj?.[colId]?.daysConfig || {};

    const getUnixDateFormat = (start, format, isStartDate = false) => {
      const unixDate = moment.unix(start);
      if (!unixDate?.isValid()) {
        return null;
      }
      switch (format) {
        case DATE_FORMATS.MONTH: {
          return isStartDate
            ? unixDate.startOf('month')
            : unixDate.endOf('month');
        }
        case DATE_FORMATS.QUARTER: {
          return isStartDate
            ? unixDate.startOf('quarter')
            : unixDate.endOf('quarter');
        }
        case DATE_FORMATS.YEAR: {
          return isStartDate
            ? unixDate.startOf('year')
            : unixDate.endOf('year');
        }
        default: {
          return unixDate;
        }
      }
    };

    const specialDateFormat = [
      DATE_FORMATS.MONTH,
      DATE_FORMATS.QUARTER,
      DATE_FORMATS.YEAR,
    ];

    let startDateVal;
    let endDateVal;
    let invalidityCheck = false;

    if (excludeFrom || excludeTo) {
      const excludeBefore = excludeFrom?.colId
        ? getUnixDateFormat(
            rowValues?.[excludeFrom?.colId]?.val,
            headerDataAsObj?.[excludeFrom?.colId]?.dateFormat,
            true,
          )
        : null;

      const excludeAfter = excludeTo?.colId
        ? getUnixDateFormat(
            rowValues?.[excludeTo?.colId]?.val,
            headerDataAsObj?.[excludeTo?.colId]?.dateFormat,
          )
        : null;

      startDateVal = getUnixDateFormat(
        rowValues?.[startDate?.colId]?.val,
        null,
      );
      endDateVal = specialDateFormat.includes(
        headerDataAsObj?.[startDate?.colId]?.dateFormat,
      )
        ? getUnixDateFormat(
            rowValues?.[startDate?.colId]?.val,
            headerDataAsObj?.[startDate?.colId]?.dateFormat,
          )
        : getUnixDateFormat(
            rowValues?.[endDate?.colId]?.val,
            headerDataAsObj?.[endDate?.colId]?.dateFormat,
          );

      if (
        excludeBefore?.isValid() &&
        excludeBefore.isSameOrAfter(startDateVal)
      ) {
        if (!excludeBefore.isSameOrBefore(endDateVal)) {
          invalidityCheck = true;
        }
        startDateVal = excludeBefore;
      }
      if (excludeAfter?.isValid() && excludeAfter.isSameOrBefore(endDateVal)) {
        if (!excludeAfter.isSameOrAfter(startDateVal)) {
          invalidityCheck = true;
        }
        endDateVal = excludeAfter;
      }
    } else {
      startDateVal = getUnixDateFormat(
        rowValues?.[startDate?.colId]?.val,
        null,
      );

      endDateVal = specialDateFormat.includes(
        headerDataAsObj?.[startDate?.colId]?.dateFormat,
      )
        ? getUnixDateFormat(
            rowValues?.[startDate?.colId]?.val,
            headerDataAsObj?.[startDate?.colId]?.dateFormat,
          )
        : getUnixDateFormat(
            rowValues?.[endDate?.colId]?.val,
            headerDataAsObj?.[endDate?.colId]?.dateFormat,
          );
    }

    const getDaysDifference = (start, end) => {
      if (!moment(start)?.isValid() || !moment(end)?.isValid()) {
        return 0;
      }

      const startDay = start.day();
      const endDay = end.day();

      const extraDates = [];

      if (startDay !== 0) {
        const daysToExtras = 6 - startDay;
        for (let i = 0; i <= daysToExtras; i++) {
          extraDates.push(start);
          start = start.clone().add(1, 'days');
        }
      }

      if (endDay !== 6) {
        for (let j = 0; j <= endDay; j++) {
          extraDates.push(end);
          end = end.clone().subtract(1, 'days');
        }
      }

      let currDaysOnlyWeeks = moment(end).diff(moment(start), 'days') + 1;
      const excludeDiff = (currDaysOnlyWeeks / 7) * weekDaysToExclude.length;

      for (let k = 0; k < extraDates?.length; k++) {
        if (!weekDaysToExclude.includes(extraDates[k]?.day())) {
          currDaysOnlyWeeks += 1;
        }
      }
      return currDaysOnlyWeeks - excludeDiff;
    };

    let daysDifference = 0;

    if (
      startDateVal?.isValid() &&
      endDateVal?.isValid() &&
      endDateVal.diff(startDateVal) > -1 &&
      !invalidityCheck
    ) {
      daysDifference = getDaysDifference(startDateVal, endDateVal);
    }

    Object.assign(rowValues, {
      [colId]: {
        val: daysDifference < 0 || isNaN(daysDifference) ? 0 : daysDifference,
      },
    });
  });

  return rowValues;
};

const MathJSInstance = {evaluate};

export {
  solveRowAllEqns,
  getEqnCalculatedData,
  calculateTotal,
  getTotalObject,
  reCalculateFooter,
  solveEqnNew,
  calculateAverage,
  solveEqnStr,
  calculateSplitByTotal,
  getOperationValue,
  sortEquationArray,
  mapHeaderData,
  solvePrevRowRefEqnForRow,
  solvePrevRowRefEqnForTable,
  MathJSInstance,
};
