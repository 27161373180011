import {captureError, captureInfo, firestore, ShowToast} from '../imports';
import {HOME_ACTION, DASHBOARD_ACTION} from './actionType';
import {
  capitalize,
  isArray,
  isEmpty,
  isEqual,
  isPlainObject,
  omit,
  forOwn,
} from 'lodash';
import {
  createNewDashboardHelper,
  sortPagesForDashboards,
  deleteDashboardHelper,
  isDashboardSuggestionAvailableForFile,
  fetchDashboardTemplateSuggestionFirestore,
  calculateDataForDashboardHelper,
  migrateUserOlderDashboardsCloud,
} from './actionHelpers/dashboardActionHelper';
import FirestoreDB from '../FirestoreHandlers/FirestoreDB';
import {
  TOTAL_NOT_ALLOWED,
  SHARE_PERMISSION_TYPE,
  FOOTER_OPERATION_TEXT,
  SPLIT_BY_ALLOWED_FIELDS,
  FIELD_TYPE_ID,
  DASHBOARD_CONSTANTS,
} from '../utils/constant';
import {AVAILABLE_ENTITLEMENTS} from '../utils/premium';
import {
  checkIfPlainText,
  getLocalText,
  getTimezone,
  serializeError,
  JSONStringifier,
  sortedInsertionIndex,
} from '../utils/utils';
import {setUserPref} from './authAction';
import getFormattedDashboardData from '../utils/dashboardUtils/getFormattedDashboardData';
import DocumentsMethods from '../FirestoreHandlers/Documents/DocumentsMethods';

/**
 * 
 * [/users/{uid}/dashboards/{dashboardId}] : {
//   columnId: 'column id on which dashboard is active',
//   createdTimestamp:
//     'timestamp when dashboard was created (helper for sorting dashboards in display order)',
//   docName: 'name of doc on which dashboard is active',
//   label: 'column name to display in dashboard',
//   operation: 'type of dashboard operation in `columnId`',
//   originalDocumentId: 'doc id of document on which dashboard is active',
//   pagesEnabled: 'if pages are enabled on the document or not',
//   pagesType: 'type of pages on the document',
//   splitBy: [
//     {
//       id: 'columnId of splitBy column',
//       fieldType: 'column type of splitBy column',
//     },
//   ], //array with some length if this is a dashboard with splitBy active
//   pages: {
//     ['page_1_id==originalDocumentId']: {
//       createdTimestamp: 'timestamp for the page same as in pages array',
//       fieldType: 'column type for the dashboard data to format',
//       subType: 'column subtype for the dashboard data to format',
//       unitSymbol: 'if field type is UNIT then this contains the unit symbol',
//       name: 'name of the page, if available',
//       val: 'if this is a dashboard without split : this is the dashboard value',
//       splitByVal:
//         'if this is a dashboard with split : this is the dashboard value',
//     },
//     ['page_2_id']: {
//       /* same for all page obj */
//     },
//   },
// }

const activateDashboardListener =
  (firestoreInstance, onInitialCallCallback) => async (dispatch, getState) => {
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      dispatch({type: DASHBOARD_ACTION.START_FETCHING_DASHBOARDS});
      const uid = getState().auth.user?.uid;
      if (!uid) {
        return dispatch({type: DASHBOARD_ACTION.STOP_FETCHING_DASHBOARDS});
      }
      let isFirstFetch = true;
      const handleListener = (snapshot) => {
        try {
          const changedDocuments = snapshot.docChanges();
          const updatedDashboards = {};
          const removedDashboardIds = [];
          changedDocuments.forEach((change) => {
            try {
              const dashboardId = change.doc.id;
              switch (change.type) {
                case 'added':
                case 'modified': {
                  const docData = Object.assign({}, change.doc.data());
                  const isParentDashboard = !isEmpty(docData.childDashboards);
                  if (!isParentDashboard) {
                    const unsortedPages = Object.assign(
                      {},
                      docData.pagesEnabled
                        ? docData.pages
                        : {
                            [docData.originalDocumentId]:
                              docData.pages[docData.originalDocumentId],
                          },
                    );
                    Object.assign(docData, {
                      pages: sortPagesForDashboards(unsortedPages),
                    });
                  }
                  Object.assign(docData, {dashboardId});
                  updatedDashboards[dashboardId] = docData;
                  break;
                }
                case 'removed': {
                  removedDashboardIds.push(dashboardId);
                  break;
                }
              }
            } catch (err) {
              captureInfo({
                err: serializeError(err),
                changedDocuments: JSONStringifier(changedDocuments),
              });
              captureError(
                new Error('Error dashboard listener (change-doc)'),
                true,
              );
            }
          });
          dispatch({
            type: DASHBOARD_ACTION.LOAD_ALL_DASHBOARDS,
            payload: omit(
              Object.assign(
                {},
                getState().dashboard.allDashboards,
                updatedDashboards,
              ),
              removedDashboardIds,
            ),
          });
          dispatch(setVisibleDashboards(Object.keys(updatedDashboards)));
        } catch (err) {
          captureInfo({
            err: serializeError(err),
            snapshot,
          });
          captureError(
            new Error('Error dashboard listener (processing-snapshot)'),
            true,
          );
        }
        if (isFirstFetch) {
          isFirstFetch = false;
          typeof onInitialCallCallback === 'function' &&
            onInitialCallCallback();
          dispatch({type: DASHBOARD_ACTION.STOP_FETCHING_DASHBOARDS});
        }
      };
      const listener = FirestoreDB.FirestoreListener(
        firestoreInstance()
          .collection('dashboards')
          .where('ownerUID', '==', uid)
          .orderBy('createdTimestamp', 'desc'),
        handleListener,
      );
      dispatch({
        type: HOME_ACTION.UPDATE_HOME_LISTENER_OBJ,
        payload: {DASHBOARD_LISTENER: listener},
      });
    } catch (error) {
      captureError(error);
      dispatch({type: DASHBOARD_ACTION.STOP_FETCHING_DASHBOARDS});
    }
  };

const deactivateDashboardListener =
  (doNotClearLastState = true) =>
  (dispatch, getState) => {
    try {
      const dashboardListener =
        getState().home.listenersObj?.DASHBOARD_LISTENER;
      if (typeof dashboardListener === 'function') {
        dashboardListener();
      }
      if (doNotClearLastState !== true) {
        dispatch({type: DASHBOARD_ACTION.CLEAR_DASHBOARD_STATE});
      }
    } catch (error) {
      captureError(error);
    }
  };

const setVisibleDashboardPremiumLimitingObj = () => (dispatch, getState) => {
  try {
    const {
      premium: {subscriptions},
      dashboard: {visibleDashboards},
    } = getState();
    const dashboardsLimit = subscriptions.includes(
      AVAILABLE_ENTITLEMENTS.UNLIMITED_DASHBOARDS,
    )
      ? 1000000000
      : subscriptions.includes(AVAILABLE_ENTITLEMENTS.UPTO_7_DASHBOARDS)
      ? DASHBOARD_CONSTANTS.MAX_PERSONAL_DASHBOARD_COUNT
      : DASHBOARD_CONSTANTS.MAX_NON_SUBSCRIBED_USER_DASHBOARD_COUNT;

    let isLimitingFound = false;
    const dashboardRestrictionsObj = visibleDashboards?.reduce(
      (accumulator, currentValue, currentIndex) => {
        const vals = currentValue?.allDashboardValCols?.size ?? 0;
        const updatedTotal = (accumulator.total ?? 0) + vals;
        const isLimiting = !isLimitingFound && updatedTotal > dashboardsLimit;
        if (isLimiting) {
          isLimitingFound = true;
        }
        return Object.assign(
          {},
          accumulator,
          {
            total: updatedTotal,
            dashboardsLimit,
          },
          isLimiting && {
            limitingIndex: currentIndex,
            excessCount: updatedTotal - dashboardsLimit,
          },
        );
      },
      {},
    );

    dispatch({
      type: DASHBOARD_ACTION.UPDATE_DASHBOARD_LIMITING_OBJECT,
      payload: dashboardRestrictionsObj,
    });
  } catch (error) {
    captureError(error);
  }
};

const setVisibleDashboards =
  (modifiedDashboardIds = []) =>
  (dispatch, getState) => {
    try {
      const {
        dashboard: {allDashboards, visibleDashboards},
        auth: {userPref, userCountry},
      } = getState();

      if (!isArray(modifiedDashboardIds)) {
        modifiedDashboardIds = [];
      }

      const updatedVisibleDashboardsObj = {};

      //process for new/modified dashboards
      modifiedDashboardIds.forEach((dashboardId) => {
        if (allDashboards[dashboardId]) {
          let dashboardIdToProcess;
          if (!isEmpty(allDashboards[dashboardId].childDashboards)) {
            //if parent dashboardId
            dashboardIdToProcess = dashboardId;
          } else if (allDashboards[dashboardId].parentDashboardId) {
            //if child dashboard with parent's id
            dashboardIdToProcess = allDashboards[dashboardId].parentDashboardId;
          } else {
            //single dashboard
            dashboardIdToProcess = dashboardId;
          }
          if (!(dashboardIdToProcess in updatedVisibleDashboardsObj)) {
            //if not already processed
            const processedDashboardData = getFormattedDashboardData(
              allDashboards[dashboardIdToProcess],
              allDashboards,
              userPref,
              userCountry,
            );
            if (processedDashboardData) {
              updatedVisibleDashboardsObj[dashboardIdToProcess] =
                processedDashboardData;
            }
          }
        }
      });

      visibleDashboards?.forEach((dashboardData) => {
        const {dashboardId} = dashboardData;
        if (
          allDashboards[dashboardId]?.display &&
          !(dashboardId in updatedVisibleDashboardsObj)
        ) {
          updatedVisibleDashboardsObj[dashboardId] = dashboardData;
        }
      }); //get existing processed dashboards which are not removed or modified

      //convert object to array by insertion sort (optimised then converting to object and then sorting)
      const updatedVisibleDashboards = [];
      forOwn(updatedVisibleDashboardsObj, (dashboardData) => {
        const index = sortedInsertionIndex(
          updatedVisibleDashboards,
          dashboardData,
          true,
          'createdTimestamp',
        );
        updatedVisibleDashboards.splice(index, 0, dashboardData);
      });

      dispatch({
        type: DASHBOARD_ACTION.SET_VISIBLE_DASHBOARDS,
        payload: updatedVisibleDashboards,
      });
      dispatch(setVisibleDashboardPremiumLimitingObj());
    } catch (error) {
      captureError(error);
    }
  };

const createNewDashboard =
  (dataObj, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      /**
       * analyticsObj = {
       *   isAutoGenerated : boolean,
       *   isFromTableContainer : boolean,
       *   multiSelectCount: number of dashboards,
       *   selectAll: boolean
       * }
       */
      const {auth} = getState();
      const uid = auth.user?.uid;
      if (!uid) {
        return {
          success: false,
          message: 'Something went wrong, please try again',
        };
      }
      Object.assign(dataObj, {
        timezone: getTimezone() || 'Asia/Kolkata',
      });

      const data = await createNewDashboardHelper(dataObj);

      if (!data || !data.success) {
        //failed
        let message;
        if (data?.message) {
          message = data.message;
        } else {
          message = 'Something went wrong, please try again';
        }
        if (data?.error) {
          captureInfo({
            log: data.log,
            dataObj: JSON.stringify(dataObj),
          });
          captureError(new Error(data.error), true);
        }
        ShowToast(message, auth.userPref);
        return {
          success: false,
          message,
        };
      }
      //success
      ShowToast(
        isEdit
          ? 'Dashboard Updated Successfully.'
          : 'Dashboard Created Successfully.',
        auth.userPref,
      );
      return {
        success: true,
      };
    } catch (error) {
      captureError(error);
      return {
        success: false,
        message: 'Something went wrong, please try again',
      };
    }
  };

/**
 *
 * @param {string} dashboardId : Parent's dashboardId or dashboardId of the individual dashboard
 */
const deleteDashboard = (dashboardId) => async (dispatch, getState) => {
  try {
    const {auth} = getState();
    if (!auth.user?.uid) {
      return {
        success: false,
        message: 'Something went wrong, please try again',
      };
    }

    const dataObj = {
      dashboardId,
    };

    const data = await deleteDashboardHelper(dataObj);

    if (!data || !data.success) {
      //failed
      let message;
      if (data?.message) {
        message = data.message;
      } else {
        message = 'Something went wrong, please try again';
      }
      if (data?.error) {
        captureInfo({log: data.log});
        captureError(new Error(data.error), true);
      }
      return {
        success: false,
        message,
      };
    }

    //success
    const {allDashboards} = getState().dashboard;
    const removedDashboardIds = [
      dashboardId,
      ...Object.keys(
        Object.assign({}, allDashboards[dashboardId]?.childDashboards),
      ),
    ];
    dispatch({
      type: DASHBOARD_ACTION.LOAD_ALL_DASHBOARDS,
      payload: omit(allDashboards, removedDashboardIds),
    }); //fallback for slow network
    dispatch(setVisibleDashboards());
    return {
      success: true,
      message: 'Dashboard Deleted Successfully.',
    };
  } catch (error) {
    captureError(error);
    return {
      success: false,
      message: 'Something went wrong, please try again',
    };
  }
};

const getFileDetailsForDashboard =
  ({documentId, dashboardId, documentData}) =>
  async (_, getState) => {
    /**
       * either of {documentId, dashboardId} is passed
       * dashboardId : in case of edit dashboard
       * documentId : in case of creating new dashboard
       * @return //{
            fileData : {tableData , headerData, footerData, fileObj},
            selectedFile: selectedFileDetails (obj),
            dashboardData : returned only when  dashboardId is passed
            splitByColumns : columns on which split by is allowed
            columnList : columns on which dashboard is allowed 
          } 
       */
    try {
      const {
        home,
        dashboard: {allDashboards},
      } = getState();
      let message = '',
        dashboardData;
      if (dashboardId) {
        dashboardData = allDashboards[dashboardId];
        documentId = dashboardData?.originalDocumentId;
      }
      const selectedFileDetails = home.files.find(
        (file) => file.documentId === documentId,
      );
      if (isEmpty(selectedFileDetails)) {
        message = 'File not found. This dashboard can only be deleted.';
        return {
          success: false,
          message,
        };
      } else {
        const isRestrictedPermission =
          !isEmpty(selectedFileDetails.documentMeta?.collab) &&
          [
            SHARE_PERMISSION_TYPE.ENTRY_ONLY,
            SHARE_PERMISSION_TYPE.CUSTOM,
          ].includes(selectedFileDetails.documentMeta.collab.permission);
        if (isRestrictedPermission) {
          message =
            "You don't have permission to perform this action. Please contact file admin or owner.";
          return {
            success: false,
            message,
          };
        }

        const fileData =
          documentData ||
          (await DocumentsMethods.getUserDocumentData(
            selectedFileDetails.documentId,
          ));
        const columnList =
          fileData.headerData.filter(
            (data) =>
              !TOTAL_NOT_ALLOWED.includes(data.fieldType) &&
              !data.hasPrevRowRef &&
              (data.fieldType === FIELD_TYPE_ID.TABLE ||
              data.fieldType === FIELD_TYPE_ID.FORMULA
                ? !TOTAL_NOT_ALLOWED.includes(data.subType)
                : true),
          ) || []; //columns on which dashboard is allowed

        if (columnList.length === 0) {
          message =
            'Document does not have valid column type for dashboard creation';
          return {
            success: false,
            message,
          };
        }

        const splitByColumns =
          fileData.headerData.filter(
            (col) =>
              (SPLIT_BY_ALLOWED_FIELDS.includes(col.fieldType) ||
                (col.fieldType === FIELD_TYPE_ID.TABLE
                  ? SPLIT_BY_ALLOWED_FIELDS.includes(col.subType)
                  : false)) &&
              !col.hasPrevRowRef,
          ) || []; //columns on which split by is allowed

        const data = {
          fileData,
          selectedFile: selectedFileDetails,
          columnList,
          splitByColumns,
        };

        if (!isEmpty(dashboardData)) {
          data.dashboardData = dashboardData;
        }

        return {
          success: true,
          data,
        };
      }
    } catch (error) {
      captureError(error);
      return {
        success: false,
        message: 'Something went wrong, please try again',
      };
    }
  };

const setDashboardTemplateSuggestions = (suggestionArray) => (dispatch) => {
  //used to set just the templateId
  try {
    const dashboardTemplateSuggestions = {};
    suggestionArray.forEach((templateId) => {
      dashboardTemplateSuggestions[templateId] = [];
    });
    dispatch({
      type: DASHBOARD_ACTION.SET_DASHBOARD_TEMPLATE_SUGGESTIONS,
      payload: dashboardTemplateSuggestions,
    });
  } catch (error) {
    captureError(error);
  }
};

const getDashboardTemplateSuggestionsForTemplateId =
  (templateId) => async (dispatch, getState) => {
    try {
      const {
        dashboard: {dashboardTemplateSuggestions},
      } = getState();
      if (!(templateId in dashboardTemplateSuggestions)) {
        return [];
      }
      if (dashboardTemplateSuggestions[templateId]?.length) {
        return dashboardTemplateSuggestions[templateId];
      } else {
        const suggestedDashboards =
          await fetchDashboardTemplateSuggestionFirestore(templateId);
        dispatch({
          type: DASHBOARD_ACTION.SET_DASHBOARD_TEMPLATE_SUGGESTIONS,
          payload: Object.assign({}, dashboardTemplateSuggestions, {
            [templateId]: suggestedDashboards,
          }),
        });
        return suggestedDashboards;
      }
    } catch (error) {
      captureError(error);
      return [];
    }
  };

const setDashboardSuggestedFiles = () => (dispatch, getState) => {
  try {
    const {
      dashboard: {dashboardTemplateSuggestions},
      home: {files},
    } = getState();
    if (isEmpty(dashboardTemplateSuggestions)) {
      return;
    }
    const eligibleFiles = files.filter((file) =>
      isDashboardSuggestionAvailableForFile(file, dashboardTemplateSuggestions),
    );
    const getNewestFile = (fileArr) => {
      let highestIndex = 0,
        highestTimestamp =
          fileArr[highestIndex].documentMeta.lastModifiedTimestamp ||
          fileArr[highestIndex].documentMeta.timestamp;
      for (let index = 0; index < fileArr.length; ++index) {
        const timestamp =
          fileArr[index].documentMeta.lastModifiedTimestamp ||
          fileArr[index].documentMeta.timestamp;
        if (timestamp > highestTimestamp) {
          highestTimestamp = timestamp;
          highestIndex = index;
        }
      }
      return highestIndex;
    };
    let numberOfFiles = 5;
    const suggestedFiles = [];
    while (numberOfFiles-- > 0 && eligibleFiles.length) {
      //used selection sort as for this case its better than orderBy
      const index = getNewestFile(eligibleFiles);
      suggestedFiles.push(eligibleFiles[index]);
      eligibleFiles.splice(index, 1);
    }
    dispatch({
      type: DASHBOARD_ACTION.SET_DASHBOARD_SUGGESTED_FILES,
      payload: suggestedFiles,
    });
  } catch (error) {
    captureError(error);
  }
};

const selectDashboardFields =
  ({fileData, selectedFile, selectedColumn, selectedOperation}) =>
  (dispatch, getState) => {
    try {
      if (!isEmpty(fileData) && !isEmpty(selectedFile)) {
        const prevSelectedFile = getState().dashboard.selectedFile;
        const isFileChanged = !isEqual(selectedFile, prevSelectedFile);
        dispatch({
          type: DASHBOARD_ACTION.SET_SELECTED_FILE,
          payload: {
            fileData,
            selectedFile,
            isFileChanged,
          },
        });
      }
      if (!isEmpty(selectedColumn)) {
        dispatch({
          type: DASHBOARD_ACTION.SET_SELECTED_COLUMN,
          payload: selectedColumn,
        });
      }
      if (!isEmpty(selectedOperation)) {
        dispatch({
          type: DASHBOARD_ACTION.SET_SELECTED_OPERATION,
          payload: selectedOperation,
        });
      }
    } catch (err) {
      captureError(err);
    }
  };

const clearSelectedData = () => (dispatch) => {
  dispatch({
    type: DASHBOARD_ACTION.CLEAR_SELECTION,
  });
};

const getTemplatesForDashboard = () => (dispatch, getState) => {
  try {
    const {
      dashboard: {selectedFile, selectedColumn, selectedOperation, fileData},
      auth: {userPref},
    } = getState();
    const dashboardSuggestionData = [];
    const data = {
      label:
        getLocalText(userPref, FOOTER_OPERATION_TEXT[selectedOperation.id]) +
        '-' +
        getLocalText(userPref, selectedColumn.val),
      docName: selectedFile.documentMeta.name,
      displayObj: {localisationData: {}},
      columnId: selectedColumn.id,
      createdTimeStamp: Date.now(),
      splitBy: [],
      operation: selectedOperation.id,
      pagesType: null,
      orignalDocumentId: selectedFile.documentId,
      dashboardId: Date.now(),
    };

    const {headerData, tableData, fileObj} = fileData;
    const dashboardVal = calculateDataForDashboardHelper(
      {headerData, tableData, fileObj},
      {
        columnObj: selectedColumn || {},
        selectedOperationVal: selectedOperation.id,
      },
      {userPref, userCountry: userPref.country},
    );
    const pages = {
      fieldType: selectedColumn.fieldType,
      name: '',
      docId: selectedFile.documentId,
    };

    if (checkIfPlainText(dashboardVal)) {
      pages.val = dashboardVal;
    } else {
      pages.splitByVal = [];
      dashboardVal.forEach &&
        dashboardVal.forEach((item) =>
          pages.splitByVal.push({
            ['title']: item.title,
            ['value']: item.value,
          }),
        );
    }
    data.pages = [pages];
    dashboardSuggestionData.push(data);
    const isDateTimeDiff =
      selectedColumn.fieldType === FIELD_TYPE_ID.FORMULA &&
      [FIELD_TYPE_ID.TIME, FIELD_TYPE_ID.DATE].includes(selectedColumn.subType);
    if (!isDateTimeDiff) {
      fileData.originalHeaderData.forEach((item, index) => {
        if (
          item.id !== selectedColumn.id &&
          (SPLIT_BY_ALLOWED_FIELDS.includes(item.fieldType) ||
            (item.fieldType === FIELD_TYPE_ID.TABLE &&
              SPLIT_BY_ALLOWED_FIELDS.includes(item.subType)))
        ) {
          // creating dashboard object like data
          const data = {
            label:
              getLocalText(
                userPref,
                FOOTER_OPERATION_TEXT[selectedOperation.id],
              ) +
              '-' +
              getLocalText(userPref, selectedColumn.val) +
              '/' +
              getLocalText(userPref, item.val),
            docName: selectedFile.documentMeta.name,
            displayObj: {localisationData: {}},
            columnId: selectedColumn.id,
            createdTimeStamp: Date.now(),
            splitBy:
              item.fieldType === FIELD_TYPE_ID.TABLE
                ? [
                    {
                      subType: item.subType,
                      fieldType: item.fieldType,
                      id: item.id,
                    },
                  ]
                : [{fieldType: item.fieldType, id: item.id}],
            operation: selectedOperation.id,
            pagesType: null,
            orignalDocumentId: selectedFile.documentId,
            dashboardId: index + Date.now(),
          };
          // const {headerData, tableData, fileObj} = fileData;
          const dashboardVal = calculateDataForDashboardHelper(
            {headerData, tableData, fileObj},
            {
              columnObj: selectedColumn || {},
              selectedOperationVal: selectedOperation.id,
              singleSplitColumnObj: item.id === selectedColumn.id ? null : item,
            },
            {userPref, userCountry: userPref.country},
          );

          const pages = {
            fieldType: selectedColumn.fieldType,
            name: '',
            docId: selectedFile.documentId,
          };

          if (checkIfPlainText(dashboardVal)) {
            pages.val = dashboardVal;
            data.pages = [pages];
            dashboardSuggestionData.push(data);
          } else {
            pages.splitByVal = [];
            dashboardVal.forEach &&
              dashboardVal.forEach((item) =>
                pages.splitByVal.push({
                  ['title']: item.title,
                  ['value']: item.value,
                }),
              );
            if (Object.keys(pages.splitByVal).length) {
              data.pages = [pages];
              dashboardSuggestionData.push(data);
            }
          }
        }
      });
    }
    dispatch({
      type: DASHBOARD_ACTION.SET_SUGGESTED_DASHBOARDS,
      payload: dashboardSuggestionData,
    });
  } catch (error) {
    captureError(error);
  }
};

const getEditDashboardConfigs =
  (dashboardId, reset = false) =>
  async (dispatch, getState) => {
    try {
      if (reset) {
        dispatch({
          type: DASHBOARD_ACTION.SET_EDIT_DASHBOARD_CONFIGS,
          payload: {},
        });
        return;
      }
      const {
        dashboard: {allDashboards},
      } = getState();

      const getColumnNameFromColumnId = (columnId, headerData) => {
        const column = headerData?.filter((column) => column.id == columnId);
        return column?.length && column[0].val;
      };

      const parentDashboardData = allDashboards?.[dashboardId];
      const dashboardConfigs = {
        parentDashboardId: dashboardId,
        dashboardName: parentDashboardData?.dashboardName,
        configsArray: [],
      };

      const getConfigs = async (dashboardId, dashboardData) => {
        const res = await dispatch(
          getFileDetailsForDashboard({
            dashboardId: dashboardId,
            documentId: dashboardData?.originalDocumentId,
          }),
        );
        if (!res?.success) {
          ShowToast(
            res?.message ?? 'Unable to fetch dashboard data. Try again.',
          );
          return null;
        }
        const selectedSplitBy = dashboardData?.splitBy?.length
          ? [
              {
                id: dashboardData?.splitBy?.[0]?.id,
                fieldType: dashboardData?.splitBy?.[0]?.fieldType,
                title: getColumnNameFromColumnId(
                  dashboardData?.splitBy?.[0]?.id,
                  res?.data?.fileData?.headerData,
                ),
                value: dashboardData?.splitBy?.[0]?.id,
                subType: dashboardData?.splitBy?.[0]?.subType,
              },
            ]
          : null;
        dashboardConfigs?.configsArray?.push({
          fileData: res?.data?.fileData,
          columnList: res?.data?.columnList,
          splitByColumns: res?.data?.splitByColumns,
          selectedColumn: {
            title: getColumnNameFromColumnId(
              dashboardData?.columnId,
              res?.data?.fileData?.headerData,
            ),
            value: dashboardData?.columnId,
          },
          selectedOperation: {
            title: capitalize(dashboardData?.operation),
            value: dashboardData?.operation,
          },
          selectedSplitBy: selectedSplitBy,
          dashboardId: dashboardId,
          selectedFile: res?.data?.selectedFile,
        });
      };
      if (isPlainObject(parentDashboardData?.childDashboards)) {
        Promise.all(
          Object.keys(parentDashboardData.childDashboards).map(
            (dashboardId) => {
              const dashboardData = allDashboards?.[dashboardId];
              return getConfigs(dashboardId, dashboardData);
            },
          ),
        ).then((res) => {
          dispatch({
            type: DASHBOARD_ACTION.SET_EDIT_DASHBOARD_CONFIGS,
            payload: dashboardConfigs,
          });
          return dashboardConfigs;
        });
      } else {
        await getConfigs(dashboardId, parentDashboardData);
        dispatch({
          type: DASHBOARD_ACTION.SET_EDIT_DASHBOARD_CONFIGS,
          payload: dashboardConfigs,
        });
        return dashboardConfigs;
      }
    } catch (error) {
      captureError(error);
    }
  };

const checkAndMigrateUserOlderDashboards = () => async (dispatch, getState) => {
  try {
    const {
      auth: {userPref},
    } = getState();

    if (userPref.areOlderDashboardsMigrated !== true) {
      dispatch({
        type: DASHBOARD_ACTION.UPDATE_DASHBOARDS_MIGRATION_LOADER,
        payload: true,
      });

      const response = await migrateUserOlderDashboardsCloud({});

      if (response?.success) {
        dispatch(setUserPref({areOlderDashboardsMigrated: true}));
      }

      dispatch({
        type: DASHBOARD_ACTION.UPDATE_DASHBOARDS_MIGRATION_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    captureError(error);
  }
};

export {
  getTemplatesForDashboard,
  selectDashboardFields,
  clearSelectedData,
  activateDashboardListener,
  createNewDashboard,
  deleteDashboard,
  getFileDetailsForDashboard,
  setDashboardSuggestedFiles,
  setDashboardTemplateSuggestions,
  getDashboardTemplateSuggestionsForTemplateId,
  getEditDashboardConfigs,
  checkAndMigrateUserOlderDashboards,
  deactivateDashboardListener,
  setVisibleDashboardPremiumLimitingObj,
};
