import {FIELD_TYPE_ID} from 'rb-redux/utils/constant';
import theme from '../../../utilsWeb/designTheme';
import {formatCurrency} from 'rb-redux/utils/utils';
// custom tool top:
export const ExternalToolTipHandler = (context, columnDetails) => {
  // const classes = useStyles(theme);
  // Tooltip Element
  const isCurrency =
    columnDetails?.fieldType === FIELD_TYPE_ID.RUPEE ||
    (columnDetails?.fieldType === FIELD_TYPE_ID.TABLE &&
      columnDetails?.subType === FIELD_TYPE_ID.RUPEE) ||
    (columnDetails?.fieldType === FIELD_TYPE_ID.FORMULA &&
      columnDetails?.subType === FIELD_TYPE_ID.RUPEE);
  const {chart, tooltip} = context;
  const tooltipEl = getOrCreateTooltip(chart);
  const title = tooltip?.dataPoints?.[0]?.label || '';
  const content = isCurrency
    ? formatCurrency(
        parseFloat(tooltip?.dataPoints?.[0]?.formattedValue.replace(/,/g, '')),
        '',
        columnDetails,
      )
    : tooltip?.dataPoints?.[0]?.formattedValue || '';
  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if (tooltip.body) {
    // const tableHead = document.createElement('thead');
    const tr = document.createElement('div');
    tr.style.borderWidth = 0;
    tr.style.maxWidth = '400px';
    // title:
    const th = document.createElement('span');
    th.style.borderWidth = 0;
    th.style.margin = '0';
    th.style.padding = '0';
    th.style.fontSize = theme.fontSize.font15;
    th.style.fontWeight = '500';
    th.style.lineHeight = theme.palette.lineHeight0;
    th.style.maxWidth = '400px';
    th.style.textAlign = 'center';
    th.style.whiteSpace = 'normal';
    th.style.overflowWrap = 'break-word';
    const text = document.createTextNode(title);
    // content:
    const th1 = document.createElement('span');
    th1.style.borderWidth = 0;
    th1.style.borderWidth = '0';
    th1.style.margin = '0';
    th1.style.padding = '0';
    th1.style.color = theme.palette.gray300;
    th1.style.fontSize = theme.fontSize.font12;
    th1.style.maxWidth = '400px';
    th1.style.textAlign = 'center';
    th1.style.whiteSpace = 'normal';
    th1.style.overflowWrap = 'break-word';
    const text1 = document.createTextNode(content);

    th.appendChild(text);
    th1.appendChild(text1);
    tr.appendChild(th);
    tr.appendChild(th1);
    tr.style.display = 'flex';
    tr.style.flexDirection = 'column';
    tr.style.alignItems = 'center';
    tr.style.rowGap = '6px';

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tr);
  }

  const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};
const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';
    table.style.fontFamily = 'Inter';
    table.style.caretColor = 'transparent';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};
