import {ShowToast, captureError, getReduxState} from '../../imports';
import {
  getFooterVisiblityAndType,
  getTimezone,
  checkIfTimeBasedFilter,
  callCloudFunction,
} from '../../utils/utils';
import {
  CLOUD_FUNCTION_PATHS,
  FIELD_TYPE_ID,
  RANGE_FILTER_FIELDS,
  TIME_BASED_RANGE_FILTER_FIELDS,
  NESTED_ARRAY_FILTER_CONFIG_FIELDS,
  CLOUD_FUNCTION_COMMON_PARAMS,
} from '../../utils/constant';
import moment from 'moment';
import {forOwn, isArray, isEmpty, isNil} from 'lodash';
import {calculateTotal, getTotalObject} from '../../utils/equationHelper';

export const commonBodyParamsForElastic = (auth) =>
  Object.assign(
    {},
    {
      userCountry: auth.userCountry,
      userTimezone: auth.userPref?.timezone ?? getTimezone(),
      userLang: auth.userPref?.lang ?? 'EN',
    },
  );

export const elasticSearchTextInDoc = (obj) => {
  return callCloudFunction(
    getReduxState().remoteConfig.isOrganisationMode
      ? CLOUD_FUNCTION_PATHS.MINI_APPS_SEARCH_FILTER_DATA
      : CLOUD_FUNCTION_PATHS.SEARCH_TEXT_IN_DOC,
    obj,
    null,
    CLOUD_FUNCTION_COMMON_PARAMS.ELASTIC_REQUEST,
  );
};

export const elasticSearchFilterParams = (obj) =>
  callCloudFunction(
    getReduxState().remoteConfig.isOrganisationMode
      ? CLOUD_FUNCTION_PATHS.GET_FILTER_SELECTABLE_PARAMS_ORG
      : CLOUD_FUNCTION_PATHS.GET_FILTER_SELECTABLE_PARAMS,
    obj,
    null,
    CLOUD_FUNCTION_COMMON_PARAMS.ELASTIC_REQUEST,
  );

export const elasticSearchApplyFilter = (obj) =>
  callCloudFunction(
    getReduxState().remoteConfig.isOrganisationMode
      ? CLOUD_FUNCTION_PATHS.MINI_APPS_SEARCH_FILTER_DATA
      : CLOUD_FUNCTION_PATHS.APPLY_FILTER_ON_COLUMNS,
    obj,
    null,
    CLOUD_FUNCTION_COMMON_PARAMS.ELASTIC_REQUEST,
  );

export const getNoOFNonEmptyRows = (obj) =>
  callCloudFunction(
    CLOUD_FUNCTION_PATHS.GET_NO_OF_NON_EMPTY_ROWS,
    obj,
    null,
    CLOUD_FUNCTION_COMMON_PARAMS.ELASTIC_REQUEST,
  );

export const getFilterObj = (filterColumnInfo, columnId, updatedObj) =>
  Object.assign({}, filterColumnInfo, {
    [columnId]: Object.assign(
      {},
      {
        areOptionsAvailable:
          filterColumnInfo[columnId]?.areOptionsAvailable ?? false,
        isActive: filterColumnInfo[columnId]?.isActive ?? false,
        options: filterColumnInfo[columnId]?.options ?? [],
        selectedOptions: filterColumnInfo[columnId]?.selectedOptions ?? [],
      },
      updatedObj,
    ),
  });

export const checkIfValidFilterConfig = (
  fieldType,
  selectedOptions,
  userPref,
  showToast = true,
) => {
  try {
    if (RANGE_FILTER_FIELDS.includes(fieldType)) {
      const lowerLimit = parseFloat(selectedOptions[0]);
      const upperLimit = parseFloat(selectedOptions[1]);
      if (isNil(selectedOptions[0]) || isNil(selectedOptions[1])) {
        //do nothing
      } else if (
        selectedOptions.length !== 2 ||
        isNaN(upperLimit) ||
        isNaN(lowerLimit) ||
        lowerLimit > upperLimit
      ) {
        showToast && ShowToast('Please enter valid range.', userPref);
        return [false];
      } else {
        selectedOptions = [lowerLimit, upperLimit];
      }
    } else if (TIME_BASED_RANGE_FILTER_FIELDS.includes(fieldType)) {
      const isTime = fieldType === FIELD_TYPE_ID.TIME;
      const format = isTime ? 'hh:mm A' : 'DD/MM/YYYY';
      const upperLimit = moment(selectedOptions[1], format);
      const lowerLimit = moment(selectedOptions[0], format);

      if (fieldType === FIELD_TYPE_ID.DATE_TIME) {
        const startDateTime = moment(selectedOptions[0], 'DD/MM/YYYY hh:mm A');
        const endDateTime = moment(selectedOptions[1], 'DD/MM/YYYY hh:mm A');
        if (checkIfTimeBasedFilter(selectedOptions[0])) {
          //do nothing
        } else if (
          selectedOptions.length !== 2 ||
          !startDateTime.isValid() ||
          !endDateTime.isValid() ||
          !startDateTime.isSameOrBefore(endDateTime)
        ) {
          showToast && ShowToast('Please enter valid range.', userPref);
          return [false];
        }
      } else if (checkIfTimeBasedFilter(selectedOptions[0])) {
        //do nothing
      } else if (
        selectedOptions.length !== 2 ||
        !upperLimit.isValid() ||
        !lowerLimit.isValid() ||
        !lowerLimit.isSameOrBefore(upperLimit)
      ) {
        showToast && ShowToast('Please enter valid range.', userPref);
        return [false];
      }
    }
  } catch (err) {
    captureError(err);
  }
  return [true, selectedOptions];
};

export const getSearchFilterCalculatedFooterData = (
  actualFooterData,
  searchFilterTableData,
  searchFilterRowIdDataMap,
  headerData,
) => {
  const resFooterData = {};
  try {
    if (!isEmpty(actualFooterData)) {
      const tableData = searchFilterTableData.map((rowId) =>
        Object.assign({}, searchFilterRowIdDataMap[rowId]),
      );
      forOwn(actualFooterData, (footerObj, colId) => {
        const {isTotalVisible} = getFooterVisiblityAndType(
          {[colId]: footerObj},
          colId,
        );
        if (isTotalVisible) {
          const totalObj = calculateTotal(tableData, colId, headerData);
          resFooterData[colId] = getTotalObject(footerObj, totalObj);
        }
      });
    }
  } catch (err) {
    captureError(err);
  }
  return resFooterData;
};

export const getCheckBoxOrSwitchOptionsForFilter = (columnObj) => {
  return [true, false];
};

export const getLabelOrSelectOptionsForFilter = (
  columnObj,
  isLabel = false,
  isDirestResponse = false,
  searchText = '',
) => {
  const result = new Set();
  try {
    (columnObj?.selectElements ?? []).forEach((element) => {
      const val = isLabel
        ? element?.val
        : `${element?.displayName?.EN ?? element?.val ?? ''}`;
      if (!isNil(val) && val !== '') {
        if (
          searchText &&
          searchText.trim() &&
          val.toLowerCase().includes(searchText.trim().toLowerCase())
        ) {
          result.add(`${val}`.trim());
        } else if (!searchText) {
          result.add(`${val}`.trim());
        }
      }
    });
  } catch (err) {
    captureError(err);
  }
  const valsArr = [...result];
  if (isDirestResponse) {
    return valsArr;
  }
  return {
    success: true,
    response: {valsArr},
  };
};

//!IMP : Any change in this function should also be made in cloud-functions
export const mapFilterArrForStoringFirestore = (filterArr = []) => {
  try {
    if (!filterArr || !isArray(filterArr)) {
      return [];
    }
    return filterArr.map((filterObj) => {
      if (NESTED_ARRAY_FILTER_CONFIG_FIELDS.includes(filterObj.fieldType)) {
        return Object.assign({}, filterObj, {
          selectedOptions:
            filterObj.selectedOptions === undefined
              ? ''
              : JSON.stringify(filterObj.selectedOptions),
        });
      }
      return filterObj;
    });
  } catch (err) {
    captureError(err);
  }
  return filterArr;
};

//!IMP : Any change in this function should also be made in cloud-functions
export const mapFilterArrFromFirestore = (filterArr = []) => {
  //change on all cloud repo also
  try {
    if (!filterArr || !isArray(filterArr)) {
      return [];
    }
    return filterArr.map((filterObj) => {
      if (typeof filterObj.selectedOptions === 'string') {
        return Object.assign({}, filterObj, {
          selectedOptions:
            typeof filterObj.selectedOptions === 'string'
              ? JSON.parse(filterObj.selectedOptions)
              : filterObj.selectedOptions,
        });
      }
      return filterObj;
    });
  } catch (err) {
    captureError(err);
  }
  return filterArr;
};
