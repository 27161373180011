const ScreenSettingIcon = ({color}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.25 9.25H10.75V10.75H6.25V9.25ZM4.75 3.25H3.25V4.75H4.75V3.25ZM6.25 7.75H10.75V6.25H6.25V7.75ZM6.25 4.75H10.75V3.25H6.25V4.75ZM4.75 6.25H3.25V7.75H4.75V6.25ZM13.75 1.75V12.25C13.75 13.075 13.075 13.75 12.25 13.75H1.75C0.925 13.75 0.25 13.075 0.25 12.25V1.75C0.25 0.925 0.925 0.25 1.75 0.25H12.25C13.075 0.25 13.75 0.925 13.75 1.75ZM12.25 1.75H1.75V12.25H12.25V1.75ZM4.75 9.25H3.25V10.75H4.75V9.25Z"
        fill={color ? color : '#495A74'}
      />
    </svg>
  );
};
export default ScreenSettingIcon;
