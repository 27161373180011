import {solveEqnStr} from '../equationHelper';
import {isNil, forOwn} from 'lodash';
import {DASHBOARD_ERRORS, INVALID_INPUT} from '../constant';
import {formatFooterCell} from '../formatDataHelper';

const isNilAndErrored = (oldVal, newVal) =>
  isNil(oldVal) && newVal in DASHBOARD_ERRORS;

/*
    accumulates data in one place
    
    eg let obj = { key1 : 100, key2 : 200 }

    let obj1 = mergeAggregatedData( obj, key2, 400);
    let obj2= mergeAggregatedData( obj, key3, 100);

    => obj1 == { key1 : 100, key2 : 600 ,
    => obj2 == { key1 : 100, key2 : 200, key3 : 100 }
*/

// @params
// accumulator :- previously accumulated data
// newKey :- key u want to add or modify
// newVal :- value u want to add

function mergeDashboardVal(accumulator, newKey, newVal) {
  const prevValue = accumulator?.[newKey];
  return {
    ...(accumulator ?? {}),
    [newKey]: isNilAndErrored(prevValue, newVal)
      ? newVal
      : solveEqnStr(`${prevValue ?? 0}+${newVal}`),
  };
}

function mergeFilesVal(accumulator, columnName, docId, value) {
  /**  accumulator = { 
            [colName]: {
              [docId]: number,
            }, 
          }

          filesVal = {
                        [pageName]: accumulator
                     }
   */
  return {
    ...(accumulator ?? {}),
    [columnName]: Object.assign({}, accumulator?.[columnName], {
      [docId]: value,
    }),
  };
}

function mergeSplitByVals(accumulator = {}, columnName, newSplitByVals) {
  /*
        accumulator == {
           {
                [columnName : string] :  {
                    [splitByValKey : string] : number
                }
           }
        }

        newSplitByVals == [{title, rawValue, value : formattedValue}]
    */

  const totalColObj = Object.assign({}, accumulator?.[columnName]);

  newSplitByVals.forEach(({title, rawValue}) => {
    totalColObj[title] = solveEqnStr(`${totalColObj[title] ?? 0}+${rawValue}`);
  });

  return {
    ...(accumulator ?? {}),
    [columnName]: totalColObj,
  };
}

function formatSingleLevelVals(
  accumulator,
  fieldTypeMappingObjMain,
  userPref,
  userCountry,
) {
  forOwn(accumulator, (pageObj, pageName) => {
    forOwn(pageObj, (value, key) => {
      const isErrored = value === INVALID_INPUT || value in DASHBOARD_ERRORS;
      if (!isErrored) {
        const fieldTypeMappingObj = fieldTypeMappingObjMain[key];
        accumulator[pageName][key] = formatFooterCell(
          {val: value, type: fieldTypeMappingObj.operation},
          {
            fieldType: fieldTypeMappingObj.fieldType,
            subType: fieldTypeMappingObj.subType,
            ...(fieldTypeMappingObj.extraMetaForUnit ?? {}),
          },
          userPref,
          userCountry,
          fieldTypeMappingObj.fileObj,
        );
      }
    });
  });
  return accumulator;
}

function formatDoubleLevelVals(
  accumulator,
  fieldTypeMappingObjMain,
  userPref,
  userCountry,
) {
  forOwn(accumulator, (pageObj, pageName) => {
    forOwn(pageObj, (colObj, columnName) => {
      forOwn(colObj, (value, docId) => {
        const isErrored = value === INVALID_INPUT || value in DASHBOARD_ERRORS;
        if (!isErrored) {
          const fieldTypeMappingObj = fieldTypeMappingObjMain[columnName];
          accumulator[pageName][columnName][docId] = formatFooterCell(
            {val: value, type: fieldTypeMappingObj.operation},
            {
              fieldType: fieldTypeMappingObj.fieldType,
              subType: fieldTypeMappingObj.subType,
              ...(fieldTypeMappingObj.extraMetaForUnit ?? {}),
            },
            userPref,
            userCountry,
            fieldTypeMappingObj.fileObj,
          );
        }
      });
    });
  });
  return accumulator;
}

export {
  mergeDashboardVal,
  mergeFilesVal,
  mergeSplitByVals,
  formatSingleLevelVals,
  formatDoubleLevelVals,
};
