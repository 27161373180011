import {ASYNC_STORAGE_KEY} from './constant';
import {checkIfPlainText} from './utils';

export const first10RowAddEvent = async () => {
  const {AsyncStorage, logAnalyticsEvent, captureError} = require('../imports');
  try {
    const old_val = await AsyncStorage().getItem(
      ASYNC_STORAGE_KEY.TOTAL_ROWS_EXCEED_10_COUNTER,
    );
    if (checkIfPlainText(old_val) && parseInt(old_val) < 10) {
      // VALUE IS SET IN COUNTER && LESS THAN 10
      let count = parseInt(old_val);
      count = count + 1;
      if (count == 10) {
        logAnalyticsEvent('DATA_ADDED_10_ROW_G');
      }
      AsyncStorage().setItem(
        ASYNC_STORAGE_KEY.TOTAL_ROWS_EXCEED_10_COUNTER,
        `${count}`,
      );
    } else {
      if (!checkIfPlainText(old_val)) {
        // VALUE NOT SET IN COUNTER THEN SET '1'
        await AsyncStorage().setItem(
          ASYNC_STORAGE_KEY.TOTAL_ROWS_EXCEED_10_COUNTER,
          '1',
        );
      }
    }
  } catch (err) {
    captureError(err);
  }
};

export const MIXPANEL_NON_ORG_ALLOWED_EVENTS = [
  'LANGUAGE_SET',
  'GET_LOGIN_STARTED',
  'LOGIN_INIT',
  'LOGIN_SUCCESS',
  'RESEND_OTP',
  'WEB_VIEW_OPEN',
  'WEB_VIEW_CLOSE',
  'p_VIEW_ONBOARDING_ADD_INFO',
  'VIEW_ONBOARDING_ADD_USECASE',
  'VIEW_ONBOARDING_ADD_BUSINESS_INFO',
  'ONBOARDING_ADD_INFO',
  'ONBOARDING_ADD_BUSINESS_INFO',
  'ONBOARDING_ADD_USECASE',
  'VIEW_ONBOARDING_VIDEO',
  'ONBOARDING_FLOW_SUBMIT_INIT',
  'ONBOARDING_FLOW_ADD_USE',
  'COMPLETED_ONBOARDING_VIDEO',
  'ONBOARDING_FLOW_SUBMIT_SUCCESS',
  'p_ONBOARDING_SUCCESS',
];
