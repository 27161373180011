const SortByIconDark = ({color}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9997 12L8.33301 9.33333H10.333V2.66667H8.33301L10.9997 0L13.6663 2.66667H11.6663V9.33333H13.6663M0.333008 10.6667V9.33333H6.99967V10.6667M0.333008 6.66667V5.33333H4.99967V6.66667M0.333008 2.66667V1.33333H2.99967V2.66667H0.333008Z"
        fill={color ? color : '#495A74'}
      />
    </svg>
  );
};
export default SortByIconDark;
