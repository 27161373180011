import {firestore, captureError} from '../../../imports';

export const createReminderFirestore = (uid, reminderId, reminderData) => {
  setReminderInFirestore(uid, reminderId, reminderData);
  try {
    const docId = `${uid}_${reminderId}`;
    firestore()
      .collection('users')
      .doc(uid)
      .collection('reminders')
      .doc(docId)
      .set(Object.assign({}, reminderData));
  } catch (error) {
    captureError(error);
  }
};

export const setReminderInFirestore = (uid, reminderId, reminderData) => {
  try {
    const docId = `${uid}_${reminderId}`;
    firestore()
      .collection('reminders')
      .doc(docId)
      .set(Object.assign({}, reminderData, {userId: uid}));
  } catch (error) {
    captureError(error);
  }
};

export const deleteReminderFirestore = (uid, reminderId) => {
  removeReminderFromMainCollection(uid, reminderId);
  try {
    const docId = `${uid}_${reminderId}`;
    firestore()
      .collection('users')
      .doc(uid)
      .collection('reminders')
      .doc(docId)
      .delete();
  } catch (error) {
    captureError(error);
  }
};

export const removeReminderFromMainCollection = (uid, reminderId) => {
  try {
    const docId = `${uid}_${reminderId}`;
    firestore().collection('reminders').doc(docId).delete();
  } catch (error) {
    captureError(error);
  }
};
