import {MINI_APPS, MINI_APPS_FIELDS} from 'rb-redux/utils/constant';
import {FIELD_TYPE_ID} from 'rb-redux/utils/constant';
import dashboardIcon from '../assets/img/dashboardIcon.svg';
import fileEditIcon from '../assets/img/fileEditIcon.svg';
import filterIconYellow from '../assets/img/filterIconYellow.svg';
import ProfileIcon from '../assets/img/profileIcon.svg';
import OrgIcon from '../assets/img/orgIcon.svg';
import TeamIcon from '../assets/img/teamIcon.svg';
import AccountIcon from '../assets/img/accountIcon.svg';
import SecurityIcon from '../assets/img/securityIcon.svg';
import SubscriptionIcon from '../assets/img/subscriptionIcon.svg';
import PaymentIcon from '../assets/img/paymentIcon.svg';
import ScreenSetting from '../assets/img/screenSettingIcon.svg';
import QuickFilter from '../assets/img/quickFiltersAppSetting.svg';
import EditIconWithBlue from '../assets/img/editIconWithBlue.svg';
import RolePermissions from '../assets/img/role-permissions.svg';
import ManageScreenIcon from '../assets/img/IconmanageScreens.svg';
import EditFieldsIconDark from '../assets/img/editFieldsIconDark.svg';
import SortByIconDark from '../assets/img/sortByIconDark.svg';
import ActionButtonIcon from '../assets/img/actionBtnIcon.svg';
import PlusIcon from '../assets/img/plus.svg';
import allAppsIcon from '../assets/img/inbox-full-outline.svg';
import image1 from '../assets/img/installAppImage1.svg';
import image2 from '../assets/img/installAppImage.svg';
import PlusBlueIcon from '../assets/img/plusWithBlueBackground.svg';
import MessageIcon from '../assets/img/messageOutlineBlue.svg';
import WhatsAppIcon from '../assets/img/whatAppBlue.svg';
import PeperClipIcon from '../assets/img/peperClipIcon.svg';
import URLIcon from '../assets/img/urlIconBlue.svg';
import VideoIcon from '../assets/img/videoBlueIcon.svg';
import EmailIcon from '../assets/img/emailBlue.svg';
import CopyIcon from '../assets/img/copyIconBlue.svg';
import PhoneIcon from '../assets/img/phoneoutLine.svg';
import NavigationIcon from '../assets/img/navigationIconBlue.svg';
import PlayMusic from '../assets/img/playMusic.svg';
import ScreenDefaultIcon from '../assets/img/screenDefaultIcon.svg';
import CalendarViewIcon from '../assets/img/calendarViewIcon.svg';
import ScreenLayoutIcon from '../assets/img/screenLayout.svg';
import AutomationIcon from '../assets/img/automationOutlineIcon.svg';
import EditIcon from '../assets/img/editIconWithBlue.svg';
import CheckCircle from '../assets/img/checkCircle.svg';
import ClickIcon from '../assets/img/actionBtnIcon.svg';
import DeleteIcon from '../assets/img/deleteIconWithRedoutline.svg';
import UserIcon from '../assets/img/assignUser.svg';
import WhatsappIcon from '../assets/img/whatappGreen.svg';
import FormEdit from '../assets/img/formEdit.svg';
import Notification from '../assets/img/notificationBell.svg';
import MoveIcon from '../assets/img/MoveIcon.svg';
import UserGrayIcon from '../assets/img/user.svg';
import StarIcon from '../assets/img/starGray.svg';
import UserPermissionIcon from '../assets/img/userPermission.svg';
import CallGrayIcon from '../assets/img/callGrayIcon.svg';
import WhatsAppGrayIcon from '../assets/img/whatAppIconGray.svg';
import CopyGrayIcon from '../assets/img/copyIconGray.svg';
import VideoGrayIcon from '../assets/img/videoIconGray.svg';
import SmsGrayIcon from '../assets/img/smsGray.svg';
import PlayAudioGray from '../assets/img/playAudioGray.svg';
import PeperClipGrayIcon from '../assets/img/peperClipGray.svg';
import OpenUrlGray from '../assets/img/openUrlGray.svg';
import NavigationGrayIcon from '../assets/img/navigationGray.svg';
import EmailGrayIcon from '../assets/img/emailGray.svg';
import ImageIconBlue from '../assets/img/imageFieldBlueIcon.svg';
import ImageIconGrayIcon from '../assets/img/imageFieldType.svg';
import {ReactComponent as CancelIcon} from '../assets/img/cancelIcon.svg';
import {ReactComponent as EraserIcon} from '../assets/img/eraserIcon.svg';
import {ReactComponent as AddDefaultValueIcon} from '../assets/img/addDefaultValueIcon.svg';
import {ReactComponent as ChevronRight} from '../assets/img/chevronRightSmall.svg';
import {ReactComponent as BoldIcon} from '../assets/img/boldIcon.svg';
import {ReactComponent as ItalicsIcon} from '../assets/img/italicsIcon.svg';
import {ReactComponent as UnderlineIcon} from '../assets/img/underlineIcon.svg';
import {ReactComponent as StrikeIcon} from '../assets/img/strikeIcon.svg';
import {ReactComponent as BorderBottomIcon} from '../assets/img/borderBottomIcon.svg';
import {ReactComponent as BorderTopIcon} from '../assets/img/borderTopIcon.svg';
import {ReactComponent as BorderRightIcon} from '../assets/img/borderRightIcon.svg';
import {ReactComponent as BorderLeftIcon} from '../assets/img/borderLeftIcon.svg';
import ActionBtnIcon from '../assets/img/actionBtnIcon';
import EditFieldsIcon from '../assets/img/EditFieldsIcon';
import EditIconWithBlues from '../assets/img/EditIcon';
import IconmanageScreens from '../assets/img/IconmanageScreens';
import RolePermissionss from '../assets/img/rolePermissions';
import ScreenLayoutIcons from '../assets/img/ScreenLayoutIcon';
import QuickFilterIcon from '../assets/img/QuickFilterIcon';
import SortByIcon from '../assets/img/sortByIconDark';
import ScreenSettingIcon from '../assets/img/ScreenSettingIcon';
import AutomationsIcon from '../assets/img/AutomationIcon';
import AggregationIcon from '../assets/imageComponents/AggregationsIcon';
import AddComment from '@material-ui/icons/AddCommentOutlined';
import Comment from '@material-ui/icons/CommentOutlined';
import IntegrationIcon from '../assets/img/integrationIcon';
import EmailFieldIcon from '../assets/img/emailField.svg';
import DuplicateIcon from '../assets/img/duplicate.svg';
import ReportIcon from '../assets/img/reportScreenIcon.svg';
import helpIcon from '../assets/img/helpIconApp.svg';
import {ReactComponent as GridIcon} from '../assets/img/gridIconV2.svg';
import ReorderIcon from '@material-ui/icons/Reorder';
import ViewColumnOutlinedIcon from '@material-ui/icons/ViewColumnOutlined';
import TodayIcon from '@material-ui/icons/Today';

import {
  DEFAULT_OPERATION_TYPE,
  AUTOMATION_OPERATION_TYPES,
  DEFAULT_VALUE_MAPPING_OPTIONS,
  // DEFAULT_OPERATION_TYPE,
} from 'rb-redux/utils/automationConstants';
import theme from './designTheme';
import MyAppsIcon from '../assets/img/MyAppsIcon';
import AppLaunchIcon from '../assets/img/appLauchIconUnselected.svg';
import KanbanViewIcon from '../assets/img/kanbanIcon.svg';
import GridViewIcon from '../assets/img/gridIcon.svg';
export const MINI_APPS_WEB = {
  CARD_LAYOUT_HEIGHT: {
    [MINI_APPS.LAYOUTS.DEFAULT]: 112,
    [MINI_APPS.LAYOUTS.COMPACT]: 88,
    [MINI_APPS.LAYOUTS.CONTACT_CARD]: 100,
    [MINI_APPS.LAYOUTS.LIST]: 54,
    [MINI_APPS.LAYOUTS.CATALOG]: 0,
    //TODO : add changes for catalog
    // [MINI_APPS.LAYOUTS.DEFAULT]: '144',
    // [MINI_APPS.LAYOUTS.COMPACT]: '120',
    // [MINI_APPS.LAYOUTS.CONTACT_CARD]: '132',
    // [MINI_APPS.LAYOUTS.LIST]: '86',
  },
  CARD_LAYOUT_MAPPING_LENGTH: {
    [MINI_APPS.LAYOUTS.DEFAULT]: 6,
    [MINI_APPS.LAYOUTS.COMPACT]: 3,
    [MINI_APPS.LAYOUTS.CONTACT_CARD]: 4,
    [MINI_APPS.LAYOUTS.LIST]: 2,
  },
  APP_LIST_SIDEBAR_OPTIONS: {
    ALL_APPS: 'ALL_APPS',
    OWNER: 'OWNER',
    SHARED: 'SHARED',
    DRAFT: 'DRAFT',
    MANAGE_TEAM: 'MANAGE_TEAM',
    SETTINGS: 'SETTINGS',
    EXPLORE: 'EXPLORE',
  },
};

export const MINIAPP_SHARE_PERMISSION_TYPE = {
  ADMIN: 'ADMIN',
  CAN_EDIT: 'CAN_EDIT',
  ENTRY_ONLY: 'ENTRY_ONLY',
  CAN_VIEW: 'CAN_VIEW',
  CUSTOM: 'CUSTOM',
  OWNER: 'OWNER',
};

// TODO : Need to review this
export const MINIAPP_SHARE_PERMISSION_TYPE_DETAILS = {
  ADMIN: {
    text: 'Admin',
    iconName: 'account-settings',
    description: 'Can edit, share document and modify permissions',
  },
  CAN_EDIT: {
    text: 'Can Edit',
    iconName: 'pencil',
    description: 'Can view and edit data',
  },
  ENTRY_ONLY: {
    text: 'Entry Only',
    iconName: 'table-large-plus',
    // description: 'Can only add new entries and view own entries',
  },
  ENTRY_ONLY_CUSTOMIZE: {
    text: 'Customize',
    iconName: 'table-cog',
    description:
      'Customize advanced options with entry only permission for the shared users',
  },
  CAN_VIEW: {
    text: 'Can View',
    iconName: 'eye',
    description: 'Can only view the data',
  },
  CUSTOM: {
    text: 'Custom',
    iconName: 'format-list-bulleted-square',
    description: 'Customise Edit/View/Hide properties for every column',
  },
  OWNER: {text: 'Owner'}, //not to be listed in actual types
};

export const MINIAPP_VIEW_STATES = {
  ADD_SCREEN: 'ADD_SCREEN',
  MANAGE_SCREEN: 'MANAGE_SCREEN',
  EDIT_SCREEN: 'EDIT_SCREEN',

  SCREEN_DATA: 'SCREEN_DATA',
  ROW_DETAILS: 'ROW_DETAILS',
  ADD_ENTRY: 'ADD_ENTRY',
  EDIT_ENTRY: 'EDIT_ENTRY',

  SHARING: 'SHARING',
  RENAME_APP: 'RENAME_APP',
  DASHBOARD: 'DASHBOARD',
  DASHBOARD_CREATE: 'DASHBOARD_CREATE',
  DASHBOARD_CONFIGURE: 'DASHBOARD_CONFIGURE',
  IS_SELECT_RECORDS: 'IS_SELECT_RECORDS',
  APP_SETTINGS: 'APP_SETTINGS',
  INTEGRATIONS: 'INTEGRATIONS',
  ADD_INTEGRATION_STEP_ONE: 'ADD_INTEGRATION_STEP_ONE',
  ADD_INTEGRATION_STEP_TWO: 'ADD_INTEGRATION_STEP_TWO',
  ADD_INTEGRATION_STEP_THREE: 'ADD_INTEGRATION_STEP_THREE',
  REPORT: 'REPORT',
  ENTRY_STATUS_LIST: 'ENTRY_STATUS_LIST',
};

export const MINIAPP_VIEW_STATES_SUBTYPE = {
  // MINIAPP_VIEW_STATES.ADD_ENTRY and MINIAPP_VIEW_STATES.EDIT_ENTRY SubTypes
  ADD_ENTRY_TO_PARENT: 'ADD_ENTRY_TO_PARENT',
  EDIT_ENTRY_PARENT: 'EDIT_ENTRY_PARENT',
  EDIT_ENTRY_IN_CHILD: 'EDIT_ENTRY_IN_CHILD',
  ACTION_BUTTON_OPEN_FORM: 'ACTION_BUTTON_OPEN_FORM',
  DUPLICATE_ROW: 'DUPLICATE_ROW',
  ADD_ENTRY_FOR_FAILED_CASE: 'ADD_ENTRY_FOR_FAILED_CASE',
  LIST_ADD_EDIT: 'LIST_ADD_EDIT',

  // MINIAPP_VIEW_STATES.ROW_DETAILS SubTypes
  PARENT_DETAILS: 'PARENT_DETAILS',
  CHILD_ROW_DETAILS: 'CHILD_ROW_DETAILS',
  ROW_DETAILS_VIA_ENTRY_STATUS: 'ROW_DETAILS_VIA_ENTRY_STATUS',
  LIST_ROW_DETAILS: 'LIST_ROW_DETAILS',

  // MINIAPP_VIEW_STATES.SCREEN_DATA SubTypes
  CHILD_ROWS: 'CHILD_ROWS',
  ADD_CUSTOM_ACTION: 'ADD_CUSTOM_ACTION',
  ADD_AUTOMATION: 'ADD_AUTOMATION',
  LIST_ROWS: 'LIST_ROWS',
  RUNTIME_MANUAL_AUTOMATION: 'RUNTIME_MANUAL_AUTOMATION',
};

export const MINIAPP_VIEW_STATES_SOURCES = {
  DUPLICATE_ENTRY: 'DUPLICATE_ENTRY',
  ADD_ENTRY: 'ADD_ENTRY',
  ROW_DETAILS: 'ROW_DETAILS',
  SHARE_URL: 'SHARE_URL',
  LIST_COLUMN: 'LIST_COLUMN',
};

export const MINIAPP_SHARING_STATES = {
  ADD_VIEW_USERS: 'ADD_VIEW_USERS',
  CUSTOM_ROLES_LIST: 'CUSTOM_ROLES_LIST',
  CREATE_CUSTOM_ROLE: 'CREATE_CUSTOM_ROLE',
  EDIT_CUSTOM_ROLE: 'EDIT_CUSTOM_ROLE',
};

export const SCREEN_TYPES = [
  MINI_APPS.SCREEN_TYPE.FILTERED_DATA,
  MINI_APPS.SCREEN_TYPE.DOCUMENT_DATA,
  MINI_APPS.SCREEN_TYPE.DASHBOARD,
  MINI_APPS.SCREEN_TYPE.REPORT,
];

export const ADD_FILTERED_DATA_SCREEN_PHASES = {
  CHOOSE_FILE: 'CHOOSE_FILE_F',
  SELECT_FILTERS: 'SELECT_FILTERS_F',
  CHOOSE_LAYOUT: 'CHOOSE_LAYOUT_F',
};

export const ADD_DOCUMENT_SCREEN_PHASES = {
  CHOOSE_FILE: 'CHOOSE_FILE_D',
  CHOOSE_LAYOUT: 'CHOOSE_LAYOUT_D',
};

export const DASHBOARD_SCREEN_PHASES = {
  CHOOSE_FILE: 'CHOOSE_FILE_E',
  CHOOSE_LAYOUT: 'CHOOSE_LAYOUT_E',
};
export const REPORT_SCREEN_PHASES = {
  CHOOSE_FILE: 'CHOOSE_FILE_R',
  CHOOSE_LAYOUT: 'CHOOSE_LAYOUT_R',
};
export const SCREEN_TYPE_DETAILS = {
  [MINI_APPS.SCREEN_TYPE.FILTERED_DATA]: {
    label: 'Data Filter',
    filterType: MINI_APPS?.SCREEN_TYPE?.FILTERED_DATA,
    filterDesc: 'Filter data and save the results in screen',
    filterIcon: filterIconYellow,
    iconBackGround: 'rgba(241, 164, 47, 0.07)',
    noMargin: false,
    component: '',
  },
  [MINI_APPS?.SCREEN_TYPE?.DOCUMENT_DATA]: {
    filterType: MINI_APPS?.SCREEN_TYPE?.DOCUMENT_DATA,
    label: 'Files',
    filterDesc: 'Show data from files as a screen',
    filterIcon: fileEditIcon,
    iconBackGround: 'rgba(68, 159, 53, 0.07)',
    noMargin: true,
  },
  [MINI_APPS?.SCREEN_TYPE?.DASHBOARD]: {
    filterType: MINI_APPS?.SCREEN_TYPE?.DASHBOARD,
    label: 'Dashboards',
    filterDesc: 'Select from dashboards for the document',
    filterIcon: dashboardIcon,
    iconBackGround: 'rgba(121, 27, 238, 0.07)',
    noMargin: false,
  },
  [MINI_APPS?.SCREEN_TYPE?.REPORT]: {
    filterType: MINI_APPS?.SCREEN_TYPE?.DASHBOARD,
    label: 'Report',
    filterDesc: 'Create Report for App Data',
    filterIcon: ReportIcon,
    iconBackGround: theme.palette.gray100,
    noMargin: true,
  },
};

export const COLOURS = [
  {
    colorCode: '#556A89',
    showDropper: true,
    seperator: true,
  },
  {
    colorCode: '#337EED',
    showDropper: false,
    seperator: false,
  },
  {
    colorCode: '#791BEE',
    showDropper: false,
    seperator: false,
  },
  {
    colorCode: '#08C9E5',
    showDropper: false,
    seperator: false,
  },
  {
    colorCode: '#E02A2A',
    showDropper: false,
    seperator: false,
  },
  {
    colorCode: '#449F35',
    showDropper: false,
    seperator: false,
  },
  {
    colorCode: '#F1A42F',
    showDropper: false,
    seperator: false,
  },
  {
    colorCode: '#F12FC7',
    showDropper: false,
    seperator: false,
  },
];

export const DASHBOARD_COLOR_VALUES = [
  {
    colorCode: '#000000',
    showDropper: false,
    seperator: false,
  },
  ...COLOURS,
];

export const SIDEBAR_MENU_MAPPING = {
  PROFILE: 'PROFILE',
  ORGANISATION: 'ORGANISATION',
  TEAMS: 'TEAMS',
  // ACCOUNTS: 'ACCOUNTS',
  // SECURITY: 'SECURITY',
  SUBSCRIPTION: 'SUBSCRIPTION',
  // PAYMENT: 'PAYMENT',
};

export const HEADING_AND_SUBHEADING_FOR_PROFILE = {
  PROFILE: {
    heading: 'Profile Details',
    subheading: 'Manage user profile details for Lio account',
    // 'You can manage all your personal and business informations from this section.',
  },
  ORGANISATION: {
    heading: 'Organisation Details',
    subheading: 'Manage your business details',
    // 'You can manage all your personal and business informations from this section.',
  },
  TEAMS: {
    heading: 'Organisation Members',
    subheading: 'Manage users and their app permissions',
    //'You can manage users app access and permission settings here.',
  },
};

// !Not used
export const MINIAPPS_CUSTOM_ROLE_SCREEN_CONFIG_DETAILS = {
  ENTRY_ACCESS: [
    {label: 'All Entries', type: 'ALL_ENTRIES'},
    {label: 'Created Entries ', type: 'CREATED_ENTRIES'},
    {label: 'Assigned Entries', type: 'ASSIGNED_ENTRIES'},
    {label: 'None', type: 'NONE'},
  ],
  FIELD_ACCESS: [
    {label: 'Edit', type: 'EDIT'},
    {label: 'View', type: 'VIEW'},
    {label: 'Hide', type: 'HIDE'},
  ],
  PROPERTIES: [
    {label: 'None', type: 'NONE'},
    {label: 'Edit only once', type: 'EDIT_ONLY_ONCE'},
    {label: 'Edit till End of Day', type: 'EDIT_TILL_END_OF_DAY'},
  ],
};

export const TEAM_MANAGEMENT_MOBILE_PHASES = {
  TEAM_MEMBERS: 'TEAM_MEMBERS',
  USER_PERMISSION: 'USER_PERMISSION',
  INVITE_MEMBERS: 'INVITE_MEMBERS',
  APP_PERMISSION: 'APP_PERMISSION',
  CREATE_CUSTOM_ROLE: 'CREATE_CUSTOM_ROLE',
  CONFIGURE_SCREEN: 'CONFIGURE_SCREEN',
  MEMBER_INFO: 'MEMBER_INFO',
};

// <-------- Constants related to org member info ------------------->
export const ORG_MEMBER_INFO_PHASES_DESKTOP = {
  MEMBER_INFO: 'MEMBER_INFO',
  CONFIGURE_MEMBER_FIELDS: 'CONFIGURE_MEMBER_FIELDS',
  ADD_CUSTOM_FIELD: 'ADD_CUSTOM_FIELD',
  EDIT_CUSTOM_FIELD: 'EDIT_CUSTOM_FIELD',
  DELETE_CUSTOM_FIELD: 'DELETE_CUSTOM_FIELD',
};

export const ORG_MEMBER_INFO_PHASES_MOBILE = {
  MEMBER_INFO: 'MEMBER_INFO',
  FIELD_CONFIG: 'FIELD_CONFIG',
};

export const ORG_MEMBER_INFO_PHASES_MOBILE_MODALS = {
  ADD_CUSTOM_FIELD: 'ADD_CUSTOM_FIELD',
  EDIT_CUSTOM_FIELD: 'EDIT_CUSTOM_FIELD',
  DELETE_CUSTOM_FIELD: 'DELETE_CUSTOM_FIELD',
};

// <---------------------------------------------------------->
export const HEADING_AND_SUBHEADING_FOR_PROFILE_MOBILE = {
  [TEAM_MANAGEMENT_MOBILE_PHASES.TEAM_MEMBERS]: {
    heading: 'Team Management',
  },
  [TEAM_MANAGEMENT_MOBILE_PHASES.USER_PERMISSION]: {
    heading: 'User Permissions',
  },
  // [TEAM_MANAGEMENT_MOBILE_PHASES.INVITE_MEMBERS]: {
  //   heading: ''
  // },
  [TEAM_MANAGEMENT_MOBILE_PHASES.APP_PERMISSION]: {
    heading: 'Choose Permissions',
  },
  [TEAM_MANAGEMENT_MOBILE_PHASES.CREATE_CUSTOM_ROLE]: {
    heading: 'Create Custom Role',
  },
  [TEAM_MANAGEMENT_MOBILE_PHASES.CONFIGURE_SCREEN]: {
    heading: 'Manage Permissions',
  },
};
export const CUSTOMISATION_TYPE = {
  NULL: 'NULL',
  COLUMN_NAME: 'COLUMN_NAME',
  CUSTOM_VALUE: 'CUSTOM_VALUE',
};

export const PROFILE_SIDEBAR_MENUS = [
  {text: 'Profile', icon: ProfileIcon, id: 'PROFILE'},
  {text: 'Organisation', icon: OrgIcon, id: 'ORGANISATION'},
  {text: 'Team Management', icon: TeamIcon, id: 'TEAMS'},
  // {text: 'Accounts', icon: AccountIcon, id: 'ACCOUNTS'},
  // {text: 'Security', icon: SecurityIcon, id: 'SECURITY'},
  {text: 'Subscription', icon: SubscriptionIcon, id: 'SUBSCRIPTION'},
  // {text: 'Payment', icon: PaymentIcon, id: 'PAYMENT'},
];

export const TEAMS_DISCARD_CHANGES_WARNING_FROM = {
  MEMBER_CHANGE: 'MEMBER_CHANGE',
  INVITE_MEMBER: 'INVITE_MEMBER',
};

export const MORE_COLORS_MINI_APPS = [
  ...COLOURS,
  {
    colorCode: '#0250E8',
    showDropper: true,
    seperator: true,
  },
  {
    colorCode: '#F159D0',
    showDropper: true,
    seperator: true,
  },
  {
    colorCode: '#22A1B2',
    showDropper: true,
    seperator: true,
  },
  {
    colorCode: '#FFCD56',
    showDropper: true,
    seperator: true,
  },
  {
    colorCode: '#FF406E',
    showDropper: true,
    seperator: true,
  },
  {
    colorCode: '#08E553',
    showDropper: true,
    seperator: true,
  },
  {
    colorCode: '#6D86DE',
    showDropper: true,
    seperator: true,
  },
  {
    colorCode: '#9649F5',
    showDropper: true,
    seperator: true,
  },
  {
    colorCode: '#05A5FF',
    showDropper: true,
    seperator: true,
  },

  {
    colorCode: '#C32A71',
    showDropper: true,
    seperator: true,
  },
  {
    colorCode: '#14BD6B',
    showDropper: false,
    seperator: false,
  },
  {
    colorCode: '#4D5360',
    showDropper: false,
    seperator: false,
  },
  {
    colorCode: '#0B70AB',
    showDropper: false,
    seperator: false,
  },
  {
    colorCode: '#D0B65B',
    showDropper: false,
    seperator: false,
  },
  {
    colorCode: '#986C66',
    showDropper: false,
    seperator: false,
  },
  {
    colorCode: '#AEED5F',
    showDropper: false,
    seperator: false,
  },
];
export const SUBSCRIPTION_CANCELLATION_REASONS = [
  'I don’t understand how to use the product',
  'It’s too expensive',
  'Lack of Technical support',
  'Something else',
];

export const CURRENCY_SYMBOL_MAPPING = {IN: '₹', US: '$'};

export const COOKIES_WEB = {
  ACTIVE_APP_ID_COOKIE_KEY: 'lioMiniAppId',
  DEVICE_ID: 'd_w_id',
};

export const MINIAPP_SIDEBAR_SETTING_STATES = {
  EDIT_RENAME: 'EDIT_RENAME',
  QUICK_FILTER: 'QUICK_FILTER',
  MANAGE_SCREENS: 'MANAGE_SCREENS',
  ROLE_PERMISSIONS: 'ROLE_PERMISSIONS',
  ACTION_BUTTONS: 'ACTION_BUTTONS',
  SCREEN_SETTINGS: 'SCREEN_SETTINGS',
  DELETE_APP: 'DELETE_APP',
  SCREEN_LAYOUT: 'SCREEN_LAYOUT',
  EDIT_FIELDS: 'EDIT_FIELDS',
  SORT_BY: 'SORT_BY',
  AUTOMATIONS: 'AUTOMATIONS',
  AGGREGATIONS: 'AGGREGATIONS',
  INTEGRATIONS: 'INTEGRATIONS',
};

export const SCREEN_TYPE_REDUX_MAPPING = {
  DOCUMENT_DATA: ADD_DOCUMENT_SCREEN_PHASES,
  FILTERED_DATA: ADD_FILTERED_DATA_SCREEN_PHASES,
  DASHBOARD: DASHBOARD_SCREEN_PHASES,
};
export const SUMMARY_OPERATION_LABEL = {
  COUNT: 'Show Count',
  AVERAGE: 'Show Average',
  TOTAL: 'Show Total Sum',
  MAX: 'Max',
  MIN: 'Min',
};

export const MINI_APP_SETTINGS_MENU_ITEMS_ACTIONS = {
  EDIT_RENAME: 'EDIT_RENAME',
  SCREEN_SETTINGS: 'SCREEN_SETTINGS',
  MANAGE_SCREENS: 'MANAGE_SCREENS',
  ROLE_PERMISSIONS: 'ROLE_PERMISSIONS',
  EDIT_FIELDS: 'EDIT_FIELDS',
  SORT_BY: 'SORT_BY',
  ACTION_BUTTONS: 'ACTION_BUTTONS',
  SCREEN_LAYOUT: 'SCREEN_LAYOUT',
  QUICK_FILTER: 'QUICK_FILTER',
  AUTOMATIONS: 'AUTOMATIONS',
  AGGREGATIONS: 'AGGREGATIONS',
  INTEGRATIONS: 'INTEGRATIONS',
};

export const MINI_APP_SETTINGS_MENU_ITEMS = [
  {
    icon: <EditIconWithBlues color={'#90ABD3'} />,
    activeIcon: <EditIconWithBlues color={'white'} />,
    listIcon: EditIconWithBlue,
    listText: 'Edit/Rename',
    action: MINIAPP_SIDEBAR_SETTING_STATES.EDIT_RENAME,
  },

  {
    icon: <ScreenSettingIcon color={'#90ABD3'} />,
    activeIcon: <ScreenSettingIcon color={'white'} />,
    listIcon: ScreenSetting,
    listText: 'Screen Settings',
    action: MINIAPP_SIDEBAR_SETTING_STATES.SCREEN_SETTINGS,
  },
  {
    icon: <ActionBtnIcon color={'#90ABD3'} />,
    activeIcon: <ActionBtnIcon color={'white'} />,
    listIcon: ActionButtonIcon,
    listText: 'Action Buttons',
    action: MINIAPP_SIDEBAR_SETTING_STATES.ACTION_BUTTONS,
  },
  {
    icon: <IconmanageScreens color={'#90ABD3'} />,
    activeIcon: <IconmanageScreens color={'white'} />,
    listIcon: ManageScreenIcon,
    listText: 'Manage Screens',
    action: MINIAPP_SIDEBAR_SETTING_STATES.MANAGE_SCREENS,
  },
  {
    icon: <ScreenLayoutIcons color={'#90ABD3'} />,
    activeIcon: <ScreenLayoutIcons color={'white'} />,
    listIcon: ScreenLayoutIcon,
    listText: 'Screen Layout',
    action: 'SCREEN_LAYOUT',
  },
  {
    icon: <RolePermissionss color={'#90ABD3'} />,
    activeIcon: <RolePermissionss color={'white'} />,
    listIcon: RolePermissions,
    listText: 'Roles & Permissions',
    action: MINIAPP_SIDEBAR_SETTING_STATES.ROLE_PERMISSIONS,
  },
  {
    icon: <SortByIcon color={'#90ABD3'} />,
    activeIcon: <SortByIcon color={'white'} />,
    listIcon: SortByIconDark,
    listText: 'Sort by',
    action: MINIAPP_SIDEBAR_SETTING_STATES.SORT_BY,
  },
  {
    icon: <QuickFilterIcon color={'#90ABD3'} />,
    activeIcon: <QuickFilterIcon color={'white'} />,
    listIcon: QuickFilter,
    listText: 'Quick Filter',
    action: 'QUICK_FILTER',
  },
  {
    icon: <EditFieldsIcon color={'#90ABD3'} />,
    activeIcon: <EditFieldsIcon color={'white'} />,
    listIcon: EditFieldsIconDark,
    listText: 'Edit Fields',
    action: MINI_APP_SETTINGS_MENU_ITEMS_ACTIONS.EDIT_FIELDS,
  },
  {
    icon: <AutomationsIcon color={'#90ABD3'} />,
    activeIcon: <AutomationsIcon color={'white'} />,
    listIcon: AutomationIcon,
    listText: 'Automations',
    action: MINI_APP_SETTINGS_MENU_ITEMS_ACTIONS.AUTOMATIONS,
  },
  {
    icon: <AggregationIcon color={'#90ABD3'} />,
    activeIcon: <AggregationIcon color={'white'} />,
    listIcon: AutomationIcon,
    listText: 'Aggregations',
    action: MINI_APP_SETTINGS_MENU_ITEMS_ACTIONS.AGGREGATIONS,
  },
  {
    icon: <IntegrationIcon color={'#90ABD3'} />,
    activeIcon: <IntegrationIcon color={'white'} />,
    listIcon: AutomationIcon,
    listText: 'Integrations',
    action: MINI_APP_SETTINGS_MENU_ITEMS_ACTIONS.INTEGRATIONS,
  },
];

export const MINI_APPS_ACTION_BUTTONS_MAPPING = {
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.CUSTOM_ACTION]: {
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.CUSTOM_ACTION,
    text: 'Custom Action',
    iconName: PlusBlueIcon,
    isNewCustomAction: true,
  },
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.CALL]: {
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.CALL,
    text: 'Call',
    iconName: PhoneIcon,
    iconGray: CallGrayIcon,
  },
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.MESSAGE]: {
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.MESSAGE,
    text: 'Message',
    iconName: MessageIcon,
    iconGray: SmsGrayIcon,
  },
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.WHATS_APP]: {
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.WHATS_APP,
    text: 'WhatsApp',
    iconName: WhatsAppIcon,
    iconGray: WhatsAppGrayIcon,
  },
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.SEND_EMAIL]: {
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.SEND_EMAIL,
    text: 'Email',
    iconName: EmailIcon,
    iconGray: EmailGrayIcon,
  },
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.OPEN_URL]: {
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.OPEN_URL,
    text: 'Open Link',
    iconName: URLIcon,
    iconGray: OpenUrlGray,
  },
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.COPY_URL]: {
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.COPY_URL,
    text: 'Copy URL',
    iconName: CopyIcon,
    iconGray: CopyGrayIcon,
  },
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.PLAY_AUDIO]: {
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.PLAY_AUDIO,
    text: 'Play Audio',
    iconName: PlayMusic,
    iconGray: PlayAudioGray,
  },
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.WATCH_VIDEO]: {
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.WATCH_VIDEO,
    text: 'Watch Video',
    iconName: VideoIcon,
    iconGray: VideoGrayIcon,
  },
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.VIEW_IMAGE]: {
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.VIEW_IMAGE,
    text: 'View Image',
    iconName: ImageIconBlue,
    iconGray: ImageIconGrayIcon,
  },
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.VIEW_ATTACHMENTS]: {
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.VIEW_ATTACHMENTS,
    text: 'Attachment',
    iconName: PeperClipIcon,
    iconGray: PeperClipGrayIcon,
  },
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.GET_DIRECTION]: {
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.GET_DIRECTION,
    text: 'Get Direction',
    iconName: NavigationIcon,
    iconGray: NavigationGrayIcon,
  },
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.ADD_COMMENTS]: {
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.ADD_COMMENTS,
    text: 'Add Comment',
    iconName: <AddComment style={{color: '#337EED', width: 20, height: 20}} />,
    iconGray: <AddComment style={{color: '#7E99C1', width: 20, height: 20}} />,
  },
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.VIEW_COMMENTS]: {
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.VIEW_COMMENTS,
    text: 'View Comment',
    iconName: <Comment style={{color: '#337EED', width: 20, height: 20}} />,
    iconGray: <Comment style={{color: '#7E99C1', width: 20, height: 20}} />,
  },
  [MINI_APPS.ACTION_BUTTONS.ACTIONS.WHATSAPP_WITH_DATA]: {
    isCustom: true,
    id: MINI_APPS.ACTION_BUTTONS.ACTIONS.WHATSAPP_WITH_DATA,
    text: 'Send WhatsApp Message',
    iconName: WhatsAppIcon,
    iconGray: WhatsAppGrayIcon,
  },
};

export const MINI_APPS_VIEW_TYPE_OPTIONS = {
  [MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.DEFAULT]: {
    title: 'Default',
    description: 'Classic list of record cards',
    iconName: ScreenDefaultIcon,
  },
  [MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.CALENDAR]: {
    title: 'Calendar',
    description: 'Calendar Panel for Records',
    iconName: CalendarViewIcon,
  },
  [MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN]: {
    title: 'Kanban Board',
    description: 'Kanban board to group',
    iconName: KanbanViewIcon,
  },
  [MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.GRID]: {
    title: 'Grid View',
    description: 'Classic tabular format',
    iconName: GridViewIcon,
  },
};

export const MINI_APP_TYPE_OF_GROUP_SCREEN = {
  NO_GROUP: 'NO_GROUP',
  GROUP: 'GROUP',
  SCREEN: 'SCREEN',
  EMPTY: 'EMPTY',
};
export const STATIC_COLUMN_TYPE = 'STATIC';

export const FIELD_CONFIG_VIEW_STATES = {
  FIELD_CONFIG: 'FIELD_CONFIG',
  PDF_CONFIG: 'PDF_CONFIG',
};

export const MINI_APPS_AUTOMATION_TRIGGER = {
  [MINI_APPS.AUTOMATION.TRIGGER_TYPES.ROW_NEW_ENTRY]: {
    title: 'New Entry is added',
    iconName: PlusIcon,
  },
  [MINI_APPS.AUTOMATION.TRIGGER_TYPES.ROW_EDIT_ENTRY]: {
    title: 'Entry is Modified',
    iconName: EditIcon,
  },
  [MINI_APPS.AUTOMATION.TRIGGER_TYPES.CELL_STATUS_CHANGE]: {
    title: 'Status column is Changed',
    iconName: ManageScreenIcon,
  },
  [MINI_APPS.AUTOMATION.TRIGGER_TYPES.ON_ASSIGNEE_CHANGE]: {
    title: 'Assignee Column Modified',
    iconName: UserIcon,
  },

  [MINI_APPS.AUTOMATION.TRIGGER_TYPES.ROW_DELETE_ENTRY]: {
    title: 'Delete Entry',
    iconName: DeleteIcon,
  },
  [MINI_APPS.AUTOMATION.TRIGGER_TYPES.ON_SPECIFIC_DATE]: {
    title: 'On Specific Date',
    iconName: CalendarViewIcon,
  },
  [MINI_APPS.AUTOMATION.TRIGGER_TYPES.MANUAL]: {
    title: 'Manually',
    iconName: ClickIcon,
  },
  [MINI_APPS.AUTOMATION.TRIGGER_TYPES.ON_USER_APP_ACCESS]: {
    title: 'New User is Added',
    iconName: AppLaunchIcon,
  },
};

export const MINI_APPS_AUTOMATION_ACTION = {
  [DEFAULT_OPERATION_TYPE]: {
    iconName: WhatsappIcon,
    color: '#ecf5eb',
  },
  [AUTOMATION_OPERATION_TYPES.SEND_NOTIFICATION]: {
    iconName: Notification,
    color: '#f2e8fd',
  },
  [AUTOMATION_OPERATION_TYPES.COPY_ROW]: {
    iconName: CopyIcon,
    color: '#ebf2fd',
  },
  [AUTOMATION_OPERATION_TYPES.MOVE_ROW]: {
    iconName: MoveIcon,
    color: '#fceaea',
  },
  [AUTOMATION_OPERATION_TYPES.SET_VALUE_BY_FILTERS]: {
    iconName: FormEdit,
    color: '#ebf2fd',
  },
  [AUTOMATION_OPERATION_TYPES.SEND_EMAIL]: {
    iconName: EmailFieldIcon,
  },
  // TODO : Enable this after added on firestore
  [AUTOMATION_OPERATION_TYPES.ADD_EMPTY_ROW]: {
    iconName: FormEdit,
  },
};
export const MINI_APPS_AUTOMATION_ROLES_ICONS_ID = {
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
  OWNER: 'OWNER',
};

export const MINI_APPS_AUTOMATION_ROLES_ICONS = {
  [MINI_APPS_AUTOMATION_ROLES_ICONS_ID.ADMIN]: {
    iconName: UserGrayIcon,
  },
  [MINI_APPS_AUTOMATION_ROLES_ICONS_ID.OWNER]: {
    iconName: UserPermissionIcon,
  },
  [MINI_APPS_AUTOMATION_ROLES_ICONS_ID.MEMBER]: {
    iconName: StarIcon,
  },
};

export const SUPPORTED_SOURCES_FOR_ACTION_BUTTONS = [
  MINIAPP_VIEW_STATES.SCREEN_DATA,
  MINIAPP_VIEW_STATES.IS_SELECT_RECORDS,
  MINIAPP_VIEW_STATES.ROW_DETAILS,
  MINI_APPS.LAYOUTS.CATALOG,
  MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN,
  MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.GRID,
];

export const UNSUPPORTED_STATE_SUBTYPES_FOR_ACTION_BUTTONS = [
  MINIAPP_VIEW_STATES_SUBTYPE.LIST_ROWS,
  MINIAPP_VIEW_STATES_SUBTYPE.LIST_ROW_DETAILS,
];

export const CATALOG_LAYOUT_VARIENTS = {
  SINGLE_CARD_LAYOUT: 'SINGLE_CARD_LAYOUT',
  THREE_CARD_LAYOUT: 'THREE_CARD_LAYOUT',
};

export const DEFAULT_VALUE_MAPPING_OPTIONS_DATA = [
  {
    id: DEFAULT_VALUE_MAPPING_OPTIONS.DO_NOTHING,
    text: 'Do Nothing',
    StartComp: <CancelIcon />,
  },
  {
    id: DEFAULT_VALUE_MAPPING_OPTIONS.ERASE_DATA,
    text: 'Erase Data',
    StartComp: <EraserIcon />,
  },
  {
    id: DEFAULT_VALUE_MAPPING_OPTIONS.ADD_DEFAULT_VALUE,
    text: 'Add Default Value',
    StartComp: <AddDefaultValueIcon />,
    EndComp: <ChevronRight />,
  },
];
export const AUTOMATION_CONTAINER_STATES = {
  ADD_NEW_AUTOMATION: 'ADD_NEW_AUTOMATION',
  CONNECTED_EMAIL: 'CONNECTED_EMAIL',
  EDIT_AUTOMATION: 'EDIT_AUTOMATION',
};
const getPdfSizeList = () => {
  const pdfSizeList = [];
  for (let i = 8; i <= 48; i++) {
    const sizeObj = {
      title: `${i}px`,
      id: i,
    };
    pdfSizeList.push(sizeObj);
  }
  return pdfSizeList;
};

export const PDF_FONT_SIZE_LIST = getPdfSizeList();

export const PDF_STYLING_DROPDOWNS = {
  FONT_SIZE_DROPDOWNS: 'FONT_SIZE_DROPDOWNS',
  LINE_HEIGHT_DROPDOWN: 'LINE_HEIGHT_DROPDOWN',
  MARGIN_TOP_DROPDOWN: 'MARGIN_TOP_DROPDOWN',
  MARGIN_RIGHT_DROPDOWN: 'MARGIN_RIGHT_DROPDOWN',
  MARGIN_BOTTOM_DROPDOWN: 'MARGIN_BOTTOM_DROPDOWN',
  MARGIN_LEFT_DROPDOWN: 'MARGIN_LEFT_DROPDOWN',
};

export const PDF_TEXT_FORMATTING_TYPE = {
  BOLD: 'bold',
  ITALICS: 'italics',
  UNDERLINE: 'underline',
  STRIKE: 'lineThrough',
};

export const PDF_PORTION_STYLE_DIRECTION_TYPES = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
};

export const PDF_PORTION_MARGINS = {
  0: 0,
  3: 3,
  5: 5,
  10: 10,
};

export const PDF_PORTION_LINEHEIGHTS = {
  1: 1,
  1.3: 1.3,
  1.5: 1.5,
  2: 2,
  3: 3,
  4: 4,
};

export const PDF_TEXT_FORMATTING_LIST = [
  {
    id: PDF_TEXT_FORMATTING_TYPE.BOLD,
    icon: BoldIcon,
  },
  {
    id: PDF_TEXT_FORMATTING_TYPE.ITALICS,
    icon: ItalicsIcon,
  },
  {
    id: PDF_TEXT_FORMATTING_TYPE.UNDERLINE,
    icon: UnderlineIcon,
  },
  {
    id: PDF_TEXT_FORMATTING_TYPE.STRIKE,
    icon: StrikeIcon,
  },
];

export const PDF_LINE_HEIGHT_LIST = [
  {
    id: PDF_PORTION_LINEHEIGHTS[1],
    title: PDF_PORTION_LINEHEIGHTS[1],
  },
  {
    id: PDF_PORTION_LINEHEIGHTS[1.3],
    title: PDF_PORTION_LINEHEIGHTS[1.3],
  },
  {
    id: PDF_PORTION_LINEHEIGHTS[1.5],
    title: PDF_PORTION_LINEHEIGHTS[1.5],
  },
  {
    id: PDF_PORTION_LINEHEIGHTS[2],
    title: PDF_PORTION_LINEHEIGHTS[2],
  },
  {
    id: PDF_PORTION_LINEHEIGHTS[3],
    title: PDF_PORTION_LINEHEIGHTS[3],
  },
  {
    id: PDF_PORTION_LINEHEIGHTS[4],
    title: PDF_PORTION_LINEHEIGHTS[4],
  },
];

export const PDF_MARGIN_TYPES = {
  0: 'No Margin',
  3: 'Small',
  5: 'Medium',
  10: 'Large',
};

export const PDF_MARGIN_LIST = [
  {
    id: PDF_PORTION_MARGINS[3],
    title: 'Small',
  },
  {
    id: PDF_PORTION_MARGINS[5],
    title: 'Medium',
  },
  {
    id: PDF_PORTION_MARGINS[10],
    title: 'Large',
  },
  {
    id: PDF_PORTION_MARGINS[0],
    title: 'No Margin',
  },
];

export const PDF_PORTION_BORDERS = [
  {id: PDF_PORTION_STYLE_DIRECTION_TYPES.TOP, icon: BorderTopIcon},
  {id: PDF_PORTION_STYLE_DIRECTION_TYPES.RIGHT, icon: BorderRightIcon},
  {id: PDF_PORTION_STYLE_DIRECTION_TYPES.BOTTOM, icon: BorderBottomIcon},
  {id: PDF_PORTION_STYLE_DIRECTION_TYPES.LEFT, icon: BorderLeftIcon},
];

export const FILES_SORT_OPTIONS = {
  NAME: 'Name',
  DATE: 'Created Date',
  OPENED_DATE: 'Opened Date',
  TYPE: 'Type',
};
// Docs Filter Option:
export const DOCS_FILTER_OPTIONS = [
  {
    id: 'Opened Date',
    text: 'Recently Opened',
  },
  {
    id: 'Name',
    text: 'Name',
  },
  // {
  //   id:"Type",
  //   text:"App Type",
  // },
  {
    id: 'Created Date',
    text: 'Created Date',
  },
];
export const AUTOMATION_TRIGGER_CONDITION_CONFIG_MAPPING = {
  [MINI_APPS.AUTOMATION.TRIGGER_CONDITION_CONFIG.ALL_SHOULD_BE_TRUE]: {
    id: MINI_APPS.AUTOMATION.TRIGGER_CONDITION_CONFIG.ALL_SHOULD_BE_TRUE,
    text: 'All should be true',
  },
  [MINI_APPS.AUTOMATION.TRIGGER_CONDITION_CONFIG
    .ANY_ONE_CONDITION_SHOULD_BE_TRUE]: {
    id: MINI_APPS.AUTOMATION.TRIGGER_CONDITION_CONFIG
      .ANY_ONE_CONDITION_SHOULD_BE_TRUE,
    text: 'Any one condition should be true',
  },
};

export const TABLE_LINKS_TRIGGER_CONDITION_CONFIG_MAPPING =
  AUTOMATION_TRIGGER_CONDITION_CONFIG_MAPPING;
export const CATALOGUE_CARD_VARIENT_OPTIONS = [
  {id: 1, title: '1 card in a row'},
  {id: 3, title: '3 cards in a row'},
];

export const CATALOGUE_CARD_VARIENT_MAPPING = {
  1: '1 card in a row',
  3: '3 cards in a row',
};
export const REPORT_MORE_OPTIONS_ID = {
  EDIT: 'EDIT',
  DUPLICATE: 'DUPLICATE',
  DELETE: 'DELETE',
};
export const REPORT_MORE_OPTIONS = [
  {
    text: 'Edit',
    id: REPORT_MORE_OPTIONS_ID.EDIT,
    StartComp: <img src={EditFieldsIconDark} alt="no-icon" />,
  },
  {
    text: 'Duplicate',
    id: REPORT_MORE_OPTIONS_ID.DUPLICATE,
    StartComp: <img src={DuplicateIcon} alt="no-icon" />,
  },
  {
    text: 'Delete',
    id: REPORT_MORE_OPTIONS_ID.DELETE,
    textStyles: {color: 'red'},
    StartComp: <img src={DeleteIcon} alt="no-icon" />,
  },
];
export const REPORT_MORE_HEADER_OPTIONS = [
  {
    text: 'Help & Support',
    id: 'HELP',
    StartComp: <img src={helpIcon} alt="no-icon" />,
  },
];

export const PDF_PAGE_ORIENTATION_OPTIONS = [
  {
    id: 'portrait',
    text: 'Portrait',
  },
  {
    id: 'landscape',
    text: 'Landscape',
  },
];

export const CALENDAR_VIEW_EVENT_COLOR = [
  // '#791BEE',
  // '#337EED',
  // '#F1812F',
  // '#449F35',
  // '#E02A56',
  // '#08C9E5',
  // '#F1A42F',
  // '#5A8DB1',
  // '#B928D0',
  // '#6F6F6F',

  // '#fafafa',
  // '#D3D3D3',
  // '#fbfbfb',
  // '#D3D3D3',
  // '#fdfdfd',
  // '#f8f8f8',
  '#D3D3D3',
  '#D3D3D3',
  '#D3D3D3',
  '#D3D3D3',
  '#D3D3D3',
  '#D3D3D3',
];
export const CALENDAR_VIEW_TYPE = [
  {text: 'Month', id: 'month'},
  {text: 'Day', id: 'day'},
  {text: 'Week', id: 'week'},
];
export const AUTOMATION_SET_COLUMN_VALUE_DROPDOWN_STATE = {
  LEVEL_1: 'LEVEL 1',
  LEVEL_2: 'LEVEL 2',
};

export const DUMMY_FIELD_TYPE_ID = {
  ACTIONS: 'ACTIONS',
  SELECT_RECORDS: 'SELECT_RECORDS',
  DELETE_ROW: 'DELETE_ROW',
};

export const GRID_VIEW_COL_MAPPING_ORDER = [
  MINI_APPS_FIELDS.headerA,
  MINI_APPS_FIELDS.headerB,
  MINI_APPS_FIELDS.title,
  MINI_APPS_FIELDS.subtitle,
  MINI_APPS_FIELDS.imageA,
  MINI_APPS_FIELDS.catalog,
  MINI_APPS_FIELDS.footerA,
  MINI_APPS_FIELDS.footerB,
];

export const DEFAULT_CARD_COL_MAPPING_ORDER = [
  MINI_APPS_FIELDS.headerA,
  MINI_APPS_FIELDS.headerB,
  MINI_APPS_FIELDS.title,
  MINI_APPS_FIELDS.subtitle,
  MINI_APPS_FIELDS.footerA,
  MINI_APPS_FIELDS.footerB,
];

export const CUSTOM_VIEW_MENU_ITEMS = {
  DEFAULT: {
    startIcon: <ReorderIcon fontSize="medium" style={{color: '#7E99C1'}} />,
    TITLE: 'Default',
    ID: 'DEFAULT',
    COLOR: 'black',
  },
  CALENDAR: {
    startIcon: <TodayIcon fontSize="medium" style={{color: '#7E99C1'}} />,
    TITLE: 'Calendar',
    ID: 'CALENDAR',
    COLOR: 'black',
  },
  GRID: {
    startIcon: <GridIcon style={{width: '24px', height: `24px`}} />,
    TITLE: 'Grid',
    ID: 'GRID',
    COLOR: 'black',
  },
  KANBAN: {
    startIcon: (
      <ViewColumnOutlinedIcon fontSize="medium" style={{color: '#7E99C1'}} />
    ),
    TITLE: 'Kanban',
    ID: 'KANBAN',
    COLOR: 'black',
  },
};

export const CALENDAR_VIEW_LAYOUT_TYPE = [
  {
    title: 'Default',
    id: 'DEFAULT',
  },
  {
    title: 'Table',
    id: 'GRID',
  },
];

export const SET_CONDITION_SOURCE_TYPE = {
  IMPORT: 'IMPORT',
};
export const mimeToExtensionMap = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/webp': 'webp',
  'text/plain': 'txt',
  'text/html': 'html',
  'application/pdf': 'pdf',
  'application/zip': 'zip',
  'audio/mpeg': 'mp3',
  'video/mp4': 'mp4',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx', // Add .xlsx here
  // Add more mappings as needed
};
