import {firestore} from '../../imports';

/**---------------- Document Refs ----------------*/
export default class MiniAppsReportsDB {
  categoryRef() {
    return firestore().collection('miniAppsReports');
  }

  screenReportsRef(appId, screenId) {
    return this.categoryRef()
      .where('miniAppId', '==', appId)
      .where('screenId', '==', screenId)
      .orderBy('createdTimestamp', 'desc');
  }
}
