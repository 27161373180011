export const routes = {
  GRID: '/grid',
  LOGIN: '/login',
  HOME: '/',
  TEMPLATE: '/template',
  DOCUMENT: '/document',
  PROFILE: '/profile',
  // TRASH: "/trash",
  SIGNUP: '/signup',
  LOGINMOBILE: '/login-mobile',
  LANGUAGE: '/language-select',
  VIDEOS: '/videos',
  NOT_FOUND: '/404',
  ENTRY_ONLY: '/entry-only',
  DASHBOARDS: '/dashboard',
  MY_TASKS: '/tasks',
  CONNECT_WHATSAPP: '/connect/whatsapp',
  WHATSAPP_REDIRECT: '/wa',
  APPS: '/apps',
  APP: '/app',
  ONBOARDING: '/onboarding',
  LIOSTORE: '/store',
  LIOSTORE_APP: '/store/:id',
  ALLNOTIFICATIONS: '/notifications',
};
