import {firestore} from '../../imports';

/**---------------- Document Refs ----------------*/
export default class ElasticDashboardsDB {
  dashboardRef(firestoreRef = null) {
    return (firestoreRef ?? firestore)().collection('elasticDashboards');
  }

  doc(dashboardId, firestoreRef = null) {
    return this.dashboardRef(firestoreRef).doc(dashboardId);
  }
}
