import {firestore} from '../../imports';

/**---------------- Document Refs ----------------*/
export default class MiniAppsStoreDB {
  categoryRef() {
    return firestore().collection('miniAppsCategory');
  }

  storeRef() {
    return firestore().collection('miniAppsStore');
  }
}
