import {firestore} from '../imports';

class BatchWriterClass {
  constructor() {
    this.reset();
  }

  set(...args) {
    return this.getBatch().set(...args);
  }

  update(...args) {
    return this.getBatch().update(...args);
  }

  delete(...args) {
    return this.getBatch().delete(...args);
  }

  getNewBatch() {
    return firestore().batch();
  }

  getBatch() {
    this.count++;
    if (this.count > 498 || !this.promiseArr.length) {
      this.promiseArr.push(this.getNewBatch());
      this.index++;
    }
    return this.promiseArr[this.index];
  }

  reset() {
    this.promiseArr = [];
    this.index = -1;
    this.count = 0;
  }

  commit() {
    const promiseArr = this.promiseArr.slice();
    this.reset(); //reseting for next set of commits
    return Promise.all(promiseArr.map((batch) => batch.commit()));
  }
}

export const BatchWriter = new BatchWriterClass();
