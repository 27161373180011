import {MINI_APPS_STORE_ACTION, MINI_APPS_ACTION} from './actionType';
import {
  captureError,
  getDeviceVersion,
  getPlatform,
  versionCompare,
} from '../imports';
import FirestoreDB from '../FirestoreHandlers/FirestoreDB';
import {orderBy} from 'lodash';
import {handleCloudErrorMsgAndLogging, getTimezone} from '../utils/utils';
import {
  createAppFromAppStoreCloudFunction,
  getFormattedMiniAppObj,
} from './actionHelpers/miniAppsActionHelper';
import {checkUserPlanForAccess} from './miniAppsAction';
import {
  MINI_APPS_BUSINESS_CATEGORY_DETAIL,
  MINI_APPS_USECASE_CATEGORY_DETAIL,
} from '../utils/miniAppsCategoryConstant';
import {setExploreForApps} from './authAction';
import {EXPLORE_LIO_STEPS} from '../utils/constant';

const isAppVisible = (appData, userPref) => {
  const appVersion = getDeviceVersion();
  return (
    (appData.minVersion
      ? versionCompare(appVersion, appData.minVersion)
      : true) &&
    shouldDisplayStoreApp(appData.display, userPref.showInternalStoreApps)
  );
};

const shouldDisplayStoreApp = (
  displayType,
  displayInternalAppsUserPref = false,
) => {
  const DISPLAY_TYPES = {
    INTERNAL: 'INTERNAL',
    PUBLIC: 'PUBLIC',
    NONE: 'NONE',
  };
  if (
    displayType === DISPLAY_TYPES.NONE ||
    (displayType === DISPLAY_TYPES.INTERNAL && !displayInternalAppsUserPref)
  ) {
    return false;
  }
  return true;
};

const getInitialStoreData =
  (businessCategoryId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: MINI_APPS_STORE_ACTION.SET_LOADING,
        payload: true,
      });
      let allAppsCount = 0;
      const {appsList} = getState().miniAppsStore;
      const {userPref} = getState().auth;
      const {orgDomainId} = getState().organisation.profileData;
      const selectedBusinessCategoryId =
        businessCategoryId && businessCategoryId?.length
          ? businessCategoryId
          : orgDomainId;
      // ^ if businessCategoryId is avaialable, use that or use
      // orgDomainId( it is the businessCategoryId selected by the user in onboarding flow)
      const updatedAppsCategory = Object.assign(
        {},
        MINI_APPS_USECASE_CATEGORY_DETAIL,
      );
      const updatedAppsList = Object.assign({}, appsList);
      const categoryAppsList = Object.assign({}, appsList);
      const catgIds = Object.keys(MINI_APPS_USECASE_CATEGORY_DETAIL);

      dispatch({
        type: MINI_APPS_STORE_ACTION.LOAD_STORE_APPS_CATEGORY,
        payload: updatedAppsCategory,
      });
      // Getting initial app list

      await Promise.all([
        ...catgIds.map((catId) =>
          FirestoreDB.miniAppsStore
            .storeRef()
            .where(`categoryProps.${catId}.isHighlighted`, '==', true)
            .limit(3)
            .get()
            .then((snap) => {
              snap.docs.forEach((doc) => {
                const appData = doc.data();
                if (isAppVisible(appData, userPref)) {
                  categoryAppsList[doc.id] = {
                    ...appData,
                    id: doc.id,
                  };
                }
              });
            }),
        ),
        selectedBusinessCategoryId
          ? FirestoreDB.miniAppsStore
              .storeRef()
              .where(
                `categoryProps.${selectedBusinessCategoryId}.isHighlighted`,
                '==',
                true,
              )
              .get()
              .then((categoryAppList) =>
                categoryAppList.docs.forEach((doc) => {
                  const appData = doc.data();
                  if (isAppVisible(appData, userPref)) {
                    updatedAppsList[doc.id] = {
                      ...appData,
                      id: doc.id,
                    };
                  }
                }),
              )
          : Promise.resolve(),
      ]);
      dispatch({
        type: MINI_APPS_STORE_ACTION.LOAD_STORE_APPS_LIST,
        payload: {
          appsList: categoryAppsList,
          selectedCategoryId: selectedBusinessCategoryId,
        },
      });
      Object.assign(updatedAppsList, categoryAppsList);

      const sectionListData = [];
      [
        ...(selectedBusinessCategoryId
          ? [MINI_APPS_BUSINESS_CATEGORY_DETAIL[selectedBusinessCategoryId]]
          : []),
        ...Object.values(updatedAppsCategory),
      ].forEach((catg) => {
        const sectionObj = {
          title: {
            title: catg?.name,
            subTitle: catg?.desc,
            catgId: catg?.id,
          },
          data: orderBy(
            Object.values(updatedAppsList).filter(
              (obj) => catg?.id in obj.categoryProps,
            ),
            [`categoryProps.${catg?.id}.priority`],
          ),
        };
        if (sectionObj?.data?.length) {
          if (sectionObj?.data?.length >= 3) {
            allAppsCount = allAppsCount + 3;
          } else {
            allAppsCount = allAppsCount + sectionObj?.data?.length;
          }
          sectionListData.push(sectionObj);
        }
      });
      dispatch({
        type: MINI_APPS_STORE_ACTION.SET_APPS_SECTION_LIST,
        payload: {
          appsSectionList: sectionListData,
          allAppsCount: allAppsCount,
        },
      });
      dispatch({
        type: MINI_APPS_STORE_ACTION.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      captureError(error);
    }
  };

const getCategoryData = (selectedCategoryId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MINI_APPS_STORE_ACTION.SET_LOADING,
      payload: true,
    });
    if (selectedCategoryId) {
      const {appsList} = getState().miniAppsStore;
      const {userPref} = getState().auth;
      const updatedAppsList = Object.assign({}, appsList);
      const categoryAppList = await FirestoreDB.miniAppsStore
        .storeRef()
        .where(`categoryProps.${selectedCategoryId}.isHighlighted`, '==', true)
        .get();
      categoryAppList.docs.forEach((doc) => {
        const appData = doc.data();
        if (isAppVisible(appData, userPref)) {
          updatedAppsList[doc.id] = {...appData, id: doc.id};
        }
      });
      dispatch({
        type: MINI_APPS_STORE_ACTION.LOAD_STORE_APPS_LIST,
        payload: {
          appsList: updatedAppsList,
          selectedCategoryId,
        },
      });
    }
  } catch (error) {
    captureError(error);
  }
  dispatch({
    type: MINI_APPS_STORE_ACTION.SET_LOADING,
    payload: false,
  });
};

const getAppDetails = (selectedAppId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MINI_APPS_STORE_ACTION.SET_LOADING,
      payload: true,
    });

    const appDetails = Object.assign({}, getState().miniAppsStore.appDetails);
    if (selectedAppId && !(selectedAppId in appDetails)) {
      const {userPref} = getState().auth;
      const categoryAppDetails = await FirestoreDB.miniAppsStore
        .storeRef()
        .doc(selectedAppId)
        .collection('languages')
        .doc(userPref.lang ?? 'EN')
        .get();
      if (categoryAppDetails.exists) {
        appDetails[selectedAppId] = categoryAppDetails.data();
        dispatch({
          type: MINI_APPS_STORE_ACTION.SET_SELECTED_APP_DETAILS,
          payload: {
            appDetails,
            selectedAppId,
          },
        });
      }
    } else if (selectedAppId in appDetails) {
      dispatch({
        type: MINI_APPS_STORE_ACTION.SET_SELECTED_APP_DETAILS,
        payload: {
          appDetails,
          selectedAppId,
        },
      });
    }
  } catch (error) {
    captureError(error);
  }
  dispatch({
    type: MINI_APPS_STORE_ACTION.SET_LOADING,
    payload: false,
  });
};

const createAppFromAppStore =
  (appId, docReuseMapping = null, successCallBack) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref, userCountry, user},
        miniAppsStore: {selectedAppId, selectedCategoryId},
        miniApps: miniAppsInitialState,
      } = getState();

      if (!user?.uid || (!selectedAppId && !appId)) {
        return false;
      }

      if (
        Object.values(miniAppsInitialState.miniApps).some(
          (appObj) => appObj.isMiniAppOwner,
        ) //if user have atleast 1 own app
      ) {
        if (
          !dispatch(
            checkUserPlanForAccess(true, false, 'CREATE_APP_FROM_STORE'),
          )
        ) {
          return false;
        }
      }

      const dataObj = {
        miniAppId: selectedAppId || appId,
        categoryId: selectedCategoryId,
        userLang: userPref.lang ?? 'EN',
        userCountry: userCountry ?? 'IN',
        appVersion: getDeviceVersion(),
        userContact: user.phoneNumber ?? user.email,
        userDisplayName: user.displayName
          ? user.displayName
          : userPref.name
          ? userPref.name
          : ' ',
        userTimezone: userPref.timezone ?? getTimezone(),
        docReuseMapping: docReuseMapping,
        platform: getPlatform(),
      };

      const data = await createAppFromAppStoreCloudFunction(dataObj);

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return false;
      } else {
        if (data.miniAppId) {
          dispatch(setExploreForApps(EXPLORE_LIO_STEPS.INSTALL_APP.id));
          const {miniApps} = getState().miniApps;
          dispatch({
            type: MINI_APPS_ACTION.LOAD_MINI_APPS,
            payload: Object.assign({}, miniApps, {
              [data.miniAppId]: getFormattedMiniAppObj(
                data.miniAppDocData,
                data.miniAppId,
                user.uid,
                userPref,
              ),
            }),
          });

          if (successCallBack) {
            successCallBack(data.miniAppId, appId);
          }
          return data;
        }

        return data;
      }
    } catch (error) {
      captureError(error);
    }
    return false;
  };

const clearSelectedApp = () => (dispatch) => {
  dispatch({
    type: MINI_APPS_STORE_ACTION.CLEAR_SELECTED_APP,
    payload: true,
  });
};

const clearSelectedCategory = () => (dispatch) => {
  dispatch({
    type: MINI_APPS_STORE_ACTION.CLEAR_SELECTED_APP_CATEGORY,
    payload: true,
  });
};

export {
  getInitialStoreData,
  getCategoryData,
  getAppDetails,
  clearSelectedApp,
  clearSelectedCategory,
  createAppFromAppStore,
};
