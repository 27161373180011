import {firestore, captureError, functions, getReduxState} from '../../imports';
import {
  CLOUD_FUNCTION_COMMON_PARAMS,
  CLOUD_FUNCTION_PATHS,
} from '../../utils/constant';
import {callCloudFunction, handleCloudError} from '../../utils/utils';
import FirestoreDB from '../../FirestoreHandlers/FirestoreDB';

const setAutoFilledLinkedData = (autoFillData, docId) => {
  try {
    firestore()
      .collection('documents')
      .doc(docId)
      .collection('autoFillLinkedFiles')
      .doc('mapping')
      .set(autoFillData);
  } catch (error) {
    captureError(error);
  }
};

// const setViewLinkedFilesFlag = (docId, selectedColumnIndex) => {
//   try {
//     firestore()
//       .collection('documents')
//       .doc(docId)
//       .set(
//         {
//           [`headerData`]: {
//             [selectedColumnIndex]: {
//               isColumnLinked: true,
//             },
//           },
//         },
//         {merge: true},
//       );
//   } catch (error) {
//     captureError(error);
//   }
// };

const getAndSetTableLinkData = (newDocId, oldDocId) => {
  try {
    FirestoreDB.documents
      .documentAutoFillLinkedFilesRef(oldDocId)
      .get()
      .then((autoFillDataSnap) => {
        if (autoFillDataSnap.exists) {
          const autoFillData = autoFillDataSnap.data();
          FirestoreDB.documents
            .documentAutoFillLinkedFilesRef(newDocId)
            .set(autoFillData);
        }
      });

    FirestoreDB.documents
      .documentParentRowMetaRef(oldDocId)
      .get()
      .then((parentRowMetaDataSnap) => {
        if (parentRowMetaDataSnap.exists) {
          const parentRowMetaData = parentRowMetaDataSnap.data();
          FirestoreDB.documents
            .documentParentRowMetaRef(newDocId)
            .set(parentRowMetaData);
        }
      });
  } catch (error) {
    captureError(error);
  }
};

const fetchLinkedParentFileDataFromCloud = async (linkedDocIds) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.FETCH_LINKED_DOCUMENT_DATA_FOR_TABLE_LINKS,
    );
    const response = await functionInstance({linkedDocIds});
    return response.data;
  } catch (error) {
    captureError(error);
    return handleCloudError(error);
  }
};

const fetchTenDocs = (docArray, collectionRef) => {
  return collectionRef
    .where('__name__', 'in', docArray)
    .get()
    .then((res) => {
      const data = {};
      res.forEach((doc) => {
        if (doc.exists) {
          data[doc.id] = Object.assign({}, doc.data());
        }
      });
      return data;
    })
    .catch((e) => {
      captureError(e);
      return {};
    });
};

const getMultipleDocs = async (docIds, collectionRef) => {
  try {
    const resultDocIdDocDataMap = {};
    const promiseArr = [];
    const end =
      docIds.length % 10 === 0
        ? docIds.length
        : (parseInt(docIds.length / 10) + 1) * 10; //rounding to next 10's multiple
    for (let i = 0; i < end; i += 10) {
      //batch of 10
      const handlePromise = async () => {
        const data = await fetchTenDocs(docIds.slice(i, i + 10), collectionRef);
        Object.assign(resultDocIdDocDataMap, data);
      };
      promiseArr.push(handlePromise);
    }
    await Promise.all(promiseArr.map((fn) => fn()));
    return resultDocIdDocDataMap;
  } catch (err) {
    captureError(err);
    return null;
  }
};

const getLinkedDocIdsForSharedDocs = async (activeDocId) => {
  try {
    if (activeDocId) {
      const sharedDocsMetaSnap = await firestore()
        .collection('sharedDocsMeta')
        .doc(activeDocId)
        .get();

      if (sharedDocsMetaSnap.exists) {
        const sharedDocMeta = sharedDocsMetaSnap.data();
        return sharedDocMeta?.linkedDocIds || [];
      }
    }
  } catch (error) {
    captureError(error);
  }
};

const updateParentDocAutoFillLinkedDataFirestore = ({
  parentDocID,
  obj,
  docId,
}) => {
  try {
    return FirestoreDB.documents
      .documentRef(docId)
      .collection('autoFillLinkedFiles')
      .doc('mapping')
      .update({[parentDocID]: obj});
  } catch (error) {
    captureError(error);
  }
};

const setAutoFillLinkedDataFirestore = async ({
  activeDocId,
  parentDocID,
  parentColId,
  childColId,
  isUpdate = false,
  prevColId = null,
}) => {
  try {
    const updateObj = {};
    updateObj[parentColId] = childColId;
    if (isUpdate && prevColId) {
      await FirestoreDB.documents
        .documentRef(activeDocId)
        .collection('autoFillLinkedFiles')
        .doc('mapping')
        .update({
          [`${parentDocID}.${prevColId}`]: firestore(true).FieldValue.delete(),
          [`${parentDocID}.${parentColId}`]: childColId,
        });
    } else {
      await FirestoreDB.documents
        .documentRef(activeDocId)
        .collection('autoFillLinkedFiles')
        .doc('mapping')
        .set({[parentDocID]: {[parentColId]: childColId}}, {merge: true});
    }
  } catch (error) {
    captureError(error);
  }
};

const updateDocumentData = (docId, obj) => {
  try {
    return FirestoreDB.documents.documentRef(docId).update(obj);
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const fetchChildLinksCloud = (data) => {
  try {
    return functions().httpsCallable(CLOUD_FUNCTION_PATHS.FETCH_CHILD_LINKS)(
      data,
    );
  } catch (error) {
    captureError(error);
  }
};

const manageChildLinks = (data) => {
  try {
    return functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.MANAGE_CHILD_TABLE_LINKS,
    )(data);
  } catch (error) {
    captureError(error);
  }
};

const getUniqueDataForParentColWithElastic = (data) => {
  try {
    return callCloudFunction(
      getReduxState().remoteConfig.isOrganisationMode
        ? CLOUD_FUNCTION_PATHS.GET_COLUMN_DEPENDENT_UNIQUE_VALUES_ORG
        : CLOUD_FUNCTION_PATHS.GET_COLUMN_DEPENDENT_UNIQUE_VALUES,
      data,
      null,
      CLOUD_FUNCTION_COMMON_PARAMS.ELASTIC_REQUEST,
    ).then((res) => {
      return {
        data: res,
      };
    });
  } catch (error) {
    captureError(error);
  }
};

const setToParentRowMetaMapping = (docId, obj, merge = true) => {
  try {
    const ref = FirestoreDB.documents
      .documentRef(docId)
      .collection('parentRowMeta')
      .doc('mapping');
    return ref.set(obj, merge ? {merge: true} : {});
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const fetchAutoFillLinkedFilesMapping = (docId, options = {}) => {
  try {
    const {firestoreInstance = firestore} = options;
    const ref = FirestoreDB.documents.documentAutoFillLinkedFilesRef(
      docId,
      firestoreInstance,
    );
    return ref.get();
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

const setToAutoFillLinkedFilesMapping = (docId, obj, merge = true) => {
  try {
    const ref = FirestoreDB.documents.documentAutoFillLinkedFilesRef(docId);
    return ref.set(obj, merge ? {merge: true} : {});
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const updateAutoFillLinkedFilesMapping = (docId, obj) => {
  try {
    const ref = FirestoreDB.documents.documentAutoFillLinkedFilesRef(docId);
    return ref.update(obj);
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const getParentRowDataFirestore = (docId, rowId) => {
  try {
    const rowsRef = FirestoreDB.documents.rowsCollectionRef(docId);
    return rowsRef.doc(rowId).get();
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

export {
  setAutoFillLinkedDataFirestore,
  getMultipleDocs,
  fetchTenDocs,
  getLinkedDocIdsForSharedDocs,
  fetchLinkedParentFileDataFromCloud,
  setAutoFilledLinkedData,
  getAndSetTableLinkData,
  updateDocumentData,
  manageChildLinks,
  updateParentDocAutoFillLinkedDataFirestore,
  // setViewLinkedFilesFlag,
  setToParentRowMetaMapping,
  fetchAutoFillLinkedFilesMapping,
  setToAutoFillLinkedFilesMapping,
  updateAutoFillLinkedFilesMapping,
  fetchChildLinksCloud,
  getUniqueDataForParentColWithElastic,
  getParentRowDataFirestore,
};
