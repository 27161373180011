import {CAMPAIGN_ACTION} from '../actions/actionType';

const initialState = {
  docDetails: {}, // docId, docName
  filterColIds: {}, // same as table filter
  campaignColDetails: {}, // mobile no/Email colId to run campaign
  isScheduled: false, //
  scheduleDetails: {},
  templateDetails: {},
  upcomingCampaigns: {},
  pastCampaigns: {},
  reciepents: {},
  currentId: null,

  // Template Mapping
  colIdMapping: {},
  constValues: {},
  imageValues: {},
  templateId: null,
  templateType: '',
  userTemplates: {},
  lioTemplates: {},
  userTemplatesType: null,
  campaignTestNumbers: {},
  creditConfig: {},
  emailTemplates: {},
  recipients: {},
  lastRecipients: null,
  isRecipientsLoading: false,

  // campaign reports states
  stats: {
    activeCalendarDate: null,
    totalEmailCampaignCount: 0,
    totalWhatsAppCampaignCount: 0,
    totalEmailCampaignMessageCount: 0,
    totalWhatsAppCampaignMessageCount: 0,
  },
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_ACTION.SET_CAMPAIGN_TEST_NUMBERS:
      return {
        ...state,
        campaignTestNumbers: action.payload,
      };
    case CAMPAIGN_ACTION.GET_RECIPIENTS:
      return {
        ...state,
        recipients: action.payload.recipients,
        lastRecipients: action.payload.lastRecipients,
      };
    case CAMPAIGN_ACTION.LOADING_RECIPIENTS:
      return {
        ...state,
        isRecipientsLoading: true,
      };
    case CAMPAIGN_ACTION.FINISHED_RECIPIENTS_LOADING:
      return {
        ...state,
        isRecipientsLoading: false,
      };
    case CAMPAIGN_ACTION.LOAD_LIO_TEMPLATES:
      return {
        ...state,
        lioTemplates: action.payload,
      };
    case CAMPAIGN_ACTION.SET_CREDIT_CONFIG:
      return {
        ...state,
        creditConfig: action.payload,
      };
    case CAMPAIGN_ACTION.LOAD_CAMPAIGNS:
      return {
        ...state,
        upcomingCampaigns: action.payload.upcomingCampaigns,
        pastCampaigns: action.payload.pastCampaigns,
      };
    case CAMPAIGN_ACTION.SET_TEMPLATE_DETAILS:
      return {
        ...state,
        templateDetails: action.payload,
      };
    case CAMPAIGN_ACTION.CLEAR_FILTER_FOR_CAMPAIGN:
      return {
        ...state,
        filterColIds: {},
      };
    case CAMPAIGN_ACTION.SET_DOC_DETAILS:
      return {
        ...state,
        docDetails: action.payload,
        campaignColDetails: {},
        filterColIds: {},
        templateDetails: {},
        colIdMapping: {},
        constValues: {},
        imageValues: {},
      };
    case CAMPAIGN_ACTION.SET_PHONE_COL:
      return {
        ...state,
        campaignColDetails: action.payload,
      };
    case CAMPAIGN_ACTION.SET_FILTER_DETAILS:
      return {
        ...state,
        filterColIds: action.payload,
      };
    case CAMPAIGN_ACTION.SET_SCHEDULE_DETAILS:
      return {
        ...state,
        scheduleDetails: Object.assign(
          {},
          state.scheduleDetails,
          action.payload.scehduleDetails,
        ),
        isScheduled: action.payload.isScheduled,
      };
    case CAMPAIGN_ACTION.CREATE_CAMPAIGN:
      return {
        ...state,
        ...action.payload,
        docDetails: {},
        filterColIds: {},
        campaignColDetails: {},
        isScheduled: false,
        scheduleDetails: {},
        templateDetails: {},
        currentId: null,
        colIdMapping: {},
      };
    case CAMPAIGN_ACTION.LOAD_CURRENT_CAMPAIGN:
      return {
        ...state,
        ...action.payload,
      };
    case CAMPAIGN_ACTION.SET_TO_CURRENT_CAMPAIGN:
      return {
        ...state,
        ...action.payload,
      };
    case CAMPAIGN_ACTION.SET_CAMPAIGN_TEMPLATE_ID:
      return {
        ...state,
        templateId: action.payload.templateId,
      };
    case CAMPAIGN_ACTION.CLEAR_CAMPAIGN_STATE:
      return {
        ...state,
        docDetails: {},
        filterColIds: {},
        campaignColDetails: {},
        isScheduled: false,
        scheduleDetails: {},
        templateDetails: {},
        currentId: null,
        colIdMapping: {},
        constValues: {},
        imageValues: {},
      };
    case CAMPAIGN_ACTION.LOAD_USER_TEMPLATES:
      return {
        ...state,
        userTemplates: action.payload.templates,
        userTemplatesType: action.payload.type,
      };
    case CAMPAIGN_ACTION.LOAD_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: action.payload.emailTemplates,
      };
    case CAMPAIGN_ACTION.LOAD_CAMPAIGN_STATS:
      return {
        ...state,
        stats: action.payload,
      };
    default:
      return state;
  }
};

export default campaignReducer;
