import {isEmpty} from 'lodash';
import {firestore} from '../../imports';
import {firestoreServerTimestamp} from '../utils';
const {SERVICE_DETAILS} = require('./notificationConstant');

class NotificationService {
  generateNotificationId(activeAppId, uid) {
    const timestamp = Date.now();
    return `${activeAppId}_${uid}_${timestamp}`;
  }

  validateService(service, type) {
    const serviceConfig = SERVICE_DETAILS?.[service];
    if (!serviceConfig || !serviceConfig.isEnabled) {
      throw new Error(`${service} service is not enabled.`);
    }
    if (!serviceConfig.notificationTypes.includes(type)) {
      throw new Error(
        `Unsupported notification type for the ${service} service.`,
      );
    }
  }

  validateParams(params) {
    const {appId, initiatedBy, isRunning, orgId, service, type, data, forUID} =
      params;
    if (
      !appId ||
      !initiatedBy ||
      typeof isRunning !== 'boolean' ||
      !orgId ||
      !service ||
      !type ||
      isEmpty(data) ||
      isEmpty(forUID)
    ) {
      throw new Error('Missing required parameters.');
    }
  }

  async addOrUpdateNotification(params) {
    const {
      appId,
      service,
      type,
      data,
      forAppId,
      forUID,
      isRunning,
      orgId,
      initiatedBy,
      notificationId,
    } = Object.assign({}, params);

    this.validateParams(params);
    this.validateService(service, type);

    let notificationDoc = {
      orgId,
      appId,
      data,
      service,
      type,
      isRunning,
      forAppId: forAppId || null,
      forUID: forUID || [],
      initiatedBy,
      startedAt: firestoreServerTimestamp(),
      completedAt: isRunning === false ? firestoreServerTimestamp() : null,
    };

    try {
      const docRef = notificationId
        ? firestore().collection('notifyUser').doc(notificationId)
        : firestore().collection('notifyUser').doc().id;
      await docRef.set(notificationDoc, {merge: true});
      return {success: true, id: docRef.id};
    } catch (error) {
      console.error('Error adding/updating notification:', error);
      throw new Error('Failed to add/update notification.');
    }
  }
}

export {NotificationService};
