import {DASHBOARD_ACTION} from '../actions/actionType';

const initialState = {
  allDashboards: {},
  dashboards: [],
  isFetching: false,
  isDashboardsMigrating: false,
  dashboardSuggestedFiles: [],
  dashboardTemplateSuggestions: {},
  selectedFile: {},
  fileData: {},
  selectedOperation: null,
  selectedColumn: null,
  suggestedDashboards: [],
  visibleDashboards: [],
  editDashboardConfigs: {},
  visibleDashboardsLimitingObj: {
    limitingIndex: -1,
    excessCount: -1,
  },
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_ACTION.START_FETCHING_DASHBOARDS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case DASHBOARD_ACTION.SET_SUGGESTED_DASHBOARDS: {
      return {
        ...state,
        suggestedDashboards: action.payload,
      };
    }
    case DASHBOARD_ACTION.SET_SELECTED_FILE: {
      if (action.payload.isFileChanged) {
        return {
          ...state,
          selectedFile: action.payload.selectedFile,
          fileData: action.payload.fileData,
          selectedOperation: null,
          selectedColumn: null,
        };
      } else {
        return {
          ...state,
          selectedFile: action.payload.selectedFile,
          fileData: action.payload.fileData,
        };
      }
    }
    case DASHBOARD_ACTION.SET_SELECTED_COLUMN: {
      return {
        ...state,
        selectedColumn: action.payload,
      };
    }
    case DASHBOARD_ACTION.SET_SELECTED_OPERATION: {
      return {
        ...state,
        selectedOperation: action.payload,
      };
    }
    case DASHBOARD_ACTION.CLEAR_SELECTION: {
      return {
        ...state,
        selectedFile: {},
        fileData: {},
        selectedOperation: null,
        selectedColumn: null,
        suggestedDashboards: [],
      };
    }
    case DASHBOARD_ACTION.UPDATE_DASHBOARD_LIMITING_OBJECT: {
      return {
        ...state,
        visibleDashboardsLimitingObj: action.payload,
      };
    }
    case DASHBOARD_ACTION.STOP_FETCHING_DASHBOARDS: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case DASHBOARD_ACTION.UPDATE_DASHBOARDS_MIGRATION_LOADER: {
      return {
        ...state,
        isDashboardsMigrating: action.payload,
      };
    }
    case DASHBOARD_ACTION.LOAD_ALL_DASHBOARDS: {
      return {
        ...state,
        allDashboards: action.payload,
      };
    }
    case DASHBOARD_ACTION.SET_DASHBOARD_SUGGESTED_FILES: {
      return {
        ...state,
        dashboardSuggestedFiles: action.payload,
      };
    }
    case DASHBOARD_ACTION.SET_DASHBOARD_TEMPLATE_SUGGESTIONS: {
      return {
        ...state,
        dashboardTemplateSuggestions: action.payload,
      };
    }
    case DASHBOARD_ACTION.CLEAR_DASHBOARD_STATE:
    case DASHBOARD_ACTION.RESET_STATE: {
      return Object.assign({}, initialState);
    }
    case DASHBOARD_ACTION.SET_VISIBLE_DASHBOARDS: {
      return {
        ...state,
        visibleDashboards: action.payload,
      };
    }
    case DASHBOARD_ACTION.SET_EDIT_DASHBOARD_CONFIGS: {
      return {
        ...state,
        editDashboardConfigs: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default dashboardReducer;
