import {cloneDeep, omit} from 'lodash';
import {PERSISTED_DATA_ACTION} from '../actions/actionType';

const initialState = {
  documentActivityLog: {},
  accountActivityLog: {},
  documentLogCount: 0,
  accountLogCount: 0,
  uniqueDeviceId: '',
};

const persistedDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case PERSISTED_DATA_ACTION.SET_DOC_ACTIVITY_LOG: {
      return {
        ...state,
        documentActivityLog: cloneDeep(action.payload),
        documentLogCount: state.documentLogCount + 1,
      };
    }
    case PERSISTED_DATA_ACTION.SET_ACCOUNT_ACTIVITY_LOG:
      return {
        ...state,
        accountActivityLog: cloneDeep(action.payload),
        accountLogCount: state.accountLogCount + 1,
      };
    case PERSISTED_DATA_ACTION.RESET_DOC_ACTIVITY_LOG: {
      return {
        ...state,
        documentActivityLog: {},
        documentLogCount: 0,
      };
    }
    case PERSISTED_DATA_ACTION.RESET_ACCOUNT_ACTIVITY_LOG:
      return {
        ...state,
        accountActivityLog: {},
        accountLogCount: 0,
      };
    case PERSISTED_DATA_ACTION.SET_UNIQUE_DEVICE_ID:
      return {
        ...state,
        uniqueDeviceId: action.payload,
      };
    case PERSISTED_DATA_ACTION.RESET_STATE:
      return omit(initialState, ['uniqueDeviceId']);
    default:
      return state;
  }
};

export default persistedDataReducer;
