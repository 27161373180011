const EditFieldsIcon = ({color}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66667 12H1.33333C0.593333 12 0 11.4067 0 10.6667V1.33333C0 0.593333 0.593333 0 1.33333 0H10.6667C11.4067 0 12 0.593333 12 1.33333V4.88667C11.8 4.80667 11.58 4.76 11.36 4.76C11.1133 4.76 10.88 4.81333 10.6667 4.91333V1.33333H1.33333V10.6667H4.74L4.66667 10.74V12ZM2.66667 4H9.33333V2.66667H2.66667V4ZM2.66667 9.33333H6.07333L7.33333 8.08V8H2.66667V9.33333ZM2.66667 6.66667H8.74667L9.33333 6.08V5.33333H2.66667V6.66667ZM12.4667 7.05333L11.6133 6.2C11.4733 6.06 11.24 6.06 11.1 6.2L10.4333 6.86667L11.8 8.23333L12.4667 7.56667C12.6067 7.42667 12.6067 7.19333 12.4667 7.05333ZM6 12.6667H7.37333L11.4067 8.62L10.04 7.25333L6 11.2933V12.6667Z"
        fill={color ? color : '#495A74'}
      />
    </svg>
  );
};
export default EditFieldsIcon;
