import {isArray, isEmpty, isObject} from 'lodash';
import FirestoreDB from '../../FirestoreHandlers/FirestoreDB';
import {
  CLOUD_FUNCTION_COMMON_PARAMS,
  CLOUD_FUNCTION_PATHS,
  DASHBOARD_CLOUD_FUNCTION_PATHS,
  DASHBOARD_SUB_TYPE,
} from '../../utils/constant';
import {callCloudFunction} from '../../utils/utils';
import {
  mapFilterArrForStoringFirestore,
  mapFilterArrFromFirestore,
} from './searchFilterActionHelper';

const createEditElasticDashboardCloudFunction = (obj) =>
  callCloudFunction(
    CLOUD_FUNCTION_PATHS.CREATE_OR_UPDATE_ELASTIC_DASHBOARD,
    obj,
    null,
    CLOUD_FUNCTION_COMMON_PARAMS.ELASTIC_REQUEST,
  );

const calculateDashboardFromData = (obj) =>
  callCloudFunction(
    DASHBOARD_CLOUD_FUNCTION_PATHS.CALCULATE_DASHBOARD_FROM_DATA,
    obj,
    null,
    CLOUD_FUNCTION_COMMON_PARAMS.ELASTIC_REQUEST,
  );

const fetchElasticDashboardsForActiveScreen = (
  appId,
  screenId,
  firestoreInstance = null,
) => {
  return FirestoreDB.elasticDashboards
    .dashboardRef(firestoreInstance)
    .where('miniAppId', '==', appId)
    .where('screenId', '==', screenId);
};

const mapFilterArrForMultiScreen = (screenDetails, mapFunction) => {
  const updatedScreenDetails = {};
  if (!isObject(screenDetails) || isEmpty(screenDetails)) {
    return {};
  }
  for (const screenId in screenDetails) {
    const screenObj = screenDetails[screenId];
    if (isArray(screenObj.filters) && screenObj.filters.length) {
      updatedScreenDetails[screenId] = {
        ...screenObj,
        filters: mapFunction(screenDetails[screenId].filters),
      };
    } else {
      updatedScreenDetails[screenId] = {
        ...screenObj,
      };
    }
    if (
      isArray(screenObj.columnDetailsArr) &&
      screenObj.columnDetailsArr.length
    ) {
      updatedScreenDetails[screenId] = {
        ...updatedScreenDetails[screenId],
        columnDetailsArr: screenObj.columnDetailsArr.map((column) => {
          if (isArray(column.filters) && column.filters.length) {
            return {...column, filters: mapFunction(column.filters)};
          } else {
            return {...column};
          }
        }),
      };
    }
  }
  return {screenDetails: updatedScreenDetails};
};

const formatDashboardConfigObj = (dashboardConfigObj, isUpload = true) => {
  if (isUpload) {
    if (
      [
        DASHBOARD_SUB_TYPE.SPLIT_BY_VALUE.N_VALUES,
        DASHBOARD_SUB_TYPE.SIMPLE.N_VALUES_WITHOUT_SPLIT,
      ].includes(dashboardConfigObj?.subType)
    ) {
      return {
        ...dashboardConfigObj,
        ...(dashboardConfigObj.filters
          ? {
              filters: mapFilterArrForStoringFirestore(
                dashboardConfigObj.filters,
              ),
            }
          : {}),
        ...(dashboardConfigObj.screenDetails
          ? elasticDashboardsActionHelper.mapFilterArrForMultiScreen(
              dashboardConfigObj.screenDetails,
              mapFilterArrForStoringFirestore,
            )
          : {}),
      };
    } else {
      return {
        ...dashboardConfigObj,
        ...(dashboardConfigObj.filters
          ? {
              filters: mapFilterArrForStoringFirestore(
                dashboardConfigObj.filters,
              ),
            }
          : {}),
      };
    }
  } else {
    const formattedObj = Object.assign({}, dashboardConfigObj);
    formattedObj['filters'] = mapFilterArrFromFirestore(
      dashboardConfigObj.filters,
    );
    if (
      [
        DASHBOARD_SUB_TYPE.SPLIT_BY_VALUE.N_VALUES,
        DASHBOARD_SUB_TYPE.SIMPLE.N_VALUES_WITHOUT_SPLIT,
      ].includes(dashboardConfigObj?.subType) &&
      dashboardConfigObj.screenDetails &&
      !isEmpty(dashboardConfigObj.screenDetails)
    ) {
      formattedObj['screenDetails'] =
        elasticDashboardsActionHelper.mapFilterArrForMultiScreen(
          dashboardConfigObj.screenDetails,
          mapFilterArrFromFirestore,
        )?.screenDetails;
    }
    return formattedObj;
  }
};

const manageDashboardIndex = (obj) =>
  callCloudFunction(
    DASHBOARD_CLOUD_FUNCTION_PATHS.MANAGE_DASHBOARD_SORTING_INDEX,
    obj,
    null,
    CLOUD_FUNCTION_COMMON_PARAMS.ELASTIC_REQUEST,
  );

export const elasticDashboardsActionHelper = {
  calculateDashboardFromData,
  fetchElasticDashboardsForActiveScreen,
  createEditElasticDashboardCloudFunction,
  mapFilterArrForMultiScreen,
  formatDashboardConfigObj,
  manageDashboardIndex,
};
