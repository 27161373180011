import {
  COLLABORATION_ACTION,
  CONTACTS_ACTION,
  HOME_ACTION,
  TABLE_ACTION,
} from './actionType';
import {
  SHARE_PERMISSION_TYPE,
  COLLAB_ROW_COLORS,
  COLLAB_LISTENERS,
  TABLE_LINK_LISTENERS,
  AUTO_MESSAGE_LISTENER,
  INITIAL_FETCH_COMPLETED,
  FIELD_TYPE_ID,
  CREATED_INFO_COLUMN,
  UNIQUE_VALUES_LISTENERS,
} from '../utils/constant';
import * as collabActionHelper from './actionHelpers/collabActionHelper';
import {reloadVisibleFiles} from './homeAction';
import {formatRecentlySharedContacts} from './contactAction';
import {
  getLocalText,
  checkIfEmail,
  getTimezone,
  getNewRowPropertiesObject,
} from '../utils/utils';
import {
  activateDocumentListener,
  activateSharedDocMetaListener,
  activeDocumentFooterPropertiesListener,
  updateInitialFetchObj,
  addNewColumn,
  activateUniqueColumnValuesListener,
} from './tableAction';
import {forOwn, isEmpty, pick, isFunction} from 'lodash';
import {
  ENV,
  database,
  captureError,
  captureInfo,
  ShowToast,
  firestore,
} from '../imports';
import {
  activateAutofillLinkedFilesListener,
  activateParentRowMetaListener,
  fetchParentFileData,
} from './tableLinksActions';
import {activatePendingAutomationsListener} from './automationAction';
import moment from 'moment';
import {restrictHeaderData} from '../utils/restrictionUtils';
import DocumentsMethods from '../FirestoreHandlers/Documents/DocumentsMethods';
import {clearVersionData} from './versionAction';

const activateListener =
  (type, firestoreInstance = null, dbInstance = null) =>
  (dispatch, getState) => {
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      dbInstance = dbInstance ?? database;
      const {home: homeState} = getState();
      const {activeDocumentId} = homeState;
      const originalDocumentId = homeState.originalDocumentId;
      let action,
        params = [];
      switch (type) {
        case AUTO_MESSAGE_LISTENER.PENDING_AUTOMATIONS: {
          action = activatePendingAutomationsListener;
          params = [activeDocumentId, firestoreInstance];
          break;
        }
        case TABLE_LINK_LISTENERS.PARENT_ROW_META: {
          action = activateParentRowMetaListener;
          params = [activeDocumentId, firestoreInstance];
          break;
        }
        case TABLE_LINK_LISTENERS.AUTO_FILL_LINKED_FILES: {
          action = activateAutofillLinkedFilesListener;
          params = [activeDocumentId, firestoreInstance];
          break;
        }
        case COLLAB_LISTENERS.ACTIVE_USERS: {
          action = activateActiveUsersListener;
          params = [activeDocumentId, dbInstance];
          break;
        }
        case COLLAB_LISTENERS.DOCUMENT_DATA: {
          action = activateDocumentListener;
          params = [activeDocumentId, firestoreInstance];
          break;
        }
        case COLLAB_LISTENERS.PAGES_META: {
          action = activateSharedDocMetaListener;
          params = [originalDocumentId, firestoreInstance];
          break;
        }
        case COLLAB_LISTENERS.FOOTER_PROPERTIES: {
          action = activeDocumentFooterPropertiesListener;
          params = [activeDocumentId, firestoreInstance];
          break;
        }
        case UNIQUE_VALUES_LISTENERS.UNIQUE_VALUES_DATA: {
          action = activateUniqueColumnValuesListener;
          params = [activeDocumentId, firestoreInstance];
          break;
        }
        default: {
          throw new Error(`Invalid collab-listener type`);
        }
      }
      const instance = dispatch(action(...params));
      const updatedListeners = Object.assign(
        {},
        getState().collab.listenersObj,
        {
          [type]: instance,
        },
      );
      dispatch({
        type: COLLABORATION_ACTION.ACTIVATE_DEACTIVATE_LISTENERS,
        payload: updatedListeners,
      });
    } catch (error) {
      captureInfo({type});
      captureError(error);
    }
  };

const deactivateListener =
  (type, isAll = false) =>
  (dispatch, getState) => {
    return new Promise((resolve) => {
      try {
        let listenersObj = Object.assign({}, getState().collab.listenersObj);
        const resetState = (listenerType) => {
          switch (listenerType) {
            case COLLAB_LISTENERS.ACTIVE_USERS: {
              dispatch({
                type: COLLABORATION_ACTION.UPDATE_ACTIVE_USERS,
                payload: {activeUsers: {}, activeUsersCount: 0, colorMap: {}},
              });
              break;
            }
          }
        };
        const deactivate = (listener) => {
          try {
            if (isFunction(listener)) {
              listener();
            }
          } catch (error) {
            captureError(error);
          }
        };
        if (isAll) {
          forOwn(listenersObj, (val, listenerType) => {
            resetState(listenerType);
            deactivate(val);
          });
          listenersObj = {};
        } else {
          if (type === COLLAB_LISTENERS.DOCUMENT_DATA) {
            dispatch(
              updateInitialFetchObj({
                [INITIAL_FETCH_COMPLETED.TABLE_DATA]: false,
              }),
            );
          }
          resetState(type);
          deactivate(listenersObj[type]); //deactivated
          delete listenersObj[type];
        }
        dispatch({
          type: COLLABORATION_ACTION.ACTIVATE_DEACTIVATE_LISTENERS,
          payload: listenersObj,
        });
      } catch (error) {
        captureError(error);
      }
      resolve();
    });
  };

const modifyListenersOnPageChange =
  (
    updatedMetaData,
    prevPageDocId,
    firestoreInstance = null,
    dbInstance = null,
  ) =>
  (dispatch, getState) => {
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      dbInstance = dbInstance ?? database;
      if (!updatedMetaData) {
        updatedMetaData = getState().home.activeDocumentMeta;
      }
      dispatch({type: TABLE_ACTION.START_TABLE_LOADING}); //reset all table states, with loading state true
      //remove listener on previous page && start on current/new page
      dispatch(deactivateListener(COLLAB_LISTENERS.DOCUMENT_DATA)).then(() =>
        dispatch(
          activateListener(COLLAB_LISTENERS.DOCUMENT_DATA, firestoreInstance),
        ),
      );
      dispatch(
        deactivateListener(UNIQUE_VALUES_LISTENERS.UNIQUE_VALUES_DATA),
      ).then(() =>
        dispatch(
          activateListener(
            UNIQUE_VALUES_LISTENERS.UNIQUE_VALUES_DATA,
            firestoreInstance,
          ),
        ),
      );
      dispatch(
        deactivateListener(AUTO_MESSAGE_LISTENER.PENDING_AUTOMATIONS),
      ).then(() =>
        dispatch(
          activateListener(
            AUTO_MESSAGE_LISTENER.PENDING_AUTOMATIONS,
            firestoreInstance,
          ),
        ),
      );
      dispatch(
        deactivateListener(TABLE_LINK_LISTENERS.AUTO_FILL_LINKED_FILES),
      ).then(() =>
        dispatch(
          activateListener(
            TABLE_LINK_LISTENERS.AUTO_FILL_LINKED_FILES,
            firestoreInstance,
          ),
        ),
      );
      dispatch(deactivateListener(TABLE_LINK_LISTENERS.PARENT_ROW_META)).then(
        () =>
          dispatch(
            activateListener(
              TABLE_LINK_LISTENERS.PARENT_ROW_META,
              firestoreInstance,
            ),
          ),
      );
      dispatch({
        type: TABLE_ACTION.SET_SPLIT_BY_CALCULATIONS,
        payload: {},
      }); //reset split by calculations on page change
      dispatch(deactivateListener(COLLAB_LISTENERS.FOOTER_PROPERTIES)).then(
        () =>
          dispatch(
            activateListener(
              COLLAB_LISTENERS.FOOTER_PROPERTIES,
              firestoreInstance,
            ),
          ),
      );

      //handle version on page changes
      dispatch(clearVersionData());

      if (!isEmpty(updatedMetaData.collab)) {
        //for shared docs
        //remove presence from previous page
        prevPageDocId &&
          dispatch(changeUserPresenceActivity(null, null, true, prevPageDocId));
        if (
          updatedMetaData.collab.permission !== SHARE_PERMISSION_TYPE.CAN_VIEW
        ) {
          dispatch(deactivateListener(COLLAB_LISTENERS.ACTIVE_USERS)).then(
            () => {
              dispatch(
                activateListener(
                  COLLAB_LISTENERS.ACTIVE_USERS,
                  null,
                  dbInstance,
                ),
              );
              //show presence on current page
              dispatch(changeUserPresenceActivity());
            },
          );
        }
      }
    } catch (error) {
      captureError(error);
    }
  };

const loadCollabMeta =
  (isForcedFetch = false, showPermissionPopUp = true) =>
  (dispatch, getState) => {
    return new Promise((resolve) => {
      const handleError = (error) => {
        dispatch({
          type: COLLABORATION_ACTION.STOP_COLLAB_LOADING,
        });
        if (isForcedFetch) {
          ShowToast(
            'Please check your internet connection and try again.',
            getState().auth.userPref,
          );
        }
        captureError(error);
        return resolve();
      };
      try {
        dispatch({type: COLLABORATION_ACTION.SET_COLLAB_DOC_TRUE});
        const {home, auth} = getState();
        if (isEmpty(home.activeDocumentMeta?.collab)) {
          return;
        }
        const {permission, isOwner} = home.activeDocumentMeta.collab;
        if (
          isForcedFetch ||
          isOwner ||
          [SHARE_PERMISSION_TYPE.OWNER, SHARE_PERMISSION_TYPE.ADMIN].includes(
            permission,
          )
        ) {
          dispatch({
            type: COLLABORATION_ACTION.START_COLLAB_LOADING,
          });
          const docId = home.originalDocumentId;
          let dataForErrorHanding = null;
          collabActionHelper
            .fetchCollabData(docId)
            .then(async (collabData) => {
              dataForErrorHanding = collabData;
              const sharedWithObj = Object.assign({}, collabData.sharedWith);
              const uid = auth.user?.uid;
              if (!uid) {
                dispatch({
                  type: COLLABORATION_ACTION.STOP_COLLAB_LOADING,
                });
                return resolve();
              }
              const selfText = getLocalText(auth.userPref, 'Me');
              const sharedWith = await collabActionHelper.formatSharedWith(
                sharedWithObj,
                uid,
                selfText,
                auth.userPref,
                showPermissionPopUp,
              );
              const isOwnerUsingEmail = checkIfEmail(
                collabData?.owner?.contact,
              );
              const ownerDetails = {
                hasThumbnail: false,
                phoneNumbers: isOwnerUsingEmail
                  ? []
                  : [collabData?.owner?.contact],
                isSelf: false,
                uid: collabData.owner?.uid,
                permission: SHARE_PERMISSION_TYPE.OWNER,
                displayName: '',
                thumbnailPath: '',
                emailAddresses: isOwnerUsingEmail
                  ? [collabData?.owner?.contact]
                  : [],
              };
              if (uid !== collabData?.owner?.uid) {
                const obj = await collabActionHelper.searchContactInPhone(
                  collabData?.owner?.contact,
                  isOwnerUsingEmail,
                  false,
                  true,
                  auth.userPref,
                  showPermissionPopUp,
                );
                if (obj) {
                  Object.assign(ownerDetails, obj);
                }
              } else {
                Object.assign(ownerDetails, {
                  displayName: selfText,
                  isSelf: true,
                });
              }
              dispatch({
                type: COLLABORATION_ACTION.LOAD_COLLAB_META,
                payload: {sharedWith, ownerDetails},
              });
              dispatch({
                type: COLLABORATION_ACTION.STOP_COLLAB_LOADING,
              });
              return resolve();
            })
            .catch((err) => {
              captureInfo({
                dataForErrorHanding: JSON.stringify(dataForErrorHanding),
                homeState: JSON.stringify(home),
                isForcedFetch,
              });
              return handleError(err);
            });
        }
      } catch (error) {
        return handleError(error);
      }
    });
  };

const resetCollabStates = () => (dispatch) => {
  dispatch({type: COLLABORATION_ACTION.CLEAR_LAST_COLLAB_STATE});
};

const activateActiveUsersListener =
  (docId, dbInstance = null) =>
  (dispatch, getState) => {
    try {
      dbInstance = dbInstance ?? database;
      const {auth} = getState();
      const uid = auth.user?.uid;
      if (!uid) {
        return;
      }
      const countActiveUsers = (users) =>
        Object.values(users).reduce((a, obj) => a + (obj?.isActive ? 1 : 0), 0);
      const masterHandler = (Snapshot, type) => {
        try {
          if (Snapshot.exists() && docId === getState().home.activeDocumentId) {
            const key = Snapshot.key;
            const val = Snapshot.val();
            if (key && key !== uid && val) {
              switch (type) {
                case 'child_added':
                case 'child_changed': {
                  const collabState = getState().collab;
                  const activeUsers = Object.assign(
                    {},
                    collabState.activeUsers,
                    {
                      [key]: val,
                    },
                  );
                  const activeUsersCount = countActiveUsers(activeUsers);
                  let extra = {};
                  if (type === 'child_added' && !collabState.colorMap[key]) {
                    extra = {
                      colorMap: Object.assign({}, collabState.colorMap, {
                        [key]:
                          COLLAB_ROW_COLORS[
                            Object.keys(collabState.colorMap).length %
                              COLLAB_ROW_COLORS.length
                          ],
                      }),
                    };
                  }
                  dispatch({
                    type: COLLABORATION_ACTION.UPDATE_ACTIVE_USERS,
                    payload: {activeUsers, activeUsersCount, extra},
                  });
                  break;
                }
                case 'child_removed': {
                  const collabState = getState().collab;
                  const activeUsers = Object.assign(
                    {},
                    collabState.activeUsers,
                  );
                  delete activeUsers[key];
                  const activeUsersCount = countActiveUsers(activeUsers);
                  dispatch({
                    type: COLLABORATION_ACTION.UPDATE_ACTIVE_USERS,
                    payload: {activeUsers, activeUsersCount},
                  });
                  break;
                }
              }
            }
          }
        } catch (error) {
          captureInfo({Snapshot, type});
          captureError(new Error('Error in activateActiveUsersListener'));
        }
      };
      const listeners = [
        {type: 'child_changed'},
        {type: 'child_added'},
        {type: 'child_removed'},
      ];
      const dbRef = dbInstance().ref(`/collabDocActiveUsers/${docId}`);
      listeners.forEach((obj, index) => {
        listeners[index].instance = dbRef.on(obj.type, (snap) => {
          masterHandler(snap, obj.type);
        });
      });
      return () => {
        listeners.forEach((obj) => {
          dbRef.off(obj.type, obj.instance);
        });
      };
    } catch (error) {
      captureError(error);
    }
  };

const changeUserPresenceActivity =
  (
    obj = {},
    checkForMoreUsers = false,
    isRemove = false,
    prevPageDocId = null,
  ) =>
  (_, getState) => {
    try {
      const {collab, home, auth} = getState();
      if (!auth.user?.uid) {
        return;
      }
      if (!(checkForMoreUsers && collab.activeUsersCount < 1)) {
        collabActionHelper.updateCollabActiveUsersData(
          prevPageDocId ? prevPageDocId : home.activeDocumentId,
          auth.user.uid,
          Object.assign({}, {isActive: true}, obj),
          isRemove,
        );
      }
    } catch (error) {
      captureError(error);
    }
  };

const getActiveUsersDetails = () => async (dispatch, getState) => {
  try {
    const homeState = getState().home;
    const docId = homeState.originalDocumentId;
    const activeUsersDetails =
      await collabActionHelper.getCollabActiveUsersDetails(docId);
    if (activeUsersDetails) {
      dispatch({
        type: COLLABORATION_ACTION.UPDATE_ACTIVE_USERS_DETAILS,
        payload: {activeUsersDetails},
      });
    }
  } catch (error) {
    captureError(error);
  }
};

const shareFileToContacts =
  ({
    contactArr,
    permission,
    countryDiallingCode,
    columnRestrictions,
    sharingProperties,
    shareFrom = null,
  }) =>
  async (dispatch, getState) => {
    return new Promise((resolve) => {
      let result = {};
      try {
        const {
          home: homeState,
          auth: authState,
          contacts: contactsState,
          remoteConfig: {restrictionConfig},
        } = getState();
        const fileMeta = homeState.activeDocumentMeta;
        const docId = homeState.originalDocumentId;
        const recentlySharedContactsArr =
          contactsState.recentlySharedContacts.slice();
        const isSharingForFirstTime = isEmpty(fileMeta?.collab);
        const isSharerOwner = isSharingForFirstTime
          ? true
          : fileMeta.collab.isOwner
          ? true
          : false;
        const userObj = authState.user;
        const sharerUID = userObj?.uid;
        const userRestrictionConfig = Object.assign({}, restrictionConfig);
        if (!sharerUID) {
          return;
        }
        const sharerContact = userObj.phoneNumber ?? userObj.email;
        const dataObj = {
          sharerContact,
          docId,
          contactArr,
          permission,
          recentlySharedContacts: recentlySharedContactsArr,
          isSharerOwner,
          countryDiallingCode,
          lang: authState.userPref?.lang ?? 'EN',
          rowIdCheck: true,
          columnRestrictions,
          sharingProperties,
          restrictionConfig: userRestrictionConfig,
        };
        collabActionHelper
          .shareCollabFile(dataObj)
          .then((data) => {
            if (!data || !data.success) {
              let message;
              if (data?.message) {
                message = data.message;
              } else {
                message = 'Something went wrong, please try again';
              }
              if (data?.error) {
                captureInfo({log: data.log});
                captureError(new Error(data.error), true);
              }
              result = {
                success: false,
                message,
                failedDueToVersionArr: data?.failedDueToVersionArr,
              };
              return resolve(result);
            } else {
              //Add Create Info column
              if (shareFrom === 'DocContainer') {
                DocumentsMethods.getUserDocumentDataWithoutRows(docId).then(
                  (obj) => {
                    if (Array.isArray(obj?.headerData)) {
                      dispatch(
                        addNewColumn(
                          {
                            type: FIELD_TYPE_ID.CREATED_INFO,
                            colName: CREATED_INFO_COLUMN.colName,
                            headerData: obj.headerData,
                          },
                          true,
                        ),
                      );
                    }
                  },
                );
              } else {
                dispatch(
                  addNewColumn(
                    {
                      type: FIELD_TYPE_ID.CREATED_INFO,
                      colName: CREATED_INFO_COLUMN.colName,
                    },
                    true,
                  ),
                );
              }
              const {
                failedArr,
                updatedMeta, //only available if sharer is owner and file is shared for first time
                sharedWith,
                recentlySharedContacts,
                failedDueToVersionArr,
              } = data;

              const selfText = getLocalText(authState.userPref, 'Me');
              if (isSharingForFirstTime) {
                const isOwnerUsingEmail = checkIfEmail(sharerContact);
                const ownerDetails = {
                  phoneNumbers: isOwnerUsingEmail ? [] : [sharerContact],
                  isSelf: true,
                  uid: sharerUID,
                  permission: SHARE_PERMISSION_TYPE.OWNER,
                  displayName: selfText,
                  hasThumbnail: false,
                  thumbnailPath: '',
                  emailAddresses: isOwnerUsingEmail ? [sharerContact] : [],
                };
                dispatch({
                  type: COLLABORATION_ACTION.UPDATE_OWNER_DETAILS,
                  payload: {ownerDetails},
                });
              }
              if (recentlySharedContacts.length > 0) {
                dispatch({
                  type: CONTACTS_ACTION.SET_RECENT_SHARED_CONTACT,
                  payload: recentlySharedContacts,
                });
                dispatch(formatRecentlySharedContacts());
              }
              if (!isEmpty(updatedMeta)) {
                dispatch({
                  type: HOME_ACTION.UPDATE_DOCUMENT_META,
                  payload: updatedMeta,
                });
                const filesArr = homeState.files.slice();
                filesArr.splice(homeState.activeFileIndex, 1, {
                  documentId: docId,
                  documentMeta: updatedMeta,
                });
                dispatch({
                  type: HOME_ACTION.LOAD_FILES,
                  payload: {files: filesArr},
                });
                reloadVisibleFiles(dispatch, getState);
              }
              return collabActionHelper
                .formatSharedWith(
                  sharedWith,
                  sharerUID,
                  selfText,
                  authState.userPref,
                  false,
                )
                .then((sharedWithArr) => {
                  dispatch({
                    type: COLLABORATION_ACTION.UPDATE_SHARED_WITH,
                    payload: sharedWithArr,
                  });
                  result = {success: true, failedArr, failedDueToVersionArr};
                  return resolve(result);
                });
            }
          })
          .catch((error) => {
            captureError(error);
            return resolve(result);
          });
      } catch (error) {
        captureError(error);
        return resolve(result);
      }
    });
  };

const changeSharingPermission =
  ({
    uidWhosePermissionChange,
    updatedPermission,
    columnRestrictions,
    sharingProperties,
  }) =>
  async (dispatch, getState) => {
    try {
      const {home: homeState, auth: authState} = getState();
      const docId = homeState.originalDocumentId;
      const userObj = authState.user;
      const uidOfChanger = userObj?.uid;
      if (!uidOfChanger) {
        return;
      }
      const contactOfChanger = userObj.phoneNumber ?? userObj.email;
      const dataObj = {
        contactOfChanger,
        uidWhosePermissionChange,
        docId,
        updatedPermission,
        columnRestrictions,
        sharingProperties,
      };
      const data = await collabActionHelper.changeCollabUserPermission(dataObj);
      const updateSharedWith = () => {
        collabActionHelper
          .formatSharedWith(
            data.sharedWith,
            uidOfChanger,
            getLocalText(authState.userPref, 'Me'),
            authState.userPref,
            false,
          )
          .then((sharedWithArr) =>
            dispatch({
              type: COLLABORATION_ACTION.UPDATE_SHARED_WITH,
              payload: sharedWithArr,
            }),
          );
      };
      if (!data || !data.success) {
        let message;
        if (data?.sharedWith) {
          updateSharedWith();
        }
        if (data?.message) {
          message = data.message;
        } else {
          message = 'Something went wrong, please try again';
        }
        if (data?.error) {
          captureInfo({log: data.log});
          captureError(new Error(data.error), true);
        }
        return {success: false, message};
      } else {
        updateSharedWith();
        return {success: true};
      }
    } catch (error) {
      captureError(error);
    }
  };

const removeCollabUser =
  ({uidOfFileToRemove}, navigation) =>
  async (dispatch, getState) => {
    try {
      const {
        home: homeState,
        auth: authState,
        collab: collabState,
      } = getState();
      const docId = homeState.originalDocumentId;
      const uid = authState.user?.uid;
      if (!uid) {
        return;
      }
      const dataObj = {
        uidOfFileToRemove,
        docId,
        callerUID: uid,
      };
      const data = await collabActionHelper.removeSharedDocFromUID(dataObj);
      if (!data || !data.success) {
        let message;
        if (data?.message) {
          message = data.message;
        } else {
          message = 'Something went wrong, please try again';
        }
        if (data?.error) {
          captureInfo({
            log: data.log,
            params: JSON.stringify({
              homeState: pick(homeState, [
                'activeDocumentId',
                'originalDocumentId',
                'activeDocumentMeta',
              ]),
              dataObj,
              data,
              isSelfRemove: false,
              collabState: pick(collabState, ['sharedWith', 'ownerDetails']),
            }),
          });
          captureError(new Error(data.error), true);
        }
        return {success: false, message};
      } else {
        const {sharedWith, isSharedDoc} = data;
        if (isSharedDoc) {
          const sharedWithArr = await collabActionHelper.formatSharedWith(
            sharedWith,
            uid,
            getLocalText(authState.userPref, 'Me'),
            authState.userPref,
            false,
          );
          dispatch({
            type: COLLABORATION_ACTION.UPDATE_SHARED_WITH,
            payload: sharedWithArr,
          });
        } else {
          /*doc is no more a shared file*/
          database()
            .ref(`/collabDocActiveUsers/${homeState.activeDocumentId}/${uid}`)
            .onDisconnect()
            .cancel();
          const updatedMeta = Object.assign({}, homeState.activeDocumentMeta);
          delete updatedMeta.collab;
          dispatch({
            type: HOME_ACTION.UPDATE_DOCUMENT_META,
            payload: updatedMeta,
          });
          const filesArr = homeState.files.slice();
          filesArr.splice(homeState.activeFileIndex, 1, {
            documentId: docId,
            documentMeta: updatedMeta,
          });
          dispatch({
            type: HOME_ACTION.LOAD_FILES,
            payload: {files: filesArr},
          });
          if (ENV) {
            navigation.pop();
          }
          dispatch({
            type: COLLABORATION_ACTION.CLEAR_LAST_COLLAB_STATE,
          });

          // UPDATE META AT WIDGETS
          reloadVisibleFiles(dispatch, getState);
        }
        return {success: true};
      }
    } catch (error) {
      captureError(error);
    }
  };

const addEntryOnlyData =
  (rowData, extra = {}) =>
  async (dispatch, getState) => {
    try {
      const {
        home: homeState,
        table,
        miniApps,
        auth,
        persistedData,
      } = getState();
      const uid = auth.user?.uid;
      if (!uid) {
        return;
      }

      const lastEditedByContact = auth.user.phoneNumber
        ? auth.user.phoneNumber
        : auth.user.email;
      const lastEditedTimestamp = moment.utc().format();
      const docId = extra?.docId ? extra.docId : homeState.activeDocumentId;
      const fileName = extra?.fileName
        ? extra.fileName
        : homeState.activeDocumentMeta.name ?? '';
      const headerData = extra?.headerData
        ? extra.headerData.slice()
        : table.headerData.slice();
      const parentRowMeta = extra?.parentRowMeta ?? {};
      const originalDocumentId = extra?.originalDocumentId
        ? extra.originalDocumentId
        : homeState.originalDocumentId;
      //Set rowProperties and Data Added By
      const updatedRowData = rowData;
      const createdContactName = auth.user?.displayName
        ? auth.user.displayName
        : auth.userPref.name
        ? auth.userPref.name
        : '';
      const restrictionConfig = homeState?.activeDocumentMeta?.restrictionConfig
        ? Object.assign({}, homeState.activeDocumentMeta.restrictionConfig)
        : {};
      const applyDocRestrictions =
        homeState?.activeDocumentMeta?.applyDocRestrictions === true;
      let isFirstDataAdded = false;
      if (isEmpty(updatedRowData?.rowProperties)) {
        isFirstDataAdded = true;
      }
      const userCountry = auth.userCountry ?? 'IN';
      const isRowEdit = Boolean(extra?.isRowEdit);
      const dataObj = {
        docId,
        rowData: updatedRowData,
        headerData,
        fileName,
        callerUID: uid,
        lastEditedByContact,
        lastEditedTimestamp,
        originalDocumentId: extra?.isMiniAppsEntry ? docId : originalDocumentId,
        timezone: getTimezone(),
        addEntryToParentFile: Boolean(extra?.addEntryToParentFile),
        isNewDashboardFlow: true,
        createdContactName,
        restrictionConfig,
        applyDocRestrictions,
        parentRowMeta,
        userCountry,
        addCommentFirst: extra?.addCommentFirst,
        rowId: extra?.rowId,
        ...(extra?.isMiniAppsEntry
          ? {
              isMiniAppsEntry: extra?.isMiniAppsEntry,
              screenId: miniApps?.activeScreenId,
              miniAppId: miniApps?.activeAppId,
              isOwner: extra?.isOwner,
            }
          : {}),
        rowProperties: getNewRowPropertiesObject(
          updatedRowData?.rowProperties,
          isFirstDataAdded,
        ),
        isRowEdit,
        callerDeviceId: persistedData.uniqueDeviceId,
      };
      const data = await collabActionHelper.addEntryOnlyDataFunction(dataObj);
      if (data) {
        if (!dataObj.addEntryToParentFile) {
          // UPDATE Only if success
          dispatch({
            type: TABLE_ACTION.LOAD_ENTRY_ONLY_DATA,
            payload: {
              headerData: data.updatedHeaderData /* headerData changed */
                ? restrictHeaderData(
                    homeState?.activeDocumentMeta,
                    data.updatedHeaderData,
                  )
                : table.headerData,
              originalHeaderData: data.updatedHeaderData,
              tableData:
                !dataObj.addEntryToParentFile &&
                extra?.isRowEdit &&
                data.success
                  ? table.tableData.map((item) => ({
                      ...(item.rowId === extra?.rowId
                        ? data.updatedRowObject
                        : item),
                    }))
                  : table.tableData,
              fileObj: data.fileObj,
            },
          });
        }

        if (data.success) {
          if (dataObj.addEntryToParentFile) {
            dispatch(
              fetchParentFileData({
                documentId: dataObj.docId,
                forceFetch: true,
              }),
            );
          }
          return {success: true};
        } else if (data?.isDocRestrictionLimitExceed) {
          return data;
        } else if (data.unshared) {
          //doc unshared
          const filesArr = homeState.files.slice();
          filesArr.splice(homeState.activeFileIndex, 1);
          dispatch({
            type: HOME_ACTION.LOAD_FILES,
            payload: {files: filesArr},
          });
          reloadVisibleFiles(dispatch, getState);
          return {
            success: false,
            message: 'Your access from this document has been removed.',
            pop: true,
          };
        } else if (data.updatedPermission) {
          //permission changed
          const filesArr = homeState.files.slice();
          const oldFileObj = filesArr[homeState.activeFileIndex];
          filesArr.splice(
            homeState.activeFileIndex,
            1,
            Object.assign(
              {},
              {
                documentId: oldFileObj.documentId,
                documentMeta: Object.assign(
                  {},
                  oldFileObj.documentMeta,
                  {
                    collab: {
                      isOwner: false,
                      permission: data.updatedPermission,
                    },
                  },
                  {activePageId: null},
                ),
              },
            ),
          );
          dispatch({
            type: HOME_ACTION.LOAD_FILES,
            payload: {files: filesArr},
          });
          reloadVisibleFiles(dispatch, getState);
          return {
            success: false,
            message: 'Your permission for this document has been changed.',
            pop: true,
          };
        }
      }
      //failed
      if (data?.error) {
        captureInfo({log: data.log});
        captureError(new Error(data.message ? data.message : data.error), true);
      }
      return {
        success: false,
        message: data?.message ?? 'No Results. Check your Internet connection.',
      };
    } catch (error) {
      captureError(error);
      return {success: false};
    }
  };

const unshareFileWithAllUsers = (docId) => async (_, getState) => {
  //currently handled for the cases when document is not active i.e. outside the table
  try {
    const {
      auth: {userPref},
    } = getState();

    const dataObj = {
      docId,
    };

    const data = await collabActionHelper.unshareWithAllUsersCloud(dataObj);

    if (!data || !data.success) {
      let message;
      if (data?.message) {
        message = data.message;
      } else {
        message = 'Something went wrong, please try again';
      }
      if (data?.error) {
        captureInfo({message, dataObj, log: data.log});
        captureError(new Error(data.error), true);
      }
      message = getLocalText(userPref, message);
      if (ENV && data?.isAlert && typeof alert === 'function') {
        alert(message);
      } else {
        ShowToast(message, null, false, true);
      }
      return {
        success: false,
      };
    } else {
      const message = 'Document unshared successfully.';
      ShowToast(message, null, false, true);
      return {
        success: true,
      };
    }
  } catch (error) {
    captureError(error);
    return {
      success: false,
    };
  }
};

export {
  activateListener,
  addEntryOnlyData,
  changeSharingPermission,
  changeUserPresenceActivity,
  deactivateListener,
  getActiveUsersDetails,
  loadCollabMeta,
  modifyListenersOnPageChange,
  removeCollabUser,
  resetCollabStates,
  shareFileToContacts,
  unshareFileWithAllUsers,
};
