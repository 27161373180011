import moment from 'moment';
import {firestore, captureError} from '../../imports';
import {FETCH_DOCS_LIMIT} from '../../utils/constantActivityLogs';

const getTodaysDate = () =>
  moment
    .utc()
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .format();

const logAccountActivity = ({uid, obj, date}) => {
  try {
    if (!date) {
      return;
    }

    firestore()
      .collection('users')
      .doc(uid)
      .collection('activityLog')
      .doc(date)
      .set({...obj, timestamp: date}, {merge: true});
  } catch (error) {
    captureError(error);
  }
};

const logDocumentActivity = ({docId, obj, date}) => {
  try {
    if (!date) {
      return;
    }

    firestore()
      .collection('documents')
      .doc(docId)
      .collection('activityLog')
      .doc(date)
      .set({...obj, timestamp: date}, {merge: true});
  } catch (error) {
    captureError(error);
  }
};

const getDocRef = (docId, to, from) => {
  if (to && from) {
    return firestore()
      .collection('documents')
      .doc(docId)
      .collection('activityLog')
      .where('timestamp', '>=', from)
      .where('timestamp', '<=', to)
      .orderBy('timestamp', 'desc')
      .limit(FETCH_DOCS_LIMIT);
  } else {
    return firestore()
      .collection('documents')
      .doc(docId)
      .collection('activityLog')
      .orderBy('timestamp', 'desc')
      .limit(FETCH_DOCS_LIMIT);
  }
};

const loadDocumentActivityLogs = ({docId, lastDoc, from, to}) => {
  try {
    return lastDoc
      ? getDocRef(docId, to, from).startAfter(lastDoc).get()
      : getDocRef(docId, to, from).get();
  } catch (error) {
    captureError(error);
  }
};

const getAccountRef = (uid, to, from) => {
  if (to && from) {
    return firestore()
      .collection('users')
      .doc(uid)
      .collection('activityLog')
      .where('timestamp', '>=', from)
      .where('timestamp', '<=', to)
      .orderBy('timestamp', 'desc')
      .limit(FETCH_DOCS_LIMIT);
  } else {
    return firestore()
      .collection('users')
      .doc(uid)
      .collection('activityLog')
      .orderBy('timestamp', 'desc')
      .limit(FETCH_DOCS_LIMIT);
  }
};

const loadAccountActivityLogs = ({uid, lastDoc, to = null, from = null}) => {
  try {
    return lastDoc
      ? getAccountRef(uid, to, from).startAfter(lastDoc).get()
      : getAccountRef(uid, to, from).get();
  } catch (error) {
    captureError(error);
  }
};

const processAndUploadLogs = (logs, isFile, uid) => {
  try {
    if (isFile) {
      for (const [docId, value] of Object.entries(logs)) {
        for (const [date, obj] of Object.entries(value)) {
          logDocumentActivity({
            docId,
            date,
            obj,
          });
        }
      }
    } else if (uid) {
      for (const [key, value] of Object.entries(logs)) {
        logAccountActivity({
          date: key,
          uid,
          obj: value,
        });
      }
    }
  } catch (error) {
    captureError(error);
  }
};

export {
  logAccountActivity,
  logDocumentActivity,
  loadDocumentActivityLogs,
  loadAccountActivityLogs,
  getTodaysDate,
  processAndUploadLogs,
};
