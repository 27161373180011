import moment from 'moment';
import {
  ENV,
  captureError,
  captureInfo,
  initializeIntlPolyfillForMobile,
} from '../imports';
import {FIELD_TYPE_ID} from './constant';
import {checkIfPlainText} from './utils';

const PREVIOUS_VALUE_REF = 'þ';

const solveStringEquation = (mainRequest) => {
  let variableOperandsCount = 0; //count of columns in formula
  let emptyOperandsCount = 0; //count of undefined columns in formula
  let isValidValue = false; //if some columns are defined and have valid values

  const evaluator = (request) => {
    const {
      eqn,
      rowObj,
      colObj,
      isValidate = false,
      isChained = false,
      prevRes = '',
    } = request ?? {};
    try {
      const chainSolver = [];

      const functionStack = [];
      const argumentStack = [];

      let i = 0;
      let lastCharComma = false;
      let isLastCharEscaped = false;

      const getZeroBasedIndex = (index, length) => {
        const number = Number(index);
        return number === 0
          ? 0
          : !isNaN(number) && number > 0
          ? number - 1
          : length + number;
      };

      const functions = {
        CONCAT(...args) {
          if (args.length) {
            return args.join('');
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        SPLIT(...args) {
          if (args.length === 3 || args.length === 2) {
            const [text, delimiter] = args;
            const splittedStr = text.split(delimiter);
            return (
              splittedStr[getZeroBasedIndex(args[2], splittedStr.length)] ?? ''
            );
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        REPLACE(...args) {
          if (args.length === 3) {
            const [text, searchValue, newValue] = args;
            return text.split(searchValue).join(newValue);
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        SUBSTRING(...args) {
          if (args.length === 3 || args.length === 2) {
            const start = getZeroBasedIndex(args[1], args[0].length);
            const length =
              typeof args[2] === 'string' && !isNaN(Number(args[2]))
                ? Number(args[2])
                : undefined;
            return args[0].slice(
              start,
              length >= 0 ? start + length : undefined,
            );
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        UPPER(...args) {
          if (args.length) {
            return args[0].toUpperCase();
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        LOWER(...args) {
          if (args.length) {
            return args[0].toLowerCase();
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        CAPITALIZE(...args) {
          if (args.length) {
            return `${args[0].charAt(0).toUpperCase()}${args[0].slice(1)}`;
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        TRIM(...args) {
          if (args.length) {
            return args[0].trim();
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        LENGTH(...args) {
          if (args.length) {
            return `${args[0].length}`;
          } else if (isValidate) {
            return [false];
          }
          return '0';
        },
        REVERSE(...args) {
          if (args.length) {
            return args[0].split('').reverse().join('');
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        INCLUDES(...args) {
          if (args.length === 4) {
            return args[0].includes(args[1]) ? args[2] : args[3];
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        STARTSWITH(...args) {
          if (args.length === 4) {
            return args[0].startsWith(args[1]) ? args[2] : args[3];
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        ENDSWITH(...args) {
          if (args.length === 4) {
            return args[0].endsWith(args[1]) ? args[2] : args[3];
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        INDEXOF(...args) {
          if (args.length === 2) {
            const index = args[0].indexOf(args[1]);
            return index === -1 ? index : index + 1;
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        FORMAT_CURRENCY(...args) {
          if (
            args.length === 2 &&
            ['INR', 'USD', 'AUD', 'GBP', 'EUR'].includes(args[1])
          ) {
            const value = parseFloat(args[0]);
            if (value != null && !isNaN(value)) {
              return formatIntlCurrency(value, args[1]);
            }
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        FORMAT_DATE(...args) {
          const DEFAULT_FORMAT = 'DD/MM/YYYY';
          if (args.length === 2 && args[1] && args[1] !== '') {
            const dateUnix =
              args[0] === '1' && isValidate ? moment.now() : args[0];
            const date = moment.unix(dateUnix).format(args[1]);
            const isValid = moment(date, args[1], true).isValid();
            return isValid
              ? date
              : moment.unix(dateUnix).format(DEFAULT_FORMAT);
          } else if (isValidate) {
            return [false];
          }
          return '';
        },
        CHAIN(...args) {
          const {
            start: chainArgsStart,
            end: chainArgsEnd,
            functionStackSize,
            argumentStackSize,
          } = chainSolver.pop();
          functionStack.splice(functionStackSize);
          argumentStack.splice(argumentStackSize);

          if (args.length) {
            let start = chainArgsStart;
            let prevResult = '';
            for (
              let chainIndex = start;
              chainIndex <= chainArgsEnd;
              chainIndex++
            ) {
              if (eqn[chainIndex].val === ';' || chainIndex === chainArgsEnd) {
                const newRes = evaluator(
                  Object.assign({}, request, {
                    eqn: eqn.slice(start, chainIndex),
                    isChained: true,
                    isValidate: false,
                    prevRes: prevResult,
                  }),
                );
                if (isValidate && newRes?.[0] === false) {
                  throw new Error(`Invalid chaining/operation`);
                }
                prevResult = newRes;
                start = chainIndex + 1;
              }
            }
            return prevResult;
          }
          return '';
        },
      };

      while (i < eqn.length) {
        let lastCharCommaLocal = false;
        let lastCharEscapedLocal = false;
        const {val, fieldType, colId} = eqn[i];
        const prevVal = eqn[i - 1]?.val;
        const nextVal = eqn[i + 1]?.val;
        if (fieldType === FIELD_TYPE_ID.OPERATION) {
          if (val in functions && nextVal === '(') {
            const isChain = val === 'CHAIN';
            functionStack.push(val);
            argumentStack.push([]);
            i += 2;
            if (isChain) {
              chainSolver.push({
                start: i,
                functionStackSize: functionStack.length - 1,
                argumentStackSize: argumentStack.length - 1,
              });
            }
            lastCharComma = lastCharCommaLocal;
            isLastCharEscaped = lastCharEscapedLocal;
            continue;
          } else {
            if (isValidate) {
              return [false, 'Invalid operation'];
            }
            throw new Error(`Invalid operation: ${val}, ${nextVal}`);
          }
        } else if (fieldType === FIELD_TYPE_ID.NUMBER_CONSTANT) {
          if (val === '\\' ? prevVal === '\\' && !isLastCharEscaped : true) {
            //skip escape character
            if (val === '\\' && prevVal === '\\') {
              lastCharEscapedLocal = true;
            }
            if (val === ')' && prevVal !== '\\') {
              const args = argumentStack.pop();
              if (prevVal === ',' && eqn[i - 2]?.val !== '\\') {
                args.push(''); //for last comma : REPLACE(ColA,,)
              }
              const fn = functionStack.pop();
              const isChain = fn === 'CHAIN';
              if (isChain) {
                chainSolver[chainSolver.length - 1].end = i;
              }
              if (isChained) {
                args.forEach((argVal, argIndex) => {
                  if (argVal === PREVIOUS_VALUE_REF) {
                    args[argIndex] = prevRes ?? '';
                  }
                });
              }
              const result = functions[fn](...args);
              if (argumentStack.length > 0) {
                argumentStack[argumentStack.length - 1].push(result);
              } else {
                argumentStack.push([result]);
              }
            } else {
              if (val === ',' && prevVal !== '\\') {
                if (lastCharComma) {
                  if (argumentStack.length > 0) {
                    argumentStack[argumentStack.length - 1].push('');
                  } else {
                    argumentStack.push(['']);
                  }
                }
                lastCharCommaLocal = true;
              } else {
                if (argumentStack.length > 0) {
                  if (lastCharComma) {
                    argumentStack[argumentStack.length - 1].push(val);
                  } else {
                    if (!argumentStack[argumentStack.length - 1].length) {
                      argumentStack[argumentStack.length - 1].push(['']);
                    }
                    argumentStack[argumentStack.length - 1][
                      argumentStack[argumentStack.length - 1].length - 1
                    ] += val;
                  }
                } else {
                  argumentStack.push([val]);
                }
              }
            }
          } else {
            lastCharCommaLocal = lastCharComma;
          }
        } else {
          variableOperandsCount += 1;
          let cellVal = isValidate ? '1' : rowObj?.[colId]?.val;
          if (cellVal == null || !checkIfPlainText(cellVal) || cellVal === '') {
            emptyOperandsCount += 1;
            cellVal = '';
          } else {
            cellVal = `${cellVal}`;
            isValidValue = true;
          }
          if (argumentStack.length > 0) {
            argumentStack[argumentStack.length - 1].push(cellVal);
          } else {
            argumentStack.push([cellVal]);
          }
        }
        ++i;
        lastCharComma = lastCharCommaLocal;
        isLastCharEscaped = lastCharEscapedLocal;
      }

      if (
        isValidate &&
        (functionStack.length ||
          argumentStack.length > 1 ||
          argumentStack[0]?.length > 1)
      ) {
        return [false];
      }

      if (
        variableOperandsCount > 0 &&
        emptyOperandsCount === variableOperandsCount &&
        !isValidValue
      ) {
        //if all columns undefined
        return '';
      }

      if (!isValidValue && variableOperandsCount === 0) {
        return '';
      }

      return (argumentStack.length > 0 ? argumentStack[0]?.[0] : null) ?? '';
    } catch (error) {
      captureInfo({eqn, rowObj, colObj, isValidate});
      captureError(error);
      if (isValidate) {
        return [false, 'Invalid operation'];
      }
      return '';
    }
  };
  return evaluator(mainRequest);
};

const formatIntlCurrency = (number, currency) => {
  try {
    ENV && initializeIntlPolyfillForMobile?.(currency);
    switch (currency) {
      case 'INR': {
        return new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR',
        }).format(number);
      }
      case 'USD': {
        return new Intl.NumberFormat('es-US', {
          style: 'currency',
          currency: 'USD',
        }).format(number);
      }
      case 'AUD': {
        const val = new Intl.NumberFormat('en-AU', {
          style: 'currency',
          currency: 'AUD',
          currencyDisplay: 'code',
        }).format(number);
        if (val?.startsWith?.('AUD')) {
          return val.replace('AUD', 'AU$');
        }
        return val;
      }
      case 'GBP': {
        return new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: 'GBP',
        }).format(number);
      }
      case 'EUR': {
        return new Intl.NumberFormat('en', {
          style: 'currency',
          currency: 'EUR',
        }).format(number);
      }
    }
  } catch (error) {
    captureInfo({number, currency});
    captureError(error);
  }
  return number;
};

export {solveStringEquation, formatIntlCurrency};
