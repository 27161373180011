import {SEARCH_ACTION} from '../actions/actionType';
import {omit} from 'lodash';

const initialState = {
  isActive: false,
  isLoading: false,
  searchCount: 0,
  files: [],
  foldersParentMap: {},
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_ACTION.SET_ACTIVE_STATE: {
      return {
        ...state,
        isActive: action.payload ? true : false,
      };
    }
    case SEARCH_ACTION.BEGIN_SEARCH: {
      return {
        ...state,
        searchCount: state.searchCount + 1,
        isLoading: true,
      };
    }
    case SEARCH_ACTION.CLEAR_SEARCH: {
      return {
        ...state,
        ...Object.assign(
          {},
          omit(initialState, ['foldersParentMap', 'isActive']),
        ),
      };
    }
    case SEARCH_ACTION.FILE_SEARCH_RESULT: {
      return {
        ...state,
        searchCount: state.searchCount - 1,
        isLoading: state.searchCount - 1 <= 0 ? false : true,
        files: action.payload.searchedFiles,
      };
    }
    case SEARCH_ACTION.SET_FOLDERS_PARENT_MAP: {
      return {
        ...state,
        foldersParentMap: Object.assign({}, action.payload),
      };
    }
    default:
      return state;
  }
};

export default searchReducer;
