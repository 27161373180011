import {functions, captureError} from '../../imports';
import {CLOUD_FUNCTION_PATHS} from '../../utils/constant';
import {callCloudFunction, handleCloudError} from '../../utils/utils';

const addOrUpdateProfileDataHelper = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.MODIFY_OR_CREATE_ORGANIZATION,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (err) {
    captureError(err);
  }
};

const manageOrganisationAppAccessCloudFunction = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.MANAGE_ORGANISATION_APP_ACCESS,
    );
    const response = await functionInstance(obj);
    return response?.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const organisationPlanExpirySendMeALink = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.ORG_PLAN_EXPIRY_SEND_ME_A_LINK,
    );
    const response = await functionInstance(obj);
    return response?.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const updateOrganisationSubscriptionCF = async (payload) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.UPDATE_ORGANISATION_SUBSCRIPTION,
    );
    const response = await functionInstance(payload);
    return response?.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const cancelOrganisationSubscriptionCF = async (payload) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.CANCEL_ORGANISATION_SUBSCRIPTION,
    );
    const response = await functionInstance(payload);
    return response?.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const getAllAppsInOrganisationHelper = async (payload) => {
  try {
    return await callCloudFunction(
      CLOUD_FUNCTION_PATHS.GET_ALL_APPS_IN_ORGANISATION,
      Object.assign({}, payload),
    );
  } catch (error) {
    console.log(error);
  }
};

export {
  addOrUpdateProfileDataHelper,
  manageOrganisationAppAccessCloudFunction,
  organisationPlanExpirySendMeALink,
  updateOrganisationSubscriptionCF,
  cancelOrganisationSubscriptionCF,
  getAllAppsInOrganisationHelper,
};
