import {isEmpty, omitBy, sortBy} from 'lodash';
import {
  getReduxState,
  setReduxState,
  captureError,
  captureInfo,
} from '../imports';
import {checkAndFetchAllRows} from '../actions/tableAction';
import {getLocalText, getFooterVisiblityAndType} from './utils';
import DocumentsMethods from '../FirestoreHandlers/Documents/DocumentsMethods';
import {mapHeaderData, solvePrevRowRefEqnForTable} from './equationHelper';

const getDocumentDataForExport = async (params) => {
  const {selectableShare, selectedRow, selectedCol, docId, ErrorAlert} = params;
  const {
    table,
    searchFilter,
    home: {activeDocumentMeta},
    auth: {userPref},
  } = getReduxState();

  const checkAndFetchAllRowsAction = async () => {
    await setReduxState(checkAndFetchAllRows());
    return getDocumentDataForExport(params);
  };

  let data = {};

  if (selectableShare) {
    if (
      !table.areAllRowsFetched &&
      isEmpty(selectedRow) &&
      !isEmpty(selectedCol)
    ) {
      return checkAndFetchAllRowsAction();
    }
    data = processSelectedData(
      selectedRow,
      selectedCol,
      table,
      activeDocumentMeta,
      searchFilter,
    );
  } else if (docId) {
    const isBlockViewActive = activeDocumentMeta.viewType === 'block';
    data = await FetchAndGetDocData(
      docId,
      activeDocumentMeta.name ?? '',
      isBlockViewActive,
      activeDocumentMeta,
      userPref,
      ErrorAlert,
    );
  } else {
    if (!table.areAllRowsFetched && !searchFilter.isActive) {
      return checkAndFetchAllRowsAction();
    }
    data = GetDefaultFileDataObj(
      table,
      searchFilter,
      activeDocumentMeta,
      selectedCol,
    );
  }
  if (!isEmpty(data)) {
    if (data.footerData) {
      data.footerData = omitBy(
        Object.assign({}, data.footerData),
        (footerObj) => {
          const {isTotalVisible} = getFooterVisiblityAndType(
            {id: footerObj},
            'id',
          );
          return !isTotalVisible;
        },
      ); //show only those footer which have isVisible value undefined or true (as per new footer structure)
    }
    if (data.tableData?.length && data.headerData?.length) {
      const {prevRowRefEqnArr} = mapHeaderData(data.headerData);
      data.tableData = solvePrevRowRefEqnForTable(
        data.tableData,
        prevRowRefEqnArr,
      );
    }
  }
  return data;
};

const downloadPagesAndGetFileObj = async (
  docIdArrays,
  userPref,
  setLoaderText = () => {},
  ErrorAlert,
) => {
  try {
    const docsData = [];
    const monthsOrder = [];
    let pageIndex = 0;
    for (const docObj of docIdArrays) {
      setLoaderText(
        `${getLocalText(userPref, 'Downloading Page')} ( ${++pageIndex} / ${
          docIdArrays.length
        } )`,
      );
      //inside for ... of loop (valid) [Sequential promise]
      // eslint-disable-next-line no-await-in-loop
      const docData = await DocumentsMethods.getUserDocumentData(docObj.id);
      if (!isEmpty(docData)) {
        const name = docObj.name ?? '';
        const obj = {
          ...docData,
          name,
        };
        monthsOrder.push(name);
        docsData.push(obj);
      }
    }
    return sortBy(docsData, (item) => monthsOrder.indexOf(item.name));
  } catch (error) {
    captureError(error);
    ErrorAlert(
      'Unable to generate file, please try again later.',
      null,
      userPref,
    );
  }
};

const GetDefaultFileDataObj = (
  table,
  searchOrFilter,
  activeDocumentMeta,
  selectedColumn = {},
) => {
  const isSearchOrFilterActive = Boolean(searchOrFilter.isActive);
  const headerData = isEmpty(selectedColumn)
    ? table.headerData
    : table.headerData.filter((obj) => obj.id in selectedColumn);
  const tableData = isSearchOrFilterActive
    ? searchOrFilter.searchFilterTableData.map((rowId) =>
        Object.assign({}, searchOrFilter.searchFilterRowIdDataMap[rowId]),
      )
    : table.tableData;
  const footerData = isSearchOrFilterActive
    ? searchOrFilter.searchFilterFooterData
    : table.footerData;
  const filename = activeDocumentMeta.name;
  const isBlockViewActive =
    activeDocumentMeta.viewType === 'table' ? false : true;
  const metaData = activeDocumentMeta;
  return {
    headerData,
    tableData,
    footerData,
    filename,
    isBlockViewActive,
    metaData,
    fileObj: Object.assign({}, table.fileObj),
  };
};

const FetchAndGetDocData = async (
  docId,
  filename,
  isBlockViewActive,
  metaData,
  userPref,
  ErrorAlert,
) => {
  try {
    const data = await DocumentsMethods.getUserDocumentData(docId);
    if (!isEmpty(data)) {
      return {
        ...data,
        filename,
        isBlockViewActive,
        metaData,
      };
    }
    captureInfo({docId, filename, isBlockViewActive});
    throw new Error('trying to share doc which does not exisit in document');
  } catch (error) {
    captureError(error);
    ErrorAlert(
      'Unable to generate file, please try again later.',
      null,
      userPref,
    );
    return Promise.resolve();
  }
};

const processSelectedData = (
  row,
  col,
  table,
  activeDocumentMeta,
  searchFilter,
) => {
  //returns processed selected data (for selectable row-columns)
  try {
    const isSearchFilterActive = searchFilter?.isActive;
    const tableDataMain = isSearchFilterActive
      ? searchFilter.searchFilterTableData.map((rowId) =>
          Object.assign({}, searchFilter.searchFilterRowIdDataMap[rowId]),
        )
      : table.tableData;
    const footerDataMain = isSearchFilterActive
      ? searchFilter.searchFilterFooterData
      : table.footerData;

    const rowIndexes = Object.keys(Object.assign({}, row));
    const areAllRowsIncluded =
      rowIndexes.length === 0 || rowIndexes.length === tableDataMain.length;
    const headerData = isEmpty(col)
      ? table.headerData.slice()
      : table.headerData.filter((element) => col[element.id] === '');
    const tableData = areAllRowsIncluded
      ? tableDataMain.slice()
      : tableDataMain.filter((_, index) => row[index]);
    let footerData = {};
    const originalFooter = Object.assign({}, footerDataMain);
    if (areAllRowsIncluded) {
      footerData = Object.assign({}, originalFooter);
    } else if (!isEmpty(originalFooter)) {
      headerData.forEach(({id}) => {
        const {isTotalVisible} = getFooterVisiblityAndType(
          {[id]: originalFooter[id]},
          id,
        );
        if (isTotalVisible) {
          const {getTotalObject, calculateTotal} = require('./equationHelper');
          try {
            footerData[id] = getTotalObject(
              originalFooter[id],
              calculateTotal(tableData, id, headerData),
            );
          } catch {}
        }
      });
    }
    const filename = !isEmpty(activeDocumentMeta)
      ? activeDocumentMeta.name || ''
      : '';
    const isBlockViewActive =
      !isEmpty(activeDocumentMeta) && activeDocumentMeta.viewType === 'table'
        ? false
        : true;
    return {
      headerData,
      tableData,
      footerData,
      filename,
      isBlockViewActive,
      metaData: activeDocumentMeta,
    };
  } catch (error) {
    captureError(error);
  }
};

export {
  getDocumentDataForExport,
  downloadPagesAndGetFileObj,
  processSelectedData,
};
