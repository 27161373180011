import {PREMIUM_REDUCER} from '../actions/actionType';

const initialState = {
  isUserSubscribed: false, //is user subscribed to a "paid" package
  isTrial: false, //is user subscribed to a trial/granted-entitlement package
  isEligibleForTrial: false, //can user get some trial/granted-entitlement
  isSharedUserPlan: false, // is a plan shared with the user
  willRenew: false, //is recurring payment
  subscriptions: [], //array of current entitlements
  subscriptionObj: {}, //actual subscription object (PurchaseInfo Obj)
  offerings: {},
  offeringLocalisation: {},
  featuresList: [],
  activePlanType: '',
  isMonthly: true,
  lastActivePlan: null, // one of [null, LAST_ACTIVE_PLAN.FREE, LAST_ACTIVE_PLAN.PAID] (premium.js)
  planSharedWith: [{name: 'Me', isOwn: true}], //array of users with whom have shared the plan
  isPlanSharedWithLoading: false,
  activeProductIdentifier: null,
  planShareMemberCount: 0,
};

const premiumReducer = (state = initialState, action) => {
  switch (action.type) {
    case PREMIUM_REDUCER.LOAD_SUBSCRIPTIONS: {
      return {
        ...state,
        isUserSubscribed: action.payload.isUserSubscribed ? true : false,
        isTrial: action.payload.isTrial ? true : false,
        isEligibleForTrial: action.payload.isEligibleForTrial ? true : false,
        willRenew: action.payload.willRenew ? true : false,
        isSharedUserPlan: action.payload.isSharedUserPlan ? true : false,
        subscriptions: action.payload.subscriptions || [],
        activePlanType: action.payload.activePlanType || '',
        isMonthly: action.payload.isMonthly ? true : false,
        subscriptionObj: action.payload.subscriptionObj || {},
        lastActivePlan: action.payload.lastActivePlan || null,
        activeProductIdentifier: action.payload.activeProductIdentifier || null,
        planShareMemberCount: action.payload.planShareMemberCount ?? 0,
      };
    }
    case PREMIUM_REDUCER.LOAD_OFFERINGS: {
      return {
        ...state,
        offerings: action.payload,
      };
    }
    case PREMIUM_REDUCER.LOAD_OFFERINGS_LOCALISATION: {
      return {
        ...state,
        offeringLocalisation: action.payload,
      };
    }
    case PREMIUM_REDUCER.LOAD_FEATURES_LIST: {
      return {
        ...state,
        featuresList: action.payload ?? [],
      };
    }
    case PREMIUM_REDUCER.UPDATE_PLAN_SHARED_WITH_ARRAY: {
      return {
        ...state,
        planSharedWith: action.payload ?? [],
        isPlanSharedWithLoading: false,
      };
    }
    case PREMIUM_REDUCER.START_PLAN_SHARED_WITH_LOADING: {
      return {
        ...state,
        isPlanSharedWithLoading: true,
      };
    }
    case PREMIUM_REDUCER.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    case PREMIUM_REDUCER.SET_SUBSCRIPTIONS: {
      return {
        ...initialState,
        subscriptions: [...action.payload],
      };
    }
    default: {
      return state;
    }
  }
};
export default premiumReducer;
