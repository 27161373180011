export const CONTAINER_MARGIN = 20;

export const FONTSIZE = {
  BIG_HEADING: 22,
  TEXT: 16,
  SUBTEXT: 12,
  SUBTEXT_LARGE: 13,
  BUTTON: 14,
  SMALL_HEADING: 20,
  TEXT_SMALL: 14,
  SUBTEXT_SMALL: 11,
  BIG_TEXT: 18,
  EXTRA_SMALL: 10,
  SUBTEXT_EXTRA_SMALL: 8,
};

export const COLOR = {
  ACTION: '#3C3C55', //'#e76f51', //'#2A9D8F',#457B9D
  HEADER: '#FFF',
  BACKGROUND: '#FFFFFF', //'#7da7c1',356488
  TEXT_DARK: '#0a0f13',
  TEXT_SECONDARY: '#60676c', //'#939a9f',
  TEXT_THIRD: '#c6cdd2',
  TEXT_BUTTON: '#FFFFFF',
  TEXT_COLOR: '#508991',
  BORDER: '#e0e7ec',
  BORDER_LIGHT: 'rgba(224, 231, 236, 0.65)',
  BORDER_DARK: '#C7CED3',
  BORDER_GREEN: '#E3E3E3',
  BORDER_RGB: 'rgb(224,231,236)',
  BORDER_DARK_RGB: 'rgb(199,206,211)',
  ERROR_MESSAGE: '#FB3640',
  SECONDARY_HEADER: '#e0e7ec',
  MODAL_VIEW: '#FFFFFF',
  MODAL_BAR: '#ADB4B9',
  BACKGROUND_LIGHT: 'rgba(210,215,223,0.15)',
  BORDER_COLOR: 'rgba(231, 111, 81,0.3)',
  ONLINE: '#2B9B8F',
  OFFLINE: '#FF0000',
  DELETE: '#e63946',
  SWITCH_THUMB: '#f4f3f4',
  SWITCH_BACKGROUND: '#e63946', //only for ios
  POSITIVE: '#2a9d8f',
  PAGE_TYPE_TEXT: '#858585',
  LIGHT_TEXT: '#BFBFBF',
  DARK_TEXT: '#B1B1B1',
  SELECT_COLOR: '#add8e6CC',
  BUTTON_SECONDARY: '#3B3B59',
  TEXT_BLACK: '#262626',
  BUTTON_BORDER_DEFAULT: '#BFCCE0',
  BUTTON_BACKGROUND_PRIMARY: '#337EED',
  BUTTON_BACKGROUND_PRIMARY_OPACITY: '#EBF2FD',
  TEXT_BLACK_LIGHT: '#556A89',
  TEXT_BROWN: '#C17A10',
  BUTTON: '#1990E0',
  DARK_SCREEN_BACKGROUND: '#F4F4F4',
  SUB_HEADING: '#7E7E7E',
  SEPARATOR: '#F0F0F0',
  INPUT_BOX: '#F9F9F9',
  FOLDER_ICON: '#FFC10A',
  WHITE: '#fff',
  BLACK: '#000',
  OFF_BLACK: 'rgba(0,0,0,0.4)',
  HIGHLIGHTED_TEXT: '#008B48',
  BUTTON_TEXT_COLOR: '#5F5F5F',
  LIGHTNING_ICON: '#FCD12A',
  OFFER_TEXT: '#7E99C1',
  DEFAULT_CIRCULAR_PROGRESS_TRACK_COLOR: '#E0E7ED',
  PURE_BLUE: '#000AFF',
  PURE_RED: '#EB3223',
  ALERT_BACKGROUND: '#FEF6EA',
  WARNING_YELLOW: '#F1A42F',
  LITE_GREEN: '#ECF5EB',
  GREEN: '#449F35',
  LITE_RED: '#FCEAEA',
  DARK_BLUE: '#0B4BAA',
};

export const MARGIN = {
  SMALL: 5,
  MEDIUM: 10,
  LARGE: 15,
  LITTLE_LARGE: 20,
  EXTRA_LARGE: 25,
};

export const BORDER = {
  RADIUS_SMALL: 4,
  RADIUS_MEDIUM: 8,
  RADIUS_LARGE: 16,
  WIDTH: 1,
};
