import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: 'white',
    color: theme.palette.textNav,
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.palette.contextMenuShadow,
    padding: 0,
  },
  items: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.navHover,
    },
    '&>.contextMenuText': {
      minWidth: '100px',
      flexGrow: 1,
      whiteSpace: 'nowrap',
      textTransform: 'capitalize',
      margin: '0 8px',
      textAlign: 'left',
      color: theme.palette.textDark,
    },
    '& svg': {
      color: theme.palette.iconLight,
    },
  },
  overlay: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
  },
}));

export default useStyles;
