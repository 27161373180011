import React, {useEffect} from 'react';
import {Avatar, ButtonBase} from '@material-ui/core';
import TextComponent from '../../../textComponent/TextComponent';
import LioLogo from '../../../../assets/img/lioLogo.svg';
import {useSelector} from 'react-redux';
import useStyles from '../menuComponentStyles';
import {isEmpty} from 'lodash';
import {useHistory} from 'react-router';
import {ORGANISATION_ACCESS_LEVEL} from 'rb-redux/utils/constant';

export const ProfileMenuHeader = () => {
  const history = useHistory();
  const classes = useStyles();
  const {photoURL, displayName, email, phoneNumber} = useSelector(
    (state) => state.auth.user,
  );
  // const {organisationIds} = useSelector((state) => state.auth);
  const {
    auth: {
      user: {uid},
    },
    organisation,
    organisation: {isUserOrganisationOwner, membersList},
    remoteConfig: {isOrganisationMode},
  } = useSelector((state) => state);
  const USER_ORGANISATION_AND_PROFILE_LIST = [
    {
      profileIcon: photoURL,
      text: displayName || email || phoneNumber,
      subText: email || phoneNumber,
      // allowNonOrgModeOnly: true,
      userProfile: true,
    },
    {
      profileIcon: organisation?.profileData?.orgLogo,
      text: organisation?.profileData?.orgName || '',
      subText: organisation?.profileData?.orgDomain,
      allowOrgModeOnly: true,
    },
  ];
  const handleProfileClick = (gotoUserProfile = false) => {
    if (isOrganisationMode) {
      if (gotoUserProfile) {
        history.push('/profile');
      } else {
        if (
          isUserOrganisationOwner ||
          membersList[uid]?.access_level?.includes(
            ORGANISATION_ACCESS_LEVEL.ADMIN,
          )
        ) {
          history.push('/profile?menu=ORGANISATION');
        }
      }
    } else if (!isOrganisationMode) {
      history.push('/profile');
    }
  };
  return USER_ORGANISATION_AND_PROFILE_LIST.filter((profileItem) => {
    if (profileItem?.allowOrgModeOnly) {
      if (isOrganisationMode) return true;
      return false;
    } else if (profileItem?.allowNonOrgModeOnly) {
      if (!isOrganisationMode) return true;
      return false;
    }
    return true;
  }).map((profile, index) => {
    if (profile.text == '') {
      return;
    }
    console.log(profile);
    return (
      <>
        <div
          className={classes.container}
          onClick={() => handleProfileClick(profile.userProfile)}>
          <Avatar
            alt={displayName}
            src={profile.profileIcon}
            className={classes.avatar}
          />
          <div className={classes.details}>
            <TextComponent text={profile.text} subText={profile.subText} />
          </div>
        </div>
        <div className={classes.bottomBorder}></div>
      </>
    );
  });
};
