import {PUSH_NOTIFICATIONS_ACTION} from '../actions/actionType';
import PushNotificationArray from '../utils/CustomClass/PushNotificationArray';

const initialState = {
  notifications: [],
  lastVisibleTimestamp: null,
  allFetched: false,
  lastNotificationFetched: null,
  localFetched: null,
  customCallbacks: {},
};

const pushNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUSH_NOTIFICATIONS_ACTION.UPDATE_NOTIFICATIONS_LIST: {
      const {localFetched, lastVisibleTimestamp, notifications} =
        action?.payload ?? {};
      return {
        ...state,
        notifications: notifications ?? new PushNotificationArray([]),
        ...(localFetched ? {localFetched} : {}),
        ...(lastVisibleTimestamp ? {lastVisibleTimestamp} : {}),
        allFetched: action.payload?.allFetched,
      };
    }
    case PUSH_NOTIFICATIONS_ACTION.SET_NOTFICATION_LAST_FETCHED_TIME: {
      return {
        ...state,
        lastNotificationFetched: action.payload,
      };
    }

    case PUSH_NOTIFICATIONS_ACTION.UPDATE_CUSTOM_CALLBACKS: {
      return {
        ...state,
        customCallbacks: Object.assign(
          {},
          state.customCallbacks,
          action.payload,
        ),
      };
    }

    case PUSH_NOTIFICATIONS_ACTION.RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default pushNotificationsReducer;
