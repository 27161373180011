import {PERSISTED_DATA_ACTION, PUSH_NOTIFICATIONS_ACTION} from './actionType';
import {captureError, ENV} from '../imports';
import {
  getTodaysDate,
  processAndUploadLogs,
} from './actionHelpers/activityLogHelper';
import {BATCH_UPLOAD_LIMIT} from '../utils/constantActivityLogs';
import {isEmpty} from 'lodash';

const saveDocActivityLogsToUpload =
  (obj, forceSet = false) =>
  (dispatch, getState) => {
    try {
      const logs = Object.assign(
        {},
        getState().persistedData.documentActivityLog,
      );
      const logCount = getState().persistedData.documentLogCount + 1;
      const date = getTodaysDate();
      if (!isEmpty(obj)) {
        logs[obj.docId] = {
          ...(logs[obj.docId] ? logs[obj.docId] : {}),
          [date]: {
            ...(logs[obj.docId]?.[date] ? logs[obj.docId][date] : {}),
            [obj['obj'].timestamp]: obj.obj,
          },
        };
      }

      if (logCount >= BATCH_UPLOAD_LIMIT || forceSet) {
        processAndUploadLogs(logs, true);
        dispatch({
          type: PERSISTED_DATA_ACTION.RESET_DOC_ACTIVITY_LOG,
        });
      } else {
        dispatch({
          type: PERSISTED_DATA_ACTION.SET_DOC_ACTIVITY_LOG,
          payload: logs,
        });
      }
    } catch (error) {
      captureError(error);
    }
  };

const saveAccountActivityLogsToUpload =
  (obj, forceSet = false) =>
  (dispatch, getState) => {
    try {
      const uid = getState().auth.user?.uid;
      const logs = Object.assign(
        {},
        getState().persistedData.accountActivityLog,
      );
      const logCount = getState().persistedData.accountLogCount + 1;
      const date = getTodaysDate();
      if (!isEmpty(obj)) {
        logs[date] = {
          ...(logs[date] ? logs[date] : {}),
          [obj['obj'].timestamp]: obj.obj,
        };
      }

      if (logCount >= BATCH_UPLOAD_LIMIT || forceSet) {
        processAndUploadLogs(logs, false, uid);
        dispatch({
          type: PERSISTED_DATA_ACTION.RESET_ACCOUNT_ACTIVITY_LOG,
        });
      } else {
        dispatch({
          type: PERSISTED_DATA_ACTION.SET_ACCOUNT_ACTIVITY_LOG,
          payload: logs,
        });
      }
    } catch (error) {
      captureError(error);
    }
  };

const uploadLogsCall = () => (dispatch) => {
  try {
    dispatch(saveDocActivityLogsToUpload({}, true));
    dispatch(saveAccountActivityLogsToUpload({}, true));
  } catch (error) {
    captureError(error);
  }
};

const getAndSetUniqueDeviceId =
  (deviceId = '') =>
  async (dispatch) => {
    try {
      let uniqueId = '';
      if (ENV) {
        //mobile
        const {getUniqueId, getDeviceId, getModel, getSystemVersion} =
          require('../imports').DeviceInfoModule();
        try {
          uniqueId = await getUniqueId();
        } catch (err) {
          captureError(err);
        }
        if (!uniqueId?.length) {
          const deviceInfoArr = [getModel(), getDeviceId(), getSystemVersion()];
          uniqueId = deviceInfoArr.join('^');
        }
      } else {
        // uniqueId = `${moment().unix()}`;
        uniqueId = deviceId;
        //web
      }
      if (uniqueId?.length) {
        dispatch({
          type: PERSISTED_DATA_ACTION.SET_UNIQUE_DEVICE_ID,
          payload: uniqueId,
        });
      }
    } catch (error) {
      captureError(error);
    }
  };

const updateCustomCallbacks = (customCallBack) => async (dispatch) => {
  try {
    dispatch({
      type: PUSH_NOTIFICATIONS_ACTION.UPDATE_CUSTOM_CALLBACKS,
      payload: customCallBack,
    });
  } catch (err) {
    captureError(err);
  }
};

export {
  saveDocActivityLogsToUpload,
  saveAccountActivityLogsToUpload,
  uploadLogsCall,
  getAndSetUniqueDeviceId,
  updateCustomCallbacks,
};
