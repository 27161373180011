import {ROW_HEIGHT_ACTION} from '../actions/actionType';

const initialState = {
  tableRowHeights: {header: null, footer: null, firstRowHeight: null},
};

const rowHeightReducer = (state = initialState, action) => {
  switch (action.type) {
    case ROW_HEIGHT_ACTION.SAVE_TABLE_ROW__HEIGHT: {
      return {
        tableRowHeights: action.payload,
      };
    }
    case ROW_HEIGHT_ACTION.RESET_HEIGHT_VALUES: {
      return initialState;
    }
    default:
      return state;
  }
};
export default rowHeightReducer;
