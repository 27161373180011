import isEmpty from 'lodash/isEmpty';
import {routes} from '../routes/routes';

export const checkURLRestrictionsAndRedirect = ({
  currentRoute,
  isOrganisationMode,
  isOnboardingPending,
  isUserOrganisationOwner,
  query,
  isFreePlan,
  isAccountActivated,
  isUserAuthenticated,
}) => {
  const defaultRedirectRoute = !isUserAuthenticated
    ? routes.SIGNUP
    : isOrganisationMode
    ? isOnboardingPending
      ? routes.ONBOARDING
      : routes.APPS
    : isOnboardingPending
    ? routes.ONBOARDING
    : routes.DOCUMENT;
  // + (query.get('app')?.length ? '?' + query.toString() : '');

  const defaultReturnObj = {redirect: true, path: defaultRedirectRoute};

  if (
    isEmpty(currentRoute) ||
    (isOnboardingPending && ![routes.ONBOARDING].includes(currentRoute)) ||
    (!isOnboardingPending && [routes.ONBOARDING].includes(currentRoute))
  ) {
    return defaultReturnObj;
  }

  if (
    !isOrganisationMode &&
    //TODO: remove this condition once onboarding is shown on prod too. This check is to show it on dev
    // !(
    //   [routes.ONBOARDING].includes(currentRoute) &&
    //   process.env.REACT_APP_IS_DEV === 'true'
    // ) &&
    ([routes.APPS, routes.APP, routes.LIOSTORE, routes.LIOSTORE_APP].includes(
      currentRoute,
    ) ||
      (currentRoute === routes.PROFILE && !isEmpty(query?.toString())))
  ) {
    return defaultReturnObj;
  } else if (isOrganisationMode) {
    if (isOnboardingPending) {
      if (![routes.LANGUAGE, routes.ONBOARDING].includes(currentRoute)) {
        return defaultReturnObj;
      }
    } else {
      if (
        [
          routes.DASHBOARDS,
          routes.TEMPLATE,
          routes.ONBOARDING,
          routes.MY_TASKS,
        ].includes(currentRoute) ||
        // (currentRoute === routes.PROFILE && //!check not needed since redirection not needed for profile route
        //   (isEmpty(query?.toString()))
        //   && !isUserOrganisationOwner
        //   ) ||
        (isFreePlan &&
          !isUserOrganisationOwner &&
          [routes.DOCUMENT].includes(currentRoute))
      ) {
        return defaultReturnObj;
      } else if (
        isAccountActivated === false &&
        [
          routes.DOCUMENT,
          routes.PROFILE,
          routes.APP,
          routes.LIOSTORE,
          routes.LIOSTORE_APP,
        ].includes(currentRoute)
      ) {
        return defaultReturnObj;
      }
    }
  }

  return {redirect: false, path: currentRoute};
};
