import {isEmpty, omit} from 'lodash';
import {MINI_APPS_ACTION} from '../actions/actionType';

const initialState = {
  processes: {},
  progressNotifications: {},
};

const runningProcessesReducer = (state = initialState, action) => {
  switch (action.type) {
    case MINI_APPS_ACTION.OPEN_APP: {
      return initialState;
    }

    case MINI_APPS_ACTION.UPDATE_PROGRESS_NOTIFICATIONS: {
      const updated = action.payload?.removeAll
        ? {}
        : omit(
            Object.assign(state.progressNotifications, action.payload.added),
            action.payload.removed,
          );
      return {
        ...state,
        progressNotifications: updated,
      };
    }

    case MINI_APPS_ACTION.UPDATE_RUNNING_PROCESSES: {
      const nestedUpdates3rdLevel = (
        toUpdateObj,
        initialObj,
        appendObj,
        key,
      ) => {
        if (!isEmpty(appendObj?.[key])) {
          if (isEmpty(initialObj?.[key])) {
            toUpdateObj[key] = appendObj?.[key];
          }
          if (!isEmpty(initialObj?.[key])) {
            toUpdateObj[key] = toUpdateObj[key] ?? {};

            Object.keys(initialObj?.[key]).forEach((nestedKey) => {
              toUpdateObj[key][nestedKey] = {
                ...(initialObj?.[key]?.[nestedKey] ?? {}),
                ...(appendObj?.[key]?.[nestedKey] ?? {}),
              };
            });
            Object.keys(appendObj?.[key]).forEach((nestedKey) => {
              toUpdateObj[key][nestedKey] = {
                ...(initialObj?.[key]?.[nestedKey] ?? {}),
                ...(appendObj?.[key]?.[nestedKey] ?? {}),
              };
            });
          }
        }
        return toUpdateObj;
      };

      return {
        ...state,
        processes:
          action.payload.isMultiRemove && Array.isArray(action.payload.request)
            ? omit(state.processes, action.payload.request)
            : action.payload.isRemove &&
              typeof action.payload.request === 'string'
            ? omit(state.processes, [action.payload.request])
            : {
                ...nestedUpdates3rdLevel(
                  {},
                  state.processes,
                  action.payload.request,
                  'requestedForDoc',
                ),
                ...nestedUpdates3rdLevel(
                  {},
                  state.processes,
                  action.payload.request,
                  'requestedForScreen',
                ),
              },
      };
    }
    default: {
      return state;
    }
  }
};
export default runningProcessesReducer;
