import {ShowToast, captureError} from '../imports';
import {AGGREGATION_ACTION} from './actionType';
import FirestoreDB from '../FirestoreHandlers/FirestoreDB';
import {
  callCloudFunction,
  getTimezone,
  handleCloudErrorMsgAndLogging,
} from '../utils/utils';
import {CLOUD_FUNCTION_PATHS} from '../utils/constant';
import {getOrganisationUserName} from '../utils/organistionUtils';
import {generateRandomId} from '../utils/RandomGenerator';
import moment from 'moment';
import {processFetchedDashboardConfig} from './actionHelpers/dashboardActionHelper';

const createEditAggregation =
  ({aggregationConfig, aggregationId, isDelete = false}) =>
  async (dispatch, getState) => {
    try {
      const {
        miniApps: {activeAppId},
        auth: {userPref, user},
        organisation: {profileData, membersList},
      } = getState();

      if (!activeAppId || !user?.uid || (isDelete && !aggregationId)) {
        return null;
      }

      const isEdit = !isDelete && aggregationId != null;

      if (!isEdit && !isDelete) {
        aggregationId = generateRandomId(10, `aggs_${user.uid}`);
      }

      const dataObj = {
        aggregationId,
        isDelete,
        ...(isDelete
          ? {}
          : {
              isEdit,
              miniAppId: activeAppId,
              aggregationConfig: Object.assign({}, aggregationConfig, {
                createdBy: {
                  uid: user.uid,
                  timestamp: moment.utc().format(),
                },
                userTimezone: userPref?.timezone ?? getTimezone(),
              }),
              userName:
                getOrganisationUserName(
                  user.uid,
                  profileData?.ownerUID,
                  profileData?.owner,
                  membersList,
                ) || 'USER',
            }),
      };

      const data = await callCloudFunction(
        CLOUD_FUNCTION_PATHS.CREATE_EDIT_AGGREGATION_FOR_MINI_APP,
        dataObj,
      );

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return null;
      }

      //success
      let msg;
      let updatedAggregationList =
        getState().aggregation.aggregationsList ?? [];
      if (isDelete) {
        updatedAggregationList = updatedAggregationList.filter(
          (aggregationMeta) => aggregationMeta.aggregationId !== aggregationId,
        );
        msg = 'Aggregation deleted successfully';
      } else if (isEdit) {
        updatedAggregationList = updatedAggregationList.map((aggregationMeta) =>
          aggregationMeta.aggregationId === aggregationId
            ? {
                aggregationId: aggregationId,
                ...data.aggregationConfig,
                aggregationConfig: processFetchedDashboardConfig(
                  data.aggregationConfig.aggregationConfig,
                ),
              }
            : aggregationMeta,
        );
        msg = 'Aggregation updated successfully';
      } else {
        updatedAggregationList = [
          {
            aggregationId,
            ...data.aggregationConfig,
            aggregationConfig: processFetchedDashboardConfig(
              data.aggregationConfig.aggregationConfig,
            ),
          },
          ...updatedAggregationList,
        ];
        msg = 'Aggregation created successfully';
      }
      dispatch({
        type: AGGREGATION_ACTION.LOAD_AGGREGATIONS,
        payload: updatedAggregationList,
      });

      ShowToast(msg);

      return true;
    } catch (error) {
      ShowToast('Something went wrong, please try again');
      captureError(error);
    }
    return null;
  };

const fetchAggregationsForMiniApp = () => async (dispatch, getState) => {
  try {
    const {
      miniApps: {activeAppId},
    } = getState();

    if (!activeAppId) {
      return false;
    }

    const querySnap = await FirestoreDB.aggregations
      .miniAppAggregations(activeAppId)
      .get();

    const aggregationList = [];
    querySnap.docs.forEach((docSnap) => {
      const docData = docSnap.data();
      aggregationList.push(
        Object.assign({}, docData, {
          aggregationConfig: processFetchedDashboardConfig(
            docData.aggregationConfig,
          ),
          aggregationId: docSnap.id,
        }),
      );
    });
    dispatch({
      type: AGGREGATION_ACTION.LOAD_AGGREGATIONS,
      payload: aggregationList,
    });
    return true;
  } catch (error) {
    captureError(error);
    return false;
  }
};

export {createEditAggregation, fetchAggregationsForMiniApp};
