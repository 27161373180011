import moment from 'moment';
import {captureError} from '../imports';
import {MINI_APPS} from './constant';
import {getFirebaseTimestampAsDateObj} from './utils';

export const SUBSCRIPTION_STATUS = {
  //https://www.chargebee.com/docs/2.0/subscriptions.html#subscription-status
  ACTIVE: 'active',
  TRIAL: 'in_trial',
  CANCELLED: 'cancelled',
};

export const SUBSCRIPTION_PLAN = {
  PRO: 'PRO',
  PLUS: 'PLUS', // won't be available for purchase
  TRIAL: 'TRIAL',
};

export const ORGANISATION_ROLES = {
  DEFAULT_ROLE: 'MEMBER',
  OWNER_ROLE: 'OWNER',
};

export const ORGANISATION_MINI_APP_PERMISSION = {
  PLUS_PLAN: {
    OWNER: MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.EDIT,
    MEMBER: MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.EDIT,
  },
};

const ORG_SUBSCRIPTION_BILLING_CYCLE_PROD = {
  MONTHLY: 'MONTH',
  YEARLY: 'YEAR',
  // DAY: 'DAY', // for testing purposes only
};
const ORG_SUBSCRIPTION_BILLING_CYCLE_DEV = {
  MONTHLY: 'MONTH', //'MONTHLY',
  YEARLY: 'YEAR', //'YEARLY',
  // DAY: 'DAY', // for testing purposes only
};

export const getBillingCycles = (isProduction = false) => {
  return isProduction
    ? ORG_SUBSCRIPTION_BILLING_CYCLE_PROD
    : ORG_SUBSCRIPTION_BILLING_CYCLE_DEV;
};

export const getBillingCycleMapping = (isProduction = false) => {
  const ORG_SUBSCRIPTION_BILLING_CYCLE = isProduction
    ? ORG_SUBSCRIPTION_BILLING_CYCLE_PROD
    : ORG_SUBSCRIPTION_BILLING_CYCLE_DEV;

  return {
    // [ORG_SUBSCRIPTION_BILLING_CYCLE.DAY]: {
    //   text: 'Daily',
    // }, // for testing purposes only
    [ORG_SUBSCRIPTION_BILLING_CYCLE.MONTHLY]: {
      text: 'Monthly',
    },
    [ORG_SUBSCRIPTION_BILLING_CYCLE.YEARLY]: {
      text: 'Yearly',
    },
  };
};

// ********************* Plan ID Mapping *******************
const PLANID_MAPPING_DEV = {
  [SUBSCRIPTION_PLAN.PRO]: {
    [ORG_SUBSCRIPTION_BILLING_CYCLE_DEV.MONTHLY]: {
      // IN: 'Pro-INR-Daily',
      // US: 'Pro-USD-Daily',
      IN: 'pro-INR-Monthly',
      US: 'pro-USD-Monthly',
    },
    [ORG_SUBSCRIPTION_BILLING_CYCLE_DEV.YEARLY]: {
      // IN: 'Pro-INR-Daily',
      // US: 'Pro-USD-Daily',
      IN: 'pro-INR-Yearly',
      US: 'pro-USD-Yearly',
    },
  },
};
const PLANID_MAPPING_PROD = {
  [SUBSCRIPTION_PLAN.PRO]: {
    [ORG_SUBSCRIPTION_BILLING_CYCLE_PROD.MONTHLY]: {
      // IN: 'Pro-INR-Daily',
      // US: 'Pro-USD-Daily',
      IN: 'Pro-INR-Monthly',
      US: 'Pro-USD-Monthly',
    },
    [ORG_SUBSCRIPTION_BILLING_CYCLE_PROD.YEARLY]: {
      // IN: 'Pro-INR-Daily',
      // US: 'Pro-USD-Daily',
      IN: 'Pro-INR-Yearly',
      US: 'Pro-USD-Yearly',
    },
  },
};

export const getPlanIdMapping = (isProduction = false) => {
  return isProduction ? PLANID_MAPPING_PROD : PLANID_MAPPING_DEV;
};
// **********************************************************

export const getPlanDetails = (isProduction = false) => {
  const ORG_SUBSCRIPTION_BILLING_CYCLE = isProduction
    ? ORG_SUBSCRIPTION_BILLING_CYCLE_PROD
    : ORG_SUBSCRIPTION_BILLING_CYCLE_DEV;

  return {
    [ORG_SUBSCRIPTION_BILLING_CYCLE.MONTHLY]: {
      [SUBSCRIPTION_PLAN.PRO]: {
        name: 'PRO',
        COUNTRY_PRICE_MAPPING: {
          IN: {
            price: 399, // per month per user
            discountedPrice: null,
          },
          US: {
            price: 10, // per month per user
            discountedPrice: null,
          },
        },
      },
    },
    [ORG_SUBSCRIPTION_BILLING_CYCLE.YEARLY]: {
      [SUBSCRIPTION_PLAN.PRO]: {
        name: 'PRO',
        COUNTRY_PRICE_MAPPING: {
          IN: {
            price: 4788, // per year per user
            discountedPrice: 3990,
          },
          US: {
            price: 120, // per year per user
            discountedPrice: 100,
          },
        },
      },
    },
    // [ORG_SUBSCRIPTION_BILLING_CYCLE.DAY]: {
    //   [SUBSCRIPTION_PLAN.PRO]: {
    //     name: 'PRO - Daily',
    //     COUNTRY_PRICE_MAPPING: {
    //       IN: {
    //         price: 1, // per year per user
    //         discountedPrice: 1,
    //       },
    //       US: {
    //         price: 1, // per year per user
    //         discountedPrice: 1,
    //       },
    //     },
    //   },
    // },
  };
};

export const ANNUAL_SUBSCRIPTION_DISCOUNT_PERCENT = ((2 / 12) * 100).toFixed(2);

export const DEFAULT_ORG_SUBSCRIPTION_STATE = () => {
  return Object.assign(
    {},
    {
      isActive: false,
      isTrial: false,
      plan: {},
      status: 'cancelled',
      totalSeats: 0,
    },
  );
};

const getIsPlanExpiryIn3days = (expiryData) => {
  const today = moment();
  const expiredPlanExpiryDate = moment(expiryData);
  const diffInSeconds = expiredPlanExpiryDate.diff(today, 'seconds');
  const secondsIn3Days = 86400 * 3;

  return diffInSeconds <= secondsIn3Days && diffInSeconds > 0;
};

const getIsPlanExpiryIn5days = (expiryData) => {
  const today = moment();
  const expiredPlanExpiryDate = moment(expiryData);
  const diffInSeconds = expiredPlanExpiryDate.diff(today, 'seconds');
  const secondsIn5Days = 86400 * 5;

  return diffInSeconds <= secondsIn5Days && diffInSeconds > 0;
};

export const getOrganisationSubscriptionFormatted = (subscriptionData) => {
  // eslint-disable-next-line new-cap
  const defaultSchema = DEFAULT_ORG_SUBSCRIPTION_STATE();
  try {
    const isNumber = require('lodash/isNumber');
    const {status, totalSeats, plan, addOns} = subscriptionData;
    /**
     * active : if plan status is proper and total
     *          seats are greater than 0 and plan
     *          is one on pro/plus
     */
    const planExpiryDate = getFirebaseTimestampAsDateObj(plan?.activeTill);

    const isActive =
      [SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.TRIAL].includes(
        status,
      ) &&
      isNumber(totalSeats) &&
      totalSeats > 0 &&
      plan?.planName in SUBSCRIPTION_PLAN &&
      planExpiryDate > new Date();
    const addOnsFinal = [];
    if (isActive) {
      subscriptionData.plan.activeTill = planExpiryDate;
      for (const key in addOns) {
        if (addOns[key]?.status !== 'active') continue;
        addOnsFinal.push(key);
      }

      const isTrial = status === SUBSCRIPTION_STATUS.TRIAL;

      const isActivePlanExpiryIn3days =
        status === SUBSCRIPTION_STATUS.ACTIVE
          ? getIsPlanExpiryIn3days(planExpiryDate)
          : false;
      const isActivePlanExpiryIn5days =
        status === SUBSCRIPTION_STATUS.ACTIVE
          ? getIsPlanExpiryIn5days(planExpiryDate)
          : false;
      Object.assign(defaultSchema, subscriptionData, {
        isActive,
        isTrial,
        plan: Object.assign({}, subscriptionData?.plan ?? {}, {
          planName: isTrial ? SUBSCRIPTION_PLAN.PRO : plan.planName,
        }),
        isActivePlanExpiryIn3days,
        isActivePlanExpiryIn5days,
        addOns: addOnsFinal,
      });
    } else {
      if (subscriptionData?.plan) {
        subscriptionData['plan']['activeTill'] = planExpiryDate;
        const isTrialExpired = plan?.planName === SUBSCRIPTION_PLAN.TRIAL;
        Object.assign(defaultSchema, {
          expiredSubscription: Object.assign({}, subscriptionData ?? {}, {
            isTrial: isTrialExpired,
          }),
        });
      }
    }
  } catch (err) {
    captureError(err);
  }
  return defaultSchema;
};

export const checkIfPlusPlan = (subscriptionData) =>
  subscriptionData?.isActive &&
  !subscriptionData.isTrial &&
  subscriptionData.plan.planName === SUBSCRIPTION_PLAN.PLUS;
