import {areShallowEqual} from '../utils/utils';
import {TABLE_ACTION, SEARCH_FILTER_ACTION} from '../actions/actionType';
import {mapHeaderData} from '../utils/equationHelper';
import {updateSearchFilterTableData} from '../actions/searchFilterAction';

const mapHeaderDataToValues = (store) => (next) => (action) => {
  const prevState = store.getState();
  next(action);
  const nextState = store.getState();

  if (nextState?.miniApps?.activeAppId?.length) {
    return;
  }

  if (
    nextState.searchFilter.isActive &&
    ![
      TABLE_ACTION.UPDATE_COLLABORATIVE_TABLE_DATA,
      TABLE_ACTION.EDIT_ROW,
      SEARCH_FILTER_ACTION.UPDATE_SEARCH_FILTER_DATA,
    ].includes(action.type) &&
    !areShallowEqual(prevState.table.tableData, nextState.table.tableData)
  ) {
    updateSearchFilterTableData(nextState.table.tableData);
  }

  if (
    action.type !== TABLE_ACTION.SET_HEADER_MAPPED_VALUES &&
    !areShallowEqual(prevState.table.headerData, nextState.table.headerData)
  ) {
    const restrictions = Object.assign(
      {},
      nextState.home.activeDocumentMeta?.collab?.restrictions,
    );
    const isUserHavingHiddenColumn = Boolean(
      nextState.table.originalHeaderData?.length &&
        Object.keys(restrictions).some(
          (colId) => restrictions[colId]?.isHidden,
        ),
    );
    const headerData = isUserHavingHiddenColumn
      ? nextState.table.originalHeaderData
      : nextState.table.headerData;
    store.dispatch({
      type: TABLE_ACTION.SET_HEADER_MAPPED_VALUES,
      payload: mapHeaderData(headerData, restrictions),
    });
  }
};

export default mapHeaderDataToValues;
