const MyAppsIcon = ({color}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.25 10.125V16.125H2.25V10.125H8.25ZM6.75 11.625H3.75V14.625H6.75V11.625ZM9 1.5L13.125 8.25H4.875L9 1.5ZM9 4.395L7.56 6.75H10.44L9 4.395ZM13.125 9.75C15 9.75 16.5 11.25 16.5 13.125C16.5 15 15 16.5 13.125 16.5C11.25 16.5 9.75 15 9.75 13.125C9.75 11.25 11.25 9.75 13.125 9.75ZM13.125 11.25C12.6277 11.25 12.1508 11.4475 11.7992 11.7992C11.4475 12.1508 11.25 12.6277 11.25 13.125C11.25 13.6223 11.4475 14.0992 11.7992 14.4508C12.1508 14.8025 12.6277 15 13.125 15C13.6223 15 14.0992 14.8025 14.4508 14.4508C14.8025 14.0992 15 13.6223 15 13.125C15 12.6277 14.8025 12.1508 14.4508 11.7992C14.0992 11.4475 13.6223 11.25 13.125 11.25Z"
        fill={color ? color : '#495A74'}
      />
    </svg>
  );
};

export default MyAppsIcon;
