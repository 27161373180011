import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {getLocalText} from 'rb-redux/utils/utils';
import {store} from '../../index';

/* 
    - simply pass a string as the component children
    - eg. <LocalText>abcd</LocalText>

    - no extra spaces allowed, or u might get unwanted results

    - if something is to be replaced , send them as replace and replace by
    - u can also pass replace and replaceBy as array 
    - eg. <LocalText replace='There' replaceBy='Amresh'>Hi There!</LocalText>
    - will result in "Hi Amresh!" 
*/

const LocalText = ({children, replace, replaceBy, quotes, component}) => {
  const currentLanguage = useSelector((state) => state.auth?.userPref?.lang);

  const displayText = useMemo(() => {
    return localTextFn({str: children, replace, replaceBy, quotes});
  }, [currentLanguage, children, replaceBy, replace, quotes]);

  //if you want replaced items to be enclosed within some component
  if (component && replaceBy) {
    return (() => {
      let temp = displayText.split(' ');
      if (Object.prototype.toString.call(replaceBy) === '[object Array]') {
        replaceBy.forEach((item) => {
          //enclosing replaced word in provided component
          let localReplaceBy = getLocalText({lang: currentLanguage}, item);
          temp[temp.indexOf(localReplaceBy)] = React.createElement(
            component,
            {},
            localReplaceBy,
          );
        });
        //the space next to {item} is intentional
        return temp.map((item, index) => (
          <React.Fragment key={index}>{item} </React.Fragment>
        ));
      }
      //if only one word is replaced , enclose the replaced word in provided component and return
      let localReplaceBy = getLocalText({lang: currentLanguage}, replaceBy);
      temp[temp.indexOf(localReplaceBy)] = React.createElement(
        component,
        {},
        localReplaceBy,
      );
      //the space next to {item} is intentional
      return temp.map((item, index) => (
        <React.Fragment key={index}>{item} </React.Fragment>
      ));
    })();
  }

  return <React.Fragment>{displayText}</React.Fragment>;
};

export const localTextFn = ({str, replace, replaceBy, quotes}) => {
  const state = store.getState();
  let currentLanguage = state.auth?.userPref?.lang;

  if (!currentLanguage) currentLanguage = 'EN';

  let text = getLocalText({lang: currentLanguage}, str);

  //if no words to replace return localized text
  if (!(replace && replaceBy)) return text;

  //if array of words to replace, replace each of them
  if (
    Object.prototype.toString.call(replace) === '[object Array]' &&
    Object.prototype.toString.call(replaceBy) === '[object Array]'
  ) {
    replace.forEach((item, index) => {
      let localReplaceBy = getLocalText(
        {lang: currentLanguage},
        replaceBy[index],
      );
      text = quotes
        ? text.replace(item, `"${localReplaceBy}"`)
        : text.replace(item, localReplaceBy);
    });
    return text;
  }

  //if only one word to replace ,replace the word and return
  let localReplaceBy = getLocalText({lang: currentLanguage}, replaceBy);
  return quotes
    ? text.replace(replace, `"${localReplaceBy}"`)
    : text.replace(replace, localReplaceBy);
};

export default LocalText;
