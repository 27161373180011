import {TEAMS_ACTION} from '../actions/actionType';
import {getFileIdsTeamMapping} from '../actions/actionHelpers/teamActionsHelper';

const initialState = {
  teams: {},
  selectedMembers: {},
  fileIds: {},
  isLoading: false,
  areTeamsMigrating: false,
};

const teamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEAMS_ACTION.SET_TEAMS: {
      const teams = Object.assign({}, action.payload.teams);
      return {
        ...state,
        teams,
        fileIds: getFileIdsTeamMapping(teams),
      };
    }
    case TEAMS_ACTION.UPDATE_SELECTED_MEMBER: {
      return {
        ...state,
        selectedMembers: Object.assign({}, action.payload),
      };
    }
    case TEAMS_ACTION.EMPTY_SELECT_MEMBERS: {
      return {
        ...state,
        selectedMembers: {},
      };
    }
    case TEAMS_ACTION.UPDATE_TEAMS_MIGRATION_LOADER: {
      return {
        ...state,
        areTeamsMigrating: action.payload ? true : false,
      };
    }
    case TEAMS_ACTION.START_LOADING_TEAMS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case TEAMS_ACTION.STOP_LOADING_TEAMS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case TEAMS_ACTION.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default teamsReducer;
