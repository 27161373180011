import {
  findLastIndex,
  isEmpty,
  isNil,
  isPlainObject,
  capitalize,
  omit,
  pick,
  forOwn,
  get,
  set,
} from 'lodash';
import {ShowToast, captureError, firestore, getReduxState} from '../../imports';
import {
  CLOUD_FUNCTION_PATHS,
  COLUMN_PROPERTY_KEYS,
  FIELD_TYPE_ID,
  MINI_APPS,
  SUMMARY_OPERATION_TYPE,
} from '../../utils/constant';
import {
  callCloudFunction,
  getNewColumnId,
  getLocalText,
  getPrintTime,
  getColumnFieldType,
  checkIfValueExistOnCell,
} from '../../utils/utils';
import {getChildLinksToUpdate} from '../../utils/tableLinkUtils';
import {mapMiniAppStates} from './miniAppsActionHelper';

const rowOperations = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

const fetchListColumnDataCF = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.FETCH_CHILD_LINKS, obj);

const getHeaderDataAsObj = (headerData = []) => {
  const headerDataAsObj = {};
  const headerProperties = {};
  headerData.forEach((headerObj) => {
    Object.assign(headerDataAsObj, {[headerObj.id]: headerObj});
    headerProperties.isBarCodeColIncluded =
      headerProperties.isBarCodeColIncluded ||
      headerObj.fieldType === FIELD_TYPE_ID.BARCODE;
  });
  return headerDataAsObj;
};

const getLastINewlyAddedRowIndex = (
  listColumnState,
  listColumnColId,
  parentScreenId,
  parentRowId,
  parentDocId,
) => {
  const {sublistTableData = [], rowIdOpsMapping = {}} = getListColumnData(
    listColumnState,
    parentScreenId,
    parentRowId,
    listColumnColId,
    parentDocId,
  );

  return findLastIndex(
    sublistTableData,
    (rowId) => rowIdOpsMapping?.[rowId] === rowOperations.ADD,
  );
};

const mergeSublistLocalChanges = (
  newRowIdDataMap = {},
  newTableData = [],
  listColumnColId = '',
  activeParentScreenId,
  activeParentRowId,
  activeParentDocId,
) => {
  const listColumns = getReduxState().listColumns;

  const {
    rowIdDataMap: localRowIdDataMap,
    sublistTableData,
    rowIdOpsMapping,
  } = getListColumnData(
    listColumns,
    activeParentScreenId,
    activeParentRowId,
    listColumnColId,
    activeParentDocId,
  );

  const lastNewlyAddedRowIndex = getLastINewlyAddedRowIndex(
    listColumns,
    listColumnColId,
    activeParentScreenId,
    activeParentRowId,
    activeParentDocId,
  );
  const oldSublistTableData = [...(sublistTableData ?? [])];
  const newlyAddedRows = [...oldSublistTableData];
  newlyAddedRows.splice(lastNewlyAddedRowIndex + 1);

  const updatedNewTableData = [
    ...newTableData.filter(
      (element) => rowIdOpsMapping?.[element] !== rowOperations.DELETE,
    ),
  ];
  // ^ filters out any locally deleted rows

  const rowIdDataMapWithLocalChanges = Object.assign(
    {},
    newRowIdDataMap,
    localRowIdDataMap,
  );
  const tableDataWithLocalChanges = [...newlyAddedRows, ...updatedNewTableData];

  return {
    rowIdDataMap: rowIdDataMapWithLocalChanges,
    tableData: tableDataWithLocalChanges,
    rowIdOpsMapping,
  };
};

//! NOT MAINTAINED
const filterTableDataBasedOnDataViewAccess = (
  tableDataObj = {},
  assignTaskColIdArr = [], // ? Handle for multiple assing task columns
  sublistScreenId,
  currentState = {},
) => {
  try {
    if (currentState.isMiniApps) {
      const isMiniAppOwner =
        currentState?.activeAppMeta?.isMiniAppOwner ?? false;
      const userPermission = isMiniAppOwner
        ? MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.OWNER
        : currentState.activeAppMeta?.['sharedWith']?.[currentState.uid]
            ?.permission;
      let DATA_VIEW_PERMISSION = MINI_APPS.CUSTOM_VIEW_ACCESS.ALL_DATA;
      if (
        userPermission === MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.CUSTOM_ROLE
      ) {
        if (
          sublistScreenId &&
          !isEmpty(
            currentState.activeCustomRoleInfo?.['screenConfiguration'],
          ) &&
          sublistScreenId in
            currentState.activeCustomRoleInfo['screenConfiguration']
        ) {
          DATA_VIEW_PERMISSION =
            currentState.activeCustomRoleInfo['screenConfiguration']?.[
              sublistScreenId
            ]?.viewAccess;

          if (
            isNil(DATA_VIEW_PERMISSION) ||
            DATA_VIEW_PERMISSION === MINI_APPS.CUSTOM_VIEW_ACCESS.ALL_DATA
          ) {
            return true;
          } else if (
            DATA_VIEW_PERMISSION ===
            MINI_APPS.CUSTOM_VIEW_ACCESS.DATA_ADDED_BY_USER
          ) {
            if (
              tableDataObj?.rowProperties?.firstAddedByUID === currentState.uid
            ) {
              return true;
            } else {
              return false;
            }
          } else if (
            DATA_VIEW_PERMISSION ===
            MINI_APPS.CUSTOM_VIEW_ACCESS.DATA_ASSIGNED_TO_USER
          ) {
            if (isEmpty(assignTaskColIdArr)) return true;
            return assignTaskColIdArr.some(
              (assignTaskColId) =>
                tableDataObj?.[assignTaskColId]?.val?.assignee?.uid ===
                currentState.uid,
            );
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  } catch (error) {
    captureError(error);
  }
};

const getUpdatedListSummaryConfig = (
  newSummaryConfig,
  oldSummaryConfig,
  listColumnId,
  subListHeaderMapping,
  subListFileObj,
  userPref,
) => {
  const updatedSummaryConfig = {};
  const summaryFileObj = {};
  const columnsToAdd = [];
  const colIdsToRemove = new Set();
  const operationsList = Object.keys(SUMMARY_OPERATION_TYPE);
  oldSummaryConfig = Object.assign({}, oldSummaryConfig);

  const doesSummaryColExist = (
    oldSummaryConfigObj = {},
    colIdToApplySummaryOn,
    operation,
  ) => Boolean(oldSummaryConfigObj?.[colIdToApplySummaryOn]?.[operation]);

  if (isPlainObject(newSummaryConfig)) {
    Object.keys(newSummaryConfig).forEach((colId) => {
      operationsList.forEach((operation) => {
        if (newSummaryConfig[colId][operation] !== true) {
          return;
        }

        const summaryColExist = doesSummaryColExist(
          oldSummaryConfig,
          colId,
          operation,
        );

        let summaryColId = null;
        if (!summaryColExist) {
          summaryColId = getNewColumnId(
            FIELD_TYPE_ID.NUMBER,
            `${colId}_${operation}`,
          );
          const columnInfo = subListHeaderMapping[colId];
          const summaryColName = columnInfo?.val ?? '';
          const checkFields = [columnInfo.fieldType, columnInfo.subType];
          const countOperation = operation === SUMMARY_OPERATION_TYPE.COUNT.id;

          // TODO : Handle this on FIELD MODIFICATION BRANCH
          const fieldType = countOperation
            ? FIELD_TYPE_ID.NUMBER
            : checkFields.includes(FIELD_TYPE_ID.TIME) // MIN / MAX
            ? FIELD_TYPE_ID.TIME // TODO : Check for time if working or not.
            : checkFields.includes(FIELD_TYPE_ID.DATE) // MIN / MAX
            ? FIELD_TYPE_ID.DATE
            : checkFields.includes(FIELD_TYPE_ID.UNIT) // SUM / AVG / MIN / MAX
            ? FIELD_TYPE_ID.UNIT
            : checkFields.includes(FIELD_TYPE_ID.RUPEE) // SUM / AVG / MIN / MAX
            ? FIELD_TYPE_ID.RUPEE
            : FIELD_TYPE_ID.NUMBER;

          if (checkFields.includes(FIELD_TYPE_ID.UNIT)) {
            summaryFileObj[summaryColId] = {
              ...subListFileObj[colId],
            };
          }

          const colInfoProperties = columnInfo?.columnProperties ?? {};
          const isRupeeNotCount =
            fieldType === FIELD_TYPE_ID.RUPEE && !countOperation;

          columnsToAdd.push({
            id: summaryColId,
            summaryInfo: {
              operation,
              summaryOfColId: colId,
              listColumnId,
            },
            val: getLocalText(userPref, 'OP_NAME of COl_NAME')
              .replace('OP_NAME', getLocalText(userPref, capitalize(operation)))
              .replace('COl_NAME', summaryColName),
            fieldType,
            columnProperties: {
              [COLUMN_PROPERTY_KEYS.NON_EDITABLE_DATA]: true,
              [COLUMN_PROPERTY_KEYS.NON_EDITABLE_HEADER]: true,
              currency:
                isRupeeNotCount && (colInfoProperties?.currency || undefined),
              CURRENCY:
                isRupeeNotCount && (colInfoProperties?.CURRENCY || undefined),
            },
          });
        } else {
          summaryColId = oldSummaryConfig[colId][operation]['colId'];
        }

        updatedSummaryConfig[colId] = Object.assign(
          {},
          updatedSummaryConfig[colId],
          {[operation]: {colId: summaryColId}},
        );
      });
    });
  }
  Object.keys(oldSummaryConfig).forEach((colId) => {
    operationsList.forEach((operation) => {
      if (
        oldSummaryConfig[colId][operation]?.colId &&
        !updatedSummaryConfig?.[colId]?.[operation]?.colId
      ) {
        colIdsToRemove.add(oldSummaryConfig[colId][operation].colId);
      }
    });
  });

  // TODO : Add updatedSummaryConfig to child file.
  // Chlid's file header index.
  // DocumentsMethods.updateHeaderDataAtIndex()
  return {
    columnsToAdd,
    colIdsToRemove: [...colIdsToRemove],
    updatedSummaryConfig,
    summaryFileObj,
  };
};

const checkCellContainsList = (childLinks, val, listColId, sublistDocId) => {
  const listColumnsState = getReduxState().listColumns;
  const {
    activeParentScreenId,
    activeParentRowId,
    activeListColId,
    activeParentDocId,
  } = listColumnsState;
  return (
    !isNil(childLinks?.[sublistDocId]) ||
    !isEmpty(
      getListColumnData(
        listColumnsState,
        activeParentScreenId,
        activeParentRowId,
        activeListColId,
        activeParentDocId,
      )?.sublistTableData,
    )
  );
};

const getKeyForUniqueColumnData = (cellValue, colObj) => {
  if (isNil(cellValue) || cellValue === '') return null;

  let keyToUse = String(cellValue).toLowerCase().trim();

  if (colObj.fieldType === FIELD_TYPE_ID.TIME) {
    keyToUse = `${getPrintTime(cellValue)}`.toLowerCase().trim();
  }

  return keyToUse;
};

//! NOT MAINTAINED
/**
 *
 * @param {Array} headerData
 * @param {Object} headerDataAsObj
 * @param {Array} tableData
 * @param {Object} rowIdDataMap
 * @param {String} sublistScreenId
 * @param {Object} currentState
 * @returns [tableData, uniqueColumnData]
 */
const processSublistTableData = (
  headerData,
  tableData = [],
  rowIdDataMap = {},
  sublistScreenId,
  currentState = {},
) => {
  //
  const updatedTableData = [];

  tableData.forEach((rowId) => {
    const rowObj = rowIdDataMap[rowId];

    // Apply restriction on tableData based on View Access
    const assignTaskColIdArr = headerData
      .filter((col) => getColumnFieldType(col) === FIELD_TYPE_ID.ASSIGN_TASK)
      .map((col) => col?.id);
    const shouldRowBeVisible = filterTableDataBasedOnDataViewAccess(
      rowObj,
      assignTaskColIdArr,
      sublistScreenId,
      currentState,
    );

    if (shouldRowBeVisible) {
      updatedTableData.push(rowId);
    }
  });

  return updatedTableData;
};

//! NOT MAINTAINED
const checkUniqueValuesForAddEntry = (
  uniqueColumnData = {},
  newRowData,
  headerData,
  headerDataAsObj,
) => {
  let newUniqueColumnData = Object.assign({}, uniqueColumnData);
  const returnObj = {
    canAdd: true,
    updatedUniqueColumnData: Object.assign({}, uniqueColumnData),
  };
  const uniqueColumnIds = getAllUniqueColumnIds(headerData);
  // checks for every column which has UNIQUE propert enabled
  uniqueColumnIds.forEach((item) => {
    const valueToCheck = getKeyForUniqueColumnData(
      newRowData?.[item]?.val,
      headerDataAsObj?.[item],
    );

    // If value not present in the cell, no need to do further checks
    if (!valueToCheck) return;

    // if value is already present, set canAdd to false
    if (uniqueColumnData?.[item]?.[valueToCheck]) returnObj.canAdd = false;
    else {
      // if value is not present, the value is added to newUniqueColumnData for concerned column
      newUniqueColumnData = {
        ...newUniqueColumnData,
        [item]: {
          ...newUniqueColumnData?.[item],
          [valueToCheck]: true,
        },
      };
    }
  });
  if (returnObj.canAdd) {
    //if row can be added return updatedUniqueColumnData
    returnObj.updatedUniqueColumnData = Object.assign({}, newUniqueColumnData);
    return returnObj;
  } else {
    return {
      canAdd: false,
    };
  }
};

//! NOT MAINTAINED
const checkUniqueValuesForEditEntry = (
  uniqueColumnData,
  updatedRowData,
  oldRowData,
  headerData,
  headerDataAsObj,
) => {
  let newUniqueColumnData = Object.assign({}, uniqueColumnData);
  const returnObj = {
    canEdit: true,
    updatedUniqueColumnData: Object.assign({}, uniqueColumnData),
  };
  const uniqueColumnIds = getAllUniqueColumnIds(headerData);
  // checks for every column which has UNIQUE propert enabled
  uniqueColumnIds.forEach((item) => {
    // will be checked only if the value is changed for this column
    if (updatedRowData?.[item]?.val !== oldRowData?.[item]?.val) {
      //handles case insensitivity for TEXT field type
      const valueToCheck = getKeyForUniqueColumnData(
        updatedRowData?.[item]?.val,
        headerDataAsObj?.[item],
      );
      const oldValueKey = getKeyForUniqueColumnData(
        oldRowData?.[item]?.val,
        headerDataAsObj?.[item],
      );

      // If value not present in the cell, no need to do further checks
      if (!valueToCheck) {
        if (oldValueKey) {
          newUniqueColumnData = {
            ...newUniqueColumnData,
            [item]: {
              ...newUniqueColumnData?.[item],
              [oldValueKey]: false,
            },
          };
        }
        return;
      }

      // if value is already present, set canAdd to false
      if (uniqueColumnData?.[item]?.[valueToCheck]) returnObj.canEdit = false;
      else {
        // if value is not present,
        // the updated value is added to newUniqueColumnData
        // and old value is set to false for concerned column
        newUniqueColumnData = {
          ...newUniqueColumnData,
          [item]: {
            ...newUniqueColumnData?.[item],
            [valueToCheck]: true,
          },
        };
        if (oldValueKey) {
          newUniqueColumnData = {
            ...newUniqueColumnData,
            [item]: {
              ...newUniqueColumnData?.[item],
              [oldValueKey]: false,
            },
          };
        }
      }
    }
  });
  if (returnObj.canEdit) {
    //if row can be edited return updatedUniqueColumnData
    returnObj.updatedUniqueColumnData = Object.assign({}, newUniqueColumnData);
    return returnObj;
  } else {
    return {
      canEdit: false,
    };
  }
};

const setChildColumnForConfig = (
  config,
  parentDocId,
  childDocId,
  parentColId, // listColId
) => {
  if (config === null || config === undefined || typeof config !== 'object')
    return;

  const ref = firestore().collection('userDocuments').doc(childDocId);
  const updateObj = {};
  const key = `fileObj.listSummaryConfig.${parentDocId}.${parentColId}`;
  updateObj[key] = config;

  return ref.update(updateObj).catch(captureError);
};

const getAllUniqueColumnIds = (headerData = []) => {
  return headerData
    .filter((colObj) => {
      return Boolean(
        colObj?.columnProperties?.[COLUMN_PROPERTY_KEYS.UNIQUE_VALUES],
      );
    })
    .map((colObj) => colObj.id);
};

const reCalculateSummaryCF = (
  docId,
  parentDocId,
  primaryColId,
  subListPrimaryColId,
  summaryConfig,
) => {
  const obj = {
    docId,
    parentDocId,
    subListPrimaryColId,
    primaryColId,
    summaryConfig,
  };

  const path = CLOUD_FUNCTION_PATHS.RECALCULATE_SUMMARY_CONFIG;
  return callCloudFunction(path, obj);
};

// Function to process assign task columns before sending to firebase
// Used only for Add Entry in List Column
const getAssignTaskProcessedRowData = (rowData = {}, headerData = []) => {
  let updatedRowData = {...rowData};

  const getAssignTaskCellObj = (cellObj) => {
    const cellValue = cellObj?.val;

    if (!cellValue?.assignee) {
      return undefined;
    }

    return {
      val: {
        assignee: cellValue.assignee,
        taskId: 'ORGANISATION_TASK',
        createdTimestamp: firestore(true).Timestamp.now().seconds,
      },
    };
  };

  headerData.forEach(({id, fieldType}) => {
    if (fieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
      const processedAssignTaskCellObj = getAssignTaskCellObj(rowData[id]);
      if (processedAssignTaskCellObj) {
        updatedRowData = {...updatedRowData, [id]: processedAssignTaskCellObj};
      }
    }
  });
  return updatedRowData;
};

// Function to get updates happening in list colums
const getListColUpdates = (listColReducerData) => {
  return listColReducerData;
};

const areEditsPresentInListCols = (listColumns) => {
  const listColumnsState = getReduxState().listColumns;
  const {
    activeParentScreenId,
    activeParentRowId,
    activeListColId,
    activeParentDocId,
  } = listColumnsState;

  const {listColumnMapping = {}} =
    getListColumnData(
      listColumnsState,
      activeParentScreenId,
      activeParentRowId,
      activeListColId,
      activeParentDocId,
    )?.sublistTableData ?? {};

  const isChangeInListRow = (listColumnData) => {
    const {
      headerData,
      rowIdOpsMapping = {},
      rowIdDataMap = {},
      originalRowIdDataMap = {},
    } = listColumnData;

    return Object.keys(rowIdOpsMapping)
      .filter((rowId) => rowIdOpsMapping[rowId] === rowOperations.EDIT)
      .some(
        (rowId) =>
          !isEmpty(
            getChildLinksToUpdate(
              originalRowIdDataMap[rowId],
              rowIdDataMap[rowId],
              headerData,
            ),
          ),
      );
  };
  return Object.keys(listColumnMapping).some((listColId) =>
    isChangeInListRow(listColumnMapping?.[listColId]),
  );
};

// !If Changes made in the same function, update the logic in getRowdataForAutofillInChild
const getPrefillSublistRowData = (
  listScreenId,
  sublistScreenId,
  listRowData,
) => {
  const reduxState = getReduxState();

  const {
    documentData: {headerDataAsObj: listHeaderDataAsObj},
    activeDocId: listDocId,
  } = mapMiniAppStates(reduxState, undefined, false, listScreenId);
  const sublistHeaderData = mapMiniAppStates(
    reduxState,
    undefined,
    false,
    sublistScreenId,
  ).documentData.headerData;

  const prefillSublistRowData = {};
  const mappedColIds = [];

  try {
    sublistHeaderData.forEach((colObj) => {
      const {fieldType, linkedMeta, id: colId, subType} = colObj;
      if (fieldType === FIELD_TYPE_ID.TABLE) {
        const linkedToColId = linkedMeta?.[listDocId]?.colId;
        if (
          listHeaderDataAsObj[linkedToColId] && //if linked to current screen column
          checkIfValueExistOnCell(
            listRowData?.[linkedToColId],
            listHeaderDataAsObj[linkedToColId],
            listRowData,
          ) //if value exists in parent row
        ) {
          prefillSublistRowData[colId] = Object.assign(
            {},
            subType === FIELD_TYPE_ID.ASSIGN_TASK
              ? {
                  val: pick(
                    omit(listRowData[linkedToColId], ['childLinks'])?.val,
                    ['assignee', 'taskId', 'createdTimestamp'],
                  ),
                }
              : omit(listRowData[linkedToColId], ['childLinks']),
            {
              parentMeta: {
                docId: listDocId,
                rowId: listRowData.rowId,
                colId: linkedToColId,
              },
            },
          );
          mappedColIds.push(colId);
        }
      }
    });
  } catch (error) {
    captureError(error);
  }

  return [prefillSublistRowData, mappedColIds];
};

const getKeyForParentDocRowMap = (parentDocId, parentRowId) => {
  return `${parentDocId}#@${parentRowId}`;
};

const getListColumnData = (
  listColumnState,
  parentScreenId, // !Not needed
  parentRowId,
  listColId,
  parentDocId,
) => {
  parentScreenId = parentScreenId ?? listColumnState.activeParentScreenId;
  parentRowId = parentRowId ?? listColumnState.activeParentRowId;
  listColId = listColId ?? listColumnState.activeListColId;
  parentDocId = parentDocId ?? listColumnState.activeParentDocId;

  return (
    listColumnState?.dataMap?.[
      getKeyForParentDocRowMap(parentDocId, parentRowId)
    ]?.listColumnMapping?.[listColId] ?? {}
  );
};
// -------------- ORDER MAP CHANGES
const getPathFromPathArr = (pathArr) => {
  let pathStr = '';
  if (Array.isArray(pathArr) && pathArr.length) {
    pathArr.forEach((element) => {
      pathStr = pathStr.concat(`[${element}]`);
    });
  }
  return pathStr;
};
// -------------- ORDER MAP CHANGES

// This function returns a mapping of list columns and their respective rows
const getListColumnMappedStatesForEntry = () => {
  try {
    const rowsMap = {};

    const {listColumns} = getReduxState();
    const {dataMap, orderMap} = listColumns;

    forOwn(dataMap, (singleDocRowData, docIdRowIdKey) => {
      const {listColumnMapping} = singleDocRowData; // list columns data for single row of doc

      forOwn(listColumnMapping, (listColData, listColId) => {
        const {rowIdOpsMapping, rowIdDataMap, originalRowIdDataMap} =
          listColData ?? {};

        if (isEmpty(rowIdOpsMapping)) return; // If no operation performed, skip

        forOwn(rowIdOpsMapping, (op, rowId) => {
          const rowItem = {};
          switch (op) {
            case rowOperations.ADD: {
              const rowData = omit({rowId, ...(rowIdDataMap?.[rowId] ?? {})}, [
                'index',
              ]);

              rowItem.type = op;
              rowItem.rowData = rowData;
              break;
            }
            case rowOperations.EDIT: {
              const rowData = {rowId, ...(rowIdDataMap?.[rowId] ?? {})};

              rowItem.type = op;
              rowItem.rowData = rowData;
              rowItem.prevRowData = originalRowIdDataMap?.[rowId];
              break;
            }
            case rowOperations.DELETE: {
              const rowData = {rowId, ...(rowIdDataMap?.[rowId] ?? {})};

              rowItem.type = op;
              rowItem.rowData = rowData;
            }
          }

          // Add row Item to mapping
          const mappingObjRef = get(rowsMap, `${docIdRowIdKey}.${listColId}`);
          if (!mappingObjRef?.rows?.length) {
            // If the list column ID is not in the mapping, add it
            set(rowsMap, `${docIdRowIdKey}.${listColId}.rows`, [rowItem]);
          } else {
            // Add the row item to the list column ID mapping
            mappingObjRef.rows.push(rowItem);
          }
        });
      });
    });

    return {rowsMap, orderMap};
  } catch (error) {
    captureError(error);
  }

  return {};
};

const isListColumnDataAlreadyFetched = (
  parentDocId,
  parentRowId,
  listColId,
) => {
  const listColumnsState = getReduxState().listColumns;
  const {dataMap} = listColumnsState;
  const instanceId = getKeyForParentDocRowMap(parentDocId, parentRowId);
  return !isEmpty(dataMap?.[instanceId]?.listColumnMapping?.[listColId]);
};

const openListColumnCellData = async ({
  colData,
  rowDataObj,
  screenName,
  screenId,
  rowId,
  navigation,
  parentRowConfig,
}) => {
  try {
    const {docId, primaryColId} = colData?.listConfig ?? {};
    const {childLinks} = rowDataObj?.[primaryColId] ?? {};

    if (isEmpty(childLinks) || !docId) {
      return {success: false, message: ''};
    }

    navigation.push('MiniAppsOrderListScreen', {
      screenId,
      screenName,
      colData,
      parentRowId: rowId,
      isView: true,

      // New Flow
      // TODO : Handle Parent Row Config
      parentRowConfig,
      parentDocId: parentRowConfig?.docId,
      parentRowObj: rowDataObj,
    });

    return {success: true};
  } catch (err) {
    ShowToast('Something went wrong, please try again');
    captureError(err);
  }
};
const getUpdateParentRowForSummary = (
  originalSublistRowData = {},
  updatedSublistRowData = {},
  operationType,
  rowIdToUpdate,
  extra = {},
) => {
  const state = getReduxState();
  const miniApps = state.miniApps;
  const organisation = state.organisation;
  const {docsData} = miniApps;

  const listColumnsState = state.listColumns;
  const activeParentRowId =
    extra.parentRowId ?? listColumnsState.activeParentRowId;
  const activeParentDocId =
    extra.parentDocId ?? listColumnsState.activeParentDocId;
  // const activeSublistScreenId =
  //   extra.sublistScreenId ?? listColumnsState.activeSublistScreenId;
  // const activeParentScreenId =
  //   extra.parentScreenId ?? listColumnsState.activeParentScreenId;
  const activeListColId = extra.listColId ?? listColumnsState.activeListColId;

  if (
    !isSpecialOrgCase(
      organisation.activeOrganisationId,
      miniApps.activeAppId,
      activeListColId,
    )
  ) {
    return {};
  }
  const path = extra.path ?? listColumnsState.path;

  const {headerDataAsObj} = docsData?.[activeParentDocId] ?? {};

  const listColObj = headerDataAsObj?.[activeListColId];
  const {summaryConfig} = listColObj ?? {};

  const instanceId = getKeyForParentDocRowMap(
    activeParentDocId,
    activeParentRowId,
  );
  let updatedParentRowObj =
    path.length === 1
      ? Object.assign({}, listColumnsState.root)
      : Object.assign(
          {},
          listColumnsState.dataMap?.[instanceId]?.listColumnMapping
            ?.rowIdDataMap?.[rowIdToUpdate],
        );

  console.log('in getUpdateParentRowForSummary  REMOVE CONSOLE', {
    originalSublistRowData,
    updatedSublistRowData,
    operationType,
    rowIdToUpdate,
    extra,
    docsData,
    headerDataAsObj,
    activeParentDocId,
    activeParentRowId,
    activeListColId,
    path,
    listColObj,
    summaryConfig,
    instanceId,
  });

  for (const sublistColId in summaryConfig) {
    // loop over every such column in sublist
    // which can affect summary columns in parent
    const sublistColSummaryConfigObj = summaryConfig[sublistColId];
    console.log('in first for loop  REMOVE CONSOLE', {
      sublistColId,
      sublistColSummaryConfigObj,
    });
    // TODO : add cases for COUNT,AVG,MIN,MAX
    if (!isNil(sublistColSummaryConfigObj?.[SUMMARY_OPERATION_TYPE.TOTAL.id])) {
      //TODO : ADD A CHECK IF parentColId exists or not in headerData
      const parentColIdToUpdate =
        sublistColSummaryConfigObj[SUMMARY_OPERATION_TYPE.TOTAL.id]?.colId;
      console.log('inside if block for TOTAL  REMOVE CONSOLE', {
        parentColIdToUpdate,
      });
      // HANDLES VALUE TO UPDATE BASED ON OPERATION TYPE
      // TODO : MAKE A SMALL HELPER FUNCTION FOR THIS
      let diffToUpdate = 0;
      if (operationType === rowOperations.ADD) {
        diffToUpdate = isFinite(updatedSublistRowData?.[sublistColId]?.val)
          ? updatedSublistRowData?.[sublistColId]?.val
          : 0;
        console.log(
          'indise if block for TOTAL and opType ADD  REMOVE CONSOLE',
          {
            diffToUpdate,
          },
        );
      } else if (operationType === rowOperations.EDIT) {
        diffToUpdate =
          (isFinite(updatedSublistRowData?.[sublistColId]?.val)
            ? updatedSublistRowData?.[sublistColId]?.val
            : 0) -
          (isFinite(originalSublistRowData?.[sublistColId]?.val)
            ? originalSublistRowData?.[sublistColId]?.val
            : 0);
        console.log(
          'indise if block for TOTAL and opType EDIT  REMOVE CONSOLE',
          {
            diffToUpdate,
          },
        );
      } else if (operationType === rowOperations.DELETE) {
        diffToUpdate = isFinite(originalSublistRowData?.[sublistColId]?.val)
          ? originalSublistRowData?.[sublistColId]?.val * -1
          : 0;
        console.log(
          'indise if block for TOTAL and opType DELETE  REMOVE CONSOLE',
          {
            diffToUpdate,
          },
        );
      }

      updatedParentRowObj = Object.assign({}, updatedParentRowObj, {
        [parentColIdToUpdate]: {
          val:
            (isFinite(Number(updatedParentRowObj?.[parentColIdToUpdate]?.val))
              ? Number(updatedParentRowObj?.[parentColIdToUpdate]?.val)
              : 0) + Number(diffToUpdate),
        },
      });
    }
  }
  return updatedParentRowObj;
};

const isSpecialOrgCase = (orgId, activeAppId, listColId) => {
  //  ACTUAL ORG DATA
  return (
    orgId === 'org_vMNRSvV9DVQDsCpwYiQzCGRfmsi2_1716407793' &&
    [
      'vMNRSvV9DVQDsCpwYiQzCGRfmsi2_1717667373_0',
      'vMNRSvV9DVQDsCpwYiQzCGRfmsi2_1721203257_0',
      'vMNRSvV9DVQDsCpwYiQzCGRfmsi2_1721203322_0',
      'vMNRSvV9DVQDsCpwYiQzCGRfmsi2_1721203355_0',
      'vMNRSvV9DVQDsCpwYiQzCGRfmsi2_1721203384_0',
      'vMNRSvV9DVQDsCpwYiQzCGRfmsi2_1724154387_0',
      'vMNRSvV9DVQDsCpwYiQzCGRfmsi2_1727762597_0',
      'vMNRSvV9DVQDsCpwYiQzCGRfmsi2_1730787652_0',
    ].includes(activeAppId) &&
    ['Col_1717440647531', 'Col_171744064753'].includes(listColId)
  );
  // TESTING DATA
  // return (
  //   orgId === 'org_SbXIuRqaXScsWqKPkVUFsStwxNH3_1681123847' &&
  //   [
  //     'hePWolbgIzfGPurGSU4sVvjQ4yn2_1718349612',
  //     'hePWolbgIzfGPurGSU4sVvjQ4yn2_1721308681_0',
  //   ].includes(activeAppId) &&
  //   listColId === 'Col_1718349746669'
  // );
};

export {
  setChildColumnForConfig,
  checkCellContainsList,
  checkUniqueValuesForAddEntry,
  checkUniqueValuesForEditEntry,
  fetchListColumnDataCF,
  getHeaderDataAsObj,
  getKeyForUniqueColumnData,
  getUpdatedListSummaryConfig,
  mergeSublistLocalChanges,
  processSublistTableData,
  rowOperations,
  reCalculateSummaryCF,
  getAssignTaskProcessedRowData,
  getListColUpdates,
  areEditsPresentInListCols,
  getPrefillSublistRowData,
  getKeyForParentDocRowMap,
  getListColumnData,
  getPathFromPathArr,
  getListColumnMappedStatesForEntry,
  isListColumnDataAlreadyFetched,
  openListColumnCellData,
  getUpdateParentRowForSummary,
  isSpecialOrgCase,
};
