import {ShowToast, captureError} from '../imports';
import {MINI_APPS_ACTION} from './actionType';
import FirestoreDB from '../FirestoreHandlers/FirestoreDB';
import {
  callCloudFunction,
  getTimezone,
  handleCloudErrorMsgAndLogging,
} from '../utils/utils';
import {CLOUD_FUNCTION_PATHS} from '../utils/constant';
import {getOrganisationUserName} from '../utils/organistionUtils';
import {generateRandomId} from '../utils/RandomGenerator';

const createEditEndpoint =
  ({endpointConfig, endpointId, isDelete = false}) =>
  async (dispatch, getState) => {
    try {
      const {
        miniApps: {activeAppId, endpointsList},
        auth: {userPref, user},
        organisation: {profileData, membersList},
      } = getState();

      if (!activeAppId || !user?.uid || (isDelete && !endpointId)) {
        return null;
      }

      const isEdit = !isDelete && endpointId != null;

      if (!isEdit && !isDelete) {
        endpointId = generateRandomId(10, `ep_${user.uid}`);
      }

      const dataObj = {
        endpointId,
        miniAppId: activeAppId,
        isDelete,
        ...(isDelete
          ? {}
          : {
              isEdit,
              endpointConfig: Object.assign({}, endpointConfig, {
                userTimezone: userPref?.timezone ?? getTimezone(),
              }),
              userName:
                getOrganisationUserName(
                  user.uid,
                  profileData?.ownerUID,
                  profileData?.owner,
                  membersList,
                ) || 'USER',
              prevEndpointConfig: isEdit
                ? endpointsList.find(({id}) => id === endpointId)
                : null,
            }),
      };

      const data = await callCloudFunction(
        CLOUD_FUNCTION_PATHS.CREATE_EDIT_ENDPOINT_FOR_MINI_APP,
        dataObj,
      );

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return null;
      }

      //success
      let msg;
      let updatedEndpointList = getState().miniApps.endpointsList ?? [];
      if (isDelete) {
        updatedEndpointList = updatedEndpointList.filter(
          (endpointMeta) => endpointMeta.id !== endpointId,
        );
        msg = 'Endpoint deleted successfully';
      } else if (isEdit) {
        updatedEndpointList = updatedEndpointList.map((endpointMeta) =>
          endpointMeta.id === endpointId
            ? {
                id: endpointId,
                ...data.endpointConfig,
              }
            : endpointMeta,
        );
        msg = 'Endpoint updated successfully';
      } else {
        updatedEndpointList = [
          {
            id: endpointId,
            ...data.endpointConfig,
          },
          ...updatedEndpointList,
        ];
        msg = 'Endpoint created successfully';
      }
      dispatch({
        type: MINI_APPS_ACTION.LOAD_MINI_APPS_ENDPOINTS,
        payload: updatedEndpointList,
      });

      ShowToast(msg);

      return true;
    } catch (error) {
      ShowToast('Something went wrong, please try again');
      captureError(error);
    }
    return null;
  };

const fetchEndpointsForMiniApp = () => async (dispatch, getState) => {
  try {
    const {
      miniApps: {activeAppId},
    } = getState();

    if (!activeAppId) {
      return false;
    }

    const querySnap = await FirestoreDB.miniApps
      .appEndpoints(activeAppId)
      .orderBy('createdTimestamp', 'desc')
      .get();

    const endpointsList = [];
    querySnap.docs.forEach((docSnap) => {
      const docData = docSnap.data();
      endpointsList.push(
        Object.assign({}, docData, {
          id: docSnap.id,
        }),
      );
    });

    dispatch({
      type: MINI_APPS_ACTION.LOAD_MINI_APPS_ENDPOINTS,
      payload: endpointsList,
    });
    return true;
  } catch (error) {
    captureError(error);
    return false;
  }
};

export {createEditEndpoint, fetchEndpointsForMiniApp};
