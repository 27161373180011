import {omit} from 'lodash';
import {AUTOMATION_ACTION} from '../actions/actionType';
import {AUTOMATION_TEMPLATES_TYPE} from '../utils/constant';

const initialState = {
  showLoader: false,
  isAutomationEnabled: false,
  availableAutomations: {},
  automationConfig: {},
  fromMiniApps: false,
  automationDocumentId: '',
  automationActiveDocumentId: '',
  // allDependentColums: [],
  // automationColMapping: {},
  automationTemplates: [],
  filters: [],
  pendingAutomations: {},
  newAuto: {},
  triggerCols: {},
  currentCol: {},
  editTriggerCol: {},
  currentAutomation: {},
  dependentCols: [],
  dependentColObj: {},
  catId: null,
  isEditingColIdMapping: false,
  constTemplateValues: {},
  dailyAutomationConfig: {},
  selectedFileForDailyAutomation: {},
  globalAutomationData: {},
  scheduledAutomation: {},
  disabledDueToPrevRowRef: {},
  automationTemplatesType: AUTOMATION_TEMPLATES_TYPE.ALL,
  operationFileData: {},
  notificationRedirectionWeb: {},
  miniAppAutomationConfig: {},
  miniAppAutomationStatus: {},
  miniAppIntegrationEmails: [],
};

const automationReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTOMATION_ACTION.SET_SCHEDULED_PENDING_AUTOMATION:
      return {
        ...state,
        scheduledAutomation: action.payload,
      };
    case AUTOMATION_ACTION.SET_SHOW_AUTOMATION:
      return {
        ...state,
        isAutomationEnabled: action.payload,
      };
    case AUTOMATION_ACTION.SET_DAILY_AUTOMATION_CONFIG:
      return {
        ...state,
        dailyAutomationConfig: action.payload,
      };
    case AUTOMATION_ACTION.SET_AUTOMATION_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case AUTOMATION_ACTION.SET_SELECTED_DOC_DAILY_AUTOMATION:
      return {
        ...state,
        selectedFileForDailyAutomation: action.payload,
      };
    case AUTOMATION_ACTION.SET_IS_EDITTING_COLUMN_MAPPING:
      return {
        ...state,
        isEditingColIdMapping: action.payload,
      };
    case AUTOMATION_ACTION.UPDATE_AUTOMATION_OBJ:
      return {
        ...state,
        newAuto: action.payload,
      };
    case AUTOMATION_ACTION.SET_AUTOMATION_OBJ:
      return {
        ...state,
        newAuto: Object.assign({}, state.newAuto, action.payload),
        constTemplateValues: action.payload?.constTemplateValues
          ? Object.assign({}, action.payload?.constTemplateValues)
          : state.constTemplateValues,
      };
    case AUTOMATION_ACTION.SET_EDIT_TRIGGER_COL:
      return {
        ...state,
        editTriggerCol: action.payload,
      };
    case AUTOMATION_ACTION.CLEAR_PREV_STATE_AUTOMATIONS:
      return {
        ...state,
        newAuto: {},
        currentCol: {},
        currentAutomation: {},
        triggerCols: {},
        editTriggerCol: {},
        catId: null,
        isEditingColIdMapping: false,
        constTemplateValues: {},
      };
    case AUTOMATION_ACTION.LOAD_AUMATIONS:
      return {
        ...state,
        availableAutomations: action.payload,
      };
    case AUTOMATION_ACTION.AUTOMATION_LOAD_BASED_ON_TRIGGER:
      return {
        ...state,
        filteredByTrigger: action.payload,
      };
    case AUTOMATION_ACTION.LOAD_GLOBAL_AUTOMATIONS:
      return {
        ...state,
        selectedFileForDailyAutomation: action.payload.docArray,
        globalAutomationData: action.payload,
      };
    case AUTOMATION_ACTION.SET_TABLE_AUTOMATION_CONFIG_DATA:
      return {
        ...state,
        automationDocumentId: action.payload.automationDocumentId,
        automationActiveDocumentId: action.payload.automationActiveDocumentId,
        fromMiniApps: action.payload.fromMiniApps,
      };
    case AUTOMATION_ACTION.SET_DOC_AUTOMATION_CONFIG_DATA: {
      const payload = omit(action.payload, ['fromMiniApps']);

      return {
        ...state,
        ...payload,
        newAuto: action.payload?.fromMiniApps ? state.newAuto : {},
        currentCol: {},
        currentAutomation: {},
        catId: null,
        isEditingColIdMapping: false,
        constTemplateValues: {},
      };
    }
    case AUTOMATION_ACTION.UPDATE_DOC_AUTOMATION_CONFIG:
      return {
        ...state,
        automationConfig: action.payload,
      };
    case AUTOMATION_ACTION.SET_PENDING_AUTOMATIONS:
      return {
        ...state,
        pendingAutomations: action.payload.pendingAutomationsObj,
        scheduledAutomation:
          action.payload.scheduledAutomationObj ?? state.scheduledAutomation,
      };
    case AUTOMATION_ACTION.CREATE_TRIGGER:
      return {
        ...state,
        triggerCols: Object.assign({}, state.triggerCols, action.payload),
      };
    case AUTOMATION_ACTION.UPDATE_CURRENT_AUTOMATION_CAT_ID:
      return {
        ...state,
        catId: action.payload,
      };
    case AUTOMATION_ACTION.UPDATE_TRIGGER_COLS:
      return {
        ...state,
        triggerCols: action.payload,
      };
    case AUTOMATION_ACTION.SET_CURRENT_COL:
      return {
        ...state,
        currentCol: action.payload,
      };
    case AUTOMATION_ACTION.SET_DOCUMENT_META_FOR_AUTOMATION:
      return {
        ...state,
        automationDocumentMeta: action.payload,
      };
    case AUTOMATION_ACTION.RESET_AUTOMATION_STATE:
      return {
        ...state,
        showLoader: false,
        automationConfig: {},
        pendingAutomations: {},
        newAuto: {},
        triggerCols: {},
        currentCol: {},
        editTriggerCol: {},
        currentAutomation: {},
        dependentCols: [],
        dependentColObj: {},
        catId: null,
        isEditingColIdMapping: false,
        constTemplateValues: {},
        dailyAutomationConfig: {},
        selectedFileForDailyAutomation: {},
        globalAutomationData: {},
        scheduledAutomation: {},
        automationDocumentMeta: {},
        automationActiveDocumentId: '',
        automationDocumentId: '',
        fromMiniApps: false,
        miniAppAutomationConfig: {},
        miniAppAutomationStatus: {},
        miniAppAutomationIdDisplayTextMap: {},
      };
    case AUTOMATION_ACTION.RESET_AUTOMATION_STATE_MINI_APPS_EDIT: {
      return {
        ...state,
        newAuto: {},
        triggerCols: {},
        currentCol: {},
        editTriggerCol: {},
        currentAutomation: {},
        dependentCols: {},
        dependentColObj: {},
        catId: null,
        constTemplateValues: {},
        operationFileData: {},
        automationDocumentMeta: {},
        automationDocumentId: {},
        automationActiveDocumentId: {},
        fromMiniApps: false,
      };
    }
    case AUTOMATION_ACTION.RESET_CURRENT_AUTOMATION_STATE_WEB:
      return {
        ...state,
        showLoader: false,
        automationConfig: {},
        pendingAutomations: {},
        newAuto: {},
        triggerCols: {},
        currentCol: {},
        editTriggerCol: {},
        currentAutomation: {},
        dependentCols: [],
        dependentColObj: {},
        catId: null,
        isEditingColIdMapping: false,
        constTemplateValues: {},
        dailyAutomationConfig: {},
        selectedFileForDailyAutomation: {},
        globalAutomationData: {},
        scheduledAutomation: {},
        automationDocumentMeta: {},
        automationActiveDocumentId: '',
        automationDocumentId: '',
        fromMiniApps: false,
      };
    case AUTOMATION_ACTION.RESET_CURRENT_AUTOMATION_RELATED_STATE:
      return {
        ...state,
        showLoader: false,
        newAuto: {},
        triggerCols: {},
        currentCol: {},
        editTriggerCol: {},
        currentAutomation: {},
        catId: null,
        isEditingColIdMapping: false,
        constTemplateValues: {},
        dailyAutomationConfig: {},
        selectedFileForDailyAutomation: {},
        globalAutomationData: {},
        scheduledAutomation: {},
        operationFileData: {},
      };
    case AUTOMATION_ACTION.SET_CURRENT_AUTOMATION:
      return {
        ...state,
        currentAutomation: Object.assign(
          {},
          state.currentAutomation,
          action.payload,
        ),
      };
    case AUTOMATION_ACTION.UPDATE_PHONE_COL: {
      const newAuto = {...state.newAuto};
      newAuto.phoneColId = action.payload.phoneColId;

      return {
        ...state,
        newAuto,
      };
    }
    case AUTOMATION_ACTION.SHOW_AUTOMATION_LOADER:
      return {
        ...state,
        showLoader: true,
      };
    case AUTOMATION_ACTION.HIDE_AUTOMATION_LOADER:
      return {
        ...state,
        showLoader: false,
      };
    case AUTOMATION_ACTION.UPDATE_CUSTOM_KEY_VALUES:
      return {
        ...state,
        constTemplateValues: action.payload,
      };
    case AUTOMATION_ACTION.LOAD_AUTOMATION_TEMPLATES:
      return {
        ...state,
        automationTemplates: action.payload.templates,
        automationTemplatesType: action.payload.type,
      };
    case AUTOMATION_ACTION.CLEAR_AUTOMATION_TEMPLATES:
      return {
        ...state,
        automationTemplates: initialState.automationTemplates,
      };
    case AUTOMATION_ACTION.SET_AUTOMATIONS_DISABLED_DUE_TO_PREV_ROW_REF:
      return {
        ...state,
        disabledDueToPrevRowRef: action.payload,
      };
    case AUTOMATION_ACTION.SET_OPERATION_FILE_DATA:
      return {
        ...state,
        operationFileData: action.payload,
      };

    case AUTOMATION_ACTION.SET_NOTIFICATION_REDIRECTION_PARAMS_WEB: {
      return {
        ...state,
        notificationRedirectionWeb: action.payload,
      };
    }
    case AUTOMATION_ACTION.RESET_NOTIFICATION_REDIRECTION_PARAMS_WEB: {
      return {
        ...state,
        notificationRedirectionWeb: {},
      };
    }
    case AUTOMATION_ACTION.SET_MINI_APP_AUTOMATION_CONFIG_DATA: {
      return {
        ...state,
        miniAppAutomationConfig: action.payload,
      };
    }
    case AUTOMATION_ACTION.MINI_APP_UPDATE_AUTOMATION_RUNNING_STATUS: {
      return {
        ...state,
        miniAppAutomationStatus: Object.assign(
          {},
          action.payload?.currAutomationRunningStatus,
        ),
        miniAppAutomationIdDisplayTextMap: Object.assign(
          {},
          action.payload?.automationIdDisplayTextMap,
        ),
      };
    }
    case AUTOMATION_ACTION.MINI_APP_SET_EMAILS_FOR_INTEGRATION: {
      return {
        ...state,
        miniAppIntegrationEmails: action.payload?.length ? action.payload : [],
      };
    }
    default:
      return state;
  }
};

export default automationReducer;
