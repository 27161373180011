import {forOwn, has, isArray, isEmpty, isEqual, isNil, omit} from 'lodash';
import moment from 'moment';
import {
  getSortedMiniAppsScreens,
  isCustomRoleFilteredScreen,
  mapMiniAppStates,
} from '../actions/actionHelpers/miniAppsActionHelper';
import {captureError, logAnalyticsEvent} from '../imports';
import {
  CLOUD_FUNCTION_COMMON_PARAMS,
  CLOUD_FUNCTION_PATHS,
  COLUMN_PROPERTY_KEYS,
  FIELD_TYPE_ID,
  MINI_APPS,
  NOT_ALLOWED_COLUMNS_TABLE_LINK_MODAL,
  RANGE_FILTER_FIELDS,
  TABLE_LIMITS,
  TABLE_LINK_OVERWRITE_RELATED_KEYS,
  TIME_BASED_RANGE_FILTER_FIELDS,
} from './constant';
import {TABLE_CHILD_LINKS_VIEW_TYPE} from './tableLinkConstants';
import {getHeaderDataAsObj} from '../actions/actionHelpers/listColumnsActionHelper';
import {
  callCloudFunction,
  checkIfValueExistOnCell,
  convertCellDataToText,
  getColumnFieldType,
  getHeaderTypeMapping,
  getScreensListFromDocIds,
  isBackgroundField,
} from './utils';
import * as tableActionHelper from '../actions/actionHelpers/tableActionHelper';
import * as tableLinksActionHelper from '../actions/actionHelpers/tableLinksActionHelper';
import DocumentsMethods from '../FirestoreHandlers/Documents/DocumentsMethods';
import {searchStringTableLinkHelper} from '../actions/tableLinksActions';

const childLinkDataFormat = (data) => {
  const sectionData = [];
  Object.keys(data).forEach((docId, index) => {
    const returnData = [];
    const {pagesEnabled, displayDocuments, docName} = data[docId];
    if (pagesEnabled) {
      Object.keys(displayDocuments).forEach((displayDocId) => {
        const {pageName, tableData, headerData, fileObj} =
          displayDocuments[displayDocId];

        returnData.push({
          type: TABLE_CHILD_LINKS_VIEW_TYPE.PAGE_NAME,
          data: pageName,
        });

        returnData.push({
          type: TABLE_CHILD_LINKS_VIEW_TYPE.HEADER,
          data: headerData,
        });

        tableData.forEach((rowData) => {
          returnData.push({
            type: TABLE_CHILD_LINKS_VIEW_TYPE.ROW,
            data: rowData,
            extra: {headerData, fileObj},
          });
          returnData.push({
            type: TABLE_CHILD_LINKS_VIEW_TYPE.ROW,
            data: rowData,
            extra: {headerData, fileObj},
          });
          returnData.push({
            type: TABLE_CHILD_LINKS_VIEW_TYPE.ROW,
            data: rowData,
            extra: {headerData, fileObj},
          });
          returnData.push({
            type: TABLE_CHILD_LINKS_VIEW_TYPE.ROW,
            data: rowData,
            extra: {headerData, fileObj},
          });
        });
      });
    } else {
      Object.keys(displayDocuments).forEach((displayDocId) => {
        const {tableData, headerData, fileObj} = displayDocuments[displayDocId];

        returnData.push({
          type: TABLE_CHILD_LINKS_VIEW_TYPE.HEADER,
          data: headerData,
        });

        tableData.forEach((rowData) => {
          returnData.push({
            type: TABLE_CHILD_LINKS_VIEW_TYPE.ROW,
            data: rowData,
            extra: {headerData, fileObj},
          });
        });
      });
    }

    /** PUSH to Section Data */
    sectionData.push({
      title: docName,
      data: returnData,
      pagesCount: pagesEnabled ? Object.keys(displayDocuments).length : 0,
      index,
    });
  });
  return sectionData;
};

const checkAndUpdatePrimaryColumnMapping = (
  colObj,
  primaryColumnMapping = {},
) => {
  try {
    if ('linkedMeta' in colObj && !isEmpty(colObj.linkedMeta)) {
      Object.keys(colObj.linkedMeta).forEach((parentDocId) => {
        if (!(parentDocId in primaryColumnMapping)) {
          primaryColumnMapping = {
            ...primaryColumnMapping,
            [parentDocId]: colObj.id,
          };
        }
      });
    }
  } catch (error) {
    captureError(error);
  }
  return Object.assign({}, primaryColumnMapping);
};

const getSubmitUndoRedoActions = (
  activeDocumentId,
  addedParentRowMetaData,
  parentRowMetaObj,
) => {
  try {
    // UPDATE PARENT ROW META.
    /**
     *  @param parentRowMetaObj  = parentRowMeta from state.tableLinks.parentRowMeta
     */
    const arr = isArray(addedParentRowMetaData) ? addedParentRowMetaData : [];
    /**
     * @param addedParentRowMetaData = Array<{
     *    parentRowMeta : string
     *    parentColId : string
     *    parentFileId : string
     *    parentRowId : string
     *    alreadyCreated : boolean
     * }>
     */

    const parentRowMetaObjUndoRemove = {};
    const parentRowMetaObjUndoAdd = {};
    const parentRowMetaObjRedo = {};

    arr.forEach((item) => {
      const prevRowMetaData = parentRowMetaObj?.meta?.[item.parentRowMeta];
      if (prevRowMetaData) {
        parentRowMetaObjUndoAdd[item.parentRowMeta] = prevRowMetaData;
      } else {
        parentRowMetaObjUndoRemove[item.parentRowMeta] = {};
      }
      parentRowMetaObjRedo[item.parentRowMeta] = {
        parentColId: item.parentColId,
        parentFileId: item.parentFileId,
        parentRowId: item.parentRowId,
      };
    });

    const undoActions = [
      ...(!isEmpty(parentRowMetaObjUndoRemove)
        ? [
            {
              updateObjType: 'object',
              elementType: 'object',
              element: {...parentRowMetaObjUndoRemove},
              action: 'REMOVE',
              collection: 'userDocuments',
              path: `${activeDocumentId}/parentRowMeta/mapping`,
              updatePath: `meta`,
            },
          ]
        : []),
      ...(!isEmpty(parentRowMetaObjUndoAdd)
        ? [
            {
              updateObjType: 'object',
              elementType: 'object',
              element: {...parentRowMetaObjUndoAdd},
              action: 'ADD',
              collection: 'userDocuments',
              path: `${activeDocumentId}/parentRowMeta/mapping`,
              updatePath: `meta`,
            },
          ]
        : []),
    ];
    const redoActions = [
      {
        updateObjType: 'object',
        elementType: 'object',
        element: {...parentRowMetaObjRedo},
        action: 'ADD',
        collection: 'userDocuments',
        path: `${activeDocumentId}/parentRowMeta/mapping`,
        updatePath: `meta`,
      },
    ];

    return {
      extraUndoActions: undoActions,
      extraRedoActions: redoActions,
    };
  } catch (error) {
    captureError(error);
    return {
      extraUndoActions: [],
      extraRedoActions: [],
    };
  }
};

const getTableLinkColumnUpdateUndoRedoActions = (
  editObj,
  {
    changeLinkedFile = false,
    changeColumn = false,
    // Required
    firstAdd,
    addMore,
    activeDocumentId,
    uid,
    activeDocumentMeta,
    changedLinkedFileDocId,
    changedLinkedFileColId,
    colObj,
  },
) => {
  try {
    const isShared = !isEmpty(activeDocumentMeta?.collab);

    return {
      extraUndoActions: [
        ...(firstAdd || addMore
          ? [
              {
                updateObjType: 'object',
                elementType: 'object',
                element: {[editObj.selectedColObj.colId]: colObj.id},
                action: 'REMOVE',
                removeParentObjIfEmpty: true,
                collection: 'userDocuments',
                path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
                updatePath: `${editObj.selectedDocID}`,
                runAfterParentObjEmptyRemove: [
                  {
                    updateObjType: 'array',
                    elementType: 'string',
                    element: editObj.selectedDocID,
                    action: 'REMOVE',
                    collection: isShared ? 'sharedDocsMeta' : 'users',
                    path: isShared
                      ? `${activeDocumentId}`
                      : `${uid}/documents/${activeDocumentId}`,
                    updatePath: 'linkedDocIds',
                  },
                ],
              },
            ]
          : []),
        ...(changeColumn
          ? [
              {
                updateObjType: 'object',
                elementType: 'object',
                element: {[editObj.selectedColObj.colId]: colObj.id},
                action: 'REMOVE',
                collection: 'userDocuments',
                path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
                updatePath: `${editObj.selectedDocID}`,
                runAfterParentObjRemove: [
                  {
                    updateObjType: 'object',
                    elementType: 'object',
                    element: {[changedLinkedFileColId]: colObj.id},
                    action: 'ADD',
                    collection: 'userDocuments',
                    path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
                    updatePath: `${editObj.selectedDocID}`,
                  },
                ],
              },
            ]
          : []),
        ...(changeLinkedFile
          ? [
              {
                updateObjType: 'object',
                elementType: 'object',
                element: {[editObj.selectedColObj.colId]: colObj.id},
                action: 'REMOVE',
                removeParentObjIfEmpty: true,
                collection: 'userDocuments',
                path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
                updatePath: `${editObj.selectedDocID}`,
                runAfterParentObjEmptyRemove: [
                  {
                    updateObjType: 'array',
                    elementType: 'string',
                    element: editObj.selectedDocID,
                    action: 'REMOVE',
                    collection: isShared ? 'sharedDocsMeta' : 'users',
                    path: isShared
                      ? `${activeDocumentId}`
                      : `${uid}/documents/${activeDocumentId}`,
                    updatePath: 'linkedDocIds',
                  },
                ],
              },
              {
                updateObjType: 'object',
                elementType: 'object',
                element: {[changedLinkedFileColId]: colObj.id},
                action: 'ADD',
                collection: 'userDocuments',
                path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
                updatePath: `${changedLinkedFileDocId}`,
                addIdPathIfNotExist: true,
                runAfterParentObjAdd: [
                  {
                    updateObjType: 'array',
                    elementType: 'string',
                    element: changedLinkedFileDocId,
                    action: 'ADD',
                    collection: isShared ? 'sharedDocsMeta' : 'users',
                    path: isShared
                      ? `${activeDocumentId}`
                      : `${uid}/documents/${activeDocumentId}`,
                    updatePath: 'linkedDocIds',
                  },
                ],
              },
            ]
          : []),
      ],
      extraRedoActions: [
        ...(firstAdd || addMore
          ? [
              {
                updateObjType: 'object',
                elementType: 'object',
                element: {[editObj.selectedColObj.colId]: colObj.id},
                action: 'ADD',
                collection: 'userDocuments',
                path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
                updatePath: `${editObj.selectedDocID}`,
                addIdPathIfNotExist: true,
                runAfterParentObjAdd: [
                  {
                    updateObjType: 'array',
                    elementType: 'string',
                    element: editObj.selectedDocID,
                    action: 'ADD',
                    collection: isShared ? 'sharedDocsMeta' : 'users',
                    path: isShared
                      ? `${activeDocumentId}`
                      : `${uid}/documents/${activeDocumentId}`,
                    updatePath: 'linkedDocIds',
                  },
                ],
              },
            ]
          : []),
        ...(changeColumn
          ? [
              {
                updateObjType: 'object',
                elementType: 'object',
                element: {[editObj.selectedColObj.colId]: colObj.id},
                action: 'ADD',
                collection: 'userDocuments',
                path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
                updatePath: `${editObj.selectedDocID}`,
                addIdPathIfNotExist: true,
                runAfterParentObjAdd: [
                  {
                    updateObjType: 'object',
                    elementType: 'object',
                    element: {[changedLinkedFileColId]: colObj.id},
                    action: 'REMOVE',
                    collection: 'userDocuments',
                    path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
                    updatePath: `${editObj.selectedDocID}`,
                  },
                ],
              },
            ]
          : []),
        ...(changeLinkedFile
          ? [
              {
                updateObjType: 'object',
                elementType: 'object',
                element: {[editObj.selectedColObj.colId]: colObj.id},
                action: 'ADD',
                collection: 'userDocuments',
                path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
                updatePath: `${editObj.selectedDocID}`,
                addIdPathIfNotExist: true,
                runAfterParentObjAdd: [
                  {
                    updateObjType: 'array',
                    elementType: 'string',
                    element: editObj.selectedDocID,
                    action: 'ADD',
                    collection: isShared ? 'sharedDocsMeta' : 'users',
                    path: isShared
                      ? `${activeDocumentId}`
                      : `${uid}/documents/${activeDocumentId}`,
                    updatePath: 'linkedDocIds',
                  },
                ],
              },
              {
                updateObjType: 'object',
                elementType: 'object',
                element: {[changedLinkedFileColId]: colObj.id},
                action: 'REMOVE',
                removeParentObjIfEmpty: true,
                collection: 'userDocuments',
                path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
                updatePath: `${changedLinkedFileDocId}`,
                runAfterParentObjEmptyRemove: [
                  {
                    updateObjType: 'array',
                    elementType: 'string',
                    element: changedLinkedFileDocId,
                    action: 'REMOVE',
                    collection: isShared ? 'sharedDocsMeta' : 'users',
                    path: isShared
                      ? `${activeDocumentId}`
                      : `${uid}/documents/${activeDocumentId}`,
                    updatePath: 'linkedDocIds',
                  },
                ],
              },
            ]
          : []),
      ],
    };
  } catch (error) {
    captureError(error);
    return {
      extraUndoActions: [],
      extraRedoActions: [],
    };
  }
};

const generateParentRowMeta = () => {
  const temp = `${Math.random()}`.slice(2, 10);
  return `${moment().valueOf()}_${temp}`;
};

/**
 * Get Table Links Delete File - Undo/Redo Actions
 * @returns {object} {extraUndoActions, extraRedoActions}
 */
const getTableLinkDeleteFileUndoRedoActions = ({
  parentColId,
  childColId,
  parentDocId,
  activeDocumentId,
  activeDocumentMeta,
  uid,
}) => {
  try {
    const isShared = !isEmpty(activeDocumentMeta?.collab);
    const undoActions = [
      {
        updateObjType: 'object',
        elementType: 'object',
        element: {[parentColId]: childColId},
        action: 'ADD',
        collection: 'userDocuments',
        path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
        addIdPathIfNotExist: true,
        updatePath: `${parentDocId}`,
        runAfterParentObjAdd: [
          {
            updateObjType: 'array',
            elementType: 'string',
            element: parentDocId,
            action: 'ADD',
            collection: isShared ? 'sharedDocsMeta' : 'users',
            path: isShared
              ? `${activeDocumentId}`
              : `${uid}/documents/${activeDocumentId}`,
            updatePath: 'linkedDocIds',
          },
        ],
      },
    ];
    const redoActions = [
      {
        updateObjType: 'object',
        elementType: 'object',
        element: {[parentColId]: childColId},
        action: 'REMOVE',
        collection: 'userDocuments',
        path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
        updatePath: `${parentDocId}`,
        runAfterParentObjEmptyRemove: [
          {
            updateObjType: 'array',
            elementType: 'string',
            element: parentDocId,
            action: 'REMOVE',
            collection: isShared ? 'sharedDocsMeta' : 'users',
            path: isShared
              ? `${activeDocumentId}`
              : `${uid}/documents/${activeDocumentId}`,
            updatePath: 'linkedDocIds',
          },
        ],
      },
    ];
    return {
      extraUndoActions: undoActions,
      extraRedoActions: redoActions,
    };
  } catch (error) {
    captureError(error);
    return {
      extraUndoActions: [],
      extraRedoActions: [],
    };
  }
};

const filterTableDataBasedOnDataViewAccess = (
  tableDataObj,
  parentDocId,
  assignTaskColumnId = null,
  currentState = {},
) => {
  try {
    if (currentState.isMiniApps) {
      const isMiniAppOwner =
        currentState?.activeAppMeta?.isMiniAppOwner ?? false;
      const userPermission = isMiniAppOwner
        ? MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.OWNER
        : currentState.activeAppMeta?.['sharedWith']?.[currentState.uid]
            ?.permission;
      let DATA_VIEW_PERMISSION = MINI_APPS.CUSTOM_VIEW_ACCESS.ALL_DATA;
      if (
        userPermission === MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.CUSTOM_ROLE
      ) {
        const screens =
          getSortedMiniAppsScreens(
            currentState.activeAppMeta,
            currentState.uid,
            currentState.activeCustomRoleInfo,
          ) ?? [];
        let checkScreenId = null;
        for (let i = 0; i < screens.length; i++) {
          if (screens[i]?.docs?.[0]?.docId === parentDocId) {
            checkScreenId = screens[i]?.screenId;
            break;
          }
        }
        if (
          checkScreenId &&
          !isEmpty(
            currentState.activeCustomRoleInfo?.['screenConfiguration'],
          ) &&
          checkScreenId in
            currentState.activeCustomRoleInfo['screenConfiguration']
        ) {
          DATA_VIEW_PERMISSION =
            currentState.activeCustomRoleInfo['screenConfiguration']?.[
              checkScreenId
            ]?.viewAccess;

          if (
            isNil(DATA_VIEW_PERMISSION) ||
            DATA_VIEW_PERMISSION === MINI_APPS.CUSTOM_VIEW_ACCESS.ALL_DATA
          ) {
            return true;
          } else if (
            DATA_VIEW_PERMISSION ===
            MINI_APPS.CUSTOM_VIEW_ACCESS.DATA_ADDED_BY_USER
          ) {
            if (
              tableDataObj?.rowProperties?.firstAddedByUID === currentState.uid
            ) {
              return true;
            } else {
              return false;
            }
          } else if (
            DATA_VIEW_PERMISSION ===
            MINI_APPS.CUSTOM_VIEW_ACCESS.DATA_ASSIGNED_TO_USER
          ) {
            if (assignTaskColumnId) {
              if (
                tableDataObj?.[assignTaskColumnId]?.val?.assignee?.uid ===
                currentState.uid
              ) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  } catch (error) {
    captureError(error);
  }
};

// !If Changes made in the same function, update the logic in getPrefillSublistRowData
// Function to get rowData for autofill in child when adding entry to parent
const getRowdataForAutofillInChild = (
  docId,
  rowId,
  headerDataWithRestriction, // headerData without hidden cols and other restriction
  rowData,
  autoFillLinkedFilesForChild,
) => {
  const KEYS_TO_OMIT = ['childLinks'];
  // TODO : Add check to see if child column is visibile or not
  const autoFillObjForChild = autoFillLinkedFilesForChild[docId] ?? {};

  const preFillRowDataForChild = {};
  const headerDataAsObjWithRestrictions = getHeaderDataAsObj(
    headerDataWithRestriction,
  );
  Object.keys(autoFillObjForChild).forEach((colId) => {
    const childColId = autoFillObjForChild[colId];
    const valueExists = checkIfValueExistOnCell(
      rowData[colId],
      headerDataAsObjWithRestrictions[colId],
      rowData,
    );
    if (valueExists) {
      const parentMeta = {colId, docId, rowId};
      const cellObj = {
        ...rowData[colId],
        parentMeta,
      };
      preFillRowDataForChild[childColId] = omit(cellObj, KEYS_TO_OMIT);
    }
  });
  return preFillRowDataForChild;
};
const getChildLinksToUpdate = (
  oldRowData = {},
  newRowData = {},
  headerData = [],
) => {
  const childLinksToUpdate = {};
  const headerDataAsObj = getHeaderDataAsObj(headerData);
  const currentRowData = omit(newRowData, TABLE_LIMITS.REQUIRED_ROW_KEYS);
  forOwn(currentRowData, (rowObj, colId) => {
    const {fieldType, columnProperties} = headerDataAsObj[colId] ?? {};
    if (
      headerDataAsObj[colId] &&
      (![FIELD_TYPE_ID.LIST].includes(fieldType) ||
        (fieldType === FIELD_TYPE_ID.DATE_TIME &&
          !columnProperties[COLUMN_PROPERTY_KEYS.RECURRING])) &&
      has(rowObj, ['childLinks']) &&
      checkIfValueExistOnCell(
        oldRowData?.[colId],
        headerDataAsObj[colId],
        oldRowData,
      ) &&
      !isEqual(oldRowData[colId], currentRowData[colId])
    ) {
      if (
        headerDataAsObj[colId].fieldType === FIELD_TYPE_ID.ASSIGN_TASK &&
        isEqual(
          oldRowData?.[colId]?.val?.assignee,
          currentRowData?.[colId]?.val?.assignee ||
            currentRowData?.[colId]?.val?.taskObj?.assignee,
        )
      ) {
        return;
      }

      forOwn(
        Object.assign({}, rowObj?.childLinks),
        (childColId, childDocId) => {
          Object.assign(childLinksToUpdate, {
            [childDocId]: Object.assign({}, childLinksToUpdate[childDocId], {
              [childColId]: omit(currentRowData?.[colId], ['childLinks']),
            }),
          });
        },
      );
    }
  });

  return childLinksToUpdate;
};

const getColumnDependencyMapping = ({
  tableColIds,
  headerDataAsObj,
  primaryColumnMapping,
}) => {
  const response = {
    dependencyObj: {},
    dependencyArrObj: {},
  };
  try {
    const tableIds =
      tableColIds && isArray(tableColIds) && tableColIds.length
        ? tableColIds.reverse().slice()
        : [];

    const dependencyObj = {};
    let dependencyArrObj = {};

    for (let i = 0; i < tableIds.length; i++) {
      const headerObjDependent = headerDataAsObj[tableIds[i]];
      if (
        !headerObjDependent?.columnProperties?.[
          COLUMN_PROPERTY_KEYS.IS_DEPENDENT_COLUMN
        ]
      ) {
        continue;
      }

      for (let j = i + 1; j < tableIds.length; j++) {
        const headerObj = headerDataAsObj[tableIds[j]];
        const headerObjLinkedMetaKeys = Object.keys?.(
          headerObj?.linkedMeta ?? {},
        );

        if (
          (!headerObj?.columnProperties?.[
            COLUMN_PROPERTY_KEYS.IS_DEPENDENT_COLUMN
          ] &&
            primaryColumnMapping[headerObjLinkedMetaKeys[0]] === headerObj.id &&
            Object.keys(headerObjDependent?.linkedMeta).some((item) =>
              headerObjLinkedMetaKeys.includes(item),
            )) ||
          (headerObj?.columnProperties?.[
            COLUMN_PROPERTY_KEYS.IS_DEPENDENT_COLUMN
          ] &&
            Object.keys(headerObjDependent?.linkedMeta).some((item) =>
              headerObjLinkedMetaKeys.includes(item),
            ))
        ) {
          dependencyObj[headerObjDependent.id] = headerObj.id;

          if (
            !isEmpty(dependencyArrObj) &&
            headerObjLinkedMetaKeys[0] in dependencyArrObj
          ) {
            const dependentArrItems =
              dependencyArrObj[headerObjLinkedMetaKeys[0]];
            if (!dependentArrItems.includes(headerObjDependent.id)) {
              dependentArrItems.push(headerObjDependent.id);
            }
            dependentArrItems.push(headerObj.id);
            dependencyArrObj[headerObjLinkedMetaKeys[0]] = dependentArrItems;
          } else {
            dependencyArrObj = {
              ...dependencyArrObj,
              [headerObjLinkedMetaKeys[0]]: [
                headerObjDependent.id,
                headerObj.id,
              ],
            };
          }
          break;
        } else {
          continue;
        }
      }
    }

    if (!isEmpty(dependencyArrObj)) {
      for (const parentDocId in dependencyArrObj) {
        dependencyArrObj[parentDocId] =
          dependencyArrObj[parentDocId]?.reverse();
      }
    }

    response.dependencyObj = dependencyObj;
    response.dependencyArrObj = dependencyArrObj;
    //empty dependency checks are removed here incase the dependency have to be cleared while updating
  } catch (error) {
    captureError(error);
  }

  return response;
};

const searchStringTableLinkingUtil = async (
  str,
  linkedData,
  userPref,
  extra = {
    parentDocId: null,
    isDependency: false,
    filteredData: [],
    isAssignTaskColumn: false,
    hasTableLinkFilters: false,
    fieldType: null,
    userPref: {
      country: 'IN',
      lang: 'EN',
    },
    parentHeaderData: [],
    isOrganisationMode: false,
    shouldSearchRowPayload: false,
    isPaginated: false,
    searchAfter: null,
  },
) => {
  try {
    // TODO : Set is `searchActive` and `isLoading` to true
    const isDependency = extra?.isDependency ?? false;
    const filteredData = extra?.filteredData ?? [];
    const isAssignTaskColumn = extra?.isAssignTaskColumn ?? false;
    const hasTableLinkFilters = extra?.hasTableLinkFilters ?? false;
    const {isPaginated, searchAfter, isOrganisationMode} = extra;

    if (isDependency || hasTableLinkFilters) {
      const searchString = `${str}`.toLowerCase();
      let searchFilterData;

      if (filteredData.length) {
        if (hasTableLinkFilters) {
          //Changed here -- Whole Header Search
          searchFilterData = searchStringTableLinkHelper({
            rowsArr: filteredData,
            searchText: searchString,
            userPref,
            isOrganisationMode: extra?.isOrganisationMode,
            headerData: extra?.parentHeaderData,
          });
        } else if (isDependency) {
          //Changed here -- Whole Header Search
          if (extra.shouldSearchRowPayload) {
            searchFilterData = searchStringTableLinkHelper({
              rowsArr: filteredData,
              searchText: searchString,
              userPref,
              isOrganisationMode: extra?.isOrganisationMode,
              headerData: extra?.parentHeaderData,
            });
          } else if (isAssignTaskColumn) {
            searchFilterData = filteredData.filter((item) => {
              const val = item?.data?.val ?? {};
              const valueInData = !isEmpty(val)
                ? `${val.assignee?.name} ${val?.assignee?.contact}`.toLowerCase()
                : '';
              return valueInData.includes(searchString);
            });
          } else {
            searchFilterData = filteredData.filter((item) => {
              const val = item.data?.val.toLowerCase();
              return val.includes(searchString);
            });
          }
        }
      } else {
        // TODO : Return `objToSend` and set `isLoading` to false
        return {tableData: []};
      }

      const tableData = [];
      searchFilterData.forEach((item) => {
        const obj = {
          [linkedData?.colId]: item?.data,
          rowId: item?.rowId ?? null,
          rowIndex: item?.rowIndex ?? null,
          rowObj: item?.rowObj ?? item ?? {},
        };
        tableData.push(obj);
      });

      // TODO : Return `objToSend` and set `isLoading` to false
      return {tableData};
    } else {
      const obj = {
        textToSearch: str,
        docId: extra?.parentDocId,
        columnId: extra?.colId ? extra.colId : linkedData?.colId,
        columnObj: extra?.columnObj,
        isPaginated,
        ...(searchAfter ? {searchAfter} : {}),
      };

      Object.assign(obj, {apiVersion: '1'});

      const response = await callCloudFunction(
        isOrganisationMode
          ? CLOUD_FUNCTION_PATHS.TABLE_LINKING_SEARCH_ORG
          : CLOUD_FUNCTION_PATHS.TABLE_LINKING_SEARCH,
        obj,
        null,
        CLOUD_FUNCTION_COMMON_PARAMS.ELASTIC_REQUEST,
      );

      // TODO : Return `objToSend` and set `isLoading` to false
      return {tableData: response.response?.tableData};
    }

    // TODO : Set `isLoading` to false
  } catch (error) {
    captureError(error);
    return {tableData: []};
  }
};

const autoFillParentValueUtil = ({
  parentDocId,
  parentRowId,
  rowValues,
  autoFillLinkedFiles, //
}) => {
  try {
    const currAutoFillLinkedFiles = !isEmpty(autoFillLinkedFiles)
      ? autoFillLinkedFiles
      : {};
    const updatedParentMeta = {};
    const updatedRowValues = {};
    Object.keys(currAutoFillLinkedFiles).forEach((parentColId) => {
      const childColId = currAutoFillLinkedFiles?.[parentColId];
      if (!isEmpty(rowValues?.[parentColId])) {
        const parentMeta = {
          colId: parentColId,
          docId: parentDocId,
          rowId: parentRowId,
        };
        updatedParentMeta[childColId] = parentMeta;
        if (
          rowValues?.[parentColId]?.[
            TABLE_LINK_OVERWRITE_RELATED_KEYS.IS_VALUE_OVERRIDEN
          ]
        ) {
          // If parent value is overriden
          // remove overriden flag and
          // previousCellObject to make sure it doesn't pass through
          // to child row and add a new flag to denote
          // parentColumn's value was overrriden one
          updatedRowValues[childColId] = Object.assign(
            {},
            omit(rowValues?.[parentColId], [
              TABLE_LINK_OVERWRITE_RELATED_KEYS.IS_VALUE_OVERRIDEN,
              TABLE_LINK_OVERWRITE_RELATED_KEYS.PREVIOUS_CELL_OBJECT,
            ]),
            {
              [TABLE_LINK_OVERWRITE_RELATED_KEYS.IS_PARENT_VALUE_OVERRIDEN]: true,
            },
          );
        } else {
          updatedRowValues[childColId] = rowValues?.[parentColId];
        }
      }
    });

    return {
      parentMeta: updatedParentMeta,
      rowValues: updatedRowValues,
    };
  } catch (error) {
    captureError(error);
  }
};

const getParentFileUniqueDataWithFilterUtil = async ({
  filters = [],
  isWithRowIds = false,

  // NECESSARY
  parentColId = null,
  parentDocId = null,
  childColObj = {},
  userPref = {},
  userUid = null,
  activeCustomRoleInfo = {},
  activeAppId = null,
  miniAppsReduxState = {},
}) => {
  try {
    const userTimezone = userPref?.timezone ?? 'Asia/Kolkata';
    const fieldType = childColObj?.subType;
    const screens = miniAppsReduxState.miniApps?.[activeAppId]?.screens ?? [];
    let screenIdForDoc = null;
    for (const screenId in screens) {
      if (
        screens?.[screenId]?.type !== MINI_APPS.SCREEN_TYPE.DASHBOARD &&
        screens?.[screenId]?.docs?.[0]?.docId === parentDocId
      ) {
        screenIdForDoc = screenId;
        break;
      }
    }

    const [isCustomViewAccess, viewAccess] = isCustomRoleFilteredScreen(
      miniAppsReduxState[activeAppId],
      userUid,
      screenIdForDoc,
      activeCustomRoleInfo,
    );

    const payload = {
      filters,
      userTimezone,
      docId: parentDocId,
      columnId: parentColId,
      fieldType,
      isWithRowIds,
      ...(isCustomViewAccess ? {viewAccess} : {}),
    };

    const uniqueData =
      await tableLinksActionHelper.getUniqueDataForParentColWithElastic(
        payload,
      );
    return Object.assign({}, uniqueData);
  } catch (error) {
    captureError(error);
  }
};

const fetchParentDocRowsForTableLink = async ({
  isInitialFetch = false,
  documentId = null,
  forceFetch = false,
  paginationLimit = null,
  isChangeAssignee = false,

  prevAreAllParentDocumentRowsFetched = {},
  prevParentFileDocData = {},
}) => {
  let areAllParentDocumentRowsFetched = prevAreAllParentDocumentRowsFetched;

  let parentFileDocData = Object.assign({}, prevParentFileDocData);

  try {
    if (!documentId) {
      return {
        areAllParentDocumentRowsFetched,
        parentFileDocData,
      };
    }

    if (isInitialFetch) {
      const docData = await DocumentsMethods.getUserDocumentDataWithoutRows(
        documentId,
      );
      const docTableData = await DocumentsMethods.getRowsData({
        docId: documentId,
        isLimited: true,
        limit:
          isChangeAssignee && !isNil(paginationLimit) ? paginationLimit : 50,
      });

      const tableData = tableActionHelper.processRowsData(
        docTableData,
        null,
        [],
        null,
      );

      const rowIdDataMap = {};

      if (Array.isArray(tableData)) {
        tableData.forEach((row) => {
          if (!row?.rowId) return;
          rowIdDataMap[row.rowId] = row;
        });
      }

      parentFileDocData = {
        headerData: docData.headerData,
        fileObj: docData.fileObj,
        footerData: docData.footerData,
        tableData,
        rowIdDataMap,
      };
    } else if ((documentId && !areAllParentDocumentRowsFetched) || forceFetch) {
      const {tableData = []} = parentFileDocData ?? {};
      const lastSortIndex = tableData?.[tableData.length - 1]?.index;

      if (
        (!isNil(lastSortIndex) && isChangeAssignee) ||
        (!isNil(lastSortIndex) && !isChangeAssignee)
      ) {
        const docTableData = await DocumentsMethods.getRowsData({
          docId: documentId,
          lastFetchedIndex: lastSortIndex,
          isLimited: true,
          limit: !isNil(paginationLimit) ? paginationLimit : 50,
        });

        if (
          (isChangeAssignee &&
            !isNil(paginationLimit) &&
            docTableData.docs.length < paginationLimit) ||
          (!isChangeAssignee && docTableData.docs.length < 50)
        ) {
          areAllParentDocumentRowsFetched = true;
        }

        if (docTableData.docs.length) {
          parentFileDocData.tableData = tableActionHelper.processRowsData(
            docTableData,
            null,
            tableData,
            null,
          );
          const rowIdDataMap = {};
          if (Array.isArray(parentFileDocData.tableData)) {
            parentFileDocData.tableData.forEach((row) => {
              if (!row.rowId) return;

              rowIdDataMap[row.rowId] = row;
            });
            parentFileDocData.rowIdDataMap = rowIdDataMap;
          }
        }
      }
    }
  } catch (error) {
    captureError(error);
  }

  return {
    areAllParentDocumentRowsFetched,
    parentFileDocData,
  };
};

const onTableLinkSelectValueUtils = async ({
  hasDependency = false,
  value: selectedValue,
  parentRowId,
  isSearchedVal,

  // isFromRowEdit,
  // isQuickEntry,
  // itemRowIndex,
  // alreadyCreatedParentRowMeta,
  // miniAppsHeaderData = [],
  // isMiniApps = false,
  // headerIndexMapping = {},

  // NECESSARY
  selectedParentRowData = {},
  primaryColumnMapping = {},

  // Necessary for Table Linking
  activeDocId = null,
  childColObj = {},
  childRowObj = {},
  childHeaderData = {},
  linkedData = {},
  parentDocId = null,
  autoFillLinkedFilesObj = {}, // { [colId] : "colId" }

  isSearchActive = false, // TODO :  (searchText?.length > 0)
  searchTableData = [],
  columnDependencyMappingObj = {dependencyObj: {}, dependencyArrObj: {}}, // {dependencyObj: {}, dependencyArrObj: {}}
}) => {
  try {
    //miniApps Condition for table linking
    const autoFillLinkedFiles = autoFillLinkedFilesObj;
    if (isEmpty(autoFillLinkedFiles)) {
      return {
        parentMeta: {},
        rowValues: {},
      };
    }

    // Pre-Requisite
    const headerMapping = getHeaderTypeMapping(childHeaderData);
    const {
      dependencyObj: columnDependencyMapping,
      dependencyArrObj: columnDependencyArrObj,
    } = columnDependencyMappingObj ?? {};
    const columnDependencyArr =
      columnDependencyArrObj?.[parentDocId]?.slice() ?? [];
    const childEditColId = childColObj.id;
    const currentRowValues = {...childRowObj};

    const obj = {};
    const updatedParentMeta = {};
    let parentRowData = {};
    let initialValue = selectedValue;
    // if (isEqual(value, {})) {
    //   obj[childEditColId] = {};
    // }

    if (
      childEditColId === columnDependencyArr[columnDependencyArr?.length - 1] &&
      parentRowId
    ) {
      parentRowData = await tableLinksActionHelper
        .getParentRowDataFirestore(parentDocId, parentRowId)
        .then((res) => (res.exists ? res.data() : {}))
        .catch(() => ({}));
    }

    Object.keys(autoFillLinkedFiles).forEach((parentColId) => {
      const childColId = autoFillLinkedFiles[parentColId];

      const keysForColumnDependencyMapping =
        Object.keys(columnDependencyMapping ?? {}).slice() ?? [];

      if (hasDependency) {
        if (
          ([
            ...keysForColumnDependencyMapping,
            ...Object.values(columnDependencyMapping),
          ].includes(childColId) &&
            childColId !== childEditColId) ||
          (childEditColId !==
            columnDependencyArr[columnDependencyArr?.length - 1] &&
            Object.keys(headerMapping[childColId]?.linkedMeta)?.includes(
              parentDocId,
            ) &&
            !headerMapping[childColId]?.columnProperties?.[
              COLUMN_PROPERTY_KEYS.IS_DEPENDENT_COLUMN
            ] &&
            !isEmpty(primaryColumnMapping) &&
            childColId !== primaryColumnMapping[parentDocId])
        ) {
          return;
        } else if (!isEmpty(parentRowData) && !isEmpty(parentRowData?.rowObj)) {
          initialValue = parentRowData?.rowObj?.[parentColId];
          if (!initialValue) {
            return;
          }
        }
      }

      let currentCellValues = {};

      if (headerMapping?.[childColId]?.fieldType === FIELD_TYPE_ID.TABLE) {
        let autoFillValRow = {};

        if (isSearchedVal) {
          const index = searchTableData.findIndex(
            (item) => item.rowId === parentRowId,
          );
          if (index !== -1) {
            const responseObj = searchTableData[index];
            autoFillValRow = responseObj?.rowObj;
          }
        }

        let parentTableValue =
          isSearchedVal && isSearchActive && searchTableData?.length > 0
            ? autoFillValRow?.[parentColId]
            : !isEmpty(selectedParentRowData)
            ? selectedParentRowData?.[parentColId]
            : initialValue;

        parentTableValue = omit(parentTableValue, ['childLinks']);
        initialValue = omit(initialValue, ['childLinks']);

        const addVal =
          childColId !== childEditColId
            ? isSearchedVal
              ? {...parentTableValue, val: parentTableValue.val}
              : parentTableValue
            : initialValue;

        if (
          !isEmpty(currentRowValues) &&
          !isEmpty(currentRowValues[childColId]) &&
          parentDocId === currentRowValues[childColId]?.parentMeta?.docId &&
          parentRowId === currentRowValues[childColId]?.parentMeta?.rowId
        ) {
          currentCellValues = currentRowValues[childColId];
        }

        const parentMeta = {
          colId: childColId !== childEditColId ? parentColId : linkedData.colId,
          docId: parentDocId,
          rowId: parentRowId,
        };

        if (
          (hasDependency && !isEmpty(currentRowValues[childColId])) ||
          !isEmpty(initialValue)
        ) {
          obj[childColId] = Object.assign(
            {},
            currentCellValues,
            addVal ? addVal : {},
            {parentMeta: parentMeta},
          );
        }

        updatedParentMeta[childColId] = parentMeta;

        if (
          keysForColumnDependencyMapping.includes(childColId) &&
          columnDependencyArr[columnDependencyArr.length - 1] === childColId
        ) {
          columnDependencyArr.forEach((colId) => {
            if (colId !== childColId) {
              const updateDataNew = {
                colId: headerMapping[colId]?.linkedMeta?.[parentDocId]?.colId,
                docId: parentDocId,
                rowId: parentRowId,
              };
              updatedParentMeta[colId] = Object.assign({}, updateDataNew);
            }
          });
        }

        logAnalyticsEvent('TABLE_LINK_VALUE_ADDED', {
          docId: activeDocId,
          colId: childColId,
          linkDocId: parentDocId,
          linkColId: parentColId,
        });
      }
    });

    // const rowValues = Object.assign({}, currentRowValue, obj);

    // TODO : Handle Navigation Back on Select
    return {
      parentMeta: updatedParentMeta,
      rowValues: obj,
    };
  } catch (error) {
    captureError(error);
    return {};
  }
};

const getHeaderDataOfLinkedParent = (
  parentHeaderData = [],
  activeParentAutoFillLinkedFiles = {},
  linkedColShouldBeFirstCol,
  childColObj = {},
  shouldApplyRestriction = false,
  state = {},
) => {
  let linkedParentHeaderData = [...parentHeaderData];
  if (shouldApplyRestriction) {
    const parentDocId = Object.keys(childColObj?.linkedMeta ?? {})?.[0];
    const firstParentScreenId = getScreensListFromDocIds(
      state,
      [parentDocId],
      false,
    )?.[0]?.screenId;
    if (firstParentScreenId) {
      const {
        documentData: {headerData},
      } = mapMiniAppStates(state, null, null, firstParentScreenId); // hide cols from field visibility + custom roles and permissions
      linkedParentHeaderData = [...headerData];
    }
  }

  if (linkedColShouldBeFirstCol) {
    const parentDocId = Object.keys(childColObj?.linkedMeta ?? {})?.[0];
    const {colId: linkedParentColId} = Object.assign(
      {},
      childColObj?.linkedMeta?.[parentDocId],
    );
    const firstParentColIdx = linkedParentHeaderData?.findIndex(
      (parentColObj) => parentColObj?.id === linkedParentColId,
    );

    if (firstParentColIdx !== -1) {
      const firstParentColObj = linkedParentHeaderData[firstParentColIdx];
      linkedParentHeaderData?.splice(firstParentColIdx, 1);
      linkedParentHeaderData?.unshift(firstParentColObj);
    }
  }

  return linkedParentHeaderData?.filter?.(
    (headerObj) =>
      activeParentAutoFillLinkedFiles?.[headerObj?.id] && // only showing linked columns
      !NOT_ALLOWED_COLUMNS_TABLE_LINK_MODAL.includes(
        getColumnFieldType(headerObj),
      ) &&
      !isBackgroundField(headerObj),
  );

  // positioning the linked parent col of the child col on first place for table link value selection modal
};

const getParentDocHeaderIndexMapping = (parentHeaderData = []) => {
  const parentDocHeaderIndexMapping = {};
  for (let i = 0; i < parentHeaderData.length; i++) {
    parentDocHeaderIndexMapping[parentHeaderData[i].id] = i;
  }
  return parentDocHeaderIndexMapping;
};

const getFiltersDependentCol = ({
  childColId,
  filters = [],
  childRowData = {},
  activeParentDoc,
  autoFillLinkedFiles,
  columnDependencyMapping,
}) => {
  try {
    const colIdToCheck = columnDependencyMapping?.[childColId];
    const val = childRowData?.[colIdToCheck]?.val ?? null;

    const flipObject = (data = {}) => {
      return Object.fromEntries(
        Object.entries(data).map(([key, value]) => [value, key]),
      );
    };

    const flippedAutoFillLinkedFiles = flipObject(
      autoFillLinkedFiles?.[activeParentDoc?.docId] ?? {},
    );
    const parentColId = flippedAutoFillLinkedFiles?.[colIdToCheck];

    const parentHeaderObj = activeParentDoc?.docData?.headerData?.find(
      (headerObj) => headerObj.id === parentColId,
    );

    const parentColFieldType = getColumnFieldType(parentHeaderObj);
    const isAssignTaskColumn = parentColFieldType === FIELD_TYPE_ID.ASSIGN_TASK;

    if (!isNil(val)) {
      filters.push({
        colId: parentColId,
        selectedOptions: TIME_BASED_RANGE_FILTER_FIELDS.includes(
          parentColFieldType,
        )
          ? [
              convertCellDataToText(
                childRowData?.[colIdToCheck],
                parentHeaderObj,
              ),
              convertCellDataToText(
                childRowData?.[colIdToCheck],
                parentHeaderObj,
              ),
            ]
          : RANGE_FILTER_FIELDS.includes(parentColFieldType)
          ? [val, val]
          : isAssignTaskColumn
          ? [null, [val?.assignee?.uid], null, null]
          : [val],
        fieldType: parentColFieldType,
        ...(isAssignTaskColumn ? {isCustom: true} : {}),
      });
      if (colIdToCheck in columnDependencyMapping) {
        return getFiltersDependentCol({
          childColId: colIdToCheck,
          filters: filters?.slice(),
          childRowData,
          activeParentDoc,
          autoFillLinkedFiles,
          columnDependencyMapping,
        });
      }
    }
    return filters?.slice();
  } catch (error) {
    captureError(error);
    return [];
  }
};

const getFilteredTableDataForLastDependentColumn = async ({
  uniqueRowIds = [],
  parentDocId = null,
}) => {
  const promises = [];
  uniqueRowIds.forEach((rowId) => {
    promises.push(
      (async () => {
        const res = await tableLinksActionHelper.getParentRowDataFirestore(
          parentDocId,
          rowId,
        );
        return res.data();
      })(),
    );
  });

  try {
    return await Promise.all(promises)?.then((result) => {
      return result?.reduce?.((rows, data, index) => {
        rows.push({
          rowId: uniqueRowIds[index],
          ...data?.rowObj,
        });
        return rows;
      }, []);
    });
  } catch (error) {
    console.error(error);
    return [];
  }
};

export {
  getSubmitUndoRedoActions,
  generateParentRowMeta,
  getTableLinkColumnUpdateUndoRedoActions,
  getTableLinkDeleteFileUndoRedoActions,
  childLinkDataFormat,
  filterTableDataBasedOnDataViewAccess,
  checkAndUpdatePrimaryColumnMapping,
  getRowdataForAutofillInChild,
  getChildLinksToUpdate,
  getColumnDependencyMapping,
  fetchParentDocRowsForTableLink,
  searchStringTableLinkingUtil,
  autoFillParentValueUtil,
  getParentFileUniqueDataWithFilterUtil,
  onTableLinkSelectValueUtils,
  getHeaderDataOfLinkedParent,
  getParentDocHeaderIndexMapping,
  getFiltersDependentCol,
  getFilteredTableDataForLastDependentColumn,
};
