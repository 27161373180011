/* eslint-disable require-jsdoc */
/**
 * https://stackoverflow.com/a/49642895/9757211
 * https://stackoverflow.com/a/28755126/9757211
 */

const getComparisionFunction = (comparator, isDesc = false) => {
  const defaultCompare = (a, b) =>
    (isDesc ? -1 : 1) * (a < b ? -1 : a > b ? 1 : 0);
  if (Array.isArray(comparator)) {
    return (a, b) => {
      for (const keyToCompare of comparator) {
        const currentKeyComparisionVal = defaultCompare(
          a?.[keyToCompare],
          b?.[keyToCompare],
        );
        if (currentKeyComparisionVal === 0) {
          continue;
        }
        return currentKeyComparisionVal;
      }
      return 0; //perfect match
    };
  }
  return typeof comparator === 'function' ? comparator : defaultCompare;
};

class LioArray extends Array {
  constructor(x, getValueMethod, setValueMethod) {
    super();
    const elements = Array.isArray(x) ? x : [];
    this.push(...elements);
    this.getValueAtIndex =
      typeof getValueMethod === 'function'
        ? (index) => getValueMethod(this[index])
        : (index) => this[index]; //use this method to get value from the array at a given index
    this.setValueAtIndex =
      typeof setValueMethod === 'function' ? setValueMethod : (val) => val; //while replacing/splicing a new value, use this method to set the value
  }

  /*
    target: the object to search for in the array
    comparator: (optional) a method for comparing the target object type (array of strings for nested object!)
    return value: index of a matching item in the array if one exists, otherwise the bitwise complement of the index where the item belongs
  */
  binarySearch(target, comparator, isDesc = false) {
    let l = 0,
      h = this.length - 1,
      m,
      comparison;

    comparator = getComparisionFunction(comparator, isDesc);
    while (l <= h) {
      m = (l + h) >>> 1; /* equivalent to Math.floor((l + h) / 2) but faster */
      comparison = comparator(this.getValueAtIndex(m), target);
      if (comparison < 0) {
        l = m + 1;
      } else if (comparison > 0) {
        h = m - 1;
      } else {
        return m; //found at m
      }
    }
    return ~l;
  }

  /*
    target: the object to insert into the array
    duplicate: (optional) whether to insert the object into the array even if a matching
                          object already exists in the array (null by default) one of ["duplicate","replace"]
    comparator: (optional) a method for comparing the target object type (array of strings for nested object!)
    return value: the index where the object was inserted into the array, or the index of a matching object in the array if a match was found and the duplicate parameter was false
   */
  binaryInsert(target, comparator, duplicate = null, isDesc = false) {
    let i = this.binarySearch(target, comparator, isDesc);
    let isDuplicate = false;
    if (i >= 0) {
      /* if the binarySearch return value was zero or positive, a matching object was found */
      isDuplicate = true;
      if (!['duplicate', 'replace'].includes(duplicate)) {
        return i;
      }
    } else {
      /* if the return value was negative, the bitwise complement of the return value is the correct index for this object */
      i = ~i;
    }
    this.splice(
      i,
      isDuplicate && duplicate === 'replace' ? 1 : 0,
      this.setValueAtIndex(target),
    );
    return i;
  }

  /*
    target: the object to insert into the array
    comparator: (optional) a method for comparing the target object type (array of strings for nested object!)
    return value: the index from where the object was deleted from the array, or -1 if the object was not found
   */
  binaryDelete(target, comparator, isDesc = false) {
    const i = this.binarySearch(target, comparator, isDesc);
    if (i >= 0) {
      /* if the binarySearch return value was zero or positive, a matching object was found */
      this.splice(i, 1);
      return i;
    }
    return -1;
  }
}

export default LioArray;
