import {TEMPLATE_ACTION} from '../actions/actionType';

const initialState = {
  defaultTemplateCategory: [],
  templateList: [],
  currentTemplateDetails: {},
  recentlyUsedTemp: [],
  businessTemplate: [],
  preferredTempCatgs: [],
  smartTemplateCategoryIndex: null,
  exploreMoreTemplateCategory: [],
  exploreMoreTemplateList: null,
};

const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEMPLATE_ACTION.SET_RECENTLY_USED_TEMPLATES:
      return {
        ...state,
        recentlyUsedTemp: action.payload,
      };
    case TEMPLATE_ACTION.SET_CURRENT_TEMP_DETAILS:
      return {
        ...state,
        currentTemplateDetails: action.payload,
      };
    case TEMPLATE_ACTION.CLEAR_CURRENT_TEMP_DETAILS:
      return {
        ...state,
        currentTemplateDetails: {},
      };
    case TEMPLATE_ACTION.LOAD_TEMPLATES_CATEGORY:
      return {
        ...state,
        defaultTemplateCategory: action.payload.defaultTemp,
        businessTemplate: action.payload.businessTemp
          ? action.payload.businessTemp
          : [],
        exploreMoreTemplateCategory: action.payload.exploreMoreCategoryArr,
      };
    case TEMPLATE_ACTION.LOAD_EXPLORE_MORE_TEMPLATES_CATEGORY:
      return {
        ...state,
        exploreMoreTemplateCategory: action.payload.exploreMoreCategoryArr,
      };
    case TEMPLATE_ACTION.SET_PREFERRED_TEMPLATE_CATGS:
      return {
        ...state,
        preferredTempCatgs: action.payload,
      };
    case TEMPLATE_ACTION.LOAD_TEMPLATE_LIST:
      return {
        ...state,
        templateList: action.payload,
      };
    case TEMPLATE_ACTION.LOAD_EXPLORE_TEMPLATE_LIST:
      return {
        ...state,
        exploreMoreTemplateList: action.payload,
      };
    case TEMPLATE_ACTION.UPDATE_SMART_TEMPLATE_CATEGORY_INDEX:
      return {
        ...state,
        smartTemplateCategoryIndex: action.payload,
      };
    case TEMPLATE_ACTION.RESET_TEMPLATE_LIST:
      return {
        ...state,
        templateList: [],
      };
    case TEMPLATE_ACTION.RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default templateReducer;
