import {isEmpty} from 'lodash';
import moment from 'moment';
import FirestoreDB from '../../FirestoreHandlers/FirestoreDB';
import {captureError, captureInfo, firestore} from '../../imports';
import {AVAILABLE_ENTITLEMENTS} from '../../utils/premium';
import {callCloudFunction, serializeError} from '../../utils/utils';
import {AUTH_ACTION} from '../actionType';
import {updateMultiLoginModalVisibility} from '../authAction';
import {CLOUD_FUNCTION_PATHS} from '../../utils/constant';

/**
 * Create Firebase Document reference for Provided uid
 * @param {string} uid user id
 * @returns Firebase Document reference
 */
const createUserDocumentRef = (uid, firestoreInstance = null) => {
  firestoreInstance = firestoreInstance ?? firestore;
  return firestoreInstance().collection('users').doc(uid);
};

/**
 * Create Firebase Document reference for Provided uid
 * @param {string} uid user id
 * @returns Firebase Document reference
 */
const createDeactivatedAccountsDocumentRef = (uid) => {
  return firestore().collection('deactivatedAccounts').doc(uid);
};

/**
 * Create Firebase UserDevices Document reference for Provided uid
 * @param {string} uid user id
 * @returns Firebase Document reference
 */
const createUserDataDevicesDocumentRef = (uid, firestoreInstance = null) => {
  firestoreInstance = firestoreInstance ?? firestore;
  return firestoreInstance()
    .collection('users')
    .doc(uid)
    .collection('userData')
    .doc('devices');
};

/**
 * Firebase Set userData/devices to provided uid
 * @param {string} uid user id
 * @param {object} obj set object
 * @returns Firebase Set Response
 */
const setUserUserDataDevicesDocumentRef = (uid, obj, merge = true) => {
  return createUserDataDevicesDocumentRef(uid).set(
    obj,
    merge ? {merge: true} : {},
  );
};

/**
 * Firebase Set Deactivaed Account Details to provided uid
 * @param {string} uid user id
 * @param {object} obj set object
 * @returns Firebase Set Response
 */
const setDeactivatedAccountDocumentRef = (uid, obj, options = {}) => {
  const {merge = true} = options ?? {};
  return createDeactivatedAccountsDocumentRef(uid).set(
    obj,
    merge ? {merge: true} : {},
  );
};

/**
 * Firebase Set Deactivaed Account Details to provided uid
 * @param {string} uid user id
 * @param {object} obj set object
 * @returns Firebase Set Response
 */
const deleteDeactivatedAccountDocumentRef = (uid) => {
  return createDeactivatedAccountsDocumentRef(uid).delete();
};

/**
 * Firebase Update userData/devices to provided uid
 * @param {string} uid user id
 * @param {object} obj set object
 * @returns Firebase Set Response
 */
const updateUserUserDataDevicesDocumentRef = (uid, obj) => {
  return createUserDataDevicesDocumentRef(uid).update(obj);
};

/**
 * Firebase Get Data of userData/devices to provided uid
 * @param {string} uid user id
 * @returns Firebase Set Response
 */
const getUserUserDataDevicesDocumentRef = (uid) => {
  return createUserDataDevicesDocumentRef(uid).get();
};

/**
 * Firebase Update data to provided uid
 * @param {string} uid user id
 * @param {object} obj update object
 * @returns Firebase Update Response
 */
const updateUserDocument = (uid, obj, firestoreInstance = null) => {
  try {
    return createUserDocumentRef(uid, firestoreInstance).update(obj);
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

/**
 * Firebase Set data to provided uid
 * @param {string} uid user id
 * @param {object} obj set object
 * @returns Firebase Set Response
 */
const setUserDocument = (uid, obj, merge = true) => {
  try {
    return createUserDocumentRef(uid).set(obj, merge ? {merge: true} : {});
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

/**
 * Firebase Get data for provided uid
 * @param {string} uid user id
 * @returns Firebase Get Response
 */
const getUserDocument = (uid) =>
  createUserDocumentRef(uid)
    .get()
    .then((dataSnap) => Promise.resolve(dataSnap.exists ? dataSnap.data() : {}))
    .catch((err) => Promise.reject(err));

const checkIfContactIsRegistered = (contact) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.CHECK_IF_CONTACT_IS_REGISTERED, {
    contact: `${contact}`,
  });

const onUserPrefChanges = (
  devicesObj,
  deviceId,
  options,
  dispatchThis,
  getState,
) => {
  try {
    const {
      auth: {devices: authDevicesObj, user},
      home: {isLoggingOut = false},
      premium: {subscriptions = [], activePlanType = ''},
      remoteConfig: {isOrganisationMode},
    } = getState();

    if (isLoggingOut || !user?.uid) {
      return;
    }

    const devices = Object.assign({}, devicesObj);
    const current_devices_count = Object.keys(devices).length;
    const timestamp = moment().utc().valueOf();

    const deviceData = {
      lastLoginTimestamp: timestamp,
    };

    const upto_2_devices_enabled =
      subscriptions.includes(AVAILABLE_ENTITLEMENTS.UPTO_2_MULTILOGIN) ||
      subscriptions.includes(AVAILABLE_ENTITLEMENTS.MULTI_DEVICE_LOGIN)
        ? true
        : false;

    const unlimited_devices_enabled =
      isOrganisationMode ||
      subscriptions.includes(
        AVAILABLE_ENTITLEMENTS.MULTI_DEVICE_LOGIN_UNLIMITED,
      )
        ? true
        : false;

    if (
      !unlimited_devices_enabled &&
      (upto_2_devices_enabled ? current_devices_count.length === 2 : true) &&
      !isEmpty(authDevicesObj) &&
      !isEmpty(devices) &&
      deviceId in authDevicesObj &&
      !(deviceId in devices)
    ) {
      options?.handleLogout?.();
      return;
    }

    if (isEmpty(devices)) {
      // Update to Firestore after adding 1st device
      Object.assign(devices, {[deviceId]: deviceData});

      authActionHelper.setUserUserDataDevicesDocumentRef(user.uid, {devices});
    } else if (!unlimited_devices_enabled && !upto_2_devices_enabled) {
      if (!(deviceId in devices)) {
        dispatchThis(updateMultiLoginModalVisibility(true));
        return;
      }
    } else if (!unlimited_devices_enabled && upto_2_devices_enabled) {
      if (!(deviceId in devices)) {
        if (current_devices_count < 2) {
          // Update to Firestore after adding 1st device
          Object.assign(devices, {[deviceId]: deviceData});

          authActionHelper.updateUserUserDataDevicesDocumentRef(user.uid, {
            devices,
          });
        } else if (current_devices_count === 2) {
          dispatchThis(updateMultiLoginModalVisibility(true));
          return;
        }
      }
    } else if (unlimited_devices_enabled) {
      if (!(deviceId in devices)) {
        // Update to Firestore after adding 1st device
        Object.assign(devices, {[deviceId]: deviceData});

        authActionHelper.updateUserUserDataDevicesDocumentRef(user.uid, {
          devices,
        });
      }
    }
    dispatchThis({
      type: AUTH_ACTION.LOAD_USER_ACTIVE_DEVICES,
      payload: devices,
    });
  } catch (err) {
    captureInfo({
      err: serializeError(err),
      devicesObj,
    });
    captureError(new Error('Error in activateLoginDevicesListener'));
  }
};

const setBussinessProfileCollection = (
  businessSetupObj,
  uid,
  firestoreInstance = null,
) => {
  return FirestoreDB.business
    .collection(firestoreInstance)
    .doc(uid)
    .set(businessSetupObj, {merge: true});
};

export const authActionHelper = {
  createUserDocumentRef,
  getUserDocument,
  setUserDocument,
  updateUserDocument,
  createUserDataDevicesDocumentRef,
  checkIfContactIsRegistered,
  getUserUserDataDevicesDocumentRef,
  setUserUserDataDevicesDocumentRef,
  updateUserUserDataDevicesDocumentRef,
  onUserPrefChanges,
  setDeactivatedAccountDocumentRef,
  deleteDeactivatedAccountDocumentRef,
  setBussinessProfileCollection,
};
