import {firestore, captureError} from '../../../imports';

export const addEntryForFlipkartCron = (uid, docId, lastSyncTimestamp = '') => {
  try {
    firestore()
      .collection('flipkartFiles')
      .doc(uid)
      .set(Object.assign({}, {FLIP_ORDER: docId}), {merge: true});
  } catch (error) {
    captureError(error);
  }
};

export const removeEntryForFlipkartCron = (uid) => {
  try {
    firestore()
      .collection('flipkartFiles')
      .doc(uid)
      .update({FLIP_ORDER: firestore(true).FieldValue.delete()});
    clearDataFromOpenOrders(uid);
    clearDataFromCloseOrders(uid);
  } catch (error) {
    captureError(error);
  }
};

export const clearDataFromOpenOrders = (uid) => {
  try {
    firestore()
      .collection('users')
      .doc(uid)
      .collection('flipkartData')
      .doc('openOrderItemRowIdMapping')
      .set({});
  } catch (error) {
    captureError(error);
  }
};

export const clearDataFromCloseOrders = (uid) => {
  try {
    firestore()
      .collection('users')
      .doc(uid)
      .collection('flipkartData')
      .doc('closedOrderItemRowIdMapping')
      .set({});
  } catch (error) {
    captureError(error);
  }
};
