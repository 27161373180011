import {COPY_PASTE_ACTION} from '../actions/actionType';

const initialState = {
  copiedObj: {}, // col object using index or row object using index
  tableData: [],
  headerData: [],
  footerData: {},
  pasteType: '',
  extraMeta: null,
  uniqueColumnData: {},
  fileObj: {},
};

const copyPasteReducer = (state = initialState, action) => {
  switch (action.type) {
    case COPY_PASTE_ACTION.COPY: {
      return {
        ...state,
        tableData: action.payload.updatedTableData,
        copiedObj: action.payload.copiedObj,
        pasteType: action.payload.copyType,
        headerData:
          action.payload?.headerData && action.payload.headerData.length > 0
            ? action.payload.headerData
            : [],
        footerData: action.payload.footerData,
        extraMeta: action.payload.extraMeta,
        uniqueColumnData: action.payload.uniqueColumnData,
        fileObj: action.payload.fileObj,
      };
    }
    case COPY_PASTE_ACTION.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default copyPasteReducer;
