import {
  FIELD_TYPE_ID,
  MINI_APPS,
  MESSAGE_AUTOMATION,
  PURE_NUMBER_FIELDS,
} from './constant';
import {COLOR} from './designValues';

const {TRIGGER_CONFIGS} = MINI_APPS.AUTOMATION;
const DEFAULT_OPERATION_TYPE = 'AUTO_MESSAGES'; // WHEN no operation  type available , this will be default operation
const AUTOMATION_OPERATION_TYPES = {
  COPY_ROW: 'COPY_ROW',
  MOVE_ROW: 'MOVE_ROW',
  SEND_NOTIFICATION: 'SEND_NOTIFICATION',
  SET_VALUE_BY_FILTERS: 'SET_VALUE_BY_FILTERS',
  SEND_EMAIL: 'SEND_EMAIL',
  ADD_EMPTY_ROW: 'ADD_EMPTY_ROW',
  ON_USER_APP_ACCESS: 'ON_USER_APP_ACCESS',
};

const AUTOMATION_DOC_OPERATIONS = [
  AUTOMATION_OPERATION_TYPES.COPY_ROW,
  AUTOMATION_OPERATION_TYPES.MOVE_ROW,
];

const ALL_AUTOMATION_OPERATION_TYPES = [
  DEFAULT_OPERATION_TYPE,
  AUTOMATION_OPERATION_TYPES.COPY_ROW,
  AUTOMATION_OPERATION_TYPES.MOVE_ROW,
  AUTOMATION_OPERATION_TYPES.SEND_NOTIFICATION,
  AUTOMATION_OPERATION_TYPES.SET_VALUE_BY_FILTERS,
  AUTOMATION_OPERATION_TYPES.SEND_EMAIL,
  AUTOMATION_OPERATION_TYPES.ADD_EMPTY_ROW,
];

const NOTIFICATION_USER_TYPES_OBJECT = {
  ALL_USERS: {
    id: 'ALL_USERS',
    val: 'All Users',
    description: 'Send notification to all members the app is shared',
  },
  ASSIGNEE: {
    id: 'ASSIGNEE',
    val: 'Assignee',
    description: 'Send notification to user assigned with the record',
  },
  CREATOR: {
    id: 'CREATOR',
    val: 'Creator',
    description: 'Send notification to user who created the record',
  },
  USER_PERMISSION: {
    id: 'USER_PERMISSION',
    val: 'User Based on Permission',
    description: 'Send notification to specific roles or permissions',
  },
  USER_LIST: {
    id: 'USER_LIST',
    val: 'User List',
    description: 'Send notifications to list of users selected from app share',
  },
};

const NOTIFICATION_OPERATION_USER_TYPES = {
  ALL_USERS: 'ALL_USERS',
  ASSIGNEE: 'ASSIGNEE',
  CREATOR: 'CREATOR',
  USER_PERMISSION: 'USER_PERMISSION',
  USER_LIST: 'USER_LIST',
  ALL_ORGANIZATION_USERS: 'ALL_ORGANIZATION_USERS',
  TRIGGERED_BY: 'TRIGGERED_BY',
  EMAIL_COLUMNS: 'EMAIL_COLUMNS',
  CC_USERLIST: 'CC_USERLIST', //Used in CC Email Custom User List
  CUSTOM: 'CUSTOM',
};

const ALLOWED_FIELDS_FOR_NOTIFICATION_CUSTOM_MESSAGE = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.SWITCH,
  FIELD_TYPE_ID.TIME,
  FIELD_TYPE_ID.CHECKBOX,
  FIELD_TYPE_ID.SELECT_POP_UP,
  FIELD_TYPE_ID.CONTACT,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.UNIT,
  FIELD_TYPE_ID.MAPS,
  FIELD_TYPE_ID.LABEL,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.TABLE,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.URL,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.EMAIL,
  FIELD_TYPE_ID.AUTO_INCREMENT_ID,
  FIELD_TYPE_ID.DATE_TIME,
  FIELD_TYPE_ID.NO_OF_DAYS,
  FIELD_TYPE_ID.OTP,
];

const SYSTEM_GENERATED_FIELDS = {
  APP_NAME: 'APP_NAME',
  SCREEN_NAME: 'SCREEN_NAME',
  TRIGGERED_BY_USER: 'TRIGGERED_BY_USER',
  ROW_URL: 'ROW_URL',

  //Current Values
  CURRENT_DATE: 'CURRENT_DATE',
  CURRENT_DATE_TIME: 'CURRENT_DATE_TIME',
  CURRENT_TIME: 'CURRENT_TIME',
  CURRENT_MONTH: 'CURRENT_MONTH',
  CURRENT_YEAR: 'CURRENT_YEAR',
  CURRENT_QUARTER: 'CURRENT_QUARTER',
};

const CURRENT_VALUES_FOR_CUSTOM_MESSAGE = [
  {
    id: SYSTEM_GENERATED_FIELDS.CURRENT_DATE,
    val: 'Current Date',
  },
  {
    id: SYSTEM_GENERATED_FIELDS.CURRENT_DATE_TIME,
    val: 'Current Date & Time',
  },
  {
    id: SYSTEM_GENERATED_FIELDS.CURRENT_TIME,
    val: 'Current Time',
  },
  {
    id: SYSTEM_GENERATED_FIELDS.CURRENT_MONTH,
    val: 'Current Month',
  },
  {
    id: SYSTEM_GENERATED_FIELDS.CURRENT_QUARTER,
    val: 'Current Quarter',
  },
  {
    id: SYSTEM_GENERATED_FIELDS.CURRENT_YEAR,
    val: 'Current Year',
  },
];

const SYSTEM_GENERATED_FIELDS_FOR_NOTIFICATION_CUSTOM_MESSAGE = [
  {
    id: SYSTEM_GENERATED_FIELDS.APP_NAME,
    val: 'App Name',
  },
  {
    id: SYSTEM_GENERATED_FIELDS.SCREEN_NAME,
    val: 'Screen Name',
  },
  {
    id: SYSTEM_GENERATED_FIELDS.TRIGGERED_BY_USER,
    val: 'User who Triggered Automation',
  },
  ...CURRENT_VALUES_FOR_CUSTOM_MESSAGE,
];

const EMAIL_CONTENT_SYSTEM_GENERATED_FIELDS = [
  ...SYSTEM_GENERATED_FIELDS_FOR_NOTIFICATION_CUSTOM_MESSAGE,
  {
    id: SYSTEM_GENERATED_FIELDS.ROW_URL,
    val: 'Row URL',
  },
  ...CURRENT_VALUES_FOR_CUSTOM_MESSAGE,
];

const ROW_LINK_CUSTOM_TEXT_OBJECT = {
  id: TRIGGER_CONFIGS.ROW_LINK_CUSTOM_TEXT,
  text: 'Row Link - Custom Text(Optional)',
};
const ROW_LINK_SCREEN_ID_OBJECT = {
  id: TRIGGER_CONFIGS.ROW_LINK_SCREEN_ID,
  text: 'Row Link Screen ID - Custom Text(Optional)',
};

//!Note: Automation trigger Type Properties are CONFIGURED in rb-redux constants please check Automation from MINI_APPS
const AUTOMATION_OPERATION_PROPERTIES = {
  [DEFAULT_OPERATION_TYPE]: {
    id: DEFAULT_OPERATION_TYPE,
    text: 'Send a WhatsApp Message',
    CONFIG_FIELDS: [
      {
        id: TRIGGER_CONFIGS.AUTOMATION_TYPE,
        text: 'Do this...',
      },
      {
        id: TRIGGER_CONFIGS.PHONE_COLUMN,
        allowedTypes: [FIELD_TYPE_ID.ASSIGN_TASK, FIELD_TYPE_ID.CONTACT],
        text: 'Phone Column',
      },
      {
        id: TRIGGER_CONFIGS.WHATSAPP_TEMPLATE,
        text: 'Whatsapp Template',
      },
    ],
  },
  [AUTOMATION_OPERATION_TYPES.SEND_NOTIFICATION]: {
    id: AUTOMATION_OPERATION_TYPES.SEND_NOTIFICATION,
    text: 'Send a Notification',
    CONFIG_FIELDS: [
      {
        id: TRIGGER_CONFIGS.AUTOMATION_TYPE,
        text: 'Do this...',
      },
      {
        id: TRIGGER_CONFIGS.NOTIFICATION_USER_TYPE,
        text: 'Notify To',
      },
      {
        id: TRIGGER_CONFIGS.NOTIFICATION_USER_TYPE_CONFIGURE,
        text: 'User Configure',
      },
      {
        id: TRIGGER_CONFIGS.NOTIFICATION_CUSTOM_MESSAGE,
        text: 'Custom Message',
      },
    ],
  },
  [AUTOMATION_OPERATION_TYPES.COPY_ROW]: {
    id: AUTOMATION_OPERATION_TYPES.COPY_ROW,
    text: 'Copy Record to Another Database',
    CONFIG_FIELDS: [
      {
        id: TRIGGER_CONFIGS.AUTOMATION_TYPE,
        text: 'Do this...',
      },
      {
        id: TRIGGER_CONFIGS.ADDITIONAL_DATA_SOURCE,
        text: 'Additional Source File (optional)',
      },
      {
        id: TRIGGER_CONFIGS.ADDITIONAL_DATA_SOURCE_CONDITIONS,
        text: 'Conditions',
      },
      {
        id: TRIGGER_CONFIGS.ADDITIONAL_DATA_SOURCE_LIMIT,
        text: 'Limit Records (optional)',
      },
      {
        id: TRIGGER_CONFIGS.TO_DATABASE,
        text: 'Copy Row to File',
      },
      {
        id: TRIGGER_CONFIGS.FIELDS_FOR_SET_VALUE,
        text: 'Fields',
      },
      {
        id: TRIGGER_CONFIGS.AGGREGATION_DATA,
        text: 'Choose Aggregations',
      },
      {
        id: TRIGGER_CONFIGS.ENDPOINT_DATA,
        text: 'Choose Endpoints',
      },
      {
        id: TRIGGER_CONFIGS.VALUES_FOR_SELECTED_SET_VALUE_FIELDS,
        text: 'Values',
      },
    ],
  },
  [AUTOMATION_OPERATION_TYPES.MOVE_ROW]: {
    id: AUTOMATION_OPERATION_TYPES.MOVE_ROW,
    text: 'Move Record to Another Database',
    CONFIG_FIELDS: [
      {
        id: TRIGGER_CONFIGS.AUTOMATION_TYPE,
        text: 'Do this...',
      },
      {
        id: TRIGGER_CONFIGS.ADDITIONAL_DATA_SOURCE,
        text: 'Additional Source File (optional)',
      },
      {
        id: TRIGGER_CONFIGS.ADDITIONAL_DATA_SOURCE_CONDITIONS,
        text: 'Conditions',
      },
      {
        id: TRIGGER_CONFIGS.ADDITIONAL_DATA_SOURCE_LIMIT,
        text: 'Limit Records (optional)',
      },
      {
        id: TRIGGER_CONFIGS.TO_DATABASE,
        text: 'Move Row to File',
      },
      {
        id: TRIGGER_CONFIGS.FIELDS_FOR_SET_VALUE,
        text: 'Fields',
      },
      {
        id: TRIGGER_CONFIGS.AGGREGATION_DATA,
        text: 'Choose Aggregations',
      },
      {
        id: TRIGGER_CONFIGS.ENDPOINT_DATA,
        text: 'Choose Endpoints',
      },
      {
        id: TRIGGER_CONFIGS.VALUES_FOR_SELECTED_SET_VALUE_FIELDS,
        text: 'Values',
      },
    ],
  },
  [AUTOMATION_OPERATION_TYPES.SET_VALUE_BY_FILTERS]: {
    id: AUTOMATION_OPERATION_TYPES.SET_VALUE_BY_FILTERS,
    text: 'Update/Set Value of a Record',
    CONFIG_FIELDS: [
      {
        id: TRIGGER_CONFIGS.AUTOMATION_TYPE,
        text: 'Do this...',
      },
      {
        id: TRIGGER_CONFIGS.ADDITIONAL_DATA_SOURCE,
        text: 'Additional Source File (optional)',
      },
      {
        id: TRIGGER_CONFIGS.ADDITIONAL_DATA_SOURCE_CONDITIONS,
        text: 'Conditions',
      },
      {
        id: TRIGGER_CONFIGS.ADDITIONAL_DATA_SOURCE_LIMIT,
        text: 'Limit Records (optional)',
      },
      {
        id: TRIGGER_CONFIGS.TO_DATABASE,
        text: 'Target File',
      },
      {
        id: TRIGGER_CONFIGS.FIELDS_FOR_SET_VALUE,
        text: 'Fields',
      },
      {
        id: TRIGGER_CONFIGS.AGGREGATION_DATA,
        text: 'Choose Aggregations',
      },
      {
        id: TRIGGER_CONFIGS.ENDPOINT_DATA,
        text: 'Choose Endpoints',
      },
      {
        id: TRIGGER_CONFIGS.VALUES_FOR_SELECTED_SET_VALUE_FIELDS,
        text: 'Values',
      },
      {
        id: TRIGGER_CONFIGS.SET_CONDITION,
        text: 'Set Condition',
      },
      {
        id: TRIGGER_CONFIGS.TO_DOC_OPERATION_INSERT_OR_UPDATE,
        text: 'Insert row if no conditions matched',
      },
      {
        id: TRIGGER_CONFIGS.UPDATE_ROWS_ON_UNIQUE_FAIL,
        text: 'Update rows on unique fail when inserting new row.',
      },
    ],
  },
  [AUTOMATION_OPERATION_TYPES.SEND_EMAIL]: {
    id: AUTOMATION_OPERATION_TYPES.SEND_EMAIL,
    text: 'Send an Email',
    CONFIG_FIELDS: [
      {
        id: TRIGGER_CONFIGS.AUTOMATION_TYPE,
        text: 'Do this...',
      },
      {
        id: TRIGGER_CONFIGS.SENDER_EMAIL,
        text: 'Send Email from',
      },
      {
        id: TRIGGER_CONFIGS.RECIPIENTS_EMAIL,
        allowedTypes: [FIELD_TYPE_ID.ASSIGN_TASK, FIELD_TYPE_ID.EMAIL],
        text: 'Recipients',
      },
      {
        id: TRIGGER_CONFIGS.RECIPIENTS_EMAIL_CONFIGURE_ROLES,
        text: 'Configure',
      },
      {
        id: TRIGGER_CONFIGS.RECIPIENTS_EMAIL_COLUMNS,
        text: 'Configure',
      },
      {
        id: TRIGGER_CONFIGS.RECIPIENTS_EMAIL_USERS_LIST,
        text: 'Configure',
      },
      {
        id: TRIGGER_CONFIGS.EMAIL_CONTENT,
        text: 'Message',
      },
      {
        id: TRIGGER_CONFIGS.CC_EMAIL,
        text: 'CC (Optional)',
      },
      {
        id: TRIGGER_CONFIGS.CC_USERLIST,
        text: 'Configure',
      },
      {
        id: TRIGGER_CONFIGS.CC_EMAIL_CONFIGURE_ROLES,
        text: 'Configure',
      },
      {
        id: TRIGGER_CONFIGS.CC_EMAIL_COLUMNS,
        text: 'Configure',
      },
    ],

    ATTACHMENT_CONFIG_FIELDS: [
      {
        id: TRIGGER_CONFIGS.ATTACHMENT,
        text: 'Column',
      },
      {
        id: TRIGGER_CONFIGS.CUSTOM_ATTACHMENT,
        text: 'Custom Attachments',
      },
    ],
  },
  [AUTOMATION_OPERATION_TYPES.ADD_EMPTY_ROW]: {
    id: AUTOMATION_OPERATION_TYPES.ADD_EMPTY_ROW,
    text: 'Add Empty Record',
    CONFIG_FIELDS: [
      {
        id: TRIGGER_CONFIGS.TO_DATABASE,
        text: 'Target File',
      },
    ],
  },
};

/* WILL USE IN FUTURE */
// [AUTOMATION_OPERATION_TYPES.SEND_EMAIL]: {
//   id: AUTOMATION_OPERATION_TYPES.SEND_EMAIL,
//   text: 'Send an Email',
//   CONFIG_FIELDS: [
//     {
//       id: TRIGGER_CONFIGS.AUTOMATION_TYPE,
//       text: 'Do this...',
//     },
//     {
//       id: TRIGGER_CONFIGS.SENDER_EMAIL,
//       text: 'Send Email from',
//     },
//     {
//       id: TRIGGER_CONFIGS.RECIPIENTS_EMAIL,
//       allowedTypes: [FIELD_TYPE_ID.ASSIGN_TASK, FIELD_TYPE_ID.EMAIL],
//       text: 'Recipients',
//     },
//     {
//       id: TRIGGER_CONFIGS.RECIPIENTS_EMAIL_CONFIGURE_ROLES,
//       text: 'Configure',
//     },
//     {
//       id: TRIGGER_CONFIGS.EMAIL_CONTENT,
//       text: 'Message',
//     },
//     {
//       id: TRIGGER_CONFIGS.CC_EMAIL,
//       text: 'CC (Optional)',
//     },
//   ],
// },

const SET_VALUE_CONDITIONS = {
  WHEN: 'WHEN',
  CONDITION: 'CONDITION',
  VALUE: 'VALUE',
};

const ACTION_BUTTON_DISABLED_AUTOMATIONS = [
  MESSAGE_AUTOMATION.ROW_DELETE_ENTRY,
  MESSAGE_AUTOMATION.ON_SPECIFIC_DATE,
];

const DISABLED_COLUMNS_COPY_MOVE_ROW_AUTOMATIONS = [
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.LIST,
];

const DISABLED_COLUMNS_FOR_DEFAULT_VALUE_COPY_MOVE_ROW_AUTOMATIONS = [
  FIELD_TYPE_ID.TABLE,
  FIELD_TYPE_ID.BARCODE,
  // FIELD_TYPE_ID.EMAIL,
  // FIELD_TYPE_ID.URL,
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.MAPS,
  // FIELD_TYPE_ID.ASSIGN_TASK,
];

// Used for copy/move row
const DEFAULT_VALUE_MAPPING_OPTIONS = {
  DO_NOTHING: 'DO_NOTHING',
  ERASE_DATA: 'ERASE_DATA',
  ADD_DEFAULT_VALUE: 'ADD_DEFAULT_VALUE',
};
const RECIPIENTS_SYSTEM_EMAIL_TYPES = [
  {
    id: NOTIFICATION_OPERATION_USER_TYPES.EMAIL_COLUMNS,
    text: 'Pick email from columns',
    val: 'Pick email from columns',
  },
  {
    id: NOTIFICATION_OPERATION_USER_TYPES.TRIGGERED_BY,
    text: 'User who Triggered Automation',
    val: 'User who Triggered Automation',
  },
  {
    id: NOTIFICATION_OPERATION_USER_TYPES.CREATOR,
    text: 'Creator of the Record',
    val: 'Creator of the Record',
  },
  {
    id: NOTIFICATION_OPERATION_USER_TYPES.ALL_USERS,
    text: 'All App Users',
    val: 'All App Users',
  },
  {
    id: NOTIFICATION_OPERATION_USER_TYPES.ALL_ORGANIZATION_USERS,
    text: 'All Organisation Users',
    val: 'All Organisation Users',
  },
  {
    id: NOTIFICATION_OPERATION_USER_TYPES.USER_PERMISSION,
    text: 'Users based on Roles',
    val: 'Users based on Roles',
  },
  {
    id: NOTIFICATION_OPERATION_USER_TYPES.USER_LIST,
    text: 'Custom User List',
    val: 'Custom User List',
  },
];

const CC_SYSTEM_EMAIL_TYPES = [
  {
    id: NOTIFICATION_OPERATION_USER_TYPES.EMAIL_COLUMNS,
    text: 'Pick email from columns',
    val: 'Pick email from columns',
  },
  {
    id: NOTIFICATION_OPERATION_USER_TYPES.TRIGGERED_BY,
    text: 'User who Triggered Automation',
    val: 'User who Triggered Automation',
  },
  {
    id: NOTIFICATION_OPERATION_USER_TYPES.CREATOR,
    text: 'Creator of the Record',
    val: 'Creator of the Record',
  },
  {
    id: NOTIFICATION_OPERATION_USER_TYPES.ALL_USERS,
    text: 'All App Users',
    val: 'All App Users',
  },
  {
    id: NOTIFICATION_OPERATION_USER_TYPES.ALL_ORGANIZATION_USERS,
    text: 'All Organisation Users',
    val: 'All Organisation Users',
  },
  {
    id: NOTIFICATION_OPERATION_USER_TYPES.USER_PERMISSION,
    text: 'Users based on Roles',
    val: 'Users based on Roles',
  },
  {
    id: NOTIFICATION_OPERATION_USER_TYPES.USER_LIST,
    text: 'Custom User List',
    val: 'Custom User List',
  },
];

const ALLOWED_FIELD_TYPE_FOR_EMAIL_CONTENTS = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.SWITCH,
  FIELD_TYPE_ID.TIME,
  FIELD_TYPE_ID.CHECKBOX,
  FIELD_TYPE_ID.SELECT_POP_UP,
  FIELD_TYPE_ID.CONTACT,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.UNIT,
  FIELD_TYPE_ID.MAPS,
  FIELD_TYPE_ID.LABEL,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.TABLE,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.URL,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.EMAIL,
  FIELD_TYPE_ID.AUTO_INCREMENT_ID,
  FIELD_TYPE_ID.DATE_TIME,
  FIELD_TYPE_ID.STRING_FORMULA,
  FIELD_TYPE_ID.OTP,
];
const AUTOMATION_COLUMN_VALUE_TYPES = {
  DO_NOTHING: 'DO_NOTHING',
  SELECT_COLUM_OR_CUSTOM_VALUE: 'SELECT_COLUM_OR_CUSTOM_VALUE',
  CUSTOM_VALUE: 'CUSTOM_VALUE',
  COLUMN_VALUE: 'COLUMN_VALUE',
  SET_EMPTY: 'SET_EMPTY',

  //Manual Trigger Only
  SET_VALUE_ON_RUNTIME: 'SET_VALUE_ON_RUNTIME',

  // Date / Time / DateTime
  NOW: 'NOW',

  // Assign Task
  LOGGED_IN_USER: 'LOGGED_IN_USER',
  ROW_CREATOR: 'ROW_CREATOR',

  // Number, Unit, Rupee
  INCREMENT_BY: 'INCREMENT_BY',
  DECREMENT_BY: 'DECREMENT_BY',

  // Auto Increment ID
  GENERATE_ID: 'GENERATE_ID',
  USER_NAME: 'USER_NAME',
  USER_EMAIL: 'USER_EMAIL',
  USER_CONTACT: 'USER_CONTACT',
  ASSIGN_USER: 'ASSIGN_USER',
};

const APP_BASED_FIELDS_FOR_NOTIFICATION_CUSTOM_MESSAGE = [
  {
    id: SYSTEM_GENERATED_FIELDS.APP_NAME,
    val: 'App Name',
  },
  {
    id: AUTOMATION_COLUMN_VALUE_TYPES.USER_CONTACT,
    val: 'New User Contact',
  },
  {
    id: AUTOMATION_COLUMN_VALUE_TYPES.USER_EMAIL,
    val: 'New User Email',
  },
  {
    id: AUTOMATION_COLUMN_VALUE_TYPES.USER_NAME,
    val: 'New User Name',
  },
  {
    id: SYSTEM_GENERATED_FIELDS.TRIGGERED_BY_USER,
    val: 'User who Triggered Automation',
  },
  ...CURRENT_VALUES_FOR_CUSTOM_MESSAGE,
];

const DEFAULT_VALUES = {
  [AUTOMATION_COLUMN_VALUE_TYPES.NOW]: {
    id: AUTOMATION_COLUMN_VALUE_TYPES.NOW,
    val: 'Now',
    iconName: 'clock',
    iconGroup: 'MaterialCommunityIcons',
    iconColor: COLOR.OFFER_TEXT,
  },
  [AUTOMATION_COLUMN_VALUE_TYPES.ROW_CREATOR]: {
    id: AUTOMATION_COLUMN_VALUE_TYPES.ROW_CREATOR,
    val: 'Row Creator',
    iconName: 'account-plus',
    iconGroup: 'MaterialCommunityIcons',
    iconColor: COLOR.OFFER_TEXT,
  },
  [AUTOMATION_COLUMN_VALUE_TYPES.LOGGED_IN_USER]: {
    id: AUTOMATION_COLUMN_VALUE_TYPES.LOGGED_IN_USER,
    val: 'Logged In User',
    iconName: 'account-outline',
    iconGroup: 'MaterialCommunityIcons',
    iconColor: COLOR.OFFER_TEXT,
  },
  [AUTOMATION_COLUMN_VALUE_TYPES.GENERATE_ID]: {
    id: AUTOMATION_COLUMN_VALUE_TYPES.GENERATE_ID,
    val: 'Generate ID',
    iconName: 'numeric',
    iconGroup: 'MaterialCommunityIcons',
    iconColor: COLOR.OFFER_TEXT,
  },
  [AUTOMATION_COLUMN_VALUE_TYPES.INCREMENT_BY]: {
    id: AUTOMATION_COLUMN_VALUE_TYPES.INCREMENT_BY,
    val: 'Increment By',
    iconName: 'plus',
    iconGroup: 'MaterialCommunityIcons',
    iconColor: COLOR.OFFER_TEXT,
  },
  [AUTOMATION_COLUMN_VALUE_TYPES.DECREMENT_BY]: {
    id: AUTOMATION_COLUMN_VALUE_TYPES.DECREMENT_BY,
    val: 'Decrement By',
    iconName: 'minus',
    iconGroup: 'MaterialCommunityIcons',
    iconColor: COLOR.OFFER_TEXT,
  },
  [AUTOMATION_COLUMN_VALUE_TYPES.USER_NAME]: {
    id: AUTOMATION_COLUMN_VALUE_TYPES.USER_NAME,
    val: 'New User Name',
    iconName: 'account',
    iconGroup: 'MaterialCommunityIcons',
    iconColor: COLOR.OFFER_TEXT,
  },
  [AUTOMATION_COLUMN_VALUE_TYPES.USER_EMAIL]: {
    id: AUTOMATION_COLUMN_VALUE_TYPES.USER_EMAIL,
    val: 'New User Email',
    iconName: 'account',
    iconGroup: 'MaterialCommunityIcons',
    iconColor: COLOR.OFFER_TEXT,
  },
  [AUTOMATION_COLUMN_VALUE_TYPES.USER_CONTACT]: {
    id: AUTOMATION_COLUMN_VALUE_TYPES.USER_CONTACT,
    val: 'New User Contact',
    iconName: 'account',
    iconGroup: 'MaterialCommunityIcons',
    iconColor: COLOR.OFFER_TEXT,
  },
  [AUTOMATION_COLUMN_VALUE_TYPES.ASSIGN_USER]: {
    id: AUTOMATION_COLUMN_VALUE_TYPES.ASSIGN_USER,
    val: 'User who Triggered Automation',
    iconName: 'account-outline',
    iconGroup: 'MaterialCommunityIcons',
    iconColor: COLOR.OFFER_TEXT,
  },
};

const SET_CONDITION_COMPONENT_DEFAULT_VALUES = Object.assign(
  {},
  DEFAULT_VALUES,
);

const AUTOMATION_COLUMN_VALUE_TYPES_WITH_OPTIONS = [
  AUTOMATION_COLUMN_VALUE_TYPES.INCREMENT_BY,
  AUTOMATION_COLUMN_VALUE_TYPES.DECREMENT_BY,
];

const NUMBER_FIELD_DEFAULT_VALUES = [
  DEFAULT_VALUES[AUTOMATION_COLUMN_VALUE_TYPES.INCREMENT_BY],
  DEFAULT_VALUES[AUTOMATION_COLUMN_VALUE_TYPES.DECREMENT_BY],
];

const COMMON_DEFAULT_VALUES = {
  // Number Fields
  [FIELD_TYPE_ID.NUMBER]: NUMBER_FIELD_DEFAULT_VALUES,
  [FIELD_TYPE_ID.RUPEE]: NUMBER_FIELD_DEFAULT_VALUES,
  [FIELD_TYPE_ID.UNIT]: NUMBER_FIELD_DEFAULT_VALUES,

  [FIELD_TYPE_ID.DATE]: [DEFAULT_VALUES[AUTOMATION_COLUMN_VALUE_TYPES.NOW]],
  [FIELD_TYPE_ID.TIME]: [DEFAULT_VALUES[AUTOMATION_COLUMN_VALUE_TYPES.NOW]],
  [FIELD_TYPE_ID.DATE_TIME]: [
    DEFAULT_VALUES[AUTOMATION_COLUMN_VALUE_TYPES.NOW],
  ],
  [FIELD_TYPE_ID.AUTO_INCREMENT_ID]: [
    DEFAULT_VALUES[AUTOMATION_COLUMN_VALUE_TYPES.GENERATE_ID],
  ],
};

const AUTOMATION_DEFAULT_VALUES_FOR_FIELD_TYPE = {
  [FIELD_TYPE_ID.ASSIGN_TASK]: [
    DEFAULT_VALUES[AUTOMATION_COLUMN_VALUE_TYPES.LOGGED_IN_USER],
    DEFAULT_VALUES[AUTOMATION_COLUMN_VALUE_TYPES.ROW_CREATOR],
  ],

  ...COMMON_DEFAULT_VALUES,
};

const COPY_MOVE_DISABLED_IDS = [
  AUTOMATION_COLUMN_VALUE_TYPES.INCREMENT_BY,
  AUTOMATION_COLUMN_VALUE_TYPES.DECREMENT_BY,
];

const NON_CHECK_DEFAULT_VALUES = [
  AUTOMATION_COLUMN_VALUE_TYPES.INCREMENT_BY,
  AUTOMATION_COLUMN_VALUE_TYPES.DECREMENT_BY,
  AUTOMATION_COLUMN_VALUE_TYPES.GENERATE_ID,
];

const DOC_OPEARTION_TYPE = {INSERT_OR_UPDATE: 'INSERT_OR_UPDATE'};

const NOT_ALLOWED_FIELDTYPES_SET_CONDITION_TRIGGER_TYPE = [
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.MAPS,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.PDF,
  FIELD_TYPE_ID.LIST,
  FIELD_TYPE_ID.URL,
  FIELD_TYPE_ID.REMINDER,
];

const NOT_ALLOWED_CUSTOM_VALUES_FOR_COLUMN_MAPPING = [
  FIELD_TYPE_ID.URL,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.IMAGE,
  // FIELD_TYPE_ID.TABLE,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.MAPS,
];

const moveRowAutomationFn = (operationType) =>
  operationType !== AUTOMATION_OPERATION_TYPES.MOVE_ROW;

const userAppAccessFilterFn = (operationType) =>
  operationType !== AUTOMATION_OPERATION_TYPES.MOVE_ROW &&
  operationType !== AUTOMATION_OPERATION_TYPES.SEND_EMAIL &&
  operationType !== DEFAULT_OPERATION_TYPE;

const onSpecificDateFilterFn = (operationType) =>
  operationType !== AUTOMATION_OPERATION_TYPES.SEND_EMAIL;

/**
 * @type {Object.<string, string[]>}
 */
const AUTOMATION_TRIGGER_OPERATION_MAP = {
  [MESSAGE_AUTOMATION.ROW_NEW_ENTRY]:
    ALL_AUTOMATION_OPERATION_TYPES.filter(moveRowAutomationFn),
  [MESSAGE_AUTOMATION.ON_ASSIGNEE_CHANGE]: ALL_AUTOMATION_OPERATION_TYPES,
  [MESSAGE_AUTOMATION.ROW_DELETE_ENTRY]: ALL_AUTOMATION_OPERATION_TYPES,
  [MESSAGE_AUTOMATION.ROW_EDIT_ENTRY]: ALL_AUTOMATION_OPERATION_TYPES,
  [MESSAGE_AUTOMATION.ON_SPECIFIC_DATE]: ALL_AUTOMATION_OPERATION_TYPES.filter(
    onSpecificDateFilterFn,
  ),
  [MESSAGE_AUTOMATION.CELL_STATUS_CHANGE]: ALL_AUTOMATION_OPERATION_TYPES,
  [MESSAGE_AUTOMATION.MANUAL]: ALL_AUTOMATION_OPERATION_TYPES,

  [MESSAGE_AUTOMATION.ON_USER_APP_ACCESS]:
    ALL_AUTOMATION_OPERATION_TYPES.filter(userAppAccessFilterFn),
};

const FIELD_TYPE_TO_OTHER_FIELD_TYPE_CONDITIONS_MAP = {
  [FIELD_TYPE_ID.AUTO_INCREMENT_ID]: [
    FIELD_TYPE_ID.AUTO_INCREMENT_ID,
    FIELD_TYPE_ID.TEXT,
  ],
  [FIELD_TYPE_ID.FORMULA]: [
    FIELD_TYPE_ID.FORMULA,
    FIELD_TYPE_ID.TEXT,
    ...PURE_NUMBER_FIELDS,
  ],
  [FIELD_TYPE_ID.STRING_FORMULA]: [
    FIELD_TYPE_ID.STRING_FORMULA,
    FIELD_TYPE_ID.TEXT,
  ],
};

// FIELD_TYPE_ID.TABLE, // ! Allowing Table Column for Edit Automations Screen.
// FIELD_TYPE_ID.FORMULA, // * I can use formula for checking edit automation condtions.
const MAPPING_DEFAULT_VALUES_DETAILS = DEFAULT_VALUES;
const MAPPING_VERSION = 'V2';
const NEW_AUTOMATION_DEFAULT_VALUES = {
  mappingVersion: MAPPING_VERSION,
};

const EMAIL_COLUMNS_FIELD_TYPES = [
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.EMAIL,
];
const EMAIL_ATTACHMENT_COLUMNS_FIELD_TYPES = [
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.PDF,
];
const AUTOMATIONS_APP_BASED_DEFAULT_VALUES = {
  [FIELD_TYPE_ID.ASSIGN_TASK]: [
    DEFAULT_VALUES[AUTOMATION_COLUMN_VALUE_TYPES.ASSIGN_USER],
  ],
  [FIELD_TYPE_ID.TEXT]: [
    DEFAULT_VALUES[AUTOMATION_COLUMN_VALUE_TYPES.USER_NAME],
    DEFAULT_VALUES[AUTOMATION_COLUMN_VALUE_TYPES.USER_EMAIL],
    DEFAULT_VALUES[AUTOMATION_COLUMN_VALUE_TYPES.USER_CONTACT],
  ],
  [FIELD_TYPE_ID.EMAIL]: [
    DEFAULT_VALUES[AUTOMATION_COLUMN_VALUE_TYPES.USER_EMAIL],
  ],
  [FIELD_TYPE_ID.CONTACT]: [
    DEFAULT_VALUES[AUTOMATION_COLUMN_VALUE_TYPES.USER_CONTACT],
  ],
  ...COMMON_DEFAULT_VALUES,
};

const ADDITIONAL_SOURCES_ALLOWED_ACTIONS = [
  AUTOMATION_OPERATION_TYPES.COPY_ROW,
  AUTOMATION_OPERATION_TYPES.MOVE_ROW,
  AUTOMATION_OPERATION_TYPES.SET_VALUE_BY_FILTERS,
];

const RUNTIME_RESTRICTED_FIELD_TYPES = [FIELD_TYPE_ID.AUTO_INCREMENT_ID];

export {
  EMAIL_COLUMNS_FIELD_TYPES,
  AUTOMATION_TRIGGER_OPERATION_MAP,
  AUTOMATION_COLUMN_VALUE_TYPES,
  DEFAULT_OPERATION_TYPE,
  AUTOMATION_OPERATION_TYPES,
  ALL_AUTOMATION_OPERATION_TYPES,
  AUTOMATION_OPERATION_PROPERTIES,
  AUTOMATION_DOC_OPERATIONS,
  NOTIFICATION_OPERATION_USER_TYPES,
  NOTIFICATION_USER_TYPES_OBJECT,
  ALLOWED_FIELDS_FOR_NOTIFICATION_CUSTOM_MESSAGE,
  SYSTEM_GENERATED_FIELDS_FOR_NOTIFICATION_CUSTOM_MESSAGE,
  SET_VALUE_CONDITIONS,
  RECIPIENTS_SYSTEM_EMAIL_TYPES,
  ALLOWED_FIELD_TYPE_FOR_EMAIL_CONTENTS,
  ACTION_BUTTON_DISABLED_AUTOMATIONS,
  DISABLED_COLUMNS_COPY_MOVE_ROW_AUTOMATIONS,
  DISABLED_COLUMNS_FOR_DEFAULT_VALUE_COPY_MOVE_ROW_AUTOMATIONS,
  DEFAULT_VALUE_MAPPING_OPTIONS,
  CC_SYSTEM_EMAIL_TYPES,
  AUTOMATION_DEFAULT_VALUES_FOR_FIELD_TYPE,
  DOC_OPEARTION_TYPE,
  FIELD_TYPE_TO_OTHER_FIELD_TYPE_CONDITIONS_MAP,
  NOT_ALLOWED_FIELDTYPES_SET_CONDITION_TRIGGER_TYPE,
  NOT_ALLOWED_CUSTOM_VALUES_FOR_COLUMN_MAPPING,
  AUTOMATION_COLUMN_VALUE_TYPES_WITH_OPTIONS,
  MAPPING_DEFAULT_VALUES_DETAILS,
  MAPPING_VERSION,
  NEW_AUTOMATION_DEFAULT_VALUES,
  EMAIL_ATTACHMENT_COLUMNS_FIELD_TYPES,
  AUTOMATIONS_APP_BASED_DEFAULT_VALUES,
  APP_BASED_FIELDS_FOR_NOTIFICATION_CUSTOM_MESSAGE,
  ADDITIONAL_SOURCES_ALLOWED_ACTIONS,
  EMAIL_CONTENT_SYSTEM_GENERATED_FIELDS,
  ROW_LINK_CUSTOM_TEXT_OBJECT,
  ROW_LINK_SCREEN_ID_OBJECT,
  COPY_MOVE_DISABLED_IDS,
  NON_CHECK_DEFAULT_VALUES,
  SET_CONDITION_COMPONENT_DEFAULT_VALUES,
  CURRENT_VALUES_FOR_CUSTOM_MESSAGE,
  SYSTEM_GENERATED_FIELDS,
  RUNTIME_RESTRICTED_FIELD_TYPES,
};
