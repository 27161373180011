import {
  ShowToast,
  captureError,
  getReduxState,
  ENV,
  isAndroid,
  openLink,
  captureInfo,
  getDeviceVersion,
  versionCompare,
  setReduxState,
  openLinkV2,
} from '../../imports';
import {
  DEFAULT_DOC_DATA,
  getSortedMiniAppsScreens,
  mapMiniAppStates,
} from './miniAppsActionHelper';
import {
  FIELD_TYPE_ID,
  MINI_APPS,
  SYSTEM_GENERATED_FIELDS_FOR_WHATSAPP_WITH_DATA_ACTION_BUTTON,
} from '../../utils/constant';
import {forOwn, isEmpty, isNil, omit} from 'lodash';
import {
  checkIfValueExistOnCell,
  conditionCheckOnRecord,
  convertCellDataToText,
  getColumnFieldType,
  getLocalText,
} from '../../utils/utils';
import {copyToClipboard} from '../../utils/platformUtils';
import {
  loadMiniAppAutomationConfig,
  triggerMiniAppAutomationManually,
} from '../automationAction';
import {downloadPDFUsingActionButton} from '../miniAppsAction';
import LioArray from '../../utils/CustomClass/LioArray';
import moment from 'moment';
import {getOrganisationUserInfo} from '../../utils/organistionUtils';
import {automationUtils} from '../../utils/automationUtils';
import {AUTOMATION_COLUMN_VALUE_TYPES} from '../../utils/automationConstants';

const getPrefilledRowObj = (
  currentScreenRowObj,
  currentScreenHeaderIdsMap,
  toScreenHeaderData,
  currentScreenDocId,
  actionBtnMeta,
) => {
  const rowObj = {};
  const mappedColIds = [];
  const {
    ACTION_BUTTONS: {CUSTOM_ACTION_BUTTON_PROPERTIES},
  } = MINI_APPS;
  try {
    toScreenHeaderData.forEach((colObj) => {
      const {fieldType, linkedMeta, id: colId} = colObj;
      if (fieldType === FIELD_TYPE_ID.TABLE) {
        const linkedToColId = linkedMeta?.[currentScreenDocId]?.colId;
        if (
          //! Any table linked columns between 2 screens
          //! are dependent on SHOW_TABLE_LINKED_COLUMN_WITH_THIS_SCREEN
          //! property and not on if parent column's value in that
          //! particular parent row was filled or not

          //! Any other table linked columns which are not from trigger screen
          //! are not deoendent on any of the above mentioned condition
          //! and will always be visible
          currentScreenHeaderIdsMap[linkedToColId]
          // && //if linked to current screen column
          // checkIfValueExistOnCell(
          //   currentScreenRowObj[linkedToColId],
          //   currentScreenHeaderIdsMap[linkedToColId],
          //   currentScreenRowObj,
          // ) //if value exists in parent row
        ) {
          rowObj[colId] = Object.assign(
            {},
            omit(currentScreenRowObj[linkedToColId], ['childLinks']),
            {
              parentMeta: {
                docId: currentScreenDocId,
                rowId: currentScreenRowObj.rowId,
                colId: linkedToColId,
              },
            },
          );
          !actionBtnMeta.actionConfig?.[
            CUSTOM_ACTION_BUTTON_PROPERTIES
              .SHOW_TABLE_LINKED_COLUMN_WITH_THIS_SCREEN
          ] && mappedColIds.push(colId);
        }
      }
    });
  } catch (error) {
    captureError(error);
  }
  return [rowObj, mappedColIds];
};

/**
 *
 * @param {string} actionBtnId : action to be performed
 * @param {string} rowId : row Id of the record
 * @param {object} actionExecutorHelper : helper to perform action with callbacks and other data
 * [processMediaAction, selectedValue, processOpenForm]
 */

const callActionButtonAction = async ({
  actionBtnId,
  screenId: activeScreenId,
  rowId,
  actionExecutorHelper,
}) => {
  const isWeb = ENV !== true;
  let actionBtnMeta;
  try {
    if (!rowId || !actionBtnId) {
      return null;
    }

    const {
      ACTION_BUTTONS: {ACTIONS, CUSTOM_ACTION_BUTTON_PROPERTIES},
    } = MINI_APPS;
    const state = getReduxState();
    const {
      auth: {userPref, user},
    } = state;

    const {miniApps, organisation} = state;
    const {
      profileData: {owner, ownerUID},
      membersList,
    } = organisation;

    const currentScreenData = mapMiniAppStates(
      state,
      false,
      false,
      activeScreenId,
    );
    const {
      activeAppId,
      activeAppMeta,
      activeDocId,
      documentData,
      activeScreenData,
    } = currentScreenData;

    actionBtnMeta = Object.assign(
      {},
      activeScreenData.actionBtns?.find?.((item) => item.id === actionBtnId),
    );

    if (isEmpty(actionBtnMeta) || !(actionBtnMeta.type in ACTIONS)) {
      ShowToast('Action not found.', userPref);
      return null;
    }

    const rowObj = Object.assign({}, documentData.rowIdDataMap[rowId]);

    const {isEnabled, message: actionBtnDisableText} =
      isActionBtnLifecycleActive({
        appId: activeAppId,
        screenId: activeScreenId,
        actionBtnMeta,
        rowObj,
        uid: user.uid,
      });
    if (!isEnabled) {
      if (actionBtnDisableText?.length) {
        ShowToast(actionBtnDisableText, userPref);
      }
      return null;
    }

    if (actionBtnMeta.minVersion) {
      //check if action can be called from current version of the app
      const appVersion = getDeviceVersion();
      if (versionCompare(actionBtnMeta.minVersion, appVersion, false)) {
        const msg = getLocalText(
          userPref,
          'This feature is not supported by current version of the app. Please update your app. Contact customer support to learn more.',
        );
        if (ENV) {
          require('../../imports').documentIncompatibleAlert(userPref, msg);
        } else {
          alert(msg);
        }
        return null;
      }
    }

    const getSelectedColumnWrapper = (fieldType, allowEmpty = false) =>
      getSelectedColumn(
        fieldType,
        {
          actionExecutorHelper,
          documentData,
          userPref,
          rowObj,
          allowEmpty,
        },
        actionBtnMeta,
      );
    const actionBtnType = actionBtnMeta.type;
    let multiMediaFieldType;

    switch (actionBtnType) {
      case ACTIONS.OPEN_FORMS: {
        if (
          !isEmpty(actionBtnMeta.actionConfig) &&
          typeof actionExecutorHelper?.processOpenForm === 'function'
        ) {
          const {screenId, columnIds} = actionBtnMeta.actionConfig;
          const isEditEntry = screenId === activeScreenId;
          //no screen level permission check for add/edit for open form action
          const getHeaderIdsMap = (headerData) =>
            headerData.reduce(
              (acc, item) => Object.assign({}, acc, {[item.id]: item}),
              {},
            );
          let prevFilledRowObj = {};
          //user should have access to the open form screen and only columns accessible to user should be shown
          const currentScreenHeader = documentData.headerData;
          const currentScreenHeaderIdsMap =
            getHeaderIdsMap(currentScreenHeader);
          let nonEditableHiddenColIds = [];
          const toScreenData = mapMiniAppStates(state, false, false, screenId);
          const isScreenNotShared =
            toScreenData.documentData === DEFAULT_DOC_DATA; //screen not shared with user and user don't have doc data
          if (
            isScreenNotShared ||
            toScreenData.userPermission ===
              MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.VIEW //view only user
          ) {
            ShowToast(
              "You don't have permission to perform this action.",
              userPref,
            );
          } else {
            if (isEditEntry) {
              //if same screen : open form ==> edit entry
              prevFilledRowObj = Object.assign({}, rowObj);
              nonEditableHiddenColIds = [];
            } else if (activeDocId !== toScreenData.activeDocId) {
              const toScreenHeaderData =
                toScreenData.documentData?.headerData ?? [];
              [prevFilledRowObj, nonEditableHiddenColIds] = getPrefilledRowObj(
                rowObj,
                currentScreenHeaderIdsMap,
                toScreenHeaderData,
                activeDocId,
                toScreenData.activeDocId,
                actionBtnMeta,
              );
            }
            actionExecutorHelper.processOpenForm(
              {
                ...actionBtnMeta.actionConfig,
                columnIds,
              },
              prevFilledRowObj,
              actionBtnMeta.actionConfig?.[
                CUSTOM_ACTION_BUTTON_PROPERTIES
                  .SHOW_TABLE_LINKED_COLUMN_WITH_THIS_SCREEN
              ]
                ? []
                : nonEditableHiddenColIds,
              isEditEntry,
            );
          }
        } else {
          throw new Error('No action config found for open form action');
        }
        return null;
      }

      case ACTIONS.OPEN_LIST_COLUMN: {
        const {columnId} = actionBtnMeta.actionConfig ?? {};
        if (
          !columnId ||
          typeof actionExecutorHelper?.processOpenList === 'function'
        ) {
          const columnData = documentData.headerData.find(
            (column) => `${column.id}` === `${columnId}`,
          );
          const {docId: listDocId, primaryColId: listPrimaryColId} =
            columnData?.listConfig ?? {};

          const childLinks = Object.assign(
            {},
            rowObj?.[listPrimaryColId]?.childLinks?.[listDocId],
          );
          if (isEmpty(childLinks)) {
            ShowToast(
              `No column with valid value found for this action.`,
              userPref,
            );
          } else {
            const activeAppScreens = getSortedMiniAppsScreens(
              activeAppMeta,
              user.uid,
              state.miniApps.activeCustomRoleInfo,
            );
            const childScreenData = activeAppScreens.find(
              (screen) =>
                screen.screenId !== activeScreenId &&
                MINI_APPS.DOC_BASED_SCREEN_TYPES.includes(screen.type) &&
                screen.docs?.[0]?.docId === listDocId,
            );
            if (isEmpty(childScreenData)) {
              ShowToast(
                `You do not have access to view the contents of this file. Please contact the admin or owner of app.`,
                userPref,
              );
            } else {
              actionExecutorHelper
                .processOpenList(
                  {columnId, columnData, rowObj},
                  {
                    screenId: childScreenData.screenId,
                    screenName: childScreenData.screenName,
                    activeDocId,
                  },
                )
                .then((extra = {success: true, message: ''}) => {
                  if (!extra?.success && extra?.message?.length) {
                    ShowToast(extra.message, userPref);
                  }
                });
            }
          }
        } else {
          throw new Error('Column not found for this action.');
        }
        return null;
      }

      case ACTIONS.RUN_AUTOMATION: {
        if (
          currentScreenData.userPermission ===
          MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.VIEW //view only user
        ) {
          ShowToast(
            "You don't have permission to perform this action.",
            userPref,
          );
        } else if (actionBtnMeta.actionConfig?.automationId) {
          // Load Running Automations
          setReduxState(loadMiniAppAutomationConfig());
          const {
            automation: miniAppsAutomation,
            miniApps: {docsData},
          } = getReduxState();
          const activeDocAutomationConfig =
            miniAppsAutomation?.miniAppAutomationConfig?.[activeDocId];
          const {openEditor, runTimeConfig} =
            automationUtils.checkIfRuntimeAutomationConfigAvailable(
              activeDocAutomationConfig,
              actionBtnMeta.actionConfig.automationId,
              docsData,
            );
          if (openEditor) {
            if (isEmpty(runTimeConfig)) {
              return ShowToast(
                'No action config found for automation action',
                userPref,
              );
            }
            return actionExecutorHelper.processOpenAutomationRunTimeCellEditor({
              runTimeConfig,
              requestParams: {
                automationId: actionBtnMeta.actionConfig.automationId?.filter?.(
                  (automationId) =>
                    activeDocAutomationConfig?.[automationId]?.isEnabled,
                ),
                rowId,
                screenId: activeScreenId,
                actionBtnId,
                updateBtnStatus: true,
              },
              activeDocId,
              actionBtnMeta,
            });
          }
          setReduxState(
            triggerMiniAppAutomationManually({
              automationId: actionBtnMeta.actionConfig.automationId,
              name: actionBtnMeta?.name,
              rowId,
              screenId: activeScreenId,
              actionBtnId,
              updateBtnStatus: true,
            }),
          );
        } else {
          throw new Error('No action config found for automation action');
        }
        return null;
      }

      case ACTIONS.DOWNLOAD_PDF: {
        const {columnId, customNotificationTextObj} =
          actionBtnMeta.actionConfig ?? {};
        const columnData = documentData.headerData.find(
          (column) => `${column.id}` === `${columnId}`,
        );
        if (
          currentScreenData.userPermission ===
          MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.VIEW //view only user
        ) {
          ShowToast(
            "You don't have permission to perform this action.",
            userPref,
          );
        } else if (
          columnData?.pdfConfigId &&
          columnData.fieldType === FIELD_TYPE_ID.PDF &&
          typeof actionExecutorHelper?.processPDFUrl === 'function'
        ) {
          setReduxState(
            downloadPDFUsingActionButton({
              rowId,
              actionBtnId,
              screenId: activeScreenId,
              docId: activeDocId,
              pdfConfigId: columnData.pdfConfigId,
              pdfNamePrefix: columnData.pdfNamePrefix,
              pdfExportType: columnData.pdfExportType,
              onSuccess: actionExecutorHelper.processPDFUrl,
              customNotificationTextObj,
              // extra: actionExecutorHelper.pdfHelperFunctions,
            }),
          );
        } else {
          ShowToast('Column not found for this action.', userPref);
        }
        return null;
      }

      case ACTIONS.CALL:
      case ACTIONS.MESSAGE:
      case ACTIONS.WHATS_APP: {
        const [selectedColId, response] = getSelectedColumnWrapper(
          FIELD_TYPE_ID.CONTACT,
        );
        if (response) {
          return response;
        }
        if (!selectedColId) {
          return null;
        }

        const phoneNumber = rowObj[selectedColId]?.val;
        if (
          !phoneNumber ||
          !['string', 'number'].includes(typeof phoneNumber)
        ) {
          ShowToast('No phone number found in the record.', userPref);
          return null;
        }
        const actionKeyword =
          actionBtnType === ACTIONS.MESSAGE
            ? 'sms'
            : isAndroid() || isWeb
            ? 'tel'
            : 'telprompt';

        const link =
          actionBtnType === ACTIONS.WHATS_APP
            ? `https://wa.me/${phoneNumber}`
            : `${actionKeyword}:${phoneNumber}`;
        openLink(link);
        return null;
      }
      case ACTIONS.WHATSAPP_WITH_DATA: {
        const [selectedColId, response] = getSelectedColumnWrapper(
          FIELD_TYPE_ID.CONTACT,
        );
        if (response) {
          return response;
        }
        if (!selectedColId) {
          return null;
        }

        const phoneNumber = rowObj[selectedColId]?.val;

        let link = `https://wa.me/`;
        if (
          selectedColId !== 'CUSTOM_CONTACT' &&
          actionExecutorHelper?.selectedValue !== 'CUSTOM_CONTACT'
        ) {
          link = link + `${phoneNumber}`;
        }
        const {actionConfig} = actionBtnMeta;
        const {messageConfig} = actionConfig;
        const {text, variables} = messageConfig;
        const getVariableItemValue = (variable) => {
          switch (variable) {
            case SYSTEM_GENERATED_FIELDS_FOR_WHATSAPP_WITH_DATA_ACTION_BUTTON.APP_NAME: {
              return (
                miniApps?.[`miniApps`]?.[activeAppId]?.[`appName`] ??
                'App name not found'
              );
            }
            case SYSTEM_GENERATED_FIELDS_FOR_WHATSAPP_WITH_DATA_ACTION_BUTTON.SCREEN_NAME: {
              return (
                miniApps?.[`miniApps`]?.[activeAppId]?.[`screens`]?.[
                  activeScreenId
                ]?.[`screenName`] ?? 'Screen name not found'
              );
            }
            default: {
              const {headerData, headerDataAsObj, fileObj} = documentData;

              // If column is hidden then don't add data for the column in the message
              if (!headerData.some((colObj) => colObj.id === variable))
                return '';

              const userInfoForDataAddedBy = getOrganisationUserInfo(
                rowObj?.rowProperties?.firstAddedByUID,
                ownerUID,
                owner,
                membersList,
              );
              return convertCellDataToText(
                rowObj[variable],
                headerDataAsObj[variable],
                user.userCountry,
                userPref,
                fileObj,
                rowObj,
                true,
                userInfoForDataAddedBy,
              );
            }
          }
        };
        const getFinalEncodedURI = () => {
          let index = 0;
          const finalText =
            text.replace(/{{.*?}}/g, () => {
              const variable = variables[index];
              index++;
              return getVariableItemValue(variable) || '';
            }) || '';
          return encodeURIComponent(finalText);
        };

        const URIEncodedText = getFinalEncodedURI();
        link = link + `?text=${URIEncodedText}`;
        openLink(link);
        return null;
      }

      case ACTIONS.SEND_EMAIL: {
        const [selectedColId, response] = getSelectedColumnWrapper(
          FIELD_TYPE_ID.EMAIL,
        );
        if (response) {
          return response;
        }
        if (!selectedColId) {
          return null;
        }

        const email = rowObj[selectedColId]?.val;
        if (!email || !['string'].includes(typeof email)) {
          ShowToast('No email found in the record.', userPref);
          return null;
        }

        const link = `mailto:${email}`;
        openLink(link);
        return null;
      }

      case ACTIONS.OPEN_URL:
      case ACTIONS.COPY_URL: {
        const [selectedColId, response] = getSelectedColumnWrapper(
          FIELD_TYPE_ID.URL,
        );
        if (response) {
          return response;
        }
        if (!selectedColId) {
          return null;
        }

        const url = rowObj[selectedColId]?.val;
        if (!url || !['string'].includes(typeof url)) {
          ShowToast('No url found in the record.', userPref);
          return null;
        }

        if (actionBtnType === ACTIONS.OPEN_URL) {
          if (isWeb) {
            openLinkV2(url);
          } else {
            openLink(url).then((isSuccess) => {
              if (!isSuccess) {
                try {
                  if (typeof url === 'string' && !/^https?:\/\//i.test(url)) {
                    openLink(`http://${url}`);
                  }
                } catch {}
              }
            });
          }
        } else {
          if (isWeb) {
            navigator.clipboard.writeText(url);
          } else {
            copyToClipboard(url);
          }
          ShowToast('URL Copied', userPref);
        }
        return null;
      }

      //MULTIMEDIA ACTIONS : STARTS
      case ACTIONS.PLAY_AUDIO: {
        multiMediaFieldType = FIELD_TYPE_ID.AUDIO;
        break;
      }
      case ACTIONS.WATCH_VIDEO: {
        multiMediaFieldType = FIELD_TYPE_ID.VIDEO;
        break;
      }
      case ACTIONS.VIEW_ATTACHMENTS: {
        multiMediaFieldType = FIELD_TYPE_ID.DOCUMENT;
        break;
      }
      case ACTIONS.GET_DIRECTION: {
        multiMediaFieldType = FIELD_TYPE_ID.MAPS;
        break;
      }
      case ACTIONS.VIEW_IMAGE: {
        multiMediaFieldType = FIELD_TYPE_ID.IMAGE;
        break;
      }
      case ACTIONS.ADD_COMMENTS:
      case ACTIONS.VIEW_COMMENTS: {
        multiMediaFieldType = FIELD_TYPE_ID.COMMENT;
        break;
      }
      //MULTIMEDIA ACTIONS : ENDS
    }

    if (multiMediaFieldType in FIELD_TYPE_ID) {
      const allowEmpty = [ACTIONS.ADD_COMMENTS].includes(actionBtnType);
      const [selectedColId, response] = getSelectedColumnWrapper(
        multiMediaFieldType,
        allowEmpty,
      );
      if (response) {
        return response;
      }
      if (!selectedColId) {
        return null;
      }

      if (typeof actionExecutorHelper?.processMediaAction === 'function') {
        actionExecutorHelper.processMediaAction(
          selectedColId,
          rowObj,
          actionBtnType,
          activeDocId,
        );
      }
      return null;
    }
  } catch (error) {
    ShowToast(
      'Something went wrong, please try again',
      getReduxState().auth.userPref,
    );
    captureInfo(Object.assign({}, {actionBtnId, rowId}, actionBtnMeta));
    captureError(error);
    return null;
  }
};

const getSelectedColumn = (
  fieldType,
  {actionExecutorHelper, documentData, userPref, rowObj, allowEmpty},
  actionBtnMeta,
) => {
  const {
    ACTION_BUTTONS: {ACTIONS},
  } = MINI_APPS;
  let selectedColId;
  if (actionExecutorHelper?.selectedValue) {
    selectedColId = actionExecutorHelper?.selectedValue;
  } else {
    const availableColumns = documentData.headerData.filter(
      (item) =>
        getColumnFieldType(item) === fieldType &&
        (allowEmpty
          ? true
          : checkIfValueExistOnCell(rowObj?.[item.id], item, rowObj)),
    );
    [ACTIONS.WHATSAPP_WITH_DATA].includes(actionBtnMeta?.type) &&
      availableColumns.push({
        val: 'Choose from contacts',
        id: 'CUSTOM_CONTACT',
      });
    // above option handles the flow for choose from contact
    if (availableColumns.length === 0) {
      ShowToast(`No column with valid value found for this action.`, userPref);
    } else if (availableColumns.length === 1) {
      selectedColId = availableColumns[0].id;
    } else {
      //ask user to select column
      return [
        selectedColId,
        {
          userMultiSelect: true,
          options: availableColumns.map((item) => ({
            text: item.val,
            id: item.id,
          })),
          actionBtnType: actionBtnMeta?.type,
        },
      ];
    }
  }
  return [selectedColId];
};

const getActionButtonsArrayForScreen = (appMeta, screenId) => {
  const actionBtns = Object.assign(
    {},
    appMeta?.screens?.[screenId]?.actionBtns,
  );
  const sortedActionBtnArr = new LioArray();
  forOwn(actionBtns, (actionBtnData, actionBtnId) => {
    if (!isEmpty(actionBtnData)) {
      const filterOnRecord = {};
      if (
        actionBtnData.filterOnRecord?.type &&
        !isEmpty(actionBtnData.filterOnRecord.conditions)
      ) {
        const conditions = [];
        Object.keys(actionBtnData.filterOnRecord.conditions).forEach(
          (colId) => {
            const filterConditionData =
              actionBtnData.filterOnRecord.conditions[colId];
            if (Array.isArray(filterConditionData)) {
              filterConditionData.forEach((filterConditionObj) => {
                conditions.push(Object.assign({}, filterConditionObj, {colId}));
              });
            } else {
              conditions.push(Object.assign({}, filterConditionData, {colId}));
            }
          },
        );
        Object.assign(filterOnRecord, {
          type: actionBtnData.filterOnRecord.type,
          conditions,
        });
      }
      sortedActionBtnArr.binaryInsert(
        Object.assign({}, actionBtnData, {id: actionBtnId, filterOnRecord}),
        ['sortingIndex'],
        'duplicate',
        true,
      );
    }
  });
  return sortedActionBtnArr;
};

const isActionBtnLifecycleActive = ({
  appId,
  screenId,
  actionBtnMeta,
  rowObj,
  uid,
}) => {
  try {
    const isUserDependentLifecycle = true;
    const {ACTION_LIFECYCLE, ACTIONS} = MINI_APPS.ACTION_BUTTONS;
    const {id: actionBtnId, isCustom, lifecycle, type} = actionBtnMeta;
    if (
      isCustom &&
      lifecycle in ACTION_LIFECYCLE &&
      type === ACTIONS.RUN_AUTOMATION
    ) {
      const btnExecutionMeta = Object.assign(
        {},
        rowObj.rowProperties?.actionBtnMeta?.[appId]?.[screenId]?.[actionBtnId],
      );
      switch (lifecycle) {
        case ACTION_LIFECYCLE.ONCE_A_DAY: {
          let lastExecutionTs;
          if (isUserDependentLifecycle) {
            lastExecutionTs = btnExecutionMeta?.[uid]?.ts;
          } else {
            lastExecutionTs = Object.values(btnExecutionMeta)[0]?.ts;
          }
          const isEnabled =
            isNil(lastExecutionTs) ||
            !moment(lastExecutionTs).isSame(new Date(), 'day');
          return {
            isEnabled,
            message: 'This action can be executed only once a day.',
          };
        }
        // case ACTION_LIFECYCLE.ONCE_IN_LIFETIME: {
        //   const isEnabled = isUserDependentLifecycle
        //     ? isEmpty(btnExecutionMeta?.[uid])
        //     : isEmpty(btnExecutionMeta);
        //   return {
        //     isEnabled,
        //     message: 'This action can be executed only once.',
        //   };
        // }
      }
    }
  } catch (error) {
    captureError(error);
    return {
      isEnabled: false,
      message: 'Something went wrong, please try again',
    };
  }
  return {
    isEnabled: true,
  };
};

//!ANY CHANGES HERE SHOULD also be changed in rb-cloud-functions
const isActionBtnVisible = ({
  actionBtnMeta,
  rowObj,
  headerDataAsObj,
  fileObj,
  userMetaObj, //userCountry,userPref
  uid,
  customRoleInfo,
  appMeta,
  screenId,
  ignoreFilterConditionCheck = false,
  isUsedForRowRenderer,
}) => {
  const {
    ACTION_BUTTONS: {CUSTOM_ACTION_BUTTON_PROPERTIES},
  } = MINI_APPS;
  try {
    const {TRIGGER_CONDITION_CONFIG} = MINI_APPS.AUTOMATION;
    const {
      isCustom,
      filterOnRecord,
      id: actionBtnId,
      actionConfig,
    } = actionBtnMeta;
    const userPermission = appMeta.sharedWith?.[uid]?.permission;
    if (
      isUsedForRowRenderer &&
      actionConfig?.[CUSTOM_ACTION_BUTTON_PROPERTIES.HIDE_FOR_ROWS]
    ) {
      return {
        isVisible: false,
      };
    }
    if (
      userPermission === MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.VIEW ||
      (userPermission ===
        MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.CUSTOM_ROLE &&
        customRoleInfo?.screenConfiguration?.[screenId]
          ?.actionBtnsRestrictions?.[actionBtnId]?.isHidden)
    ) {
      //not visible to users with view permission or custom role with hidden action button
      return {
        isVisible: false,
      };
    }
    if (ignoreFilterConditionCheck) {
      return {
        isVisible: true,
      };
    }
    if (
      isCustom &&
      filterOnRecord.type &&
      Array.isArray(filterOnRecord.conditions) &&
      filterOnRecord.conditions.length
    ) {
      const conditionCheck = ({
        colId: columnId,
        val: filterVal,
        type: filterType,
        valType,
      }) => {
        return conditionCheckOnRecord(
          columnId,
          filterVal,
          filterType,
          valType,
          rowObj,
          headerDataAsObj,
          fileObj,
          userMetaObj,
          uid,
          {defaultReturn: false},
        );
      };

      const isVisible =
        filterOnRecord?.type === TRIGGER_CONDITION_CONFIG.ALL_SHOULD_BE_TRUE
          ? filterOnRecord.conditions.every(conditionCheck)
          : filterOnRecord.conditions.some(conditionCheck);
      return {
        isVisible,
      };
    }
  } catch (error) {
    captureError(error);
    return {
      isVisible: false,
      message: 'Something went wrong, please try again',
    };
  }
  return {
    isVisible: true,
  };
};

const getActionButtonsArrayForRow = ({
  actionBtns,
  rowObj,
  headerDataAsObj,
  fileObj,
  userMetaObj, //userCountry,userPref
  appMeta,
  customRoleInfo,
  uid,
  screenId,
  disableVisiblityCheck = false,
  onlyAutomations = false,
  isUsedForRowRenderer = true,
  activeDocumentId,
}) =>
  (Array.isArray(actionBtns) ? actionBtns : []).filter((actionBtnMeta) => {
    if (onlyAutomations) {
      const isAutomationActionBtn =
        actionBtnMeta?.type === MINI_APPS.ACTION_BUTTONS.ACTIONS.RUN_AUTOMATION;
      const isAutomationWithRunTimeConfig =
        isAutomationActionBtnWithRunTimeConfig(
          actionBtnMeta?.actionConfig?.automationId,
          activeDocumentId,
        );
      return !(!isAutomationActionBtn || isAutomationWithRunTimeConfig);
    }

    return isActionBtnVisible({
      actionBtnMeta,
      rowObj,
      headerDataAsObj,
      fileObj,
      userMetaObj, //userCountry,userPref
      uid,
      customRoleInfo,
      appMeta,
      screenId,
      ignoreFilterConditionCheck: disableVisiblityCheck,
      isUsedForRowRenderer,
    })?.isVisible;
  });

const getNonHiddenActionButtonsForRows = (appMeta, screenId) => {
  return getActionButtonsArrayForScreen(appMeta, screenId).filter(
    ({actionConfig}) =>
      !actionConfig?.[
        MINI_APPS.ACTION_BUTTONS.CUSTOM_ACTION_BUTTON_PROPERTIES.HIDE_FOR_ROWS
      ],
  );
};

const isAutomationActionBtnWithRunTimeConfig = (automationId, docId) => {
  const automationConfig =
    getReduxState()?.automation?.miniAppAutomationConfig?.[docId] || {};

  if (!Array.isArray(automationId)) {
    return false;
  }

  for (const id of automationId) {
    const config = automationConfig?.[id]?.defaultValConstantMap ?? {};
    for (const value of Object.values(config)) {
      if (
        value === AUTOMATION_COLUMN_VALUE_TYPES.SET_VALUE_ON_RUNTIME &&
        automationConfig?.[id]?.isEnabled
      ) {
        return true;
      }
    }
  }

  return false;
};

export {
  callActionButtonAction,
  getActionButtonsArrayForRow,
  getActionButtonsArrayForScreen,
  isActionBtnLifecycleActive,
  isActionBtnVisible,
  getNonHiddenActionButtonsForRows,
  isAutomationActionBtnWithRunTimeConfig,
};
