import {SEARCH_FILTER_ACTION} from '../actions/actionType';
import {SEARCH_FILTER_MODE} from '../utils/constant';

const initialState = {
  isLoading: false,
  isActive: false,
  searchFilterTableData: [],
  searchFilterRowIdDataMap: {},
  searchFilterFooterData: {},
  filterColumnInfo: {},
  mode: SEARCH_FILTER_MODE.SEARCH,
  currentSearchedText: '',
  countOfNonEmptyRows: 0,
};

const searchFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_FILTER_ACTION.UPDATE_SEARCH_FILTER_LOADING_STATE: {
      return {
        ...state,
        isLoading: Boolean(action.payload),
      };
    }
    case SEARCH_FILTER_ACTION.UPDATE_NON_EMPTY_ROW_COUNT: {
      return {
        ...state,
        countOfNonEmptyRows: action.payload,
      };
    }
    case SEARCH_FILTER_ACTION.UPDATE_CURRENT_SEARCHED_TEXT: {
      return {
        ...state,
        currentSearchedText:
          typeof action.payload === 'string' ? action.payload : '',
      };
    }
    case SEARCH_FILTER_ACTION.CHANGE_ACTIVE_STATE: {
      return {
        ...state,
        isActive: Boolean(action.payload.isActive),
        mode: action.payload.mode ?? SEARCH_FILTER_MODE.SEARCH,
      };
    }
    case SEARCH_FILTER_ACTION.UPDATE_SEARCH_FILTER_DATA: {
      return {
        ...state,
        searchFilterTableData: action.payload.tableData ?? [],
        searchFilterRowIdDataMap: Object.assign(
          {},
          action.payload.rowIdDataMap,
        ),
        searchFilterFooterData: Object.assign({}, action.payload.footerData),
      };
    }
    case SEARCH_FILTER_ACTION.UPDATE_FILTER_COLUMN_INFO: {
      return {
        ...state,
        filterColumnInfo: action.payload,
      };
    }
    case SEARCH_FILTER_ACTION.CLEAR_SEARCH_FILTER:
    case SEARCH_FILTER_ACTION.RESET_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default searchFilterReducer;
