import Building from '../assets/img/building.svg';
import {cloneDeep, isEmpty} from 'lodash';
import {SortMenuHeader} from '../components/dropDownMenu/menuComponents/headerComponents/SortMenuHeaderComponent';
import {
  deleteFile,
  duplicateFile,
  moveFile,
  renameFile,
} from 'rb-redux/actions/homeAction';
import {unshareDocWithTeam} from 'rb-redux/actions/teamActions';
import {shareFileToContacts} from 'rb-redux/actions/collabAction';
// import EditIcon from '@material-ui/icons/Edit';
import EditIcon from '../assets/img/editIcon.svg';
import ProfileIcon3 from '../assets/img/profileIcon3.svg';
import AllUsersIcon from '../assets/img/allUsersIcon.svg';
import CreatorIcon from '../assets/img/creatorIcon.svg';
import UserPermissionIcon from '../assets/img/userPermissionIcon.svg';
import UserListIcon from '../assets/img/userListIcon.svg';
import RenameIcon from '../assets/img/renameIcon.svg';

import SaveAsTemplateileIcon from '../assets/img/saveAsTemplate.svg';
import AddToFavourites from '../assets/img/addToFavouritesIcon.svg';
import DuplicateIcon from '../assets/img/duplicate.svg';
import DeleteIcon from '../assets/img/deleteIcon.svg';
import RedDeleteIcon from '../assets/img/redDeleteIcon.svg';
import ShareIcon from '../assets/img/shareIcon.svg';
import MoveFileIcon from '../assets/img/moveFile.svg';
import AutoReportPie from '../assets/img/autoReportPie.svg';
import AutoReportTriangle from '../assets/img/autoReportTriangle.svg';
import BrowseTemplateCircleSquares from '../assets/img/browseTemplateCircleSquares.svg';
import BrowseTemplateTriangle from '../assets/img/browseTemplateTriangleIcon.svg';
import CreateNewTeamIcon from '../assets/img/createNewTeamIcon.svg';
import CustomerSupportIcon from '../assets/img/customerSupportIcon.svg';
import HelpIcon from '../assets/img/helpIcon.svg';
import ContentDuplicateIcon from '../assets/img/content-duplicate.svg';
import ShareOutlineIcon from '../assets/img/share-outline.svg';
import VideoTutorialIcon from '../assets/img/videoTutorialIcon.svg';
import PremiumDiamondIcon from '../assets/img/premiumDiamondIcon.svg';
import ReportBugIcon from '../assets/img/reportBugIcon.svg';
import HelpIconApp from '../assets/img/helpIconApp.svg';
import RequestFeatureIcon from '../assets/img/requestFeature.svg';
import SmartTemplateLightning from '../assets/img/smartTemplateLightning.svg';
import adminIcon from '../assets/img/adminIcon.svg';
import entryOnlyIcon from '../assets/img/entryOnlyIcon.svg';
import viewOnlyIcon from '../assets/img/viewOnlyIcon.svg';
import VersionHistory from '../assets/img/versionHistory2.svg';
// import TeamsIcon from '../assets/img/teamsIcon.svg';
import TeamsIcon from '../assets/img/teamsIcon.svg';
import NewDocIconHead from '../assets/img/newDocIconHead.svg';
import NewDocIconBody from '../assets/img/newDocIconBody.svg';
import ImportFromExcelIcom from '../assets/img/importFromExcelIcon.svg';
import NewFolderIcon from '../assets/img/newFolderIcon.svg';
import SortByNameIcon from '../assets/img/sortByNameIcon.svg';
import SortByTypeIcon from '../assets/img/sortByTypeIcon.svg';
import SortByDateCreatedIcon from '../assets/img/sortByDateCreatedIcon.svg';
import SortByDateModifiedIcon from '../assets/img/sortByDateLastModifiedIcon.svg';
import AddTeamMembersIcon from '../assets/img/addTeamMembersIcon.svg';
import AddColLeftIcon from '../assets/img/addColLeftIcon.svg';
import AddColRightIcon from '../assets/img/addColRightIcon.svg';
import ColumnChangeIcon from '../assets/img/columnChangeIcon.svg';
import ConfigureIcon from '../assets/img/configureIcon.svg';
import ColSortAsc from '../assets/img/colSortAsc.svg';
import ColSortDesc from '../assets/img/colSortDesc.svg';
import CalculateIcon from '../assets/img/calculateIcon.svg';
import SetFormulaIcon from '../assets/img/setFormulaIcon.svg';
import FilterIcon from '../assets/img/filterIcon.svg';
import pdf from '../assets/img/pdfExport.svg';
import excel from '../assets/img/excelExport.svg';
import pdfSetting from '../assets/img/pdfCustom.svg';
import TeamSettingsIcon from '../assets/img/teamSettingsIcon.svg';
import IconComponent from '../components/dropDownMenu/iconComponents/IconComponent';
import TwoIconComponent from '../components/dropDownMenu/iconComponents/TwoIconComponent';
import {ProfileMenuHeader} from '../components/dropDownMenu/menuComponents/headerComponents/ProfileMenuHeaderComponent';
import FooterLogoutComponent from '../components/dropDownMenu/menuComponents/footerComponents/FooterLogoutComponent';
import SortByIcon from '../assets/img/sortByIconDark.svg';
import ClearRecordIcon from '../assets/img/clearRecordIcon.svg';
import ShareOnWhatsappIcon from '../assets/img/shareOnWhatsappIcon.svg';
import TimerIcon from '../assets/img/timerIcon.svg';
import InterktIcon from '../assets/img/interakt.svg';
import FacebookIcon from '../assets/img/facebook-icon.svg';
import {FooterCreateNewTeamComponent} from '../components/dropDownMenu/menuComponents/footerComponents/FooterCreateNewTeamComponent';
import {EmptyPlaceholderComponent} from '../components/dropDownMenu/menuItems/EmptyPlaceholderComponent';
import FilterList from '@material-ui/icons/FilterList';
import Code from '@material-ui/icons/Code';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {getAllTheMonths} from './getAllTheMonths';
import {getAllTheYears} from './getAllTheYears';
import History from '@material-ui/icons/History';
import {
  FIELD_TYPE_ID,
  FILES_SORT_OPTIONS,
  FOOTER_OPERATION_TEXT,
  FOOTER_OPERATION_TYPES,
  SPLIT_BY_ALLOWED_FIELDS,
} from 'rb-redux/utils/constant';
import sumIcon from '../assets/img/sigma.svg';
import avgIcon from '../assets/img/dividerAvg.svg';
import countIcon from '../assets/img/digits123.svg';
import rupeeIcon from '../assets/img/rupeeIcon.svg';
import textIcon from '../assets/img/textIcon.svg';
import {getLocalText} from 'rb-redux/utils/utils';
import {FIELD_TYPE_DETAIL} from '../containers/GridContainer/Constants/FieldTypes';
import CustomImage from '../assets/img/custom-share.png';
import rightArrowIcon from '../assets/img/rightArrowFilled.svg';
import entryOnlyCustomiseIcon from '../assets/img/entryOnlyCustomiseIcon.svg';
import DashboardCornerImage1 from '../assets/img/dashboardCornerImg1.svg';
import DashboardCornerImage2 from '../assets/img/dashboardCornerImg2.svg';
import DashboardCornerImage3 from '../assets/img/dashboardCornerImg3.svg';
import DashboardCornerImage4 from '../assets/img/dashboardCornerImg4.svg';
import DashboardCornerImage5 from '../assets/img/dashboardCornerImg5.svg';
import DashboardCornerImage6 from '../assets/img/dashboardCornerImg6.svg';
import DashboardCornerImage7 from '../assets/img/dashboardCornerImg7.svg';
import DashboardCornerImage8 from '../assets/img/dashboardCornerImg8.svg';
import AppExports from '../assets/img/appExports.svg';
import ExportData from '../assets/img/exportData.svg';
import ImportData from '../assets/img/importData.svg';
import theme from './designTheme';
import SettingsIconNew from '../assets/img/settingsIconNew.svg';
//import editLayoutIcon from '../assets/img/editLayoutIcon.svg';
import helpIcon from '../assets/img/helpIconMenuOption.svg';
import redDeleteIcon from '../assets/img/deleteRedOutlineIcon.svg';
import dashboardSumIcon from '../assets/img/sigma-dashboard.svg';
import dashboardAvgIcon from '../assets/img/math-compass.svg';
import dashboardCountIcon from '../assets/img/numeric-positive-1.svg';

import BlueTickIcon from '../assets/img/tick.svg';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import allAppsIcon from '../assets/img/inbox-full-outline.svg';
import {Step} from '@material-ui/core';
import CheckCircleIcon from '../assets/img/checkCircleOutline.svg';
import {ExternalToolTipHandler} from '../components/DoughnutChart/tooltip/DoughnutCharToolTip';

export const templateOptionData = [
  {
    img: Building,
    heading: 'Shop',
    text: '12 TEMPLATES',
  },
  {
    img: Building,
    heading: 'Shop',
    text: '12 TEMPLATES',
  },
  {
    img: Building,
    heading: 'Shop',
    text: '12 TEMPLATES',
  },
  {
    img: Building,
    heading: 'Shop',
    text: '12 TEMPLATES',
  },
  {
    img: Building,
    heading: 'Shop',
    text: '12 TEMPLATES',
  },
  {
    img: Building,
    heading: 'Shop',
    text: '12 TEMPLATES',
  },
  {
    img: Building,
    heading: 'Shop',
    text: '12 TEMPLATES',
  },
  {
    img: Building,
    heading: 'Shop',
    text: '12 TEMPLATES',
  },
];

export const NOTIFICATION_ICON_PROPS = {
  ALL_USERS: {
    isIcon: true,
    icon: AllUsersIcon,
    title: 'All Users',
    subtitle: 'Send notification to all members the app is shared',
  },
  ASSIGNEE: {
    isIcon: true,
    icon: ProfileIcon3,
    title: 'Assignee',
    subtitle: 'Send notification to user assigned with the record',
  },
  CREATOR: {
    isIcon: true,
    icon: CreatorIcon,
    title: 'Creator',
    subtitle: 'Send notification to user who created the record',
  },
  USER_PERMISSION: {
    isIcon: true,
    icon: UserPermissionIcon,
    title: 'Users based on Permission',
    subtitle: 'Send notification to specific roles or permissions',
  },
  USER_LIST: {
    isIcon: true,
    icon: UserListIcon,
    title: 'User List',
    subtitle: 'Send notification to list of users selected',
  },
};

export const documentListData = [
  {
    img: Building,
    heading: 'College Trip',
    text: '24 may 2021',
  },
];

export const profileList = [
  {
    text: 'Change Language',
    url: '/language-select',
  },
  // {
  //   text: 'Lock Your App',
  //   url: '/profile',
  // },
  // {
  //   text: 'Trash',
  //   url: '/trash',
  // },
  // {
  //   text: 'Contact Us',
  //   url: '/profile',
  // },
];

export const languageData = [
  {
    text: 'Hindi',
    background: '#F0F8FF',
    color: '#2082DD',
    name: 'hindi',
    symbolText: 'अ',
  },
  {
    text: 'English',
    background: '#F0FFFD',
    color: 'var(--primary)',
    symbolText: 'A',
    name: 'english',
  },
  {
    text: 'French',
    background: '#F0FFFD',
    color: 'var(--primary)',
    symbolText: 'U',
    name: 'french',
  },
  {
    text: 'Tamil',
    background: '#F0FFFD',
    color: 'var(--primary)',
    symbolText: 'T',
    name: 'tamil',
  },
  {
    text: 'Tamil',
    background: '#F0FFFD',
    color: 'var(--primary)',
    symbolText: 'T',
    name: 'tamil',
  },
  {
    text: 'Tamil',
    background: '#F0FFFD',
    color: 'var(--primary)',
    symbolText: 'T',
    name: 'tamil',
  },
];

export const unAvailableColumn = ['BARCODE'];

export const MAX_AUDIO_LIMIT = '1 MB';
export const MAX_AUDIO_TIMER_LIMIT = 60;
export const AUDIO_CHUNK_DURATION = 100;

export const getColorFromObj = (obj) => {
  const oldStyleObj = obj?.styleObj?.style?.react?.text;
  const newStyleObj = obj?.valStyleObj;
  const isStylingAvailable = !isEmpty(oldStyleObj);
  const isNewStylingObjAvailable = !isEmpty(newStyleObj);
  const getColor =
    isStylingAvailable && oldStyleObj?.color
      ? oldStyleObj?.color
      : isNewStylingObjAvailable && newStyleObj?.color
      ? newStyleObj?.color
      : '';
  const getBackgroundColor =
    isStylingAvailable && oldStyleObj?.backgroundColor
      ? oldStyleObj?.backgroundColor
      : isNewStylingObjAvailable && newStyleObj?.backgroundColor
      ? newStyleObj?.backgroundColor
      : '';
  return {
    color: getColor,
    backgroundColor: getBackgroundColor,
    isStylingAvailable,
    isNewStylingObjAvailable,
  };
};

export const ERROR_MESSAGE = '#FB3640';

export const TEAM_ICON_COLOR = ['#E76A6A', '#5F6FFF', '#2B9B8F', '#FFB800'];
// const ShareIconComponent = () =>

export const DOC_EXPORT_MENU_OPTIONS = {
  PDF: {
    TITLE: 'PDF',
    startIcon: <IconComponent option={pdf} />,
  },
  EXCEL: {
    TITLE: 'Excel',
    startIcon: <IconComponent option={excel} />,
  },
  CUSTOM_PDF: {
    TITLE: 'Customize PDF',
    startIcon: <IconComponent option={pdfSetting} />,
  },
};

export const IN_GRID_FILE_WITH_PAGES_MENU_OPTIONS = (showLeaveFile = false) => {
  return {
    RENAME: {
      TITLE: 'Rename',
      startIcon: <IconComponent option={EditIcon} />,
    },
    MOVE: {
      TITLE: 'Move file',
      startIcon: <IconComponent option={MoveFileIcon} />,
    },
    VERSION_HISTORY: {
      TITLE: 'Version History',
      startIcon: (
        <IconComponent
          option={VersionHistory}
          containerStyle={{marginLeft: '-4px', marginTop: '5px'}}
          elementStyle={{width: '16px', height: '16px'}}
        />
      ),
    },
    DISABLE_PAGES: {
      TITLE: 'Disable Pages',
      startIcon: <IconComponent option={DuplicateIcon} />,
    },
    SAVE_AS_TEMPLATE: {
      TITLE: 'Save as a template',
      startIcon: <IconComponent option={SaveAsTemplateileIcon} />,
    },
    DUPLICATE: {
      TITLE: 'Duplicate',
      startIcon: <IconComponent option={DuplicateIcon} />,
    },
    // DELETE:   {
    //   TITLE: showLeaveFile ? 'Leave Shared File' : 'Delete',
    //   COLOR: '#F5222D',
    //   startIcon: <IconComponent option={RedDeleteIcon} />,
    // },
  };
};

export const IN_GRID_FILE_MENU_OPTIONS = (showLeaveFile = false) => {
  return {
    RENAME: {
      TITLE: 'Rename',
      startIcon: <IconComponent option={EditIcon} />,
    },
    MOVE: {
      TITLE: 'Move file',
      startIcon: <IconComponent option={MoveFileIcon} />,
    },
    VERSION_HISTORY: {
      TITLE: 'Version History',
      startIcon: (
        <IconComponent
          option={VersionHistory}
          containerStyle={{marginLeft: '-4px', marginTop: '5px'}}
          elementStyle={{width: '16px', height: '16px'}}
        />
      ),
    },
    SAVE_AS_TEMPLATE: {
      TITLE: 'Save as a template',
      startIcon: <IconComponent option={SaveAsTemplateileIcon} />,
    },
    DUPLICATE: {
      TITLE: 'Duplicate',
      startIcon: <IconComponent option={DuplicateIcon} />,
    },
    // DELETE: {
    //   TITLE: showLeaveFile ? 'Leave Shared File' : 'Delete',
    //   COLOR: '#F5222D',
    //   startIcon: <IconComponent option={RedDeleteIcon} />,
    // },
  };
};

export const FILE_MENU_OPTIONS = (
  showLeaveFile = false,
  condition = false,
  docId,
  teamId,
) => {
  let obj = {
    // SHARE: {
    //   TITLE: 'Share',
    //   fn: shareFileToContacts,
    //   startIcon: <IconComponent option={ShareIcon} />,
    // },
    RENAME: {
      TITLE: 'Rename',
      fn: renameFile,
      startIcon: <IconComponent option={EditIcon} />,
    },
    // ADD_TO_FAVOURITES: {
    //   TITLE: 'Add to Favourites',
    //   startIcon: <IconComponent option={AddToFavourites} />,
    // },
    // REMOVE_TO_FAVOURITES: {TITLE:'Remove from Favourites',startIcon: <EditIcon/>},
    MOVE: {
      TITLE: 'Move',
      fn: moveFile,
      startIcon: <IconComponent option={MoveFileIcon} />,
    },
    SAVE_AS_TEMPLATE: {
      TITLE: 'Save as Template',
      startIcon: <IconComponent option={SaveAsTemplateileIcon} />,
    },
    DUPLICATE: {
      TITLE: 'Duplicate',
      fn: duplicateFile,
      startIcon: <IconComponent option={DuplicateIcon} />,
    },
    DELETE: {
      TITLE: showLeaveFile ? 'Leave Shared File' : 'Delete',
      fn: deleteFile,
      startIcon: <IconComponent option={DeleteIcon} />,
    },
  };
  // if (condition) {
  //   obj['UNSHARE_FILE'] = {
  //     TITLE: 'Stop Sharing With Team',
  //     fn: () => unshareDocWithTeam(docId, teamId),
  //     startIcon: <IconComponent option={EditIcon} />,
  //   };
  // }
  return obj;
};

export const FOLDER_MENU_OPTIONS = {
  // SHARE: {
  //   TITLE: 'Share',
  //   fn: shareFileToContacts,
  //   startIcon: <IconComponent option={ShareIcon} />,
  // },
  RENAME: {
    TITLE: 'Rename',
    fn: renameFile,
    startIcon: <IconComponent option={EditIcon} />,
  },
  // ADD_TO_FAVOURITES: {
  //   TITLE: 'Add to Favourites',
  //   startIcon: <IconComponent option={AddToFavourites} />,
  // },
  // REMOVE_TO_FAVOURITES: {TITLE:'Remove from Favourites',startIcon: <EditIcon/>},
  MOVE: {
    TITLE: 'Move',
    fn: moveFile,
    startIcon: <IconComponent option={MoveFileIcon} />,
  },
  DELETE: {
    TITLE: 'Delete',
    fn: deleteFile,
    startIcon: <IconComponent option={DeleteIcon} />,
  },
};

export const TEAMS_SETTING_MENU_OPTIONS = {
  HEADING: {},
  TOP_RIGHT_OPTION: {},
  ADD_MEMBERS: {
    TITLE: 'Add Members',
    startIcon: <IconComponent option={AddTeamMembersIcon} />,
  },
  TEAM_SETTINGS: {
    TITLE: 'Team Settings',
    startIcon: <IconComponent option={TeamSettingsIcon} />,
  },
  // RENAME: {TITLE: 'Rename', startIcon: <IconComponent option={EditIcon} />},
  // DELETE: {TITLE: 'Delete', startIcon: <IconComponent option={DeleteIcon} />},
};

export const PERMISSION_MENU = {
  ADMIN: {
    TITLE: 'Admin',
    startIcon: <IconComponent option={adminIcon} />,
  },
  CAN_EDIT: {TITLE: 'Can edit', startIcon: <IconComponent option={EditIcon} />},
  ENTRY_ONLY: {
    TITLE: 'Entry only',
    startIcon: <IconComponent option={entryOnlyIcon} />,
    endIcon: <IconComponent option={rightArrowIcon} />,

    SUB_MENU: {
      ENTRY_ONLY_DEFAULT: {
        TITLE: 'Entry - Default',
        startIcon: <IconComponent option={entryOnlyIcon} />,
      },
      ENTRY_ONLY_CUSTOMISE: {
        TITLE: 'Entry - Customise',
        startIcon: <IconComponent option={entryOnlyCustomiseIcon} />,
      },
    },
  },
  CAN_VIEW: {
    TITLE: 'Can view',
    startIcon: <IconComponent option={viewOnlyIcon} />,
  },
};
export const PERMISSION_MENU_COPY = {
  ADMIN: {
    TITLE: 'Admin',
    startIcon: <IconComponent option={adminIcon} />,
  },
  CAN_EDIT: {TITLE: 'Can edit', startIcon: <IconComponent option={EditIcon} />},
  ENTRY_ONLY: {
    TITLE: 'Entry only',
    startIcon: <IconComponent option={entryOnlyIcon} />,
    endIcon: <IconComponent option={rightArrowIcon} />,
    SUB_MENU: {
      ENTRY_ONLY_DEFAULT: {
        TITLE: 'Entry - Default',
        startIcon: <IconComponent option={entryOnlyIcon} />,
      },
      ENTRY_ONLY_CUSTOMISE: {
        TITLE: 'Entry - Customise',
        startIcon: <IconComponent option={entryOnlyCustomiseIcon} />,
      },
    },
  },
  CAN_VIEW: {
    TITLE: 'Can view',
    startIcon: <IconComponent option={viewOnlyIcon} />,
  },
  CUSTOM: {
    TITLE: 'Custom',
    startIcon: <img src={CustomImage} width="16" height="16" />,
  },
};

export const CREATE_NEW_MENU_OPTIONS = {
  NEW_DOCUMENT: {
    TITLE: 'New Document',
    startIcon: (
      <TwoIconComponent
        option1={NewDocIconHead}
        option2={NewDocIconBody}
        styleInfo={{position: 'relative', top: '-10px', left: '10px'}}
      />
    ),
  },
  NEW_FOLDER: {
    TITLE: 'New Folder',
    startIcon: (
      <IconComponent
        option={NewFolderIcon}
        containerStyle={{width: '14px', textAlign: 'right'}}
      />
    ),
  },
  IMPORT_FROM_EXCEL: {
    TITLE: 'Import from Excel',
    startIcon: <IconComponent option={ImportFromExcelIcom} />,
  },
};
export const TEAMS_SHORTCUT_MENU_OPTIONS = {
  HEADING: 'Teams',
  TOP_RIGHT_OPTION: {},
  TEAMS: [],
  EMPTY_PLACEHOLDER: <EmptyPlaceholderComponent />,
  FOOTER: <FooterCreateNewTeamComponent />,
};

export const SORT_MENU_OPTIONS = {
  HEADER: <SortMenuHeader />,
  HEADING: {},
  TOP_RIGHT_OPTION: {},
  NAME: {
    TITLE: FILES_SORT_OPTIONS.NAME,
    startIcon: <IconComponent option={SortByNameIcon} />,
    ACTIVE: false,
  },
  OPENED_DATE: {
    TITLE: FILES_SORT_OPTIONS.OPENED_DATE,
    startIcon: <IconComponent option={SortByDateModifiedIcon} />,
    ACTIVE: false,
  },
  DATE: {
    TITLE: FILES_SORT_OPTIONS.DATE,
    startIcon: <IconComponent option={SortByDateCreatedIcon} />,
    ACTIVE: false,
  },
  FOOTER: {},
  EMPTY_PLACEHOLDER: {},
};

export const TEMPLATE_MENU_OPTIONS = {
  HEADING: {},
  TOP_RIGHT_OPTION: {},
  BROWSE_TEMPLATES: {
    TITLE: 'Browse Templates',
    startIcon: (
      <TwoIconComponent
        option1={BrowseTemplateTriangle}
        option2={BrowseTemplateCircleSquares}
        styleInfo={{position: 'absolute', top: '2px'}}
      />
    ),
  },
  // AUTO_REPORTS: {
  //   TITLE: 'Auto Reports',
  //   startIcon: (
  //     <TwoIconComponent
  //       option1={AutoReportTriangle}
  //       option2={AutoReportPie}
  //       containerStyle={{position: 'relative', margin: '2px'}}
  //       styleInfo={{position: 'absolute', top: '2px', right: '5px'}}
  //     />
  //   ),
  // },
  // SMART_TEMPLATES: {
  //   TITLE: 'Smart Templates',
  //   startIcon: (
  //     <IconComponent
  //       option={SmartTemplateLightning}
  //       containerStyle={{margin: '2px'}}
  //     />
  //   ),
  // },
  EMPTY_PLACEHOLDER: {},
};

const HeadingBadge = () => (
  <div
    style={{
      width: '10px',
      height: '10px',
      position: 'relative',
      top: '-23px',
      left: '60px',
      color: 'white',
      border: 'solid red',
      borderRadius: '10px',
      backgroundColor: 'red',
      fontSize: '8px',
      textAlign: 'center',
    }}>
    <span>4</span>
  </div>
);

export const NOTIFICATION_DROPDOWN = {
  HEADING: 'Notifications',
  HEADING_BADGE: <HeadingBadge />,
  TOP_RIGHT_OPTION: {},
  BROWSE_TEMPLATES: {TITLE: 'Browse Templates'},
  AUTO_REPORTS: {TITLE: 'Auto Reports'},
  SMART_TEMPLATES: {TITLE: 'Smart Templates'},
  EMPTY_PLACEHOLDER: {},
  DUMMY_DATA: {
    1: {
      PIC: require('../assets/img/profilepic.png'),
      TEXT: 'Anurag shared a file Customer new requested features',
      TIME: 'Just now',
    },
    2: {
      PIC: require('../assets/img/profilepic.png'),
      TEXT: 'Anupam added you to the team Web Engineering',
      TIME: '30m ago',
    },
    3: {
      PIC: require('../assets/img/profilepic.png'),
      TEXT: 'Santhosh Sivan shared a file Order details from Flipkart Integration...',
      TIME: '2h ago',
    },
    4: {
      PIC: require('../assets/img/profilepic.png'),
      TEXT: 'Anurag shared a file Customer new requested features',
      TIME: 'Today, 11:30am',
    },
  },
};

export const HELP_MENU_OPTIONS = {
  HEADING: {},
  TOP_RIGHT_OPTION: {},
  // HELP_DOCS: {
  //   TITLE: 'Help docs',
  //   startIcon: <IconComponent option={HelpIcon} />,
  // },
  VIDEO_TUTORIALS: {
    TITLE: 'Video tutorials',
    startIcon: <IconComponent option={VideoTutorialIcon} />,
  },
  // REQUEST_FEATURE: {
  //   TITLE: 'Request a feature',
  //   startIcon: <IconComponent option={RequestFeatureIcon} />,
  // },
  // REPORT_ISSUE: {
  //   TITLE: 'Report an issue',
  //   startIcon: <IconComponent option={ReportBugIcon} />,
  // },
  // CUSTOMER_SUPPORT: {
  //   TITLE: 'Customer support',
  //   startIcon: <IconComponent option={CustomerSupportIcon} />,
  // },
  EMPTY_PLACEHOLDER: {},
};
export const MY_TEMPLATES_MENU_OPTIONS = {
  HEADING: {},
  TOP_RIGHT_OPTION: {},
  RENAME: {
    TITLE: 'Rename',
    startIcon: <IconComponent option={EditIcon} />,
  },
  DELETE: {
    TITLE: 'Delete',
    startIcon: <IconComponent option={DeleteIcon} />,
  },
  EMPTY_PLACEHOLDER: {},
};

export const TRASH_MENU_OPTIONS = {
  HEADING: {},
  TOP_RIGHT_OPTION: {},
  RESTORE: {
    TITLE: 'Restore',
    startIcon: <IconComponent option={MoveFileIcon} />,
  },
  DELETE: {
    TITLE: 'Delete forever',
    startIcon: <IconComponent option={DeleteIcon} />,
  },
  EMPTY_PLACEHOLDER: {},
};

export const ONBOARDING_MENU_ITEMS = {
  CHANGE_LANGUAGE: {TITLE: 'Change language'},
  FOOTER: <FooterLogoutComponent />,
};
export const PREMIUM_PROFILE_MENU_OPTIONS = {
  HEADING_BADGE: () => (
    <div
      style={{
        border: 'solid red',
        borderRadius: '10px',
        backgroundColor: 'red',
      }}>
      4
    </div>
  ),
  TOP_RIGHT_OPTION: {},
  HEADER: <ProfileMenuHeader />,
  ACCOUNT_SETTINGS: {TITLE: 'Settings'},
  CHANGE_LANGUAGE: {TITLE: 'Change language'},
  // ACTIVITY: { TITLE: 'Activity' },
  FOOTER: <FooterLogoutComponent />,
  EMPTY_PLACEHOLDER: {},
};

export const PROFILE_MENU_OPTIONS = {
  HEADING_BADGE: () => (
    <div
      style={{
        border: 'solid red',
        borderRadius: '10px',
        backgroundColor: 'red',
      }}>
      4
    </div>
  ),
  TOP_RIGHT_OPTION: {},
  HEADER: <ProfileMenuHeader />,
  ACCOUNT_SETTINGS: {TITLE: 'Account settings'},
  CHANGE_LANGUAGE: {TITLE: 'Change language'},
  // ACTIVITY: {TITLE: 'Activity'},
  // UPGRADE_PREMIUM: {
  //   TITLE: 'Upgrade to Premium',
  //   ACTIVE: true,
  //   endIcon: <IconComponent option={PremiumDiamondIcon} />,
  // },
  FOOTER: <FooterLogoutComponent />,
  EMPTY_PLACEHOLDER: {},
};

// export const GRID_MENU = {
//   CONFIGURE: {
//     TITLE: 'Configure',
//     startIcon: (
//       <IconComponent
//         option={ConfigureIcon}
//         containerStyle={{width: '16px', height: '16px'}}
//       />
//     ),
//     // startIcon: <I EditIcon />,
//     // order: 1,
//     // openModal: openRenameModal,
//     // closeModal: closeRenameModal,
//     // modalSubmit: handleRenameModalSubmit,
//   },
//   CHANGE_COLUMN_TYPE: {
//     TITLE: 'Rename/Edit Column',
//     startIcon: (
//       <IconComponent
//         option={ColumnChangeIcon}
//         containerStyle={{width: '16px', height: '16px'}}
//       />
//     ),
//     // startIcon: <I EditIcon />,
//     // order: 1,
//     // openModal: openRenameModal,
//     // closeModal: closeRenameModal,
//     // modalSubmit: handleRenameModalSubmit,
//   },
//   SORT_DESCENDING: {
//     TITLE: 'Sort Descending',
//     startIcon: (
//       <IconComponent
//         option={ColSortDesc}
//         containerStyle={{width: '16px', height: '16px'}}
//       />
//     ),
//     // startIcon: <I EditIcon />,
//     // order: 1,
//     // openModal: openRenameModal,
//     // closeModal: closeRenameModal,
//     // modalSubmit: handleRenameModalSubmit,
//   },
//   SORT_ASCENDING: {
//     TITLE: 'Sort Ascending',
//     startIcon: (
//       <IconComponent
//         option={ColSortAsc}
//         containerStyle={{width: '16px', height: '16px'}}
//       />
//     ),
//     // startIcon: <I EditIcon />,
//     // order: 1,
//     // openModal: openRenameModal,
//     // closeModal: closeRenameModal,
//     // modalSubmit: handleRenameModalSubmit,
//   },

//   // RENAME: {
//   //   TITLE: 'Rename',
//   //   startIcon: (
//   //     <IconComponent
//   //       option={EditIcon}
//   //       containerStyle={{width: '16px', height: '16px'}}
//   //     />
//   //   ),
//   //   // startIcon: <I EditIcon />,
//   //   // order: 1,
//   //   // openModal: openRenameModal,
//   //   // closeModal: closeRenameModal,
//   //   // modalSubmit: handleRenameModalSubmit,
//   // },
//   FILTER: {
//     TITLE: 'Filter',
//     startIcon: (
//       <IconComponent
//         option={FilterIcon}
//         containerStyle={{width: '16px', height: '16px'}}
//       />
//     ),
//     // startIcon: <FilterList />,
//     // order: 2,
//     // openModal: openFilterModal,
//     // closeModal: closeFilterModal,
//     // modalSubmit: () => {},
//   },
//   SET_FORMULA: {
//     TITLE: 'Set Formula',
//     startIcon: (
//       <IconComponent
//         option={SetFormulaIcon}
//         containerStyle={{width: '16px', height: '16px'}}
//       />
//     ),
//     // startIcon: <Code />,
//     // order: 4,
//     // openModal: openFormulaModal,
//     // closeModal: closeFormulaModal,
//     // modalSubmit: () => {},
//   },
//   ADD_COLUMN_TO_LEFT: {
//     TITLE: 'Add Column to Left',
//     startIcon: (
//       <IconComponent
//         option={AddColLeftIcon}
//         containerStyle={{width: '16px', height: '16px'}}
//       />
//     ),
//     // startIcon: <KeyboardArrowLeft />,
//     // order: 5,
//     // openModal: () => {
//     //   addColMid(true);
//     // },
//     // closeModal: closeRenameModal,
//     // modalSubmit: () => {},
//   },
//   ADD_COLUMN_TO_RIGHT: {
//     TITLE: 'Add Column to Right',
//     startIcon: (
//       <IconComponent
//         option={AddColRightIcon}
//         containerStyle={{width: '16px', height: '16px'}}
//       />
//     ),
//     // startIcon: <KeyboardArrowRight />,
//     // order: 6,
//     // openModal: () => {
//     //   addColMid(false);
//     // },
//     // closeModal: closeRenameModal,
//     // modalSubmit: () => {},
//   },
//   DELETE_COLUMN: {
//     TITLE: 'Delete Column',
//     startIcon: (
//       <IconComponent
//         option={RedDeleteIcon}
//         containerStyle={{width: '16px', height: '16px'}}
//       />
//     ),
//     color: '#F5222D',
//     // startIcon: <DeleteIcon />,
//     // order: 9,
//     // openModal: openDeleteModal,
//     // closeModal: closeDeleteModal,
//     // modalSubmit: handleDelete,
//   },
// };

// test purpose only, remove one of 2
export const GRID_MENU = {
  CONFIGURE: {
    TITLE: 'Configure',
    startIcon: (
      <IconComponent
        option={ConfigureIcon}
        containerStyle={{width: '16px', height: '16px'}}
      />
    ),
    restricted_Field_Type: [
      FIELD_TYPE_ID.TEXT,
      FIELD_TYPE_ID.COMMENT,
      FIELD_TYPE_ID.NUMBER,
      FIELD_TYPE_ID.RUPEE,
      FIELD_TYPE_ID.OPERATION,
      FIELD_TYPE_ID.NUMBER_CONSTANT,
      FIELD_TYPE_ID.DATE,
      FIELD_TYPE_ID.SWITCH,
      FIELD_TYPE_ID.TIME,
      FIELD_TYPE_ID.CHECKBOX,
      FIELD_TYPE_ID.IMAGE,
      FIELD_TYPE_ID.DOCUMENT,
      FIELD_TYPE_ID.REMINDER,
      FIELD_TYPE_ID.MAPS,
      FIELD_TYPE_ID.AUDIO,
      FIELD_TYPE_ID.BARCODE,
      FIELD_TYPE_ID.PERCENT,
      FIELD_TYPE_ID.ASSIGN_TASK,
      FIELD_TYPE_ID.URL,
      FIELD_TYPE_ID.CREATED_INFO,
      FIELD_TYPE_ID.EMAIL,
      FIELD_TYPE_ID.VIDEO,
      FIELD_TYPE_ID.LIST,
      FIELD_TYPE_ID.AUTO_INCREMENT_ID,
      FIELD_TYPE_ID.OTP,
    ],
    // startIcon: <I EditIcon />,
    // order: 1,
    // openModal: openRenameModal,
    // closeModal: closeRenameModal,
    // modalSubmit: handleRenameModalSubmit,
  },
  CHANGE_COLUMN_TYPE: {
    TITLE: 'Rename/Edit Column',
    startIcon: (
      <IconComponent
        option={ColumnChangeIcon}
        containerStyle={{width: '16px', height: '16px'}}
      />
    ),
    restricted_Field_Type: [FIELD_TYPE_ID.CREATED_INFO],
    // startIcon: <I EditIcon />,
    // order: 1,
    // openModal: openRenameModal,
    // closeModal: closeRenameModal,
    // modalSubmit: handleRenameModalSubmit,
  },
  SORT_DESCENDING: {
    TITLE: 'Sort Descending',
    startIcon: (
      <IconComponent
        option={ColSortDesc}
        containerStyle={{width: '16px', height: '16px'}}
      />
    ),
    restricted_Field_Type: [
      FIELD_TYPE_ID.VIDEO,
      FIELD_TYPE_ID.USER_COLUMN,
      FIELD_TYPE_ID.LIST,
    ],
    // startIcon: <I EditIcon />,
    // order: 1,
    // openModal: openRenameModal,
    // closeModal: closeRenameModal,
    // modalSubmit: handleRenameModalSubmit,
  },
  SORT_ASCENDING: {
    TITLE: 'Sort Ascending',
    startIcon: (
      <IconComponent
        option={ColSortAsc}
        containerStyle={{width: '16px', height: '16px'}}
      />
    ),
    restricted_Field_Type: [
      FIELD_TYPE_ID.VIDEO,
      FIELD_TYPE_ID.USER_COLUMN,
      FIELD_TYPE_ID.LIST,
    ],
    // startIcon: <I EditIcon />,
    // order: 1,
    // openModal: openRenameModal,
    // closeModal: closeRenameModal,
    // modalSubmit: handleRenameModalSubmit,
  },

  // RENAME: {
  //   TITLE: 'Rename',
  //   startIcon: (
  //     <IconComponent
  //       option={EditIcon}
  //       containerStyle={{width: '16px', height: '16px'}}
  //     />
  //   ),
  //   // startIcon: <I EditIcon />,
  //   // order: 1,
  //   // openModal: openRenameModal,
  //   // closeModal: closeRenameModal,
  //   // modalSubmit: handleRenameModalSubmit,
  // },
  FILTER: {
    TITLE: 'Filter',
    startIcon: (
      <IconComponent
        option={FilterIcon}
        containerStyle={{width: '16px', height: '16px'}}
      />
    ),
    restricted_Field_Type: [
      FIELD_TYPE_ID.VIDEO,
      FIELD_TYPE_ID.USER_COLUMN,
      FIELD_TYPE_ID.LIST,
    ],
    // startIcon: <FilterList />,
    // order: 2,
    // openModal: openFilterModal,
    // closeModal: closeFilterModal,
    // modalSubmit: () => {},
  },
  SET_FORMULA: {
    TITLE: 'Set Formula',
    startIcon: (
      <IconComponent
        option={SetFormulaIcon}
        containerStyle={{width: '16px', height: '16px'}}
      />
    ),
    restricted_Field_Type: [
      FIELD_TYPE_ID.VIDEO,
      FIELD_TYPE_ID.CREATED_INFO,
      FIELD_TYPE_ID.USER_COLUMN,
      FIELD_TYPE_ID.LIST,
    ],
    // startIcon: <Code />,
    // order: 4,
    // openModal: openFormulaModal,
    // closeModal: closeFormulaModal,
    // modalSubmit: () => {},
  },
  ADD_COLUMN_TO_LEFT: {
    TITLE: 'Add Column to Left',
    startIcon: (
      <IconComponent
        option={AddColLeftIcon}
        containerStyle={{width: '16px', height: '16px'}}
      />
    ),
    restricted_Field_Type: [],
    // startIcon: <KeyboardArrowLeft />,
    // order: 5,
    // openModal: () => {
    //   addColMid(true);
    // },
    // closeModal: closeRenameModal,
    // modalSubmit: () => {},
  },
  ADD_COLUMN_TO_RIGHT: {
    TITLE: 'Add Column to Right',
    startIcon: (
      <IconComponent
        option={AddColRightIcon}
        containerStyle={{width: '16px', height: '16px'}}
      />
    ),
    restricted_Field_Type: [],
    // startIcon: <KeyboardArrowRight />,
    // order: 6,
    // openModal: () => {
    //   addColMid(false);
    // },
    // closeModal: closeRenameModal,
    // modalSubmit: () => {},
  },
  DELETE_COLUMN: {
    TITLE: 'Delete Column',
    startIcon: (
      <IconComponent
        option={RedDeleteIcon}
        containerStyle={{width: '16px', height: '16px'}}
      />
    ),
    restricted_Field_Type: [],
    color: '#F5222D',
    // startIcon: <DeleteIcon />,
    // order: 9,
    // openModal: openDeleteModal,
    // closeModal: closeDeleteModal,
    // modalSubmit: handleDelete,
  },
};

export const MONTH_DICT = {
  Jan: {TITLE: 'January', INDEX: 0},
  Feb: {TITLE: 'Feburary', INDEX: 1},
  Mar: {TITLE: 'March', INDEX: 2},
  Apr: {TITLE: 'April', INDEX: 3},
  May: {TITLE: 'May', INDEX: 4},
  Jun: {TITLE: 'June', INDEX: 5},
  Jul: {TITLE: 'July', INDEX: 6},
  Aug: {TITLE: 'August', INDEX: 7},
  Sep: {TITLE: 'September', INDEX: 8},
  Oct: {TITLE: 'October', INDEX: 9},
  Nov: {TITLE: 'November', INDEX: 10},
  Dec: {TITLE: 'December', INDEX: 11},
};

export const MONTHS_IN_YEAR_ARRAY = getAllTheMonths();

const YEARS = getAllTheYears();

const yearGenerator = {
  years: YEARS,
  [Symbol.iterator]: function* () {
    for (let year of this.years) {
      yield {TITLE: String(year), YEAR: year};
    }
  },
};

export const YEAR_DICT = {...[...yearGenerator]};

export const DASHBOARD_OPTIONS_MENU = (showEdit = true) => {
  // ADD_TO_FAVOURITES: {
  //   TITLE: 'Add to favourites'
  // },
  return {
    ...(showEdit
      ? {
          EDIT: {
            TITLE: 'Edit Board',
            startIcon: <IconComponent option={EditIcon} />,
          },
        }
      : []),
    DELETE: {
      TITLE: 'Delete',
      COLOR: theme.palette.redText2,
      startIcon: <IconComponent option={redDeleteIcon} />,
    },
  };
};

const gridLineColor = '#EBF2FD';
const labelColor = '#556A89';

export const doughnutChartOptions = (columnDetails) => {
  return {
    cutout: '60%',
    responsive: true,
    plugins: {
      legend: {display: false},
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: (context) => ExternalToolTipHandler(context, columnDetails),
      },
    },
    animation: false,
  };
};
export const pieChartOptions = (columnDetails) => {
  return {
    responsive: true,
    plugins: {
      legend: {display: false},
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: (context) => ExternalToolTipHandler(context, columnDetails),
      },
    },
    animation: false,
  };
};
export const BAR_CHART_OPTIONS = {
  indexAxis: 'x',
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: true,
      ticks: {
        font: {size: 10, color: labelColor},
        callback: function (value) {
          const label = this.getLabelForValue(value) || '';
          return label.length > 8 ? label.substr(0, 6) + '...' : label;
        },
      },
      grid: {
        display: false,
        borderColor: gridLineColor,
      },
    },
    y: {
      display: true,
      ticks: {
        size: 8,
        display: false,
      },
      grid: {
        display: true,
        color: (context) => (context.tick.value === 0 ? '#ddd' : gridLineColor),
        drawBorder: false,
      },
    },
  },
  responsive: true,
};

export const BAR_CHART_OPTIONS_LG = cloneDeep(BAR_CHART_OPTIONS);
BAR_CHART_OPTIONS_LG.scales.x.ticks = {
  font: {
    size: 10,
    color: labelColor,
    style: 'normal',
    family:
      " -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  },
  callback: function (value) {
    const label = this.getLabelForValue(value) || '';
    return label.length > 15 ? label.substr(0, 13) + '...' : label;
  },
};
BAR_CHART_OPTIONS_LG.scales.y.ticks.display = true;

export const STACKED_BAR_CHART_OPTIONS = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
      display: true,
      ticks: {
        font: {size: 10, color: labelColor},
        callback: function (value) {
          const label = this.getLabelForValue(value) || '';
          return label.length > 8 ? label.substr(0, 6) + '...' : label;
        },
      },
      grid: {
        display: false,
        borderColor: gridLineColor,
      },
    },
    y: {
      stacked: true,
      display: true,
      ticks: {
        display: false,
        font: {size: 8},
      },
      grid: {
        display: true,
        drawBorder: false,
        color: (context) => (context.tick.value === 0 ? '#ddd' : gridLineColor),
      },
    },
  },
  plugins: {
    legend: {display: false},
  },
};

export const STACKED_BAR_CHART_OPTIONS_LG = cloneDeep(
  STACKED_BAR_CHART_OPTIONS,
);
STACKED_BAR_CHART_OPTIONS_LG.scales.x.ticks = {
  font: {
    size: 10,
    color: labelColor,
    style: 'normal',
    family:
      " -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  },
  callback: function (value) {
    const label = this.getLabelForValue(value) || '';
    return label.length > 15 ? label.substr(0, 13) + '...' : label;
  },
};
STACKED_BAR_CHART_OPTIONS_LG.scales.y.ticks.display = true;

export const COLOR_PALETTE = [
  '#DD4242',
  '#FF9429',
  '#FFCD56',
  '#26C636',
  '#A1E44B',
  '#4BC0C0',
  '#08C9E5',
  '#059BFF',
  '#0057FF',
  '#9649F5',
  '#DF6AE1',
  '#FF6384',
  '#8E1D51',
  '#9E4A3E',
  '#A4892A',
  '#0B70AB',
  '#4D5360',
  '#55D295',
  '#6D86DE',
  '#D0B65B',
  '#BBA6A3',
  '#949FB1',
  '#97BBCD',
  '#CDCDCD',
];

export const DASHBOARD_PAGES = {
  DASHBOARD_LIST: 'DASHBOARD_LIST',
  DASHBOARD_DETAIL: 'DASHBOARD_DETAIL',
  CREATE_DASHBOARD: 'CREATE_DASHBOARD',
  EDIT_DASHBOARD: 'EDIT_DASHBOARD',
};

export const DASHBOARD_OPERATIONS = [
  {
    title: FOOTER_OPERATION_TEXT.TOTAL,
    subtitle: 'Addition of all values in the column',
    value: FOOTER_OPERATION_TYPES.TOTAL,
    icon: sumIcon,
    dropdownIcon: dashboardSumIcon,
  },
  {
    title: FOOTER_OPERATION_TEXT.AVERAGE,
    subtitle: 'Average of all values in the column',
    value: FOOTER_OPERATION_TYPES.AVERAGE,
    icon: avgIcon,
    dropdownIcon: dashboardAvgIcon,
  },
  {
    title: FOOTER_OPERATION_TEXT.COUNT,
    subtitle: 'Total number of values in column',
    value: FOOTER_OPERATION_TYPES.COUNT,
    icon: countIcon,
    dropdownIcon: dashboardCountIcon,
  },
];

export const GET_DASHBOARD_COLUMNS = (headerData, type) => {
  return headerData
    ?.filter(
      (item) =>
        ![FIELD_TYPE_ID.LIST, FIELD_TYPE_ID.PDF].includes(item.fieldType),
    )
    .map((item, index) => ({
      ...item,
      title: item.val,
      value: item.id,
      label: item?.val,
      index: item.index ?? index,
      icon:
        item.icon ||
        FIELD_TYPE_DETAIL[item.fieldType]?.svgIconSrc ||
        FIELD_TYPE_DETAIL[FIELD_TYPE_ID.CREATED_INFO]?.svgIconSrc,
    }));
};

export const GET_DASHBOARD_COLUMNS_BY_TYPE = (headerData, typeArr) =>
  headerData
    ?.filter((items) => typeArr.includes(items?.fieldType))
    .map((item, index) => ({
      ...item,
      title: item.val,
      value: item.id,
      index,
      icon:
        FIELD_TYPE_DETAIL[item.fieldType]?.svgIconSrc ||
        FIELD_TYPE_DETAIL[FIELD_TYPE_ID.CREATED_INFO]?.svgIconSrc,
    }));

export const GET_COLUMNS_FOR_LINKING = (headerData) =>
  headerData?.map((item, index) => ({
    title: item.val,
    value: item.id,
    fieldType: item.fieldType,
    index,
    icon: FIELD_TYPE_DETAIL[item.fieldType]?.svgIconSrc,
  }));

export const GET_COLUMNS_FOR_AUTOMATION_VALUE_MAPPING = (headerData) =>
  headerData?.map((item, index) => ({
    ...item,
    title: item.val,
    value: item.id,
    index,
    icon: FIELD_TYPE_DETAIL[item.fieldType]?.svgIconSrc,
  }));
export const GET_COLUMNS_FOR_AUTOMATION_VALUE_MAPPING_V2 = (headerData) =>
  headerData?.map((item, index) => ({
    ...item,
    title: item.val,
    value: item.id,
    index,
    // icon: FIELD_TYPE_DETAIL[item.fieldType]?.svgIconSrc,
  }));

export const MAX_AUDIO_DURATION = 120;
export const MAX_AUDIO_DURATION_PREMIUM = 15 * 60;
export const MAX_AUDIO_SIZE = 1;
export const MAX_AUDIO_SIZE_PREMIUM = 30;

export const AUTOMATION_TABS = {
  ADD_NEW: 0,
  RUNNING: 1,
};

export const AUTOMATION_MODALS = {
  AUTOMATIONS_LIST: 'AUTOMATION_LIST',
  CREATE_AUTOMATION: 'CREATE_AUTOMATION',
  CHOOSE_TEMPLATE: 'CHOOSE_TEMPLATE',
  SUCCESS_MODAL: 'SUCCESS_MODAL',
  ADDON_STATE: 'ADDON_STATE',
};

export const WHATSAPP_SUPPORT_NUMBER = '+919619601744';

export const AUTOMATION_TRIGGER_DESC = {
  ON_ASSIGNEE_CHANGE: 'On Assignee Change',
  ROW_NEW_ENTRY: 'New entry',
  ON_SPECIFIC_DATE: 'On Specific Date',
  ROW_EDIT_ENTRY: 'Edit Entry',
  ON_NEW_COMMENT: 'On New Comment',
  ROW_DELETE_ENTRY: 'Delete Entry',
};

export const dontShowColumn = [
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.COMMENT,
];

export const DASHBOARD_CORNER_IMAGES = [
  DashboardCornerImage1,
  DashboardCornerImage2,
  DashboardCornerImage3,
  DashboardCornerImage4,
  DashboardCornerImage5,
  DashboardCornerImage6,
  DashboardCornerImage7,
  DashboardCornerImage8,
];

export const APP_MENU_OPTIONS = {
  RENAME: {
    TITLE: 'Edit & Rename',
    COLOR: theme.palette.textDark,
    startIcon: <IconComponent option={RenameIcon} />,
  },
  // TODO: Remove edit layout?
  // EDIT_LAYOUT: {
  //   TITLE: 'Edit Layouts',
  //   COLOR: theme.palette.textDark,
  //   startIcon: <IconComponent option={editLayoutIcon} />,
  // },
  // HELP: {
  //   TITLE: 'Help & Support',
  //   COLOR: theme.palette.textDark,
  //   startIcon: <IconComponent option={helpIcon} />,
  // },
  DELETE: {
    TITLE: 'Delete App',
    COLOR: theme.palette.redText2,
    startIcon: <IconComponent option={redDeleteIcon} />,
  },
  LEAVE_APP: {
    TITLE: 'Leave App',
    COLOR: theme.palette.redText2,
    startIcon: <ExitToAppIcon color="error" fontSize="small" />,
  },
};

export const APP_VIEW_TOPBAR_OPTIONS = {
  // RENAME: {
  //   TITLE: 'Edit & Rename',
  //   COLOR: theme.palette.textDark,
  //   startIcon: <IconComponent option={RenameIcon} />,
  // },
  // TODO: Remove edit layout?
  // EDIT_LAYOUT: {
  //   TITLE: 'Edit Layouts',
  //   COLOR: theme.palette.textDark,
  //   startIcon: <IconComponent option={editLayoutIcon} />,
  // },
  // TODO: Find a suitable startIcon
  // REVERSE_ROW_ORDER: {
  //   TITLE: 'Reverse Row Order',
  //   COLOR: theme.palette.textDark,
  //   startIcon: <IconComponent option={SortByIcon} />,
  //   endIcon: <IconComponent option={BlueTickIcon} />,
  // },
  // HELP: {
  //   TITLE: 'Help & Support',
  //   COLOR: theme.palette.textDark,
  //   startIcon: <IconComponent option={helpIcon} />,
  // },
  // DELETE: {
  //   TITLE: 'Delete App',
  //   COLOR: theme.palette.redText2,
  //   startIcon: <IconComponent option={redDeleteIcon} />,
  // },
  LEAVE: {
    TITLE: 'Leave App',
    COLOR: theme.palette.redText2,
    startIcon: <ExitToAppIcon color="error" fontSize="small" />,
  },
};

export const APP_SCREEN_OPTIONS = {
  SELECT_RECORDS: {
    TITLE: 'Select',
    COLOR: theme.palette.textDark,
    startIcon: <IconComponent option={CheckCircleIcon} />,
  },
  // SETTINGS: {
  //   TITLE: 'Current Screen Settings',
  //   COLOR: theme.palette.textDark,
  //   startIcon: <IconComponent option={SettingsIconNew} />,
  // },
  HELP: {
    TITLE: 'Help & Support',
    COLOR: theme.palette.textDark,
    startIcon: <IconComponent option={helpIcon} />,
  },
  // DELETE: {
  //   TITLE: 'Delete Screen',
  //   COLOR: theme.palette.redText2,
  //   startIcon: <IconComponent option={redDeleteIcon} />,
  // },
  EXPORT_DATA: {
    TITLE: 'Export Data',
    COLOR: theme.palette.textDark,
    startIcon: <IconComponent option={ExportData} />,
  },
  IMPORT_DATA: {
    TITLE: 'Import to Database',
    COLOR: theme.palette.textDark,
    startIcon: <IconComponent option={ImportData} />,
  },
  APP_EXPORTS: {
    TITLE: 'Import / Exports History',
    COLOR: theme.palette.textDark,
    startIcon: <IconComponent option={TimerIcon} />,
  },
  EXPORT_DASHBOARD_REPORT: {
    TITLE: 'Export Dashboard Report',
    COLOR: theme.palette.textDark,
    startIcon: <IconComponent option={TimerIcon} />,
  },
};
export const SUMMARY_COLUMN_SCREEN_OPTIONS = {
  'Show Average': {
    TITLE: 'Show Average',
    COLOR: theme.palette.textDark,
  },
  'Show Count': {
    TITLE: 'Show Count',
    COLOR: theme.palette.textDark,
  },
  'Show Total Sum': {
    TITLE: 'Show Total Sum',
    COLOR: theme.palette.textDark,
  },
  'Remove Summary': {
    TITLE: 'Remove Summary',
    COLOR: theme.palette.redText2,
  },
};

export const APP_ROW_DETAILS_OPTIONS = {
  DUPLICATE: {
    TITLE: 'Duplicate',
    COLOR: theme.palette.textDark,
    startIcon: <IconComponent option={ContentDuplicateIcon} />,
  },
  SHARE: {
    TITLE: 'Share on WhatsApp',
    COLOR: theme.palette.textDark,
    startIcon: <IconComponent option={ShareOutlineIcon} />,
  },
  // CLEAR: {
  //   TITLE: 'Clear Record',
  //   COLOR: theme.palette.textDark,
  //   startIcon: <IconComponent option={ClearRecordIcon} />,
  // },
  DELETE_ROW: {
    TITLE: 'Delete',
    COLOR: theme.palette.redText2,
    startIcon: <IconComponent option={redDeleteIcon} />,
  },
  // DOWNLOAD_PDF: {
  //   TITLE: 'Download PDF',
  //   COLOR: theme.palette.textDark,
  //   startIcon: <IconComponent option={pdf} />,
  // },
};

export const ALL_APPS_CATEGORY = {
  id: 'allApps1',
  name: {
    EN: 'All',
    HN: 'सभी',
    MA: 'सर्व',
    BE: 'সব',
    TE: 'అన్నీ',
    TA: 'அனைத்து',
    GU: 'બધા',
    KN: 'ಎಲ್ಲಾ',
    ML: 'എല്ലാം',
    UR: 'تمام',
    ID: 'Semua',
    PT: 'Todos',
    VN: 'Tất cả',
  },
  desc: {},
  icon: {image: allAppsIcon},
};
export const RECOMMENDED_APPS_CATEGORY = {
  id: 'recommendedApps',
  name: {
    EN: 'Recommended Apps',
    HN: 'अनुशंसित ऐप्स',
    MA: 'शिफारस केलेले अॅप्स',
    BE: 'প্রস্তাবিত অ্যাপস',
    TE: 'సిఫార్సు చేసిన యాప్‌లు',
    TA: 'பரிந்துரைக்கப்பட்ட பயன்பாடுகள்',
    GU: 'ભલામણ કરેલ એપ્સ',
    KN: 'ಶಿಫಾರಸು ಮಾಡಲಾದ ಅಪ್ಲಿಕೇಶನ್‌ಗಳು',
    ML: 'ശുപാർശ ചെയ്യുന്ന ആപ്പുകൾ',
    UR: 'تجویز کردہ ایپس',
    ID: 'Aplikasi yang Direkomendasikan',
    PT: 'Aplicativos recomendados',
    VN: 'Ứng dụng được đề xuất',
  },
  desc: {},
  icon: {image: allAppsIcon},
};
export const IMPORT_DELETE_OPTIONS = {
  DELETE: {
    TITLE: 'Delete Imported',
    COLOR: theme.palette.textDark,
    startIcon: <IconComponent option={redDeleteIcon} />,
  },
  SUPPORT: {
    TITLE: 'Support',
    COLOR: theme.palette.textDark,
    startIcon: <IconComponent option={HelpIconApp} />,
  },
};

export const INTERAKT_CARD_ITEMS = [
  {
    TITLE: 'How to get API key from Interakt',
    Step1: '1. Log in to your Interakt account',
    Step2: '2. Go to Settings -> Integration',
    Step3: '3. Copy the Access key',
    Icon: InterktIcon,
  },
  {
    TITLE: 'Why Facebook Authorize?',
    Step1: 'WhatsApp policy is defined under',
    Step2: 'Meta, to access WhatsApp templates we',
    Step3: 'need facebook authorisations',
    Icon: FacebookIcon,
  },
];

export const SUB_HEADER_TITLE = {
  DOCS: 'Docs',
};
