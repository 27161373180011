import {backOff} from 'exponential-backoff';
import {cloneDeep, isEmpty, omit} from 'lodash';
import moment from 'moment';
import {
  captureError,
  getDeviceVersion,
  logAnalyticsEvent,
  ShowToast,
} from '../imports';
import {
  TIME_FILTER_DASHBOARD,
  MINI_APPS,
  FIELD_TYPE_ID,
  DASHBOARD_SUB_TYPE,
  DASHBOARD_TYPES_V3,
} from '../utils/constant';
import {
  allSettled,
  getLocalText,
  getTimezone,
  handleCloudErrorMsgAndLogging,
} from '../utils/utils';
import {elasticDashboardsActionHelper} from './actionHelpers/elasticDashboardsActionHelper';
import {ELASTIC_DASHBOARDS_ACTION} from './actionType';
import {isCustomRoleFilteredScreen} from './actionHelpers/miniAppsActionHelper';
import {fetchFilterParamsForDoc} from './searchFilterAction';
import {updateMiniAppScreenTimeFilter} from './miniAppsAction';

const createEditElasticDashboard =
  ({
    dashboardConfigObj = {},
    isEdit = false,
    isDelete = false,
    dashboardId = null, // required for edit, delete
    filter = TIME_FILTER_DASHBOARD.CURRENT_MONTH.id,
    options,
    extraParams = {},
    isSetChartType = false,
  }) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref, user},
      } = getState();

      if (!user?.uid) {
        return;
      }

      const analyticsObj = isDelete
        ? {dashboardId}
        : {
            dashboardId,
            operation: dashboardConfigObj.operation,
            filterApplied: !isEmpty(dashboardConfigObj.filters),
            splitBy: !isEmpty(dashboardConfigObj.splitBy),
            splitByField: !isEmpty(dashboardConfigObj.splitBy?.fieldType)
              ? dashboardConfigObj.splitBy.fieldType
              : null,
            p_dashboardType: dashboardConfigObj.type,
            p_dashboardSubType: dashboardConfigObj.subType,
            ...(extraParams?.operationColList
              ? {operationColList: extraParams?.operationColList}
              : {}),
          };
      if (isDelete) {
        logAnalyticsEvent('DELETE_DASHBOARD_INIT', analyticsObj);
      } else {
        logAnalyticsEvent(
          isEdit ? 'EDIT_DASHBOARD_INIT' : 'CREATE_DASHBOARD_INIT',
          analyticsObj,
        );
      }

      const timestamp = moment.utc().format();
      const createdBy = {
        uid: user.uid,
        timestamp,
      };

      const updatedDashboardConfig =
        elasticDashboardsActionHelper.formatDashboardConfigObj(
          dashboardConfigObj,
        );

      const dataObj = Object.assign(
        {},
        {dashboardId},
        isSetChartType
          ? {
              dashboardConfigObj,
            }
          : !isDelete && !isEdit
          ? {
              dashboardConfigObj: {
                ...updatedDashboardConfig,
                userTimezone: userPref?.timezone ?? getTimezone(),
                createdBy,
                appVersion: getDeviceVersion(),
              },
            }
          : {
              dashboardConfigObj: {
                ...updatedDashboardConfig,
              },
            },
        isDelete
          ? {isDelete}
          : isEdit
          ? {isEdit}
          : isSetChartType
          ? {isSetChartType}
          : {},
      );

      const data =
        await elasticDashboardsActionHelper.createEditElasticDashboardCloudFunction(
          dataObj,
        );

      if (!data || !data.success) {
        handleCloudErrorMsgAndLogging(data, dataObj, userPref);
        return null;
      } else {
        const {activeScreenId, miniApps, activeCustomRoleInfo, activeAppId} =
          getState().miniApps;
        const {allDashboards} = getState().elasticDashboards;

        const updatedDashboards = allDashboards?.[activeScreenId]?.dashboards;

        const dashboardConfig =
          elasticDashboardsActionHelper.formatDashboardConfigObj(
            data.dashboardConfigObj,
            false,
          );

        const screenAccessAndFilterObj = {};
        if (
          [
            DASHBOARD_SUB_TYPE.SPLIT_BY_VALUE.N_VALUES,
            DASHBOARD_SUB_TYPE.SIMPLE.N_VALUES_WITHOUT_SPLIT,
          ].includes(dashboardConfig?.subType)
        ) {
          for (const screen in dashboardConfig.screenDetails) {
            if (dashboardConfig.screenDetails[screen]) {
              const screenFilterOptions = (() => {
                const currScreenMeta = Object.assign(
                  {},
                  miniApps[activeAppId]?.screens?.[screen],
                );
                return currScreenMeta?.type ===
                  MINI_APPS.SCREEN_TYPE.FILTERED_DATA
                  ? currScreenMeta.docs[0].filterOptions
                  : [];
              })();
              const [isCustomViewAccess, viewAccess] =
                isCustomRoleFilteredScreen(
                  miniApps[activeAppId],
                  user.uid,
                  screen,
                  activeCustomRoleInfo,
                );
              screenAccessAndFilterObj[screen] = {
                ...(isCustomViewAccess ? {viewAccess} : {}),
                filterOptions: screenFilterOptions,
              };
            }
          }
        }

        const screenFilterOptions = (() => {
          const currScreenMeta = Object.assign(
            {},
            miniApps[activeAppId]?.screens?.[dashboardConfig?.docScreenId],
          );
          return currScreenMeta?.type === MINI_APPS.SCREEN_TYPE.FILTERED_DATA
            ? currScreenMeta.docs[0].filterOptions
            : [];
        })();

        const [isCustomViewAccess, viewAccess] = isCustomRoleFilteredScreen(
          miniApps[activeAppId],
          user.uid,
          dashboardConfig?.docScreenId,
          activeCustomRoleInfo,
        );
        const currentFilterOption =
          miniApps[activeAppId]?.screens[activeScreenId]?.timeFilter
            ?.filterOption;
        const dashboardValue =
          await elasticDashboardsActionHelper.calculateDashboardFromData({
            dashboardId: data.dashboardId,
            dashboardConfig,
            ...(isCustomViewAccess ? {viewAccess} : {}),
            filterOptions: screenFilterOptions,
            timeFilterOptions: options
              ? options
              : currentFilterOption
              ? currentFilterOption
              : TIME_FILTER_DASHBOARD[filter].filterOption,
            ...(!isEmpty(screenAccessAndFilterObj)
              ? {screenAccessAndFilterObj}
              : {}),
          });

        dispatch({
          type: ELASTIC_DASHBOARDS_ACTION.SET_ALL_DASHBOARDS,
          payload: {
            dashboards: isDelete
              ? omit(updatedDashboards, [data.dashboardId])
              : Object.assign({}, updatedDashboards, {
                  [data.dashboardId]: {
                    ...dashboardConfig,
                    dashboardValue,
                    id: data.dashboardId,
                  },
                }),
            activeScreenId,
          },
        });
        if (isEdit) {
          dispatch(
            fetchQuickFilterForDashboard(activeScreenId, data.dashboardId),
          );
        }
        const defaultMsg = isDelete
          ? 'Dashboard Deleted Successfully.'
          : isEdit || isSetChartType
          ? 'Dashboard Modified Successfully.'
          : 'Dashboard Created Successfully.';
        const message = getLocalText(userPref, data.message ?? defaultMsg);
        ShowToast(message, userPref);
        if (isDelete) {
          logAnalyticsEvent('p_DELETE_DASHBOARD_SUCCESS', analyticsObj);
        } else {
          analyticsObj.dashboardId = data.dashboardId;
          logAnalyticsEvent(
            isEdit ? 'EDIT_DASHBOARD_SUCCESS' : 'CREATE_DASHBOARD_SUCCESS',
            analyticsObj,
          );
        }
        return data.dashboardId;
      }
    } catch (error) {
      captureError(error);
    }
  };

const fetchAllDasboardsForScreen =
  (
    screenId = null,
    filter = TIME_FILTER_DASHBOARD.ALL_DATA.id,
    options = null,
  ) =>
  async (dispatch, getState) => {
    try {
      const {
        miniApps: {activeAppId, activeScreenId, docsData, miniApps},
        elasticDashboards: {areDashboardsLoading},
      } = getState();
      const currScreenId = screenId ?? activeScreenId;
      const dashboards = {};

      const timeFilterObj = miniApps[activeAppId].screens[screenId]
        ?.timeFilter ?? {
        id: filter,
        text: TIME_FILTER_DASHBOARD[filter].text,
        filterOption: options
          ? options
          : TIME_FILTER_DASHBOARD[filter].filterOption,
      };
      dispatch({
        type: ELASTIC_DASHBOARDS_ACTION.SET_DASHBOARD_TIME_FILTER,
        payload: {
          activeScreenId,
          timeFilter: timeFilterObj,
        },
      });
      dispatch(updateMiniAppScreenTimeFilter(timeFilterObj));
      if (areDashboardsLoading) {
        return;
      }

      dispatch({
        type: ELASTIC_DASHBOARDS_ACTION.UPDATE_LOADING_ELASTIC_DASHBOARD,
        payload: {areDashboardsLoading: true, activeScreenId: activeScreenId},
      });

      const docRef =
        elasticDashboardsActionHelper.fetchElasticDashboardsForActiveScreen(
          activeAppId,
          currScreenId,
        );
      const snapshot = await backOff(() => docRef.get(), {numOfAttempts: 2});
      if (snapshot && !snapshot.empty) {
        snapshot.forEach((doc) => {
          const snapData =
            elasticDashboardsActionHelper.formatDashboardConfigObj(
              doc.data(),
              false,
            );

          // Get latest Header Data of Column For Currency
          if (
            snapData?.columnDetails?.fieldType === FIELD_TYPE_ID.RUPEE &&
            !isEmpty(
              docsData?.[snapData.docId]?.headerDataAsObj?.[
                snapData.columnDetails.id
              ],
            )
          ) {
            dashboards[doc.id] = {
              ...snapData,
              id: doc.id,
              columnDetails:
                docsData[snapData.docId].headerDataAsObj[
                  snapData.columnDetails.id
                ],
            };
          } else {
            dashboards[doc.id] = {...snapData, id: doc.id};
          }
        });
      }
      dispatch({
        type: ELASTIC_DASHBOARDS_ACTION.SET_ALL_DASHBOARDS,
        payload: {dashboards, activeScreenId: currScreenId},
      });

      if (isEmpty(dashboards)) {
        dispatch({
          type: ELASTIC_DASHBOARDS_ACTION.UPDATE_LOADING_ELASTIC_DASHBOARD,
          payload: {
            areDashboardsLoading: false,
            activeScreenId: activeScreenId,
          },
        });
      } else {
        Object.keys(dashboards)?.forEach((dashboardId) => {
          dispatch(
            fetchDashboardFromId(
              dashboardId,
              dashboards[dashboardId].docScreenId,
              dashboards[dashboardId].miniAppId,
              timeFilterObj.id,
              timeFilterObj.filterOption,
            ),
          );
        });
      }
    } catch (error) {
      captureError(error);
    }
  };

const fetchDashboardFromId =
  (dashboardId, screenId, miniAppId, filter, options) =>
  async (dispatch, getState) => {
    const {
      miniApps: {miniApps, activeScreenId, activeCustomRoleInfo},
      auth,
      elasticDashboards: {allDashboards},
    } = getState();
    try {
      if (allDashboards[activeScreenId]?.[dashboardId]?.isLoading) {
        return; // dashboard already loading
      }

      dispatch({
        type: ELASTIC_DASHBOARDS_ACTION.START_LOADING_DASHBOARD,
        payload: {
          activeScreenId,
          dashboardId,
          isLoading: true,
        },
      });

      const dashboardConfig =
        allDashboards[activeScreenId]?.dashboards?.[dashboardId];
      const screenAccessAndFilterObj = {};
      if (
        [
          DASHBOARD_SUB_TYPE.SPLIT_BY_VALUE.N_VALUES,
          DASHBOARD_SUB_TYPE.SIMPLE.N_VALUES_WITHOUT_SPLIT,
          DASHBOARD_SUB_TYPE.MULTI_SPLIT.PIVOT_TABLE,
        ].includes(dashboardConfig?.subType)
      ) {
        const selectedFilterData =
          allDashboards?.[activeScreenId]?.allFilterData?.[dashboardId];
        for (const screen in dashboardConfig.screenDetails) {
          if (dashboardConfig.screenDetails[screen]) {
            const screenFilterOptions = (() => {
              const currScreenMeta = Object.assign(
                {},
                miniApps[miniAppId]?.screens?.[screen],
              );
              return currScreenMeta?.type ===
                MINI_APPS.SCREEN_TYPE.FILTERED_DATA
                ? currScreenMeta.docs[0].filterOptions
                : [];
            })().slice();

            //optional filter for  screen
            if (
              !isEmpty(selectedFilterData?.selectedOptions) &&
              dashboardConfig.screenDetails[screen].optionalFilterColumn?.id
            ) {
              screenFilterOptions.push({
                colId:
                  dashboardConfig.screenDetails[screen].optionalFilterColumn
                    ?.id,
                fieldType:
                  dashboardConfig.screenDetails[screen].optionalFilterColumn
                    ?.fieldType,
                selectedOptions: selectedFilterData?.selectedOptions,
              });
            }

            const [isCustomViewAccess, viewAccess] = isCustomRoleFilteredScreen(
              miniApps[miniAppId],
              auth.user.uid,
              screen,
              activeCustomRoleInfo,
            );
            screenAccessAndFilterObj[screen] = {
              ...(isCustomViewAccess ? {viewAccess} : {}),
              filterOptions: screenFilterOptions,
            };
          }
        }
      }

      const [isCustomViewAccess, viewAccess] = isCustomRoleFilteredScreen(
        miniApps[miniAppId],
        auth.user.uid,
        screenId,
        activeCustomRoleInfo,
      );

      const screenFilterOptions = (() => {
        const currScreenMeta = Object.assign(
          {},
          miniApps[miniAppId]?.screens?.[dashboardConfig?.docScreenId],
        );
        return currScreenMeta?.type === MINI_APPS.SCREEN_TYPE.FILTERED_DATA
          ? currScreenMeta.docs[0].filterOptions
          : [];
      })().slice();

      const selectedFilterData =
        allDashboards?.[activeScreenId]?.allFilterData?.[dashboardId];
      if (!isEmpty(selectedFilterData)) {
        if (!isEmpty(selectedFilterData?.selectedOptions)) {
          screenFilterOptions.push({
            colId: selectedFilterData?.colId,
            fieldType: selectedFilterData?.fieldType,
            selectedOptions: selectedFilterData?.selectedOptions,
          });
        }
      }

      if (
        !(
          dashboardConfig?.type in DASHBOARD_TYPES_V3 &&
          dashboardConfig?.subType in DASHBOARD_SUB_TYPE[dashboardConfig?.type]
        )
      ) {
        dispatch({
          type: ELASTIC_DASHBOARDS_ACTION.SET_DASHBOARD_VALUE,
          payload: {
            activeScreenId,
            dashboardId,
            dashboardValue: {
              success: false,
              message: 'This dashboard is not supported for this version.',
              isVersionMismatch: true,
            },
          },
        });
        dispatch({
          type: ELASTIC_DASHBOARDS_ACTION.START_LOADING_DASHBOARD,
          payload: {
            activeScreenId,
            dashboardId,
            isLoading: false,
          },
        });
        return;
      }
      const dashboardValue =
        await elasticDashboardsActionHelper.calculateDashboardFromData({
          dashboardId,
          ...(isCustomViewAccess ? {viewAccess} : {}),
          timeFilterOptions: options
            ? options
            : TIME_FILTER_DASHBOARD[filter].filterOption,
          dashboardConfig,
          filterOptions: screenFilterOptions,
          ...(!isEmpty(screenAccessAndFilterObj)
            ? {screenAccessAndFilterObj}
            : {}),
        });
      dispatch({
        type: ELASTIC_DASHBOARDS_ACTION.SET_DASHBOARD_VALUE,
        payload: {
          activeScreenId,
          dashboardId,
          dashboardValue,
        },
      });
      dispatch({
        type: ELASTIC_DASHBOARDS_ACTION.START_LOADING_DASHBOARD,
        payload: {
          activeScreenId,
          dashboardId,
          isLoading: false,
        },
      });
    } catch (error) {
      captureError(error);
      dispatch({
        type: ELASTIC_DASHBOARDS_ACTION.START_LOADING_DASHBOARD,
        payload: {
          activeScreenId,
          dashboardId,
          isLoading: false,
        },
      });
    }
  };

const setEditDashboardId = (dashboardId) => (dispatch) =>
  dispatch({
    type: ELASTIC_DASHBOARDS_ACTION.SET_EDIT_DASHBOARD_ID,
    payload: {dashboardId},
  });

const updateDashboardConfig =
  (id, screenId, filterData) => async (dispatch, getState) => {
    const {
      elasticDashboards: {allDashboards},
    } = getState();
    const activeDashboard = cloneDeep(
      allDashboards?.[screenId]?.dashboards?.[id],
    );
    const allFilterDataObj =
      cloneDeep(allDashboards?.[screenId]?.allFilterData) || {};
    dispatch({
      type: ELASTIC_DASHBOARDS_ACTION.UPDATE_DASHBOARD_CONFIG,
      payload: {
        allFilterData: Object.assign({}, allFilterDataObj, {
          [id]: {
            ...filterData,
          },
        }),
        activeScreenId: screenId,
      },
    });

    await dispatch(
      fetchDashboardFromId(
        activeDashboard?.id,
        activeDashboard?.docScreenId,
        activeDashboard?.miniAppId,
        allDashboards?.[screenId]?.timeFilter?.id,
        allDashboards?.[screenId]?.timeFilter?.filterOption,
      ),
    );
  };

const fetchQuickFilterForDashboard =
  (screenId, dashboardId) => async (dispatch, getState) => {
    try {
      const ALL_DATA = 'All data';

      const {
        elasticDashboards: {allDashboards},
      } = getState();
      const dashboards = allDashboards[screenId].dashboards;
      let valueArr = [];
      let isValidOptionalFilter = false;
      if (
        [
          DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SPLIT_BY_VALUE].N_VALUES,
          DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SIMPLE].N_VALUES_WITHOUT_SPLIT,
          DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.MULTI_SPLIT].PIVOT_TABLE,
        ].includes(dashboards[dashboardId]?.subType)
      ) {
        const screens = dashboards[dashboardId].screenDetails;

        const promiseArr = [];
        const quickFilterIds = [];
        for (const currScreenId in screens) {
          if (screens[currScreenId]?.optionalFilterColumn?.id) {
            isValidOptionalFilter = true;
            promiseArr.push(
              dispatch(
                fetchFilterParamsForDoc(
                  screens[currScreenId].docId,
                  null,
                  true,
                ),
              ),
            );
            quickFilterIds.push(screens[currScreenId].optionalFilterColumn.id);
          }
        }
        const resolvedArr = await allSettled(promiseArr);
        resolvedArr.forEach((obj, index) => {
          if (obj.status === 'fulfilled') {
            valueArr = [
              ...new Set([
                ...valueArr,
                ...(obj.value.filterColIdOptionsMapping[
                  quickFilterIds[index]
                ] ?? []),
              ]),
            ];
          }
        });
      } else {
        const docId = dashboards[dashboardId].docId;
        const quickFilterId = dashboards[dashboardId]?.optionalFilterColumn?.id;
        if (quickFilterId) {
          isValidOptionalFilter = true;
          const returnObj = await dispatch(
            fetchFilterParamsForDoc(docId, null, true),
          );
          valueArr = returnObj.filterColIdOptionsMapping[quickFilterId];
        }
      }

      if (isValidOptionalFilter) {
        dispatch({
          type: ELASTIC_DASHBOARDS_ACTION.SET_DASHBOARD_QUICK_FILTER_VALUE,
          payload: {
            dashboardId,
            valueArr: [ALL_DATA, ...(valueArr ?? [])],
          },
        });
      }
    } catch (err) {
      captureError(err);
    }
  };

// Updated Sorting Order
// Only One sorting obj can be stored either Split Columns or Values Columns
// dashboardObj={}, sortingObj:{id:renderId/colId,sortType:asc/desc,isSplitVal:true/false}
const updateDashboardSorting =
  (dashboardObj, sortingObj) => async (dispatch, getState) => {
    try {
      const dataObj = {
        isEdit: true,
        dashboardId: dashboardObj.id,
        dashboardConfigObj: {...dashboardObj, sorting: sortingObj},
      };
      const response =
        await elasticDashboardsActionHelper.createEditElasticDashboardCloudFunction(
          dataObj,
        );
      if (!response || !response.success) {
        handleCloudErrorMsgAndLogging(response, dataObj);
        return null;
      } else {
        const {activeScreenId} = getState().miniApps;
        const {allDashboards} = getState().elasticDashboards;
        const updatedDashboards = allDashboards?.[activeScreenId]?.dashboards;
        await dispatch({
          type: ELASTIC_DASHBOARDS_ACTION.SET_ALL_DASHBOARDS,
          payload: {
            dashboards: Object.assign({}, updatedDashboards, {
              [response.dashboardId]: {
                ...dataObj.dashboardConfigObj,
                id: response.dashboardId,
              },
            }),
            activeScreenId,
          },
        });
        return true;
      }
    } catch (err) {
      captureError(err);
      return false;
    }
  };

const updateDashboardOrdering =
  (dataObj = {}) =>
  async (dispatch, getState) => {
    try {
      const response = await elasticDashboardsActionHelper.manageDashboardIndex(
        {dashboardIndexObj: dataObj},
      );
      if (!response || !response.success) {
        handleCloudErrorMsgAndLogging(response, dataObj);
        return null;
      }
    } catch (error) {
      captureError(error);
    }
  };

export {
  fetchQuickFilterForDashboard,
  updateDashboardConfig,
  createEditElasticDashboard,
  fetchAllDasboardsForScreen,
  fetchDashboardFromId,
  setEditDashboardId,
  updateDashboardSorting,
  updateDashboardOrdering,
};
