import FirestoreDB from '../FirestoreDB';
import {BatchWriter} from '../BatchWriter';
import {captureError} from '../../imports';
import {omit, isEmpty} from 'lodash';

export default class TemplatesMethods {
  static getTemplateData(templateId) {
    return FirestoreDB.templates.templateRef(templateId).get();
  }

  static getTemplateRowsData(templateId) {
    return FirestoreDB.templates
      .templateRowsCollectionRefSorted(templateId)
      .get();
  }

  static setTemplateDocumentData(templateId, obj, merge = true) {
    try {
      const tableData = obj?.tableData;

      // contains everything except tableData
      const objCopy = {};

      Object.keys(obj).forEach((key) => {
        if (key !== 'tableData') {
          objCopy[key] = obj[key];
        }
      });

      // set template data
      BatchWriter.set(
        FirestoreDB.templates.templateRef(templateId),
        omit(objCopy, 'tableData'),
        merge ? {merge: true} : {},
      );

      const ROW_OBJ_OMITS = ['index', 'rowId', 'rowProperties'];
      // set template row data
      if (!isEmpty(tableData)) {
        tableData.forEach((row) => {
          const rowObjToSet = omit(Object.assign({}, row), ROW_OBJ_OMITS);
          // keep only index and rowObj (no index, rowId or rowProperties);
          const rowItem = {
            index: row.index,
            rowObj: rowObjToSet,
          };
          BatchWriter.set(
            FirestoreDB.templates.templateRowRef(templateId, row['rowId']),
            rowItem,
          );
        });
      }
      return BatchWriter.commit();
    } catch (error) {
      captureError(error);
    }
  }

  static deleteTemplate(templateId) {
    try {
      BatchWriter.delete(FirestoreDB.templates.templateRef(templateId));

      return BatchWriter.commit();
    } catch (error) {
      captureError(error);
    }
  }
}
