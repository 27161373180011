import {firestore} from '../../imports';

export default class UsersDB {
  userMetaRef(uid, firestoreRef = null) {
    return (firestoreRef ?? firestore)().collection('users').doc(uid);
  }

  userDocumentsMetaRef(uid, documentId, firestoreRef = null) {
    return this.userMetaRef(uid, firestoreRef)
      .collection('documents')
      .doc(documentId);
  }

  userSharedDocsMetaRef(uid, documentId, firestoreRef = null) {
    return this.userMetaRef(uid, firestoreRef)
      .collection('sharedDocs')
      .doc(documentId);
  }

  sharedDocumentsMetaRef(documentId, firestoreRef = null) {
    return (firestoreRef ?? firestore)()
      .collection('sharedDocsMeta')
      .doc(documentId);
  }

  userTemplateDocumentDataRef(uid, templateId) {
    return this.userMetaRef(uid)
      .collection('templates_ARCH_V2')
      .doc(templateId);
  }

  usersSubCollectionDataWithQueryRef(uid, collectionName, firestoreRef = null) {
    return this.userMetaRef(uid, firestoreRef)
      .collection(collectionName)
      .orderBy('timestamp', 'desc');
  }
}
