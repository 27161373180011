import {MESSAGE_TEMPLATES_ACTIONS} from '../actions/actionType';

const initialState = {
  savedMessageTemplates: {},
  isNewTemplateUsed: false,
};

const messageTemplateReducer = function (state = initialState, action) {
  switch (action.type) {
    case MESSAGE_TEMPLATES_ACTIONS.LOAD_MESSAGE_TEMPLATE:
      return {...state, savedMessageTemplates: action.payload};
    case MESSAGE_TEMPLATES_ACTIONS.SET_SAVED_STATE:
      return {...state, isNewTemplateUsed: action.payload};
    case MESSAGE_TEMPLATES_ACTIONS.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
export default messageTemplateReducer;
