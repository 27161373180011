import {captureError, captureInfo, getReduxState} from '../imports';
import moment from 'moment';
import {omit, forOwn, orderBy, isEmpty} from 'lodash';
import FirestoreDB from '../FirestoreHandlers/FirestoreDB';
import {generateDocId} from './RandomGenerator';

class CommentsPagingWithListener {
  constructor(
    firestoreRef,
    queryParams = {},
    classStateListeners = {LOADING_STATE: () => {}, COMMENTS_LIST: () => {}},
  ) {
    this.isLoading = true;
    this.docLimit = 10;
    this.commentsData = {};
    this.currentTimestamp = moment().unix();
    this.lastFetchedTimestamp = this.currentTimestamp;
    this.listeners = {};
    this.classStateListeners = Object.assign({}, classStateListeners);
    this.docRef = FirestoreDB.documents
      .commentsCollectionRef(queryParams.docId, queryParams.rowId, firestoreRef)
      .where('columnId', '==', queryParams.columnId)
      .orderBy('createdTimestamp', 'desc');
    this.setLoading(this.isLoading);
    this.setComments(this.commentsData);
    this.getNewDocs();
    this.getPreviousDocs(true);
  }

  setLoading(isLoading) {
    this.isLoading = Boolean(isLoading);
    typeof this.classStateListeners.LOADING_STATE === 'function' &&
      this.classStateListeners.LOADING_STATE(this.isLoading);
  }

  setComments(commentsObj) {
    this.commentsData = Object.assign({}, commentsObj);
    if (typeof this.classStateListeners.COMMENTS_LIST === 'function') {
      const list = Object.keys(this.commentsData).map((id) => {
        return Object.assign({}, this.commentsData[id], {id});
      });
      this.classStateListeners.COMMENTS_LIST(
        orderBy(list, ['createdTimestamp'], ['desc']),
      );
    }
  }

  areMoreDocsAvailable() {
    return typeof this.listeners[this.lastFetchedTimestamp] !== 'object';
  }

  addFirestoreListener(query, listenerName) {
    try {
      this.listeners[listenerName] = {isInitialCall: true};
      const listener = query.onSnapshot(
        (snapshot) => this.handleDocsChange(snapshot, listenerName),
        captureError,
      );
      this.listeners[listenerName] = Object.assign(
        {},
        this.listeners[listenerName],
        {listener},
      );
    } catch (err) {
      captureError(err);
    }
  }

  handleDocsChange(snapshot, listenerName) {
    try {
      const changedDocuments = snapshot.docChanges();
      const updatedComments = {};
      const removedComments = [];
      changedDocuments.forEach((change) => {
        if (change.type === 'removed') {
          removedComments.push(change.doc.id);
        } else {
          updatedComments[change.doc.id] = change.doc.data();
        }
      });
      const comments = omit(
        Object.assign({}, this.commentsData, updatedComments),
        removedComments,
      );
      this.setComments(comments);
      if (this.listeners[listenerName].isInitialCall) {
        this.listeners[listenerName].isInitialCall = false;
        this.setLoading(false);
      }
      if (changedDocuments.length === this.docLimit) {
        this.lastFetchedTimestamp =
          changedDocuments[
            changedDocuments.length - 1
          ].doc.data().createdTimestamp;
      }
    } catch (err) {
      captureError(err);
    }
  }

  getNewDocs() {
    const timestamp = this.currentTimestamp;
    const query = this.docRef.where('createdTimestamp', '>=', timestamp);
    this.addFirestoreListener(query, `${timestamp}_new`);
  }

  getPreviousDocs(isForcedFetch = false) {
    if (isForcedFetch || this.areMoreDocsAvailable()) {
      const timestamp = this.lastFetchedTimestamp;
      const query = this.docRef
        .where('createdTimestamp', '<', timestamp)
        .limit(this.docLimit);
      this.addFirestoreListener(query, timestamp);
    }
  }

  deactivateListeners() {
    forOwn(
      this.listeners,
      ({listener}) => typeof listener === 'function' && listener(),
    );
  }
}

const CommentsUtils = {
  getCellDataForComment: (commentObj) => {
    if (isEmpty(commentObj)) {
      return {};
    }
    return {
      val: commentObj.text,
      addedByName: commentObj.addedBy.name,
      uid: commentObj.addedBy.uid,
      timestamp: commentObj.createdTimestamp,
    };
  },
  generateCommentObj: (
    columnId,
    rowId,
    commentText,
    generateCommentId = false,
    mergeObj,
  ) => {
    const {
      auth: {user, userPref},
    } = getReduxState();
    const timestamp = moment().utc().unix();
    const contact = user.phoneNumber ?? user.email;
    const name = userPref.name?.length ? userPref.name : contact;
    return Object.assign(
      {},
      {
        addedBy: {contact, name, uid: user.uid},
        columnId,
        rowId,
        isEdited: false,
        text: commentText,
        createdTimestamp: timestamp,
      },
      generateCommentId ? {id: generateDocId('cmt', '', 12)} : null,
      mergeObj,
    );
  },

  generateEditedCommentObj: (commentText, prevCommentObj) => {
    const timestamp = moment().utc().unix();
    const updateObj = {
      isEdited: true,
      text: commentText,
      modifiedTimestamp: timestamp,
    };
    return [Object.assign({}, prevCommentObj, updateObj), updateObj];
  },
};

const ListenToComments = (...args) => {
  try {
    const obj = new CommentsPagingWithListener(...args);
    return {
      deactivateListener: () => obj.deactivateListeners(),
      areMoreDocsAvailable: () => obj.areMoreDocsAvailable(),
      getMoreDocs: () => obj.getPreviousDocs(),
    };
  } catch (e) {
    captureInfo({args: JSON.stringify(args)});
    captureError(e);
    return {
      deactivateListener: () => {},
      areMoreDocsAvailable: () => false,
      getMoreDocs: () => {},
    };
  }
};

export {ListenToComments, CommentsUtils};
