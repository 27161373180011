import {
  setAutoFillLinkedDataFirestore,
  updateParentDocAutoFillLinkedDataFirestore,
  getMultipleDocs,
  getLinkedDocIdsForSharedDocs,
  fetchLinkedParentFileDataFromCloud,
  updateDocumentData,
  getParentRowDataFirestore,
} from './actionHelpers/tableLinksActionHelper';
import {editColumn, editRow, updateActiveDocMeta} from './tableAction';
import {
  checkIfPlainText,
  serializeError,
  getHeaderTypeMapping,
  callCloudFunction,
  convertCellDataToText,
} from '../utils/utils';
import {
  cloneDeep,
  forOwn,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  keys,
  omit,
} from 'lodash';
import * as tableLinksActionHelper from './actionHelpers/tableLinksActionHelper';
import {TABLE_LINKS_ACTION, VERSION_ACTION} from './actionType';
import {
  firestore,
  captureError,
  captureInfo,
  logAnalyticsEvent,
  ENV,
  ShowToast,
} from '../imports';
import {
  FIELD_TYPE_ID,
  SHARE_PERMISSION_TYPE,
  CLOUD_FUNCTION_PATHS,
  MINI_APPS,
  COLUMN_PROPERTY_KEYS,
  CLOUD_FUNCTION_COMMON_PARAMS,
  TABLE_LINK_OVERWRITE_RELATED_KEYS,
} from '../utils/constant';
import * as tableLinkUtils from '../utils/tableLinkUtils';
import {openFile} from './homeAction';
import {EXTRA_ACTIONS_UNDO_REDO_TYPE} from '../utils/undoRedoConstants';
import FirestoreDB from '../FirestoreHandlers/FirestoreDB';
import DocumentsMethods from '../FirestoreHandlers/Documents/DocumentsMethods';
import {AVAILABLE_ENTITLEMENTS, FREE_SUBSCRIPTIONS} from '../utils/premium';
import * as tableActionHelper from './actionHelpers/tableActionHelper';
import {TABLE_PRESS_RESTRICTIONS} from '../utils/tableViewUtils';
import {
  checkIfFilteredDataScreen,
  getHeaderFieldMappingForElasticSearch,
  isCustomRoleFilteredScreen,
  searchFilterOnMiniAppScreenCloudFunction,
} from './actionHelpers/miniAppsActionHelper';
import {changeAssigneeForMultipleRows} from './miniAppsAction';
import {commonBodyParamsForElastic} from './actionHelpers/searchFilterActionHelper';
import {getHeaderDataAsObj} from './actionHelpers/listColumnsActionHelper';

const createTableLink = (dataObj) => async (dispatch, getState) => {
  const {
    home: {activeDocumentId},
  } = getState();
  return setAutoFillLinkedDataFirestore(
    Object.assign({}, {activeDocId: activeDocumentId}, dataObj),
  );
};

const fetchAndUpdateTableLinkData =
  (versionId) => async (dispatch, getState) => {
    try {
      const {
        home: {activeDocumentId},
      } = getState();
      const docRef = (colName) =>
        firestore()
          .collection('documents')
          .doc(activeDocumentId)
          .collection('version')
          .doc(versionId)
          .collection(colName)
          .doc('mapping');
      const tableLinkingVersionData = {
        autoFillLinkedFiles: {},
        parentRowMeta: {},
      };
      await Promise.all([
        docRef('autoFillLinkedFiles')
          .get()
          .then((res) => {
            if (res.exists) {
              Object.assign(tableLinkingVersionData, {
                autoFillLinkedFiles: res.data(),
              });
            }
            return Promise.resolve();
          })
          .catch(() => Promise.resolve()),
        docRef('parentRowMeta')
          .get()
          .then((res) => {
            if (res.exists) {
              Object.assign(tableLinkingVersionData, {
                parentRowMeta: res.data(),
              });
            }
            return Promise.resolve();
          })
          .catch(() => Promise.resolve()),
      ]);
      dispatch({
        type: VERSION_ACTION.UPDATE_TABLE_LINKING_VERSION_DATA,
        payload: tableLinkingVersionData,
      });
    } catch (error) {
      captureError(error);
    }
  };

const clearTableLinkState = () => (dispatch) => {
  try {
    dispatch({
      type: TABLE_LINKS_ACTION.CLEAR_TABLE_LINK_STATE,
    });
  } catch (error) {
    captureError(error);
  }
};

const reApplyPrevState = () => (dispatch) => {
  dispatch({
    type: TABLE_LINKS_ACTION.REAPPLY_PREV_STATE,
  });
};

const updateRowIds = (parentTableData, parentDocId) => (dispatch, getState) => {
  try {
    // TODO:Remove function call from web
  } catch (error) {
    captureError(error);
  }
};

const getParentFileUniqueDataWithFilter =
  ({filters = [], docId = null, colId = null, isWithRowIds = false}) =>
  async (dispatch, getState) => {
    try {
      const {
        tableLinks: {activeChildData, activeParentDoc},
        auth: {userPref, user},
        miniApps: {miniApps, activeAppId, activeCustomRoleInfo},
      } = getState();
      const parentDocId = docId ? docId : activeParentDoc?.docId;
      const parentColId = colId
        ? colId
        : activeChildData?.colData?.linkedMeta?.[parentDocId]?.colId;
      const userTimezone = userPref?.timezone ?? 'Asia/Kolkata';
      const fieldType = activeChildData?.colData?.subType;
      const screens = miniApps[activeAppId].screens ?? {};
      let screenIdForDoc = null;
      for (const screenId in screens) {
        if (
          screens?.[screenId]?.type !== MINI_APPS.SCREEN_TYPE.DASHBOARD &&
          screens?.[screenId]?.docs?.[0]?.docId === parentDocId
        ) {
          screenIdForDoc = screenId;
          break;
        }
      }

      const [isCustomViewAccess, viewAccess] = isCustomRoleFilteredScreen(
        miniApps[activeAppId],
        user.uid,
        screenIdForDoc,
        activeCustomRoleInfo,
      );

      const payload = {
        filters,
        userTimezone,
        docId: parentDocId,
        columnId: parentColId,
        fieldType,
        isWithRowIds,
        ...(isCustomViewAccess ? {viewAccess} : {}),
      };

      const uniqueData =
        await tableLinksActionHelper.getUniqueDataForParentColWithElastic(
          payload,
        );
      return Object.assign({}, uniqueData);
    } catch (error) {
      captureError(error);
    }
  };

const deleteLinkedFiles =
  (currentHeaderData, parentDocId, activeDocId, colIndex, extra = {}) =>
  async (dispatch, getState) => {
    try {
      const {
        tableLinks: {autoFillLinkedFiles},
      } = getState();
      const headerData = currentHeaderData;
      const autoFillLinkedFilesData = Object.assign({}, autoFillLinkedFiles);
      dispatch(
        updateTableLinkActiveState({
          refreshChild: true,
        }),
      );
      const parentColId =
        headerData?.[colIndex]?.linkedMeta?.[parentDocId]?.colId ||
        extra?.parentColId ||
        null;

      if (
        parentColId &&
        autoFillLinkedFilesData &&
        Object.keys(autoFillLinkedFilesData).length > 0 &&
        parentDocId
      ) {
        if (headerData) {
          const updateData = {
            [`headerData.${colIndex}.linkedMeta.${parentDocId}`]:
              firestore(true).FieldValue.delete(),
          };

          await updateDocumentData(activeDocId, updateData);
        }

        const parentDocData = !isEmpty(autoFillLinkedFilesData?.[parentDocId])
          ? autoFillLinkedFilesData[parentDocId]
          : {};
        const updateObj = {};
        if (!isEmpty(parentDocData)) {
          if (Object.keys(parentDocData).length <= 1) {
            updateObj[parentDocId] = firestore(true).FieldValue.delete();
            dispatch(manageLinkedIdsList(parentDocId, {}, true));
          } else {
            updateObj[`${parentDocId}.${parentColId}`] =
              FirestoreDB.FieldValue().delete();
          }
          await tableLinksActionHelper.updateAutoFillLinkedFilesMapping(
            activeDocId,
            updateObj,
          );

          logAnalyticsEvent('TABLE_LINK_REMOVED', {
            docId: activeDocId,
            colId: headerData?.[colIndex]?.id || extra?.childColId || null,
            linkDocId: parentDocId,
          });
        }
      }
    } catch (err) {
      captureError(err);
    }
  };

const activateParentRowMetaListener =
  (docId, firestoreInstance) => (dispatch, getState) => {
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      const onParentRowMetaChange = (QuerySnapshot) => {
        try {
          if (!QuerySnapshot.exists) {
            return;
          }
          const {
            home: {activeDocumentId},
          } = getState();
          if (docId === activeDocumentId) {
            const data = QuerySnapshot.data();
            dispatch({
              type: TABLE_LINKS_ACTION.UPDATE_PARENT_ROW_META,
              payload: data,
            });
          }
        } catch (err) {
          captureInfo({
            err: serializeError(err),
            QuerySnapshot,
          });
          captureError(new Error('Error in activateParentRowMetaListener'));
        }
      };
      return FirestoreDB.FirestoreListener(
        FirestoreDB.documents.documentParentRowMetaRef(
          docId,
          firestoreInstance,
        ),
        onParentRowMetaChange,
      );
    } catch (error) {
      captureError(error);
    }
  };

const activateAutofillLinkedFilesListener =
  (docId, firestoreInstance) => (dispatch, getState) => {
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      const onAutoFillLinkedFilesDataChange = (QuerySnapshot) => {
        try {
          if (!QuerySnapshot.exists) {
            return;
          }
          const {
            home: {activeDocumentId},
          } = getState();
          if (docId === activeDocumentId) {
            const data = QuerySnapshot.data();
            dispatch({
              type: TABLE_LINKS_ACTION.FETCH_AUTOLINKED_DATA,
              payload: data,
            });
          }
        } catch (err) {
          captureInfo({
            err: serializeError(err),
            QuerySnapshot,
          });
          captureError(
            new Error('Error in activateAutofillLinkedFilesListener'),
          );
        }
      };
      return FirestoreDB.FirestoreListener(
        FirestoreDB.documents.documentAutoFillLinkedFilesRef(
          docId,
          firestoreInstance,
        ),
        onAutoFillLinkedFilesDataChange,
      );
    } catch (error) {
      captureError(error);
    }
  };

const fetchAutofillLinkedFiles = (docId) => async (dispatch, getState) => {
  try {
    const {
      home: {activeDocumentId},
    } = getState();
    const autoFillLinkedFilesSnap =
      await tableLinksActionHelper.fetchAutoFillLinkedFilesMapping(
        docId ?? activeDocumentId,
      );
    if (autoFillLinkedFilesSnap.exists) {
      dispatch({
        type: TABLE_LINKS_ACTION.FETCH_AUTOLINKED_DATA,
        payload: autoFillLinkedFilesSnap.data(),
      });
    }
  } catch (error) {
    captureError(error);
  }
};

const changeLoaderState =
  (isLoading = false) =>
  (dispatch) => {
    dispatch({
      type: TABLE_LINKS_ACTION.SHOW_LOADER_ON_DATA_FETCH,
      payload: isLoading ? true : false,
    });
  };

/**
 * Get linked doc ids from headerData.
 * @returns {Array<string>} Array of Linked Doc Ids
 */
const getLinkedDocIdsFromHeaderData =
  (docHeaderData = []) =>
  (dispatch, getState) => {
    const {
      table,
      home,
      tableLinks: {parentFileDocData},
    } = getState();
    const headerData = docHeaderData?.length
      ? docHeaderData
      : getOriginalHeaderData(home, table);
    return headerData
      .map((item) =>
        item.fieldType === FIELD_TYPE_ID.TABLE
          ? Object.keys(item.linkedMeta ?? {})
          : [],
      )
      .reduce((prevVal, currentVal) => [...currentVal, ...prevVal], [])
      .filter((item) => !Object.keys(parentFileDocData ?? {}).includes(item));
  };

const getUpdatedLinkedDocIds = (parentDocIds, currentDocIds) => {
  let isCurrentArrayChanged = false;

  currentDocIds = isArray(currentDocIds) ? currentDocIds : [];

  parentDocIds.forEach((element) => {
    if (!currentDocIds?.includes?.(element)) {
      currentDocIds.push(element);
      isCurrentArrayChanged = true;
    }
  });
  return {
    linkedDocIds: currentDocIds,
    isCurrentArrayChanged,
  };
};

const autoFillDataAndUpdateToFirestore =
  ({parentRowObj, parentDocId, parentRowId, selectedRows, autoFillData}) =>
  (dispatch, getState) => {
    try {
      const {userPref} = getState().auth;
      if (isEmpty(autoFillData)) {
        ShowToast('Something went wrong', userPref);
        return false;
      }

      const childOldRows = selectedRows.slice();
      const childUpdatedRows = selectedRows.slice();

      // Child Rows Loop
      for (let i = 0; i < childUpdatedRows.length; i++) {
        Object.keys(autoFillData).forEach((parentColId) => {
          const childColId = autoFillData[parentColId];
          const parentMeta = {
            colId: parentColId,
            docId: parentDocId,
            rowId: parentRowId,
          };

          if (isEmpty(parentRowObj?.[parentColId]?.val)) {
            childUpdatedRows[i] = omit(childUpdatedRows[i], [childColId]);
            return;
          }
          childUpdatedRows[i] = Object.assign({}, childUpdatedRows[i], {
            [childColId]: Object.assign({}, childUpdatedRows[i]?.[childColId], {
              val: parentRowObj[parentColId].val,
              parentMeta: parentMeta,
            }),
          });
        });
      }

      dispatch(
        changeAssigneeForMultipleRows([], {}, null, {
          finalRows: childUpdatedRows,
          oldRows: childOldRows,
        }),
      );
      return true;
    } catch (error) {
      captureError(error);
      return true;
    }
  };

const fetchParentFileData =
  ({
    isInitialFetch = false,
    docSnap = {},
    documentId = null,
    forceFetch = false,
    extra = {},
    paginationLimit = null,
  }) =>
  async (dispatch, getState) => {
    try {
      const {
        tableLinks: {parentFileDocData, areAllParentDocumentRowsFetched},
        premium: {subscriptions},
      } = getState();

      const canViewAllTableLinkValues = subscriptions.includes(
        AVAILABLE_ENTITLEMENTS.TABLE_LINKING_COLUMN,
      );

      const canViewUpto_50_TableLinkValues = subscriptions.includes(
        AVAILABLE_ENTITLEMENTS.UPTO_50_TABLE_LINK_VALUES,
      );

      const isChangeAssignee = extra?.isChangeAssignee ?? false;

      if (isInitialFetch) {
        const linkedDocIds = extra?.linkedDocIds
          ? extra.linkedDocIds
          : documentId
          ? [documentId]
          : await dispatch(getLinkedDocIds(docSnap, extra));

        const fetchStatusObj = {};
        linkedDocIds?.forEach((item) => {
          fetchStatusObj[item] = false;
        });
        dispatch({
          type: TABLE_LINKS_ACTION.SET_ROW_FETCH_FLAG,
          payload: fetchStatusObj,
        });

        const promiseArr = [];

        linkedDocIds?.forEach((docId) => {
          const handlePromise = async () => {
            const docData =
              await DocumentsMethods.getUserDocumentDataWithoutRows(docId);

            const docTableData = await DocumentsMethods.getRowsData({
              docId,
              isLimited: true,
              limit:
                isChangeAssignee && !isNil(paginationLimit)
                  ? paginationLimit
                  : canViewAllTableLinkValues || canViewUpto_50_TableLinkValues
                  ? 10
                  : FREE_SUBSCRIPTIONS.TABLE_LINKING_MAX_VALUES,
            });
            const tableData = tableActionHelper.processRowsData(
              docTableData,
              null,
              [],
              null,
            );

            if (
              (isChangeAssignee && tableData.length < paginationLimit) ||
              (!isChangeAssignee &&
                (canViewAllTableLinkValues || canViewUpto_50_TableLinkValues) &&
                tableData.length < 10) ||
              (!isChangeAssignee &&
                !canViewAllTableLinkValues &&
                !canViewUpto_50_TableLinkValues &&
                tableData.length <= FREE_SUBSCRIPTIONS.TABLE_LINKING_MAX_VALUES)
            ) {
              fetchStatusObj[docId] = true;
              dispatch({
                type: TABLE_LINKS_ACTION.SET_ROW_FETCH_FLAG,
                payload: fetchStatusObj,
              });
            }
            return {
              [docId]: {
                headerData: docData.headerData,
                fileObj: docData.fileObj,
                footerData: docData.footerData,
                tableData,
              },
            };
          };
          promiseArr.push(handlePromise);
        });

        const parentDocData = await Promise.all(
          promiseArr.map((fn) => fn()),
        ).then((res) => {
          return res.reduce((prev, current) => {
            return Object.assign(prev, current);
          }, {});
        });

        dispatch({
          type: TABLE_LINKS_ACTION.FETCH_PARENT_DOC_DATA,
          payload: parentDocData,
        });
      } else if (
        (documentId && !areAllParentDocumentRowsFetched[documentId]) ||
        forceFetch
      ) {
        const lastSortIndex =
          parentFileDocData[documentId]?.tableData[
            parentFileDocData[documentId]?.tableData.length - 1
          ]?.index;

        if (
          (!isNil(lastSortIndex) && isChangeAssignee) ||
          (!isNil(lastSortIndex) &&
            !isChangeAssignee &&
            (canViewAllTableLinkValues || canViewUpto_50_TableLinkValues))
        ) {
          const docTableData = await DocumentsMethods.getRowsData({
            docId: documentId,
            lastFetchedIndex: lastSortIndex,
            isLimited: true,
            limit: !isNil(paginationLimit) ? paginationLimit : 10,
          });

          if (
            (isChangeAssignee &&
              !isNil(paginationLimit) &&
              docTableData.docs.length < paginationLimit) ||
            (!isChangeAssignee && docTableData.docs.length < 10)
          ) {
            const updatedObj = Object.assign(
              {},
              areAllParentDocumentRowsFetched,
            );
            updatedObj[documentId] = true;
            dispatch({
              type: TABLE_LINKS_ACTION.SET_ROW_FETCH_FLAG,
              payload: updatedObj,
            });
          }

          if (docTableData.docs.length) {
            const tableData = tableActionHelper.processRowsData(
              docTableData,
              null,
              parentFileDocData[documentId]?.tableData ?? [],
              null,
            );

            const parentFileData = Object.assign({}, parentFileDocData);
            parentFileData[documentId].tableData = tableData;
            dispatch({
              type: TABLE_LINKS_ACTION.FETCH_PARENT_DOC_DATA,
              payload: parentFileData,
            });
          }
        }
      }
      dispatch({
        type: TABLE_LINKS_ACTION.UPDATE_PARENT_DATA_FETCHED_FLAG,
        payload: true,
      });
    } catch (error) {
      captureError(error);
      dispatch(changeLoaderState(false));
    }
  };

const getFilteredParentRowsFromElastic = ({
  docId,
  filterOptions,
  headerData,
  fileObj,
  filterConditionType = '',
  state = {auth: {}},
}) => {
  try {
    const {auth} = state;
    if (filterConditionType === 'AND') {
      const dataObj = {
        textToSearch: '',
        docId,
        originalDocumentId: docId,
        customSorting: fileObj?.customSorting,
        filters: filterOptions,
        headerIdFieldMapping: getHeaderFieldMappingForElasticSearch(headerData),
        // isDescOrderOfIndex: true,

        ...commonBodyParamsForElastic(auth),
      };
      return searchFilterOnMiniAppScreenCloudFunction(dataObj);
    }

    if (filterConditionType === 'OR') {
      return Promise.all(
        filterOptions.map((filter) => {
          const dataObj = {
            textToSearch: '',
            docId,
            originalDocumentId: docId,
            customSorting: fileObj?.customSorting,
            filters: [filter],
            headerIdFieldMapping:
              getHeaderFieldMappingForElasticSearch(headerData),
            // isDescOrderOfIndex: true,
            ...commonBodyParamsForElastic(auth),
          };
          return searchFilterOnMiniAppScreenCloudFunction(dataObj);
        }),
      )
        .then((res) => {
          let rowIDMap = {};
          let rowIdArr = [];
          res?.forEach((row) => {
            if (row.success) {
              const {rowIdDataMap, tableData} = row.response;
              rowIDMap = Object.assign({}, rowIDMap, rowIdDataMap);
              rowIdArr = [...rowIdArr, ...tableData];
              rowIdArr = [...new Set(rowIdArr)];
            }
          });
          return {
            success: true,
            response: {rowIdDataMap: rowIDMap, tableData: rowIdArr},
          };
        })
        .catch((err) => console.log('err in Filter', err));
    }
    return Promise.resolve();
  } catch (error) {
    captureError(error);
    return Promise.resolve();
  }
};

const getLinkedDocIds =
  (docSnap = {}, extra = {}) =>
  async (dispatch, getState) => {
    try {
      const {
        home: {activeDocumentMeta, activeDocumentId},
        miniApps: {docsData},
      } = getState();

      let currentLinkedDocIds;
      const linkedDocIdsFromHeaderData = dispatch(
        getLinkedDocIdsFromHeaderData(extra?.headerData),
      );

      if (!isEmpty(docSnap) && docSnap?.exists) {
        const docData = docSnap.data();
        currentLinkedDocIds = docData?.linkedDocIds ?? [];
      } else if (
        activeDocumentMeta?.collab?.permission ===
        SHARE_PERMISSION_TYPE.ENTRY_ONLY
      ) {
        currentLinkedDocIds = await getLinkedDocIdsForSharedDocs(
          activeDocumentId,
        );
      } else {
        currentLinkedDocIds = activeDocumentMeta?.linkedDocIds ?? [];
      }

      const linkedDocIdsObj = getUpdatedLinkedDocIds(
        linkedDocIdsFromHeaderData,
        currentLinkedDocIds,
      );

      if (linkedDocIdsObj.isCurrentArrayChanged) {
        // Update Linked Doc Ids on Firebase
        dispatch(
          updateActiveDocMeta({
            linkedDocIds: linkedDocIdsObj.linkedDocIds,
          }),
        );
      }

      if (
        linkedDocIdsObj?.linkedDocIds &&
        !linkedDocIdsObj?.linkedDocIds.length &&
        !isEmpty(extra) &&
        extra?.isFromMiniApps &&
        extra?.docId
      ) {
        const docHeaderData = docsData?.[extra.docId]?.headerData ?? {};
        if (!isEmpty(docHeaderData)) {
          const linkedDocIds = [];
          docHeaderData.forEach((headerObj) => {
            if (headerObj?.linkedMeta && !isEmpty(headerObj.linkedMeta)) {
              const docIds = Object.keys(headerObj.linkedMeta);
              docIds.forEach((docId) => {
                if (!linkedDocIds.includes(docId)) {
                  linkedDocIds.push(docId);
                }
              });
            }
          });
          return linkedDocIds;
        }
      }
      return linkedDocIdsObj?.linkedDocIds ?? [];
    } catch (error) {
      captureError(error);
      dispatch(changeLoaderState(false));
      return null;
    }
  };

const fetchParentDocData = (linkedDocIds) => async (dispatch, getState) => {
  try {
    const {
      home: {activeDocumentMeta},
    } = getState();
    const isShared = !isEmpty(activeDocumentMeta?.collab);

    if (isArray(linkedDocIds) && linkedDocIds.length > 0) {
      if (isShared) {
        try {
          // TODO: BackOff
          const response = await fetchLinkedParentFileDataFromCloud(
            linkedDocIds,
          );
          if (response?.success) {
            dispatch({
              type: TABLE_LINKS_ACTION.FETCH_PARENT_DOC_DATA,
              payload: response.parentDocDataObj,
            });
            return true;
          }
        } catch (error) {
          captureError(error);
        }
      } else {
        const docRef = firestore().collection('userDocuments');
        const parentDocDataObj = await getMultipleDocs(linkedDocIds, docRef);
        if (!isEmpty(parentDocDataObj)) {
          const ids = Object.keys(parentDocDataObj);
          const docTableData = await DocumentsMethods.getAllRowsData(ids[0]);
          Object.assign(parentDocDataObj[ids[0]], {
            tableData: docTableData,
          });
          dispatch({
            type: TABLE_LINKS_ACTION.FETCH_PARENT_DOC_DATA,
            payload: parentDocDataObj,
          });
          return true;
        }
      }
    }
  } catch (error) {
    captureError(error);
  }
};

const manageLinkedIdsList =
  (docId, extra = {}, isRemove = false) =>
  async (dispatch, getState) => {
    try {
      if (checkIfPlainText(docId)) {
        const {home} = getState();
        const currentLinkedDocIds = !isEmpty(extra?.activeDocumentMeta)
          ? extra?.activeDocumentMeta?.linkedDocIds
          : home.activeDocumentMeta?.linkedDocIds; // FIRESTORE LINKED IDS
        if (isArray(currentLinkedDocIds)) {
          if (currentLinkedDocIds.includes(docId) && isRemove) {
            dispatch(
              updateActiveDocMeta({
                linkedDocIds: currentLinkedDocIds.filter(
                  (item) => item != docId,
                ),
              }),
            ); // FOR REMOVING DOC ID
          } else if (!currentLinkedDocIds.includes(docId) && !isRemove) {
            if (!isEmpty(extra?.activeDocumentMeta)) {
              const fileIndex = home.files.findIndex(
                (item) => item.documentId === extra?.docId ?? docId,
              );
              dispatch(
                updateActiveDocMeta(
                  {linkedDocIds: [...currentLinkedDocIds, docId]},
                  {
                    documentId: extra?.docId,
                    documentMeta: extra?.activeDocumentMeta,
                    fileIndex: fileIndex,
                  },
                ),
              );
            } else {
              dispatch(
                updateActiveDocMeta({
                  linkedDocIds: [...currentLinkedDocIds, docId],
                }),
              ); // FOR ADDING DOC ID
            }
          }
        } else if (
          currentLinkedDocIds === null ||
          currentLinkedDocIds === undefined
        ) {
          const linkedDocIds = [docId];
          if (!isEmpty(extra?.activeDocumentMeta)) {
            const fileIndex = home.files.findIndex(
              (item) => item.documentId === extra?.docId,
            );
            dispatch(
              updateActiveDocMeta(
                {linkedDocIds},
                {
                  documentId: extra?.docId,
                  documentMeta: extra?.activeDocumentMeta,
                  fileIndex: fileIndex,
                },
              ),
            );
          } else {
            dispatch(updateActiveDocMeta({linkedDocIds}));
          }
        }
      }
    } catch (error) {
      captureError(error);
    }
  };

// const updateParentRowMetaCollection =
//   (updatedParentRowMeta) => async (dispatch, getState) => {
//     try {
//       if (!isEmpty(updatedParentRowMeta)) {
//         // UPDATE PARENT ROW META
//         const {
//           home: {activeDocumentId},
//         } = getState();
//         await tableLinksActionHelper.setToParentRowMetaMapping(
//           activeDocumentId,
//           {meta: updatedParentRowMeta},
//         );
//       }
//     } catch (error) {
//       captureError(error);
//     }
//   };

// TODO : Update ColData after
// - deleting a file
// - adding a file
// - changing a column
const updateTableLinkActiveState =
  ({
    colIndex,
    rowIndex,
    rowObj,
    fileName = '',
    docId, // parent docId
    parentRowMeta,
    docData = {},
    setDoc = false,
    setDocUsingId = null,
    refreshChild = false,
    addMore = false,
    colObj = null,
    activeDocId, // child doc Id of current screen
  }) =>
  (dispatch, getState) => {
    const {
      miniApps,
      table: {headerData, tableData},
      tableLinks: {parentFileDocData, activeChildData: activeChildDataRedux},
      auth: {user},
    } = getState();

    //miniApps Condition for table linking
    const {activeScreenId, activeAppId, docsData, activeCustomRoleInfo} =
      miniApps;
    const activeAppMeta = miniApps.miniApps?.[activeAppId];
    const miniAppDocId =
      activeDocId ?? activeAppMeta?.screens?.[activeScreenId]?.docs[0]?.docId;

    const currentTableData =
      (miniApps.searchFilterData?.[activeScreenId]?.isActive
        ? miniApps.searchFilterData[activeScreenId].tableData
        : checkIfFilteredDataScreen(
            activeAppMeta,
            user.uid,
            activeScreenId,
            activeCustomRoleInfo,
          )
        ? docsData?.[miniAppDocId]?.filterData?.[activeScreenId]?.tableData
        : docsData?.[miniAppDocId]?.tableData) ??
      tableData ??
      [];

    colIndex =
      setDocUsingId || refreshChild || addMore
        ? activeChildDataRedux?.colIndex
        : colIndex;
    rowIndex =
      setDocUsingId || refreshChild || addMore
        ? activeChildDataRedux?.rowIndex
        : rowIndex;

    const colData = colObj
      ? colObj
      : miniApps.docsData?.[miniAppDocId]?.headerData?.[colIndex] ??
        headerData?.[colIndex];
    const rowData = rowObj ?? currentTableData?.[rowIndex];

    const selectedLinkedDocData = colData?.linkedMeta?.[docId];

    const activeParentDoc = {
      docId: docId,
      fileName: setDoc ? fileName : selectedLinkedDocData?.fileName ?? '',
      docData:
        setDoc && !isEmpty(docData)
          ? docData
          : !setDoc && !isEmpty(parentFileDocData?.[docId])
          ? parentFileDocData?.[docId]
          : {},
    };

    const activeChildData = {
      colData: colData,
      rowId: rowData?.rowId,
      parentRowMeta,
      rowIndex,
      rowObj,
      colIndex,
      linkedData: selectedLinkedDocData ?? {},
    };

    const payloadData =
      refreshChild /** Refresh {activeParentDoc} data for remove file */
        ? {
            activeChildData: {
              linkedData: selectedLinkedDocData ?? {},
              colData,
            },
          }
        : setDocUsingId ||
          addMore /** Refresh {activeParentDoc} and {activeParentDoc} for - add more / remove file / change column  */
        ? {
            activeParentDoc,
            activeChildData: {
              linkedData: selectedLinkedDocData ?? {},
              colData,
            },
          }
        : setDoc /** Refresh {activeParentDoc} for - selecting value */
        ? {
            activeParentDoc,
          }
        : /** Initial Set */
          {
            activeChildData,
            activeParentDoc,
          };

    dispatch({
      type: TABLE_LINKS_ACTION.UPDATE_ACTIVE_STATE,
      payload: payloadData,
    });
    return {
      activeChildData,
      activeParentDoc,
    };
  };

const getOriginalHeaderData = (home, table) => {
  const isCustomPermission =
    home.activeDocumentMeta?.collab?.permission ===
    SHARE_PERMISSION_TYPE.CUSTOM;
  const {ENTRY_ONLY, CUSTOM_RESTRICTIONS} =
    TABLE_PRESS_RESTRICTIONS.SHARED_PERMISSIONS({
      collab: home.activeDocumentMeta?.collab,
    });
  return (isCustomPermission ||
    (ENTRY_ONLY && !isEmpty(CUSTOM_RESTRICTIONS))) &&
    table.originalHeaderData?.length
    ? table.originalHeaderData
    : table.headerData;
};

const getAutofillLinkedFilesDataFromHeader =
  (docId, miniAppsHeaderData = [], isMiniApps = false) =>
  (dispatch, getState) => {
    const {
      table,
      home,
      tableLinks: {autoFillLinkedFiles},
    } = getState();
    const headerData = isMiniApps
      ? miniAppsHeaderData
      : getOriginalHeaderData(home, table);
    const autoFillLinkedFilesOriginal = headerData.reduce((prevVal, value) => {
      const obj = {...prevVal};
      const colId = value.id;
      const linkedMeta = value?.linkedMeta ?? {};
      Object.keys(linkedMeta).forEach((doc_id) => {
        const {colId: parentColId} = linkedMeta[doc_id];
        obj[doc_id] = {...(obj?.[doc_id] ?? {}), [parentColId]: colId};
      });
      return obj;
    }, {});

    const originalDocAutoFillLinks = autoFillLinkedFilesOriginal?.[docId];

    const isChanged = !isEqual(
      autoFillLinkedFiles?.[docId],
      originalDocAutoFillLinks,
    );

    return {
      isChanged: !originalDocAutoFillLinks ? false : isChanged,
      autoFillLinkedFiles: originalDocAutoFillLinks,
    };
  };

const openChildFile = (docId) => (dispatch, getState) => {
  const {
    home: {files},
  } = getState();
  const item = files.find((fileObj) => fileObj.documentId === docId);
  if (item !== -1) {
    const response = dispatch(
      openFile(
        item.documentMeta.name,
        item.documentId,
        item.documentMeta,
        item.index,
      ),
    );
    return response ? item : null;
  }
  return false;
};

const updateColumnDependencyMapping =
  (columnDependencyData = {}) =>
  (dispatch) => {
    try {
      if (!isEmpty(columnDependencyData)) {
        const dependencyObj = columnDependencyData?.columnDependencyMapping;
        const dependencyArrObj = columnDependencyData?.columnDependencyArrObj;
        dispatch({
          type: TABLE_LINKS_ACTION.UPDATE_COLUMN_DEPENDENCY_MAPPING,
          payload: {
            dependencyObj,
            dependencyArrObj,
          },
        });
      }
    } catch (error) {
      captureError(error);
    }
  };

const createAndSetColumnDependencyMapping =
  ({tableColIds, headerIndexMapping, headerData, primaryColumnMapping}) =>
  (dispatch, getState) => {
    try {
      const {
        tableLinks: {
          forceStopDependencyArrUpdate,
          columnDependencyMapping,
          columnDependencyArrObj,
        },
      } = getState();

      if (forceStopDependencyArrUpdate) {
        return;
      }

      const tableIds =
        tableColIds && isArray(tableColIds) && tableColIds.length
          ? tableColIds.reverse().slice()
          : [];

      const dependencyObj = {};
      let dependencyArrObj = {};

      for (let i = 0; i < tableIds.length; i++) {
        const headerObjDependent = headerData[headerIndexMapping[tableIds[i]]];
        if (
          headerObjDependent?.columnProperties?.[
            COLUMN_PROPERTY_KEYS.IS_DEPENDENT_COLUMN
          ]
        ) {
          for (let j = i + 1; j < tableIds.length; j++) {
            const headerObj = headerData[headerIndexMapping[tableIds[j]]];
            const headerObjLinkedMetaKeys = Object.keys?.(
              headerObj?.linkedMeta ?? {},
            );
            if (
              (!headerObj?.columnProperties?.[
                COLUMN_PROPERTY_KEYS.IS_DEPENDENT_COLUMN
              ] &&
                primaryColumnMapping[headerObjLinkedMetaKeys[0]] ===
                  headerObj.id &&
                Object.keys(headerObjDependent?.linkedMeta).some((item) =>
                  headerObjLinkedMetaKeys.includes(item),
                )) ||
              (headerObj?.columnProperties?.[
                COLUMN_PROPERTY_KEYS.IS_DEPENDENT_COLUMN
              ] &&
                Object.keys(headerObjDependent?.linkedMeta).some((item) =>
                  headerObjLinkedMetaKeys.includes(item),
                ))
            ) {
              dependencyObj[headerObjDependent.id] = headerObj.id;

              if (
                !isEmpty(dependencyArrObj) &&
                headerObjLinkedMetaKeys[0] in dependencyArrObj
              ) {
                const dependentArrItems =
                  dependencyArrObj[headerObjLinkedMetaKeys[0]];
                if (!dependentArrItems.includes(headerObjDependent.id)) {
                  dependentArrItems.push(headerObjDependent.id);
                }
                dependentArrItems.push(headerObj.id);
                dependencyArrObj[headerObjLinkedMetaKeys[0]] =
                  dependentArrItems;
              } else {
                dependencyArrObj = {
                  ...dependencyArrObj,
                  [headerObjLinkedMetaKeys[0]]: [
                    headerObjDependent.id,
                    headerObj.id,
                  ],
                };
              }
              break;
            } else {
              continue;
            }
          }
        }
      }

      if (!isEmpty(dependencyArrObj)) {
        for (const parentDocId in dependencyArrObj) {
          dependencyArrObj[parentDocId] =
            dependencyArrObj[parentDocId]?.reverse();
        }
      }
      //empty dependency checks are removed here incase the dependency have to be cleared while updating
      if (!isEmpty(dependencyObj) && !isEmpty(dependencyArrObj)) {
        dispatch({
          type: TABLE_LINKS_ACTION.UPDATE_COLUMN_DEPENDENCY_MAPPING,
          payload: {
            dependencyObj,
            dependencyArrObj,
          },
        });
      } else if (isEmpty(dependencyObj) && isEmpty(dependencyArrObj)) {
        if (
          !isEmpty(columnDependencyMapping) ||
          !isEmpty(columnDependencyArrObj)
        ) {
          dispatch({
            type: TABLE_LINKS_ACTION.CLEAR_COLUMN_DEPENDENCY_MAPPING,
          });
        }
      }
    } catch (error) {
      captureError(error);
    }
  };

const autoFillParentValue =
  ({parentDocId, parentRowId, rowValues, isWeb, childDocId}) =>
  async (dispatch, getState) => {
    try {
      const {autoFillLinkedFiles: autoFillLinkedFilesObj, activeChildData} =
        getState().tableLinks;
      let autoFillLinkedFiles = {...autoFillLinkedFilesObj};

      // Fetch AutoFillLinkedFilesMapping only if childDocId is present
      if (isEmpty(autoFillLinkedFiles?.[parentDocId]) && childDocId) {
        const autoFillLinkedFilesSnap =
          await tableLinksActionHelper.fetchAutoFillLinkedFilesMapping(
            childDocId,
          );
        if (autoFillLinkedFilesSnap.exists) {
          autoFillLinkedFiles = autoFillLinkedFilesSnap.data();
        }
      }

      const currAutoFillLinkedFiles = !isEmpty(autoFillLinkedFiles)
        ? autoFillLinkedFiles?.[parentDocId]
        : {};

      const updatedParentMeta = {};
      const updatedRowValues = {};
      Object.keys(currAutoFillLinkedFiles).forEach((parentColId) => {
        const childColId = currAutoFillLinkedFiles?.[parentColId];
        if (!isEmpty(rowValues?.[parentColId])) {
          const parentMeta = {
            colId: parentColId,
            docId: parentDocId,
            rowId: parentRowId,
          };
          updatedParentMeta[childColId] = parentMeta;
          if (
            rowValues?.[parentColId]?.[
              TABLE_LINK_OVERWRITE_RELATED_KEYS.IS_VALUE_OVERRIDEN
            ]
          ) {
            // If parent value is overriden
            // remove overriden flag and
            // previousCellObject to make sure it doesn't pass through
            // to child row and add a new flag to denote
            // parentColumn's value was overrriden one
            updatedRowValues[childColId] = Object.assign(
              {},
              omit(rowValues?.[parentColId], [
                TABLE_LINK_OVERWRITE_RELATED_KEYS.IS_VALUE_OVERRIDEN,
                TABLE_LINK_OVERWRITE_RELATED_KEYS.PREVIOUS_CELL_OBJECT,
              ]),
              {
                [TABLE_LINK_OVERWRITE_RELATED_KEYS.IS_PARENT_VALUE_OVERRIDEN]: true,
              },
            );
          } else {
            updatedRowValues[childColId] = rowValues?.[parentColId];
          }
        }
      });

      if (isWeb) {
        return {
          parentMeta: updatedParentMeta,
          rowValues: updatedRowValues,
        };
      }

      return {
        navigatePath: '',
        navigateParams: {
          parentMeta: updatedParentMeta,
          rowValues: updatedRowValues,
        },
      };
    } catch (error) {
      captureError(error);
    }
  };

const onTableLinkSelectValue =
  ({
    hasDependency = false,
    isFromRowEdit,
    isQuickEntry,
    itemRowIndex,
    alreadyCreatedParentRowMeta,
    value,
    parentRowId,
    isSearchedVal,
    miniAppsHeaderData = [],
    isMiniApps = false,
    activeDocId = null,
    headerIndexMapping = {},
    primaryColumnMapping = {},
    selectedRowData = {},
    screenId,
  }) =>
  async (dispatch, getState) => {
    try {
      const {
        tableLinks: {
          activeChildData,
          activeParentDoc,
          autoFillLinkedFiles: autoFillLinkedFilesObj,
          parentFileDocData,
          search,
          columnDependencyMapping,
          columnDependencyArrObj,
        },
        auth: {user},
        table: {tableData, headerData},
        home: {activeDocumentId, originalDocumentId},
        miniApps,
      } = getState();

      const {linkedData, rowIndex, rowObj, colIndex, colData} = activeChildData;
      const colObj = colData ?? {};
      const {docId} = activeParentDoc;

      const columnDependencyArr =
        columnDependencyArrObj?.[docId]?.slice() ?? [];

      //miniApps Condition for table linking
      let {activeScreenId} = miniApps;
      const {activeAppId} = miniApps;
      if (!isNil(screenId)) activeScreenId = screenId;
      const activeAppMeta = miniApps.miniApps?.[activeAppId];
      const miniAppDocId =
        activeDocId ?? activeAppMeta?.screens?.[activeScreenId]?.docs[0]?.docId;
      let currMiniAppsheaderData = null;
      let currMiniAppstableData = null;

      if (miniAppDocId) {
        const columnRestrictions =
          miniApps.activeCustomRoleInfo?.screenConfiguration?.[activeScreenId]
            ?.columnRestrictions;
        const isRestrictedEditAccess =
          activeAppMeta?.sharedWith?.[user?.uid]?.permission ===
            MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.CUSTOM_ROLE &&
          !isEmpty(columnRestrictions);
        const mappedHeader =
          miniApps.docsData?.[miniAppDocId]?.headerData ?? [];
        currMiniAppsheaderData = isRestrictedEditAccess
          ? mappedHeader.filter(
              (colObj) => !columnRestrictions[colObj?.id]?.isReadOnly,
            )
          : mappedHeader;

        const isSearchFilterActive =
          miniApps.searchFilterData?.[activeScreenId]?.isActive;
        const isFilteredDataScreen =
          !isSearchFilterActive &&
          checkIfFilteredDataScreen(
            activeAppMeta,
            user?.uid,
            activeScreenId,
            miniApps.activeCustomRoleInfo,
          );
        currMiniAppstableData =
          (isSearchFilterActive
            ? miniApps.searchFilterData[activeScreenId].tableData
            : isFilteredDataScreen
            ? miniApps.docsData?.[miniAppDocId]?.filterData?.[activeScreenId]
                ?.tableData
            : miniApps.docsData?.[miniAppDocId].tableData) ?? [];
      }

      // Get AutoFillLinked Files Data
      const {isChanged, autoFillLinkedFiles: originalAutoFillLinks} = dispatch(
        getAutofillLinkedFilesDataFromHeader(
          docId,
          currMiniAppsheaderData ?? miniAppsHeaderData,
          isMiniApps,
        ),
      );

      if (isChanged && !isMiniApps) {
        updateParentDocAutoFillLinkedDataFirestore({
          obj: originalAutoFillLinks,
          docId: activeDocumentId ?? miniAppDocId,
          parentDocID: docId,
        });
      }

      const autoFillLinkedFiles = isChanged
        ? originalAutoFillLinks
        : autoFillLinkedFilesObj?.[docId];

      const headerMapping = getHeaderTypeMapping(
        isFromRowEdit ? currMiniAppsheaderData : headerData,
      );

      if (typeof autoFillLinkedFiles === 'object') {
        const editColId = colObj.id;

        const initCurrentRowValue = currMiniAppstableData
          ? rowObj ?? currMiniAppstableData[rowIndex] // Check if rowObject Available else from MiniApps TableData
          : tableData[rowIndex];
        const currentRowValue = {...initCurrentRowValue};
        const obj = {};

        if (isEqual(value, {})) {
          obj[editColId] = {};
        }

        const updatedParentMeta = {};
        const updatedParentRowMeta = {};
        let parentRowData = {};

        if (
          editColId === columnDependencyArr[columnDependencyArr?.length - 1] &&
          parentRowId
        ) {
          const parentRow = await getParentRowDataFirestore(docId, parentRowId);
          parentRowData = parentRow.exists ? parentRow.data() : {};
        }

        Object.keys(autoFillLinkedFiles).forEach((parentColId) => {
          const childColId = autoFillLinkedFiles[parentColId];

          const keysForColumnDependencyMapping =
            Object.keys(columnDependencyMapping)?.slice() ?? [];

          if (hasDependency) {
            if (
              ([
                ...keysForColumnDependencyMapping,
                ...Object.values(columnDependencyMapping),
              ].includes(childColId) &&
                childColId !== editColId) ||
              (editColId !==
                columnDependencyArr[columnDependencyArr.length - 1] &&
                Object.keys(headerMapping[childColId]?.linkedMeta)?.includes(
                  docId,
                ) &&
                !headerMapping[childColId]?.columnProperties?.[
                  COLUMN_PROPERTY_KEYS.IS_DEPENDENT_COLUMN
                ] &&
                !isEmpty(primaryColumnMapping) &&
                childColId !== primaryColumnMapping[docId])
            ) {
              return;
            } else if (
              !isEmpty(parentRowData) &&
              !isEmpty(parentRowData?.rowObj)
            ) {
              value = parentRowData?.rowObj?.[parentColId];
              if (!value) {
                return;
              }
            }
          }

          let currentCellValues = {};

          if (headerMapping?.[childColId]?.fieldType === FIELD_TYPE_ID.TABLE) {
            let autoFillVal = '';
            if (isSearchedVal) {
              const index = search?.tableData.findIndex(
                (item) => item.rowId === parentRowId,
              );
              if (index !== -1) {
                const responseObj = search.tableData[index];
                autoFillVal = responseObj[parentColId];
                autoFillVal = omit(autoFillVal, ['childLinks']);
              }
            }

            let parentTableValue =
              isSearchedVal && search?.isActive && search?.tableData?.length > 0
                ? autoFillVal
                : !isEmpty(selectedRowData)
                ? selectedRowData?.[parentColId]
                : !isNil(itemRowIndex)
                ? parentFileDocData?.[docId]?.tableData?.[itemRowIndex]?.[
                    parentColId
                  ]
                : value;

            parentTableValue = omit(parentTableValue, ['childLinks']);
            value = omit(value, ['childLinks']);

            let addVal =
              childColId !== editColId
                ? isSearchedVal
                  ? {...parentTableValue, val: parentTableValue.val}
                  : parentTableValue
                : value;

            if (
              addVal?.[TABLE_LINK_OVERWRITE_RELATED_KEYS.IS_VALUE_OVERRIDEN]
            ) {
              // If parent value is overriden
              // remove overriden flag and
              // previousCellObject to make sure it doesn't pass through
              // to child row and add a new flag to denote
              // parentColumn's value was overrriden one
              addVal = omit(addVal, [
                TABLE_LINK_OVERWRITE_RELATED_KEYS.IS_VALUE_OVERRIDEN,
                TABLE_LINK_OVERWRITE_RELATED_KEYS.PREVIOUS_CELL_OBJECT,
              ]);
              addVal[
                TABLE_LINK_OVERWRITE_RELATED_KEYS.IS_PARENT_VALUE_OVERRIDEN
              ] = true;
            }

            if (
              !isEmpty(currentRowValue) &&
              !isEmpty(currentRowValue[childColId])
            ) {
              currentCellValues = currentRowValue[childColId];
            }

            if (isEqual(value, {})) {
              obj[childColId] = {};
            } else {
              obj[childColId] = Object.assign(
                {},
                currentCellValues,
                addVal ? addVal : {},
              );
            }

            const updateData = {
              colId: childColId !== editColId ? parentColId : linkedData.colId,
              docId: docId,
              rowId: parentRowId,
            };
            updatedParentMeta[childColId] = updateData;

            if (
              keysForColumnDependencyMapping.includes(childColId) &&
              columnDependencyArr[columnDependencyArr.length - 1] === childColId
            ) {
              const headerDataCurr = isFromRowEdit
                ? currMiniAppsheaderData
                : headerData;
              columnDependencyArr.forEach((colId) => {
                if (colId !== childColId) {
                  const updateDataNew = {
                    colId:
                      headerDataCurr[headerIndexMapping[colId]]?.linkedMeta?.[
                        docId
                      ]?.colId,
                    docId: docId,
                    rowId: parentRowId,
                  };
                  updatedParentMeta[colId] = Object.assign({}, updateDataNew);
                }
              });
            }

            logAnalyticsEvent('TABLE_LINK_VALUE_ADDED', {
              docId: miniAppDocId ?? activeDocumentId,
              colId: childColId,
              linkDocId: docId,
              linkColId: parentColId,
            });
          }
        });

        const rowValues = Object.assign({}, currentRowValue, obj);

        if (isQuickEntry) {
          return {
            navigatePath: 'NewQuickEntryRow',
            navigateParams: {
              parentMeta: updatedParentMeta,
              rowValues: obj,
            },
          };
        } else if (isFromRowEdit) {
          return {
            navigatePath: '',
            navigateParams: {
              parentMeta: updatedParentMeta,
              rowValues: obj,
            },
          };
        } else {
          dispatch(
            editRow(
              rowValues,
              rowIndex,
              {currentlyModifiedColId: editColId},
              false,
              {updatedParentMeta},
            ),
          );
          return {
            navigatePath: 'NewSheet',
            ...(!ENV
              ? {parentRowMetaData: updatedParentRowMeta, rowValues: obj}
              : {}),
          };
        }
      }
      return {};
    } catch (error) {
      captureError(error);
      return {};
    }
  };

const deleteChildLinks = (rows) => (dispatch, getState) => {
  try {
    const {
      tableLinks: {parentRowMeta},
      auth: {user},
      home: {activeDocumentId, originalDocumentId},
    } = getState();
    let addAction = () => {};
    let removeAction = () => {};

    const linkDataArray = [];

    rows.forEach((rowData) => {
      Object.keys(rowData).forEach((colId) => {
        const parentRowMetaId = rowData?.[colId]?.['parentRowMeta'];

        if (parentRowMetaId && parentRowMeta?.['meta']?.[parentRowMetaId]) {
          const {parentColId, parentRowId, parentFileId} =
            parentRowMeta['meta'][parentRowMetaId];

          linkDataArray.push({
            childColId: colId,
            parentColId,
            childRowId: rowData['rowId'],
            parentRowId,
            childDocId: originalDocumentId,
            parentDocId: parentFileId,
            childPageId: activeDocumentId,
          });
        }
      });
    });

    if (linkDataArray.length) {
      const removeObj = {
        type: 'DELETE',
        uid: user.uid,
        linkData: linkDataArray,
      };
      const addObj = {
        type: 'ADD',
        uid: user.uid,
        linkData: linkDataArray,
      };

      /** Function for undo/redo */
      addAction = () => tableLinksActionHelper.manageChildLinks(addObj);
      removeAction = () => tableLinksActionHelper.manageChildLinks(removeObj);

      // Remove Links From Parent File
      removeAction();
    }

    return {
      extraRedoActions: [
        {
          type: EXTRA_ACTIONS_UNDO_REDO_TYPE.CLOUD_FUNCTION_CALL,
          callback: removeAction,
        },
      ],
      extraUndoActions: [
        {
          type: EXTRA_ACTIONS_UNDO_REDO_TYPE.CLOUD_FUNCTION_CALL,
          callback: addAction,
        },
      ],
    };
  } catch (error) {
    captureError(error);
    return {
      extraRedoActions: [],
      extraUndoActions: [],
    };
  }
};

const selectColumnAnalyticsCall = ({
  changeLinkedFile,
  linkedFiles,
  changedLinkedFileDocId,
  activeDocumentId,
  colObj,
  linkedMeta,
  editObj,
  changeColumn,
}) => {
  if (changeLinkedFile != undefined || changeColumn != undefined) {
    if (changeLinkedFile) {
      const linkedParentFiles = linkedFiles.filter(
        (item) => item != changedLinkedFileDocId,
      );
      logAnalyticsEvent('TABLE_LINK_UPDATED', {
        docId: activeDocumentId,
        colId: colObj.id,
        linkDocId: linkedParentFiles,
        operationType: 'Change File',
      });
    } else if (changeColumn) {
      logAnalyticsEvent('TABLE_LINK_UPDATED', {
        docId: activeDocumentId,
        colId: colObj.id,
        linkDocId: linkedFiles,
        operationType: 'Change Column',
      });
    }
  } else {
    if (linkedMeta && Object.keys(linkedMeta).length > 0) {
      logAnalyticsEvent('TABLE_LINK_UPDATED', {
        docId: activeDocumentId,
        colId: colObj.id,
        linkDocId: linkedFiles,
        operationType: 'Add another File',
      });
    } else {
      logAnalyticsEvent('TABLE_LINK_CREATED', {
        docId: activeDocumentId,
        colId: colObj.id,
        linkDocId: editObj.selectedDocID,
        linkColId: editObj.selectedColObj.colId,
        operationType: 'add table',
      });
    }
  }
};

const onTableLinkSelectColumn =
  ({changeLinkedFile, changeColumn, selectedColumn, firstAdd, addMore}) =>
  (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      try {
        const {
          home: {activeDocumentId, activeDocumentMeta},
          auth: {user},
          tableLinks: {activeParentDoc, activeChildData, previousParentData},
          table: {headerData},
        } = getState();

        const changedLinkedFileDocId = previousParentData?.docId;
        const changedLinkedFileColId = previousParentData?.colId;

        const {docId: selectedDocID, fileName} = activeParentDoc;
        const {colIndex, colData: colObj} = activeChildData;

        const editObj = {
          type: FIELD_TYPE_ID.TABLE,
          index: colIndex,
          subType:
            selectedColumn.fieldType === FIELD_TYPE_ID.TABLE
              ? selectedColumn.subType
              : selectedColumn.fieldType,
          parentSubType: selectedColumn?.subType ?? undefined,
          selectedDocID: selectedDocID,
          changeLinkedFile: addMore
            ? undefined
            : changeLinkedFile
            ? changedLinkedFileDocId
            : undefined, // TODO : Update This in Redux and then access and also add types for change and delete.
          selectedColObj: {
            colId: selectedColumn.id,
            colName: selectedColumn.val,
            fileName: fileName,
          },
          property: selectedColumn?.columnProperties,
        };

        const linkedMeta = colObj?.linkedMeta || {};

        let linkedFiles = [];

        if (!isEmpty(linkedMeta)) {
          linkedFiles = Object.keys(linkedMeta);
        }
        linkedFiles.push(editObj.selectedDocID);

        /**
         * Add Analytics
         */
        selectColumnAnalyticsCall({
          activeDocumentId,
          changeColumn,
          changedLinkedFileDocId,
          changeLinkedFile,
          colObj,
          editObj,
          linkedFiles,
          linkedMeta,
        });

        const sendObj = {
          changeColumn,
          firstAdd,
          addMore,
          activeDocumentId,
          changeLinkedFile,
          colObj,
          activeDocumentMeta,
          changedLinkedFileColId,
          changedLinkedFileDocId,
          uid: user.uid,
        };

        const extra = {
          isUpdate: changeColumn ?? false,
          prevColId: changedLinkedFileColId,
          ...tableLinkUtils.getTableLinkColumnUpdateUndoRedoActions(
            editObj,
            sendObj,
          ),
        };

        if (!changeLinkedFile) {
          dispatch(
            handleTableLinkColumnUpdate({
              editObj,
              selectedColumn,
              colObj,
              extra,
            }),
          )
            .then(() => {
              resolve({done: true, editObj});
            })
            .catch(() => resolve({canceled: true}));
        } else {
          dispatch(
            deleteLinkedFiles(
              headerData,
              changedLinkedFileDocId,
              activeDocumentId,
              colIndex,
            ),
          )
            .then(() => {
              dispatch(
                handleTableLinkColumnUpdate({
                  editObj,
                  selectedColumn,
                  colObj,
                  extra,
                }),
              )
                .then(() => resolve({done: true, editObj}))
                .catch(() => resolve({canceled: true}));
            })
            .catch(() => resolve({canceled: true}));
        }
      } catch (error) {
        captureError(error);
        resolve({canceled: true});
      }
    });
  };

const handleTableLinkColumnUpdate =
  ({editObj, selectedColumn, colObj, extra = {}}) =>
  async (dispatch, getState) => {
    const {
      tableLinks: {parentFileDocData, activeParentDoc},
    } = getState();

    let tableUnitMeta = {};
    if (selectedColumn?.fieldType === FIELD_TYPE_ID.UNIT) {
      const selectedColumnId = selectedColumn?.id;
      tableUnitMeta =
        activeParentDoc?.docData?.fileObj?.[selectedColumnId] ?? {};
    }

    const {docData = {}, docId} = activeParentDoc ?? {};
    await dispatch(
      editColumn(editObj, {
        extraRedoActions: extra?.extraRedoActions,
        extraUndoActions: extra?.extraUndoActions,
        tableUnitMeta,
      }),
    );

    // const parentFileSelectedColumnIndex = Object.values(
    //   docData?.headerData ?? {},
    // ).findIndex((headerObj) => headerObj.id == selectedColumn.id);
    // if (parentFileSelectedColumnIndex != -1) {
    //   tableLinksActionHelper.setViewLinkedFilesFlag(
    //     editObj.selectedDocID,
    //     parentFileSelectedColumnIndex,
    //   );
    // }

    if (extra?.isUpdate) {
      if (editObj.selectedDocID) {
        if (colObj?.id) {
          // AUTO FILL LINKED ADD/UPDATE
          dispatch(
            createTableLink({
              parentDocID: editObj.selectedDocID,
              parentColId: selectedColumn.id,
              childColId: colObj?.id,
              ...extra,
            }),
          );
        }

        if (!Object.keys(parentFileDocData).includes(editObj.selectedDocID)) {
          // ADD PARENT TABLE DATA IN REDUX
          dispatch(
            fetchParentFileData({
              isInitialFetch: true,
              documentId: editObj.selectedDocID,
            }),
          );

          // UPDATE PARENT ROW ID-INDEX MAPPING IF NOT DONE
          // dispatch(updateRowIds(docData.tableData, docId));
        }

        // ADD PARENT DOC ID TO linkedDocIDs in DocumentMeta
        dispatch(manageLinkedIdsList(editObj.selectedDocID));
      }
    } else {
      if (editObj.selectedDocID) {
        if (colObj?.id) {
          // AUTO FILL LINKED ADD/UPDATE
          await dispatch(
            createTableLink({
              parentDocID: editObj.selectedDocID,
              parentColId: selectedColumn.id,
              childColId: colObj?.id,
              ...extra,
            }),
          );
        }
        if (!Object.keys(parentFileDocData).includes(editObj.selectedDocID)) {
          // ADD PARENT TABLE DATA IN REDUX
          // await dispatch(fetchParentDocData([editObj.selectedDocID]));
          await dispatch(
            fetchParentFileData({
              isInitialFetch: true,
              documentId: editObj.selectedDocID,
            }),
          );

          // UPDATE PARENT ROW ID-INDEX MAPPING IF NOT DONE
          await dispatch(updateRowIds(docData.tableData, docId));
        }
        // ADD PARENT DOC ID TO linkedDocIDs in DocumentMeta
        await dispatch(manageLinkedIdsList(editObj.selectedDocID));
      }
    }
    // Refresh Active State
    dispatch(
      updateTableLinkActiveState({
        setDocUsingId: true,
        docId: editObj.selectedDocID,
      }),
    );
  };

const updatePreviousParentDocData =
  (reset = false) =>
  (dispatch, getState) => {
    const {
      tableLinks: {activeParentDoc, activeChildData},
    } = getState();

    dispatch({
      type: TABLE_LINKS_ACTION.UPDATE_PREVIOUS_PARENT_DATA,
      payload: reset
        ? {}
        : {
            docId: activeParentDoc.docId,
            colId: activeChildData?.linkedData?.colId,
          },
    });
  };

const copyPreviousTableLinkState = () => (dispatch) => {
  try {
    dispatch({
      type: TABLE_LINKS_ACTION.COPY_PREVIOUS_TABLE_LINK_STATE,
    });
  } catch (error) {
    captureError(error);
  }
};

const deleteColumnTableLinkHandler =
  (colObj, colIndex) => (dispatch, getState) => {
    const {
      home: {activeDocumentId, activeDocumentMeta},
      auth: {user},
      tableLinks: {autoFillLinkedFiles},
    } = getState();

    const isShared = !isEmpty(activeDocumentMeta?.collab);
    const linkedMeta = colObj.linkedMeta;

    const undoActions = [];
    const redoActions = [];
    const promises = [];

    try {
      for (const parentDocId in linkedMeta) {
        const parentColId = linkedMeta[parentDocId].colId;
        const childColId = colObj.id;

        undoActions.push({
          updateObjType: 'object',
          elementType: 'object',
          element: {[parentColId]: childColId},
          action: 'ADD',
          collection: 'userDocuments',
          path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
          addIdPathIfNotExist: true,
          updatePath: `${parentDocId}`,
          runAfterParentObjAdd: [
            {
              updateObjType: 'array',
              elementType: 'string',
              element: parentDocId,
              action: 'ADD',
              collection: isShared ? 'sharedDocsMeta' : 'users',
              path: isShared
                ? `${activeDocumentId}`
                : `${user.uid}/documents/${activeDocumentId}`,
              updatePath: 'linkedDocIds',
            },
          ],
        });
        if (autoFillLinkedFiles?.[parentDocId]) {
          redoActions.push({
            updateObjType: 'object',
            elementType: 'object',
            element: {[parentColId]: childColId},
            action: 'REMOVE',
            collection: 'userDocuments',
            path: `${activeDocumentId}/autoFillLinkedFiles/mapping`,
            removeParentObjIfEmpty:
              keys(autoFillLinkedFiles[parentDocId])?.length == 1
                ? true
                : false,
            updatePath: `${parentDocId}`,
            runAfterParentObjEmptyRemove: [
              {
                updateObjType: 'array',
                elementType: 'string',
                element: parentDocId,
                action: 'REMOVE',
                collection: isShared ? 'sharedDocsMeta' : 'users',
                path: isShared
                  ? `${activeDocumentId}`
                  : `${user.uid}/documents/${activeDocumentId}`,
                updatePath: 'linkedDocIds',
              },
            ],
          });
        }
        promises.push(() =>
          dispatch(
            deleteLinkedFiles(null, parentDocId, activeDocumentId, colIndex, {
              parentColId,
              childColId,
            }),
          ),
        );
      }
      return {
        undoActions,
        redoActions,
        promises,
      };
    } catch (error) {
      captureError(error);
      return {
        undoActions: [],
        redoActions: [],
        promises: [],
      };
    }
  };

const searchStringTableLinkHelper = ({
  rowsArr = [],
  searchText = '',
  userPref,
  isOrganisationMode = false,
  headerData = [],
}) => {
  const headerDataAsObj = getHeaderDataAsObj(headerData);
  searchText = searchText.trim().toLowerCase();

  return rowsArr.filter((rowData) => {
    let isSearchValExistsOnRow = false;
    forOwn(rowData?.rowObj || rowData, (cellObj, colId) => {
      if (headerDataAsObj[colId]) {
        const val = convertCellDataToText(
          cellObj,
          headerDataAsObj[colId],
          userPref?.country,
          userPref,
          {},
          {},
          isOrganisationMode,
          {},
          false,
        );
        isSearchValExistsOnRow = val?.toLowerCase()?.includes(searchText);
        if (isSearchValExistsOnRow) {
          return false;
        }
      }
    });
    return isSearchValExistsOnRow;
  });
};

const searchStringTableLinking =
  (str, extra = {}) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: TABLE_LINKS_ACTION.SET_SEARCH_LOADING,
        payload: {isLoading: true},
      });
      dispatch({
        type: TABLE_LINKS_ACTION.SET_SEARCH_ACTIVE,
      });
      const {
        tableLinks,
        auth: {userPref},
        remoteConfig: {isOrganisationMode},
      } = getState();

      const isDependency = extra?.isDependency ?? false;
      const filteredData = extra?.filteredData ?? [];
      const isScanBarcode = extra?.isScanBarcode ?? false;
      const isAssignTaskColumn = extra?.isAssignTaskColumn ?? false;
      const hasTableLinkFilters = extra?.hasTableLinkFilters ?? false;
      const parentColId = tableLinks?.activeChildData?.linkedData?.colId;
      const parentHeaderData = extra?.headerData ?? [];
      const tableLinkSearchData = tableLinks?.search?.tableData;
      const searchAfterIndex =
        tableLinkSearchData[tableLinkSearchData.length - 1]?.index || '';
      const searchAfterRowId =
        tableLinkSearchData[tableLinkSearchData.length - 1]?.rowId || '';
      const searchAfter = [searchAfterIndex, searchAfterRowId];
      const isFromSubmitButton = extra?.isFromSubmitButton || false;
      const fieldType = extra?.fieldType;
      let objToSend = {};

      if (isDependency || hasTableLinkFilters) {
        const searchString = `${str}`.toLowerCase();
        let searchFilterData;

        if (filteredData.length) {
          if (hasTableLinkFilters) {
            searchFilterData = searchStringTableLinkHelper({
              rowsArr: filteredData,
              searchText: searchString,
              userPref,
              isOrganisationMode,
              headerData: parentHeaderData,
            });
            // searchFilterData = filteredData.filter((item) => {
            //   const val = convertCellDataToText(
            //     item?.[parentColId],
            //     {
            //       fieldType: FIELD_TYPE_ID.TABLE,
            //       subType: fieldType,
            //     },
            //     userPref?.country,
            //     userPref,
            //     {},
            //     {},
            //     isOrganisationMode,
            //     {},
            //     false,
            //   );
            //   return val?.toLowerCase()?.includes(searchString);
            // });
            dispatch({
              type: TABLE_LINKS_ACTION.SET_SEARCH_LOADING,
              payload: {isLoading: false},
            });
            dispatch({
              type: TABLE_LINKS_ACTION.SET_SEARCH_RESULT,
              payload: {tableData: searchFilterData},
            });
            return {};
          } else if (isDependency) {
            if (extra?.shouldSearchRowPayload) {
              searchFilterData = searchStringTableLinkHelper({
                rowsArr: filteredData,
                searchText: searchString,
                userPref,
                isOrganisationMode,
                headerData: parentHeaderData,
              });
              dispatch({
                type: TABLE_LINKS_ACTION.SET_SEARCH_RESULT,
                payload: {tableData: searchFilterData},
              });
              dispatch({
                type: TABLE_LINKS_ACTION.SET_SEARCH_LOADING,
                payload: {isLoading: false},
              });
              return {};
            } else if (isAssignTaskColumn) {
              searchFilterData = filteredData.filter((item) => {
                const val = item?.data?.val ?? {};
                const valueInData = !isEmpty(val)
                  ? `${val.assignee?.name} ${val?.assignee?.contact}`.toLowerCase()
                  : '';
                return valueInData.includes(searchString);
              });
            } else {
              searchFilterData = filteredData.filter((item) => {
                const val = item.data?.val.toLowerCase();
                return val.includes(searchString);
              });
            }
          }
        } else {
          dispatch({
            type: TABLE_LINKS_ACTION.SET_SEARCH_RESULT,
            payload: {tableData: []},
          });
          dispatch({
            type: TABLE_LINKS_ACTION.SET_SEARCH_LOADING,
            payload: {isLoading: false},
          });
          return {};
        }

        const tableData = [];
        searchFilterData.forEach((item) => {
          const obj = {
            [tableLinks?.activeChildData?.linkedData?.colId]: item?.data,
            rowId: item?.rowId ?? null,
            rowIndex: item?.rowIndex ?? null,
          };
          tableData.push(obj);
        });
        const objToSend = {
          tableData,
        };

        dispatch({
          type: TABLE_LINKS_ACTION.SET_SEARCH_RESULT,
          payload: objToSend,
        });
        if (isScanBarcode && objToSend.tableData.length === 1) {
          return objToSend.tableData[0];
        }
      } else {
        const obj = {
          textToSearch: str,
          docId: extra?.docId
            ? extra.docId
            : tableLinks?.activeParentDoc?.docId,
          columnId: extra?.colId
            ? extra.colId
            : tableLinks?.activeChildData?.linkedData?.colId,
          columnObj: extra?.columnObj,
          isPaginated: true,
          ...(!isFromSubmitButton &&
          searchAfterRowId &&
          searchAfterIndex &&
          searchAfterIndex !== '' &&
          searchAfterRowId !== ''
            ? {searchAfter}
            : {}),
          headerIdFieldMapping:
            getHeaderFieldMappingForElasticSearch(parentHeaderData),
          isHeaderIndependentSearch: false,
        };
        Object.assign(obj, {apiVersion: '1'});
        const respone = await callCloudFunction(
          isOrganisationMode
            ? CLOUD_FUNCTION_PATHS.TABLE_LINKING_SEARCH_ORG
            : CLOUD_FUNCTION_PATHS.TABLE_LINKING_SEARCH,
          obj,
          null,
          CLOUD_FUNCTION_COMMON_PARAMS.ELASTIC_REQUEST,
        );
        objToSend = {tableData: respone.response?.tableData};
        const mergedTableData = {
          tableData: isFromSubmitButton
            ? [...(respone.response?.tableData || [])]
            : [
                ...cloneDeep(tableLinks?.search?.tableData),
                ...(respone.response?.tableData || []),
              ],
        };

        dispatch({
          type: TABLE_LINKS_ACTION.SET_SEARCH_RESULT,
          payload: mergedTableData,
        });
        dispatch({
          type: TABLE_LINKS_ACTION.SET_SEARCH_LOADING,
          payload: {isLoading: false},
        });
        if (isScanBarcode && objToSend.tableData.length === 1) {
          return objToSend.tableData[0];
        }
      }
      dispatch({
        type: TABLE_LINKS_ACTION.SET_SEARCH_LOADING,
        payload: {isLoading: false},
      });
      return {success: true, ...objToSend};
    } catch (error) {
      console.log(error, 'error');
      captureError(error);
    }
  };

const clearSearch = () => async (dispatch) => {
  dispatch({
    type: TABLE_LINKS_ACTION.CLEAR_SEARCH,
  });
};

const clearColumnDependencyMapping = () => (dispatch) => {
  try {
    dispatch({
      type: TABLE_LINKS_ACTION.CLEAR_COLUMN_DEPENDENCY_MAPPING,
    });
  } catch (error) {
    captureError(error);
  }
};

const updateForceStopDependencyArrUpdate = (val) => (dispatch) => {
  try {
    dispatch({
      type: TABLE_LINKS_ACTION.UPDATE_FORCE_STOP_DEPENDENCY_ARR_UPDATE,
      payload: val,
    });
  } catch (error) {
    captureError(error);
  }
};

export {
  clearSearch,
  searchStringTableLinking,
  onTableLinkSelectColumn,
  updatePreviousParentDocData,
  createTableLink,
  fetchParentDocData,
  deleteLinkedFiles,
  manageLinkedIdsList,
  updateRowIds,
  activateAutofillLinkedFilesListener,
  // updateParentRowMetaCollection,
  activateParentRowMetaListener,
  fetchAndUpdateTableLinkData,
  clearTableLinkState,
  getLinkedDocIds,
  fetchAutofillLinkedFiles,
  updateTableLinkActiveState,
  onTableLinkSelectValue,
  handleTableLinkColumnUpdate,
  getLinkedDocIdsFromHeaderData,
  openChildFile,
  deleteChildLinks,
  deleteColumnTableLinkHandler,
  fetchParentFileData,
  getParentFileUniqueDataWithFilter,
  createAndSetColumnDependencyMapping,
  copyPreviousTableLinkState,
  reApplyPrevState,
  autoFillDataAndUpdateToFirestore,
  clearColumnDependencyMapping,
  updateForceStopDependencyArrUpdate,
  updateColumnDependencyMapping,
  getFilteredParentRowsFromElastic,
  autoFillParentValue,
  searchStringTableLinkHelper,
};
