import {isEmpty} from 'lodash';
import {TABLE_ACTION, TABLE_LINKS_ACTION, VERSION_ACTION} from './actionType';
import {VERSION_HISTORY_LIMITS} from '../utils/constant';
import {captureError, ShowToast, captureInfo} from '../imports';
import {manageTableViewModesFromMeta} from './tableViewActions';
import {AVAILABLE_ENTITLEMENTS} from '../utils/premium';
import {
  versionSnaps,
  restoreVersionFileFromCloud,
} from './actionHelpers/versionActionHelper';
import {
  checkAndUpdateEntryOnlyData,
  processRowsData,
} from './actionHelpers/tableActionHelper';
import FirestoreDB from '../FirestoreHandlers/FirestoreDB';
import DocumentsMethods from '../FirestoreHandlers/Documents/DocumentsMethods';
import {removeAllPendingAutomations} from './automationAction';
import {fetchParentFileData} from './tableLinksActions';
import {updateActiveDocMeta} from './tableAction';
import {getTimezone} from '../utils/utils';

const loadAllVersionFiles = (docId) => async (dispatch, getState) => {
  /**
   * orderBy() + limit() query -> x number of versions received
   * if x<5 : fetch all the versions and then show versions based on user's subscription  (fallback/old method)
   * else directy show all the versions fetched from initial query
   */
  try {
    const {
      premium: {subscriptions},
      version: {versionFiles, lastDoc},
    } = getState();
    const prevVersionFiles = Object.assign({}, versionFiles);
    let prevVersionLength = Object.keys(prevVersionFiles).length;
    const canUserView20Versions = subscriptions.includes(
      AVAILABLE_ENTITLEMENTS.UPTO_20_DOCUMENT_VERSIONS,
    );

    const canUserView100Versions = subscriptions.includes(
      AVAILABLE_ENTITLEMENTS.UPTO_100_DOCUMENT_VERSIONS,
    );

    const canUserView50Versions = subscriptions.includes(
      AVAILABLE_ENTITLEMENTS.ONE_MONTH_VERSION,
    );

    const maxVersionsAllowed = canUserView100Versions
      ? VERSION_HISTORY_LIMITS.MAX_NUMBER_OF_VERSIONS_PRO_100
      : canUserView50Versions
      ? VERSION_HISTORY_LIMITS.MAX_NUMBER_OF_VERSIONS_PREMIUM
      : canUserView20Versions
      ? VERSION_HISTORY_LIMITS.MAX_NUMBER_OF_VERSIONS_PERSONAL_20
      : VERSION_HISTORY_LIMITS.MAX_NUMBER_OF_VERSIONS;

    if (prevVersionLength >= maxVersionsAllowed) {
      return;
    }

    const newVersionFlowSnap = await versionSnaps(docId, lastDoc);

    if (!newVersionFlowSnap?.docs?.length) {
      return;
    }

    newVersionFlowSnap.forEach((doc) => {
      if (prevVersionLength < maxVersionsAllowed) {
        prevVersionFiles[doc.id] = Object.assign({}, doc.data(), {
          id: doc.id,
        });
        prevVersionLength++;
      }
    });

    return dispatch({
      type: VERSION_ACTION.LOAD_VERSION_FILES,
      payload: {
        files: Object.assign({}, prevVersionFiles),
        lastDoc: newVersionFlowSnap.docs[newVersionFlowSnap.docs.length - 1],
      },
    });
  } catch (error) {
    captureError(error);
    return dispatch({
      type: VERSION_ACTION.VERSION_FILES_LOADED,
    });
  }
};

/**
 * for initial fetch pass isInitialFetch=true
 */
const getPaginatedVersionTableData =
  (versionId, isInitialFetch = false) =>
  async (dispatch, getState) => {
    // this is important check. Dont Remove this
    if (!versionId) {
      return;
    }
    try {
      const {
        home: {activeDocumentId},
        version: {
          versionFiles,
          areAllRowsFetched,
          isLoadingMoreRows,
          tableData,
        },
      } = getState();
      if (activeDocumentId && !areAllRowsFetched && !isLoadingMoreRows) {
        dispatch({
          type: VERSION_ACTION.UPDATE_IS_LOADING_MORE_ROWS,
          payload: true,
        });
        const versionData = versionFiles[versionId];
        const versionTableData = tableData;
        const lastTableIndex =
          versionTableData[versionTableData?.length - 1]?.index ?? -99999999999;
        const rowsData = await DocumentsMethods.getRowsData({
          docId: activeDocumentId,
          lastFetchedIndex: isInitialFetch ? null : lastTableIndex,
          isLimited: true,
          limit: VERSION_HISTORY_LIMITS.VERSION_DATA_INITAL_FETCH_LIMIT,
          getVersionData: true,
          versionId: versionId,
        });

        if (rowsData === null) {
          return ShowToast('Fetching rows failed due to network issue.');
        }

        if (
          rowsData.docs.length <
          VERSION_HISTORY_LIMITS.VERSION_DATA_INITAL_FETCH_LIMIT
        ) {
          dispatch({
            type: VERSION_ACTION.UPDATE_ALL_ROWS_FETCHED_STATE,
            payload: true,
          });
        }

        if (rowsData.docs.length) {
          const payload = {
            tableData: processRowsData(rowsData, null, tableData.slice()),
            headerData: Object.values(versionData.headerData),
            footerData: isEmpty(versionData.footerData)
              ? {}
              : versionData.footerData,
            fileObj: Object.assign({}, versionData.fileObj),
          };
          dispatch({
            type: VERSION_ACTION.LOAD_TABLE_VERSION_DATA,
            payload,
          });
        }
      }
    } catch (error) {
      captureError(error);
      dispatch({
        type: VERSION_ACTION.UPDATE_IS_LOADING_MORE_ROWS,
        payload: false,
      });
    }
    dispatch({
      type: VERSION_ACTION.UPDATE_IS_LOADING_MORE_ROWS,
      payload: false,
    });
  };

// TODO: with pagination of rows
const previewVersionFile =
  (versionDocId, isVersionPreview) => async (dispatch, getState) => {
    // const {
    //   home: {activeDocumentId},
    // } = getState();
    try {
      // const {
      //   version: {versionFiles},
      // } = getState();
      // const versionData = versionFiles[versionDocId];

      // dispatch({
      //   type: VERSION_ACTION.LOAD_TABLE_VERSION_DATA,
      //   payload: {
      //     headerData: Object.values(versionData.headerData),
      //     tableData: await DocumentsMethods.getAllRowsData(
      //       activeDocumentId,
      //       null,
      //       false,
      //       true,
      //       versionDocId,
      //     ),
      //     footerData: isEmpty(versionData.footerData)
      //       ? {}
      //       : versionData.footerData,
      //     fileObj: Object.assign({}, versionData.fileObj),
      //   },
      // });
      dispatch({type: VERSION_ACTION.START_TABLE_LOADING});

      await dispatch(getPaginatedVersionTableData(versionDocId, true));

      /**
       * Update Table View Modes
       */
      dispatch(manageTableViewModesFromMeta({}, {isVersionPreview}));

      dispatch({type: VERSION_ACTION.STOP_TABLE_LOADING});
    } catch (error) {
      captureError(error);
    }
  };

const resetPreviewData = () => (dispatch) => {
  dispatch({
    type: VERSION_ACTION.RESET_PREVIEW_DATA,
  });
};

const backupTableData = () => (dispatch, getState) => {
  const {table} = getState();
  dispatch({
    type: VERSION_ACTION.BACKUP_TABLE_DATA,
    payload: Object.assign({}, table),
  });
};

const restoreData = () => (dispatch, getState) => {
  try {
    const {version} = getState();
    dispatch({type: TABLE_ACTION.START_TABLE_LOADING});
    dispatch({
      type: TABLE_ACTION.LOAD_TABLE_BACKUP,
      payload: version.tableBackupData,
    });
    dispatch({type: TABLE_ACTION.STOP_TABLE_LOADING});
  } catch (error) {
    captureError(error);
  }
};

const setBackupRequired = (isBackup) => (dispatch) => {
  dispatch({
    type: VERSION_ACTION.SET_BACKUP,
    payload: isBackup,
  });
};

const clearVersionData = () => (dispatch) => {
  dispatch({
    type: VERSION_ACTION.CLEAR_VERSION_DATA,
  });
};

const deleteVersionFiles = (docId) => {
  try {
    const versionFilesRef = FirestoreDB.documents
      .documentRef(docId)
      .collection('version');
    versionFilesRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // If no version are created it wont come inside this loop
        doc.ref.delete();
      });
    });
  } catch (error) {
    captureError(error);
  }
};

const restoreVersionFile = (versionId) => async (dispatch, getState) => {
  try {
    const {
      auth: {user},
      automation: {pendingAutomations},
      home: {activeDocumentId, originalDocumentId},
      version: {headerData, tableData, fileObj, footerData, versionFiles},
    } = getState();

    const dataObj = {
      docId: activeDocumentId,
      originalDocumentId,
      versionId,
      timezone: getTimezone() || 'Asia/Kolkata',
    };

    const data = await restoreVersionFileFromCloud(dataObj);

    const versionData = versionFiles[versionId];
    const linkedDocIds = versionData.linkedDocIds;

    let message;
    if (data.success) {
      // is it possible to reload toable reducer freshly here for the file, how to do it?
      dispatch(setBackupRequired(false));
      dispatch({
        type: TABLE_ACTION.LOAD_TABLE_VERSION_DATA,
        payload: {
          headerData,
          tableData,
          footerData,
          fileObj,
          noOfRows: versionData.noOfRows,
          areAllRowsFetched:
            versionData.noOfRows >= tableData?.length ? false : true,
        },
      });
      dispatch(resetPreviewData());
      checkAndUpdateEntryOnlyData({
        getState,
        headerData,
        fileObj,
      });
      if (!isEmpty(pendingAutomations)) {
        dispatch(removeAllPendingAutomations(activeDocumentId));
      }
      if (linkedDocIds && linkedDocIds.length > 0) {
        dispatch({
          type: TABLE_LINKS_ACTION.CLEAR_TABLE_LINK_STATE,
        });
        dispatch(updateActiveDocMeta({linkedDocIds}));
        await dispatch(fetchParentFileData({isInitialFetch: true}));
      }
      message = 'File Restored Successfully';
      ShowToast(message, null, false, true);
      return {
        success: true,
      };
    } else {
      message = 'Something went wrong, please try again';
      ShowToast(message, null, false, true);
      if (data?.error) {
        captureInfo({
          message,
          ...{docId: activeDocumentId, versionId, uid: user.uid},
          log: data.log,
        });
        captureError(new Error(data.error), true);
      }
      return {
        success: false,
      };
    }
  } catch (error) {
    captureError(error);
    return {
      success: false,
    };
  }
};

export {
  loadAllVersionFiles,
  previewVersionFile,
  backupTableData,
  setBackupRequired,
  restoreData,
  clearVersionData,
  deleteVersionFiles,
  getPaginatedVersionTableData,
  resetPreviewData,
  restoreVersionFile,
};
