import {VERSION_ACTION} from '../actions/actionType';

const initialState = {
  tableBackupData: {},
  tableData: [],
  headerData: [],
  footerData: {},
  fileObj: {},
  versionFiles: {},
  shouldCurrentTableReload: false,
  isVersionFilesFetched: false,
  tableLinkingVersionData: {},
  areAllRowsFetched: false,
  isLoadingMoreRows: false,
  lastDoc: null,
  isLoading: false,
};

const versionReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERSION_ACTION.UPDATE_TABLE_LINKING_VERSION_DATA:
      return {
        ...state,
        tableLinkingVersionData: action.payload,
      };
    case VERSION_ACTION.VERSION_FILES_LOADED:
      return {
        ...state,
        isVersionFilesFetched: true,
      };
    case VERSION_ACTION.LOAD_VERSION_FILES:
      return {
        ...state,
        versionFiles: action.payload.files || [],
        isVersionFilesFetched: true,
        lastDoc: action.payload.lastDoc,
      };
    case VERSION_ACTION.CLEAR_VERSION_DATA: {
      return {...initialState};
    }
    case VERSION_ACTION.SET_BACKUP: {
      return {
        ...state,
        shouldCurrentTableReload: action.payload,
      };
    }
    case VERSION_ACTION.BACKUP_TABLE_DATA: {
      return {
        ...state,
        tableBackupData: action.payload,
      };
    }
    case VERSION_ACTION.RESET_STATE:
      return {
        ...initialState,
      };
    case VERSION_ACTION.UPDATE_ALL_ROWS_FETCHED_STATE: {
      return {
        ...state,
        areAllRowsFetched: Boolean(action.payload),
      };
    }
    case VERSION_ACTION.UPDATE_IS_LOADING_MORE_ROWS: {
      return {
        ...state,
        isLoadingMoreRows: Boolean(action.payload),
      };
    }
    case VERSION_ACTION.LOAD_TABLE_VERSION_DATA:
      return {
        ...state,
        headerData: action.payload.headerData,
        tableData: action.payload.tableData,
        footerData: action.payload.footerData,
        fileObj: action.payload.fileObj,
      };
    case VERSION_ACTION.RESET_PREVIEW_DATA:
      return {
        ...state,
        tableData: [],
        headerData: [],
        fileObj: {},
        footerData: {},
        isLoadingMoreRows: false,
        areAllRowsFetched: false,
        lastDoc: null,
        shouldCurrentTableReload: false,
      };
    case VERSION_ACTION.START_TABLE_LOADING: {
      return {...state, isLoading: true};
    }
    case VERSION_ACTION.STOP_TABLE_LOADING: {
      return {...state, isLoading: false};
    }
    default:
      return state;
  }
};
export default versionReducer;
