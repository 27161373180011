import {
  functions,
  firestore,
  captureError,
  SharedPreferenceStorage,
} from '../../imports';
import {handleCloudError} from '../../utils/utils';
import {ASYNC_STORAGE_KEY, CLOUD_FUNCTION_PATHS} from '../../utils/constant';
import moment from 'moment';
import {orderBy} from 'lodash';
import FirestoreDB from '../../FirestoreHandlers/FirestoreDB';

const assignTaskCloudFunction = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.ASSIGN_TASK_TO_USER,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const getAllTaskCloudFunction = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.FETCH_TASK_DATA,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch {}
};

const sortVisibleTaskArray = (arr) => {
  if (arr?.length) {
    return orderBy(arr, [(obj) => obj?.createdTimestamp], ['desc']);
  }
  return arr;
};

const getTasksFromLocalStorage = (uid) => {
  return SharedPreferenceStorage()
    .get(ASYNC_STORAGE_KEY.ALL_TASKS_DATA)
    .then((res) => {
      if (typeof res === 'string') {
        const parsedObj = JSON.parse(res);
        if (parsedObj?.uid === uid && parsedObj.tasks) {
          return {
            success: true,
            tasks: parsedObj.tasks,
          };
        }
      }
      return {success: false};
    })
    .catch(() => {
      return {success: false};
    });
};

const modifyTaskActiveState = (docId, taskId, isActive = true) => {
  try {
    //for restricting non-premium user
    if (docId) {
      const incrementBy = isActive ? 1 : -1;
      const updateObjPath = `${'fileObj'}.tasksCount`;
      FirestoreDB.documents.documentRef(docId).update({
        [updateObjPath]: FirestoreDB.FieldValue().increment(incrementBy),
      });
    }

    firestore()
      .collection('tasks')
      .doc(taskId)
      .update({
        isActive: isActive ? true : false,
      });
  } catch (error) {
    captureError(error);
  }
};

const updateTaskDetails = (taskId, updateObj) => {
  try {
    firestore()
      .collection('tasks')
      .doc(taskId)
      .update(
        Object.assign({}, updateObj, {
          modifiedTimestamp: moment().unix(),
          isActive: true,
        }),
      );
  } catch (error) {
    captureError(error);
  }
};

const alterTaskCompletionState = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.CHANGE_TASK_COMPLETION_STATE,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

export {
  alterTaskCompletionState,
  assignTaskCloudFunction,
  getAllTaskCloudFunction,
  modifyTaskActiveState,
  getTasksFromLocalStorage,
  updateTaskDetails,
  sortVisibleTaskArray,
};
