import {ENTRY_STATUS_ACTION} from '../actions/actionType';
import {ENTRY_STATUS_TYPES} from '../utils/constant';
import {omit} from 'lodash';

const initialState = {
  activeAppId: null,
  updatesSinceLastOpen: 0,
  entryStatusObj: {
    pending: [],
    failed: [],
    success: [],
  },
  isWebDrawerOpen: false,
  rowsUnderProcess: {},
};

const entryStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENTRY_STATUS_ACTION.INITIALIZE_STATE: {
      const isAppIdSame = action.payload.appId === state.activeAppId;
      return isAppIdSame
        ? state
        : {
            ...initialState,
            activeAppId: action.payload.appId,
          };
    }

    case ENTRY_STATUS_ACTION.UPDATE_REQUEST_STATUS_OBJECT: {
      const {entryStatusObj} = state;
      let updatesSinceLastOpen = state.updatesSinceLastOpen;
      const {requestId, status} = action.payload; //status => new status of the request
      const findPrevStatusIndex = (arr) =>
        arr.findIndex((item) => item.requestId === requestId);
      const prevPendingIndex = findPrevStatusIndex(entryStatusObj.pending);
      const newEntryStatusObj = {
        ...entryStatusObj,
      };
      if (prevPendingIndex !== -1) {
        //prev status was pending
        const isNewStatusSuccess = status === ENTRY_STATUS_TYPES.SUCCESS;
        const newPendingArr = entryStatusObj.pending.slice();
        newPendingArr.splice(prevPendingIndex, 1);
        Object.assign(
          newEntryStatusObj,
          {pending: newPendingArr},
          isNewStatusSuccess
            ? {success: [action.payload, ...entryStatusObj.success]}
            : {failed: [action.payload, ...entryStatusObj.failed]},
        );

        ++updatesSinceLastOpen;
      } else {
        //prev status was failed
        const prevFailedIndex = findPrevStatusIndex(entryStatusObj.failed);
        const newFailedArr = entryStatusObj.failed.slice();
        prevFailedIndex !== -1 && newFailedArr.splice(prevFailedIndex, 1);
        Object.assign(newEntryStatusObj, {
          failed: newFailedArr,
          pending: [action.payload, ...entryStatusObj.pending],
        });
      }
      return {
        ...state,
        entryStatusObj: newEntryStatusObj,
        updatesSinceLastOpen,
      };
    }

    case ENTRY_STATUS_ACTION.RESET_LAST_OPENED_NOTIFY_COUNT: {
      return {
        ...state,
        updatesSinceLastOpen: 0,
      };
    }

    case ENTRY_STATUS_ACTION.TOGGLE_WEB_ACTIVITY_MENU_OPEN_STATUS: {
      const {valueToSet, currentAppId} = action?.payload ?? {};
      const isAppIdSame = currentAppId === state.activeAppId;
      return isAppIdSame
        ? {
            ...state,
            isWebDrawerOpen: valueToSet ?? !state?.isWebDrawerOpen,
          }
        : state;
    }

    case ENTRY_STATUS_ACTION.UPDATE_REQUEST_STATUS_OBJECT_FOR_KANBAN: {
      const {docId, rowMeta, isUnderProcess} = action?.payload ?? {};
      const {rowsUnderProcess} = state ?? {};
      const {rowId} = rowMeta;
      return isUnderProcess
        ? {
            ...state,
            rowsUnderProcess: {
              ...rowsUnderProcess,
              [docId]: {
                ...rowsUnderProcess?.[docId],
                [rowId]: rowMeta,
              },
            },
          }
        : {
            ...state,
            rowsUnderProcess: {
              ...rowsUnderProcess,
              [docId]: {
                ...Object.assign(
                  {},
                  omit(rowsUnderProcess?.[docId], [[rowId]]),
                ),
              },
            },
          };
    }

    default: {
      return state;
    }
  }
};

export default entryStatusReducer;
