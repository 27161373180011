import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {Provider} from 'react-redux';
import configureStore from './store/configureStore';
import {initializeApp} from 'rb-redux/imports';
import {PersistGate} from 'redux-persist/integration/react';
import {LicenseManager} from 'ag-grid-enterprise';

// import reportWebVitals from "./reportWebVitals";
if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
} else {
  require('./ReactotronConfig');
}

LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_LICENSE);
var script = document.createElement('script');
script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places&callback=Function.prototype`;
document.getElementsByTagName('head')[0].appendChild(script);
initializeApp();
const rootElement = document.getElementById('root');

export const {store, persistor} = configureStore();

//export const db = firebase.firestore();
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App persistor={persistor} />
    </PersistGate>
  </Provider>,
  rootElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
