import {captureError, captureInfo} from '../imports';

const firestoreListener = (docRef, onResult, onErrorHandler) => {
  try {
    const onError = (error) => {
      const collectionPath = docRef?._collectionPath;
      captureInfo({
        collectionPath,
        error: 'Error in firestore listerner',
      });
      captureError(error);
      typeof onErrorHandler === 'function' && onErrorHandler(error);
    };
    return docRef.onSnapshot(onResult, onError);
  } catch (error) {
    captureError(error);
    return () => {};
  }
};

export default firestoreListener;
