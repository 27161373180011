import {TABLE_ACTION} from '../actions/actionType';
import {performUndoAction} from '../actions/actionHelpers/undoRedoActions/undoActions';
import {performRedoAction} from '../actions/actionHelpers/undoRedoActions/redoActions';
import {copyData} from '../actions/actionHelpers/undoRedoActions/undoRedoHelper';
import {checkAndUpdateUndoRedoDashboards} from '../actions/actionHelpers/dashboardActionHelper';
import {cloneDeep, isArray, isNil, isNumber, intersection} from 'lodash';
import {captureError} from '../imports';
import {INITIAL_FETCH_COMPLETED} from '../utils/constant';

const initialState = {
  headerData: [],
  tableData: [],
  originalHeaderData: [],
  footerData: {},
  fileObj: {},
  noOfRows: null,
  isLoading: false,
  undo: [],
  redo: [],
  emptyRowIndexes: [],
  helperFunctionRefs: {},
  frozenColumnIds: [],
  splitByCalculation: {},
  initialFetchCompleted: {
    [INITIAL_FETCH_COMPLETED.TABLE_DATA]: false,
    [INITIAL_FETCH_COMPLETED.SHARED_META]: false,
    [INITIAL_FETCH_COMPLETED.VIEW_ENTRY_DATA]: false,
  },
  participantVersionArray: [],
  isPremiumSetAutomationBannerShown: false,
  uniqueColumnData: {},
  areAllRowsFetched: false,
  isLoadingMoreRows: false,
  headerMappedValues: {},
  informationObj: {},
};

const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case TABLE_ACTION.UPDATE_ALL_ROWS_FETCHED_STATE: {
      return {
        ...state,
        areAllRowsFetched: Boolean(action.payload),
      };
    }
    case TABLE_ACTION.UPDATE_IS_LOADING_MORE_ROWS: {
      return {
        ...state,
        isLoadingMoreRows: Boolean(action.payload),
      };
    }
    case TABLE_ACTION.TABLE_DATA_UPDATE_PAGE_CHANGE: {
      return {
        ...state,
        headerData: action.payload.headerData,
        tableData: action.payload.tableData,
        footerData: action.payload.footerData,
        fileObj: Object.assign({}, action.payload.fileObj),
        isLoading: false,
        undo: [],
        redo: [],
        emptyRowIndexes: [],
        noOfRows: action.payload.tableData.length,
        frozenColumnIds: [],
        splitByCalculation: {},
        uniqueColumnData: {},
        areAllRowsFetched: true,
        isLoadingMoreRows: false,
      };
    }
    case TABLE_ACTION.LOAD_TABLE_VERSION_DATA:
      return {
        ...state,
        headerData: action.payload.headerData,
        tableData: action.payload.tableData,
        footerData: action.payload.footerData,
        fileObj: action.payload.fileObj,
        noOfRows: action.payload.noOfRows,
        areAllRowsFetched: action.payload.areAllRowsFetched,
      };
    case TABLE_ACTION.UNDO: {
      try {
        const undo = state.undo.slice();
        if (undo.length === 0) {
          return {...state};
        }
        const [currUndoObj] = undo.splice(-1, 1); //remove last element
        const newState = performUndoAction(
          currUndoObj,
          action.payload.docId,
          state,
          action.payload.isShared,
          action.payload.activeDocumentMeta,
          action.payload.latestReduxState,
        ); //perform Undo Action
        if (state.frozenColumnIds.length && newState.headerData) {
          const updatedHeader = newState.headerData;
          const isFrozenColumnAvailable = intersection(
            updatedHeader,
            state.frozenColumnIds,
          ).length;
          if (!isFrozenColumnAvailable) {
            newState.frozenColumnIds = [];
          }
        }
        const isPrevSplitByCalculationAvailable = !isNil(
          currUndoObj && currUndoObj.prevSplitByCalculation,
        );
        newState.undo = undo.slice();
        newState.redo = copyData(
          state.redo,
          Object.assign(
            {},
            currUndoObj,
            isPrevSplitByCalculationAvailable
              ? {
                  prevSplitByCalculation: cloneDeep(
                    Object.assign({}, state.splitByCalculation),
                  ),
                }
              : {},
          ),
        ); //push undoObj to redo
        checkAndUpdateUndoRedoDashboards(
          state.footerData,
          newState.footerData,
          state.splitByCalculation,
          currUndoObj.prevSplitByCalculation,
          action.payload.docId,
          action.payload.activeDocumentMeta,
          newState.headerData ?? state.headerData,
        );
        return {
          ...state,
          ...newState,
        };
      } catch (err) {
        captureError(err);
        return state;
      }
    }
    case TABLE_ACTION.REDO: {
      try {
        const redo = state.redo.slice();
        if (redo.length === 0) {
          return {...state};
        }
        const [currRedoObj] = redo.splice(-1, 1); //remove last element
        const newState = performRedoAction(
          currRedoObj,
          action.payload.docId,
          state,
          action.payload.isShared,
          action.payload.activeDocumentMeta,
          action.payload.latestReduxState,
        );
        if (state.frozenColumnIds.length && newState.headerData) {
          const updatedHeader = newState.headerData;
          const isFrozenColumnAvailable = intersection(
            updatedHeader,
            state.frozenColumnIds,
          ).length;
          if (!isFrozenColumnAvailable) {
            newState.frozenColumnIds = [];
          }
        }
        const isPrevSplitByCalculationAvailable = !isNil(
          currRedoObj && currRedoObj.prevSplitByCalculation,
        );
        newState.redo = redo.slice();
        newState.undo = copyData(
          state.undo,
          Object.assign(
            {},
            currRedoObj,
            isPrevSplitByCalculationAvailable
              ? {
                  prevSplitByCalculation: cloneDeep(
                    Object.assign({}, state.splitByCalculation),
                  ),
                }
              : {},
          ),
        ); //push redoObj to undo
        checkAndUpdateUndoRedoDashboards(
          state.footerData,
          newState.footerData,
          state.splitByCalculation,
          currRedoObj.prevSplitByCalculation,
          action.payload.docId,
          action.payload.activeDocumentMeta,
          newState.headerData ?? state.headerData,
        );
        return {
          ...state,
          ...newState,
        };
      } catch (err) {
        captureError(err);
        return state;
      }
    }
    case TABLE_ACTION.LOAD_TABLE_DATA: {
      const extra = {};
      if ('areAllRowsFetched' in action.payload) {
        extra.areAllRowsFetched = action.payload.areAllRowsFetched;
      }
      return {
        ...state,
        headerData: action.payload.headerData,
        tableData: action.payload.tableData,
        footerData: Object.assign({}, action.payload.footerData),
        pdfHeaderData: Object.assign({}, action.payload.pdfHeaderData),
        pdfFooterData: Object.assign({}, action.payload.pdfFooterData),
        originalHeaderData:
          action.payload.originalHeaderData ?? action.payload.headerData,
        isLoading: false,
        undo: [],
        redo: [],
        data: action.payload.data,
        emptyRowIndexes: isArray(action.payload?.emptyRowIndexes)
          ? action.payload.emptyRowIndexes.slice()
          : [],
        fileObj: Object.assign({}, action.payload.fileObj),
        isPremiumSetAutomationBannerShown: false,
        noOfRows: isNumber(action.payload.noOfRows)
          ? action.payload.noOfRows
          : action.payload.tableData?.length,
        ...extra,
      };
    }
    case TABLE_ACTION.LOAD_ENTRY_ONLY_DATA: {
      return {
        ...state,
        headerData: action.payload.headerData,
        originalHeaderData:
          action.payload.originalHeaderData ?? action.payload.headerData,
        tableData: action.payload?.tableData ?? [],
        isLoading: false,
        fileObj: Object.assign({}, action.payload.fileObj),
      };
    }
    case TABLE_ACTION.ADD_EMPTY_ROW: {
      const emptyRowIndexes = isArray(action.payload.emptyRowIndexes)
        ? action.payload.emptyRowIndexes.slice()
        : state.emptyRowIndexes.slice();
      const undoUpdated = copyData(state.undo, {
        ACTION_TYPE: TABLE_ACTION.ADD_EMPTY_ROW,
        prevEmptyRowIndexes: state.emptyRowIndexes.slice(),
        newEmptyRowIndexes: emptyRowIndexes.slice(),
        newRows: action.payload.newRows.slice(),
      });
      return {
        ...state,
        tableData: [...state.tableData, ...action.payload.newRows],
        undo: undoUpdated,
        redo: [],
        emptyRowIndexes,
      };
    }
    case TABLE_ACTION.ADD_COLUMN_AT_POS: {
      const updatedFileObj = Object.assign(
        {},
        state.fileObj,
        action.payload.updatedFileObj,
      );

      const undoUpdated = copyData(state.undo, {
        ACTION_TYPE: TABLE_ACTION.ADD_COLUMN_AT_POS,
        index: action.payload.insertIndex,
        headerDataObj: action.payload.newHeaderObj,
        prevFileObj: Object.assign({}, state.fileObj),
        newFileObj: updatedFileObj,
        extraColumnsToAdd: action.payload.extraColumnsToAdd,
      });
      return {
        ...state,
        headerData: action.payload.headerData,
        undo: undoUpdated,
        redo: [],
        fileObj: updatedFileObj,
      };
    }
    case TABLE_ACTION.EDIT_ROW: {
      const {isQuickEntryIndexGreater, extra} = action.payload;
      const updatedTableData = state.tableData.slice();
      const extraStates = {};
      const updatedFileObj = extra.tasks?.length
        ? Object.assign({}, state.fileObj, {
            tasksCount: state.fileObj?.tasksCount
              ? state.fileObj.tasksCount + 1
              : 1,
          })
        : Object.assign({}, state.fileObj);
      if (!action.payload.doNotPushToUndoStack) {
        extraStates.undo = copyData(state.undo, {
          ACTION_TYPE: TABLE_ACTION.EDIT_ROW,
          index: action.payload.index,
          prevRowDataObj: action.payload?.prevRowDataObj
            ? action.payload?.prevRowDataObj
            : updatedTableData[action.payload.index],
          newRowDataObj: action.payload.updatedRowData,
          prevFooterData: action.payload.prevFooterData,
          newFooterData: action.payload.updatedFooterData,
          extraRedoActions: action.payload.extraRedoActions,
          extraUndoActions: action.payload.extraUndoActions,
          extra: action.payload.extra,
          prevSplitByCalculation: Object.assign(
            {},
            action.payload.prevSplitByCalculation,
          ),
          prevFileObj: Object.assign({}, state.fileObj),
          newFileObj: updatedFileObj,
        });
        extraStates.redo = [];
      }
      if (!isQuickEntryIndexGreater) {
        updatedTableData.splice(
          action.payload.index,
          1,
          action.payload.updatedRowData,
        );
      }
      return {
        ...state,
        tableData: updatedTableData,
        footerData: action.payload.updatedFooterData,
        fileObj: updatedFileObj,
        ...extraStates,
      };
    }
    case TABLE_ACTION.SORT_COL: {
      const newTableData = action.payload.updatedTableData.slice();
      const undoUpdated = copyData(state.undo, {
        ACTION_TYPE: TABLE_ACTION.SORT_COL,
        prevTableData: state.tableData.slice(),
        newTableData,
      });
      return {
        ...state,
        tableData: newTableData,
        undo: undoUpdated,
        redo: [],
      };
    }
    case TABLE_ACTION.DELETE_COL_OR_FORMULA: {
      const undoUpdated = copyData(state.undo, {
        ACTION_TYPE: TABLE_ACTION.DELETE_COL_OR_FORMULA,
        prevData: {
          //for undo
          headerData: state.headerData.slice(),
          tableData: state.tableData.slice(),
          footerData: cloneDeep(state.footerData),
          fileObj: cloneDeep(state.fileObj),
          uniqueColumnData: cloneDeep(state.uniqueColumnData),
        },
        newData: {
          //curr-data for redo
          tableData: action.payload.updatedTableData,
          headerData: action.payload.updatedHeaderData,
          footerData: action.payload.updateFooterData,
          fileObj: Object.assign({}, action.payload.fileObj),
          uniqueColumnData: Object.assign({}, action.payload.uniqueColumnData),
        },
        extraUndoActions: action.payload?.extraUndoActions,
        extraRedoActions: action.payload?.extraRedoActions,
        extra: action.payload.extra,
        prevSplitByCalculation: Object.assign(
          {},
          action.payload.prevSplitByCalculation,
        ),
        undoDashboardUpdateObj: Object.assign(
          {},
          action.payload.undoDashboardUpdateObj,
        ),
        redoDashboardUpdateObj: Object.assign(
          {},
          action.payload.redoDashboardUpdateObj,
        ),
        dashboardColumnId: action.payload.dashboardColumnId,
        undoColumnIdForUnique: action.payload.undoColumnIdForUnique,
        rowsFirestoreUpdateIndexArr: action.payload.rowsFirestoreUpdateIndexArr,
      });
      return {
        ...state,
        tableData: action.payload.updatedTableData,
        headerData: action.payload.updatedHeaderData,
        footerData: action.payload.updateFooterData,
        undo: undoUpdated,
        redo: [],
        fileObj: Object.assign({}, action.payload.fileObj),
      };
    }
    case TABLE_ACTION.COPY_PASTE_DATA: {
      //copy-paste-action
      const updatedUniqueColumnData = Object.assign(
        {},
        action.payload.updatedUniqueColumnData,
      );
      const undoUpdated = copyData(state.undo, {
        ACTION_TYPE: TABLE_ACTION.COPY_PASTE_DATA,
        prevData: {
          //for undo
          headerData: state.headerData.slice(),
          tableData: state.tableData.slice(),
          footerData: cloneDeep(state.footerData),
          fileObj: cloneDeep(state.fileObj),
          uniqueColumnData: cloneDeep(state.uniqueColumnData),
        },
        newData: {
          //curr-data for redo
          tableData: action.payload.updatedTableData,
          headerData: action.payload.updatedHeaderData,
          footerData: action.payload.updateFooterData,
          fileObj: action.payload.updatedFileObj,
          uniqueColumnData: updatedUniqueColumnData,
        },
        extra: action.payload.extra,
        prevSplitByCalculation: Object.assign(
          {},
          action.payload.prevSplitByCalculation,
        ),
        newRowIndexArr: action.payload.newRowIndexArr,
        changedRowsIndexArr: action.payload.changedRowsIndexArr,
      });
      const emptyRowIndexes = isArray(action.payload.extra?.emptyRowIndexes)
        ? action.payload.extra.emptyRowIndexes.slice()
        : state.emptyRowIndexes.slice();
      return {
        ...state,
        tableData: action.payload.updatedTableData,
        headerData: action.payload.updatedHeaderData,
        footerData: action.payload.updateFooterData,
        fileObj: action.payload.updatedFileObj,
        undo: undoUpdated,
        redo: [],
        emptyRowIndexes,
      };
    }
    case TABLE_ACTION.RESTORED_TABLE_DATA: {
      //restoring-from-version-file
      return {
        ...state,
        tableData: action.payload.updatedTableData,
        headerData: action.payload.updatedHeaderData,
        footerData: action.payload.updatedFooterData,
        undo: [],
        redo: [],
        fileObj: Object.assign({}, action.payload.fileObj),
      };
    }
    case TABLE_ACTION.EDIT_COL: {
      //editing properties of a column
      const updatedFileObj = Object.assign(
        {},
        state.fileObj,
        action.payload.updatedFileObj,
      );
      const updatedHeaderData = action.payload.headerDataAfterEdit;
      const undoUpdated = copyData(state.undo, {
        ACTION_TYPE: TABLE_ACTION.EDIT_COL,
        index: action.payload.index,
        prevColumnDataObj: action.payload?.undoPrevColObj
          ? action.payload.undoPrevColObj
          : state.headerData[action.payload.index],
        newColumnDataObj: action.payload?.undoNewColObj
          ? action.payload.undoNewColObj
          : action.payload.newColObj,
        extra: action.payload.extra,
        extraUndoActions: action.payload?.extraUndoActions,
        extraRedoActions: action.payload?.extraRedoActions,
        prevFileObj: Object.assign({}, state.fileObj),
        newFileObj: updatedFileObj,
        undoDashboardUpdateObj: action.payload.undoDashboardUpdateObj,
        redoDashboardUpdateObj: action.payload.redoDashboardUpdateObj,
        dashboardColumnId: action.payload.dashboardColumnId,
        prevHeaderData: state.headerData.slice(),
        newHeaderData: updatedHeaderData,
      });
      return {
        ...state,
        headerData: updatedHeaderData,
        fileObj: updatedFileObj,
        undo: undoUpdated,
        redo: [],
      };
    }
    case TABLE_ACTION.EDIT_FORMULA_HEADER: {
      const undoUpdated = copyData(state.undo, {
        ACTION_TYPE: TABLE_ACTION.EDIT_FORMULA_HEADER,
        prevData: {
          //for undo
          headerData: state.headerData.slice(),
          tableData: state.tableData.slice(),
          footerData: action.payload.prevFooterData,
        },
        newData: {
          //curr-data for redo
          headerData: action.payload.updatedHeader,
          tableData: action.payload.updatedTable,
          footerData: action.payload.newFooterData,
        },
        prevSplitByCalculation: Object.assign(
          {},
          action.payload.prevSplitByCalculation,
        ),
        undoDashboardUpdateObj: action.payload.undoDashboardUpdateObj,
        redoDashboardUpdateObj: action.payload.redoDashboardUpdateObj,
        dashboardColumnId: action.payload.dashboardColumnId,
        rowsFirestoreUpdateIndexArr: action.payload.rowsFirestoreUpdateIndexArr,
      });
      return {
        ...state,
        headerData: action.payload.updatedHeader,
        tableData: action.payload.updatedTable,
        footerData: action.payload.newFooterData,
        undo: undoUpdated,
        redo: [],
      };
    }
    case TABLE_ACTION.ADD_PDF_HEADER: {
      return {...state, pdfHeaderData: action.payload};
    }
    case TABLE_ACTION.ADD_PDF_FOOTER: {
      return {...state, pdfFooterData: action.payload};
    }
    case TABLE_ACTION.SHOW_TOTAL: {
      return {
        ...state,
        footerData: action.payload,
      };
    }
    case TABLE_ACTION.SET_SPLIT_BY_CALCULATIONS: {
      return {
        ...state,
        splitByCalculation: Object.assign({}, action.payload),
      };
    }
    case TABLE_ACTION.DELETE_ROW: {
      const emptyRowIndexArray = isArray(action.payload?.updatedEmptyRowArr)
        ? action.payload.updatedEmptyRowArr.slice()
        : state.emptyRowIndexes.slice();
      const undoUpdated = copyData(state.undo, {
        ACTION_TYPE: TABLE_ACTION.DELETE_ROW,
        prevTableData: action.payload.prevTableData,
        newTableData: action.payload.tableData,
        prevFooterData: action.payload.prevFooterData,
        newFooterData: action.payload.updatedFooterData,
        prevEmptyRowIndexes: state.emptyRowIndexes.slice(),
        currEmptyRowIndexes: emptyRowIndexArray,
        extraRedoActions: action.payload.extraRedoActions,
        extraUndoActions: action.payload.extraUndoActions,
        prevSplitByCalculation: Object.assign(
          {},
          action.payload.prevSplitByCalculation,
        ),
        deletedTaskIds: action.payload.deletedTaskIds,
        deletedRowIdsArr: action.payload.deletedRowIdsArr,
        prevFileObj: Object.assign({}, state.fileObj),
        newFileObj: action.payload.updatedFileObj,
      });
      return {
        ...state,
        tableData: action.payload.tableData,
        footerData: action.payload.updatedFooterData,
        fileObj: action.payload.updatedFileObj,
        undo: undoUpdated,
        redo: [],
        emptyRowIndexes: emptyRowIndexArray,
      };
    }
    case TABLE_ACTION.DELETE_ROW_COL_DATA: {
      const undoUpdated = copyData(state.undo, {
        ACTION_TYPE: TABLE_ACTION.DELETE_ROW_COL_DATA,
        modifiedRowIdArr: action.payload.modifiedRowIdArr,
        modifiedColIdArr: action.payload.modifiedColIdArr,
        prevTableData: action.payload.prevTableData,
        newTableData: action.payload.tableData,
        prevFooterData: action.payload.prevFooterData,
        newFooterData: action.payload.updatedFooterData,
        extraRedoActions: action.payload.extraRedoActions,
        extraUndoActions: action.payload.extraUndoActions,
        prevSplitByCalculation: Object.assign(
          {},
          action.payload.prevSplitByCalculation,
        ),
        deletedTaskIds: action.payload.deletedTaskIds,
        rowsFirestoreUpdateObjArr: action.payload.rowsFirestoreUpdateObjArr,
        rowsFirestoreUpdateUndoObjArr:
          action.payload.rowsFirestoreUpdateUndoObjArr,
        prevFileObj: Object.assign({}, state.fileObj),
        newFileObj: action.payload.updatedFileObj,
      });
      return {
        ...state,
        tableData: action.payload.tableData,
        footerData: action.payload.updatedFooterData,
        fileObj: action.payload.updatedFileObj,
        undo: undoUpdated,
        redo: [],
      };
    }
    case TABLE_ACTION.START_TABLE_LOADING: {
      return {
        ...state,
        isLoading: true,
        headerData: [],
        tableData: [],
        originalHeaderData: [],
        footerData: {},
        fileObj: {},
        noOfRows: null,
        undo: [],
        redo: [],
        emptyRowIndexes: [],
        frozenColumnIds: [],
        splitByCalculation: {},
        uniqueColumnData: {},
        lastFetchedTimestampMergedObj: {},
        areAllRowsFetched: false,
        isLoadingMoreRows: false,
        headerMappedValues: {},
      };
    }
    case TABLE_ACTION.STOP_TABLE_LOADING: {
      return {...state, isLoading: false};
    }
    case TABLE_ACTION.CLEAR_LAST:
    case TABLE_ACTION.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    case TABLE_ACTION.ADD_ROW_IN_BETWEEN: {
      //add row in b/w
      const emptyRowIndexArray = isArray(action.payload.emptyRowIndexes)
        ? action.payload.emptyRowIndexes.slice()
        : state.emptyRowIndexes.slice();
      const undoUpdated = copyData(state.undo, {
        ACTION_TYPE: TABLE_ACTION.ADD_ROW_IN_BETWEEN,
        index: action.payload.index,
        prevEmptyRowIndexes: state.emptyRowIndexes.slice(),
        currEmptyRowIndexes: emptyRowIndexArray,
        newTableData: action.payload.tableData.slice(),
      });
      return {
        ...state,
        tableData: action.payload.tableData,
        undo: undoUpdated,
        redo: [],
        emptyRowIndexes: emptyRowIndexArray,
      };
    }
    case TABLE_ACTION.ADD_CLIENT_INFO_FOR_PDF: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case TABLE_ACTION.UPDATE_COLLABORATIVE_TABLE_DATA: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    }
    case TABLE_ACTION.LOAD_TABLE_BACKUP:
      return {
        ...state,
        ...action.payload,
      };
    case TABLE_ACTION.UPDATE_HELPER_FUNCTION_REFS:
      return {
        ...state,
        helperFunctionRefs: Object.assign(
          {},
          state.helperFunctionRefs,
          action.payload,
        ),
      };
    case TABLE_ACTION.UPDATE_TABLE_STATES: {
      const updatedStates = action.payload ?? {};
      return {...state, ...updatedStates};
    }
    case TABLE_ACTION.UPDATE_SMS_OBJ: {
      return {
        ...state,
        headerData: action.payload,
      };
    }
    case TABLE_ACTION.MOVE_ROW: {
      const undoUpdated = copyData(state.undo, {
        ACTION_TYPE: TABLE_ACTION.MOVE_ROW,
        prevRowObj: action.payload.prevRowObj,
        newRowObj: action.payload.newRowObj,
        removeFrom: action.payload.removeFrom,
        insertAt: action.payload.insertAt,
      });
      return {
        ...state,
        tableData: action.payload.tableData,
        undo: undoUpdated,
        redo: [],
      };
    }
    case TABLE_ACTION.MOVE_COLUMN: {
      const undoUpdated = copyData(state.undo, {
        ACTION_TYPE: TABLE_ACTION.MOVE_COLUMN,
        prevHeaderData: action.payload.currentHeader,
        newHeaderData: action.payload.updatedHeaderData,
      });
      return {
        ...state,
        headerData: action.payload.updatedHeaderData,
        undo: undoUpdated,
        redo: [],
      };
    }
    case TABLE_ACTION.SET_FROZEN_COLUMN_IDS: {
      return {
        ...state,
        frozenColumnIds: action.payload,
      };
    }
    case TABLE_ACTION.UNFREEZE_COLUMNS: {
      return {
        ...state,
        frozenColumnIds: [],
      };
    }
    case TABLE_ACTION.UPDATE_INITIAL_DATA_FETCH_OBJ: {
      return {
        ...state,
        initialFetchCompleted: Object.assign(
          {},
          state.initialFetchCompleted,
          action.payload,
        ),
      };
    }
    case TABLE_ACTION.SET_PARTICIPANT_LIST: {
      return {
        ...state,
        participantVersionArray: action.payload,
      };
    }
    case TABLE_ACTION.CLEAR_PARTICIPANT_LIST: {
      return {
        ...state,
        participantVersionArray: [],
      };
    }
    case TABLE_ACTION.SET_SHOW_AUTOMATION_BANNER: {
      return {
        ...state,
        isPremiumSetAutomationBannerShown: true,
      };
    }
    case TABLE_ACTION.ADD_UNIQUE_VALUE: {
      return {
        ...state,
        uniqueColumnData: action.payload,
      };
    }
    case TABLE_ACTION.SET_HEADER_MAPPED_VALUES: {
      return {
        ...state,
        headerMappedValues: action.payload,
      };
    }
    case TABLE_ACTION.SHOW_INFORMAITON_MODAL: {
      return {
        ...state,
        informationObj: action.payload,
      };
    }
    case TABLE_ACTION.APPEND_DATA_IN_BULK: {
      return {
        ...state,
        headerData: action.payload.headerData,
        tableData: action.payload.tableData,
        footerData: action.payload.footerData,
      };
    }
    default:
      return state;
  }
};
export default tableReducer;
