import {firestore} from '../../imports';

/**---------------- Document Refs ----------------*/
export default class MiniApps {
  appCollection(firestoreInstance) {
    return (firestoreInstance ?? firestore)().collection('miniApps');
  }

  notifyUserCollection(firestoreInstance) {
    return (firestoreInstance ?? firestore)().collection('notifyUser');
  }

  app(appId, firestoreInstance) {
    return this.appCollection(firestoreInstance).doc(appId);
  }

  customRoles(appId, firestoreInstance) {
    return this.app(appId, firestoreInstance).collection('customRoles');
  }

  customRoleDoc(appId, roleId, firestoreInstance) {
    return this.customRoles(appId, firestoreInstance).doc(roleId);
  }

  userExportExcelColRef(appId, uid) {
    return this.app(appId)
      .collection('exports')
      .doc(uid)
      .collection('screenXLS');
  }

  userExportExcelDocRef(appId, uid, docId) {
    return this.userExportExcelColRef(appId, uid).doc(docId);
  }

  userImportExcelColRef(appId) {
    return this.app(appId).collection('imports');
  }

  importTemplatesDocRef(appId, screenId, firestoreInstance) {
    return this.app(appId, firestoreInstance)
      .collection('importTemplates')
      .where('screenId', '==', screenId);
  }

  exportTemplatesDocRef(appId, screenId, firestoreInstance) {
    return this.app(appId, firestoreInstance)
      .collection('exportTemplates')
      .where('screenId', '==', screenId);
  }

  appEndpoints(appId) {
    return this.app(appId).collection('endpoints');
  }
}
