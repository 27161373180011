import {keyBy} from 'lodash';

import {
  FIELD_TYPE_TO_OTHER_FIELD_TYPE_MAP,
  SETVALUE_COLUMN_VALUE_TYPES,
} from './constant';
import {getColumnFieldType} from './utils';
import {SET_VALUE_CONDITIONS} from './automationConstants';
import {automationUtils} from './automationUtils';

/**
 *
 * @param {*} headerData - Header Data
 * @param {*} colId - Selected Col Id on `WHEN` condition
 * @returns
 */
const getFilteredHeaderDataForValueField = (headerData, colId) => {
  const headerDataAsObj = keyBy(headerData, 'id');

  return headerData.filter(
    (colObj) =>
      colObj.id !== colId &&
      FIELD_TYPE_TO_OTHER_FIELD_TYPE_MAP[
        getColumnFieldType(headerDataAsObj?.[colId])
      ]?.includes(getColumnFieldType(colObj)),
  );
};

const getValueToSetForConditions = (
  item,
  columnObjectForWhen,
  columnObjectForValue,
) => {
  if (!item?.val) {
    return {};
  }

  const getTitle = () =>
    automationUtils.getTitleForValueMapping(
      {},
      {[columnObjectForWhen?.id]: columnObjectForWhen},
      {
        headerMapping: {},
        defaultValMap: {},
        defaultValConstantMapOptions: {},
        defaultValConstantMap: {[columnObjectForWhen?.id]: item?.val},
      },
      columnObjectForWhen?.id,
      {},
      {},
    );

  return (item?.valType !== SETVALUE_COLUMN_VALUE_TYPES.COLUMN &&
    item?.valType !== SETVALUE_COLUMN_VALUE_TYPES.DEFAULT_CONST_VALUE) ||
    item?.valType === SETVALUE_COLUMN_VALUE_TYPES.CUSTOM_VALUE
    ? {
        [SET_VALUE_CONDITIONS.VALUE]: {
          text: 'Custom Value',
          type: SETVALUE_COLUMN_VALUE_TYPES.CUSTOM_VALUE,
          value: {val: item?.val},
        },
      }
    : item?.valType === SETVALUE_COLUMN_VALUE_TYPES.DEFAULT_CONST_VALUE
    ? {
        [SET_VALUE_CONDITIONS.VALUE]: {
          text: getTitle(),
          type: SETVALUE_COLUMN_VALUE_TYPES.DEFAULT_CONST_VALUE,
          value: item?.val,
        },
      }
    : item?.valType === SETVALUE_COLUMN_VALUE_TYPES.COLUMN
    ? {
        [SET_VALUE_CONDITIONS.VALUE]: {
          text: columnObjectForValue?.val,
          type: SETVALUE_COLUMN_VALUE_TYPES.COLUMN,
          value: {
            id: columnObjectForValue?.id,
            fieldType: columnObjectForValue?.fieldType,
            val: columnObjectForValue?.val,
            subType: columnObjectForValue?.subType,
            columnProperties: columnObjectForValue?.columnProperties,
          },
        },
      }
    : {};
};

const convertConditionsToIdValStructure = (conditionObj) => {
  return {
    colId: conditionObj?.[SET_VALUE_CONDITIONS.WHEN]?.value.id,

    type: conditionObj?.[SET_VALUE_CONDITIONS.CONDITION]?.value,

    valType: conditionObj?.[SET_VALUE_CONDITIONS.VALUE]?.type,

    val:
      conditionObj?.[SET_VALUE_CONDITIONS.VALUE]?.type ===
      SETVALUE_COLUMN_VALUE_TYPES.DEFAULT_CONST_VALUE
        ? conditionObj?.[SET_VALUE_CONDITIONS.VALUE]?.value
        : conditionObj?.[SET_VALUE_CONDITIONS.VALUE]?.type ===
          SETVALUE_COLUMN_VALUE_TYPES.COLUMN
        ? conditionObj?.[SET_VALUE_CONDITIONS.VALUE]?.value?.id
        : typeof conditionObj?.[SET_VALUE_CONDITIONS.VALUE]?.value?.val !==
          'string'
        ? conditionObj?.[SET_VALUE_CONDITIONS.VALUE]?.value?.val
        : conditionObj?.[SET_VALUE_CONDITIONS.VALUE]?.value?.val?.trim(),
  };
};

export {
  getFilteredHeaderDataForValueField,
  getValueToSetForConditions,
  convertConditionsToIdValStructure,
};
