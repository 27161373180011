import {isEmpty} from 'lodash';
import Features from './Features';

export const FIELD_TYPE_ID = {
  TEXT: 'TEXT',
  COMMENT: 'COMMENT',
  NUMBER: 'NUMBER',
  RUPEE: 'RUPEE',
  OPERATION: 'OPERATION',
  NUMBER_CONSTANT: 'NUMBER_CONSTANT',
  FORMULA: 'FORMULA',
  DATE: 'DATE',
  SWITCH: 'SWITCH',
  TIME: 'TIME',
  CHECKBOX: 'CHECKBOX',
  SELECT_POP_UP: 'SELECT_POP_UP',
  IMAGE: 'IMAGE',
  CONTACT: 'CONTACT',
  DOCUMENT: 'DOCUMENT', // pdf, docx ,excel ,text
  REMINDER: 'REMINDER',
  UNIT: 'UNIT',
  MAPS: 'MAPS',
  AUDIO: 'AUDIO',
  LABEL: 'LABEL',
  BARCODE: 'BARCODE',
  PERCENT: 'PERCENT',
  TABLE: 'TABLE',
  ASSIGN_TASK: 'ASSIGN_TASK',
  URL: 'URL',
  CREATED_INFO: 'CREATED_INFO',
  EMAIL: 'EMAIL',
  VIDEO: 'VIDEO',
  USER_COLUMN: 'USER_COLUMN',
  PDF: 'PDF',
  AUTO_INCREMENT_ID: 'AUTO_INCREMENT_ID',
  LIST: 'LIST',
  DATE_TIME: 'DATE_TIME',
  NO_OF_DAYS: 'NO_OF_DAYS',
  STRING_FORMULA: 'STRING_FORMULA',
  OTP: 'OTP',
}; //update-on-cloud-function-too

export const STRING_FORMULA_CONSTANT = {
  NUMBER_CONSTANT: 'NUMBER_CONSTANT',
  OPERATION: 'OPERATION',
};
export const STRING_FUNCTIONS = {
  CONCAT: 'concat',
  SPLIT: 'split',
};

export const ALLOWED_STRING_FUNCTIONS = [
  STRING_FUNCTIONS.CONCAT,
  STRING_FUNCTIONS.SPLIT,
];

export const DATE_FORMATS = {
  DATE: 'DATE',
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  YEAR: 'YEAR',
};

export const FIELD_TYPE_DETAIL = {
  TEXT: {
    text: 'Text',
    iconName: 'text',
    id: FIELD_TYPE_ID.TEXT,
    color: '#ffd6a5',
    egText: 'Enter Value',
    altEgText: 'Text Value',
    iconColor: '#337EED',
  },
  NUMBER: {
    text: 'Number',
    iconName: 'numeric',
    id: FIELD_TYPE_ID.NUMBER,
    color: '#a0c4ff',
    egText: '1000',
    altEgText: '10',
    iconColor: '#791BEE',
  },
  RUPEE: {
    text: 'Currency',
    iconName: 'currency-usd',
    id: FIELD_TYPE_ID.RUPEE,
    color: '#caffbf',
    egText: '₹ 500',
    altEgText: ' 100',
    iconColor: '#F1A42F',
  },
  FORMULA: {
    text: 'Formula',
    iconName: 'function-variant',
    id: FIELD_TYPE_ID.FORMULA,
    color: '#bdb2ff',
    egText: 'Column A + Column B',
    altEgText: '100',
    iconColor: '#337EED',
  },
  STRING_FORMULA: {
    text: 'String Formula',
    iconName: 'function-variant',
    id: FIELD_TYPE_ID.STRING_FORMULA,
    color: '#bdb2ff',
    egText: 'SPLIT(Sample_Name,_,1) = Sample',
    altEgText: 'Evaluated Text',
    iconColor: '#337EED',
  },
  DATE: {
    text: 'Date',
    iconName: 'calendar',
    id: FIELD_TYPE_ID.DATE,
    color: '#ffc6ff',
    egText: '01/01/2024',
    altEgText: '01/01/2024',
    textOptions: {
      [DATE_FORMATS.DATE]: '01/01/2024',
      [DATE_FORMATS.MONTH]: 'January 2024',
      [DATE_FORMATS.QUARTER]: 'Jan-Mar 2024',
      [DATE_FORMATS.YEAR]: '2024',
    },
    iconColor: '#08C9E5',
  },
  SWITCH: {
    text: 'Yes/No',
    iconName: 'toggle-switch-outline',
    id: FIELD_TYPE_ID.SWITCH,
    color: '#9bf6ff',
    egText: 'Yes/No',
    altEgText: 'Yes',
    iconColor: '#449F35',
  },
  TIME: {
    text: 'Time',
    iconName: 'clock-time-five-outline',
    id: FIELD_TYPE_ID.TIME,
    color: '#caffbf',
    egText: '2:00 PM',
    altEgText: '12:00 AM',
    iconColor: '#E02A2A',
  },
  CHECKBOX: {
    text: 'Checkbox',
    iconName: 'checkbox-marked-outline',
    id: FIELD_TYPE_ID.CHECKBOX,
    color: '#fdffb6',
    egText: 'Yes/No',
    altEgText: 'No',
    iconColor: '#337EED',
  },
  IMAGE: {
    text: 'Image',
    iconName: 'image-outline',
    id: FIELD_TYPE_ID.IMAGE,
    color: '#ffadad',
    egText: 'Image',
    altEgText: 'Image',
    iconColor: '#337EED',
  },
  SELECT_POP_UP: {
    text: 'Select Box',
    iconName: 'form-dropdown',
    id: FIELD_TYPE_ID.SELECT_POP_UP,
    color: '#ffba08',
    egText: 'Select',
    altEgText: 'Cash In',
    iconColor: '#449F35',
  },
  CONTACT: {
    text: 'Mobile Number',
    iconName: 'phone-outline',
    id: FIELD_TYPE_ID.CONTACT,
    color: '#a0c4ff',
    egText: '9987642310',
    altEgText: '9876543210',
    iconColor: '#337EED',
  },
  DOCUMENT: {
    text: 'Attachment',
    iconName: 'paperclip',
    id: FIELD_TYPE_ID.DOCUMENT,
    color: '#bef7c6',
    egText: 'Upload Document',
    altEgText: '1 Document',
    iconColor: '#F1A42F',
  },
  REMINDER: {
    text: 'Reminder',
    iconName: 'bell-plus-outline',
    id: FIELD_TYPE_ID.REMINDER,
    color: '#ffd6a5',
    egText: '8:00 PM 01/10/2023',
    actionText: 'Set Reminder',
    altEgText: '8:00 PM 01/10/2023',
    iconColor: '#449F35',
  },
  UNIT: {
    text: 'Unit',
    iconName: 'ruler',
    id: FIELD_TYPE_ID.UNIT,
    color: '#ffedad',
    egText: '5 Kg',
    altEgText: '10 Kg',
    iconColor: '#556A89',
  },
  MAPS: {
    text: 'Location',
    iconName: 'map-marker',
    id: FIELD_TYPE_ID.MAPS,
    color: '#ffc6ff',
    egText: 'Pune, India',
    actionText: 'Choose Location',
    altEgText: 'Mumbai, India',
    iconColor: '#E02A2A',
  },
  AUDIO: {
    text: 'Audio',
    iconName: 'headphones',
    id: FIELD_TYPE_ID.AUDIO,
    color: '#ffd6a5',
    egText: 'Select Audio',
    actionText: 'Select Audio',
    altEgText: '1 Audio',
    iconColor: '#449F35',
  },
  LABEL: {
    text: 'Label',
    iconName: 'label',
    id: FIELD_TYPE_ID.LABEL,
    color: '#9bf6ff',
    egText: 'Select',
    actionText: 'Choose Label',
    altEgText: 'Online, Offline',
    iconColor: '#08C9E5',
  },
  BARCODE: {
    text: 'Barcode/QR',
    iconName: 'barcode',
    id: FIELD_TYPE_ID.BARCODE,
    color: '#caffbf',
    egText: 'Barcode/QR',
    actionText: 'Barcode/QR',
    altEgText: 'A1231FDE54DSFDF',
    iconColor: '#791BEE',
  },
  TABLE: {
    text: 'Table',
    iconName: 'table',
    id: FIELD_TYPE_ID.TABLE,
    color: '#ffedad',
    isPremium: true,
    egText: 'Table Link',
    actionText: 'Choose Value',
    altEgText: 'Linked Value',
    iconColor: '#791BEE',
  },
  ASSIGN_TASK: {
    text: 'Assign Task',
    iconName: 'account-arrow-left',
    id: FIELD_TYPE_ID.ASSIGN_TASK,
    color: '#fed9a5',
    isPremium: true,
    egText: 'Assign a Task',
    actionText: 'Choose Assignee',
    altEgText: 'Task Assigned to Sam',
    iconColor: '#F1A42F',
  },
  URL: {
    text: 'URL',
    iconName: 'link-variant',
    id: FIELD_TYPE_ID.URL,
    color: '#ffedad',
    egText: 'https://lio.io',
    actionText: 'Add URL',
    altEgText: 'https://lio.io',
    iconColor: '#F1A42F',
  },
  CREATED_INFO: {
    text: 'Data Added By',
    iconName: 'text-account',
    id: FIELD_TYPE_ID.CREATED_INFO,
    color: '#FED9A5',
    egText: 'Data Added By',
    altEgText: 'Data Added By',
    iconColor: '#08C9E5',
  },
  COMMENT: {
    text: 'Comments',
    iconName: 'comment-outline',
    id: FIELD_TYPE_ID.COMMENT,
    color: '#ffedad',
    egText: 'Comment Text',
    actionText: 'Add Comment',
    altEgText: 'Sample Comment',
    iconColor: '#E02A2A',
  },
  EMAIL: {
    text: 'Email',
    iconName: 'email',
    id: FIELD_TYPE_ID.EMAIL,
    color: '#ffedad',
    egText: 'Email Id',
    altEgText: 'Email Id',
    iconColor: '#791BEE',
  },
  VIDEO: {
    text: 'Video',
    iconName: 'video-outline',
    id: FIELD_TYPE_ID.VIDEO,
    color: '#ffadad',
    egText: '',
    iconColor: '#791BEE',
  },
  USER_COLUMN: {
    text: 'User',
    iconName: 'account-multiple-outline',
    id: FIELD_TYPE_ID.USER_COLUMN,
    color: '#ffadad',
    egText: '',
    iconColor: '#791BEE',
  },
  AUTO_INCREMENT_ID: {
    text: 'Auto Increment ID',
    iconName: 'numeric-positive-1',
    id: FIELD_TYPE_ID.AUTO_INCREMENT_ID,
    color: '#F2E8FD',
    egText: '',
    iconColor: '#791BEE',
  },
  LIST: {
    text: 'List',
    iconName: 'format-list-bulleted',
    id: FIELD_TYPE_ID.LIST,
    color: '#F1A42F',
    egText: '',
    altEgText: '',
    iconColor: '#791BEE',
  },
  DATE_TIME: {
    text: 'Date Time',
    iconName: 'calendar-clock',
    id: FIELD_TYPE_ID.DATE_TIME,
    color: '#F1A42F',
    egText: '01/01/2023 06:48 am',
    altEgText: '',
    iconColor: '#E02A2A',
  },
  PDF: {
    text: 'PDF',
    iconName: 'file-pdf-box',
    id: FIELD_TYPE_ID.PDF,
    color: '#337EED',
    egText: 'pdf',
    altEgText: '',
    iconColor: '#337EED',
  },
  NO_OF_DAYS: {
    text: 'Number Of Days',
    iconName: 'calendar-expand-horizontal',
    id: FIELD_TYPE_ID.NO_OF_DAYS,
    color: '#F1A42F',
    egText: '88',
    altEgText: 'No of Days',
    iconColor: '#F1A42F',
  },
  OTP: {
    text: 'OTP Verification',
    iconName: 'cellphone',
    id: FIELD_TYPE_ID.OTP,
    color: '#262626',
    egText: 'OTP',
    altEgText: 'OTP',
    iconColor: '#262626',
  },
};

export const FIELD_TYPE_GROUPS = [
  {
    name: 'Form Fields',
    fields: [
      FIELD_TYPE_ID.TEXT,
      FIELD_TYPE_ID.NUMBER,
      FIELD_TYPE_ID.DATE,
      FIELD_TYPE_ID.TIME,
      FIELD_TYPE_ID.RUPEE,
      FIELD_TYPE_ID.UNIT,
      FIELD_TYPE_ID.SELECT_POP_UP,
      FIELD_TYPE_ID.LABEL,
      FIELD_TYPE_ID.URL,
      FIELD_TYPE_ID.BARCODE,
      FIELD_TYPE_ID.DATE_TIME,
    ],
  },
  {
    name: 'Switches',
    fields: [FIELD_TYPE_ID.SWITCH, FIELD_TYPE_ID.CHECKBOX],
  },
  {
    name: 'Contact Fields',
    fields: [
      FIELD_TYPE_ID.CONTACT,
      FIELD_TYPE_ID.EMAIL,
      FIELD_TYPE_ID.MAPS,
      FIELD_TYPE_ID.OTP,
    ],
  },
  {
    name: 'Media Fields',
    fields: [
      FIELD_TYPE_ID.DOCUMENT,
      FIELD_TYPE_ID.IMAGE,
      FIELD_TYPE_ID.AUDIO,
      FIELD_TYPE_ID.VIDEO,
    ],
  },
  {
    name: 'Advanced',
    fields: [
      FIELD_TYPE_ID.FORMULA,
      FIELD_TYPE_ID.TABLE,
      FIELD_TYPE_ID.LIST,
      FIELD_TYPE_ID.ASSIGN_TASK,
      FIELD_TYPE_ID.REMINDER,
      FIELD_TYPE_ID.COMMENT,
      FIELD_TYPE_ID.CREATED_INFO,
      FIELD_TYPE_ID.AUTO_INCREMENT_ID,
      FIELD_TYPE_ID.PDF,
      FIELD_TYPE_ID.NO_OF_DAYS,
      FIELD_TYPE_ID.STRING_FORMULA,
    ],
  },
];

export const DEPENDENT_ALLOWED_COLUMNS = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.UNIT,
  FIELD_TYPE_ID.EMAIL,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.AUTO_INCREMENT_ID,
];

// !!
// !! update keys in RB-CLOUD-FUNCTIONS when updating this
// !!
export const COLUMN_PROPERTY_KEYS = {
  AUTO_FILL_ON_ENTRY: 'AUTO_FILL_ON_ENTRY',
  AUTO_FILL_ON_CLICK: 'AUTO_FILL_ON_CLICK',
  UNIQUE_VALUES: 'UNIQUE_VALUES',
  AUTO_INCREMENT: 'AUTO_INCREMENT',
  ONLY_LIVE_LOCATION: 'ONLY_LIVE_LOCATION',
  ONLY_IMAGES_FROM_CAMERA: 'ONLY_IMAGES_FROM_CAMERA',
  GEO_TAGGING: 'GEO_TAGGING',
  CURRENCY: 'CURRENCY',
  MANDATORY: 'MANDATORY',
  POSITIVE_VALUE_GREEN: 'POSITIVE_VALUE_GREEN',
  NEGATIVE_VALUE_RED: 'NEGATIVE_VALUE_RED',
  UNIT: FIELD_TYPE_ID.UNIT,
  ONLY_VIDEO_FROM_CAMERA: 'ONLY_VIDEO_FROM_CAMERA',
  IS_DEPENDENT_COLUMN: 'IS_DEPENDENT_COLUMN',
  MULTILINE_TEXT_INPUT: 'MULTILINE_TEXT_INPUT',
  START_VALUE: 'START_VALUE',
  INCREMENT_VALUE: 'INCREMENT_VALUE',
  RESET_VALUE: 'RESET_VALUE',
  ADD_PREFIX_TO_ID: 'ADD_PREFIX_TO_ID',
  DATA_LIST: 'DATA_LIST',
  PRIMARY_COLUMN: 'PRIMARY_COLUMN',
  PREVIEW_MAPPING: 'PREVIEW_MAPPING',
  NON_EDITABLE_HEADER: 'NON_EDITABLE_HEADER',
  NON_EDITABLE_HEADER_FIELD_TYPE: 'NON_EDITABLE_HEADER_FIELD_TYPE',
  NON_EDITABLE_DATA: 'NON_EDITABLE_DATA',
  RECURRING: 'RECURRING',
  CAPTURE_TIME: 'CAPTURE_TIME',
  AUTO_CAPTURE_ON_ENTRY: 'AUTO_CAPTURE_ON_ENTRY',
  NON_DELETE_HEADER: 'NON_DELETE_HEADER',
  PDF_SETTING: 'PDF_SETTING',
  GENERATE_MANUALLY: 'GENERATE_MANUALLY',
  DEFAULT_VALUE: 'DEFAULT_VALUE',
  DISPLAY_IN_PERCENTAGE: 'DISPLAY_IN_PERCENTAGE',
  SHOW_CHECKBOX: 'SHOW_CHECKBOX',
  SHOW_RADIO: 'SHOW_RADIO',
  OVERWRITE_TABLE_LINK_VALUE: 'OVERWRITE_TABLE_LINK_VALUE',
  OVERWRITE_VALUE: 'OVERWRITE_VALUE',
  BACKGROUND_FIELD: 'BACKGROUND_FIELD',
};

// !!
// !! update keys in RB-CLOUD-FUNCTIONS when updating this
// !!
export const COLUMN_PROPERTIES = {
  MULTILINE_TEXT_INPUT: {
    propertyName: COLUMN_PROPERTY_KEYS.MULTILINE_TEXT_INPUT,
    propertyLabel: 'Multiline Input',
    isConditional: true,
  },
  AUTO_FILL_ON_ENTRY: {
    propertyName: COLUMN_PROPERTY_KEYS.AUTO_FILL_ON_ENTRY,
    propertyLabel: 'Auto-Fill on Entry',
  },
  AUTO_FILL_ON_CLICK: {
    propertyName: COLUMN_PROPERTY_KEYS.AUTO_FILL_ON_CLICK,
    propertyLabel: 'Auto-Fill on Click',
    propertyLabelMobile: 'Auto-Fill on Tap',
  },
  IS_DEPENDENT_COLUMN: {
    propertyName: COLUMN_PROPERTY_KEYS.IS_DEPENDENT_COLUMN,
    propertyLabel: 'Is dependent on previous column value',
  },
  UNIQUE_VALUES: {
    propertyName: COLUMN_PROPERTY_KEYS.UNIQUE_VALUES,
    propertyLabel: 'Unique Values',
  },
  AUTO_INCREMENT: {
    propertyName: COLUMN_PROPERTY_KEYS.AUTO_INCREMENT,
    propertyLabel: 'Auto-Increment',
  },
  ONLY_LIVE_LOCATION: {
    propertyName: COLUMN_PROPERTY_KEYS.ONLY_LIVE_LOCATION,
    propertyLabel: 'Only Live Location',
  },
  ONLY_IMAGES_FROM_CAMERA: {
    propertyName: COLUMN_PROPERTY_KEYS.ONLY_IMAGES_FROM_CAMERA,
    propertyLabel: 'Only Images from Camera',
  },
  GEO_TAGGING: {
    propertyName: COLUMN_PROPERTY_KEYS.GEO_TAGGING,
    propertyLabel: 'Geo Tagging',
  },
  CURRENCY: {
    propertyName: COLUMN_PROPERTY_KEYS.CURRENCY,
    propertyLabel: 'Currency',
    isSelectableProperty: true,
  },
  UNIT: {
    propertyName: COLUMN_PROPERTY_KEYS.UNIT,
    propertyLabel: 'Unit',
    isSelectableProperty: true,
  },
  MANDATORY: {
    propertyName: COLUMN_PROPERTY_KEYS.MANDATORY,
    propertyLabel: 'Mandatory',
  },
  POSITIVE_VALUE_GREEN: {
    propertyName: COLUMN_PROPERTY_KEYS.POSITIVE_VALUE_GREEN,
    propertyLabel: 'Show positive values in green',
  },
  NEGATIVE_VALUE_RED: {
    propertyName: COLUMN_PROPERTY_KEYS.NEGATIVE_VALUE_RED,
    propertyLabel: 'Show negative values in red',
  },
  ONLY_VIDEO_FROM_CAMERA: {
    propertyName: COLUMN_PROPERTY_KEYS.ONLY_VIDEO_FROM_CAMERA,
    propertyLabel: 'Only Video from Camera',
  },
  START_VALUE: {
    propertyName: COLUMN_PROPERTY_KEYS.START_VALUE,
    propertyLabel: 'Start Value',
    isTextValue: true,
    isSelectableProperty: true,
  },
  INCREMENT_VALUE: {
    propertyName: COLUMN_PROPERTY_KEYS.INCREMENT_VALUE,
    propertyLabel: 'Increment Value',
    isTextValue: true,
    isSelectableProperty: true,
  },
  RESET_VALUE: {
    propertyName: COLUMN_PROPERTY_KEYS.RESET_VALUE,
    propertyLabel: 'Reset Value',
    placeholder: 'Reset Value',
    keyboardType: 'number-pad',
    isEditOnly: true,
    isSwitchTextValue: true,
    isSelectableProperty: true,
  },
  ADD_PREFIX_TO_ID: {
    propertyName: COLUMN_PROPERTY_KEYS.ADD_PREFIX_TO_ID,
    propertyLabel: 'Add Prefix to ID',
    placeholder: 'Prefix',
    isSwitchTextValue: true,
    isSelectableProperty: true,
  },
  DATA_LIST: {
    propertyName: COLUMN_PROPERTY_KEYS.DATA_LIST,
    propertyLabel: 'Data List',
    isSelectableProperty: true,
  },
  PRIMARY_COLUMN: {
    propertyName: COLUMN_PROPERTY_KEYS.PRIMARY_COLUMN,
    propertyLabel: 'Primary Column',
    isSelectableProperty: true,
  },
  PREVIEW_MAPPING: {
    propertyName: COLUMN_PROPERTY_KEYS.PREVIEW_MAPPING,
    propertyLabel: 'Preview Mapping',
    isSelectableProperty: true,
  },
  NON_EDITABLE_HEADER: {
    propertyName: COLUMN_PROPERTY_KEYS.NON_EDITABLE_HEADER,
    propertyLabel: 'View Only Column Header',
  },
  NON_EDITABLE_DATA: {
    propertyName: COLUMN_PROPERTY_KEYS.NON_EDITABLE_DATA,
    propertyLabel: 'View Only Column Data',
  },
  RECURRING: {
    propertyName: COLUMN_PROPERTY_KEYS.RECURRING,
    propertyLabel: 'Recurring',
  },
  CAPTURE_TIME: {
    propertyName: COLUMN_PROPERTY_KEYS.CAPTURE_TIME,
    propertyLabel: 'Capture time',
  },
  AUTO_CAPTURE_ON_ENTRY: {
    propertyName: COLUMN_PROPERTY_KEYS.AUTO_CAPTURE_ON_ENTRY,
    propertyLabel: 'Auto Capture on Entry',
    isConditional: true,
  },
  PDF_SETTING: {
    propertyName: COLUMN_PROPERTY_KEYS.PDF_SETTING,
    propertyLabel: 'PDF settings',
    isSelectableProperty: true,
  },
  GENERATE_MANUALLY: {
    propertyName: COLUMN_PROPERTY_KEYS.GENERATE_MANUALLY,
    propertyLabel: 'Generate manually',
    isSwitchTextValue: true,
  },
  DEFAULT_VALUE: {
    propertyName: COLUMN_PROPERTY_KEYS.DEFAULT_VALUE,
    propertyLabel: 'Default Value',
  },
  DISPLAY_IN_PERCENTAGE: {
    propertyName: COLUMN_PROPERTY_KEYS.DISPLAY_IN_PERCENTAGE,
    propertyLabel: 'Display in Percentage',
  },
  SHOW_CHECKBOX: {
    propertyName: COLUMN_PROPERTY_KEYS.SHOW_CHECKBOX,
    propertyLabel: 'Show CheckBox',
  },
  SHOW_RADIO: {
    propertyName: COLUMN_PROPERTY_KEYS.SHOW_RADIO,
    propertyLabel: 'Show Radio',
  },
  OVERWRITE_TABLE_LINK_VALUE: {
    propertyName: COLUMN_PROPERTY_KEYS.OVERWRITE_TABLE_LINK_VALUE,
    propertyLabel: 'Allow overwrite',
  },
  OVERWRITE_VALUE: {
    propertyName: COLUMN_PROPERTY_KEYS.OVERWRITE_VALUE,
    propertyLabel: 'Allow overwrite',
  },
  BACKGROUND_FIELD: {
    propertyName: COLUMN_PROPERTY_KEYS.BACKGROUND_FIELD,
    propertyLabel: 'Background Field',
  },
};

// !!
// !! update keys in RB-CLOUD-FUNCTIONS when updating this
// !!
export const COLUMN_PROPERTY_DETAILS = {
  [FIELD_TYPE_ID.TEXT]: [
    COLUMN_PROPERTIES.MULTILINE_TEXT_INPUT,
    COLUMN_PROPERTIES.UNIQUE_VALUES,
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
    COLUMN_PROPERTIES.DEFAULT_VALUE,
  ], //handled
  [FIELD_TYPE_ID.IMAGE]: [
    COLUMN_PROPERTIES.ONLY_IMAGES_FROM_CAMERA,
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.GEO_TAGGING,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
  ],
  [FIELD_TYPE_ID.DATE]: [
    COLUMN_PROPERTIES.AUTO_FILL_ON_ENTRY,
    COLUMN_PROPERTIES.AUTO_FILL_ON_CLICK,
    COLUMN_PROPERTIES.UNIQUE_VALUES, //handled
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
  ],
  [FIELD_TYPE_ID.TIME]: [
    COLUMN_PROPERTIES.AUTO_FILL_ON_ENTRY,
    COLUMN_PROPERTIES.AUTO_FILL_ON_CLICK,
    COLUMN_PROPERTIES.UNIQUE_VALUES, //unhandled
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
  ],
  [FIELD_TYPE_ID.NUMBER]: [
    COLUMN_PROPERTIES.DISPLAY_IN_PERCENTAGE,
    COLUMN_PROPERTIES.UNIQUE_VALUES,
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.POSITIVE_VALUE_GREEN,
    COLUMN_PROPERTIES.NEGATIVE_VALUE_RED,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
    COLUMN_PROPERTIES.DEFAULT_VALUE,
  ], //handled
  [FIELD_TYPE_ID.MAPS]: [
    COLUMN_PROPERTIES.ONLY_LIVE_LOCATION,
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
  ],
  [FIELD_TYPE_ID.RUPEE]: [
    COLUMN_PROPERTIES.CURRENCY,
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.POSITIVE_VALUE_GREEN,
    COLUMN_PROPERTIES.NEGATIVE_VALUE_RED,
    COLUMN_PROPERTIES.UNIQUE_VALUES,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
    COLUMN_PROPERTIES.DEFAULT_VALUE,
  ],
  [FIELD_TYPE_ID.UNIT]: [
    COLUMN_PROPERTIES.UNIT,
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
    COLUMN_PROPERTIES.DEFAULT_VALUE,
  ],
  [FIELD_TYPE_ID.CONTACT]: [
    COLUMN_PROPERTIES.UNIQUE_VALUES,
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
    COLUMN_PROPERTIES.DEFAULT_VALUE,
  ], //handled
  [FIELD_TYPE_ID.URL]: [
    //COLUMN_PROPERTIES.UNIQUE_VALUES,    In case of Undo/Redo => The unique data getting toggled was on nested obj.
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
  ], //handled
  [FIELD_TYPE_ID.SWITCH]: [
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
    COLUMN_PROPERTIES.DEFAULT_VALUE,
  ],
  [FIELD_TYPE_ID.CHECKBOX]: [
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
    COLUMN_PROPERTIES.DEFAULT_VALUE,
  ],
  [FIELD_TYPE_ID.SELECT_POP_UP]: [
    COLUMN_PROPERTIES.SHOW_RADIO,
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.UNIQUE_VALUES,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
    COLUMN_PROPERTIES.DEFAULT_VALUE,
  ],
  [FIELD_TYPE_ID.DOCUMENT]: [
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
  ],
  [FIELD_TYPE_ID.REMINDER]: [
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
  ],
  [FIELD_TYPE_ID.AUDIO]: [
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
  ],
  [FIELD_TYPE_ID.LABEL]: [
    COLUMN_PROPERTIES.SHOW_CHECKBOX,
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
    COLUMN_PROPERTIES.DEFAULT_VALUE,
  ],
  [FIELD_TYPE_ID.BARCODE]: [
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.UNIQUE_VALUES,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
  ],
  [FIELD_TYPE_ID.TABLE]: [
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.IS_DEPENDENT_COLUMN,
    COLUMN_PROPERTIES.AUTO_FILL_ON_ENTRY,
    COLUMN_PROPERTIES.OVERWRITE_TABLE_LINK_VALUE,
    COLUMN_PROPERTIES.DEFAULT_VALUE,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
  ],
  [FIELD_TYPE_ID.ASSIGN_TASK]: [
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.AUTO_CAPTURE_ON_ENTRY,
    COLUMN_PROPERTIES.UNIQUE_VALUES,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
    COLUMN_PROPERTIES.DEFAULT_VALUE,
  ],
  [FIELD_TYPE_ID.FORMULA]: [
    COLUMN_PROPERTIES.DISPLAY_IN_PERCENTAGE,
    COLUMN_PROPERTIES.OVERWRITE_VALUE,
    COLUMN_PROPERTIES.POSITIVE_VALUE_GREEN,
    COLUMN_PROPERTIES.NEGATIVE_VALUE_RED,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
  ],
  [FIELD_TYPE_ID.STRING_FORMULA]: [COLUMN_PROPERTIES.BACKGROUND_FIELD],
  [FIELD_TYPE_ID.EMAIL]: [
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.UNIQUE_VALUES,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
    COLUMN_PROPERTIES.DEFAULT_VALUE,
  ],
  [FIELD_TYPE_ID.VIDEO]: [
    COLUMN_PROPERTIES.ONLY_VIDEO_FROM_CAMERA,
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
  ],
  [FIELD_TYPE_ID.AUTO_INCREMENT_ID]: [
    COLUMN_PROPERTIES.START_VALUE,
    COLUMN_PROPERTIES.INCREMENT_VALUE,
    COLUMN_PROPERTIES.RESET_VALUE,
    COLUMN_PROPERTIES.ADD_PREFIX_TO_ID,
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.GENERATE_MANUALLY,
  ],
  [FIELD_TYPE_ID.LIST]: [
    COLUMN_PROPERTIES.DATA_LIST,
    COLUMN_PROPERTIES.PREVIEW_MAPPING,
    COLUMN_PROPERTIES.PRIMARY_COLUMN,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
  ],
  [FIELD_TYPE_ID.DATE_TIME]: [
    COLUMN_PROPERTIES.MANDATORY,
    COLUMN_PROPERTIES.RECURRING,
    // COLUMN_PROPERTIES.CAPTURE_TIME,
    COLUMN_PROPERTIES.UNIQUE_VALUES,
    COLUMN_PROPERTIES.AUTO_FILL_ON_ENTRY,
    COLUMN_PROPERTIES.AUTO_FILL_ON_CLICK,
    COLUMN_PROPERTIES.BACKGROUND_FIELD,
  ],
  [FIELD_TYPE_ID.PDF]: [COLUMN_PROPERTIES.PDF_SETTING],
  [FIELD_TYPE_ID.NO_OF_DAYS]: [COLUMN_PROPERTIES.BACKGROUND_FIELD],
};

export const CAN_VIEW_ACCESS_ALLOWED_FIELDTYPES = [
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.MAPS,
  FIELD_TYPE_ID.COMMENT,
  //TODO: to check for VIDEO column
];

export const FORMULA_RESTRICTED_FIELDS = [
  FIELD_TYPE_ID.CONTACT,
  FIELD_TYPE_ID.SWITCH,
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.CHECKBOX,
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.MAPS,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.LABEL,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.URL,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.EMAIL,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.LIST,
  FIELD_TYPE_ID.DATE_TIME,
  FIELD_TYPE_ID.PDF,
  FIELD_TYPE_ID.OTP,
];

export const STRING_FORMULA_RESTRICTED_FIELDS = [
  FIELD_TYPE_ID.CONTACT,
  FIELD_TYPE_ID.SWITCH,
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.CHECKBOX,
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.MAPS,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.LABEL,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.URL,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.EMAIL,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.LIST,
  FIELD_TYPE_ID.DATE_TIME,
  FIELD_TYPE_ID.PDF,
  FIELD_TYPE_ID.OTP,
];

export const AUTO_MESSAGE_ALLOWED_FIELDS = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.TIME,
  FIELD_TYPE_ID.CONTACT,
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.SELECT_POP_UP,
  FIELD_TYPE_ID.UNIT,
  FIELD_TYPE_ID.MAPS,
  FIELD_TYPE_ID.LABEL,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.URL,
  FIELD_TYPE_ID.AUTO_INCREMENT_ID,
  FIELD_TYPE_ID.NO_OF_DAYS,
  FIELD_TYPE_ID.OTP,
];

export const PURE_NUMBER_FIELDS = [
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.FORMULA, // Formula `without` date/time/date_time in equation.
  FIELD_TYPE_ID.UNIT,
  FIELD_TYPE_ID.NO_OF_DAYS,
]; //numeric fields

export const NUMBER_SORT_FIELD = [
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.TIME,
  FIELD_TYPE_ID.UNIT,
  FIELD_TYPE_ID.NO_OF_DAYS,
]; //update-on-cloud-function-too

export const QUICK_ENTRY_TEXT_EDIT_FIELD = [
  //editing on Quick Entry screen
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.UNIT,
  FIELD_TYPE_ID.TABLE,
  FIELD_TYPE_ID.CONTACT,
];

export const FORMATTING_NOT_ALLOWED = [
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.MAPS,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.NO_OF_DAYS,
];

export const COPY_NOT_ALLOWED = [
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.SWITCH,
  FIELD_TYPE_ID.CHECKBOX,
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.TIME,
  FIELD_TYPE_ID.SELECT_POP_UP,
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.MAPS,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.LABEL,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.LIST,
  FIELD_TYPE_ID.DATE_TIME,
  FIELD_TYPE_ID.PDF,
  FIELD_TYPE_ID.NO_OF_DAYS,
  FIELD_TYPE_ID.OTP,
];

export const PASTE_NOT_ALLOWED = [
  FIELD_TYPE_ID.SWITCH,
  FIELD_TYPE_ID.CHECKBOX,
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.TIME,
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.STRING_FORMULA,
  FIELD_TYPE_ID.SELECT_POP_UP,
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.MAPS,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.LABEL,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.LIST,
  FIELD_TYPE_ID.DATE_TIME,
  FIELD_TYPE_ID.PDF,
  FIELD_TYPE_ID.NO_OF_DAYS,
  FIELD_TYPE_ID.OTP,
];

export const TOTAL_NOT_ALLOWED = [
  FIELD_TYPE_ID.SWITCH,
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.CHECKBOX,
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.TIME,
  FIELD_TYPE_ID.CONTACT,
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.MAPS,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.LABEL,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.URL,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.EMAIL,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.LIST,
  FIELD_TYPE_ID.DATE_TIME,
  FIELD_TYPE_ID.PDF,
  FIELD_TYPE_ID.SELECT_POP_UP,
  FIELD_TYPE_ID.OTP,
]; //update-on-cloud-function-too

export const CONFIGURE_ALLOWED_FIELDTYPES = [
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.SELECT_POP_UP,
  FIELD_TYPE_ID.LABEL,
  FIELD_TYPE_ID.CONTACT,
  FIELD_TYPE_ID.UNIT,
];

export const TOTAL_SUMMARY_NOT_ALLOWED = [
  //formula columnObj.subType only
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.TIME,
];

export const SORT_NOT_ALLOWED = [
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.MAPS,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.LABEL,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.LIST,
  FIELD_TYPE_ID.PDF,
];

export const STRING_FORMATTING_NOT_ALLOWED_FIELDS = [
  //fields which are not plain strings (example : all attachments)
  //exceptions : [MAPS : contains string location, LABELS : can be converted from array to comma seperated string values]
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.USER_COLUMN,
];

export const SUM_FIELD = [
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.UNIT,
  FIELD_TYPE_ID.NO_OF_DAYS,
];

export const TEXT_SORT_FIELD = [FIELD_TYPE_ID.TEXT];

export const PREDEFINED_UNITS = {
  KILOGRAM: 'KILOGRAM',
  GRAM: 'GRAM',
  KILOMETER: 'KILOMETER',
  METER: 'METER',
  CENTIMETER: 'CENTIMETER',
  MILLIMETER: 'MILLIMETER',
  FEET: 'FEET',
  INCH: 'INCH',
  PERCENT: 'PERCENT',
};

export const PREDEFINED_UNITS_DETAILS = {
  KILOGRAM: {
    name: 'Kilogram',
    symbol: 'kg',
    id: PREDEFINED_UNITS.KILOGRAM,
  },
  GRAM: {
    name: 'Gram',
    symbol: 'gm',
    id: PREDEFINED_UNITS.GRAM,
  },
  KILOMETER: {
    name: 'Kilometer',
    symbol: 'km',
    id: PREDEFINED_UNITS.KILOMETER,
  },
  METER: {
    name: 'Meter',
    symbol: 'mtr',
    id: PREDEFINED_UNITS.METER,
  },
  CENTIMETER: {
    name: 'Centimeter',
    symbol: 'cm',
    id: PREDEFINED_UNITS.CENTIMETER,
  },
  MILLIMETER: {
    name: 'Millimeter',
    symbol: 'mm',
    id: PREDEFINED_UNITS.MILLIMETER,
  },
  FEET: {
    name: 'Feet',
    symbol: "'",
    id: PREDEFINED_UNITS.FEET,
  },
  INCH: {
    name: 'Inch',
    symbol: '"',
    id: PREDEFINED_UNITS.INCH,
  },
  PERCENT: {
    name: 'Percent',
    symbol: '%',
    id: PREDEFINED_UNITS.PERCENT,
  },
};

export const COUNTRY_PREFERRED_LANGUAGES = {
  IN: ['EN', 'HN', 'MA', 'BE', 'TE', 'TA', 'GU', 'KN', 'ML', 'UR'],
  ID: ['EN', 'ID'],
  VN: ['EN', 'VN'],
  BR: ['EN', 'PT'],
  PT: ['EN', 'PT'],
};

export const LANGUAGES = [
  {
    locale: 'हिन्दी',
    en: 'Hindi',
    key: 'HN',
    voiceId: 'hi-IN',
  },
  {
    locale: 'বাংলা',
    en: 'Bangla',
    key: 'BE',
    voiceId: 'bn-IN',
  },
  {
    locale: 'मराठी',
    en: 'Marathi',
    key: 'MA',
    voiceId: 'mr-IN',
  },
  {
    locale: 'తెలుగు',
    en: 'Telugu',
    key: 'TE',
    voiceId: 'te-IN',
  },
  {
    locale: 'தமிழ்',
    en: 'Tamil',
    key: 'TA',
    voiceId: 'ta-IN',
  },
  {
    locale: 'ગુજરાતી',
    en: 'Gujarati',
    key: 'GU',
    voiceId: 'gu-IN',
  },
  {
    locale: 'ಕನ್ನಡ',
    en: 'Kannada',
    key: 'KN',
    voiceId: 'kn-IN',
  },
  {
    locale: 'മലയാളം',
    en: 'Malayalam',
    key: 'ML',
    voiceId: 'ml-IN',
  },
  {
    locale: 'اردو',
    en: 'Urdu',
    key: 'UR',
    voiceId: 'ur-IN',
  },
  {
    locale: 'Bahasa',
    en: 'Indonesian',
    key: 'ID',
    voiceId: 'id-ID',
  },
  {
    locale: 'Tiếng Việt',
    en: 'Vietnamese',
    key: 'VN',
    voiceId: 'vi-VN',
  },
  {
    locale: 'português',
    en: 'Portuguese',
    key: 'PT',
    voiceId: 'pt-BR',
  },
  {
    locale: 'English',
    en: 'English',
    key: 'EN',
    voiceId: 'en-IN',
  },
];

export const LANGUAGES_VOICE_IOS = [
  {
    locale: 'हिन्दी',
    en: 'Hindi',
    key: 'HN',
    voiceId: 'hi-IN',
  },
  {
    locale: 'English',
    en: 'English',
    key: 'EN',
    voiceId: 'en-IN',
  },
];

export const LANGAUGE_VOICE_MAPPING = {
  HN: {voiceId: 'hi-IN', locale: 'हिन्दी'},
  BE: {voiceId: 'bn-IN', locale: 'বাংলা'},
  MA: {voiceId: 'mr-IN', locale: 'मराठी'},
  TE: {voiceId: 'te-IN', locale: 'తెలుగు'},
  TA: {voiceId: 'ta-IN', locale: 'தமிழ்'},
  GU: {voiceId: 'gu-IN', locale: 'ગુજરાતી'},
  KN: {voiceId: 'kn-IN', locale: 'ಕನ್ನಡ'},
  UR: {voiceId: 'ur-IN', locale: 'اردو'},
  ML: {voiceId: 'ml-IN', locale: 'മലയാളം'},
  EN: {voiceId: 'en-IN', locale: 'English'},
  ID: {voiceId: 'id-ID', locale: 'Bahasa'},
  VN: {voiceId: 'vi-VN', locale: 'Tiếng Việt'},
  PT: {voiceId: 'pt-BR', locale: 'português'},
};

export const GOOGLE_LANGUAGE_CODE = {
  EN: 'en',
  HN: 'hn',
  BE: 'bn',
  MA: 'mr',
  TE: 'te',
  TA: 'ta',
  GU: 'gu',
  KN: 'kn',
  UR: 'ur',
  ML: 'ml',
  ID: 'id',
  VN: 'vi',
  PT: 'pt-BR',
};

export const CONTACT_OPTIONS = {
  CALL: 'CALL',
  SMS: 'SMS',
  WHATSAPP: 'WHATSAPP',
};

export const COLOR_CODE_FOR_FILES = [
  '#ffadad',
  '#ffd6a5',
  '#fdffb6',
  '#caffbf',
  '#9bf6ff',
  '#a0c4ff',
  '#bdb2ff',
  '#ffc6ff',
  '#ffba08',
  '#80ffdb',
];

export const SUPPORT_PHONE_NUMBER = {
  EN: '+919636012926',
};

// export const IP_STACK_API = {
//   ACCESS_KEY: '2bc12b48bd97063cf60b2e4a24b6689a',
// };

export const NEWFILE_SCREEN_TYPE = {
  CREATE_NEW_FILE: 'CREATE_NEW_FILE',
  RENAME_FILE: 'RENAME_FILE',
  SAVE_TEMPLATE: 'SAVE_TEMPLATE',
  RENAME_TEMPLATE: 'RENAME_TEMPLATE',
};

export const ASYNC_STORAGE_KEY = {
  USER_PREF: 'USER_PREF',
  USER_AUTOMATION_CONFIG: 'USER_AUTOMATION_CONFIG',
  USER_LOG_OUT: 'USER_LOG_OUT',
  USER_ID: 'USER_ID',
  APP_OPEN_COUNT: 'APP_OPEN_COUNT',
  FILES_BEING_DELETED: 'FILES_BEING_DELETED',
  RATING_POP_UP: 'RATING_POP_UP',
  USER_COUNTRY: 'USER_COUNTRY',
  USER_APP_VERSION: 'USER_APP_VERSION',
  USER_LANG: 'USER_LANG',
  NEW_APP_UPDATE_SESSION_COUNT: 'NEW_APP_UPDATE_SESSION_COUNT',
  APP_RECENT_VERSION: 'APP_RECENT_VERSION',
  SMART_TEMPLATE_LIST: 'SMART_TEMPLATE_LIST',
  TOTAL_ROWS_EXCEED_10_COUNTER: 'TOTAL_ROWS_EXCEED_10_COUNTER',
  PREFERRED_TEMPLATE_CATGS: 'PREFERRED_TEMPLATE_CATGS',
  MULTI_LOGIN_TOKEN: 'MULTI_LOGIN_TOKEN',
  FLIPKART_LOGIN_SUCCESS: 'FLIPKART_LOGIN_SUCCESS',
  FLIPKART_FILE_CREATED: 'FLIPKART_FILE_CREATED',
  REVENUE_CAT_CACHE_OFFERINGS: 'REVENUE_CAT_CACHE_OFFERINGS',
  REVENUE_CAT_SUBSCRIPTION_DATA: 'REVENUE_CAT_SUBSCRIPTION_DATA',
  ALL_TASKS_DATA: 'ALL_TASKS_DATA',
};

export const EXTERNAL_LINKS = {
  PLAYSTORE_LINK:
    'https://play.google.com/store/apps/details?id=com.swific.registerbook',
  APPSTORE_LINK: 'https://apps.apple.com/us/app/lio/id1537061015',
};

export const INVALID_INPUT = 'Invalid Input';
export const DIV_ERR = 'Division Error';

export const DEFAULT_HEADER = [
  {
    val: 'COLUMN A',
    fieldType: FIELD_TYPE_ID.TEXT,
    id: 'default_colA',
  },
  {
    val: 'COLUMN B',
    fieldType: FIELD_TYPE_ID.TEXT,
    id: 'default_colB',
  },
  {
    val: 'COLUMN C',
    fieldType: FIELD_TYPE_ID.TEXT,
    id: 'default_colC',
  },
];

export const DEFAULT_ROWS = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

export const COLUMN_NAMES = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'AA',
  'AB',
  'AC',
  'AD',
  'AE',
  'AF',
  'AG',
  'AH',
  'AI',
  'AJ',
  'AK',
  'AL',
  'AM',
  'AN',
  'AO',
  'AP',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AV',
  'AW',
  'AX',
  'AY',
  'AZ',
];

export const TABLE_LIMITS = {
  MAX_IMAGE_UPLOAD_AT_ONCE_COUNT: 2,
  MAX_IMAGE_UPLOAD_PERSONAL: 5,
  MAX_DOC_UPLOAD_AT_ONCE_COUNT: 2,
  MAX_COLUMNS: 150,
  MAX_TABLE_LINK_PERSONAL_VALUES: 50,
  MAX_ASSIGNED_TASK: 2,
  INITIAL_FETCH_LIMIT: 50,
  ROWS_BATCH_FETCH_SIZE: 550,
  OPTIONAL_ROW_KEYS: ['sortKey'],
  REQUIRED_ROW_KEYS: [
    'index',
    'rowId',
    'isDeleted',
    'rowProperties',
    'sortKey',
  ], //update on all cloud repos if used in that repo
  MANDATORY_ROWS_KEYS: ['index', 'rowId', 'isDeleted', 'rowProperties'],
};

export const IMPORT_EXCEL_LIMITS = {
  MAX_FILE_SIZE: 1, //Mb
  MAX_TABLE_ROWS_COUNT: 5000,
};

export const AUDIO_LIMITS = {
  MAX_FILE_SIZE: 1, //Mb
  MAX_DURATION_MIN: 2, // min
  MAX_DURATION_MIN_PREMIUM: 15, // min
  MAX_DURATION_MIN_PRO: 25, // min
  MAX_DURATION_MIN_PERSONAL: 5, // min
  MAX_FILE_SIZE_PREMIUM: 30, //Mb
};

export const VERSION_HISTORY_LIMITS = {
  MAX_NUMBER_OF_VERSIONS: 5,
  MAX_NUMBER_OF_VERSIONS_PERSONAL_20: 20,
  MAX_NUMBER_OF_VERSIONS_PREMIUM: 50,
  MAX_NUMBER_OF_VERSIONS_PRO_100: 100,
  FETCH_DOC_LIMIT: 15,
  VERSION_DATA_INITAL_FETCH_LIMIT: 20,
};
export const CAMPAIGNS_LIMITS = {
  FETCH_DOC_LIMIT: 15,
};

export const FORMATTING_TYPE = {
  COL_WIDTH: 'COL_WIDTH',
  TEXT_COLOR: 'TEXT_COLOR',
  BACKGROUND_COLOR: 'BACKGROUND_COLOR',
  TEXT_STYLE: 'TEXT_STYLE',
  ALLIGNMENT: 'ALLIGNMENT',
  CLEAR_FORMATTING: 'CLEAR_FORMATTING',
};

export const ROOT_FOLDER = '_ROOT_';

export const FILES_SORT_OPTIONS = {
  NAME: 'Name',
  DATE: 'Created Date',
  OPENED_DATE: 'Opened Date',
  TYPE: 'Type',
};

export const FILES_SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const DEFAULT_FILES_SORT = FILES_SORT_OPTIONS.DATE;
export const DEFAULT_SORT_ORDER = FILES_SORT_ORDER.DESC;

export const FORMAT_PICKER = {
  CELL: 'CELL',
  ROW: 'ROW',
  COLUMN: 'COLUMN',
};

export const COLOR_OPTIONS = [
  'rgb(255,255,255)',
  'rgb(10,15,19)',
  'rgb(252,13,27)',
  'rgb(253,153,39)',
  'rgb(255,253,56)',
  'rgb(41,253,47)',
  'rgb(78,137,229)',
  'rgb(11,36,251)',
];

export const emptyFunction = () => {};

export const COLLAB_ROW_COLORS = [
  'purple',
  'skyblue',
  'green',
  'blue',
  'red',
  'pink',
];

export const DARK_TO_LIGHT_COLORS = [
  '#791BEE',
  '#08C9E5',
  '#FFC700',
  '#2BD800',
];

export const UNDO_REDO_LIMIT = {
  LIMIT: 5,
};

export const SHARE_TYPE = {
  CUSTOMISE: 'CUSTOMISE',
  PDF: 'PDF', // to generate direct pdf
  EXCEL: 'EXCEL',
};

export const QUICK_ENTRY = {
  MAX_BANNER_ATTEMPTS: 3,
  OPTIONS: {
    INTRODUCTION: 'INTRODUCTION',
    ENABLE_BANNER: 'ENABLE_BANNER',
    DISABLE_BANNER: 'DISABLE_BANNER',
  },
};

export const TRASH_DURATION = {
  DAYS: 30,
};

export const APP_OPEN_COUNT_TEN = 10;

export const PROFILE_URL = {
  PROD: 'https://firebasestorage.googleapis.com/v0/b/registerbook-public/o/user.png?alt=media&token=b019aa19-29fd-49c7-9fb4-68958a5b2749',
  STAGE:
    'https://firebasestorage.googleapis.com/v0/b/registerbook-development-public/o/user.png?alt=media&token=7db0c91b-fcb8-4ef7-848a-9ccbba3963b4',
};
export const SMART_TEMPLATE_BANNER_ICON_URL = {
  PROD: 'https://firebasestorage.googleapis.com/v0/b/registerbook-public/o/WB-LIO.png?alt=media&token=a4948c7a-7c99-4dad-877e-8e3c7b84dfc2',
  STAGE:
    'https://firebasestorage.googleapis.com/v0/b/registerbook-development-public/o/WB-LIO.png?alt=media&token=fa3442ea-ff31-49dc-bc66-12149e602eb8',
};

export const MAX_RECENT_USED_TEMPLATE = 2;

export const PAGE_TYPE = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  CLIENT: 'CLIENT',
};

export const WEB_CLIENT_ID = {
  PROD: '221256281779-fdecunaur0jd2ensj8ggh67gtlhh60oo.apps.googleusercontent.com',
  STAGE:
    '452983432395-m7a34m86obh2ilkhbp41bik5aooafgc6.apps.googleusercontent.com',
};

export const HOMESCREEN_TITLE = {
  documents: 'My Documents',
  templates: 'Templates',
  profile: 'Profile',
  videos: 'Videos',
  dashboard: 'Dashboard',
  community: 'Community',
  tasks: 'My Tasks',
  teams: 'Teams',
  miniApps: 'My Apps',
  lio_store: 'Lio Store',
};

export const COLOR_PICKER = [
  '#22A1B2',
  '#556A89',
  '#6D86DE',
  '#337EED',
  '#FF9429',
  '#08C9E5',
  '#986C66',
  '#FF406E',
  '#08E553',
  '#AEED5F',
  '#F1A42F',
  '#9649F5',
  '#D0B65B',
  '#FFCD56',
  '#791BEE',
  '#14BD6B',
  '#0B70AB',
  '#F159D0',
  '#449F35',
  '#C32A71',
  '#4D5360',
  '#05A5FF',
  '#0250E8',
  '#E02A2A',
];

export const MAX_SELECTABLE_COLLAB_SHARE_CONTACTS = 5;
export const MAX_NUMBER_OF_VERSIONS = 5;

export const CUSTOM_EDIT_MODES = {
  OWN_ROWS: 'OWN_ROWS',
  //default : can edit all rows
};

export const ENTRY_EDIT_MODES = {
  END_OF_DAY: 'END_OF_DAY',
  ONLY_EMPTY_CELLS: 'ONLY_EMPTY_CELLS',
  ANYTIME: 'ANYTIME',
  //default : can't edit
};

export const CUSTOM_SHARE_EDIT_OPTIONS = [
  {
    key: 'ALL_ROWS',
    title: 'Edit All Rows',
    subText: 'Can edit all the rows',
  },
  {
    key: CUSTOM_EDIT_MODES.OWN_ROWS,
    title: 'Edit Own Rows Only',
    subText: 'Can edit the rows they have created',
  },
];

export const ENTRY_ONLY_EDIT_OPTIONS = [
  {
    key: 'CANNOT_EDIT',
    title: "Can't Edit",
    subText: 'User cannot edit entries done by them.',
  },
  {
    key: ENTRY_EDIT_MODES.END_OF_DAY,
    title: 'Edit Till End of Day',
    subText: 'User can only edit the entries till end of the day.',
  },
  {
    key: ENTRY_EDIT_MODES.ONLY_EMPTY_CELLS,
    title: 'Edit only Empty Cells',
    subText: 'Once entry is done user can only fill up empty cells.',
  },
  {
    key: ENTRY_EDIT_MODES.ANYTIME,
    title: 'Edit Entries',
    subText: 'User can edit their own entries anytime.',
  },
];

export const SHARE_PERMISSION_TYPE = {
  ADMIN: 'ADMIN',
  CAN_EDIT: 'CAN_EDIT',
  ENTRY_ONLY: 'ENTRY_ONLY',
  CAN_VIEW: 'CAN_VIEW',
  CUSTOM: 'CUSTOM',
  OWNER: 'OWNER',
};

export const SHARE_PERMISSION_TYPE_DETAILS = {
  ADMIN: {
    text: 'Admin',
    iconName: 'account-settings',
    description: 'Can edit, share document and modify permissions',
  },
  CAN_EDIT: {
    text: 'Can Edit',
    iconName: 'pencil',
    description: 'Can view and edit data',
  },
  ENTRY_ONLY: {
    text: 'Entry Only',
    iconName: 'table-large-plus',
    description: 'Can only add new entries and view own entries',
  },
  ENTRY_ONLY_CUSTOMIZE: {
    text: 'Customize',
    iconName: 'table-cog',
    description:
      'Customize advanced options with entry only permission for the shared users',
  },
  CAN_VIEW: {
    text: 'Can View',
    iconName: 'eye',
    description: 'Can only view the data',
  },
  CUSTOM: {
    text: 'Custom',
    iconName: 'format-list-bulleted-square',
    description: 'Customise Edit/View/Hide properties for every column',
  },
  OWNER: {text: 'Owner'}, //not to be listed in actual types
};

export const RESTRICTION_TYPES_FOR_NON_PREMIUM_USERS = {
  FILE_COUNT: 'FILE_COUNT',
  DOCUMENT_ROW_COUNT: 'DOCUMENT_ROW_COUNT',
  PAGE_COUNT: 'PAGE_COUNT',
  ASSIGN_TASK_COUNT: 'ASSIGN_TASK_COUNT',
};

export const CUSTOM_SHARING_RESTRICTIONS = {
  VIEW: 'VIEW',
  HIDE: 'HIDE',
};

export const SHARE_PERMISSION_TYPE_OPTIONS = [
  SHARE_PERMISSION_TYPE.ADMIN,
  SHARE_PERMISSION_TYPE.CAN_EDIT,
  SHARE_PERMISSION_TYPE.CAN_VIEW,
  SHARE_PERMISSION_TYPE.ENTRY_ONLY,
  SHARE_PERMISSION_TYPE.CUSTOM,
];

export const AUTO_MESSAGE_LISTENER = {
  PENDING_AUTOMATIONS: 'AUTO_MESSAGE_PENDING_AUTOMATIONS',
};
export const TABLE_LINK_LISTENERS = {
  AUTO_FILL_LINKED_FILES: 'AUTO_FILL_LINKED_FILES',
  PARENT_ROW_META: 'PARENT_ROW_META',
};

export const UNIQUE_VALUES_LISTENERS = {
  UNIQUE_VALUES_DATA: 'UNIQUE_VALUES_DATA',
};

export const COLLAB_LISTENERS = {
  FOOTER_PROPERTIES: 'FOOTER_PROPERTIES',
  ACTIVE_USERS: 'ACTIVE_USERS',
  DOCUMENT_DATA: 'DOCUMENT_DATA',
  PAGES_META: 'PAGES_META',
  // DOCUMENT_META: 'DOCUMENT_META', replaced by home documents listener
};

export const TABLE_VIEW_MODES = {
  PREVIEW: 'PREVIEW', // Cannot edit anything + Preview Modal
  VERSION_PREVIEW: 'VERSION_PREVIEW', // Cannot edit anything + VERSION Preview Modal
  SELECTABLE_PREVIEW: 'SELECTABLE_PREVIEW', //multiple row-col selecting
  DOCUMENT_LOCK: 'DOCUMENT_LOCK', // Can add new rows
  COLLAB_OFFLINE: 'COLLAB_OFFLINE', // Cannot edit anything
  COLLAB_CAN_VIEW: 'COLLAB_CAN_VIEW', // Cannot edit anything
  CUSTOM_SHARED: 'CUSTOM_SHARED', // Custom edit/view/hide sharing
};

export const TABLE_INTERACTIONS = {
  ADD_NEW_ROW: 'ADD_NEW_ROW',
  ADD_PAGES: 'ADD_PAGES',
  RESTORE_PAGES: 'RESTORE_PAGES',
  CHANGE_PAGE: 'CHANGE_PAGE',
  CELL_LONG_PRESS: 'CELL_LONG_PRESS',
  CELL_PRESS: 'CELL_PRESS',
  CAN_VIEW: 'CAN_VIEW',
  FOOTER_PRESS: 'FOOTER_PRESS',
  CAN_ENABLE_QUICK_ENTRY: 'CAN_ENABLE_QUICK_ENTRY',
  HEADER_ADD_NEW_COL: 'HEADER_ADD_NEW_COL',
  BLOCK_VIEW_ADD_NEW_COL: 'BLOCK_VIEW_ADD_NEW_COL',
  HEADER_PRESS: 'HEADER_PRESS',
  MULTIPLE_SELECTABLE_ROW_COL_DELETE: 'MULTIPLE_SELECTABLE_ROW_COL_DELETE',
  ROW_DELETE: 'ROW_DELETE',
  SWITCH_AND_CHECKBOX: 'SWITCH_AND_CHECKBOX',
  DOCUMENT_LOCK: 'DOCUMENT_LOCK',
  UNDO_REDO: 'UNDO_REDO',
  CELL_FORMATTING_PRESS: 'CELL_FORMATTING_PRESS',
  ROW_SELECTABLE: 'ROW_SELECTABLE',
};

export const TABLE_VIEW_RULES = {
  //edit-disable rules for view modes
  [TABLE_INTERACTIONS.CELL_PRESS]: [
    TABLE_VIEW_MODES.PREVIEW,
    TABLE_VIEW_MODES.COLLAB_OFFLINE,
    TABLE_VIEW_MODES.COLLAB_CAN_VIEW,
  ],
  [TABLE_INTERACTIONS.CELL_LONG_PRESS]: [
    TABLE_VIEW_MODES.PREVIEW,
    TABLE_VIEW_MODES.COLLAB_OFFLINE,
    TABLE_VIEW_MODES.COLLAB_CAN_VIEW,
    TABLE_VIEW_MODES.SELECTABLE_PREVIEW,
  ],
  [TABLE_INTERACTIONS.HEADER_PRESS]: [
    TABLE_VIEW_MODES.PREVIEW,
    TABLE_VIEW_MODES.COLLAB_OFFLINE,
    TABLE_VIEW_MODES.COLLAB_CAN_VIEW,
  ],
  [TABLE_INTERACTIONS.HEADER_ADD_NEW_COL]: [
    TABLE_VIEW_MODES.COLLAB_CAN_VIEW,
    TABLE_VIEW_MODES.COLLAB_OFFLINE,
    TABLE_VIEW_MODES.SELECTABLE_PREVIEW,
    TABLE_VIEW_MODES.PREVIEW,
    TABLE_VIEW_MODES.DOCUMENT_LOCK,
  ],
  [TABLE_INTERACTIONS.ADD_NEW_ROW]: [
    TABLE_VIEW_MODES.PREVIEW,
    TABLE_VIEW_MODES.COLLAB_OFFLINE,
    TABLE_VIEW_MODES.COLLAB_CAN_VIEW,
    TABLE_VIEW_MODES.SELECTABLE_PREVIEW,
  ],
  [TABLE_INTERACTIONS.MULTIPLE_SELECTABLE_ROW_COL_DELETE]: [
    TABLE_VIEW_MODES.PREVIEW,
    TABLE_VIEW_MODES.COLLAB_CAN_VIEW,
    TABLE_VIEW_MODES.COLLAB_OFFLINE,
    TABLE_VIEW_MODES.DOCUMENT_LOCK,
  ],
  [TABLE_INTERACTIONS.UNDO_REDO_DOCLOCK_AND_FORMATTING]: [
    TABLE_VIEW_MODES.PREVIEW,
    TABLE_VIEW_MODES.SELECTABLE_PREVIEW,
    TABLE_VIEW_MODES.COLLAB_CAN_VIEW,
    TABLE_VIEW_MODES.COLLAB_OFFLINE,
  ],
  [TABLE_INTERACTIONS.SWITCH_AND_CHECKBOX]: [
    TABLE_VIEW_MODES.COLLAB_CAN_VIEW,
    TABLE_VIEW_MODES.COLLAB_OFFLINE,
    TABLE_VIEW_MODES.SELECTABLE_PREVIEW,
    TABLE_VIEW_MODES.PREVIEW,
  ],
  [TABLE_INTERACTIONS.FOOTER_PRESS]: [
    TABLE_VIEW_MODES.COLLAB_CAN_VIEW,
    TABLE_VIEW_MODES.COLLAB_OFFLINE,
    TABLE_VIEW_MODES.SELECTABLE_PREVIEW,
    TABLE_VIEW_MODES.PREVIEW,
  ],
  [TABLE_INTERACTIONS.FOOTER_PRESS]: [
    TABLE_VIEW_MODES.COLLAB_CAN_VIEW,
    TABLE_VIEW_MODES.COLLAB_OFFLINE,
    TABLE_VIEW_MODES.SELECTABLE_PREVIEW,
    TABLE_VIEW_MODES.PREVIEW,
  ],
};

export const CLOUD_FUNCTION_PATHS = {
  GET_LOGIN_TOKEN: 'getLoginToken',
  CREATE_MINI_APP_WITH_AI: 'createminiappwithai',
  SHARE_DOC_TO_CONTACTS: 'shareDocToContact_ARCH_V2',
  REMOVE_SHARED_DOC_FROM_UID: 'removeSharedDocFromUID_ARCH_V2',
  CHANGE_SHARED_DOC_PERMISSION: 'changeSharedDocPermission_ARCH_V2',
  DISABLE_COLLAB_PAGES: 'disableCollabPages',
  ADD_EDIT_MINI_APP_ENTRY: 'miniappaddeditentryv1',
  MINI_APP_UNIQUE_VALUES_CHECK: 'miniappuniquevaluecheckv1',
  ADD_ENTRY_ONLY_DATA: 'addEntryOnlyData_ARCH_V2_MINI_APPS',
  MANAGE_ENTRY_ONLY_HEADER: 'manageEntryOnlyHeader_ARCH_V2',
  SEND_LOGIN_OTP: 'sendLoginOtp',
  CHECK_IF_USER_EXISTS: 'checkIfUserExists',
  CREATE_NEW_DASHBOARD: 'createDashboardV2_ARCH_V2',
  MIGRATE_USER_OLDER_DASHBOARDS: 'migrateUserOlderDashboards_ARCH_V2',
  DELETE_DASHBOARD: 'deleteDashboardV2_ARCH_V2',
  // prev AUTO_MESSAGE_WHATSAPP_CALL function name - autoMessageWhatsappCall ->
  // prev AUTO_MESSAGE_WHATSAPP_CALL function name - autoMessageWhatsappCallv2 ->
  // AUTO_MESSAGE_WHATSAPP_CALL: 'autoMessageWhatsappCallv2_ARCH_V2',
  AUTO_MESSAGE_WHATSAPP_CALL: 'autoMessageWhatsappCallv2_ARCH_V2_MINI_APPS',
  MANAGE_CHILD_TABLE_LINKS: 'manageChildTableLinks_ARCH_V2',
  RECALCULATE_SUMMARY_CONFIG: 'recalculatesummaryconfig',
  AUTO_MESSAGE_WHATSAPP_TEST_NOTIFICATION:
    'autoMessageWhatsappTestNotification_ARCH_V2',
  FLIPKART_LOGIN: 'flipkartLogin',
  FLIPKART_IMPORT: 'flipkartImport',
  FLIPKART_SYNC: 'flipkartSync',
  VERIFY_CAMPAIGN_TEST_NUMBER: 'sendCampaignVerifyNumber',
  FETCH_LINKED_DOCUMENT_DATA_FOR_TABLE_LINKS:
    'fetchLinkedDocumentDataForTableLinks_ARCH_V2',
  GET_FREE_TRIAL_PREMIUM: 'getFreeTrialV1',
  ASSIGN_TASK_TO_USER: 'assignTaskV1',
  FETCH_TASK_DATA: 'fetchTaskForUser_ARCH_V2',
  CHANGE_TASK_COMPLETION_STATE: 'alterTaskCompletionState_ARCH_V2',
  HTML_TO_PDF: 'createPdfFromHtml',
  CHECK_PARTICIPANT_APP_VERSION: 'checkParticipantAppVersion',
  REMOVE_SHARED_DOC_MULTI_USER: 'removeSharedDocMultiUser',
  CHECK_IF_CONTACT_IS_REGISTERED: 'checkifuserregistered',
  SEND_MULTI_NOTIFICATION_WHATSAPP: 'sendMultiNotificationWhatsapp',
  ON_BOARDING_AUTO_CREATE: 'onboardingAutoCreations_ARCH_V2',
  SHARE_PLAN_WITH_CONTACT: 'sharePremiumWithUserV1',
  REMOVE_CONTACT_FROM_PLAN: 'removeSharedPremiumFromUserV1',
  CREATE_EDIT_TEAM: 'createEditTeam_ARCH_V2',
  SHARE_MULTIPLE_DOCS_WITH_MULTIPLE_TEAMS: 'shareDocsWithTeamsV1_ARCH_V2',
  UNSHARE_FILE_WITH_ALL_USERS: 'stopSharingCollabFile_ARCH_V2',
  MIGRATE_USER_OLDER_TEAMS: 'migrateUserOlderTeams',
  UNSHARE_DOC_WITH_TEAM: 'unshareDocWithTeam_ARCH_V2',
  RESTORE_FILES_FOLDERS_FROM_TRASH: 'restoreFilesFoldersFromTrash_ARCH_V2',
  MANAGE_DUPLICATE_FILE: 'manageDuplicateFile',
  MIGRATE_DOC_TO_ARCH_V2: 'migrateSingleDocForScalebility',
  UPDATE_SHARED_FILE_META_KEY_VALUES: 'updateSharedFileMetaKeyValuesCF',
  RESTORE_VERSION_FILE: 'restoredocumentversion',
  TABLE_LINKING_SEARCH: 'searchTextTableLinking', //for non-orgs
  TABLE_LINKING_SEARCH_ORG: 'searchtexttablelinkingorg', //for orgs
  TRIGGER_AUTOMATION_MANUALLY: 'triggerautomationforminiappv1',
  TRIGGER_ACTION_BTN_MULTI_SELECT: 'multiselecttriggeractionbtn',
  SEARCH_TEXT_IN_DOC: 'searchTextInDoc', //for non-orgs
  GET_COLUMN_DEPENDENT_UNIQUE_VALUES: 'getColumnDependentUniqueValues', //for non-orgs
  GET_COLUMN_DEPENDENT_UNIQUE_VALUES_ORG: 'getcoldependentvalsorg', //for orgs
  GET_FILTER_SELECTABLE_PARAMS: 'getFilterParams', //for non-orgs
  GET_FILTER_SELECTABLE_PARAMS_ORG: 'getfilteroptions', //for orgs
  APPLY_FILTER_ON_COLUMNS: 'applyFilterOnColumns', //for non-orgs
  MINI_APPS_SEARCH_FILTER_DATA: 'searchfilterforminiapp', //for orgs
  ADMIN_CREATE_NEW_TEMPLATE_MASTER: 'adminCreateNewTemplateMaster_ARCH_V2',
  ADMIN_PUSH_APP_TO_LIO_STORE: 'adminpushapptoliostore',
  MIGRATE_DOC_FOR_SCALABILITY: 'migrateDocForScalibilty',
  CAMPAIGN_RUN: 'campaignRun',
  GET_NO_OF_NON_EMPTY_ROWS: 'noOfNonEmptyRowsWithColumn',
  CREATE_OR_UPDATE_MINI_APPS: 'createUpdateMiniAppV1',
  ADD_OR_EDIT_SCREEN_MINI_APPS: 'addEditScreenMiniAppV1',
  ADD_OR_EDIT_MULTIPLE_SCREEN_MINI_APPS: 'addEditMultipleScreenMiniAppV1',
  MANAGE_SCREENS_MINI_APPS: 'managescreensminiappv2',
  CHANGE_MINI_APP_PROPERTY: 'changeMiniAppPropertyV1',
  INSTALL_MINI_APP_FROM_APP_STORE: 'installminiappfromstore',
  MIGRATE_USER_OLDER_TEMPLATES: 'migrateMyTemplates_ARCH_V2',
  FETCH_CHILD_LINKS: 'fetchChidLinkData_ARCH_V2',
  CREATE_OR_UPDATE_ELASTIC_DASHBOARD: 'createUpdateElasticDashboardV1',
  SHARE_MINI_APP: 'shareMiniAppV1',
  REMOVE_SHARED_USER_OR_LEAVE_MINI_APP: 'removeSharedUserOrLeaveAppV1',
  CREATE_EDIT_MINI_APP_CUSTOM_ROLES: 'createEditMiniAppCustomRoleV2',
  MODIFY_OR_CREATE_ORGANIZATION: 'modifyOrCreateOrganization',
  DUPLICATE_MINI_APP_SCREEN: 'duplicatescreenv1',
  ADD_EDIT_ACTION_BUTTONS: 'addeditactionbtns',
  ADD_EDIT_FILE_OBJ_PROPERTIES: 'addeditfileobjproperties',
  SEND_TEST_EVENT_FOR_WEBHOOK: 'sendTestWebHookEventIntegration',
  DELETE_ROW_CF: 'deleteRowCF',
  ADD_COLUMN_CF: 'addColumnCF',
  DELETE_COLUMN_CF: 'deleteColumnCF',
  EDIT_COLUMN_CF: 'editColumnCF',
  MOVE_COLUMNS_CF: 'moveColumnsCF',
  MANAGE_ORGANISATION_APP_ACCESS: 'manageOrganisationAppAccess',
  ORG_PLAN_EXPIRY_SEND_ME_A_LINK: 'orgPlanExpirySendMeALink',
  UPDATE_QUICK_FILTER_COLUMNS_FOR_SCREEN: 'updateQuickFilterColumnsForScreenV1',
  ADD_EDIT_MULTIPLE_ROWS: 'addeditmultiplerows',
  GENERATE_MANUALLY_MINI_APP_REPORT: 'generatedashboardreportv1',
  CREATE_EDIT_REPORT_FOR_MINI_APP_SCREEN: 'createeditreportminiappscreen',
  CREATE_EDIT_AGGREGATION_FOR_MINI_APP: 'createeditaggreagationminiapp',
  CREATE_EDIT_ENDPOINT_FOR_MINI_APP: 'createeditendpointnminiapp',
  DELETE_MINI_APPS_SAMPLE_DATA: 'deleteAppSampleDataV1',
  GENERATE_MANUALLY_MINI_APP_DASHBOARD_REPORT: 'generatedashboardreportv1',
  EXPORT_MINI_APP_SCREEN_DATA_AS_EXCEL: 'exportMiniAppScreenV1',
  IMPORT_EXCEL_GET_HEADER_MAPPING_CONFIG: 'getExcelImportConfig',
  IMPORT_EXCEL_FOR_MINI_APP: 'importExcelForMiniAppV1',
  CREATE_IMPORT_TEMPLATES_FOR_MINI_APPS: 'createImportTemplateForMiniApps',
  UPDATE_CREATED_IMPORT_TEMPLATE: 'updatecreatedtemplateflowv1',
  UPDATE_ORGANISATION_SUBSCRIPTION: 'updateSubscription',
  CANCEL_ORGANISATION_SUBSCRIPTION: 'cancelSubscription',
  DELETE_IMPORTED_ROWS: 'deleteimportedrows',
  SORT_DOC_BY_COLUMN: 'sortdocbycolumn',
  POST_API_HANDLER: 'postapihandler',
  AUTO_INCREMENT_ID_HANDLER: 'autoIncrementIdHandler',
  GET_FACEBOOK_PAGES: 'getFacebookPages',
  GET_FACEBOOK_PAGE_FORMS: 'getFacebookPageForms',
  GET_FACEBOOK_PAGE_FORM: 'getFacebookPageForm',
  STORE_FACEBOOK_PAGE_FORM: 'storeFacebookPageForm',
  DELETE_ROWS_RECURRING: 'deleterecurringrows',
  SYNC_WHATSAPP_TEMPLATES: 'syncWhatsAppTemplates',
  GENERATE_PDF: 'generatepdf',
  DELETE_ROW_V2: 'deleterowv2',
  GENERATE_OTP_MINI_APPS: 'generateotp',
  VERIFY_OTP_MINI_APPS: 'verifyotp',

  // Inegrations
  STORE_INTERAKT_INTEGRATION: 'storeInteraktIntegration',
  DELETE_INTERAKT_INTEGRATION: 'deleteInteraktIntegration',
  TOGGLE_INTERAKT_INTEGRATION: 'toggleInteraktIntegration',
  DELETE_FILES_FOLDERS_AND_MOVE_TO_TRASH:
    'deleteFilesFoldersAndMoveToTrash_ARCH_V2',
  DELETE_INTEGRATION: 'deleteIntegrationForMiniApp',

  // Gmail APIs
  GENERATE_GMAIL_AUTH_URL: 'generateGmailAuthURL',

  GET_ALL_APPS_IN_ORGANISATION: 'getallappsinorganisation',
  GET_ALL_FILES_CONTENT_AND_ZIP: 'getAllFilesAsZip',
  FETCH_DATA_FROM_ENDPOINT: 'fetchAllDataFromEndpoint',
  CREATE_EXPORT_TEMPLATES_FOR_MINI_APPS: 'createexporttemplateminiapps',
};

export const FIELD_OBJ_MANIPULATION_PROPERTY = {
  UNIQUE_COLUMN_SET: 'UNIQUE_COLUMN_SET',
};

export const DASHBOARD_CLOUD_FUNCTION_PATHS = {
  CALCULATE_DASHBOARD_FROM_DATA: 'calculatedashboarddata',
  MANAGE_DASHBOARD_SORTING_INDEX: 'setDashboardIndexes',
};

export const CLOUD_FUNCTION_COMMON_PARAMS = {
  ELASTIC_REQUEST: 'ELASTIC_REQUEST',
};

export const CAMPAIGN_STATUS = {
  SCHEDULED: 'SCHEDULED',
  RUNNING: 'RUNNING',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
};

const SURVEY_OPTIONS = {
  PAID_SOFTWARE: 'PAID_SOFTWARE',
  FREE_SOFTWARE: 'FREE_SOFTWARE',
  MS_EXCEL_OR_GOOGLE_SHEET: 'MS_EXCEL_OR_GOOGLE_SHEET',
  MANUAL_DATA_MANAGEMENT: 'MANUAL_DATA_MANAGEMENT',
};

export const CUSTOM_APP_SURVEY_OPTIONS = [
  {
    id: SURVEY_OPTIONS.PAID_SOFTWARE,
    displayName: 'Paid Software',
  },
  {
    id: SURVEY_OPTIONS.FREE_SOFTWARE,
    displayName: 'Free Software',
  },
  {
    id: SURVEY_OPTIONS.MS_EXCEL_OR_GOOGLE_SHEET,
    displayName: 'MS Excel/Google Sheets',
  },
  {
    id: SURVEY_OPTIONS.MANUAL_DATA_MANAGEMENT,
    displayName: 'Manual Data management',
  },
];

export const PROFILE_FIELDS = [
  'name',
  'email',
  'phoneNumber',
  'businessType',
  'businessName',
  'address',
  'profilePicture',
  'ownBusiness',
];

export const SHARED_DOC_META_FIELDS = [
  /**
   * only fields which are present in sharedDocMeta collection
   * if some new field is added to sharedDocMeta collection, then it needs to be added here
   * same in cloud functions
   */
  'pages',
  'pageObj',
  'isGlobalDocumentLock',
  'dashboards',
  'linkedDocIds',
  'isRowIdAdded',
  'isAutomationEnabled',
  'applyDocRestrictions',
  'restrictionConfig',
]; //update-on-cloud-function-too

export const LIO_LINKS = {
  PRIVACY_POLICY: 'https://lio.io/privacy-policy/',
  TERMS_AND_CONDITIONS: 'https://lio.io/terms-and-conditions/',
};

export const RANGE_FILTER_FIELDS = [
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.UNIT,
  FIELD_TYPE_ID.NO_OF_DAYS,
];

export const BOOLEAN_FILTER_FIELDS = [
  FIELD_TYPE_ID.SWITCH,
  FIELD_TYPE_ID.CHECKBOX,
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.MAPS,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.OTP,
];

export const TEXT_VALUE_TYPE_FILTER_FIELDS = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.CONTACT,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.TABLE,
  FIELD_TYPE_ID.URL,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.EMAIL,
  FIELD_TYPE_ID.AUTO_INCREMENT_ID,
  FIELD_TYPE_ID.STRING_FORMULA,
];

export const TIME_BASED_RANGE_FILTER_FIELDS = [
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.TIME,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.DATE_TIME,
];

export const MULTI_VALUE_FILTER_FIELDS = [
  FIELD_TYPE_ID.SELECT_POP_UP,
  FIELD_TYPE_ID.LABEL,
];

export const DASHBOARD_TYPES = {
  WITH_SPLIT: 'WITH_SPLIT',
  WITH_PAGES: 'WITH_PAGES',
  SPLIT_WITH_PAGES: 'SPLIT_WITH_PAGES',
};

export const GRAPH_TYPES = {
  STACKED_BAR: 'STACKED', //stackedBar chart
  PIE: 'PIE', //pie chart
  BAR: 'BAR', //bar chart
};

export const DASHBOARD_ERRORS = {
  DASHBOARD_INVALID_FIELD: 'DASHBOARD_INVALID_FIELD',
  DASHBOARD_COLUMN_NOT_FOUND: 'DASHBOARD_COLUMN_NOT_FOUND',
  DASHBOARD_SPLIT_BY_FIELDTYPE_MISMATCH:
    'DASHBOARD_SPLIT_BY_FIELDTYPE_MISMATCH',
}; //update-on-cloud-function-too

export const DASHBOARD_ERRORS_MESSAGES = {
  DASHBOARD_INVALID_FIELD: 'Invalid Column Type',
  DASHBOARD_COLUMN_NOT_FOUND: 'Column Not Found',
  DASHBOARD_SPLIT_BY_FIELDTYPE_MISMATCH:
    'Column type is not EXPECTED_COLUMN_TYPE',
  [INVALID_INPUT]: INVALID_INPUT,
};

export const SPLIT_BY_ALLOWED_FIELDS = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.SELECT_POP_UP,
  FIELD_TYPE_ID.LABEL,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.CONTACT,
  FIELD_TYPE_ID.DATE_TIME,
  FIELD_TYPE_ID.AUTO_INCREMENT_ID,
  FIELD_TYPE_ID.EMAIL,
  FIELD_TYPE_ID.STRING_FORMULA,
];

export const RESTRICTED_UI_FIELD_TYPES = [FIELD_TYPE_ID.PDF];

export const OPTION_COLUMN_FILTER = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.SELECT_POP_UP,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.SELECT_POP_UP,
  FIELD_TYPE_ID.CONTACT,
  FIELD_TYPE_ID.STRING_FORMULA,
  // FIELD_TYPE_ID.BARCODE,
];

//!IMP : Any change in this function should also be made in cloud-functions
export const NESTED_ARRAY_FILTER_CONFIG_FIELDS = [
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.CREATED_INFO,
];

export const FOOTER_OPERATION_TYPES = {
  TOTAL: 'TOTAL',
  AVERAGE: 'AVERAGE',
  COUNT: 'COUNT',
}; //update-on-cloud-function-too

export const FOOTER_OPERATION_TEXT = {
  TOTAL: 'Total',
  AVERAGE: 'Average',
  COUNT: 'Count',
};

export const DASHBOARD_LABEL_HELPER = {
  SEPARATOR: ', ',
  ENCODER: '#$_',
}; //update-on-cloud-function-too

export const DASHBOARD_CONSTANTS = {
  MAX_DASHBOARD_INTRO_POP_UP_COUNT: 3,
  MAX_PERSONAL_DASHBOARD_COUNT: 7,
  MAX_NON_SUBSCRIBED_USER_DASHBOARD_COUNT: 2,
  DEFAULT_PAGE_IDENTIFIER: '__DEFAULT_PAGE__',
  DEFAULT_PAGE_DISPLAY_NAME: 'Default Page',
  VIDEO: {
    backgroundColor: '#F5B102',
    iconName: 'chart-line',
    title: 'Dashboards',
    description:
      'Learn how to create dashboard and stay on top of your business.',
    url: 'https://d2mq8zmv4jun96.cloudfront.net/how_dasboard.mp4',
  },
};

export const FOOTER_MANDATORY_FIELDS = ['isVisible', 'total', 'count']; //update-on-cloud-function-too

// These types won't be available for selection in Table Linking
export const TABLE_LINK_DISABLED_COLOUMN_TYPES = [
  // FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.SWITCH,
  FIELD_TYPE_ID.CHECKBOX,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.LIST,
  FIELD_TYPE_ID.PDF,
];

export const TABLE_LINK_DISABLED_COLOUMN_TYPES_NON_ORG_USERS = [
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.IMAGE,
];

export const ENTRY_ONLY_USER_RESTRICTED_FIELDS = [FIELD_TYPE_ID.REMINDER];

export const ENTRY_ONLY_USER_RESTRICTED_FIELDS_NON_ORG = [
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.REMINDER,
];

export const REGISTERBOOK_DEVELOPMENT_COLLECTIONS = {
  communities: 'communities',
};

export const FORMULA_ALLOWED_FIELDS = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.TIME,
  FIELD_TYPE_ID.UNIT,
];
export const IMAGE_CONSTANTS = {
  MAX_NON_SUBSCRIBED_USER_IMAGES_COUNT: 2,
};

export const ATTACHMENT_CONTENT_TYPE = {
  PDF: 'application/pdf',
  //XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //DOCX : "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
};

export const SMART_TEMPLATE_TYPES = {
  W4B_TEMPLATE: 'W4B_TEMPLATE',
};

export const AUTO_TEMPLATE_TYPE = {
  FILP_ORDER: 'FILP_ORDER',
};

export const MESSAGE_AUTOMATION = {
  MANUAL: 'MANUAL',
  ROW_NEW_ENTRY: 'ROW_NEW_ENTRY',
  ON_ASSIGNEE_CHANGE: 'ON_ASSIGNEE_CHANGE',
  CELL_STATUS_CHANGE: 'CELL_STATUS_CHANGE',
  ON_SPECIFIC_DATE: 'ON_SPECIFIC_DATE',
  ON_NEW_COMMENT: 'ON_NEW_COMMENT',
  ROW_EDIT_ENTRY: 'ROW_EDIT_ENTRY',
  ROW_DELETE_ENTRY: 'ROW_DELETE_ENTRY',
  ON_USER_APP_ACCESS: 'ON_USER_APP_ACCESS',
};

export const MESSAGE_AUTOMATION_REMOVE_KEYS = ['businessName'];

export const AUTOMATION_SCREENS = {
  CREATE_AUTOMATION: 'CREATE_AUTOMATION',
  ADD_COLUMN: 'ADD_COLUMN',
};

export const DAILY_AUTOMATION_MAX_FILES_LIMIT = 10;

export const LIO_LOGO = {
  PROD: 'https://firebasestorage.googleapis.com/v0/b/registerbook-public/o/liologo.png?alt=media&token=0c6d8d57-988c-407a-8ad3-eb6e7c2fbe58',
  STAGE:
    'https://firebasestorage.googleapis.com/v0/b/registerbook-development-public/o/liologo.png?alt=media&token=38105a7a-6134-40d4-bdf7-5ead49be12fb',
};

export const INITIAL_FETCH_COMPLETED = {
  TABLE_DATA: 'TABLE_DATA',
  SHARED_META: 'SHARED_META',
  VIEW_ENTRY_DATA: 'VIEW_ENTRY_DATA',
};

export const SEARCH_FILTER_MODE = {
  SEARCH: 'SEARCH',
  FILTER: 'FILTER',
};

export const PDF_FIELD_EXPORTS = {
  OPTIONS: {
    PDF: 'PDF',
    EXCEL: 'EXCEL',
  },
  OPTIONS_LIST: [
    {id: 'PDF', text: 'PDF'},
    {id: 'EXCEL', text: 'Excel'},
  ],
};

export const DASHBOARDS_CHART_COLORS = [
  '#DD4242',
  '#C32A71',
  '#FF9429',
  '#986C66',
  '#FFCD56',
  '#A4892A',
  '#26C636',
  '#0B70AB',
  '#A1E44B',
  '#4D5360',
  '#4BC0C0',
  '#55D295',
  '#FF3FC9',
  '#6D86DE',
  '#059BFF',
  '#D0B65B',
  '#0250E8',
  '#BBA6A3',
  '#9649F5',
  '#8A93A1',
  '#F092F2',
  '#97BBCD',
  '#FF6384',
  '#D7D7D7',
];

export const ASSIGN_TASK_DETAILS = {
  PRIORITY: {
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
  },
  PRIORITY_DETAILS: {
    NONE: {icon: 'signal-cellular-outline', color: '#DFDFDF', text: 'None'},
    LOW: {icon: 'signal-cellular-1', color: '#FF8C22', text: 'Low'},
    MEDIUM: {icon: 'signal-cellular-2', color: '#6CB424', text: 'Medium'},
    HIGH: {icon: 'signal-cellular-3', color: '#ED5525', text: 'High'},
  },
  FILTER_DISPLAY_TEXT: {
    OPEN: 'Open',
    OVERDUE: 'Overdue',
    COMPLETED: 'Completed',
    HIGH: 'High',
    MEDIUM: 'Medium',
    LOW: 'Low',
    DUE_TODAY: 'Due Today',
    DUE_TOMORROW: 'Due Tomorrow',
  },
  FILTERS: [
    'OPEN',
    'OVERDUE',
    'COMPLETED',
    'HIGH',
    'MEDIUM',
    'LOW',
    'DUE_TODAY',
    'DUE_TOMORROW',
  ],
  PRIORITY_FILTERS: ['HIGH', 'MEDIUM', 'LOW'],
};

export const DATE_FORMATS_FOR_DATE_PICKER = [
  {id: DATE_FORMATS.DATE, text: 'Date'},
  {id: DATE_FORMATS.MONTH, text: 'Month'},
  {id: DATE_FORMATS.QUARTER, text: 'Quarter'},
  {id: DATE_FORMATS.YEAR, text: 'Year'},
];

export const DATA_FILTERS = {
  DATE_FILTER_RANGES: {
    TODAY: 'TODAY',
    YESTERDAY: 'YESTERDAY',
    TOMORROW: 'TOMORROW',
    CURRENT_MONTH: 'CURRENT_MONTH',
    PREV_MONTH: 'PREV_MONTH',
    NEXT_MONTH: 'NEXT_MONTH',
    CURRENT_QUARTER: 'CURRENT_QUARTER',
    PREV_QUARTER: 'PREV_QUARTER',
    NEXT_QUARTER: 'NEXT_QUARTER',
    CURRENT_YEAR: 'CURRENT_YEAR',
    PREV_YEAR: 'PREV_YEAR',
    NEXT_YEAR: 'NEXT_YEAR',
    LAST_N_DAYS: 'LAST_N_DAYS',
    LAST_N_MONTHS: 'LAST_N_MONTHS',
    LAST_N_QUARTERS: 'LAST_N_QUARTERS',
    LAST_N_YEARS: 'LAST_N_YEARS',
    NEXT_N_DAYS: 'NEXT_N_DAYS',
    NEXT_N_MONTHS: 'NEXT_N_MONTHS',
    NEXT_N_QUARTERS: 'NEXT_N_QUARTERS',
    NEXT_N_YEARS: 'NEXT_N_YEARS',
  },
  EMPTY_CELLS_ROWS: '__EMPTY_CELLS_ROWS__',
  ANY_CELLS_ROWS: '__ANY_CELLS_ROWS__',
  LOGGED_IN_USER: '__LOGGED_IN_USER__',
  EMPTY_CELLS_ROWS_TEXT: 'No Value',
  ANY_CELLS_ROWS_TEXT: 'Any Value',
  LOGGED_IN_USER_TEXT: 'Logged In User',
  DATA_ADDED_BY_USER: 'DATA_ADDED_BY_USER',
  DATA_ASSIGNED_TO_USER: 'DATA_ASSIGNED_TO_USER',
};

export const DATE_FILTER_QUICK_OPTIONS = (() => {
  const common = [
    {
      id: DATA_FILTERS.ANY_CELLS_ROWS,
      text: DATA_FILTERS.ANY_CELLS_ROWS_TEXT,
    },
    {
      id: DATA_FILTERS.EMPTY_CELLS_ROWS,
      text: DATA_FILTERS.EMPTY_CELLS_ROWS_TEXT,
    },
  ];
  const currMonth = {
    id: DATA_FILTERS.DATE_FILTER_RANGES.CURRENT_MONTH,
    text: 'Current Month',
  };
  const currYear = {
    id: DATA_FILTERS.DATE_FILTER_RANGES.CURRENT_YEAR,
    text: 'Current Year',
  };
  return {
    ['TIME']: [...common],
    [DATE_FORMATS.DATE]: [
      ...common,
      {
        id: DATA_FILTERS.DATE_FILTER_RANGES.TODAY,
        text: 'Today',
      },
      {
        id: DATA_FILTERS.DATE_FILTER_RANGES.YESTERDAY,
        text: 'Yesterday',
      },
      {
        id: DATA_FILTERS.DATE_FILTER_RANGES.TOMORROW,
        text: 'Tomorrow',
      },
      currMonth,
      currYear,
    ],
    [DATE_FORMATS.MONTH]: [
      ...common,
      currMonth,
      {
        id: DATA_FILTERS.DATE_FILTER_RANGES.PREV_MONTH,
        text: 'Previous Month',
      },
      {
        id: DATA_FILTERS.DATE_FILTER_RANGES.NEXT_MONTH,
        text: 'Next Month',
      },
      currYear,
    ],
    [DATE_FORMATS.QUARTER]: [
      ...common,
      {
        id: DATA_FILTERS.DATE_FILTER_RANGES.CURRENT_QUARTER,
        text: 'Current Quarter',
      },
      {
        id: DATA_FILTERS.DATE_FILTER_RANGES.PREV_QUARTER,
        text: 'Previous Quarter',
      },
      {
        id: DATA_FILTERS.DATE_FILTER_RANGES.NEXT_QUARTER,
        text: 'Next Quarter',
      },
      currYear,
    ],
    [DATE_FORMATS.YEAR]: [
      ...common,
      currYear,
      {
        id: DATA_FILTERS.DATE_FILTER_RANGES.PREV_YEAR,
        text: 'Previous Year',
      },
      {
        id: DATA_FILTERS.DATE_FILTER_RANGES.NEXT_YEAR,
        text: 'Next Year',
      },
    ],
  };
})();

export const DATE_FILTER_RANGE_OPTIONS = {
  [DATE_FORMATS.DATE]: ['Days', 'Months', 'Years'],
  [DATE_FORMATS.MONTH]: ['Months'],
  [DATE_FORMATS.QUARTER]: ['Quarters'],
  [DATE_FORMATS.YEAR]: ['Years'],
};

export const PREMIUM_TEAMS_LIMIT = {
  MAX_MEMBERS: 2,
  //MAX_TEAMS:1
};

export const REMINDER_FORMAT = 'Do MMMM YYYY, h:mm A';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const DATE_TIME_RENDER_FORMAT = 'dd/MM/yyyy hh:mm a';
export const TIME_FORMAT = 'h:mm A';
export const DATE_FORMAT = 'DD/MM/YYYY';

export const MAX_WHATSAPP_REMINDER_LIMIT = 5;

export const DEFAULT_WEBAPP_VERSION = '10.2.7';
export const AUTOMATION_ASSIGNEE_WEB_APP_VERSION = '10.2.1';

export const CREATED_INFO_COLUMN = {
  id: FIELD_TYPE_ID.CREATED_INFO,
  colName: 'Data Added By',
};
export const USER_INFO_COLUMN = {
  id: FIELD_TYPE_ID.USER_COLUMN,
  colName: 'User',
};

export const AUTOMATION_CATEGORY = {
  CAT_DAILY_SUMMARY: 'cat_daily_summary',
};

export const MMP_EVENTS_LIST = [
  'TRIAL_ACTIVATE_INIT',
  'TRIAL_ACTIVATED',
  'PREMIUM_VIEW_TRIAL',
  'PREMIUM_PLAN_ACTIVATE_INIT',
  'PREMIUM_VIEW_PLANS',
  'PREMIUM_EXPLORE_MORE',
  'PREMIUM_SELECT_PLAN',
  'PREMIUM_POST_ACTIVATE_DONE',
  'PREMIUM_PLAN_ACTIVATE',
  'GET_PREMIUM',
  'LOGIN_INIT',
  'LOGIN_SUCCESS',
  'DATA_ADDED_10_ROW_G',
  'FILE_SHARED_TO_TEAM',
  'SHARE_SUCCESS',
  'CREATE_AUTOMATION_SUCCESS',
  'CREATE_DASHBOARD_SUCCESS',
];

export const COPY_TYPES = {
  ROW: 'ROW',
  MULTIPLE_ROW: 'MULTIPLE_ROW',
  MULTIPLE_COL: 'MULTIPLE_COL',
  COL: 'COL',
};

export const PASTE_DATA_NOT_ALLOWED_COLS = [
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.TABLE,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.USER_COLUMN,
];

export const COPY_COL_NOT_ALLOWED = [
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.STRING_FORMULA,
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.TABLE,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.LIST,
  FIELD_TYPE_ID.PDF,
  FIELD_TYPE_ID.NO_OF_DAYS,
];

export const AUTOMATION_TEMPLATES_TYPE = {
  WHATSAPP: 'WHATSAPP',
  ALL: 'ALL',
  INTEGRATIONS: 'INTEGRATIONS',
};

// Not used but kept for Information
// export const SINGLE_COL_TYPES = [
//   FIELD_TYPE_ID.CREATED_INFO,
//   FIELD_TYPE_ID.ASSIGN_TASK,
// ];

export const colsWithNoProperty = [
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.COMMENT,
];

export const PAGE_DELETE_NOT_ALLOWED_PERMISSIONS = [
  SHARE_PERMISSION_TYPE.CAN_VIEW,
  SHARE_PERMISSION_TYPE.CAN_EDIT,
  SHARE_PERMISSION_TYPE.CUSTOM,
  SHARE_PERMISSION_TYPE.ENTRY_ONLY,
];

const MINI_APPS_LAYOUTS = {
  DEFAULT: 'DEFAULT',
  COMPACT: 'COMPACT',
  CONTACT_CARD: 'CONTACT_CARD',
  LIST: 'LIST',
  CATALOG: 'CATALOG',
};

export const MINI_APPS_FIELDS = {
  headerA: 'Header #1',
  headerB: 'Header #2',
  title: 'Title',
  subtitle: 'Subtitle',
  footerA: 'Footer #1',
  footerB: 'Footer #2',
  imageA: 'Image #1',
  rowStatus: 'Row Status',
  catalog: 'Additional Columns',
  catalogueVarient: 'Varient',
};

export const MINI_APPS_FIELDS_ORDER = [
  'imageA',
  'title',
  'subtitle',
  'headerA',
  'headerB',
  'footerA',
  'footerB',
];

export const MINI_APPS_SCREEN_TYPE = {
  DOCUMENT_DATA: 'DOCUMENT_DATA',
  FILTERED_DATA: 'FILTERED_DATA',
  DASHBOARD: 'DASHBOARD',
  REPORT: 'REPORT',
};

export const MINI_APPS_DATE_RECURRING_TYPES = {
  DOES_NOT_REPEAT: 'DOES_NOT_REPEAT',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
};

export const MINI_APPS_DATE_TIME_ENTRY = {
  DATE_TIME_ENTRY_ALL_ENTRIES: 'DATE_TIME_ENTRY_ALL_ENTRIES',
  DATE_TIME_ENTRY_CURRENT_FOLLOWING: 'DATE_TIME_ENTRY_CURRENT_FOLLOWING',
  DATE_TIME_ENTRY_CURRENT_ONLY: 'DATE_TIME_ENTRY_CURRENT_ONLY',
};

export const DATE_TIME_CALCULATION_TYPES = {
  D_A_N: 'D_A_N', //DATE ADD NUMBER
  D_S_N: 'D_S_N', //DATE SUBSTRACT NUMBER
  D_A_C: 'D_A_C', //DATE ADD COLUMN
  D_S_C: 'D_S_C', //DATE SUBSTRACT COLUMN
};

const SCREEN_VIEW_LAYOUTS = () => {
  const LAYOUT_TYPES = {
    DEFAULT: 'DEFAULT',
    CALENDAR: 'CALENDAR',
    KANBAN: 'KANBAN',
    GRID: 'GRID',
  };
  const LAYOUT_PROPERTIES = {
    CALENDAR: {
      SUB_TYPES: {
        SPECIFIC_DATE: 'SPECIFIC_DATE',
        DATE_RANGE: 'DATE_RANGE',
      },
      CONFIG_FIELDS: {
        START_DATE: 'START_DATE',
        END_DATE: 'END_DATE',
        SELECT_VIEW: 'fullScreenCalendarMapping',
        TITLE: 'title',
      },
    },
  };
  const SCREEN_VIEW_LAYOUTS_ORDER = [
    {
      id: LAYOUT_TYPES.DEFAULT,
      text: 'Default',
    },
    {
      id: LAYOUT_TYPES.CALENDAR,
      text: 'Calendar',
    },
    {
      id: LAYOUT_TYPES.KANBAN,
      text: 'Kanban Board',
    },
  ];
  const CALENDAR_SUB_TYPES = [
    {
      id: LAYOUT_PROPERTIES.CALENDAR.SUB_TYPES.SPECIFIC_DATE,
      text: 'Specific Date',
      CONFIG_FIELDS: [
        {
          id: LAYOUT_PROPERTIES.CALENDAR.CONFIG_FIELDS.START_DATE,
          text: 'Date Column',
        },
      ],
    },
    {
      id: LAYOUT_PROPERTIES.CALENDAR.SUB_TYPES.DATE_RANGE,
      text: 'Date Range',
      CONFIG_FIELDS: [
        {
          id: LAYOUT_PROPERTIES.CALENDAR.CONFIG_FIELDS.START_DATE,
          text: 'Start Date Column',
        },
        {
          id: LAYOUT_PROPERTIES.CALENDAR.CONFIG_FIELDS.END_DATE,
          text: 'End Date Column',
        },
      ],
    },
  ];
  return {
    LAYOUT_TYPES,
    LAYOUT_PROPERTIES,
    SCREEN_VIEW_LAYOUTS_ORDER,
    CALENDAR_SUB_TYPES,
  };
};

const MINI_APPS_CALCULATE_DATE_FIELD_TYPE = () => {
  const CONFIG_FIELDS = {
    DATE_VALUE: 'dateColId',
    OPERATION: 'type',
    ADDITION: 'numberColId',
    NUMBER_OF_DAYS: 'number',
    DATE_UNIT: 'unit',
  };
  const CONFIGURATION = {
    EXCLUDE_WEEKENDS: 'excludeWeekends',
  };
  const DATE_VALUE_OPTIONS = {
    CREATED_DATE: 'CREATED_DATE',
  };
  const ADDITION_VALUE_OPTIONS = {
    DAYS: 'DAYS',
  };
  const OPERATION_TYPES = {
    SUM: 'SUM',
    DIFFERENCE: 'DIFFERENCE',
  };
  const DATE_UNIT_OPTIONS = {
    DAY: 'day',
    MONTH: 'month',
    YEAR: 'year',
  };
  const DATE_TIME_UNIT_OPTIONS = {
    MINUTE: 'minute',
    HOUR: 'hour',
    ...DATE_UNIT_OPTIONS,
  };

  const CALCULATE_DATE_CONFIGS_ORDER = [
    {
      id: CONFIG_FIELDS.DATE_VALUE,
      text: 'Date Value',
    },
    {
      id: CONFIG_FIELDS.OPERATION,
      text: 'Operation',
    },
    {
      id: CONFIG_FIELDS.ADDITION,
      text: 'Addition',
    },
    {
      id: CONFIG_FIELDS.NUMBER_OF_DAYS,
      text: 'Value',
    },
    {
      id: CONFIG_FIELDS.DATE_UNIT,
      text: 'Unit',
    },
  ];
  const CONFIGURATION_ORDER = [
    {
      id: CONFIGURATION.EXCLUDE_WEEKENDS,
      text: 'Exclude Weekends in Calculation',
    },
  ];
  const OPERATIONS_ORDER = [
    {
      id: OPERATION_TYPES.SUM,
      text: 'Sum (+)',
      val: 'Sum (+)',
    },
    {
      id: OPERATION_TYPES.DIFFERENCE,
      text: 'Difference (-)',
      val: 'Difference (-)',
    },
  ];
  const DATE_VALUE_OPTIONS_ORDER = [
    {
      id: DATE_VALUE_OPTIONS.CREATED_DATE,
      text: 'Created Date',
      val: 'Created Date',
    },
  ];
  const ADDITIONS_VALUE_ORDER = [
    {
      id: ADDITION_VALUE_OPTIONS.DAYS,
      text: 'Custom Value',
      val: 'Custom Value',
    },
  ];
  const DATE_UNIT_OPTIONS_ORDER = [
    {
      id: DATE_UNIT_OPTIONS.DAY,
      text: 'Day',
      val: 'Day',
    },
    {
      id: DATE_UNIT_OPTIONS.MONTH,
      text: 'Month',
      val: 'Month',
    },
    {
      id: DATE_UNIT_OPTIONS.YEAR,
      text: 'Year',
      val: 'Year',
    },
  ];
  const DATE_TIME_UNIT_OPTIONS_ORDER = [
    {
      id: DATE_TIME_UNIT_OPTIONS.MINUTE,
      text: 'Minute',
      val: 'Minute',
    },
    {
      id: DATE_TIME_UNIT_OPTIONS.HOUR,
      text: 'Hour',
      val: 'Hour',
    },
    ...DATE_UNIT_OPTIONS_ORDER,
  ];
  return {
    CALCULATE_DATE_CONFIGS_ORDER,
    CONFIGURATION_ORDER,
    OPERATIONS_ORDER,
    DATE_VALUE_OPTIONS_ORDER,
    ADDITIONS_VALUE_ORDER,
    DATE_VALUE_OPTIONS,
    ADDITION_VALUE_OPTIONS,
    OPERATION_TYPES,
    CONFIGURATION,
    CONFIG_FIELDS,
    DATE_UNIT_OPTIONS_ORDER,
    DATE_UNIT_OPTIONS,
    DATE_TIME_UNIT_OPTIONS_ORDER,
    DATE_TIME_UNIT_OPTIONS,
  };
};

const MINI_APPS_TABLE_LINKS = () => {
  const TRIGGER_CONDITIONS = {
    EQUAL: 'EQUAL',
    NOT_EQUAL: 'NOT_EQUAL',
    GREATER_THAN: 'GREATER_THAN',
    LESS_THAN: 'LESS_THAN',
    GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
    LESS_THAN_OR_EQUAL: 'LESS_THAN_OR_EQUAL',
    IS_EMPTY: 'IS_EMPTY',
    ANY_VALUE: 'ANY_VALUE',
  };

  const TRIGGER_CONDITION_CONFIG = {
    ALL_SHOULD_BE_TRUE: 'AND',
    ANY_ONE_CONDITION_SHOULD_BE_TRUE: 'OR',
  };

  const ALLOWED_CONDITION_FIELD_TYPES = [
    FIELD_TYPE_ID.TEXT,
    FIELD_TYPE_ID.NUMBER,
    FIELD_TYPE_ID.RUPEE,
    FIELD_TYPE_ID.FORMULA,
    FIELD_TYPE_ID.STRING_FORMULA,
    FIELD_TYPE_ID.DATE,
    FIELD_TYPE_ID.SWITCH,
    FIELD_TYPE_ID.TIME,
    FIELD_TYPE_ID.CHECKBOX,
    FIELD_TYPE_ID.SELECT_POP_UP,
    FIELD_TYPE_ID.CONTACT,
    FIELD_TYPE_ID.UNIT,
    FIELD_TYPE_ID.LABEL,
    FIELD_TYPE_ID.BARCODE,
    FIELD_TYPE_ID.ASSIGN_TASK,
    FIELD_TYPE_ID.CREATED_INFO,
    FIELD_TYPE_ID.EMAIL,
    FIELD_TYPE_ID.AUTO_INCREMENT_ID,
    FIELD_TYPE_ID.DATE_TIME,
    FIELD_TYPE_ID.NO_OF_DAYS,
    FIELD_TYPE_ID.OTP,
  ];

  const NUMERIC_FIELD_TYPES = [
    FIELD_TYPE_ID.NUMBER,
    FIELD_TYPE_ID.RUPEE,
    FIELD_TYPE_ID.FORMULA,
    FIELD_TYPE_ID.DATE,
    FIELD_TYPE_ID.DATE_TIME,
    FIELD_TYPE_ID.TIME,
    FIELD_TYPE_ID.UNIT,
    FIELD_TYPE_ID.NO_OF_DAYS,
  ];

  const TRIGGER_CONDITION_PROPERTIES = {
    CONFIG: [
      {
        id: TRIGGER_CONDITION_CONFIG.ALL_SHOULD_BE_TRUE,
        text: 'All should be true',
      },
      {
        id: TRIGGER_CONDITION_CONFIG.ANY_ONE_CONDITION_SHOULD_BE_TRUE,
        text: 'Any one condition should be true',
      },
    ],
    CONDITION: [
      {
        id: TRIGGER_CONDITIONS.EQUAL,
        fieldTypes: ALLOWED_CONDITION_FIELD_TYPES,
        text: 'Equal',
      },
      {
        id: TRIGGER_CONDITIONS.NOT_EQUAL,
        fieldTypes: ALLOWED_CONDITION_FIELD_TYPES,
        text: 'Not Equal',
      },
      {
        id: TRIGGER_CONDITIONS.GREATER_THAN,
        fieldTypes: NUMERIC_FIELD_TYPES,
        filterForActions: true,
        text: 'Greater Than',
      },
      {
        id: TRIGGER_CONDITIONS.LESS_THAN,
        fieldTypes: NUMERIC_FIELD_TYPES,
        filterForActions: true,
        text: 'Less Than',
      },
      {
        id: TRIGGER_CONDITIONS.GREATER_THAN_OR_EQUAL,
        fieldTypes: NUMERIC_FIELD_TYPES,
        filterForActions: true,
        text: 'Greater than or Equal',
      },
      {
        id: TRIGGER_CONDITIONS.LESS_THAN_OR_EQUAL,
        fieldTypes: NUMERIC_FIELD_TYPES,
        filterForActions: true,
        text: 'Less than or Equal',
      },
      {
        id: TRIGGER_CONDITIONS.IS_EMPTY,
        fieldTypes: ALLOWED_CONDITION_FIELD_TYPES,
        text: 'Is Empty',
      },
      {
        id: TRIGGER_CONDITIONS.ANY_VALUE,
        fieldTypes: ALLOWED_CONDITION_FIELD_TYPES,
        text: 'Any Value',
      },
    ],
  };

  return {
    TRIGGER_CONDITION_CONFIG,
    TRIGGER_CONDITION_PROPERTIES,
  };
};

const MINI_APPS_AUTOMATION = () => {
  const TRIGGER_TYPES = {
    MANUAL: 'MANUAL',
    ROW_NEW_ENTRY: 'ROW_NEW_ENTRY',
    ROW_EDIT_ENTRY: 'ROW_EDIT_ENTRY',
    CELL_STATUS_CHANGE: 'CELL_STATUS_CHANGE',
    ON_ASSIGNEE_CHANGE: 'ON_ASSIGNEE_CHANGE',
    ROW_DELETE_ENTRY: 'ROW_DELETE_ENTRY',
    ON_SPECIFIC_DATE: 'ON_SPECIFIC_DATE',
    ON_USER_APP_ACCESS: 'ON_USER_APP_ACCESS',
    // ON_NEW_COMMENT: 'ON_NEW_COMMENT',
  };

  const TRIGGER_CONFIGS = {
    AUTOMATION_NAME: 'AUTOMATION_NAME',
    CUSTOM_NOTIFICATION_TEXT: 'CUSTOM_NOTIFICATION_TEXT',
    TRIGGER_TYPE: 'TRIGGER_TYPE',
    ON_DATABASE: 'ON_DATABASE',
    COLUMN: 'COLUMN',
    ASSIGNEE_CHANGE_COLUMN: 'ASSIGNEE_CHANGE_COLUMN',
    VALUE: 'VALUE',
    ON_DATE: 'ON_DATE', // { id: 'default_colA', val: 'Phone Column' fieldType: DATE },
    AUTOMATION_TYPE: 'AUTOMATION_TYPE', // CELL_STATUS_CHANGE

    // Whatsapp
    PHONE_COLUMN: 'PHONE_COLUMN', // { id: 'default_colA', val: 'Phone Column', fieldType: CONTACT / ASSIGNEE },
    WHATSAPP_TEMPLATE: 'WHATSAPP_TEMPLATE', // currently non - just for rendering

    // Notification
    NOTIFICATION_USER_TYPE: 'NOTIFICATION_USER_TYPE',
    NOTIFICATION_USER_TYPE_CONFIGURE: 'NOTIFICATION_USER_TYPE_CONFIGURE',
    NOTIFICATION_CUSTOM_MESSAGE: 'NOTIFICATION_CUSTOM_MESSAGE',

    // COPY ROW / MOVE ROW / SET_VALUE_BY_FILTER
    TO_DATABASE: 'TO_DATABASE',
    // ADDITIONAL SOURCES FOR
    // COPY ROW / MOVE ROW / SET_VALUE_BY_FILTER
    ADDITIONAL_DATA_SOURCE: 'ADDITIONAL_DATA_SOURCE',
    ADDITIONAL_DATA_SOURCE_LIMIT: 'ADDITIONAL_DATA_SOURCE_LIMIT',
    ADDITIONAL_DATA_SOURCE_CUSTOM_SORTING:
      'ADDITIONAL_DATA_SOURCE_CUSTOM_SORTING',
    ADDITIONAL_DATA_SOURCE_CONDITIONS: 'ADDITIONAL_DATA_SOURCE_CONDITIONS',
    AGGREGATION_DATA: 'AGGREGATION_DATA',
    ENDPOINT_DATA: 'ENDPOINT_DATA',

    // Copy / Move Row
    COPY_MOVE_ROW_COLUMN_MAPPING: 'COPY_MOVE_ROW_COLUMN_MAPPING',
    ROW_DEFAULT_VALUE_MAPPING: 'ROW_DEFAULT_VALUE_MAPPING',

    // Copy / Move Row
    SET_CONDITION: 'SET_CONDITION',
    FIELDS_FOR_SET_VALUE: 'FIELDS_FOR_SET_VALUE',
    VALUES_FOR_SELECTED_SET_VALUE_FIELDS:
      'VALUES_FOR_SELECTED_SET_VALUE_FIELDS',
    // SET_VALUE_BY_FILTER
    TO_DOC_OPERATION_INSERT_OR_UPDATE: 'TO_DOC_OPERATION_INSERT_OR_UPDATE',
    UPDATE_ROWS_ON_UNIQUE_FAIL: 'UPDATE_ROWS_ON_UNIQUE_FAIL',

    //Edit Row (Entry Modified)
    SET_EDIT_ENTRY_CONDITION: 'SET_EDIT_ENTRY_CONDITION',
    SET_EDIT_ENTRY_CONDITIONAL_COLUMN_IDS:
      'SET_EDIT_ENTRY_CONDITIONAL_COLUMN_IDS',
    SET_EDIT_ENTRY_CUSTOM_CONDITIONS: 'SET_EDIT_ENTRY_CUSTOM_CONDITIONS',

    EMPTY_VALUES_ALLOWED: 'EMPTY_VALUES_ALLOWED',
    SET_CUSTOM_CONDITIONS_TRIGGER: 'SET_CUSTOM_CONDITIONS_TRIGGER',

    // Import
    IMPORT_CONDITIONS_CONDITIONS: 'IMPORT_CONDITIONS_CONDITIONS',

    // Email
    SENDER_EMAIL: 'SENDER_EMAIL',
    RECIPIENTS_EMAIL: 'RECIPIENTS_EMAIL',
    EMAIL_CONTENT: 'EMAIL_CONTENT',

    //SubContents for EMAIL_CONTENT

    EMAIL_SUBJECT: 'EMAIL_SUBJECT',
    EMAIL_BODY: 'EMAIL_BODY',
    ATTACHMENT: 'ATTACHMENT',
    CUSTOM_ATTACHMENT: 'CUSTOM_ATTACHMENT',

    //End of SubContents for EMAIL_CONTENT

    RECIPIENTS_EMAIL_COLUMNS: 'RECIPIENTS_EMAIL_COLUMNS',
    RECIPIENTS_EMAIL_CONFIGURE_ROLES: 'RECIPIENTS_EMAIL_CONFIGURE_ROLES',
    RECIPIENTS_EMAIL_USERS_LIST: 'RECIPIENTS_EMAIL_USERS_LIST',

    CC_EMAIL: 'CC_EMAIL',
    CC_EMAIL_COLUMNS: 'CC_EMAIL_COLUMNS',
    CC_EMAIL_CONFIGURE_ROLES: 'CC_EMAIL_CONFIGURE_ROLES',
    CC_USERLIST: 'CC_USERLIST',

    ROW_LINK_CUSTOM_TEXT: 'ROW_LINK_CUSTOM_TEXT',
    ROW_LINK_SCREEN_ID: 'ROW_LINK_SCREEN_ID',
  };

  const TRIGGER_CONDITION_CONFIG = {
    ALL_SHOULD_BE_TRUE: 'AND',
    ANY_ONE_CONDITION_SHOULD_BE_TRUE: 'OR',
  };

  const TRIGGER_CONDITIONS = {
    EQUAL: 'EQUAL',
    NOT_EQUAL: 'NOT_EQUAL',
    GREATER_THAN: 'GREATER_THAN',
    LESS_THAN: 'LESS_THAN',
    GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
    LESS_THAN_OR_EQUAL: 'LESS_THAN_OR_EQUAL',
    IS_EMPTY: 'IS_EMPTY',
    ANY_VALUE: 'ANY_VALUE',
    UPTO_FIFTY_METERS: 'UPTO_FIFTY_METERS',
    UPTO_HUNDERED_METERS: 'UPTO_HUNDERED_METERS',
    UPTO_TO_THREEHUNDERED_METERS: 'UPTO_TO_THREEHUNDERED_METERS',
  };

  const NAME_FIELD = {
    id: TRIGGER_CONFIGS.AUTOMATION_NAME,
    text: 'Name',
  };

  const CUSTOM_NOTIFICATION_TEXT_FIELD = {
    id: TRIGGER_CONFIGS.CUSTOM_NOTIFICATION_TEXT,
    text: 'Custom Notification Text',
  };

  const COMMON_CONFIG_FIELDS_NOT_DISPLAY = [
    {
      id: TRIGGER_CONFIGS.TRIGGER_TYPE,
      text: 'Every Time When',
    },
  ];

  const COMMON_CONFIG_FIELDS = [
    ...COMMON_CONFIG_FIELDS_NOT_DISPLAY,
    {
      id: TRIGGER_CONFIGS.ON_DATABASE,
      text: 'On Database',
    },
    CUSTOM_NOTIFICATION_TEXT_FIELD,
  ];

  const COMMON_CUSTOM_FIELDS = [
    {
      id: TRIGGER_CONFIGS.SET_CUSTOM_CONDITIONS_TRIGGER,
      text: 'Set Condition (Optional)',
    },
  ];
  const EDIT_TRIGGER_TYPES = {
    ANY: 'ANY',
    ALL_SELECTED: 'ALL_SELECTED',
    ANY_ONE_OF_SELECTED: 'ANY_ONE_OF_SELECTED',
    CUSTOM_VALUE: 'CUSTOM_VALUE',
  };
  const TRIGGER_TYPE_PROPERTIES = {
    [TRIGGER_TYPES.MANUAL]: {
      id: TRIGGER_TYPES.MANUAL,
      text: 'Manual Trigger',
      subText: 'triggered manually',
      CONFIG_FIELDS: [
        ...COMMON_CONFIG_FIELDS,
        NAME_FIELD,
        ...COMMON_CUSTOM_FIELDS,
      ],
    },
    [TRIGGER_TYPES.ROW_NEW_ENTRY]: {
      id: TRIGGER_TYPES.ROW_NEW_ENTRY,
      text: 'New Entry is added',
      subText: 'a new entry is added to the database',
      CONFIG_FIELDS: [
        ...COMMON_CONFIG_FIELDS,
        NAME_FIELD,
        ...COMMON_CUSTOM_FIELDS,
      ],
    },
    [TRIGGER_TYPES.ON_USER_APP_ACCESS]: {
      id: TRIGGER_TYPES.ON_USER_APP_ACCESS,
      text: 'New User Is Added',
      subText: 'a user is given access to app',
      CONFIG_FIELDS: [...COMMON_CONFIG_FIELDS_NOT_DISPLAY, NAME_FIELD],
    },
    [TRIGGER_TYPES.CELL_STATUS_CHANGE]: {
      id: TRIGGER_TYPES.CELL_STATUS_CHANGE,
      text: 'Status column in Changed',
      subText: 'a status is changed to a specific value',
      CONFIG_FIELDS: [
        ...COMMON_CONFIG_FIELDS,
        {
          id: TRIGGER_CONFIGS.COLUMN,
          text: 'Column',
        },
        {
          id: TRIGGER_CONFIGS.VALUE,
          text: 'Value',
        },
        NAME_FIELD,
        ...COMMON_CUSTOM_FIELDS,
      ],
    },
    [TRIGGER_TYPES.ON_ASSIGNEE_CHANGE]: {
      id: TRIGGER_TYPES.ON_ASSIGNEE_CHANGE,
      text: 'Assignee Column Modified',
      subText: 'a assignee is changed of a specific column',
      CONFIG_FIELDS: [
        ...COMMON_CONFIG_FIELDS,
        {
          id: TRIGGER_CONFIGS.ASSIGNEE_CHANGE_COLUMN,
          allowedTypes: [FIELD_TYPE_ID.ASSIGN_TASK],
          text: 'Assignee Column',
        },
        NAME_FIELD,
        ...COMMON_CUSTOM_FIELDS,
      ],
    },
    [TRIGGER_TYPES.ON_SPECIFIC_DATE]: {
      id: TRIGGER_TYPES.ON_SPECIFIC_DATE,
      text: 'On Specific Date',
      subText: 'a specified date of a specific column',
      CONFIG_FIELDS: [
        ...COMMON_CONFIG_FIELDS,
        {
          id: TRIGGER_CONFIGS.ON_DATE,
          allowedTypes: [FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.DATE_TIME],
          text: 'On Date',
        },
        NAME_FIELD,
        ...COMMON_CUSTOM_FIELDS,
      ],
    },
    [TRIGGER_TYPES.ROW_EDIT_ENTRY]: {
      id: TRIGGER_TYPES.ROW_EDIT_ENTRY,
      text: 'Entry is modified',
      subText: 'an entry is modified in the database',
      CONFIG_FIELDS: [
        ...COMMON_CONFIG_FIELDS,
        NAME_FIELD,
        {id: TRIGGER_CONFIGS.SET_EDIT_ENTRY_CONDITION, text: 'Condition'},
        {
          id: TRIGGER_CONFIGS.SET_EDIT_ENTRY_CONDITIONAL_COLUMN_IDS,
          allowedTypes: [FIELD_TYPE_ID.DATE],
          text: 'Columns',
        },
        {
          id: TRIGGER_CONFIGS.EMPTY_VALUES_ALLOWED,
          text: 'Run when value is entered for first time.',
        },
        {
          id: TRIGGER_CONFIGS.SET_EDIT_ENTRY_CUSTOM_CONDITIONS,
          text: 'Set Condition',
        },
      ],
      EDIT_TRIGGER_TYPES,
      EDIT_ENTRY_CONDITION_OPTIONS: [
        {
          id: EDIT_TRIGGER_TYPES.ANY,
          text: 'Any Edit or Update',
        },
        {
          id: EDIT_TRIGGER_TYPES.ALL_SELECTED,
          text: 'Edit on Selected Columns',
        },
        {
          id: EDIT_TRIGGER_TYPES.ANY_ONE_OF_SELECTED,
          text: 'Edit on any Selected Columns',
        },
        {
          id: EDIT_TRIGGER_TYPES.CUSTOM_VALUE,
          text: 'Custom',
        },
      ],
    },
    [TRIGGER_TYPES.ROW_DELETE_ENTRY]: {
      id: TRIGGER_TYPES.ROW_DELETE_ENTRY,
      text: 'Entry is deleted',
      subText: 'an entry is deleted from the database',
      CONFIG_FIELDS: [
        ...COMMON_CONFIG_FIELDS,
        NAME_FIELD,
        ...COMMON_CUSTOM_FIELDS,
      ],
    },
  };
  const ALLOWED_CONDITION_FIELD_TYPES = [
    FIELD_TYPE_ID.TEXT,
    FIELD_TYPE_ID.NUMBER,
    FIELD_TYPE_ID.RUPEE,
    FIELD_TYPE_ID.FORMULA,
    FIELD_TYPE_ID.DATE,
    FIELD_TYPE_ID.SWITCH,
    FIELD_TYPE_ID.TIME,
    FIELD_TYPE_ID.CHECKBOX,
    FIELD_TYPE_ID.SELECT_POP_UP,
    FIELD_TYPE_ID.CONTACT,
    FIELD_TYPE_ID.UNIT,
    FIELD_TYPE_ID.LABEL,
    FIELD_TYPE_ID.BARCODE,
    FIELD_TYPE_ID.ASSIGN_TASK,
    FIELD_TYPE_ID.CREATED_INFO,
    FIELD_TYPE_ID.EMAIL,
    FIELD_TYPE_ID.AUTO_INCREMENT_ID,
    FIELD_TYPE_ID.DATE_TIME,
    FIELD_TYPE_ID.NO_OF_DAYS,
    FIELD_TYPE_ID.STRING_FORMULA,
    FIELD_TYPE_ID.OTP,
  ];
  const NUMERIC_FIELD_TYPES = [
    FIELD_TYPE_ID.NUMBER,
    FIELD_TYPE_ID.RUPEE,
    FIELD_TYPE_ID.FORMULA,
    FIELD_TYPE_ID.DATE,
    FIELD_TYPE_ID.DATE_TIME,
    FIELD_TYPE_ID.TIME,
    FIELD_TYPE_ID.UNIT,
    FIELD_TYPE_ID.NO_OF_DAYS,
  ];
  const ATTACHMENT_FIELD_TYPES = [
    FIELD_TYPE_ID.IMAGE,
    FIELD_TYPE_ID.DOCUMENT,
    FIELD_TYPE_ID.AUDIO,
    FIELD_TYPE_ID.VIDEO,
  ];

  const HIDE_VALUE_FIELD_FOR_CONDITIONS = [
    TRIGGER_CONDITIONS.IS_EMPTY,
    TRIGGER_CONDITIONS.ANY_VALUE,
  ];

  const TRIGGER_CONDITION_PROPERTIES = {
    CONFIG: [
      {
        id: TRIGGER_CONDITION_CONFIG.ALL_SHOULD_BE_TRUE,
        text: 'All should be true',
      },
      {
        id: TRIGGER_CONDITION_CONFIG.ANY_ONE_CONDITION_SHOULD_BE_TRUE,
        text: 'Any one condition should be true',
      },
    ],
    CONDITION: [
      {
        id: TRIGGER_CONDITIONS.EQUAL,
        fieldTypes: ALLOWED_CONDITION_FIELD_TYPES,
        text: 'Equal',
      },
      {
        id: TRIGGER_CONDITIONS.NOT_EQUAL,
        fieldTypes: ALLOWED_CONDITION_FIELD_TYPES,
        text: 'Not Equal',
      },
      {
        id: TRIGGER_CONDITIONS.GREATER_THAN,
        fieldTypes: NUMERIC_FIELD_TYPES,
        filterForActions: true,
        text: 'Greater Than',
      },
      {
        id: TRIGGER_CONDITIONS.LESS_THAN,
        fieldTypes: NUMERIC_FIELD_TYPES,
        filterForActions: true,
        text: 'Less Than',
      },
      {
        id: TRIGGER_CONDITIONS.GREATER_THAN_OR_EQUAL,
        fieldTypes: NUMERIC_FIELD_TYPES,
        filterForActions: true,
        text: 'Greater than or Equal',
      },
      {
        id: TRIGGER_CONDITIONS.LESS_THAN_OR_EQUAL,
        fieldTypes: NUMERIC_FIELD_TYPES,
        filterForActions: true,
        text: 'Less than or Equal',
      },
      {
        id: TRIGGER_CONDITIONS.IS_EMPTY,
        fieldTypes: [
          ...ALLOWED_CONDITION_FIELD_TYPES,
          ...ATTACHMENT_FIELD_TYPES,
        ],
        excludeForFieldValidation: true,
        text: 'Is Empty',
      },
      {
        id: TRIGGER_CONDITIONS.ANY_VALUE,
        fieldTypes: [
          ...ALLOWED_CONDITION_FIELD_TYPES,
          ...ATTACHMENT_FIELD_TYPES,
        ],
        excludeForFieldValidation: true,
        text: 'Any Value',
      },
      {
        id: TRIGGER_CONDITIONS.UPTO_FIFTY_METERS,
        fieldTypes: [FIELD_TYPE_ID.MAPS],
        text: 'Upto 50 meters',
        onlyFieldValidation: true,
      },
      {
        id: TRIGGER_CONDITIONS.UPTO_HUNDERED_METERS,
        fieldTypes: [FIELD_TYPE_ID.MAPS],
        text: 'Upto 100 meters',
        onlyFieldValidation: true,
      },
      {
        id: TRIGGER_CONDITIONS.UPTO_TO_THREEHUNDERED_METERS,
        fieldTypes: [FIELD_TYPE_ID.MAPS],
        text: 'Upto 300 meters',
        onlyFieldValidation: true,
      },
    ],
    ALLOWED_FIELDTYPES: [
      // check fieldType or if fieldType is TABLE, check subType
      FIELD_TYPE_ID.TEXT,
      FIELD_TYPE_ID.NUMBER,
      FIELD_TYPE_ID.RUPEE,
      FIELD_TYPE_ID.FORMULA,
      FIELD_TYPE_ID.STRING_FORMULA,
      FIELD_TYPE_ID.DATE,
      FIELD_TYPE_ID.SWITCH,
      FIELD_TYPE_ID.TIME,
      FIELD_TYPE_ID.CHECKBOX,
      FIELD_TYPE_ID.SELECT_POP_UP,
      FIELD_TYPE_ID.CONTACT,
      FIELD_TYPE_ID.REMINDER,
      FIELD_TYPE_ID.UNIT,
      FIELD_TYPE_ID.MAPS,
      FIELD_TYPE_ID.LABEL,
      FIELD_TYPE_ID.BARCODE,
      FIELD_TYPE_ID.ASSIGN_TASK,
      FIELD_TYPE_ID.URL,
      FIELD_TYPE_ID.CREATED_INFO,
      FIELD_TYPE_ID.EMAIL,
      FIELD_TYPE_ID.AUTO_INCREMENT_ID,
      FIELD_TYPE_ID.IMAGE,
      FIELD_TYPE_ID.VIDEO,
      FIELD_TYPE_ID.AUDIO,
      FIELD_TYPE_ID.DOCUMENT,
      FIELD_TYPE_ID.DATE_TIME,
      FIELD_TYPE_ID.NO_OF_DAYS,
      FIELD_TYPE_ID.OTP,
    ],
    CUSTOM_VALUE_NOT_ALLOWED_FIELDTYPES: [
      FIELD_TYPE_ID.TABLE,
      FIELD_TYPE_ID.FORMULA,
      FIELD_TYPE_ID.STRING_FORMULA,
      FIELD_TYPE_ID.BARCODE,
      FIELD_TYPE_ID.EMAIL,
      FIELD_TYPE_ID.URL,
      FIELD_TYPE_ID.DATE_TIME,
      FIELD_TYPE_ID.ASSIGN_TASK,
      FIELD_TYPE_ID.CREATED_INFO,
      FIELD_TYPE_ID.REMINDER,
      FIELD_TYPE_ID.IMAGE,
      FIELD_TYPE_ID.DOCUMENT,
      FIELD_TYPE_ID.COMMENT,
      FIELD_TYPE_ID.AUDIO,
      FIELD_TYPE_ID.VIDEO,
      FIELD_TYPE_ID.USER_COLUMN,
      FIELD_TYPE_ID.LIST,
      FIELD_TYPE_ID.PDF,
    ],
    VALUE_NOT_REQUIRED_CONDITIONS: HIDE_VALUE_FIELD_FOR_CONDITIONS,
  };

  return {
    TRIGGER_TYPES,
    TRIGGER_CONFIGS,
    TRIGGER_TYPE_PROPERTIES,
    TRIGGER_CONDITION_PROPERTIES,
    HIDE_VALUE_FIELD_FOR_CONDITIONS,
    TRIGGER_CONDITIONS,
    TRIGGER_CONDITION_CONFIG,
  };
};

export const REPORT_SECTION_TYPE_ID = {
  N_COLUMNS: 'A-5',
  N_COLUMNS_WITHOUT_SPLIT: 'A-4',
  N_COLUMNS_WITH_MULTI_SPLIT: 'E-1',
  CASH_IN_AND_OUT: 'OC-2',
};

export const MINI_APPS = {
  MINI_APPS_FIELDS,
  MINI_APPS_FIELDS_INVERSE: (() => {
    const res = {};
    Object.keys(MINI_APPS_FIELDS).forEach((key) => {
      res[MINI_APPS_FIELDS[key]] = key;
    });
    return res;
  })(),
  LAYOUTS: MINI_APPS_LAYOUTS,
  DEFAULT_LAYOUT_SELECTION: MINI_APPS_LAYOUTS.LIST,
  BOTTOM_BAR_SCREENS_LIMIT: 4,
  DEFAULT_EMPTY_VALUE: '', //when no column is mapped
  DEFAULT_EMPTY_OBJECT: {},
  DEFAULT_EMPTY_ARRAY: [],
  DEFAULT_EMPTY_CELL_VALUE: '-', //when column is mapped but cell is empty
  LAYOUTS_ORDER: [
    {
      id: MINI_APPS_LAYOUTS.LIST,
      text: 'List',
    },
    {
      id: MINI_APPS_LAYOUTS.COMPACT,
      text: 'Compact',
    },
    {
      id: MINI_APPS_LAYOUTS.CATALOG,
      text: 'Catalog',
    },
    {
      id: MINI_APPS_LAYOUTS.CONTACT_CARD,
      text: 'Contact Card',
    },
    {
      id: MINI_APPS_LAYOUTS.DEFAULT,
      text: 'Default',
    },
  ],
  LAYOUT_FIELDS: {
    [MINI_APPS_LAYOUTS.DEFAULT]: [
      MINI_APPS_FIELDS.headerA,
      MINI_APPS_FIELDS.headerB,
      MINI_APPS_FIELDS.title,
      MINI_APPS_FIELDS.subtitle,
      MINI_APPS_FIELDS.footerA,
      MINI_APPS_FIELDS.footerB,
      MINI_APPS_FIELDS.rowStatus,
    ],
    [MINI_APPS_LAYOUTS.CATALOG]: [
      MINI_APPS_FIELDS.title,
      MINI_APPS_FIELDS.footerA,
      MINI_APPS_FIELDS.imageA,
      MINI_APPS_FIELDS.catalog,
      MINI_APPS_FIELDS.catalogueVarient,
      MINI_APPS_FIELDS.rowStatus,
    ],
    [MINI_APPS_LAYOUTS.COMPACT]: [
      MINI_APPS_FIELDS.title,
      MINI_APPS_FIELDS.subtitle,
      MINI_APPS_FIELDS.footerA,
      MINI_APPS_FIELDS.rowStatus,
    ],
    [MINI_APPS_LAYOUTS.CONTACT_CARD]: [
      MINI_APPS_FIELDS.title,
      MINI_APPS_FIELDS.subtitle,
      MINI_APPS_FIELDS.footerA,
      MINI_APPS_FIELDS.imageA,
      MINI_APPS_FIELDS.rowStatus,
    ],
    [MINI_APPS_LAYOUTS.LIST]: [
      MINI_APPS_FIELDS.title,
      MINI_APPS_FIELDS.subtitle,
      MINI_APPS_FIELDS.rowStatus,
    ],
  },
  SPECIAL_FIELD_MAPPING: {
    [MINI_APPS_FIELDS.imageA]: FIELD_TYPE_ID.IMAGE,
  },
  CATALOGUE_CARD_VARIENT_OPTIONS: [
    {id: 1, title: '1 card in a row', val: '1 card in a row'},
    {id: 3, title: '3 cards in a row', val: '3 cards in a row'},
  ],
  SCREEN_VIEW_LAYOUTS: SCREEN_VIEW_LAYOUTS(),
  AUTOMATION: MINI_APPS_AUTOMATION(),
  TABLE_LINKS: MINI_APPS_TABLE_LINKS(),
  ALLOWED_FIELD_TYPES: [
    FIELD_TYPE_ID.TEXT,
    FIELD_TYPE_ID.COMMENT,
    FIELD_TYPE_ID.NUMBER,
    FIELD_TYPE_ID.RUPEE,
    FIELD_TYPE_ID.FORMULA,
    FIELD_TYPE_ID.STRING_FORMULA,
    FIELD_TYPE_ID.DATE,
    FIELD_TYPE_ID.SWITCH,
    FIELD_TYPE_ID.TIME,
    FIELD_TYPE_ID.CHECKBOX,
    FIELD_TYPE_ID.SELECT_POP_UP,
    FIELD_TYPE_ID.IMAGE,
    FIELD_TYPE_ID.CONTACT,
    FIELD_TYPE_ID.UNIT,
    FIELD_TYPE_ID.MAPS,
    FIELD_TYPE_ID.LABEL,
    FIELD_TYPE_ID.BARCODE,
    FIELD_TYPE_ID.TABLE,
    FIELD_TYPE_ID.ASSIGN_TASK,
    FIELD_TYPE_ID.URL,
    FIELD_TYPE_ID.CREATED_INFO,
    FIELD_TYPE_ID.EMAIL,
    FIELD_TYPE_ID.VIDEO,
    FIELD_TYPE_ID.USER_COLUMN,
    FIELD_TYPE_ID.DATE_TIME,
    FIELD_TYPE_ID.AUTO_INCREMENT_ID,
    FIELD_TYPE_ID.NO_OF_DAYS,
    FIELD_TYPE_ID.OTP,
    // FIELD_TYPE_ID.DOCUMENT,
    // FIELD_TYPE_ID.REMINDER,
    // FIELD_TYPE_ID.AUDIO,
  ],

  DATE_RECURRING_TYPES: {
    [MINI_APPS_DATE_RECURRING_TYPES.DOES_NOT_REPEAT]: {
      id: MINI_APPS_DATE_RECURRING_TYPES.DOES_NOT_REPEAT,
      displayName: `Doesn't Repeat`,
    },
    [MINI_APPS_DATE_RECURRING_TYPES.DAILY]: {
      id: MINI_APPS_DATE_RECURRING_TYPES.DAILY,
      displayName: 'Daily',
    },
    [MINI_APPS_DATE_RECURRING_TYPES.WEEKLY]: {
      id: MINI_APPS_DATE_RECURRING_TYPES.WEEKLY,
      displayName: 'Weekly',
    },
    [MINI_APPS_DATE_RECURRING_TYPES.MONTHLY]: {
      id: MINI_APPS_DATE_RECURRING_TYPES.MONTHLY,
      displayName: 'Monthly',
    },
    [MINI_APPS_DATE_RECURRING_TYPES.YEARLY]: {
      id: MINI_APPS_DATE_RECURRING_TYPES.YEARLY,
      displayName: 'Yearly',
    },
  },
  DATE_TIME_ENTRY_TYPES: {
    [MINI_APPS_DATE_TIME_ENTRY.DATE_TIME_ENTRY_CURRENT_ONLY]: {
      id: MINI_APPS_DATE_TIME_ENTRY.DATE_TIME_ENTRY_CURRENT_ONLY,
      displayName: `Only this record`,
    },
    [MINI_APPS_DATE_TIME_ENTRY.DATE_TIME_ENTRY_CURRENT_FOLLOWING]: {
      id: MINI_APPS_DATE_TIME_ENTRY.DATE_TIME_ENTRY_CURRENT_FOLLOWING,
      displayName: `This and following day records`,
    },
    [MINI_APPS_DATE_TIME_ENTRY.DATE_TIME_ENTRY_ALL_ENTRIES]: {
      id: MINI_APPS_DATE_TIME_ENTRY.DATE_TIME_ENTRY_ALL_ENTRIES,
      displayName: `All Records`,
    },
  },
  SCREEN_TYPE: MINI_APPS_SCREEN_TYPE,
  DOC_BASED_SCREEN_TYPES: [
    MINI_APPS_SCREEN_TYPE.DOCUMENT_DATA,
    MINI_APPS_SCREEN_TYPE.FILTERED_DATA,
  ],
  MINI_APPS_SHARE_PERMISSION_TYPE: {
    ADMIN: 'ADMIN',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
    OWNER: 'OWNER',
    CUSTOM_ROLE: 'CUSTOM_ROLE',
  },
  RESTRICTED_ROLES_NAMES: ['ADMIN', 'EDIT', 'VIEW', 'OWNER', 'CUSTOM ROLE'],
  SHARING_RESTRICTIONS: {
    VIEW: 'VIEW',
    HIDE: 'HIDE',
  },
  CUSTOM_ENTRY_ACCESS: {
    CAN_ADD_DATA: 'CAN_ADD_DATA',
    CANNOT_ADD_DATA: 'CANNOT_ADD_DATA',
  },
  CUSTOM_ENTRY_PROPERTY: {
    CAN_EDIT_DATA: 'CAN_EDIT_DATA',
    CANNOT_EDIT_DATA: 'CANNOT_EDIT_DATA',
    CAN_EDIT_END_OF_DAY: 'CAN_EDIT_END_OF_DAY',
    CAN_EDIT_MISSING_VALUES: 'CAN_EDIT_MISSING_VALUES',
  },
  CUSTOM_VIEW_ACCESS: {
    ALL_DATA: 'ALL_DATA',
    DATA_ADDED_BY_USER: DATA_FILTERS.DATA_ADDED_BY_USER,
    DATA_ASSIGNED_TO_USER: DATA_FILTERS.DATA_ASSIGNED_TO_USER,
  },
  CUSTOM_DELETE_ROW_ACCESS: {
    CAN_DELETE_DATA: 'CAN_DELETE_DATA',
    CANNOT_DELETE_DATA: 'CANNOT_DELETE_DATA',
  },
  CUSTOM_IMPORT_EXPORT_PROPERTY: {
    CAN_IMPORT_DATA: 'CAN_IMPORT_DATA',
    CAN_EXPORT_DATA: 'CAN_EXPORT_DATA',
  },
  MAPPING_CONFIG: {
    LABEL_VALUE_OPTION: {
      NULL: 'NULL',
      COLUMN_NAME: 'COLUMN_NAME',
      CUSTOM_VALUE: 'CUSTOM_VALUE',
    },
    MAX_CUSTOM_LABEL_LENGTH: 18,
  },
  SORT_BY_SELECTABLES_MAX: 1,
  ADD_ENTRY_BUTTON_CONFIGS: {
    ENTRY_BUTTON_TEXT_LENGTH: 12, //Custom Entry Button Text Length
    ENTRY_CONFIGS: {
      DISABLE_ENTRY: 'disableEntry',
      DISABLE_EDIT: 'disableEdit',
    },
    ENTRY_CONFIGS_ORDER: [
      {
        id: 'DISABLE_ENTRY',
        text: 'Disable Entry',
      },
      {
        id: 'DISABLE_EDIT',
        text: 'Disable Edit',
      },
    ],
  },
  ENTRY_BUTTON_TEXT_LENGTH: 12, //Custom Entry Button Text Length

  CALCULATE_DATE: MINI_APPS_CALCULATE_DATE_FIELD_TYPE(),

  ACTION_BUTTONS: {
    ACTIONS: {
      CALL: 'CALL',
      MESSAGE: 'MESSAGE',
      WHATS_APP: 'WHATS_APP',
      SEND_EMAIL: 'SEND_EMAIL',
      OPEN_URL: 'OPEN_URL',
      COPY_URL: 'COPY_URL',
      PLAY_AUDIO: 'PLAY_AUDIO',
      WATCH_VIDEO: 'WATCH_VIDEO',
      VIEW_ATTACHMENTS: 'VIEW_ATTACHMENTS',
      GET_DIRECTION: 'GET_DIRECTION',
      VIEW_IMAGE: 'VIEW_IMAGE',
      OPEN_FORMS: 'OPEN_FORMS',
      OPEN_LIST_COLUMN: 'OPEN_LIST_COLUMN',
      RUN_AUTOMATION: 'RUN_AUTOMATION',
      DOWNLOAD_PDF: 'DOWNLOAD_PDF',
      ADD_COMMENTS: 'ADD_COMMENTS',
      VIEW_COMMENTS: 'VIEW_COMMENTS',
      WHATSAPP_WITH_DATA: 'WHATSAPP_WITH_DATA',
    },
    ACTION_LIFECYCLE: {
      ONCE_A_DAY: 'ONCE_A_DAY',
    },
    CONFIG_FIELDS: {
      ACTION_NAME: 'ACTION_NAME',
      ACTION_TYPE: 'ACTION_TYPE',
      ACTION_LIFE_CYCLE: 'ACTION_LIFE_CYCLE',
      BUTTON_COLOR: 'BUTTON_COLOR',
      CONFIGURE_FORMS: 'CONFIGURE_FORMS',
      CONFIGURE_AUTOMATION: 'CONFIGURE_AUTOMATION',
      CONFIGURE_VISIBILITY: 'CONFIGURE_VISIBILITY',
      CONFIGURE_OPEN_LIST: 'CONFIGURE_OPEN_LIST',
      CONFIGURE_DOWNLOAD_PDF: 'CONFIGURE_DOWNLOAD_PDF',
      CONFIGURE_DOWNLOAD_PDF_ADDITIONAL: 'CONFIGURE_DOWNLOAD_PDF_ADDITIONAL',
      CONFIGURE_WHATSAPP_MESSAGE: 'CONFIGURE_WHATSAPP_MESSAGE',
    },
    BTN_STATUS: {
      RUNNING: 'RUNNING',
      SUCCESS: 'SUCCESS',
      FAILED: 'FAILED',
    },
    RESTRICTIONS: {
      TRIGGER: null,
      HIDE: 'HIDE',
    },
    // use getColumnsForVisibilityCondition function
    // to get relavent columns for setting visibility condtion
    VISIBLE_FILTER_FIELDS: [
      ...PURE_NUMBER_FIELDS,
      FIELD_TYPE_ID.TEXT,
      FIELD_TYPE_ID.SELECT_POP_UP,
      FIELD_TYPE_ID.CONTACT,
      FIELD_TYPE_ID.MAPS,
      FIELD_TYPE_ID.BARCODE,
      FIELD_TYPE_ID.URL,
      FIELD_TYPE_ID.EMAIL,
      FIELD_TYPE_ID.AUTO_INCREMENT_ID,
      FIELD_TYPE_ID.ASSIGN_TASK,
    ],
    CUSTOM_ACTION_NAME_MAX_LENGTH: 15,
    CUSTOM_ACTION_PANEL_MAX_BUTTONS: 3,
    CUSTOM_ACTION_OPTIONS: [
      {
        id: 'OPEN_FORMS',
        text: 'Open Form',
      },
      {
        id: 'OPEN_LIST_COLUMN',
        text: 'Open List',
      },
      {
        id: 'DOWNLOAD_PDF',
        text: 'Download PDF',
      },
      ...(Features.AUTOMATION_V2
        ? [
            {
              id: 'RUN_AUTOMATION',
              text: 'Run Automation(s)',
            },
          ]
        : []),
      {
        id: 'WHATSAPP_WITH_DATA',
        text: 'Send Whatsapp Message',
      },
    ],
    CUSTOM_ACTION_LIFECYCLES: {
      ...(Features.AUTOMATION_V2
        ? {
            RUN_AUTOMATION: [
              {
                id: null,
                text: 'No Limit',
              },
              {
                id: 'ONCE_A_DAY',
                text: 'Once a Day',
              },
            ],
          }
        : {}),
    },
    VISIBILITY_CONDITION: {
      OPEN_FORMS: 'OPEN_FORMS',
      RUN_AUTOMATION: 'RUN_AUTOMATION',
      DOWNLOAD_PDF: 'DOWNLOAD_PDF',
      WHATSAPP_WITH_DATA: 'WHATSAPP_WITH_DATA',
      OPEN_LIST_COLUMN: 'OPEN_LIST_COLUMN',
    },
    CUSTOM_ACTION_BUTTON_PROPERTIES: {
      SHOW_TABLE_LINKED_COLUMN_WITH_THIS_SCREEN:
        'showTableLinkedColumnWithThisScreen',
      HIDE_FOR_ROWS: 'hideForRows',
      REQUEST_ACTION_CONFIRMATION: 'requestActionConfirmation',
      OPEN_STANDARD_ENTRY_FORM_VIEW: 'openStandardEntryFormView',
    },
  },
  REPORT_NAME_MAX_LENGTH: 60,
  REPORT_SECTION_TYPES: [
    {
      id: REPORT_SECTION_TYPE_ID.N_COLUMNS,
      val: 'N Columns With Single Split',
    },
    {
      id: REPORT_SECTION_TYPE_ID.N_COLUMNS_WITHOUT_SPLIT,
      val: 'N Columns Without Split',
    },
    {
      id: REPORT_SECTION_TYPE_ID.N_COLUMNS_WITH_MULTI_SPLIT,
      val: 'N Columns With Multi Split',
    },
    {
      id: REPORT_SECTION_TYPE_ID.CASH_IN_AND_OUT,
      val: 'Credit / Debit Tracker',
    },
  ],
  AGGREGATION_TYPES: [
    {
      id: REPORT_SECTION_TYPE_ID.N_COLUMNS_WITHOUT_SPLIT,
      val: 'Aggregation Without Split',
    },
    {
      id: REPORT_SECTION_TYPE_ID.N_COLUMNS_WITH_MULTI_SPLIT,
      val: 'Aggregation With Split',
    },
  ],
};

export const SHARING_DATA_VIEW_ACCESS_TEXT = [
  {
    accessId: MINI_APPS.CUSTOM_VIEW_ACCESS.ALL_DATA,
    accessName: 'User can view all the data in the file',
  },
  {
    accessId: MINI_APPS.CUSTOM_VIEW_ACCESS.DATA_ADDED_BY_USER,
    accessName: 'User can view only data added by them',
  },
  {
    accessId: MINI_APPS.CUSTOM_VIEW_ACCESS.DATA_ASSIGNED_TO_USER,
    accessName: 'User can view only data assigned to them',
  },
];

export const SHARING_ENTRY_ACCESS_TEXT = [
  {
    accessId: MINI_APPS.CUSTOM_ENTRY_ACCESS.CAN_ADD_DATA,
    accessName: 'User can add new data',
  },
  {
    accessId: MINI_APPS.CUSTOM_ENTRY_ACCESS.CANNOT_ADD_DATA,
    accessName: 'User cannot add new data',
  },
];

export const DATA_DELETE_ACCESS_TEXT = [
  {
    accessId: MINI_APPS.CUSTOM_DELETE_ROW_ACCESS.CAN_DELETE_DATA,
    accessName: 'User can delete data visible to them',
  },
  {
    accessId: MINI_APPS.CUSTOM_DELETE_ROW_ACCESS.CANNOT_DELETE_DATA,
    accessName: 'User cannot delete data visible to them',
  },
];

export const SHARING_PROPERTIES_TEXT = [
  {
    propertyId: MINI_APPS.CUSTOM_ENTRY_PROPERTY.CAN_EDIT_DATA,
    propertyName: 'User can edit data',
  },
  {
    propertyId: MINI_APPS.CUSTOM_ENTRY_PROPERTY.CANNOT_EDIT_DATA,
    propertyName: 'User cannot edit data',
  },
  {
    propertyId: MINI_APPS.CUSTOM_ENTRY_PROPERTY.CAN_EDIT_END_OF_DAY,
    propertyName: 'User can only edit till end of the day',
  },
  {
    propertyId: MINI_APPS.CUSTOM_ENTRY_PROPERTY.CAN_EDIT_MISSING_VALUES,
    propertyName: 'Users can only edit missing values in the rows',
  },
];

export const IMPORT_EXPORT_PROPERTY_TEXT = [
  {
    propertyId: MINI_APPS.CUSTOM_IMPORT_EXPORT_PROPERTY.CAN_IMPORT_DATA,
    propertyName: 'User can import data',
  },
  {
    propertyId: MINI_APPS.CUSTOM_IMPORT_EXPORT_PROPERTY.CAN_EXPORT_DATA,
    propertyName: 'User can export data',
  },
];

export const MINI_APPS_SHARE_PERMISSION_LIST = [
  {
    roleId: MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.ADMIN,
    roleName: 'Admin',
    iconName: 'account-star-outline',
  },
  {
    roleId: MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.EDIT,
    roleName: 'Edit',
    iconName: 'pencil-outline',
  },
  {
    roleId: MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.VIEW,
    roleName: 'View',
    iconName: 'eye-outline',
  },
];

export const ORG_MEMBER_PROFILE_FIELDS = {
  // m_uid : should be added for the selected user
  USER_IMAGE: 'm_user_image',
  NAME: 'm_name', // 'NAME' key is mapped to 'm_name' key in firebase
  // ID: 'm_id',
  // DEPARTMENT: 'm_department',
  EMAIL: 'm_email',
  PHONE: 'm_phone',
  CUSTOM_FIELDS: 'm_custom',
  ORGANISATION_ROLES: 'access_level',
  TEAMS: 'teams',
};

export const ORG_MEMBER_PROFILE_SPECIAL_FIELDS = [
  ORG_MEMBER_PROFILE_FIELDS.USER_IMAGE,
  ORG_MEMBER_PROFILE_FIELDS.CUSTOM_FIELDS,
];

export const ORG_MEMBER_SYSTEM_FIELDS_DETAILS = {
  [ORG_MEMBER_PROFILE_FIELDS.USER_IMAGE]: {
    fieldId: ORG_MEMBER_PROFILE_FIELDS.USER_IMAGE,
    name: 'User Image',
  },
  [ORG_MEMBER_PROFILE_FIELDS.NAME]: {
    fieldId: ORG_MEMBER_PROFILE_FIELDS.NAME,
    name: 'Name',
  },
  // [ORG_MEMBER_PROFILE_FIELDS.ID]: {
  //   fieldId: ORG_MEMBER_PROFILE_FIELDS.ID,
  //   name: 'ID',
  // },
  // [ORG_MEMBER_PROFILE_FIELDS.DEPARTMENT]: {
  //   fieldId: ORG_MEMBER_PROFILE_FIELDS.DEPARTMENT,
  //   name: 'Department',
  // },
  [ORG_MEMBER_PROFILE_FIELDS.EMAIL]: {
    fieldId: ORG_MEMBER_PROFILE_FIELDS.EMAIL,
    name: 'Email',
  },
  [ORG_MEMBER_PROFILE_FIELDS.PHONE]: {
    fieldId: ORG_MEMBER_PROFILE_FIELDS.PHONE,
    name: 'Mobile',
  },
};

export const RESTRICTED_CUSTOM_FIELD_NAMES = [
  'User Image',
  'Name',
  // 'ID',
  // 'Department',
  'Email',
  'Mobile',
];

export const TEMPLATE_TYPE = {
  WHATSAPP: 'WHATSAPP',
  SMS: 'SMS',
  EMAIL: 'EMAIL',
};

export const TEMPLATE_TYPE_STRING = {
  [TEMPLATE_TYPE.WHATSAPP]: 'Whatsapp',
  [TEMPLATE_TYPE.SMS]: 'SMS',
  [TEMPLATE_TYPE.EMAIL]: 'Email',
};

export const INDIAN_STATES = [
  {code: 'AN', name: 'Andaman and Nicobar Islands'},
  {code: 'AP', name: 'Andhra Pradesh'},
  {code: 'AR', name: 'Arunachal Pradesh'},
  {code: 'AS', name: 'Assam'},
  {code: 'BR', name: 'Bihar'},
  {code: 'CG', name: 'Chandigarh'},
  {code: 'CH', name: 'Chhattisgarh'},
  {code: 'DH', name: 'Dadra and Nagar Haveli'},
  {code: 'DD', name: 'Daman and Diu'},
  {code: 'DL', name: 'Delhi'},
  {code: 'GA', name: 'Goa'},
  {code: 'GJ', name: 'Gujarat'},
  {code: 'HR', name: 'Haryana'},
  {code: 'HP', name: 'Himachal Pradesh'},
  {code: 'JK', name: 'Jammu and Kashmir'},
  {code: 'JH', name: 'Jharkhand'},
  {code: 'KA', name: 'Karnataka'},
  {code: 'KL', name: 'Kerala'},
  {code: 'LD', name: 'Lakshadweep'},
  {code: 'MP', name: 'Madhya Pradesh'},
  {code: 'MH', name: 'Maharashtra'},
  {code: 'MN', name: 'Manipur'},
  {code: 'ML', name: 'Meghalaya'},
  {code: 'MZ', name: 'Mizoram'},
  {code: 'NL', name: 'Nagaland'},
  {code: 'OR', name: 'Odisha'},
  {code: 'PY', name: 'Puducherry'},
  {code: 'PB', name: 'Punjab'},
  {code: 'RJ', name: 'Rajasthan'},
  {code: 'SK', name: 'Sikkim'},
  {code: 'TN', name: 'Tamil Nadu'},
  {code: 'TS', name: 'Telangana'},
  {code: 'TR', name: 'Tripura'},
  {code: 'UK', name: 'Uttarakhand'},
  {code: 'UP', name: 'Uttar Pradesh'},
  {code: 'WB', name: 'West Bengal'},
];

export const TEST_NUMBER_VERIFICATION_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
};

export const ARCH_V2_FLAGS = {
  minSupportedVersion: '9.0.0',
};

export const DISABLED_COLUMNS_COPY_MOVE_ROW_AUTOMATIONS = [
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.LIST,
  FIELD_TYPE_ID.PDF,
];
export const DISABLED_COLUMNS_COPY_MOVE_ROW_AUTOMATIONS_V2 = [
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.LIST,
  FIELD_TYPE_ID.PDF,
];
export const SETVALUE_TEXTINPUT_ALLOWED_COLUMNS = [
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.AUTO_INCREMENT_ID,
  FIELD_TYPE_ID.NO_OF_DAYS,
  FIELD_TYPE_ID.STRING_FORMULA,
];

export const LIO_APP = 'lioapp';
export const LIO_WEB_APP = 'liowebapp';
export const LIO_APP_URL = require('../imports').isProduction
  ? 'https://web.lio.io'
  : 'https://getlio.io';
export const IOS_LIOAPP_IDENTIFIER = require('../imports').isProduction
  ? 'com.lio.io'
  : 'com.getlio.lio';
export const PROJECT_ID = require('../imports').isProduction
  ? 'registerbook-e077b'
  : 'registerbook-development';
export const LIO_AUTH_SCHEME = 'lioapp://auth';
export const DASHBOARD_TYPES_V3 = {
  SIMPLE: 'SIMPLE',
  SPLIT_BY_VALUE: 'SPLIT_BY_VALUE',
  SPLIT_BY_DATE: 'SPLIT_BY_DATE',
  STOCK: 'STOCK',
  VENDOR: 'VENDOR',
  CASH_IN_AND_OUT: 'CASH_IN_AND_OUT',
  MULTI_SPLIT: 'MULTI_SPLIT',
  LINE_AND_BAR: 'LINE_AND_BAR',
};

export const PIVOT_SPLIT_TYPES = {
  ROWS: 'ROWS',
  COLUMNS: 'COLUMNS',
};

export const DASHBOARD_TYPES_FILTER = [
  {
    icon: 'list-alt',
    iconGroup: 'MaterialIcons',
    text: 'Simple',
    type: DASHBOARD_TYPES_V3.SIMPLE,
  },
  {
    icon: 'chart-arc',
    iconGroup: 'MaterialCommunityIcons',
    text: 'Split Value',
    type: DASHBOARD_TYPES_V3.SPLIT_BY_VALUE,
  },
  {
    icon: 'file-compare',
    iconGroup: 'MaterialCommunityIcons',
    text: 'Open / Closing Bal.',
    type: DASHBOARD_TYPES_V3.CASH_IN_AND_OUT,
  },
  {
    icon: 'chart-donut-variant',
    iconGroup: 'MaterialCommunityIcons',
    text: 'Multi Split',
    type: DASHBOARD_TYPES_V3.MULTI_SPLIT,
  },
  {
    icon: 'chart-timeline-variant',
    iconGroup: 'MaterialCommunityIcons',
    text: 'Timeline',
    type: DASHBOARD_TYPES_V3.LINE_AND_BAR,
  },
];

export const DASHBOARD_VALUES_DISPLAY_COUNT = 5;
export const DASHBOARD_VALUES_DISPLAY_COUNT_N_COLUMNS_WITHOUT_SPLIT = 4;

export const DASHBOARD_SUB_TYPE = {
  [DASHBOARD_TYPES_V3.SIMPLE]: {
    BASIC: 'BASIC',
    DIFF_IN_VAL: 'DIFF_IN_VAL',
    DIFF_IN_PERCENTAGE: 'DIFF_IN_PERCENTAGE',
    N_VALUES_WITHOUT_SPLIT: 'N_VALUES_WITHOUT_SPLIT',
    LINE_OR_BAR: 'LINE_OR_BAR',
  },
  [DASHBOARD_TYPES_V3.SPLIT_BY_VALUE]: {
    ONLY_VALUE: 'ONLY_VALUE',
    ONLY_CHART: 'ONLY_CHART',
    CHART_AND_VALUE: 'CHART_AND_VALUE',
    N_VALUES: 'N_VALUES',
  },
  [DASHBOARD_TYPES_V3.MULTI_SPLIT]: {
    ONLY_VALUE: 'ONLY_VALUE',
    ONLY_CHART: 'ONLY_CHART',
    CHART_AND_VALUE: 'CHART_AND_VALUE',
    N_VALUES: 'N_VALUES',
    PIVOT_TABLE: 'PIVOT_TABLE',
  },
  [DASHBOARD_TYPES_V3.SPLIT_BY_DATE]: {
    ONLY_VALUE: 'ONLY_VALUE',
    ONLY_CHART: 'ONLY_CHART',
    CHART_AND_VALUE: 'CHART_AND_VALUE',
  },
  [DASHBOARD_TYPES_V3.STOCK]: {
    STOCK_MOVEMENT: 'STOCK_MOVEMENT',
    STOCK_OUT: 'STOCK_OUT',
    LOW_STOCK: 'LOW_STOCK',
  },
  [DASHBOARD_TYPES_V3.VENDOR]: {
    DELIVERY_ANALYSIS: 'DELIVERY_ANALYSIS',
  },
  [DASHBOARD_TYPES_V3.CASH_IN_AND_OUT]: {
    WITH_PARTY: 'WITH_PARTY',
    WITHOUT_PARTY: 'WITHOUT_PARTY',
  },
  [DASHBOARD_TYPES_V3.LINE_AND_BAR]: {
    TIMELINE: 'TIMELINE',
  },
};

export const DASHBOARD_TYPE_KEYS = {
  NAME: 'NAME',
  SCREEN: 'SCREEN',
  COLUMN: 'COLUMN',
  N_COLUMN: 'N_COLUMN',
  OPERATION: 'OPERATION',
  FILTER: 'FILTER',
  VALUE_COLOR: 'VALUE_COLOR',
  SPLIT_BY_COLUMN: 'SPLIT_BY_COLUMN',
  MULTI_SPLIT_COLUMN: 'MULTI_SPLIT_COLUMN',
  INDEX: 'INDEX',
  DATE_COLUMN: 'DATE_COLUMN',
  INTERVAL: 'INTERVAL',
  DEBIT_COLUMN: 'DEBIT_COLUMN',
  CREDIT_COLUMN: 'CREDIT_COLUMN',
  OPTIONAL_FILTER_COLUMN: 'OPTIONAL_FILTER_COLUMN',
  CHART_TYPE: 'CHART_TYPE',
  AGGREGATION_LEVEL: 'AGGREGATION_LEVEL',
  PIVOT_COLUMN_SPLITS: 'PIVOT_COLUMN_SPLITS',
  PIVOT_ROW_SPLITS: 'PIVOT_ROW_SPLITS',
};

export const DASHBORD_MODAL_SELECT_TYPES = {
  SCREEN: 'SCREEN',
  COLUMN: 'COLUMN',
  SPLIT_BY: 'SPLIT_BY',
  MULTI_SPLIT_COLUMN: 'MULTI_SPLIT_COLUMN',
  INDEX: 'INDEX',
  DATE_COLUMN: 'DATE_COLUMN',
  N_COLUMN: 'N_COLUMN',
  INTERVAL: 'INTERVAL',
  DEBIT_COLUMN: 'DEBIT_COLUMN',
  CREDIT_COLUMN: 'CREDIT_COLUMN',
  OPTIONAL_FILTER_COLUMN: 'OPTIONAL_FILTER_COLUMN',
  CHART_TYPE: 'CHART_TYPE',
  AGGREGATION_LEVEL: 'AGGREGATION_LEVEL',
  PIVOT_COLUMN_SPLITS: 'PIVOT_COLUMN_SPLITS',
  PIVOT_ROW_SPLITS: 'PIVOT_ROW_SPLITS',
};

export const DASHBOARD_INTERVALS = {
  Daily: {id: 'Daily', title: 'Daily'},
  Monthly: {id: 'Monthly', title: 'Monthly'},
  Yearly: {id: 'Yearly', title: 'Yearly'},
};

export const DASHBOARD_INDEX_TYPES = {
  INCREASE_WITH_POSITIVE: {
    text: 'Increase with Positive Values',
    id: 'INCREASE_WITH_POSITIVE',
  },
  INCREASE_WITH_NEGATIVE: {
    text: 'Increase with Negative Values',
    id: 'INCREASE_WITH_NEGATIVE',
  },
};

export const DASHBOARD_COLUMN_CUSTOMIZATION = {
  DASHBOARD_LABEL_OPTION: {
    COLUMN_NAME: {
      id: 'COLUMN_NAME',
      text: 'Column Name',
    },
    CUSTOM_VALUE: {
      id: 'CUSTOM_VALUE',
      text: 'Custom Value',
    },
  },
  DASHBOARD_COLOR_OPTIONS: {
    DEFAULT: {
      DEFAULT: {
        id: 'DEFAULT',
        text: 'Default',
        defaultColor: '#000',
      },
    },
    BASED_ON_CONDITION: {
      POSITIVE: {
        id: 'POSITIVE',
        text: 'Positive Value',
        defaultColor: '#449F35',
      },
      NEGATIVE: {
        id: 'NEGATIVE',
        text: 'Negative Value',
        defaultColor: '#E02A2A',
      },
    },
  },
};

export const CUSTOMISE_COLUMN_TYPE = {
  DEBIT: 'DEBIT',
  CREDIT: 'CREDIT',
  SPLIT_BY: 'SPLIT_BY',
  MULTI_SPLIT_COLUMN: 'MULTI_SPLIT_COLUMN',
  N_COLUMN: 'N_COLUMN',
  DATE: 'DATE',
  COLUMN: 'COLUMN',
  PIVOT_SPLITS: 'PIVOT_SPLITS',
};

export const DASHBOARD_LINE_BAR_CONFIG = {
  CHART_TYPE: {
    LINE: 'LINE',
    BAR: 'BAR',
  },
  LINE_BAR_TYPE_ORDER: [
    {
      id: 'LINE',
      text: 'Line',
    },
    {
      id: 'BAR',
      text: 'Bar',
    },
  ],
};

export const DASHBOARD_AGGREGATION_LEVEL_CONFIG = {
  LEVELS: {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    QUARTERLY: 'QUARTERLY',
    YEARLY: 'YEARLY',
  },
  AGGREGATION_ORDER: [
    {
      id: 'DAILY',
      text: 'Daily',
    },
    {
      id: 'WEEKLY',
      text: 'Weekly',
    },
    {
      id: 'MONTHLY',
      text: 'Monthly',
    },
    {
      id: 'QUARTERLY',
      text: 'Quarterly',
    },
    {
      id: 'YEARLY',
      text: 'Yearly',
    },
  ],
};

export const DUMMY_DASHBOARDS = [
  {
    cardTitle: 'Basic Metrics',
    title: 'Metrics',
    value: '1,25,000',
    type: DASHBOARD_TYPES_V3.SIMPLE,
    subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SIMPLE].BASIC,
    id: 'A-1',
    keys: [
      DASHBOARD_TYPE_KEYS.NAME,
      DASHBOARD_TYPE_KEYS.COLUMN,
      DASHBOARD_TYPE_KEYS.SCREEN,
      DASHBOARD_TYPE_KEYS.VALUE_COLOR,
      DASHBOARD_TYPE_KEYS.FILTER,
      DASHBOARD_TYPE_KEYS.OPERATION,
      DASHBOARD_TYPE_KEYS.DATE_COLUMN,
      DASHBOARD_TYPE_KEYS.OPTIONAL_FILTER_COLUMN,
    ],
  },
  {
    cardTitle: 'N Values Without Split By',
    title: 'Daily Sales',
    value: '₹ 8,98,760',
    columns: {
      value: {
        index: 1,
        name: 'Units',
        id: 'value',
      },
      value2: {
        index: 2,
        name: 'Bill Amount',
        id: 'value2',
      },
      value1: {
        index: 4,
        name: 'Rating',
        id: 'value1',
      },
    },
    valueArr: {value: '₹ 12,887', value1: '123', value2: '9.38%'},
    type: DASHBOARD_TYPES_V3.SIMPLE,
    subType:
      DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SIMPLE].N_VALUES_WITHOUT_SPLIT,
    id: 'A-4',
    keys: [
      DASHBOARD_TYPE_KEYS.NAME,
      DASHBOARD_TYPE_KEYS.N_COLUMN,
      DASHBOARD_TYPE_KEYS.SCREEN,
      DASHBOARD_TYPE_KEYS.VALUE_COLOR,
      DASHBOARD_TYPE_KEYS.FILTER,
      DASHBOARD_TYPE_KEYS.OPTIONAL_FILTER_COLUMN,
      DASHBOARD_TYPE_KEYS.DATE_COLUMN,
    ],
  },
  {
    cardTitle: 'N Columns Dashboard',
    title: 'N columns',
    value: '1,25,000',
    type: DASHBOARD_TYPES_V3.SPLIT_BY_VALUE,
    subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SPLIT_BY_VALUE].N_VALUES,
    id: 'A-5',
    columnNames: ['Current Stock'],
    constColIds: [
      {id: 'Col_1686329309967', val: 'Cost'},
      {id: 'Col_1686329310404', val: 'Quantity'},
      {id: 'default_colC', val: 'Review'},
      {id: 'default_colB', val: 'Sold'},
    ],

    valueArr: [
      {
        name: 'Oneplus',
        Col_1686329309967: 12011,
        color: '#A4892A',
        Col_1686329310404: 118,
        default_colC: 6.5,
        default_colB: 7,
      },
      {
        name: 'Realme',
        Col_1686329309967: 62286,
        color: '#26C636',
        Col_1686329310404: 114,
        default_colC: 4.5,
        default_colB: 11,
      },
      {
        name: 'Apple',
        Col_1686329309967: 1804,
        color: '#0B70AB',
        Col_1686329310404: 18,
        default_colC: 2.5,
        default_colB: 3,
      },
      {
        name: 'Samsung',
        Col_1686329309967: 5912,
        color: '#A1E44B',
        Col_1686329310404: 28,
        default_colC: 4.5,
        default_colB: 6,
      },
      {
        name: 'Nokia',
        Col_1686329309967: 9522,
        color: '#4D5360',
        Col_1686329310404: 129,
        default_colC: 4.5,
        default_colB: 8,
      },
    ],
    keys: [
      DASHBOARD_TYPE_KEYS.NAME,
      DASHBOARD_TYPE_KEYS.N_COLUMN,
      DASHBOARD_TYPE_KEYS.SCREEN,
      DASHBOARD_TYPE_KEYS.VALUE_COLOR,
      DASHBOARD_TYPE_KEYS.DATE_COLUMN,
      DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
      DASHBOARD_TYPE_KEYS.OPTIONAL_FILTER_COLUMN,
    ],
  },
  {
    cardTitle: 'N Columns Dashboard with multi split',
    title: 'N columns with multi split',
    value: '1,25,000',
    type: DASHBOARD_TYPES_V3.MULTI_SPLIT,
    subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.MULTI_SPLIT].N_VALUES,
    id: 'E-1',
    columnNames: ['Current Stock'],
    constColIds: [
      {id: 'Col_1686329309967', val: 'Cost'},
      {id: 'Col_1686329310404', val: 'Quantity'},
      {id: 'default_colC', val: 'Review'},
      {id: 'default_colB', val: 'Sold'},
    ],
    valueArr: [
      {
        name: 'Oneplus',
        Col_1686329309967: 12011,
        color: '#A4892A',
        Col_1686329310404: 118,
        default_colC: 6.5,
        default_colB: 7,
      },
      {
        name: 'Realme',
        Col_1686329309967: 62286,
        color: '#26C636',
        Col_1686329310404: 114,
        default_colC: 4.5,
        default_colB: 11,
      },
      {
        name: 'Apple',
        Col_1686329309967: 1804,
        color: '#0B70AB',
        Col_1686329310404: 18,
        default_colC: 2.5,
        default_colB: 3,
      },
      {
        name: 'Samsung',
        Col_1686329309967: 5912,
        color: '#A1E44B',
        Col_1686329310404: 28,
        default_colC: 4.5,
        default_colB: 6,
      },
      {
        name: 'Nokia',
        Col_1686329309967: 9522,
        color: '#4D5360',
        Col_1686329310404: 129,
        default_colC: 4.5,
        default_colB: 8,
      },
    ],
    keys: [
      DASHBOARD_TYPE_KEYS.NAME,
      DASHBOARD_TYPE_KEYS.N_COLUMN,
      DASHBOARD_TYPE_KEYS.SCREEN,
      DASHBOARD_TYPE_KEYS.VALUE_COLOR,
      DASHBOARD_TYPE_KEYS.DATE_COLUMN,
      DASHBOARD_TYPE_KEYS.OPTIONAL_FILTER_COLUMN,
      DASHBOARD_TYPE_KEYS.MULTI_SPLIT_COLUMN,
    ],
  },
  {
    cardTitle: 'Pivot Table',
    title: 'Pivot Table',
    type: DASHBOARD_TYPES_V3.MULTI_SPLIT,
    subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.MULTI_SPLIT].PIVOT_TABLE,
    id: 'PV-1',
    dummyConfig: {
      subType: 'PIVOT_TABLE',
      type: 'MULTI_SPLIT',
      dateColumn: {
        id: 'DATA_ADDED_BY',
        val: 'Data added timestamp',
      },
      screenDetails: {
        s_1717498418: {
          optionalFilterColumn: {},
          dateColumn: {
            id: 'DATA_ADDED_BY',
            val: 'Data added timestamp',
          },
          columnDetailsArr: [
            {
              index: 0,
              screenId: 's_1717498418',
              operation: 'TOTAL',
              id: 'Col_1717498369731',
              fieldType: 'NUMBER',
              val: 'Spendings',
              renderId: 'RE_SJ4c3B1JEa',
            },
          ],
          docId: 'VL66Ic5O2NXzpXeRSIKJ3R8AJWB3_1717498294485',
          splitBy: {},
          columnSplits: [
            {
              columnProperties: {},
              id: 'Col_1717498519954',
              aggregationLevel: 'MONTHLY',
              styleObj: {},
              dateFormat: 'MONTH',
              fieldType: 'DATE',
              val: 'Date',
              renderId: 'PV_Va2Ktw2G6F',
            },
          ],
          splitByArr: [],
          rowSplits: [
            {
              columnProperties: {},
              val: 'BU_Level',
              fieldType: 'TEXT',
              styleObj: {},
              id: 'default_colA',
              renderId: 'PV_HSl3lt72vc',
            },
          ],
        },
      },
      title: 'Sahad',
      filters: [],
      dashboardValue: {
        success: true,
        result: {
          pivotVals: {
            'BU1#@RE_SJ4c3B1JEa#@January 2025#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@March 2025#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@July 2024#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@November 2025#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@December 2025#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@January 2026#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@May 2026#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@August 2025#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@August 2026#@': 890,
            'BU1#@RE_SJ4c3B1JEa#@April 2026#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@September 2025#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@June 2026#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@December 2024#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@July 2024#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@April 2025#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@May 2026#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@October 2025#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@August 2025#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@July 2025#@': 345,
            'BU3#@RE_SJ4c3B1JEa#@August 2024#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@February 2026#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@June 2026#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@June 2024#@': 980,
            'BU3#@RE_SJ4c3B1JEa#@January 2025#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@December 2025#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@August 2026#@': 456,
            'BU3#@RE_SJ4c3B1JEa#@October 2024#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@July 2026#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@June 2025#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@March 2026#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@July 2025#@': 1000,
            'BU3#@RE_SJ4c3B1JEa#@April 2026#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@October 2025#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@February 2025#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@January 2026#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@May 2025#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@September 2024#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@June 2025#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@November 2024#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@November 2025#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@March 2025#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@September 2025#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@December 2024#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@August 2024#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@February 2026#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@March 2026#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@October 2024#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@July 2026#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@November 2024#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@June 2024#@': 480,
            'BU3#@RE_SJ4c3B1JEa#@May 2025#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@April 2025#@': 0,
            'BU1#@RE_SJ4c3B1JEa#@February 2025#@': 0,
            'BU3#@RE_SJ4c3B1JEa#@September 2024#@': 0,
          },
          grandTotal: {
            rowTotalValue: [
              [
                2705, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2134, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 1346, 6185,
              ],
            ],
            colTotalValue: [1281, 2034, 2870],
          },
          splitArrCols: [
            [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0,
            ],
            [],
          ],
          valueArrRows: [
            ['BU1', 'BU2', 'BU3'],
            ['BU1#@RE_SJ4c3B1JEa', 'BU2#@RE_SJ4c3B1JEa', 'BU3#@RE_SJ4c3B1JEa'],
            [],
          ],
          splitArrRows: [[1, 1, 1], [0, 0, 0], []],
          valueArrCols: [
            [
              'June 2024',
              'July 2024',
              'August 2024',
              'September 2024',
              'October 2024',
              'November 2024',
              'December 2024',
              'January 2025',
              'February 2025',
              'March 2025',
              'April 2025',
              'May 2025',
              'June 2025',
              'July 2025',
              'August 2025',
              'September 2025',
              'October 2025',
              'November 2025',
              'December 2025',
              'January 2026',
              'February 2026',
              'March 2026',
              'April 2026',
              'May 2026',
              'June 2026',
              'July 2026',
              'August 2026',
            ],
            [],
          ],
          valueArr: [
            [
              'BU1#@RE_SJ4c3B1JEa#@June 2024#@',
              'BU2#@RE_SJ4c3B1JEa#@June 2024#@',
              'BU3#@RE_SJ4c3B1JEa#@June 2024#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@July 2024#@',
              'BU2#@RE_SJ4c3B1JEa#@July 2024#@',
              'BU3#@RE_SJ4c3B1JEa#@July 2024#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@August 2024#@',
              'BU2#@RE_SJ4c3B1JEa#@August 2024#@',
              'BU3#@RE_SJ4c3B1JEa#@August 2024#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@September 2024#@',
              'BU2#@RE_SJ4c3B1JEa#@September 2024#@',
              'BU3#@RE_SJ4c3B1JEa#@September 2024#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@October 2024#@',
              'BU2#@RE_SJ4c3B1JEa#@October 2024#@',
              'BU3#@RE_SJ4c3B1JEa#@October 2024#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@November 2024#@',
              'BU2#@RE_SJ4c3B1JEa#@November 2024#@',
              'BU3#@RE_SJ4c3B1JEa#@November 2024#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@December 2024#@',
              'BU2#@RE_SJ4c3B1JEa#@December 2024#@',
              'BU3#@RE_SJ4c3B1JEa#@December 2024#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@January 2025#@',
              'BU2#@RE_SJ4c3B1JEa#@January 2025#@',
              'BU3#@RE_SJ4c3B1JEa#@January 2025#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@February 2025#@',
              'BU2#@RE_SJ4c3B1JEa#@February 2025#@',
              'BU3#@RE_SJ4c3B1JEa#@February 2025#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@March 2025#@',
              'BU2#@RE_SJ4c3B1JEa#@March 2025#@',
              'BU3#@RE_SJ4c3B1JEa#@March 2025#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@April 2025#@',
              'BU2#@RE_SJ4c3B1JEa#@April 2025#@',
              'BU3#@RE_SJ4c3B1JEa#@April 2025#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@May 2025#@',
              'BU2#@RE_SJ4c3B1JEa#@May 2025#@',
              'BU3#@RE_SJ4c3B1JEa#@May 2025#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@June 2025#@',
              'BU2#@RE_SJ4c3B1JEa#@June 2025#@',
              'BU3#@RE_SJ4c3B1JEa#@June 2025#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@July 2025#@',
              'BU2#@RE_SJ4c3B1JEa#@July 2025#@',
              'BU3#@RE_SJ4c3B1JEa#@July 2025#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@August 2025#@',
              'BU2#@RE_SJ4c3B1JEa#@August 2025#@',
              'BU3#@RE_SJ4c3B1JEa#@August 2025#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@September 2025#@',
              'BU2#@RE_SJ4c3B1JEa#@September 2025#@',
              'BU3#@RE_SJ4c3B1JEa#@September 2025#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@October 2025#@',
              'BU2#@RE_SJ4c3B1JEa#@October 2025#@',
              'BU3#@RE_SJ4c3B1JEa#@October 2025#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@November 2025#@',
              'BU2#@RE_SJ4c3B1JEa#@November 2025#@',
              'BU3#@RE_SJ4c3B1JEa#@November 2025#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@December 2025#@',
              'BU2#@RE_SJ4c3B1JEa#@December 2025#@',
              'BU3#@RE_SJ4c3B1JEa#@December 2025#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@January 2026#@',
              'BU2#@RE_SJ4c3B1JEa#@January 2026#@',
              'BU3#@RE_SJ4c3B1JEa#@January 2026#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@February 2026#@',
              'BU2#@RE_SJ4c3B1JEa#@February 2026#@',
              'BU3#@RE_SJ4c3B1JEa#@February 2026#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@March 2026#@',
              'BU2#@RE_SJ4c3B1JEa#@March 2026#@',
              'BU3#@RE_SJ4c3B1JEa#@March 2026#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@April 2026#@',
              'BU2#@RE_SJ4c3B1JEa#@April 2026#@',
              'BU3#@RE_SJ4c3B1JEa#@April 2026#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@May 2026#@',
              'BU2#@RE_SJ4c3B1JEa#@May 2026#@',
              'BU3#@RE_SJ4c3B1JEa#@May 2026#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@June 2026#@',
              'BU2#@RE_SJ4c3B1JEa#@June 2026#@',
              'BU3#@RE_SJ4c3B1JEa#@June 2026#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@July 2026#@',
              'BU2#@RE_SJ4c3B1JEa#@July 2026#@',
              'BU3#@RE_SJ4c3B1JEa#@July 2026#@',
            ],
            [
              'BU1#@RE_SJ4c3B1JEa#@August 2026#@',
              'BU2#@RE_SJ4c3B1JEa#@August 2026#@',
              'BU3#@RE_SJ4c3B1JEa#@August 2026#@',
            ],
          ],
        },
      },
      id: 'uyJZvSr2bhBvVN49nktl',
    },
    keys: [
      DASHBOARD_TYPE_KEYS.NAME,
      DASHBOARD_TYPE_KEYS.N_COLUMN,
      DASHBOARD_TYPE_KEYS.SCREEN,
      DASHBOARD_TYPE_KEYS.VALUE_COLOR,
      DASHBOARD_TYPE_KEYS.OPTIONAL_FILTER_COLUMN,
      DASHBOARD_TYPE_KEYS.PIVOT_ROW_SPLITS,
      DASHBOARD_TYPE_KEYS.PIVOT_COLUMN_SPLITS,
    ],
  },
  // {
  //   id: 'OC-1',
  //   cardTitle: 'Credit / Debit Tracker',
  //   title: 'LIO Tech - Credit / Debit Tracker',
  //   type: DASHBOARD_TYPES_V3.CASH_IN_AND_OUT,
  //   subType:
  //     DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.CASH_IN_AND_OUT].WITHOUT_PARTY,

  //   keys: [
  //     DASHBOARD_TYPE_KEYS.INTERVAL,
  //     DASHBOARD_TYPE_KEYS.DATE_COLUMN,
  //     DASHBOARD_TYPE_KEYS.DEBIT_COLUMN,
  //     DASHBOARD_TYPE_KEYS.CREDIT_COLUMN,
  //   ],
  //   valueArr: [
  //     {
  //       name: '23 Jan 2023',
  //       openingBalance: '₹1000',
  //       closingBalance: '₹980',
  //     },
  //     {
  //       name: '22 Jan 2023',
  //       openingBalance: '₹1000',
  //       closingBalance: '₹980',
  //     },
  //     {
  //       name: '21 Jan 2023',
  //       openingBalance: '₹1000',
  //       closingBalance: '₹980',
  //     },
  //     {
  //       name: '20 Jan 2023',
  //       openingBalance: '₹1000',
  //       closingBalance: '₹980',
  //     },
  //     {
  //       name: '19 Jan 2023',
  //       openingBalance: '₹1000',
  //       closingBalance: '₹980',
  //     },
  //   ],
  // },
  {
    id: 'OC-2',
    cardTitle: 'Credit / Debit Tracker with Party',
    title: 'LIO Tech - Credit / Debit Tracker',
    type: DASHBOARD_TYPES_V3.CASH_IN_AND_OUT,
    subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.CASH_IN_AND_OUT].WITH_PARTY,
    keys: [
      DASHBOARD_TYPE_KEYS.INTERVAL,
      DASHBOARD_TYPE_KEYS.DATE_COLUMN,
      DASHBOARD_TYPE_KEYS.OPTIONAL_FILTER_COLUMN,
      DASHBOARD_TYPE_KEYS.DEBIT_COLUMN,
      DASHBOARD_TYPE_KEYS.CREDIT_COLUMN,
    ],
    valueArr: [
      {
        name: '23 Feb 2023',
        openingBalance: '₹1000',
        closingBalance: '₹980',
        creditColumn: '₹1000',
        debitColumn: '₹980',
      },
      {
        name: '22 Jan 2023',
        openingBalance: '₹1000',
        closingBalance: '₹980',
        creditColumn: '₹1000',
        debitColumn: '₹980',
      },
      {
        name: '21 Jan 2023',
        openingBalance: '₹1000',
        closingBalance: '₹980',
        creditColumn: '₹1000',
        debitColumn: '₹980',
      },
      {
        name: '20 Jan 2023',
        openingBalance: '₹1000',
        closingBalance: '₹980',
        creditColumn: '₹1000',
        debitColumn: '₹980',
      },
      {
        name: '19 Jan 2023',
        openingBalance: '₹1000',
        closingBalance: '₹980',
        creditColumn: '₹1000',
        debitColumn: '₹980',
      },
    ],
  },
  // {
  //   cardTitle: 'Diff. in Value',
  //   title: 'Daily Sales',
  //   value: '₹ 88,750',
  //   indexVal: '34',
  //   type: DASHBOARD_TYPES_V3.SIMPLE,
  //   subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SIMPLE].DIFF_IN_VAL,
  //   id: 'A-2',
  //   keys: [
  //     DASHBOARD_TYPE_KEYS.NAME,
  //     DASHBOARD_TYPE_KEYS.COLUMN,
  //     DASHBOARD_TYPE_KEYS.SCREEN,
  //     DASHBOARD_TYPE_KEYS.VALUE_COLOR,
  //     DASHBOARD_TYPE_KEYS.FILTER,
  //     DASHBOARD_TYPE_KEYS.OPERATION,
  //     DASHBOARD_TYPE_KEYS.INDEX,
  //   ],
  // },
  // {
  //   cardTitle: 'Diff. in Percent',
  //   title: 'Daily Sales',
  //   value: '49,000',
  //   indexVal: '3.89',
  //   type: DASHBOARD_TYPES_V3.SIMPLE,
  //   subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SIMPLE].DIFF_IN_PERCENTAGE,
  //   id: 'A-3',
  //   keys: [
  //     DASHBOARD_TYPE_KEYS.NAME,
  //     DASHBOARD_TYPE_KEYS.COLUMN,
  //     DASHBOARD_TYPE_KEYS.SCREEN,
  //     DASHBOARD_TYPE_KEYS.VALUE_COLOR,
  //     DASHBOARD_TYPE_KEYS.FILTER,
  //     DASHBOARD_TYPE_KEYS.OPERATION,
  //     DASHBOARD_TYPE_KEYS.INDEX,
  //   ],
  // },
  // {
  //   cardTitle: 'Stock Movement',
  //   title: 'Stock Movement',
  //   valueArr: [
  //     {
  //       name: 'Mobile Phone',
  //       openingStock: 250,
  //       closingStock: 45,
  //       difference: -205,
  //     },
  //     {
  //       name: 'Laptop',
  //       openingStock: 135,
  //       closingStock: 45,
  //       difference: -95,
  //     },
  //     {
  //       name: 'Head Phone',
  //       openingStock: 100,
  //       closingStock: 22,
  //       difference: -78,
  //     },
  //     {
  //       name: 'Tablet',
  //       openingStock: 45,
  //       closingStock: 200,
  //       difference: 155,
  //     },
  //     {
  //       name: 'Accessories',
  //       openingStock: 30,
  //       closingStock: 22,
  //       difference: -8,
  //     },
  //   ],
  //   type: DASHBOARD_TYPES_V3.STOCK_MOVEMENT,
  //   subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SPLIT_BY_VALUE].ONLY_VALUE,
  //   id: 'C-1',
  //   keys: [
  //     DASHBOARD_TYPE_KEYS.NAME,
  //     DASHBOARD_TYPE_KEYS.COLUMN,
  //     DASHBOARD_TYPE_KEYS.SCREEN,
  //     DASHBOARD_TYPE_KEYS.VALUE_COLOR,
  //     DASHBOARD_TYPE_KEYS.FILTER,
  //     DASHBOARD_TYPE_KEYS.OPERATION,
  //     DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
  //   ],
  // },
  // {
  //   cardTitle: 'Stock Out date',
  //   title: 'Stock Out date',
  //   valueArr: [
  //     {
  //       name: 'Mobile Phone',
  //       avgSold: 87,
  //       outDate: '17 Apr',
  //     },
  //     {
  //       name: 'Mobile Phone',
  //       avgSold: 87,
  //       outDate: '17 Apr',
  //     },
  //     {
  //       name: 'Mobile Phone',
  //       avgSold: 87,
  //       outDate: '17 Apr',
  //     },
  //     {
  //       name: 'Mobile Phone',
  //       avgSold: 87,
  //       outDate: '17 Apr',
  //     },
  //     {
  //       name: 'Mobile Phone',
  //       avgSold: 87,
  //       outDate: '17 Apr',
  //     },
  //   ],
  //   type: DASHBOARD_TYPES_V3.OUT_OF_STOCK,
  //   subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SPLIT_BY_VALUE].ONLY_VALUE,
  //   id: 'C-2',
  //   keys: [
  //     DASHBOARD_TYPE_KEYS.NAME,
  //     DASHBOARD_TYPE_KEYS.COLUMN,
  //     DASHBOARD_TYPE_KEYS.SCREEN,
  //     DASHBOARD_TYPE_KEYS.VALUE_COLOR,
  //     DASHBOARD_TYPE_KEYS.FILTER,
  //     DASHBOARD_TYPE_KEYS.OPERATION,
  //     DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
  //   ],
  // },
  {
    cardTitle: 'Split Value with Chart',
    title: 'Daily Sales',
    value: '₹ 8,98,760',
    valueArr: [
      {
        name: 'Microsoft',
        value: 245884,
        color: DASHBOARDS_CHART_COLORS[0],
      },
      {name: 'Amazon', value: 133485, color: DASHBOARDS_CHART_COLORS[1]},
      {name: 'Google', value: 129990, color: DASHBOARDS_CHART_COLORS[2]},
      {name: 'Tesla', value: 98389, color: DASHBOARDS_CHART_COLORS[3]},
      {name: 'Meta', value: 131209, color: DASHBOARDS_CHART_COLORS[4]},
    ],
    type: DASHBOARD_TYPES_V3.SPLIT_BY_VALUE,
    subType:
      DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SPLIT_BY_VALUE].CHART_AND_VALUE,
    id: 'B-1',
    keys: [
      DASHBOARD_TYPE_KEYS.NAME,
      DASHBOARD_TYPE_KEYS.COLUMN,
      DASHBOARD_TYPE_KEYS.SCREEN,
      DASHBOARD_TYPE_KEYS.VALUE_COLOR,
      DASHBOARD_TYPE_KEYS.FILTER,
      DASHBOARD_TYPE_KEYS.OPERATION,
      DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
      DASHBOARD_TYPE_KEYS.DATE_COLUMN,
      DASHBOARD_TYPE_KEYS.OPTIONAL_FILTER_COLUMN,
    ],
  },
  {
    cardTitle: 'Split Values',
    title: 'Daily Sales',
    value: '₹ 8,98,760',
    valueArr: [
      {
        name: 'Microsoft',
        value: 245884,
        color: DASHBOARDS_CHART_COLORS[0],
      },
      {name: 'Amazon', value: 133485, color: DASHBOARDS_CHART_COLORS[1]},
      {name: 'Google', value: 129990, color: DASHBOARDS_CHART_COLORS[2]},
      {name: 'Tesla', value: 98389, color: DASHBOARDS_CHART_COLORS[3]},
      {name: 'Meta', value: 131209, color: DASHBOARDS_CHART_COLORS[4]},
    ],
    type: DASHBOARD_TYPES_V3.SPLIT_BY_VALUE,
    subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SPLIT_BY_VALUE].ONLY_VALUE,
    id: 'B-2',
    keys: [
      DASHBOARD_TYPE_KEYS.NAME,
      DASHBOARD_TYPE_KEYS.COLUMN,
      DASHBOARD_TYPE_KEYS.SCREEN,
      DASHBOARD_TYPE_KEYS.VALUE_COLOR,
      DASHBOARD_TYPE_KEYS.FILTER,
      DASHBOARD_TYPE_KEYS.OPERATION,
      DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
      DASHBOARD_TYPE_KEYS.DATE_COLUMN,
      DASHBOARD_TYPE_KEYS.OPTIONAL_FILTER_COLUMN,
    ],
  },
  {
    cardTitle: 'Split Value only Chart',
    title: 'Daily Sales',
    value: '₹ 8,98,760',
    valueArr: [
      {
        name: 'Microsoft',
        value: 245884,
        color: DASHBOARDS_CHART_COLORS[0],
      },
      {name: 'Amazon', value: 133485, color: DASHBOARDS_CHART_COLORS[1]},
      {name: 'Google', value: 129990, color: DASHBOARDS_CHART_COLORS[2]},
      {name: 'Tesla', value: 98389, color: DASHBOARDS_CHART_COLORS[3]},
      {name: 'Meta', value: 131209, color: DASHBOARDS_CHART_COLORS[4]},
    ],
    type: DASHBOARD_TYPES_V3.SPLIT_BY_VALUE,
    subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SPLIT_BY_VALUE].ONLY_CHART,
    id: 'B-3',
    keys: [
      DASHBOARD_TYPE_KEYS.NAME,
      DASHBOARD_TYPE_KEYS.COLUMN,
      DASHBOARD_TYPE_KEYS.SCREEN,
      DASHBOARD_TYPE_KEYS.VALUE_COLOR,
      DASHBOARD_TYPE_KEYS.FILTER,
      DASHBOARD_TYPE_KEYS.OPERATION,
      DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
      DASHBOARD_TYPE_KEYS.DATE_COLUMN,
      DASHBOARD_TYPE_KEYS.OPTIONAL_FILTER_COLUMN,
    ],
  },
  {
    cardTitle: 'Split Values (multi split)',
    title: 'Daily Sales',
    value: '₹ 8,98,760',
    valueArr: [
      {
        name: 'Microsoft',
        value: 245884,
        color: DASHBOARDS_CHART_COLORS[0],
      },
      {name: 'Amazon', value: 133485, color: DASHBOARDS_CHART_COLORS[1]},
      {name: 'Google', value: 129990, color: DASHBOARDS_CHART_COLORS[2]},
      {name: 'Tesla', value: 98389, color: DASHBOARDS_CHART_COLORS[3]},
      {name: 'Meta', value: 131209, color: DASHBOARDS_CHART_COLORS[4]},
    ],
    type: DASHBOARD_TYPES_V3.MULTI_SPLIT,
    subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.MULTI_SPLIT].ONLY_VALUE,
    id: 'E-3',
    keys: [
      DASHBOARD_TYPE_KEYS.NAME,
      DASHBOARD_TYPE_KEYS.COLUMN,
      DASHBOARD_TYPE_KEYS.SCREEN,
      DASHBOARD_TYPE_KEYS.VALUE_COLOR,
      DASHBOARD_TYPE_KEYS.FILTER,
      DASHBOARD_TYPE_KEYS.OPERATION,
      DASHBOARD_TYPE_KEYS.DATE_COLUMN,
      DASHBOARD_TYPE_KEYS.OPTIONAL_FILTER_COLUMN,
      DASHBOARD_TYPE_KEYS.MULTI_SPLIT_COLUMN,
    ],
  },
  // {
  //   cardTitle: 'Multi Split Value only Chart',
  //   title: 'Daily Sales',
  //   isDummy: true,
  //   value: '₹ 8,98,760',
  //   valueArr: [
  //     {
  //       name: 'Microsoft#@Product1',
  //       value: 245884,
  //       color: DASHBOARDS_CHART_COLORS[0],
  //     },
  //     {
  //       name: 'Amazon#@Product1',
  //       value: 133485,
  //       color: DASHBOARDS_CHART_COLORS[1],
  //     },
  //     {
  //       name: 'Google#@Product1',
  //       value: 129990,
  //       color: DASHBOARDS_CHART_COLORS[2],
  //     },
  //     {
  //       name: 'Tesla#@Product1',
  //       value: 98389,
  //       color: DASHBOARDS_CHART_COLORS[3],
  //     },
  //     {
  //       name: 'Meta#@Product1',
  //       value: 131209,
  //       color: DASHBOARDS_CHART_COLORS[4],
  //     },
  //   ],
  //   type: DASHBOARD_TYPES_V3.MULTI_SPLIT,
  //   subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.MULTI_SPLIT].ONLY_CHART,
  //   id: 'B-4',
  //   keys: [
  //     DASHBOARD_TYPE_KEYS.NAME,
  //     DASHBOARD_TYPE_KEYS.COLUMN,
  //     DASHBOARD_TYPE_KEYS.SCREEN,
  //     DASHBOARD_TYPE_KEYS.VALUE_COLOR,
  //     DASHBOARD_TYPE_KEYS.FILTER,
  //     DASHBOARD_TYPE_KEYS.OPERATION,
  //     DASHBOARD_TYPE_KEYS.MULTI_SPLIT_COLUMN,
  //     DASHBOARD_TYPE_KEYS.DATE_COLUMN,
  //     DASHBOARD_TYPE_KEYS.OPTIONAL_FILTER_COLUMN,
  //   ],
  // },
  // {
  //   cardTitle: 'Low Stock Items',
  //   title: 'Low Stock Items',
  //   value: '5',
  //   valueArr: [
  //     {name: 'USB Charger', value: 0, isOutOfStock: true},
  //     {name: 'Laptop', value: 8, isOutOfStock: true},
  //     {name: 'Head Phone', value: 12, isOutOfStock: true},
  //     {name: 'Tablet', value: 29, isOutOfStock: false},
  //     {name: 'Accessories', value: 44, isOutOfStock: true},
  //   ],
  //   type: DASHBOARD_TYPES_V3.STOCK,
  //   subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.STOCK].LOW_STOCK,
  //   id: 'D-6',
  //   keys: [
  //     DASHBOARD_TYPE_KEYS.NAME,
  //     DASHBOARD_TYPE_KEYS.COLUMN,
  //     DASHBOARD_TYPE_KEYS.SCREEN,
  //     DASHBOARD_TYPE_KEYS.VALUE_COLOR,
  //     DASHBOARD_TYPE_KEYS.FILTER,
  //     DASHBOARD_TYPE_KEYS.OPERATION,
  //     DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
  //   ],
  // },
  {
    cardTitle: 'Split Value Only Chart (multi split)',
    title: 'Daily Sales',
    isDummy: true,
    value: '₹ 8,98,760',
    valueArr: [
      {
        name: 'Microsoft#@Product1',
        value: 245884,
        color: DASHBOARDS_CHART_COLORS[0],
      },
      {
        name: 'Amazon#@Product1',
        value: 133485,
        color: DASHBOARDS_CHART_COLORS[1],
      },
      {
        name: 'Google#@Product1',
        value: 129990,
        color: DASHBOARDS_CHART_COLORS[2],
      },
      {
        name: 'Tesla#@Product1',
        value: 98389,
        color: DASHBOARDS_CHART_COLORS[3],
      },
      {
        name: 'Meta#@Product1',
        value: 131209,
        color: DASHBOARDS_CHART_COLORS[4],
      },
    ],
    type: DASHBOARD_TYPES_V3.MULTI_SPLIT,
    subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.MULTI_SPLIT].ONLY_CHART,
    id: 'B-4',
    keys: [
      DASHBOARD_TYPE_KEYS.NAME,
      DASHBOARD_TYPE_KEYS.COLUMN,
      DASHBOARD_TYPE_KEYS.SCREEN,
      DASHBOARD_TYPE_KEYS.VALUE_COLOR,
      DASHBOARD_TYPE_KEYS.FILTER,
      DASHBOARD_TYPE_KEYS.OPERATION,
      DASHBOARD_TYPE_KEYS.MULTI_SPLIT_COLUMN,
      DASHBOARD_TYPE_KEYS.DATE_COLUMN,
      DASHBOARD_TYPE_KEYS.OPTIONAL_FILTER_COLUMN,
    ],
  },
  {
    cardTitle: 'Split Value with Chart (multi split)',
    title: 'Daily Sales',
    value: '₹ 8,98,760',
    valueArr: [
      {
        name: 'Microsoft',
        value: 245884,
        color: DASHBOARDS_CHART_COLORS[0],
      },
      {name: 'Amazon', value: 133485, color: DASHBOARDS_CHART_COLORS[1]},
      {name: 'Google', value: 129990, color: DASHBOARDS_CHART_COLORS[2]},
      {name: 'Tesla', value: 98389, color: DASHBOARDS_CHART_COLORS[3]},
      {name: 'Meta', value: 131209, color: DASHBOARDS_CHART_COLORS[4]},
    ],
    type: DASHBOARD_TYPES_V3.MULTI_SPLIT,
    subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.MULTI_SPLIT].CHART_AND_VALUE,
    id: 'E-2',
    keys: [
      DASHBOARD_TYPE_KEYS.NAME,
      DASHBOARD_TYPE_KEYS.COLUMN,
      DASHBOARD_TYPE_KEYS.SCREEN,
      DASHBOARD_TYPE_KEYS.VALUE_COLOR,
      DASHBOARD_TYPE_KEYS.FILTER,
      DASHBOARD_TYPE_KEYS.OPERATION,
      DASHBOARD_TYPE_KEYS.DATE_COLUMN,
      DASHBOARD_TYPE_KEYS.OPTIONAL_FILTER_COLUMN,
      DASHBOARD_TYPE_KEYS.MULTI_SPLIT_COLUMN,
    ],
  },
  {
    cardTitle: 'TimeLine',
    title: 'Daily Sales',
    value: '₹ 8,98,760',
    valueArr: {
      data: [
        {
          label: 'Microsoft',
          value: 245884,
          frontColor: '#177AD5',
          labelWidth: 18,
          labelTextStyle: {
            color: 'gray',
            fontSize: 13,
            paddingHorizontal: 10,
          },
        },
        {
          label: 'Amazon',
          value: 133485,
          frontColor: '#177AD5',
          labelWidth: 18,
          labelTextStyle: {
            color: 'gray',
            fontSize: 13,
            paddingHorizontal: 10,
          },
        },
        {
          label: 'Google',
          value: 129990,
          frontColor: '#177AD5',
          labelWidth: 18,
          labelTextStyle: {
            color: 'gray',
            fontSize: 13,
            paddingHorizontal: 10,
          },
        },
        {
          label: 'Tesla',
          value: 98389,
          frontColor: '#177AD5',
          labelWidth: 18,
          labelTextStyle: {
            color: 'gray',
            fontSize: 13,
            paddingHorizontal: 10,
          },
        },
        {
          label: 'Meta',
          value: 131209,
          frontColor: '#177AD5',
          labelWidth: 18,
          labelTextStyle: {
            color: 'gray',
            fontSize: 13,
            paddingHorizontal: 10,
          },
        },
      ],
    },
    type: DASHBOARD_TYPES_V3.LINE_AND_BAR,
    subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.LINE_AND_BAR].TIMELINE,
    id: 'L-B-1',
    keys: [
      DASHBOARD_TYPE_KEYS.NAME,
      DASHBOARD_TYPE_KEYS.SCREEN,
      DASHBOARD_TYPE_KEYS.COLUMN,
      DASHBOARD_TYPE_KEYS.FILTER,
      DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
      DASHBOARD_TYPE_KEYS.CHART_TYPE,
      DASHBOARD_TYPE_KEYS.AGGREGATION_LEVEL,
      DASHBOARD_TYPE_KEYS.OPERATION,
      DASHBOARD_TYPE_KEYS.OPTIONAL_FILTER_COLUMN,
      DASHBOARD_TYPE_KEYS.DATE_COLUMN,
    ],
    chartType: 'BAR',
  },
];

export const DUMMY_DASHBOARDS_OBJ = {
  [DASHBOARD_TYPES_V3.SIMPLE]: {
    id: DASHBOARD_TYPES_V3.SIMPLE,
    name: 'Simple Metrics',
    iconName: 'description',
    dashboards: [
      {
        isDummy: true,
        cardTitle: 'Basic Metrics',
        title: 'Metrics',
        value: '1,25,000',
        type: DASHBOARD_TYPES_V3.SIMPLE,
        subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SIMPLE].BASIC,
        id: 'A-1',
        keys: [
          DASHBOARD_TYPE_KEYS.NAME,
          DASHBOARD_TYPE_KEYS.COLUMN,
          DASHBOARD_TYPE_KEYS.SCREEN,
          DASHBOARD_TYPE_KEYS.VALUE_COLOR,
          DASHBOARD_TYPE_KEYS.FILTER,
          DASHBOARD_TYPE_KEYS.OPERATION,
        ],
      },
      // {
      //   cardTitle: 'Diff. in Value',
      //   title: 'Daily Sales',
      //   value: '₹ 88,750',
      //   indexVal: '34',
      //   type: DASHBOARD_TYPES_V3.SIMPLE,
      //   subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SIMPLE].DIFF_IN_VAL,
      //   id: 'A-2',
      //   keys: [
      //     DASHBOARD_TYPE_KEYS.NAME,
      //     DASHBOARD_TYPE_KEYS.COLUMN,
      //     DASHBOARD_TYPE_KEYS.SCREEN,
      //     DASHBOARD_TYPE_KEYS.VALUE_COLOR,
      //     DASHBOARD_TYPE_KEYS.FILTER,
      //     DASHBOARD_TYPE_KEYS.OPERATION,
      //     DASHBOARD_TYPE_KEYS.INDEX,
      //   ],
      // },
      // {
      //   cardTitle: 'Diff. in Percent',
      //   title: 'Daily Sales',
      //   value: '49,000',
      //   indexVal: '3.89',
      //   type: DASHBOARD_TYPES_V3.SIMPLE,
      //   subType:
      //     DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SIMPLE].DIFF_IN_PERCENTAGE,
      //   id: 'A-3',
      //   keys: [
      //     DASHBOARD_TYPE_KEYS.NAME,
      //     DASHBOARD_TYPE_KEYS.COLUMN,
      //     DASHBOARD_TYPE_KEYS.SCREEN,
      //     DASHBOARD_TYPE_KEYS.VALUE_COLOR,
      //     DASHBOARD_TYPE_KEYS.FILTER,
      //     DASHBOARD_TYPE_KEYS.OPERATION,
      //   ],
      // },
      {
        cardTitle: 'N Values Without Split By',
        title: 'Daily Sales',
        value: '₹ 8,98,760',
        columns: {
          value: {
            index: 1,
            name: 'Units',
            id: 'value',
          },
          value2: {
            index: 2,
            name: 'Bill Amount',
            id: 'value2',
          },
          value1: {
            index: 4,
            name: 'Rating',
            id: 'value1',
          },
        },
        valueArr: {
          value: '12.3K',
          value2: '334',
          value1: '9.38%',
        },
        type: DASHBOARD_TYPES_V3.SIMPLE,
        subType:
          DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SIMPLE].N_VALUES_WITHOUT_SPLIT,
        id: 'A-4',
        keys: [
          DASHBOARD_TYPE_KEYS.NAME,
          DASHBOARD_TYPE_KEYS.COLUMN,
          DASHBOARD_TYPE_KEYS.N_COLUMN,
          DASHBOARD_TYPE_KEYS.SCREEN,
          DASHBOARD_TYPE_KEYS.VALUE_COLOR,
          DASHBOARD_TYPE_KEYS.FILTER,
          DASHBOARD_TYPE_KEYS.OPERATION,
        ],
      },
    ],
  },
  [DASHBOARD_TYPES_V3.SPLIT_BY_VALUE]: {
    id: DASHBOARD_TYPES_V3.SPLIT_BY_VALUE,
    name: 'Split Value Boards',
    iconName: 'description',
    dashboards: [
      {
        isDummy: true,
        cardTitle: 'Split Value with Chart',
        title: 'Daily Sales',
        value: '₹ 8,98,760',
        valueArr: [
          {
            name: 'Microsoft',
            value: 245884,
            color: DASHBOARDS_CHART_COLORS[0],
          },
          {name: 'Amazon', value: 133485, color: DASHBOARDS_CHART_COLORS[1]},
          {name: 'Google', value: 129990, color: DASHBOARDS_CHART_COLORS[2]},
          {name: 'Tesla', value: 98389, color: DASHBOARDS_CHART_COLORS[3]},
          {name: 'Meta', value: 131209, color: DASHBOARDS_CHART_COLORS[4]},
        ],
        type: DASHBOARD_TYPES_V3.SPLIT_BY_VALUE,
        subType:
          DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SPLIT_BY_VALUE].CHART_AND_VALUE,
        id: 'B-1',
        keys: [
          DASHBOARD_TYPE_KEYS.NAME,
          DASHBOARD_TYPE_KEYS.COLUMN,
          DASHBOARD_TYPE_KEYS.SCREEN,
          DASHBOARD_TYPE_KEYS.VALUE_COLOR,
          DASHBOARD_TYPE_KEYS.FILTER,
          DASHBOARD_TYPE_KEYS.OPERATION,
          DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
        ],
      },
      {
        cardTitle: 'Split Values',
        title: 'Daily Sales',
        isDummy: true,
        value: '₹ 8,98,760',
        valueArr: [
          {
            name: 'Microsoft',
            value: 245884,
            color: DASHBOARDS_CHART_COLORS[0],
          },
          {name: 'Amazon', value: 133485, color: DASHBOARDS_CHART_COLORS[1]},
          {name: 'Google', value: 129990, color: DASHBOARDS_CHART_COLORS[2]},
          {name: 'Tesla', value: 98389, color: DASHBOARDS_CHART_COLORS[3]},
          {name: 'Meta', value: 131209, color: DASHBOARDS_CHART_COLORS[4]},
        ],
        type: DASHBOARD_TYPES_V3.SPLIT_BY_VALUE,
        subType:
          DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SPLIT_BY_VALUE].ONLY_VALUE,
        id: 'B-2',
        keys: [
          DASHBOARD_TYPE_KEYS.NAME,
          DASHBOARD_TYPE_KEYS.COLUMN,
          DASHBOARD_TYPE_KEYS.SCREEN,
          DASHBOARD_TYPE_KEYS.VALUE_COLOR,
          DASHBOARD_TYPE_KEYS.FILTER,
          DASHBOARD_TYPE_KEYS.OPERATION,
          DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
        ],
      },
      {
        cardTitle: 'Split Value only Chart',
        title: 'Daily Sales',
        isDummy: true,
        value: '₹ 8,98,760',
        valueArr: [
          {
            name: 'Microsoft',
            value: 245884,
            color: DASHBOARDS_CHART_COLORS[0],
          },
          {name: 'Amazon', value: 133485, color: DASHBOARDS_CHART_COLORS[1]},
          {name: 'Google', value: 129990, color: DASHBOARDS_CHART_COLORS[2]},
          {name: 'Tesla', value: 98389, color: DASHBOARDS_CHART_COLORS[3]},
          {name: 'Meta', value: 131209, color: DASHBOARDS_CHART_COLORS[4]},
        ],
        type: DASHBOARD_TYPES_V3.SPLIT_BY_VALUE,
        subType:
          DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SPLIT_BY_VALUE].ONLY_CHART,
        id: 'B-3',
        keys: [
          DASHBOARD_TYPE_KEYS.NAME,
          DASHBOARD_TYPE_KEYS.COLUMN,
          DASHBOARD_TYPE_KEYS.SCREEN,
          DASHBOARD_TYPE_KEYS.VALUE_COLOR,
          DASHBOARD_TYPE_KEYS.FILTER,
          DASHBOARD_TYPE_KEYS.OPERATION,
          DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
        ],
      },
      {
        cardTitle: 'Split Value with N columns',
        title: 'Daily Sales',
        isDummy: true,
        value: '₹ 8,98,760',
        columns: {
          value: {
            index: 1,
            name: 'Company',
            id: 'value',
          },
          value3: {
            index: 3,
            name: 'Revenue',
            id: 'value3',
          },
          value2: {
            index: 2,
            name: 'Products',
            id: 'value2',
          },
          value1: {
            index: 4,
            name: 'Employees',
            id: 'value1',
          },
        },
        valueArr: [
          {
            name: 'Microsoft',
            value3: 245884,
            value2: 234,
            value1: 454,
            color: DASHBOARDS_CHART_COLORS[0],
          },
          {
            name: 'Amazon',
            value3: 133485,
            color: DASHBOARDS_CHART_COLORS[1],
            value2: 2341,
            value1: 3454,
          },
          {
            name: 'Google',
            value3: 129990,
            color: DASHBOARDS_CHART_COLORS[2],
            value2: 6534,
            value1: 5354,
          },
          {
            name: 'Tesla',
            value3: 98389,
            color: DASHBOARDS_CHART_COLORS[3],
            value2: 1234,
            value1: 9454,
          },
          {
            name: 'Meta',
            value3: 131209,
            color: DASHBOARDS_CHART_COLORS[4],
            value2: 324,
            value1: 764,
          },
        ],
        type: DASHBOARD_TYPES_V3.SPLIT_BY_VALUE,
        subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.SPLIT_BY_VALUE].N_VALUES,
        id: 'B-4',
        keys: [
          DASHBOARD_TYPE_KEYS.NAME,
          DASHBOARD_TYPE_KEYS.COLUMN,
          DASHBOARD_TYPE_KEYS.N_COLUMN,
          DASHBOARD_TYPE_KEYS.SCREEN,
          DASHBOARD_TYPE_KEYS.VALUE_COLOR,
          DASHBOARD_TYPE_KEYS.FILTER,
          DASHBOARD_TYPE_KEYS.OPERATION,
          DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
        ],
      },
    ],
  },
  [DASHBOARD_TYPES_V3.MULTI_SPLIT]: {
    id: DASHBOARD_TYPES_V3.MULTI_SPLIT,
    name: 'Multiple Split Value Boards',
    iconName: 'description',
    dashboards: [
      {
        isDummy: true,
        cardTitle: 'Multiple Split Value with Chart',
        title: 'Daily Sales',
        value: '₹ 8,98,760',
        valueArr: [
          {
            name: 'Microsoft#@Product1',
            value: 245884,
            color: DASHBOARDS_CHART_COLORS[0],
          },
          {
            name: 'Amazon#@Product1',
            value: 133485,
            color: DASHBOARDS_CHART_COLORS[1],
          },
          {
            name: 'Google#@Product1',
            value: 129990,
            color: DASHBOARDS_CHART_COLORS[2],
          },
          {
            name: 'Tesla#@Product1',
            value: 98389,
            color: DASHBOARDS_CHART_COLORS[3],
          },
          {
            name: 'Meta#@Product1',
            value: 131209,
            color: DASHBOARDS_CHART_COLORS[4],
          },
        ],
        type: DASHBOARD_TYPES_V3.MULTI_SPLIT,
        subType:
          DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.MULTI_SPLIT].CHART_AND_VALUE,
        id: 'B-1',
        keys: [
          DASHBOARD_TYPE_KEYS.NAME,
          DASHBOARD_TYPE_KEYS.COLUMN,
          DASHBOARD_TYPE_KEYS.SCREEN,
          DASHBOARD_TYPE_KEYS.VALUE_COLOR,
          DASHBOARD_TYPE_KEYS.FILTER,
          DASHBOARD_TYPE_KEYS.OPERATION,
          DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
        ],
      },
      {
        cardTitle: 'Multi Split Values',
        title: 'Daily Sales',
        isDummy: true,
        value: '₹ 8,98,760',
        valueArr: [
          {
            name: 'Microsoft#@Product1',
            value: 245884,
            color: DASHBOARDS_CHART_COLORS[0],
          },
          {
            name: 'Amazon#@Product1',
            value: 133485,
            color: DASHBOARDS_CHART_COLORS[1],
          },
          {
            name: 'Google#@Product1',
            value: 129990,
            color: DASHBOARDS_CHART_COLORS[2],
          },
          {
            name: 'Tesla#@Product1',
            value: 98389,
            color: DASHBOARDS_CHART_COLORS[3],
          },
          {
            name: 'Meta#@Product1',
            value: 131209,
            color: DASHBOARDS_CHART_COLORS[4],
          },
        ],
        type: DASHBOARD_TYPES_V3.MULTI_SPLIT,
        subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.MULTI_SPLIT].ONLY_VALUE,
        id: 'B-2',
        keys: [
          DASHBOARD_TYPE_KEYS.NAME,
          DASHBOARD_TYPE_KEYS.COLUMN,
          DASHBOARD_TYPE_KEYS.SCREEN,
          DASHBOARD_TYPE_KEYS.VALUE_COLOR,
          DASHBOARD_TYPE_KEYS.FILTER,
          DASHBOARD_TYPE_KEYS.OPERATION,
          DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
        ],
      },
      {
        cardTitle: 'Multi Split Value only Chart',
        title: 'Daily Sales',
        isDummy: true,
        value: '₹ 8,98,760',
        valueArr: [
          {
            name: 'Microsoft#@Product1',
            value: 245884,
            color: DASHBOARDS_CHART_COLORS[0],
          },
          {
            name: 'Amazon#@Product1',
            value: 133485,
            color: DASHBOARDS_CHART_COLORS[1],
          },
          {
            name: 'Google#@Product1',
            value: 129990,
            color: DASHBOARDS_CHART_COLORS[2],
          },
          {
            name: 'Tesla#@Product1',
            value: 98389,
            color: DASHBOARDS_CHART_COLORS[3],
          },
          {
            name: 'Meta#@Product1',
            value: 131209,
            color: DASHBOARDS_CHART_COLORS[4],
          },
        ],
        type: DASHBOARD_TYPES_V3.MULTI_SPLIT,
        subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.MULTI_SPLIT].ONLY_CHART,
        id: 'B-3',
        keys: [
          DASHBOARD_TYPE_KEYS.NAME,
          DASHBOARD_TYPE_KEYS.COLUMN,
          DASHBOARD_TYPE_KEYS.SCREEN,
          DASHBOARD_TYPE_KEYS.VALUE_COLOR,
          DASHBOARD_TYPE_KEYS.FILTER,
          DASHBOARD_TYPE_KEYS.OPERATION,
          DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
        ],
      },
      {
        cardTitle: 'Multi Split Value with N columns',
        title: 'Daily Sales',
        isDummy: true,
        value: '₹ 8,98,760',
        columns: {
          value: {
            index: 1,
            name: 'Company',
            id: 'value',
          },
          value3: {
            index: 3,
            name: 'Revenue',
            id: 'value3',
          },
          value2: {
            index: 2,
            name: 'Products',
            id: 'value2',
          },
          value1: {
            index: 4,
            name: 'Employees',
            id: 'value1',
          },
          value4: {
            index: 5,
            name: 'Company',
            id: 'value',
          },
        },
        valueArr: [
          {
            name: 'Microsoft#@product1',
            value3: 245884,
            value2: 234,
            value1: 454,
            color: DASHBOARDS_CHART_COLORS[0],
          },
          {
            name: 'Amazon#@product1',
            value3: 133485,
            color: DASHBOARDS_CHART_COLORS[1],
            value2: 2341,
            value1: 3454,
          },
          {
            name: 'Google#@product1',
            value3: 129990,
            color: DASHBOARDS_CHART_COLORS[2],
            value2: 6534,
            value1: 5354,
          },
          {
            name: 'Tesla#@product1',
            value3: 98389,
            color: DASHBOARDS_CHART_COLORS[3],
            value2: 1234,
            value1: 9454,
          },
          {
            name: 'Meta#@product1',
            value3: 131209,
            color: DASHBOARDS_CHART_COLORS[4],
            value2: 324,
            value1: 764,
          },
        ],
        type: DASHBOARD_TYPES_V3.MULTI_SPLIT,
        subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.MULTI_SPLIT].N_VALUES,
        id: 'B-4',
        keys: [
          DASHBOARD_TYPE_KEYS.NAME,
          DASHBOARD_TYPE_KEYS.COLUMN,
          DASHBOARD_TYPE_KEYS.N_COLUMN,
          DASHBOARD_TYPE_KEYS.SCREEN,
          DASHBOARD_TYPE_KEYS.VALUE_COLOR,
          DASHBOARD_TYPE_KEYS.FILTER,
          DASHBOARD_TYPE_KEYS.OPERATION,
          DASHBOARD_TYPE_KEYS.SPLIT_BY_COLUMN,
        ],
      },
      {
        isDummy: true,
        cardTitle: 'Pivot Table',
        type: DASHBOARD_TYPES_V3.MULTI_SPLIT,
        subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.MULTI_SPLIT].PIVOT_TABLE,
        filters: [],
        dateColumn: {
          id: 'DATA_ADDED_BY',
          val: 'Data added timestamp',
        },
        screenDetails: {
          s_1717498418: {
            optionalFilterColumn: {},
            dateColumn: {
              id: 'DATA_ADDED_BY',
              val: 'Data added timestamp',
            },
            columnDetailsArr: [
              {
                index: 0,
                screenId: 's_1717498418',
                operation: 'TOTAL',
                id: 'Col_1717498369731',
                fieldType: 'NUMBER',
                val: 'Spendings',
                renderId: 'RE_SJ4c3B1JEa',
              },
            ],
            docId: 'VL66Ic5O2NXzpXeRSIKJ3R8AJWB3_1717498294485',
            splitBy: {},
            columnSplits: [
              {
                columnProperties: {},
                id: 'Col_1717498519954',
                aggregationLevel: 'MONTHLY',
                styleObj: {},
                dateFormat: 'MONTH',
                fieldType: 'DATE',
                val: 'Date',
                renderId: 'PV_Va2Ktw2G6F',
              },
            ],
            splitByArr: [],
            rowSplits: [
              {
                columnProperties: {},
                val: 'BU_Level',
                fieldType: 'TEXT',
                styleObj: {},
                id: 'default_colA',
                renderId: 'PV_HSl3lt72vc',
              },
            ],
          },
        },
        title: 'Sales',
        dashboardValue: {
          success: true,
          result: {
            pivotVals: {
              'BU1#@RE_SJ4c3B1JEa#@January 2025#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@March 2025#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@July 2024#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@November 2025#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@December 2025#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@January 2026#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@May 2026#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@August 2025#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@August 2026#@': 890,
              'BU1#@RE_SJ4c3B1JEa#@April 2026#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@September 2025#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@June 2026#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@December 2024#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@July 2024#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@April 2025#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@May 2026#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@October 2025#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@August 2025#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@July 2025#@': 345,
              'BU3#@RE_SJ4c3B1JEa#@August 2024#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@February 2026#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@June 2026#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@June 2024#@': 980,
              'BU3#@RE_SJ4c3B1JEa#@January 2025#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@December 2025#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@August 2026#@': 456,
              'BU3#@RE_SJ4c3B1JEa#@October 2024#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@July 2026#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@June 2025#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@March 2026#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@July 2025#@': 1000,
              'BU3#@RE_SJ4c3B1JEa#@April 2026#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@October 2025#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@February 2025#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@January 2026#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@May 2025#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@September 2024#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@June 2025#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@November 2024#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@November 2025#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@March 2025#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@September 2025#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@December 2024#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@August 2024#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@February 2026#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@March 2026#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@October 2024#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@July 2026#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@November 2024#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@June 2024#@': 480,
              'BU3#@RE_SJ4c3B1JEa#@May 2025#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@April 2025#@': 0,
              'BU1#@RE_SJ4c3B1JEa#@February 2025#@': 0,
              'BU3#@RE_SJ4c3B1JEa#@September 2024#@': 0,
            },
            grandTotal: {
              rowTotalValue: [
                [
                  2705, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2134, 0, 0, 0, 0, 0,
                  0, 0, 0, 0, 0, 0, 0, 1346, 6185,
                ],
              ],
              colTotalValue: [1281, 2034, 2870],
            },
            splitArrCols: [
              [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0,
              ],
              [],
            ],
            valueArrRows: [
              ['BU1', 'BU2', 'BU3'],
              [
                'BU1#@RE_SJ4c3B1JEa',
                'BU2#@RE_SJ4c3B1JEa',
                'BU3#@RE_SJ4c3B1JEa',
              ],
              [],
            ],
            splitArrRows: [[1, 1, 1], [0, 0, 0], []],
            valueArrCols: [
              [
                'June 2024',
                'July 2024',
                'August 2024',
                'September 2024',
                'October 2024',
                'November 2024',
                'December 2024',
                'January 2025',
                'February 2025',
                'March 2025',
                'April 2025',
                'May 2025',
                'June 2025',
                'July 2025',
                'August 2025',
                'September 2025',
                'October 2025',
                'November 2025',
                'December 2025',
                'January 2026',
                'February 2026',
                'March 2026',
                'April 2026',
                'May 2026',
                'June 2026',
                'July 2026',
                'August 2026',
              ],
              [],
            ],
            valueArr: [
              [
                'BU1#@RE_SJ4c3B1JEa#@June 2024#@',
                'BU2#@RE_SJ4c3B1JEa#@June 2024#@',
                'BU3#@RE_SJ4c3B1JEa#@June 2024#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@July 2024#@',
                'BU2#@RE_SJ4c3B1JEa#@July 2024#@',
                'BU3#@RE_SJ4c3B1JEa#@July 2024#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@August 2024#@',
                'BU2#@RE_SJ4c3B1JEa#@August 2024#@',
                'BU3#@RE_SJ4c3B1JEa#@August 2024#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@September 2024#@',
                'BU2#@RE_SJ4c3B1JEa#@September 2024#@',
                'BU3#@RE_SJ4c3B1JEa#@September 2024#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@October 2024#@',
                'BU2#@RE_SJ4c3B1JEa#@October 2024#@',
                'BU3#@RE_SJ4c3B1JEa#@October 2024#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@November 2024#@',
                'BU2#@RE_SJ4c3B1JEa#@November 2024#@',
                'BU3#@RE_SJ4c3B1JEa#@November 2024#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@December 2024#@',
                'BU2#@RE_SJ4c3B1JEa#@December 2024#@',
                'BU3#@RE_SJ4c3B1JEa#@December 2024#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@January 2025#@',
                'BU2#@RE_SJ4c3B1JEa#@January 2025#@',
                'BU3#@RE_SJ4c3B1JEa#@January 2025#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@February 2025#@',
                'BU2#@RE_SJ4c3B1JEa#@February 2025#@',
                'BU3#@RE_SJ4c3B1JEa#@February 2025#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@March 2025#@',
                'BU2#@RE_SJ4c3B1JEa#@March 2025#@',
                'BU3#@RE_SJ4c3B1JEa#@March 2025#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@April 2025#@',
                'BU2#@RE_SJ4c3B1JEa#@April 2025#@',
                'BU3#@RE_SJ4c3B1JEa#@April 2025#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@May 2025#@',
                'BU2#@RE_SJ4c3B1JEa#@May 2025#@',
                'BU3#@RE_SJ4c3B1JEa#@May 2025#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@June 2025#@',
                'BU2#@RE_SJ4c3B1JEa#@June 2025#@',
                'BU3#@RE_SJ4c3B1JEa#@June 2025#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@July 2025#@',
                'BU2#@RE_SJ4c3B1JEa#@July 2025#@',
                'BU3#@RE_SJ4c3B1JEa#@July 2025#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@August 2025#@',
                'BU2#@RE_SJ4c3B1JEa#@August 2025#@',
                'BU3#@RE_SJ4c3B1JEa#@August 2025#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@September 2025#@',
                'BU2#@RE_SJ4c3B1JEa#@September 2025#@',
                'BU3#@RE_SJ4c3B1JEa#@September 2025#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@October 2025#@',
                'BU2#@RE_SJ4c3B1JEa#@October 2025#@',
                'BU3#@RE_SJ4c3B1JEa#@October 2025#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@November 2025#@',
                'BU2#@RE_SJ4c3B1JEa#@November 2025#@',
                'BU3#@RE_SJ4c3B1JEa#@November 2025#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@December 2025#@',
                'BU2#@RE_SJ4c3B1JEa#@December 2025#@',
                'BU3#@RE_SJ4c3B1JEa#@December 2025#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@January 2026#@',
                'BU2#@RE_SJ4c3B1JEa#@January 2026#@',
                'BU3#@RE_SJ4c3B1JEa#@January 2026#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@February 2026#@',
                'BU2#@RE_SJ4c3B1JEa#@February 2026#@',
                'BU3#@RE_SJ4c3B1JEa#@February 2026#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@March 2026#@',
                'BU2#@RE_SJ4c3B1JEa#@March 2026#@',
                'BU3#@RE_SJ4c3B1JEa#@March 2026#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@April 2026#@',
                'BU2#@RE_SJ4c3B1JEa#@April 2026#@',
                'BU3#@RE_SJ4c3B1JEa#@April 2026#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@May 2026#@',
                'BU2#@RE_SJ4c3B1JEa#@May 2026#@',
                'BU3#@RE_SJ4c3B1JEa#@May 2026#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@June 2026#@',
                'BU2#@RE_SJ4c3B1JEa#@June 2026#@',
                'BU3#@RE_SJ4c3B1JEa#@June 2026#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@July 2026#@',
                'BU2#@RE_SJ4c3B1JEa#@July 2026#@',
                'BU3#@RE_SJ4c3B1JEa#@July 2026#@',
              ],
              [
                'BU1#@RE_SJ4c3B1JEa#@August 2026#@',
                'BU2#@RE_SJ4c3B1JEa#@August 2026#@',
                'BU3#@RE_SJ4c3B1JEa#@August 2026#@',
              ],
            ],
          },
        },
        id: 'uyJZvSr2bhBvVN49nktl',
      },
    ],
  },
  [DASHBOARD_TYPES_V3.LINE_AND_BAR]: {
    id: DASHBOARD_TYPES_V3.LINE_AND_BAR,
    name: 'Line and Bar graph',
    iconName: 'description',
    dashboards: [
      {
        isDummy: true,
        cardTitle: 'Timeline',
        type: DASHBOARD_TYPES_V3.LINE_AND_BAR,
        subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.LINE_AND_BAR]?.TIMELINE,
        chartType: 'BAR',
        title: 'Daily Sales',
        value: '₹ 8,98,760',
        // valueArr: [
        //   {name: 'Microsoft', value: 245884},
        //   {name: 'Amazon', value: 133485},
        //   {name: 'Google', value: 129990},
        //   {name: 'Tesla', value: 98389},
        //   {name: 'Meta', value: 131209},
        // ],
        valueArr: {
          maxYLabels: 245884,
          labels: ['Microsoft', 'Amazon', 'Google', 'Tesla', 'Meta'],
          datasets: [
            {
              data: [245884, 133485, 129990, 98389, 131209],
              barThickness: 16,
              backgroundColor: '#337EED',
            },
          ],
        },
      },
    ],
    // dashboards: [
    //   {
    // isDummy: true,
    // cardTitle: 'Line Bar Graph',

    // title: 'Line Bar Graph',
    //     value: '₹ 8,98,760',
    //     currency: '₹',
    //     valueArr: [
    //       {
    //         name: '23 Jan 2023',
    //         openingBalance: 1000,
    //         closingBalance: 960,
    //       },
    //       {
    //         name: '22 Jan 2023',
    //         openingBalance: 1000,
    //         closingBalance: 960,
    //       },
    //       {
    //         name: '21 Jan 2023',
    //         openingBalance: 1000,
    //         closingBalance: 960,
    //       },
    //       {
    //         name: '20 Jan 2023',
    //         openingBalance: 1000,
    //         closingBalance: 960,
    //       },
    //       {
    //         name: '19 Jan 2023',
    //         openingBalance: 1000,
    //         closingBalance: 960,
    //       },
    //     ],
    // type: DASHBOARD_TYPES_V3.LINE_AND_BAR,
    // subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.LINE_AND_BAR]?.subType,
    //     id: 'C-1',
    //   },
    // ],
  },
  [DASHBOARD_TYPES_V3.CASH_IN_AND_OUT]: {
    id: DASHBOARD_TYPES_V3.CASH_IN_AND_OUT,
    name: 'Special Dashboard',
    iconName: 'description',
    dashboards: [
      {
        isDummy: true,
        cardTitle: 'Credit / Debit Tracker',

        title: 'Credit / Debit Tracker',
        value: '₹ 8,98,760',
        currency: '₹',
        valueArr: [
          {
            name: '23 Jan 2023',
            openingBalance: 1000,
            closingBalance: 960,
            creditColumn: 1000,
            debitColumn: 40,
          },
          {
            name: '22 Jan 2023',
            openingBalance: 1000,
            closingBalance: 960,
            creditColumn: 1000,
            debitColumn: 40,
          },
          {
            name: '21 Jan 2023',
            openingBalance: 1000,
            closingBalance: 960,
            creditColumn: 1000,
            debitColumn: 40,
          },
          {
            name: '20 Jan 2023',
            openingBalance: 1000,
            closingBalance: 960,
            creditColumn: 1000,
            debitColumn: 40,
          },
          {
            name: '19 Jan 2023',
            openingBalance: 1000,
            closingBalance: 960,
            creditColumn: 1000,
            debitColumn: 40,
          },
        ],
        type: DASHBOARD_TYPES_V3.CASH_IN_AND_OUT,
        subType:
          DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.CASH_IN_AND_OUT].WITH_PARTY,
        id: 'C-1',
      },
      // {
      //   isDummy: true,
      //   cardTitle: 'Pivot Table',
      //   title: 'Pivot Table',
      //   valueArr: [],
      //   type: DASHBOARD_TYPES_V3.MULTI_SPLIT,
      //   subType: DASHBOARD_SUB_TYPE[DASHBOARD_TYPES_V3.MULTI_SPLIT].PIVOT_TABLE,
      //   id: 'C-1',
      // },
    ],
  },
};

export const DASHBOARD_CHART_TYPES = {
  BAR: 'BAR',
  LINE: 'LINE',
};

export const DASHBOARDS_ORDER_TYPES_ID = {
  BASIC_DASHBOARDS: 'basicDashboards',
  N_COLUMNS_BASIC_DASHBOARDS: 'nColumnBasicDashboards',
  N_COLUMNS_DASHBOARD: 'nColumnDashboards',
  CASHIN_DASHBOARDS: 'cashinDashboards',
  TIMELINE_DASHBOARDS: 'timelineDashboards',
  OTHER_DASHBOARDS: 'otherDashboards',
  MULTI_SPLIT_DASHBOARDS: 'multiSplitDashboard',
  ALL_DASHBOARDS: 'allDashboards',
};

export const DASHBOARDS_ORDER = [
  {id: DASHBOARDS_ORDER_TYPES_ID.ALL_DASHBOARDS, val: 'All Dashboards'},
  {id: DASHBOARDS_ORDER_TYPES_ID.BASIC_DASHBOARDS, val: 'Basic Dashboards'},
  {
    id: DASHBOARDS_ORDER_TYPES_ID.N_COLUMNS_BASIC_DASHBOARDS,
    val: 'N-Columns Basic Dashboards',
  },
  {
    id: DASHBOARDS_ORDER_TYPES_ID.N_COLUMNS_DASHBOARD,
    val: 'N-Columns Dashboards',
  },
  {id: DASHBOARDS_ORDER_TYPES_ID.CASHIN_DASHBOARDS, val: 'Special Dashboards'},
  {id: DASHBOARDS_ORDER_TYPES_ID.TIMELINE_DASHBOARDS, val: 'Line And Bar'},
  {id: DASHBOARDS_ORDER_TYPES_ID.OTHER_DASHBOARDS, val: 'Other Dashboards'},
];

export const PIVOT_ERROR_CODES = {
  TOO_MANY_CELLS:
    'This pivot table is trying to produce too many cells, make it smaller by adjusting rows and column breakouts or filtering source data.',
};

export const USER_COLUMN_DEFAULT_FIELD_CONFIG_FIELDS = [
  // {
  //   name: 'ID',
  //   fieldId: 'm_id',
  // },
  // {
  //   name: 'Department',
  //   fieldId: 'm_department',
  // },
  {
    name: 'Name',
    fieldId: 'm_name',
  },
  {
    name: 'User Image',
    fieldId: 'm_user_image',
  },
  {
    name: 'Email',
    fieldId: 'm_email',
  },
  {
    name: 'Phone',
    fieldId: 'm_phone',
  },
];

// TODO
// ****TEST / TO BE REMOVED ****
export const COLUMN_NOT_SUPPORTED_IN_DOC = [
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.LIST,
];

export const TIME_FILTER_DASHBOARD = {
  ALL_DATA: {
    id: 'ALL_DATA',
    text: 'All Data',
    filterOption: [],
  },
  TODAY: {
    id: 'TODAY',
    text: 'Today',
    filterOption: ['TODAY', null],
  },
  LAST_7_DAYS: {
    id: 'LAST_7_DAYS',
    text: 'Last 7 Days',
    filterOption: ['LAST_N_DAYS', 7],
  },
  LAST_30_DAYS: {
    id: 'LAST_30_DAYS',
    text: 'Last 30 Days',
    filterOption: ['LAST_N_DAYS', 30],
  },
  CURRENT_MONTH: {
    id: 'CURRENT_MONTH',
    text: 'This Month',
    filterOption: ['CURRENT_MONTH', null],
  },
  CUSTOM_RANGE: {
    id: 'CUSTOM_RANGE',
    text: 'Custom',
    filterOption: [null, null],
  },
};

export const TIME_FILTER_DATA_ADDED_TIMESTAMP = {
  id: 'DATA_ADDED_BY',
  val: 'Data added timestamp',
};

export const COLUMN_CONFIGURE_OPTIONS_ALLOWED_FIELDS = {
  [FIELD_TYPE_ID.STRING_FORMULA]: {
    title: 'Formula',
    val_postfix: '',
  },
  [FIELD_TYPE_ID.FORMULA]: {
    title: 'Formula',
    val_postfix: '',
  },
  [FIELD_TYPE_ID.LABEL]: {
    title: 'List Options',
    val_postfix: 'value(s)',
  },
  [FIELD_TYPE_ID.SELECT_POP_UP]: {
    title: 'List Options',
    val_postfix: 'value(s)',
  },
  [FIELD_TYPE_ID.TABLE]: {
    title: 'Linked Table',
    // val_postfix: 'file(s)',
    val_postfix: '',
  },
  [FIELD_TYPE_ID.DATE]: {
    title: 'Calculate Date',
    val_postfix: '',
    isOptional: true,
  },
  [FIELD_TYPE_ID.DATE_TIME]: {
    title: 'Calculate Date',
    val_postfix: '',
    isOptional: true,
  },
  [FIELD_TYPE_ID.NO_OF_DAYS]: {
    title: 'Field Configuration',
    val_postfix: '',
  },
  [FIELD_TYPE_ID.OTP]: {
    title: 'Verification Field',
    val_postfix: '',
  },
};
export const SECTION_TYPE = {
  LEFT: 'left-section',
  RIGHT: 'right-section',
};

export const SCREEN_TYPE = {
  FULL: 'full-section',
  HALF: 'half-section',
};
export const ALIGNMENT_TYPES = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};

export const RESTRICTED_FIELDS_FOR_IMPORT_EXCEL = [
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.STRING_FORMULA,
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.MAPS,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.LIST,
  FIELD_TYPE_ID.PDF,
  FIELD_TYPE_ID.AUTO_INCREMENT_ID,
  FIELD_TYPE_ID.NO_OF_DAYS,
  FIELD_TYPE_ID.OTP,
];

export const RESTRICTED_FIELDS_FOR_EXPORT_EXCEL = [
  FIELD_TYPE_ID.USER_COLUMN,
  FIELD_TYPE_ID.LIST,
  FIELD_TYPE_ID.PDF,
];

export const ALLOW_FORMULA_SUBTYPE_AUTOMATION = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.UNIT,
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.NO_OF_DAYS,
  // FIELD_TYPE_ID.TIME,
];

export const AUTOMATION_AGGREGATION_ALLOWED_TYPES = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.UNIT,
];

export const FIELD_TYPE_ACTIONS = {
  CALL: 'CALL',
  SMS: 'SMS',
  WHATSAPP: 'WHATSAPP',
  MAIL: 'MAIL',
};

export const ACTIONS_FOR_FIELDTYPE = {
  [FIELD_TYPE_ID.CONTACT]: {
    actionsList: [
      FIELD_TYPE_ACTIONS.CALL,
      FIELD_TYPE_ACTIONS.SMS,
      FIELD_TYPE_ACTIONS.WHATSAPP,
    ],
  },
  [FIELD_TYPE_ID.EMAIL]: {
    actionsList: [FIELD_TYPE_ACTIONS.MAIL],
  },
};

export const TOTAL_NOT_ALLOWED_OPERATIONS = [
  FOOTER_OPERATION_TYPES.COUNT,
  'CARDINALITY',
];

export const EXPLORE_LIO_STEPS_ARR = [
  'INSTALL_APP',
  'INVITE_TEAM_MEMBER',
  'SHARE_APP_TO_TEAM_MEMBER',
];

export const EXPLORE_LIO_STEPS = {
  INSTALL_APP: {
    text: 'Install App from Lio Store',
    id: 'INSTALL_APP',
  },
  INVITE_TEAM_MEMBER: {
    text: 'Invite a Team Member',
    id: 'INVITE_TEAM_MEMBER',
  },
  SHARE_APP_TO_TEAM_MEMBER: {
    text: 'Share an app to team member',
    id: 'SHARE_APP_TO_TEAM_MEMBER',
  },
};

export const EXPLORE_LIO_MINI_APP_STEPS_ARR = [
  'DELETE_SAMPLE_DATA',
  'ADD_ENTRY',
  'EDIT_ENTRY',
  'INVITE_TEAM_MEMBER',
];

export const EXPLORE_LIO_MINI_APP_STEPS = {
  DELETE_SAMPLE_DATA: {
    text: 'Delete Sample Data',
    id: 'DELETE_SAMPLE_DATA',
  },
  ADD_ENTRY: {
    text: 'Add a Entry',
    id: 'ADD_ENTRY',
  },
  EDIT_ENTRY: {
    text: 'Edit Entry',
    id: 'EDIT_ENTRY',
  },
  INVITE_TEAM_MEMBER: {
    text: 'Invite a Team Member',
    id: 'INVITE_TEAM_MEMBER',
  },
};

export const COLUMNS_SUPPORTED_FOR_QUICK_FILTER = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.EMAIL,
  FIELD_TYPE_ID.LABEL,
  FIELD_TYPE_ID.TABLE,
  FIELD_TYPE_ID.SWITCH,
  FIELD_TYPE_ID.CHECKBOX,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.SELECT_POP_UP,
  FIELD_TYPE_ID.STRING_FORMULA,
  FIELD_TYPE_ID.OTP,
];
export const TABLE_SUBTYPES_SUPPORTED_FOR_QUICK_FILTER = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.SELECT_POP_UP,
  // FIELD_TYPE_ID.LABEL,
];

export const SEARCH_NOT_ALLOWED_COLUMNS_QUICK_FILTER = [
  FIELD_TYPE_ID.CHECKBOX,
  FIELD_TYPE_ID.SWITCH,
  FIELD_TYPE_ID.OTP,
];

export const SORT_BY_COLUMN = {
  ALLOWED_TYPES: [
    FIELD_TYPE_ID.NUMBER,
    FIELD_TYPE_ID.RUPEE,
    FIELD_TYPE_ID.FORMULA,
    FIELD_TYPE_ID.STRING_FORMULA,
    FIELD_TYPE_ID.DATE,
    FIELD_TYPE_ID.TIME,
    FIELD_TYPE_ID.UNIT,
    FIELD_TYPE_ID.TEXT,
    FIELD_TYPE_ID.SELECT_POP_UP,
    FIELD_TYPE_ID.BARCODE,
    FIELD_TYPE_ID.TABLE,
    FIELD_TYPE_ID.AUTO_INCREMENT_ID,
    FIELD_TYPE_ID.DATE_TIME,
    FIELD_TYPE_ID.NO_OF_DAYS,
  ],
  DEFAULT_SORT: 'index',
  ADDED_DATE_TIME: 'ADDED_DATE_TIME',
  SORT: {
    ASC: 'Ascending',
    DESC: 'Descending',
  },
};
export const DATA_SORT_ORDER = [
  SORT_BY_COLUMN.SORT.ASC,
  SORT_BY_COLUMN.SORT.DESC,
];

export const OMIT_ROW_KEYS_FOR_COLIDS = [
  'rowId',
  'rowProperties',
  CREATED_INFO_COLUMN.id,
  USER_INFO_COLUMN.id,
];

export const ORG_ADD_ONS = {AUTO_MESSAGES: 'AUTO_MESSAGES'};

export const autoIncrementKeyValues = {
  ADD_PREFIX_TO_ID: 'addPrefixToID',
  INCREMENT_VALUE: 'incrementValue',
  IS_ADD_PREFIX_TO_ID: 'isaddPrefixToID',
  START_VALUE: 'startValue',
  RESET_VALUE: 'resetValue',
  GENERATE_MANUALLY: 'GENERATE_MANUALLY',
};
export const DASHBOARD_FORMULA = 'DASHBOARD_FORMULA';
export const FIELD_TYPE_CHANGE_NOT_ALLOWED = [FIELD_TYPE_ID.LIST];

export const SUMMARY_OPERATION_TYPE = {
  TOTAL: {id: 'TOTAL', name: 'Total'},
  AVERAGE: {id: 'AVERAGE', name: 'Average'},
  COUNT: {id: 'COUNT', name: 'Count'},
  MIN: {id: 'MIN', name: 'Min'},
  MAX: {id: 'MAX', name: 'Max'},
};

export const ALLOWED_FIELD_TYPES_FOR_PRIMARY_COLUMN = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.TIME,
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.CONTACT,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.EMAIL,
  FIELD_TYPE_ID.AUTO_INCREMENT_ID,
  FIELD_TYPE_ID.STRING_FORMULA,
];

export const ALLOWED_FIELD_TYPES_FOR_SUMMARY_COLUMN = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.UNIT,
  FIELD_TYPE_ID.TABLE,
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.AUTO_INCREMENT_ID,
  FIELD_TYPE_ID.NO_OF_DAYS,
];

export const CHECK_USERPREF_FOR_SELF_SERVING = false;

export const SUBSCRIPTION_GRACE_PERIOD = 3;
export const DUMMY_CATALOGUE_VALUES = [
  {
    colName: 'Column #1',
    value: 'Column Value',
  },
  {
    colName: 'Column #2',
    value: 'Column Value',
  },
  {
    colName: 'Column #3',
    value: 'Column Value',
  },
];
export const RECURRING_INTERVAL_CONSTANT = [
  {
    text: "Doesn't repeat",
    id: 'DOES_NOT_REPEAT',
    title: "Doesn't repeat",
    value: "Doesn't repeat",
  },
  {
    text: 'Daily',
    id: 'DAILY',
    title: 'Daily',
    value: 'Daily',
  },
  {
    text: 'Weekly',
    id: 'WEEKLY',
    title: 'Weekly',
    value: 'Weekly',
  },
  {
    text: 'Monthly',
    id: 'MONTHLY',
    title: 'Monthly',
    value: 'Monthly',
  },
  {
    text: 'Yearly',
    id: 'YEARLY',
    title: 'Yearly',
    value: 'Yearly',
  },
];

export const OPERATORS = [
  ',',
  '^',
  '?',
  ':',
  '==',
  '!=',
  '<',
  '>',
  '<=',
  '>=',
  '!',
  'pi',
  'e',
  'exp',
  'log',
  'sqrt',
  'sin',
  'cos',
  'tan',
  'abs',
  'round',
  'ceil',
  'floor',
  'sign',
  'min',
  'mean',
  'median',
  'max',
  'random',
  'isInteger',
  '+',
  '-',
  '/',
  'x',
  '(',
  ')',
  '%',
  '.',
];

export const MATH_FUNCTIONS_LIST = [
  {
    title: 'Constants & Symbols',
    data: [
      {
        val: ',',
        name: 'x, y',
        description: 'Comma separator between variables x and y.',
      },
      {
        val: '^',
        name: 'x ^ y',
        description: 'Calculates x to the power y.',
      },
      {
        val: '?',
        name: '? (Conditional expression)',
        description: 'x ? y : z',
      },
      {
        val: ':',
        name: ': (Conditional expression)',
        description: 'x ? y : z',
      },
      {
        val: '==',
        name: 'x == y',
        description: 'Checks if x is equal to y.',
      },
      {
        val: '!=',
        name: 'x != y',
        description: 'Checks if x is not equal to y.',
      },
      {
        val: '<',
        name: 'x < y',
        description: 'Checks if x is less than y.',
      },
      {
        val: '>',
        name: 'x > y',
        description: 'Checks if x is greater than y.',
      },
      {
        val: '<=',
        name: 'x <= y',
        description: 'Checks if x is less than or equal to y.',
      },
      {
        val: '>=',
        name: 'x >= y',
        description: 'Checks if x is greater than or equal to y.',
      },
      {
        val: '!',
        name: 'x!',
        description: 'Calculates the factorial of x.',
      },
      {val: 'pi', name: 'pi', description: 'Mathematical constant π (pi).'},
      {
        val: 'e',
        name: 'e',
        description: "Mathematical constant e (Euler's number).",
      },
    ],
  },
  {
    title: 'Exponential and Logarithmic',
    data: [
      {
        val: 'exp',
        name: 'exp(x)',
        description: 'Returns the exponential value of x.',
      },
      {
        val: 'log',
        name: 'log(x, base)',
        description: 'Returns the logarithm of x optionally with base base.',
      },
      {
        val: 'sqrt',
        name: 'sqrt(x)',
        description: 'Returns the square root of x.',
      },
    ],
  },
  {
    title: 'Trigonometric',
    data: [
      {val: 'sin', name: 'sin(x)', description: 'Returns the sine of x.'},
      {val: 'cos', name: 'cos(x)', description: 'Returns the cosine of x.'},
      {
        val: 'tan',
        name: 'tan(x)',
        description: 'Returns the tangent of x.',
      },
    ],
  },
  {
    title: 'Miscellaneous',
    data: [
      {
        val: 'abs',
        name: 'abs(x)',
        description: 'Returns the absolute value of x.',
      },
      {
        val: 'round',
        name: 'round(x, n)',
        description:
          'Round x towards the nearest rounded value with n (Default value: 0) decimals',
      },
      {
        val: 'ceil',
        name: 'ceil(x, n)',
        description:
          'Returns the smallest rounded value greater than or equal to x with n (Default value: 0) decimals.',
      },
      {
        val: 'floor',
        name: 'floor(x, n)',
        description:
          'Returns the largest rounded value less than or equal to x with n (Default value: 0) decimals.',
      },
      {
        val: 'sign',
        name: 'sign(x)',
        description:
          'Returns the sign of x (1 if positive, -1 if negative, 0 if zero).',
      },
      {
        val: 'mean',
        name: 'mean(x1, x2, ..., xn)',
        description:
          'Returns the arithmetic mean (average) of the given values.',
      },
      {
        val: 'median',
        name: 'median(x1, x2, ..., xn)',
        description: 'Returns the median value of the given values.',
      },
      {
        val: 'min',
        name: 'min(x1, x2, ..., xn)',
        description: 'Returns the minimum value among the given arguments.',
      },
      {
        val: 'max',
        name: 'max(x1, x2, ..., xn)',
        description: 'Returns the maximum value among the given arguments.',
      },
      {
        val: 'random',
        name: 'random()',
        description: 'Returns a pseudo-random number between 0 and 1.',
      },
      {
        val: 'isInteger',
        name: 'isInteger(x)',
        description: 'Returns a pseudo-random number between 0 and 1.',
      },
    ],
  },
];

export const ALL_CURRENCY_MAP = {
  USD: {
    symbol: '$',
    name: 'US Dollar',
    symbol_native: '$',
    decimal_digits: 2,
    rounding: 0,
    code: 'USD',
    name_plural: 'US dollars',
    popular: true,
  },
  INR: {
    symbol: 'Rs.',
    name: 'Indian Rupee',
    symbol_native: '₹',
    decimal_digits: 2,
    rounding: 0,
    code: 'INR',
    name_plural: 'Indian rupees',
  },
  AUD: {
    symbol: 'AU$',
    name: 'Australian Dollar',
    symbol_native: 'AU$',
    decimal_digits: 2,
    rounding: 0,
    code: 'AUD',
    name_plural: 'Australian dollars',
  },
  GBP: {
    symbol: '£',
    name: 'British Pound Sterling',
    symbol_native: '£',
    decimal_digits: 2,
    rounding: 0,
    code: 'GBP',
    name_plural: 'British pounds sterling',
  },
  EUR: {
    symbol: '€',
    name: 'Euro',
    symbol_native: '€',
    decimal_digits: 2,
    rounding: 0,
    code: 'EUR',
    name_plural: 'euros',
    popular: true,
  },
};

export const STRING_FUNCTIONS_LIST = [
  {
    title: 'String Operations',
    data: [
      {
        val: 'CAPITALIZE',
        name: 'Capitalize(text)',
        description: 'Capitalize the first letter of text',
      },
      {
        val: 'CONCAT',
        name: 'Concat(x,y,z,....)',
        description: 'Combine 2 or more texts',
      },
      {
        val: 'ENDSWITH',
        name: 'EndsWith(text,substring,trueResult,falseResult)',
        description:
          'Return trueResult if text ends with substring, otherwise falseResult',
      },
      {
        val: 'INCLUDES',
        name: 'Includes(text,substring,trueResult,falseResult)',
        description:
          'Return trueResult if text includes substring, otherwise falseResult',
      },
      {
        val: 'CHAIN',
        name: 'Chain ( fn1 (a,b) ; fn2 (þ,c) ; ...)',
        description:
          'Run functions fn1, fn2, ... in sequence with output of previous function(þ) as input to next function',
      },
      {
        val: 'FORMAT_CURRENCY',
        name: 'FormatCurrency(text, currencyCode)',
        description:
          'Format text/number as currency with currencyCode symbol (INR, USD, AUD, GBP, EUR)',
      },
      {
        val: 'FORMAT_DATE',
        name: 'FormatDate(text, format)',
        description:
          'This function takes a string as input and formats it into the desired date format ',
      },
      {
        val: 'INDEXOF',
        name: 'IndexOf(text,substring)',
        description:
          'Return the 1-based index of substring in text, or -1 if not found',
      },
      {
        val: 'LENGTH',
        name: 'Length(text)',
        description: 'Get the length of text',
      },
      {
        val: 'LOWER',
        name: 'Lower(text)',
        description: 'Convert text to lowercase',
      },
      {
        val: 'REPLACE',
        name: 'Replace(text,searchValue,newValue)',
        description:
          'Replace all occurrences of searchValue in text with newValue',
      },
      {
        val: 'REVERSE',
        name: 'Reverse(text)',
        description: 'Reverse the text',
      },
      {
        val: 'SPLIT',
        name: 'Split(text,delimiter,position)',
        description:
          'Split text by delimiter and return part at position (1-based, negative for from end)',
      },
      {
        val: 'STARTSWITH',
        name: 'StartsWith(text,substring,trueResult,falseResult)',
        description:
          'Return trueResult if text starts with substring, otherwise falseResult',
      },
      {
        val: 'SUBSTRING',
        name: 'Substring(text,start[,length])',
        description:
          'Extract substring from text starting at start position for length characters (optional)',
      },
      {
        val: 'TRIM',
        name: 'Trim(text)',
        description: 'Trim whitespace from both sides of text',
      },
      {
        val: 'UPPER',
        name: 'Upper(text)',
        description: 'Convert text to uppercase',
      },
    ],
  },
];

// User with below permissions are allowed to CHANGE values which have been Auto Captured
export const ALLOWED_PERMISSIONS_FOR_AUTO_CAPTURE_CHANGE = [
  MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.OWNER,
  MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.ADMIN,
];
export const CUSTOMISE_COLOR_OPTIONS_ID = {
  DEFAULT: 'DEFAULT',
  BASED_ON_CONDITION: 'BASED_ON_CONDITION',
};

export const CUSTOMISE_COLOR_OPTIONS = {
  [CUSTOMISE_COLOR_OPTIONS_ID.DEFAULT]: [
    {
      value: 'Default',
      color: '#000000',
      type: CUSTOMISE_COLOR_OPTIONS_ID.DEFAULT,
      id: 'DEFAULT',
    },
  ],
  [CUSTOMISE_COLOR_OPTIONS_ID.BASED_ON_CONDITION]: [
    {
      value: 'Positive Value',
      color: '#449F35',
      type: CUSTOMISE_COLOR_OPTIONS_ID.BASED_ON_CONDITION,
      id: 'POSITIVE',
    },
    {
      value: 'Negative Value',
      color: '#E02A2A',
      type: CUSTOMISE_COLOR_OPTIONS_ID.BASED_ON_CONDITION,
      id: 'NEGATIVE',
    },
  ],
};

export const CLEAR_OPTION_AVAILABLE_FIELD_TYPES = [
  FIELD_TYPE_ID.SELECT_POP_UP,
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.TIME,
  FIELD_TYPE_ID.DATE_TIME,
  FIELD_TYPE_ID.ASSIGN_TASK,
];

export const RESET_OPTION_AVAILABLE_FIELD_TYPES = [FIELD_TYPE_ID.FORMULA];

export const SETVALUE_COLUMN_VALUE_TYPES = {
  CUSTOM_VALUE: 'CUSTOM_VALUE',
  DEFAULT_CONST_VALUE: 'DEFAULT_CONST_VALUE',
  COLUMN: 'COLUMN',
  CONDITION: 'CONDITION',
};

export const SETVALUE_CUSTOM_VALUE_ARRAY = [
  SETVALUE_COLUMN_VALUE_TYPES.CUSTOM_VALUE,
  SETVALUE_COLUMN_VALUE_TYPES.DEFAULT_CONST_VALUE,
];

export const MAX_MULTI_SPLIT_COLUMN = 6;

export const SHOULD_CHECK_IF_CREATE_CUSTOM_APP_IS_DISABLED = true;
// ^ Use this constant to check if isCreateCustomAppDisabled
// flag needs to be checked for users while creating a new app
export const LIO_API_URL = require('../imports').isProduction
  ? 'https://api.lio.io'
  : 'https://api.getlio.io';

export const ROW_WEBHOOK = {
  EVENT_TYPES: {
    ADD: 'row.add',
    EDIT: 'row.edit',
    DELETE: 'row.delete',
  },
};

export const BOOLEAN_ARR = [
  {
    label: 'Yes',
    id: true,
  },
  {
    label: 'No',
    id: false,
  },
];

export const getChangeAssigneeProcessedRowsArr = ({
  documentData,
  checkedRecords,
  selectedColId,
  selectedAppMember,
}) => {
  const allRows = documentData?.rowIdDataMap;
  const rows = {};
  let finalRows = [];
  let oldRows = [];

  if (allRows) {
    checkedRecords.forEach((rowId) => {
      rows[rowId] = allRows[rowId];
    });
  }

  const keys = Object.keys(rows);
  keys.forEach((key) => finalRows.push(rows[key]));

  oldRows = [...finalRows];

  finalRows = finalRows.map((row) => {
    const selectedCol = row[selectedColId];
    const val = selectedCol?.val || {
      note: '',
      dueDate: undefined,
      priority: undefined,
      taskId: 'ORGANISATION_TASK',
      isCompleted: false,
    };
    const assignee = val?.assignee;

    return {
      ...row,
      [selectedColId]: {
        ...selectedCol,
        val: {
          ...val,
          assignee: {
            ...assignee,
            name: selectedAppMember?.displayName,
            uid: selectedAppMember?.m_uid,
            contact: selectedAppMember.contact,
          },
        },
      },
    };
  });

  const hasOldRows = !isEmpty(oldRows);

  return finalRows.map((item, index) => ({
    rowObj: item,
    ...(hasOldRows ? {prevRowObj: oldRows[index]} : {}),
  }));
};
export const CUSTOM_CONTACT_ID = 'CUSTOM_CONTACT';

export const CUSTOM_CONTACT = {
  id: CUSTOM_CONTACT_ID,
  text: 'Choose from contacts',
};

export const SYSTEM_GENERATED_FIELDS_FOR_WHATSAPP_WITH_DATA_ACTION_BUTTON = {
  APP_NAME: 'APP_NAME',
  SCREEN_NAME: 'SCREEN_NAME',
};

export const SYSTEM_GENERATED_FIELDS_DETAILS_FOR_WHATSAPP_WITH_DATA_ACTION_BUTTON =
  [
    {
      id: SYSTEM_GENERATED_FIELDS_FOR_WHATSAPP_WITH_DATA_ACTION_BUTTON.APP_NAME,
      val: 'App Name',
    },
    {
      id: SYSTEM_GENERATED_FIELDS_FOR_WHATSAPP_WITH_DATA_ACTION_BUTTON.SCREEN_NAME,
      val: 'Screen Name',
    },
  ];
export const OTHERS_SECTION_ID = 'OTHERS_SECTION';

export const INVALID_REORDER_TYPES = {
  OTHER_SECTION_NOT_IN_LAST: 'OTHER_SECTION_NOT_IN_LAST',
  EMPTY_SECTION: 'EMPTY_SECTION',
  WRONG_DEPENDENT_COLUMN_ORDER: 'WRONG_DEPENDENT_COLUMN_ORDER',
  INVALID_VISIBILITY_CONDITIONS_ORDER: 'INVALID_VISIBILITY_CONDITIONS_ORDER',
};
export const INVALID_REORDER_DETAILS = {
  OTHER_SECTION_NOT_IN_LAST: {
    detail: `"Other" section should always be placed in last`,
    appToast: `"Other" section should always be placed in last`,
  },
  EMPTY_SECTION: {
    detail: `One or more sections is empty`,
    appToast: `One or more sections is empty`,
  },
  WRONG_DEPENDENT_COLUMN_ORDER: {
    detail: `One or more dependent columns are placed above its primary column`,
    appToast: `One or more dependent columns are placed above its primary column`,
  },
  INVALID_VISIBILITY_CONDITIONS_ORDER: {
    detail: `Some of the columns which have dependency conditions on other columns are placed below them. A column with visibility conditions should be placed below it's dependent columns only`,
    appToast: `A column with visibility conditions should be placed below it's dependent columns only.`,
  },
};
export const FULL_CALENDAR_VIEW_MAPPING = {
  TITLE: 'title',
};

export const ALLOWED_FIELD_TYPES_FOR_TITLE_IN_FULLSCREEN_CALENDAR_VIEW = [
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.EMAIL,
  FIELD_TYPE_ID.CREATED_INFO,
];

export const NO_OF_DAYS_CONFIGS = () => {
  const CONFIG_FIELDS = {
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    WEEK_DAYS_TO_EXCLUDE: 'weekDaysToExclude',
    EXCLUDE_FROM: 'excludeFrom',
    EXCLUDE_TO: 'excludeTo',
  };

  const NO_OF_DAYS_CONFIGS_ORDER = [
    {
      id: CONFIG_FIELDS.START_DATE,
      text: 'Start Date Column',
    },
    {
      id: CONFIG_FIELDS.END_DATE,
      text: 'End Date Column',
    },
    {
      id: CONFIG_FIELDS.WEEK_DAYS_TO_EXCLUDE,
      text: 'Configure Days',
    },
    {
      id: CONFIG_FIELDS.EXCLUDE_FROM,
      text: 'Exclude Days Before',
    },
    {
      id: CONFIG_FIELDS.EXCLUDE_TO,
      text: 'Exclude Days After',
    },
  ];

  return {
    CONFIG_FIELDS,
    NO_OF_DAYS_CONFIGS_ORDER,
  };
};

export const WEEK_DAYS_ID = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

export const WEEK_DAYS_ORDER = [
  {
    id: WEEK_DAYS_ID.SUNDAY,
    text: 'Sunday',
    val: 'Sunday',
  },
  {
    id: WEEK_DAYS_ID.MONDAY,
    text: 'Monday',
    val: 'Monday',
  },
  {
    id: WEEK_DAYS_ID.TUESDAY,
    text: 'Tuesday',
    val: 'Tuesday',
  },
  {
    id: WEEK_DAYS_ID.WEDNESDAY,
    text: 'Wednesday',
    val: 'Wednesday',
  },
  {
    id: WEEK_DAYS_ID.THURSDAY,
    text: 'Thursday',
    val: 'Thursday',
  },
  {
    id: WEEK_DAYS_ID.FRIDAY,
    text: 'Friday',
    val: 'Friday',
  },
  {
    id: WEEK_DAYS_ID.SATURDAY,
    text: 'Saturday',
    val: 'Saturday',
  },
];

export const MINIAPPS_ONLY_ALLOWED_FIELDTYPES = [FIELD_TYPE_ID.NO_OF_DAYS];

export const ENTRY_STATUS_TYPES = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
};

export const RUNNING_AUTOMATION_TOAST_ID = 'automation-toast';
export const TABLE_LINK_VALUE_OVERRIDE_CONFIG_TYPES = {
  ALLOW_ONLY_ADMIN_OWNER: 'ALLOW_ONLY_ADMIN_OWNER',
  ALLOW_EVERYONE: 'ALLOW_EVERYONE',
};
export const TABLE_LINK_VALUE_OVERRIDE_CONFIG_DETAILS = {
  [TABLE_LINK_VALUE_OVERRIDE_CONFIG_TYPES.ALLOW_ONLY_ADMIN_OWNER]: {
    ID: TABLE_LINK_VALUE_OVERRIDE_CONFIG_TYPES.ALLOW_ONLY_ADMIN_OWNER,
    TITLE: 'Allow only admin/owner',
  },
  [TABLE_LINK_VALUE_OVERRIDE_CONFIG_TYPES.ALLOW_EVERYONE]: {
    ID: TABLE_LINK_VALUE_OVERRIDE_CONFIG_TYPES.ALLOW_EVERYONE,
    TITLE: 'Allow everyone',
  },
};
export const ALLOWED_SUBTYPES_FOR_TABLE_LINK_OVERWRITE = [
  FIELD_TYPE_ID.TEXT,
  FIELD_TYPE_ID.NUMBER,
  FIELD_TYPE_ID.RUPEE,
  FIELD_TYPE_ID.DATE,
  FIELD_TYPE_ID.TIME,
  FIELD_TYPE_ID.CONTACT,
  FIELD_TYPE_ID.UNIT,
  FIELD_TYPE_ID.ASSIGN_TASK,
  FIELD_TYPE_ID.EMAIL,
  FIELD_TYPE_ID.DATE_TIME,
];

export const TABLE_LINK_OVERWRITE_RELATED_KEYS = {
  IS_VALUE_OVERRIDEN: 'isValueOverriden',
  PREVIOUS_CELL_OBJECT: 'previousCellObject',
  IS_PARENT_VALUE_OVERRIDEN: 'isParentValueOverriden',
};
export const PERCENTAGE_MAP_DASHBOARD = {
  '25%': '23%',
  '50%': '48%',
  '75%': '73%',
  '100%': '100%',
};

export const NOT_ALLOWED_COLUMNS_TABLE_LINK_MODAL = [FIELD_TYPE_ID.LIST];

export const ORGANISATION_ACCESS_LEVEL = {
  ADMIN: 'ADMIN',
  TEAM_MANAGEMENT: 'TEAM_MANAGEMENT',
  SUBSCRIPTION: 'SUBSCRIPTION',
  // MEMBER: 'MEMBER',
  // OWNER: 'OWNER',
};

export const ORG_ROLE_CONSTANT = {
  ADMIN: 'ADMIN',
  ORGANISATION: 'ORGANISATION',
  TEAM_MANAGEMENT: 'TEAM_MANAGEMENT',
  SUBSCRIPTION: 'SUBSCRIPTION',
};
export const ORGANISATION_ACCESS_LEVEL_SCREEN = {
  ADMIN: [
    ORG_ROLE_CONSTANT.ORGANISATION,
    ORG_ROLE_CONSTANT.TEAM_MANAGEMENT,
    ORG_ROLE_CONSTANT.SUBSCRIPTION,
  ],
  TEAM_MANAGEMENT: [ORG_ROLE_CONSTANT.TEAM_MANAGEMENT],
  SUBSCRIPTION: [ORG_ROLE_CONSTANT.SUBSCRIPTION],
  OWNER: [
    ORG_ROLE_CONSTANT.ORGANISATION,
    ORG_ROLE_CONSTANT.TEAM_MANAGEMENT,
    ORG_ROLE_CONSTANT.SUBSCRIPTION,
  ],
  MEMBER: [],
  TEAM_MANAGEMENT_AND_SUBSCRIPTION: [
    ORG_ROLE_CONSTANT.TEAM_MANAGEMENT,
    ORG_ROLE_CONSTANT.SUBSCRIPTION,
  ],
};
export const VISIBILITY_CONDITION_TYPE = {
  COLUMN: 'COLUMN',
};

// ! FORMULA Case is Handled Differently
export const FIELD_TYPE_TO_OTHER_FIELD_TYPE_MAP = {
  [FIELD_TYPE_ID.TEXT]: [
    FIELD_TYPE_ID.TEXT,
    FIELD_TYPE_ID.AUTO_INCREMENT_ID,
    FIELD_TYPE_ID.SELECT_POP_UP,
    FIELD_TYPE_ID.LABEL,
    FIELD_TYPE_ID.NUMBER,
    FIELD_TYPE_ID.RUPEE,
    FIELD_TYPE_ID.FORMULA,
    FIELD_TYPE_ID.DATE,
    FIELD_TYPE_ID.SWITCH,
    FIELD_TYPE_ID.TIME,
    FIELD_TYPE_ID.CHECKBOX,
    FIELD_TYPE_ID.CONTACT,
    FIELD_TYPE_ID.UNIT,
    FIELD_TYPE_ID.MAPS,
    FIELD_TYPE_ID.BARCODE,
    FIELD_TYPE_ID.PERCENT,
    FIELD_TYPE_ID.TABLE,
    FIELD_TYPE_ID.ASSIGN_TASK,
    FIELD_TYPE_ID.URL,
    FIELD_TYPE_ID.CREATED_INFO,
    FIELD_TYPE_ID.EMAIL,
    FIELD_TYPE_ID.DATE_TIME,
    FIELD_TYPE_ID.NO_OF_DAYS,
    FIELD_TYPE_ID.STRING_FORMULA,
    FIELD_TYPE_ID.OTP,
  ],

  [FIELD_TYPE_ID.NUMBER]: PURE_NUMBER_FIELDS,
  [FIELD_TYPE_ID.RUPEE]: PURE_NUMBER_FIELDS,
  [FIELD_TYPE_ID.UNIT]: PURE_NUMBER_FIELDS,
  [FIELD_TYPE_ID.NO_OF_DAYS]: PURE_NUMBER_FIELDS,

  [FIELD_TYPE_ID.DATE]: [FIELD_TYPE_ID.DATE, FIELD_TYPE_ID.DATE_TIME],
  [FIELD_TYPE_ID.DATE_TIME]: [FIELD_TYPE_ID.DATE_TIME, FIELD_TYPE_ID.DATE],
  [FIELD_TYPE_ID.TIME]: [FIELD_TYPE_ID.TIME],

  [FIELD_TYPE_ID.SWITCH]: [FIELD_TYPE_ID.SWITCH, FIELD_TYPE_ID.CHECKBOX],
  [FIELD_TYPE_ID.CHECKBOX]: [FIELD_TYPE_ID.CHECKBOX, FIELD_TYPE_ID.SWITCH],
  [FIELD_TYPE_ID.OTP]: [FIELD_TYPE_ID.OTP],

  [FIELD_TYPE_ID.SELECT_POP_UP]: [FIELD_TYPE_ID.SELECT_POP_UP],
  [FIELD_TYPE_ID.LABEL]: [FIELD_TYPE_ID.LABEL],

  [FIELD_TYPE_ID.CONTACT]: [FIELD_TYPE_ID.CONTACT],
  [FIELD_TYPE_ID.MAPS]: [FIELD_TYPE_ID.MAPS],
  [FIELD_TYPE_ID.BARCODE]: [
    FIELD_TYPE_ID.BARCODE,
    FIELD_TYPE_ID.RUPEE,
    FIELD_TYPE_ID.NUMBER,
    FIELD_TYPE_ID.TEXT,
    FIELD_TYPE_ID.FORMULA,
    FIELD_TYPE_ID.STRING_FORMULA,
  ],
  [FIELD_TYPE_ID.ASSIGN_TASK]: [FIELD_TYPE_ID.ASSIGN_TASK],
  [FIELD_TYPE_ID.URL]: [FIELD_TYPE_ID.URL],
  [FIELD_TYPE_ID.EMAIL]: [FIELD_TYPE_ID.EMAIL],
  [FIELD_TYPE_ID.AUTO_INCREMENT_ID]: [FIELD_TYPE_ID.AUTO_INCREMENT_ID],
  // Document based
  [FIELD_TYPE_ID.IMAGE]: [FIELD_TYPE_ID.IMAGE],
  [FIELD_TYPE_ID.DOCUMENT]: [FIELD_TYPE_ID.DOCUMENT, FIELD_TYPE_ID.IMAGE],
  [FIELD_TYPE_ID.VIDEO]: [FIELD_TYPE_ID.VIDEO],
  [FIELD_TYPE_ID.AUDIO]: [FIELD_TYPE_ID.AUDIO],
};
export const COLUMN_VALIDATION_NON_SUPPORTED_COLUMNS = [
  FIELD_TYPE_ID.LIST,
  FIELD_TYPE_ID.FORMULA,
  FIELD_TYPE_ID.STRING_FORMULA,
  FIELD_TYPE_ID.NO_OF_DAYS,
  FIELD_TYPE_ID.AUTO_INCREMENT_ID,
  FIELD_TYPE_ID.URL,
  FIELD_TYPE_ID.PDF,
  FIELD_TYPE_ID.CHECKBOX,
  FIELD_TYPE_ID.REMINDER,
  FIELD_TYPE_ID.IMAGE,
  FIELD_TYPE_ID.AUDIO,
  FIELD_TYPE_ID.VIDEO,
  FIELD_TYPE_ID.COMMENT,
  FIELD_TYPE_ID.CREATED_INFO,
  FIELD_TYPE_ID.AUTO_INCREMENT_ID,
  FIELD_TYPE_ID.DOCUMENT,
  FIELD_TYPE_ID.BARCODE,
  FIELD_TYPE_ID.SWITCH,
  FIELD_TYPE_ID.STRING_FORMULA,
];

export const MINI_APPS_SCREEN_CUSTOM_VIEW_TYPE = {
  GRID: 'GRID',
  KANBAN: 'KANBAN',
  DEFAULT: 'DEFAULT',
};

export const MINI_APPS_SCREEN_CUSTOM_VIEW_LIST = [
  {
    id: MINI_APPS_SCREEN_CUSTOM_VIEW_TYPE.GRID,
    title: 'Grid',
  },
  {
    id: MINI_APPS_SCREEN_CUSTOM_VIEW_TYPE.KANBAN,
    title: 'Kanban',
  },
];

export const MINI_APPS_SCREEN_CUSTOM_VIEW_LIST_TITLE = {
  GRID: {
    id: MINI_APPS_SCREEN_CUSTOM_VIEW_TYPE.GRID,
    title: 'Table',
  },
  DEFAULT: {
    id: MINI_APPS_SCREEN_CUSTOM_VIEW_TYPE.DEFAULT,
    title: 'Default',
  },
};
export const FIELD_OPERATION_TYPE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
};

export const OTP_FIELD_COL_OPTIONS = {
  ALLOWED_FIELDS_FOR_OTP_VERIFICATION: [
    FIELD_TYPE_ID.EMAIL,
    FIELD_TYPE_ID.CONTACT,
  ],
  VERIFICATION_OPTIONS: {
    [FIELD_TYPE_ID.EMAIL]: [FIELD_TYPE_ID.EMAIL],
    [FIELD_TYPE_ID.CONTACT]: [CONTACT_OPTIONS.WHATSAPP, CONTACT_OPTIONS.SMS],
  },
};

export const OTP_MODES = [
  {
    id: 'SMS',
    val: 'SMS',
  },
  {
    id: 'WHATSAPP',
    val: 'WhatsApp',
  },
];

export const OTP_BUTTON_STATE = {
  GENERATE_OTP: {
    label: 'Send OTP',
    id: 'GENERATE_OTP',
  },
  VERIFICATION_PENDING: {
    label: 'Resend OTP',
    id: 'VERIFICATION_PENDING',
  },
};

export const OTP_BUTTON_STATUS = {
  GENERATE_OTP: 'GENERATE_OTP',
  VERIFICATION_PENDING: 'VERIFICATION_PENDING',
  VERIFIED: 'VERIFIED',
};

export const OTP_STATUS = {
  VERIFIED: 'VERIFIED',
  NOT_VERIFIED: 'NOT_VERIFIED',
};

export const OTP_STATUS_ARR = [
  {
    id: OTP_STATUS.VERIFIED,
    text: 'Verified',
    title: 'Verified',
    val: true,
  },
  {
    id: OTP_STATUS.NOT_VERIFIED,
    text: 'Not Verified',
    title: 'Not Verified',
    val: false,
  },
];

export const DATE_FORMAT_ARRAY = [
  'DD/MM/YYYY',
  'DD/MM/YY',
  'MM/DD/YYYY',
  'MM/DD/YY',
];

export const MINI_APPS_TRANSLATION_TEXT_KEY = {
  LANGUAGE_TEXT: 'languageText',
  APP_LANGUAGE_TEXT: 'appLanguageText',
  SCREEN_LANGUAGE_TEXT: 'screenLanguageText',
  ACTION_BTN_LANGUAGE_TEXT: 'actionBtnLanguageText',
  SCREEN_GROUP_LANGUAGE_TEXT: 'screenGroupLanguageText',
  FIELD_SECTION_LANGUAGE_TEXT: 'fieldSectionLanguageText',
  ENTRY_BUTTON_LANGUAGE_TEXT: 'entryBtnLanguageText',
};
