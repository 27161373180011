const months = new Map([
  ['january', 0],
  ['february', 1],
  ['march', 2],
  ['april', 3],
  ['may', 4],
  ['june', 5],
  ['july', 6],
  ['august', 7],
  ['september', 8],
  ['october', 9],
  ['november', 10],
  ['december', 11],
  ['jan', 0],
  ['feb', 1],
  ['mar', 2],
  ['apr', 3],
  ['jun', 5],
  ['jul', 6],
  ['aug', 7],
  ['sept', 8],
  ['oct', 9],
  ['nov', 10],
  ['dec', 11],
]);

const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();

function convertToISOFormat(dd, mm, yyyy) {
  let month; //, date;
  const year = Number(yyyy.length === 4 ? yyyy : '20' + yyyy);
  if (!year) throw 'invalid date format, year is invalid';
  if (months.has(mm)) month = months.get(mm) + 1;
  else {
    month = parseInt(mm);
    if (month < 1 || month > 12) throw 'invalid date format, month is invalid';
  }
  const date = parseInt(dd);
  const daysInMonth = getDaysInMonth(year, month);
  if (date < 0 || date > daysInMonth) throw 'invalid date format, invalid date';
  return `${year}-${month}-${date}`;
}

function is_dd_mm_yy([val1, val2, val3]) {
  try {
    return convertToISOFormat(val1, val2, val3);
  } catch (err) {
    console.log(err);
    return null;
  }
}

function is_mm_dd_yy([val1, val2, val3]) {
  try {
    return convertToISOFormat(val2, val1, val3);
  } catch (err) {
    console.log(err);
    return null;
  }
}

function is_yyyy_mm_dd([val1, val2, val3]) {
  try {
    return convertToISOFormat(val3, val2, val1);
  } catch (err) {
    console.log(err);
    return null;
  }
}

export function dateParser(val) {
  try {
    if (typeof val !== 'string')
      throw 'only string are accepted as params for converting into dates';
    const separators = {
      '/': 0,
      '-': 0,
      ' ': 0,
    };
    let separator_used;
    const len = val.length;
    let separator_found = false;
    for (let i = 0; i < len; i++) {
      if (separators[val[i]]) {
        separators[val[i]]++;
        if (separators[val[i]] == 2) {
          separator_found = true;
          separator_used = val[i];
          break;
        }
      }
    }

    if (!separator_found) throw 'invalid date format';

    const val_array = val.split(separator_used);

    if (val_array.length != 3) throw 'invalid date format';

    let iso_date_string = is_dd_mm_yy(val_array);
    if (iso_date_string) return new Date(iso_date_string).getTime() / 1000;

    iso_date_string = is_mm_dd_yy(val_array);
    if (iso_date_string) return new Date(iso_date_string).getTime() / 1000;

    iso_date_string = is_yyyy_mm_dd(val_array);
    if (iso_date_string) return new Date(iso_date_string).getTime() / 1000;

    throw "invalid date format, can't convert string to date";
  } catch (err) {
    console.log(err);
    return null;
  }
}

export function timeParser(val) {
  try {
    if (typeof val !== 'string')
      throw 'only string are accepted as params for converting into dates';
    // 12:00 pm
    let val_array = val.split(' ');
    val_array = [...val_array[0].split(':'), val_array[1]];
    let hrs = Number(val_array[0]);
    const min = Number(val_array[1]);
    const am_pm = val_array[2].toLocaleLowerCase();

    if (am_pm && !['am', 'pm'].includes(am_pm)) throw 'invalid time format';

    if (!hrs || !min || min < 0 || min > 60 || hrs < 0)
      throw 'invalid time format';

    if ((am_pm && hrs > 12) || hrs > 24) throw 'invalid time format';

    if (am_pm === 'pm') hrs += 12;

    var d = new Date();
    d.setHours(hrs);
    d.setMinutes(min);
    return d.getTime() / 1000;
  } catch (err) {
    console.log(err);
    return null;
  }
}
