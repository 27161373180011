import {ACTIVITY_LOG_ACTION} from '../actions/actionType';

const initialState = {
  settingLogs: {},
  lastSettingDoc: null,
  fileLogs: {},
  lastFileDoc: null,
  filter: {},
  isLoading: false,
  visibleLogs: [],
  isFilterEnabled: false,
  filterConfig: null,
};

const activityLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_LOG_ACTION.LOAD_ACCOUNT_ACTIVITY:
      return {
        ...state,
        settingLogs: action.payload.logs,
        lastSettingDoc: action.payload.lastDoc,
        isLoading: false,
      };
    case ACTIVITY_LOG_ACTION.START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIVITY_LOG_ACTION.LOAD_DOCUMENT_ACTIVITY:
      return {
        ...state,
        fileLogs: action.payload.logs,
        lastFileDoc: action.payload.lastDoc,
        isLoading: false,
      };
    case ACTIVITY_LOG_ACTION.UPDATE_FILTER:
      return {
        ...state,
        visibleLogs: action.payload.visibleLogs?.slice?.(),
        filterConfig: action.payload.filterConfig,
        isFilterEnabled: action.payload.isLoading ? false : true,
      };
    case ACTIVITY_LOG_ACTION.RESET_FILTER:
      return {
        ...state,
        visibleLogs: [],
        isFilterEnabled: false,
        filterConfig: null,
        lastSettingDoc: null,
        lastFileDoc: null,
        settingLogs: {},
        fileLogs: {},
      };
    case ACTIVITY_LOG_ACTION.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default activityLogReducer;
