import {forOwn, isEmpty, capitalize} from 'lodash';
import {captureError} from '../imports';
import {FIELD_TYPE_ID} from './constant';

const getColumnLikeOptionsFromAggsConfig = (aggsConfig, aggregationId) => {
  if (isEmpty(aggsConfig)) {
    return [];
  }
  const columnLikeOptions = [];
  try {
    forOwn(aggsConfig.screenDetails, (screenConfig) => {
      if (Array.isArray(screenConfig.columnDetailsArr)) {
        screenConfig.columnDetailsArr.forEach((column) => {
          const val = column?.customConfig?.columnLabel?.length
            ? column.customConfig.columnLabel
            : `${capitalize(column.operation)} of ${column.val}`;
          columnLikeOptions.push(
            Object.assign({}, column, {
              aggregationId,
              val: `${val} (${aggsConfig.title})`,
            }),
          );
        });
      }
    });
    if (Array.isArray(aggsConfig.formulaColumnArr)) {
      aggsConfig.formulaColumnArr.forEach((column) => {
        columnLikeOptions.push(
          Object.assign({}, column, {
            fieldType: FIELD_TYPE_ID.FORMULA,
            aggregationId,
            val: `${column.val} (${aggsConfig.title})`,
          }),
        );
      });
    }
  } catch (e) {
    captureError(e);
  }
  return columnLikeOptions;
};

export {getColumnLikeOptionsFromAggsConfig};
