// import firebase from 'firebase/app';
import 'firebase/remote-config';
import {remoteConfig} from 'rb-redux/imports';

// let remoteConfig;

export function initRemoteConfig() {
  // remoteConfig = firebase.remoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
  remoteConfig.defaultConfig = {
    dashboard_template_suggestions: [],
  };
  fetchRemoteConfig();
}

export async function fetchRemoteConfig() {
  const activated = await remoteConfig.fetchAndActivate();
  if (activated) {
    //console.log('Fetched values successfully activated.');
  } else {
    //console.log('Fetched values failed to activate.', remoteConfig);
  }
}

export function getRemoteConfigValue(key) {
  const val = remoteConfig.getValue(key).asString();
  try {
    const json = JSON.parse(val);
    return json;
  } catch (err) {
    return null;
  }
}

export const REMOTE_CONFIG_KEYS = {
  DASHBOARD_TEMPLATE_SUGGEATION: 'dashboard_template_suggestions',
  APPLY_RESTRICTIONS: 'applyRestrictions_v2',
  RESTRICTION_CONFIG: 'countryWiseRestrictions',
};

// export default remoteConfig;
