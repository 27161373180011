import {CONTACTS_ACTION} from '../actions/actionType';

const initialState = {
  isLoading: false,
  isLoadingCallLogs: false,
  contactsArr: [],
  emailArr: [],
  contactsAndEmailsArr: [],
  recentlySharedContacts: [],
  recentlySharedFormattedContacts: [],
  contactInfoIndexMapping: {}, //contact : index mapping for searching contact in *contactsAndEmailsArr*
  recentCalls: [],
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTACTS_ACTION.SET_RECENT_SHARED_CONTACT:
      return {
        ...state,
        recentlySharedContacts: action.payload,
      };
    case CONTACTS_ACTION.SET_RECENT_SHARED_FORMATTED_CONTACT:
      return {
        ...state,
        recentlySharedFormattedContacts: action.payload,
      };
    case CONTACTS_ACTION.SET_CALL_LOGS:
      return {
        ...state,
        recentCalls: action.payload,
        isLoadingCallLogs: false,
      };
    case CONTACTS_ACTION.LOAD_CONTACTS:
      return {
        ...state,
        contactsArr: action.payload.contacts,
        emailArr: action.payload.emails,
        contactsAndEmailsArr: action.payload.contactsAndEmails,
        isLoading: false,
        contactInfoIndexMapping: Object.assign(
          {},
          action.payload.contactInfoIndexMapping,
        ),
      };
    case CONTACTS_ACTION.FETCH_START:
      return {
        ...state,
        isLoading: true,
      };
    case CONTACTS_ACTION.FETCH_CALL_LOGS_START:
      return {
        ...state,
        isLoadingCallLogs: true,
      };
    case CONTACTS_ACTION.FETCH_CALL_LOGS_STOP:
      return {
        ...state,
        isLoadingCallLogs: false,
      };
    case CONTACTS_ACTION.LOAD_ALL_CONTACTS:
      return {
        ...state,
        contactsArr: action.payload.contacts,
        emailArr: action.payload.emails,
        contactsAndEmailsArr: action.payload.contactsAndEmails,
      };
    case CONTACTS_ACTION.RESET_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default contactsReducer;
