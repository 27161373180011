import {DOCS_FILTER_ACTIONS} from '../actions/actionType';
import {DOCUMENT_FILTER_TYPE} from '../actions/documentFilterAction';

const initialState = {
  team: false,
  filter: DOCUMENT_FILTER_TYPE.ALL,
};

const DocumentFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOCS_FILTER_ACTIONS.SET_DOCS_FILTER_TYPE:
      return {team: false, filter: action.payload};
    case DOCS_FILTER_ACTIONS.SET_DOCS_FILTER_TEAM:
      return {team: true, filter: action.payload};
    default:
      return state;
  }
};

export default DocumentFilterReducer;
