const RolePermissions = ({color}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.35 4.5C4.05 3.6 3.225 3 2.25 3C0.975 3 0 3.975 0 5.25C0 6.525 0.975 7.5 2.25 7.5C3.225 7.5 4.05 6.9 4.35 6H5.25V7.5H6.75V6H8.25V4.5H4.35ZM2.25 6C1.8 6 1.5 5.7 1.5 5.25C1.5 4.8 1.8 4.5 2.25 4.5C2.7 4.5 3 4.8 3 5.25C3 5.7 2.7 6 2.25 6ZM12 0C10.35 0 9 1.35 9 3C9 4.65 10.35 6 12 6C13.65 6 15 4.65 15 3C15 1.35 13.65 0 12 0ZM12 4.575C11.1 4.575 10.425 3.9 10.425 3C10.425 2.1 11.1 1.425 12 1.425C12.9 1.425 13.575 2.1 13.575 3C13.575 3.9 12.9 4.575 12 4.575ZM12 6.75C9.975 6.75 6 7.725 6 9.75V12H18V9.75C18 7.725 14.025 6.75 12 6.75ZM16.575 10.575H7.425V9.75C7.425 9.3 9.75 8.175 12 8.175C14.25 8.175 16.575 9.3 16.575 9.75V10.575Z"
        fill={color ? color : '#495A74'}
      />
    </svg>
  );
};
export default RolePermissions;
