import {firestore} from '../imports';
import DocumentsDB from './Documents/DocumentsDB';
import UsersDB from './Users/UsersDB';
import TemplatesDB from './Templates/TemplatesDB';
import FirestoreListener from './SnapshotListener';
import AutomationsDB from './Automations/AutomationsDB';
import BusinessDB from './Business/BusinessDB';
import MiniAppsStoreDB from './MiniApps/MiniAppsStoreDB';
import OrganisationsDB from './Organisations/OrganisationsDB';
import ElasticDashboardsDB from './ElasticDashboards/ElasticDashboardsDB';
import MiniApps from './MiniApps/MiniApps';
import MiniAppsUserWhatsAppTemplatesDB from './MiniAppsUserWhatsAppTemplates/MiniAppsUserWhatsAppTemplatesDB';
import MiniAppIntegrationsDB from './MiniAppIntegrations/MiniAppIntegrationsDB';
import MiniAppsReportsDB from './MiniApps/MiniAppsReportsDB';
import AggregationsDB from './MiniApps/AggregationsDB';

export default class FirestoreDB {
  static aggregations = new AggregationsDB();
  static automations = new AutomationsDB();
  static business = new BusinessDB();
  static documents = new DocumentsDB();
  static elasticDashboards = new ElasticDashboardsDB();
  static miniAppIntegrations = new MiniAppIntegrationsDB();
  static miniApps = new MiniApps();
  static miniAppsReports = new MiniAppsReportsDB();
  static miniAppsStore = new MiniAppsStoreDB();
  static miniAppsUserWhatsAppTemplates = new MiniAppsUserWhatsAppTemplatesDB();
  static organisations = new OrganisationsDB();
  static templates = new TemplatesDB();
  static users = new UsersDB();
  static FirestoreListener = FirestoreListener;
  static FieldValue() {
    return firestore(true).FieldValue;
  }
}
