const IconmanageScreens = ({color}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 7.75C1.8425 7.75 0.5 9.0925 0.5 10.75C0.5 12.4075 1.8425 13.75 3.5 13.75C5.1575 13.75 6.5 12.4075 6.5 10.75C6.5 9.0925 5.1575 7.75 3.5 7.75ZM3.5 12.25C2.675 12.25 2 11.575 2 10.75C2 9.925 2.675 9.25 3.5 9.25C4.325 9.25 5 9.925 5 10.75C5 11.575 4.325 12.25 3.5 12.25ZM3.5 0.25C1.8425 0.25 0.5 1.5925 0.5 3.25C0.5 4.9075 1.8425 6.25 3.5 6.25C5.1575 6.25 6.5 4.9075 6.5 3.25C6.5 1.5925 5.1575 0.25 3.5 0.25ZM8 1.75H15.5V3.25H8V1.75ZM8 12.25V10.75H15.5V12.25H8ZM8 6.25H15.5V7.75H8V6.25Z"
        fill={color ? color : '#495A74'}
      />
    </svg>
  );
};
export default IconmanageScreens;
