import {formatCurrency, formatUnit} from './utils';
import {FIELD_TYPE_ID} from './constant';

export const formatFooterAsHeader = (
  footerValue,
  columnDetails,
  fileObj = {},
  userCountry = 'IN',
) => {
  try {
    const {type, fieldType, subType, id, unitDependentColumn} = columnDetails;
    const columnType = type ?? fieldType;
    if (
      columnType === FIELD_TYPE_ID.RUPEE ||
      (columnType === FIELD_TYPE_ID.FORMULA && subType === FIELD_TYPE_ID.RUPEE)
    ) {
      footerValue = formatCurrency(footerValue, userCountry);
    } else if (
      columnType === FIELD_TYPE_ID.UNIT ||
      (columnType === FIELD_TYPE_ID.FORMULA && subType === FIELD_TYPE_ID.UNIT)
    ) {
      footerValue = formatUnit(
        footerValue,
        subType === FIELD_TYPE_ID.UNIT
          ? unitDependentColumn
            ? fileObj[unitDependentColumn]
            : {}
          : fileObj[id],
        userCountry,
      );
    }
  } catch (e) {}
  return footerValue;
};
