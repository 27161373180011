import {firestore} from '../../imports';

/**---------------- Automations Refs ----------------*/
export default class AutomationsDB {
  config(documentId, firestoreRef = null) {
    return (firestoreRef ?? firestore)()
      .collection('docAutomationConfig')
      .doc(documentId);
  }

  pending(documentId, firestoreRef = null) {
    return (firestoreRef ?? firestore)()
      .collection('pendingAutomations')
      .doc(documentId);
  }

  scheduledCol(documentId, firestoreRef = null) {
    return this.pending(documentId, firestoreRef).collection('scheduled');
  }

  scheduled(documentId, scheduleId, firestoreRef = null) {
    return this.scheduledCol(documentId, firestoreRef).doc(scheduleId);
  }

  automationsCol(firestoreRef = null) {
    return (firestoreRef ?? firestore)().collection('automations');
  }

  integrationTemplates(docId, firestoreRef = null) {
    return (firestoreRef ?? firestore)()
      .collection('miniAppsUserWhatsAppTemplates')
      .doc(docId)
      .collection('automationTemplates');
  }

  dailyAutomationsCol(firestoreRef = null) {
    return (firestoreRef ?? firestore)().collection('dailyAutomations');
  }

  daily(docId, firestoreRef = null) {
    return this.dailyAutomationsCol(firestoreRef).doc(docId);
  }

  filters(firestoreRef = null) {
    return (firestoreRef ?? firestore)()
      .collection('automationConfig')
      .doc('filters');
  }

  pushNotificationLogs() {
    return firestore().collection('pushNotificationLogs');
  }

  fetchPushNotifications(uid, lastVisibleTimestamp) {
    let query = firestore()
      .collection('pushNotificationLogs')
      .where('uid', '==', uid)
      .orderBy('createdAt', 'desc')
      .limit(10);

    if (lastVisibleTimestamp) {
      query = query.startAfter(lastVisibleTimestamp);
    }

    return query.get();
  }

  fetchFromLastFetchedNotifications(uid, lastFetched, limit = null) {
    const query = firestore()
      .collection('pushNotificationLogs')
      .where('uid', '==', uid)
      .orderBy('createdAt', 'desc')
      .endAt(lastFetched);

    if (limit) {
      return query.limit(limit).get();
    }

    return query.get();
  }

  automationTemplatesCol() {
    return firestore().collection('automationTemplates');
  }
}
