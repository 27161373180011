import {MINI_APPS} from '../constant';
import moment from 'moment';
import {checkIfValueExistOnCell, getLocalText} from '../utils';
import {getReduxState} from '../../imports';

const {
  MINI_APPS_SHARE_PERMISSION_TYPE: PERMISSION,
  CUSTOM_ENTRY_ACCESS,
  CUSTOM_ENTRY_PROPERTY,
  CUSTOM_DELETE_ROW_ACCESS,
  CUSTOM_IMPORT_EXPORT_PROPERTY,
} = MINI_APPS;

const MINI_APP_FEATURE_ACCESS_MAPPING = {
  APP_SETTINGS: [PERMISSION.ADMIN],
  EDIT_FIELDS: [PERMISSION.ADMIN],
  EDIT_APP_META: [PERMISSION.ADMIN],
  ACTION_BUTTONS: [PERMISSION.ADMIN],
  SCREEN_SETTINGS: [PERMISSION.ADMIN],
  SCREEN_LAYOUTS: [PERMISSION.ADMIN],
  MANAGE_SCREENS: [
    PERMISSION.ADMIN,
    PERMISSION.EDIT,
    PERMISSION.VIEW,
    PERMISSION.CUSTOM_ROLE,
  ],
  SORT_BY: [PERMISSION.ADMIN],
  REORDER_SCREENS: [PERMISSION.ADMIN],
  QUICK_FILTERS: [PERMISSION.ADMIN],
  AUTOMATIONS: [PERMISSION.ADMIN],
  CUSTOM_ROLES: [PERMISSION.ADMIN],
  DELETE_APP: [],
  ADD_SCREEN: [PERMISSION.ADMIN],
  SHARE_APP: [PERMISSION.ADMIN],
  ENDPOINTS: [PERMISSION.ADMIN],
  AGGREGATIONS: [PERMISSION.ADMIN],
  AUTOMATION_SETTINGS: [PERMISSION.ADMIN],
  INTEGRATION: [PERMISSION.ADMIN],
  ADD_ENTRY: [PERMISSION.ADMIN, PERMISSION.EDIT, PERMISSION.CUSTOM_ROLE],
  EDIT_ENTRY: [PERMISSION.ADMIN, PERMISSION.EDIT, PERMISSION.CUSTOM_ROLE],
  DELETE_ENTRY: [PERMISSION.ADMIN, PERMISSION.EDIT, PERMISSION.CUSTOM_ROLE],
  DUPLICATE_ENTRY: [PERMISSION.ADMIN, PERMISSION.EDIT, PERMISSION.CUSTOM_ROLE],
  ADD_DASHBOARDS: [PERMISSION.ADMIN],
  EDIT_DASHBOARDS: [PERMISSION.ADMIN],
  ADD_REPORTS: [PERMISSION.ADMIN, PERMISSION.EDIT],
  EDIT_REPORTS: [PERMISSION.ADMIN, PERMISSION.EDIT],
  IMPORT_DATA: [PERMISSION.ADMIN, PERMISSION.EDIT, PERMISSION.CUSTOM_ROLE],
  EXPORT_DATA: [
    PERMISSION.ADMIN,
    PERMISSION.EDIT,
    PERMISSION.VIEW,
    PERMISSION.CUSTOM_ROLE,
  ],
  AUTO_CAPTURE_CHANGE_ASSIGNEE: [PERMISSION.ADMIN],
  CHANGE_ASSIGNEE: [PERMISSION.ADMIN, PERMISSION.EDIT, PERMISSION.CUSTOM_ROLE],
};

const ACTIONS_EVENT = {
  ADD_ENTRY: 'ADD_ENTRY',
  DUPLICATE_ENTRY: 'ADD_ENTRY', //equivalent to add entry
  EDIT_ENTRY: 'EDIT_ENTRY',
  DELETE_ENTRY: 'DELETE_ENTRY',
  IMPORT_DATA: 'IMPORT_DATA',
  EXPORT_DATA: 'EXPORT_DATA',
};

export default class MiniAppsAccessManager {
  constructor(userUID, activeAppMeta, customRoleData = null) {
    this.activeAppMeta = Object.assign({}, activeAppMeta);
    customRoleData = Object.assign({}, customRoleData);
    [this.userPermission, this.permissionConfig] = this.getUserPermission(
      activeAppMeta,
      userUID,
      customRoleData,
    );
  }

  static ACTIONS = MINI_APP_FEATURE_ACCESS_MAPPING;
  static ACTIONS_EVENT = ACTIONS_EVENT;

  getUserPermission(activeAppMeta, userUID, customRoleData) {
    const permissionConfig = {};
    if (activeAppMeta.appOwner?.uid === userUID) {
      return [PERMISSION.OWNER, permissionConfig];
    }
    const sharedUserPermission =
      activeAppMeta.sharedWith?.[userUID]?.permission ?? null;
    if (sharedUserPermission === PERMISSION.CUSTOM_ROLE) {
      Object.assign(
        permissionConfig,
        Object.assign({}, customRoleData?.screenConfiguration),
      );
    }
    return [sharedUserPermission, permissionConfig];
  }

  isAllowed = (
    featureArr,
    showAlert = false,
    actionEvent = null,
    extraData = {},
  ) => {
    const activeScreenMeta =
      this.activeAppMeta?.screens?.[extraData?.screenId] ?? {};
    let isAllowed =
      this.userPermission === PERMISSION.OWNER ||
      Boolean(featureArr?.includes?.(this.userPermission));
    if (isAllowed && actionEvent in ACTIONS_EVENT) {
      //checks for all the users
      switch (actionEvent) {
        case ACTIONS_EVENT.ADD_ENTRY: {
          isAllowed = activeScreenMeta.disableEntry !== true;
          break;
        }
        case ACTIONS_EVENT.EDIT_ENTRY: {
          isAllowed = activeScreenMeta.disableEdit !== true;
          break;
        }
        case ACTIONS_EVENT.IMPORT_DATA: {
          isAllowed = activeScreenMeta.disableEntry !== true;
          break;
        }
      }
      if (isAllowed && this.userPermission === PERMISSION.CUSTOM_ROLE) {
        //checks for only custom roles
        const isScreenShared =
          extraData?.screenId && extraData.screenId in this.permissionConfig;
        if (isScreenShared) {
          const activeScreenRestrictions = Object.assign(
            {},
            this.permissionConfig[extraData.screenId],
          );
          switch (actionEvent) {
            case ACTIONS_EVENT.ADD_ENTRY: {
              isAllowed =
                activeScreenRestrictions.entryAccess !==
                CUSTOM_ENTRY_ACCESS.CANNOT_ADD_DATA;
              break;
            }
            case ACTIONS_EVENT.EDIT_ENTRY: {
              isAllowed =
                activeScreenRestrictions.entryProperties !==
                CUSTOM_ENTRY_PROPERTY.CANNOT_EDIT_DATA;
              if (isAllowed) {
                const rowObj = Object.assign({}, extraData?.rowObj);
                if (
                  activeScreenRestrictions.entryProperties ===
                  CUSTOM_ENTRY_PROPERTY.CAN_EDIT_END_OF_DAY
                ) {
                  const rowProperties = Object.assign({}, rowObj.rowProperties);
                  isAllowed = moment(moment().format('YYYY-MM-DD')).isSame(
                    moment(rowProperties.firstAddedTimestamp)
                      .utc(true)
                      .format('YYYY-MM-DD'),
                  );
                } else if (
                  activeScreenRestrictions.entryProperties ===
                  CUSTOM_ENTRY_PROPERTY.CAN_EDIT_MISSING_VALUES
                ) {
                  isAllowed = Array.isArray(extraData?.headerData)
                    ? extraData.headerData.some((colObj) => {
                        const colRestriction =
                          activeScreenRestrictions.columnRestrictions?.[
                            colObj.id
                          ];
                        if (
                          !colRestriction?.isReadOnly &&
                          !colRestriction?.isHidden
                        ) {
                          return !checkIfValueExistOnCell(
                            rowObj?.[colObj?.id] ?? {},
                            colObj,
                            rowObj,
                          );
                        }
                        return false;
                      })
                    : false;
                }
              }
              break;
            }
            case ACTIONS_EVENT.DELETE_ENTRY: {
              isAllowed =
                activeScreenRestrictions.deleteAccess !==
                CUSTOM_DELETE_ROW_ACCESS.CANNOT_DELETE_DATA;
              break;
            }
            case ACTIONS_EVENT.IMPORT_DATA: {
              isAllowed =
                activeScreenRestrictions.importExport?.[
                  CUSTOM_IMPORT_EXPORT_PROPERTY.CAN_IMPORT_DATA
                ] === true;
              break;
            }
            case ACTIONS_EVENT.EXPORT_DATA: {
              isAllowed =
                activeScreenRestrictions.importExport?.[
                  CUSTOM_IMPORT_EXPORT_PROPERTY.CAN_EXPORT_DATA
                ] === true;
              break;
            }
          }
        } else {
          isAllowed = false;
        }
      }
    }
    if (showAlert && !isAllowed) {
      alert(
        getLocalText(
          getReduxState().auth.userPref,
          "You don't have permission to perform this action.",
        ),
      );
    }
    return isAllowed;
  };
}
