export const SERVICE_TYPE = {
  IMPORT: 'IMPORT',
  EXPORT: 'EXPORT',
  DELETE_ROWS: 'DELETE_ROWS',
  GENERATE_PDF: 'GENERATE_PDF',
  AUTOMATION: 'AUTOMATION',
  FORMULA_UPDATE: 'FORMULA_UPDATE',
};

export const NOTIFICATION_TYPE = {
  REQUESTED: 'REQUESTED',
  RUNNING: 'RUNNING',
  INFO: 'INFO',
  WARNING: 'WARNING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const SERVICE_DETAILS = {
  [SERVICE_TYPE.IMPORT]: {
    isEnabled: true,
    notificationTypes: [
      NOTIFICATION_TYPE.REQUESTED,
      NOTIFICATION_TYPE.RUNNING,
      NOTIFICATION_TYPE.SUCCESS,
      NOTIFICATION_TYPE.ERROR,
    ],
  },
  [SERVICE_TYPE.EXPORT]: {
    isEnabled: true,
    notificationTypes: [
      NOTIFICATION_TYPE.REQUESTED,
      NOTIFICATION_TYPE.RUNNING,
      NOTIFICATION_TYPE.SUCCESS,
      NOTIFICATION_TYPE.ERROR,
    ],
  },
  [SERVICE_TYPE.DELETE_ROWS]: {
    isEnabled: true,
    notificationTypes: [
      NOTIFICATION_TYPE.REQUESTED,
      NOTIFICATION_TYPE.RUNNING,
      NOTIFICATION_TYPE.SUCCESS,
      NOTIFICATION_TYPE.ERROR,
    ],
  },
  [SERVICE_TYPE.GENERATE_PDF]: {
    isEnabled: true,
    notificationTypes: [
      NOTIFICATION_TYPE.REQUESTED,
      NOTIFICATION_TYPE.RUNNING,
      NOTIFICATION_TYPE.SUCCESS,
      NOTIFICATION_TYPE.ERROR,
    ],
  },
  [SERVICE_TYPE.AUTOMATION]: {
    isEnabled: true,
    notificationTypes: [
      NOTIFICATION_TYPE.REQUESTED,
      NOTIFICATION_TYPE.RUNNING,
      NOTIFICATION_TYPE.SUCCESS,
      NOTIFICATION_TYPE.ERROR,
    ],
  },
  [SERVICE_TYPE.FORMULA_UPDATE]: {
    isEnabled: true,
    notificationTypes: [
      NOTIFICATION_TYPE.SUCCESS,
      NOTIFICATION_TYPE.ERROR,
      NOTIFICATION_TYPE.INFO,
    ],
  },
};
