import {TABLE_ACTION} from '../../../actions/actionType';
import {
  checkAndUpdateEntryOnlyData,
  prepareTableDataForFirestore,
} from '../tableActionHelper';
import {updateAllDashboardsProperty} from '../dashboardActionHelper';
import {
  cloneDeep,
  forOwn,
  get,
  isArray,
  isEmpty,
  isEqual,
  isFunction,
  isNil,
  isNumber,
  isString,
  omit,
} from 'lodash';
import {captureError, firestore} from '../../../imports';
import {handleTaskUndoRedo} from './undoRedoHelper';
import {modifyTaskActiveState} from '../tasksActionHelper';
import {FIELD_TYPE_ID} from '../../../utils/constant';
import {EXTRA_ACTIONS_UNDO_REDO_TYPE} from '../../../utils/undoRedoConstants';
import DocumentsMethods from '../../../FirestoreHandlers/Documents/DocumentsMethods';
import {getPrintTime} from '../../../utils/utils';

export const extraActionsHandler = (actions = [], extras = {}) => {
  const {latestReduxState = {}} = extras ?? {};
  try {
    actions.forEach((item) => {
      if (item.type === EXTRA_ACTIONS_UNDO_REDO_TYPE.CLOUD_FUNCTION_CALL) {
        const data = {
          type: item.type,
          callback: item.callback,
        };

        /** Callback should be a cloud function call which should execute for undo/redo action. */
        if (isFunction(data.callback)) {
          data.callback();
        }
      } else {
        const data = {
          updateObjType: item.updateObjType, // "array"
          elementType: item.elementType, // string,
          element: item.element, // "aslkfjasdfkjl_123123"
          action: item.action, // "ADD"
          collection: item.collection, // "users",
          path: item.path, // "<uid>/<'documents'/'sharedDocs'>/<doc_id>",
          updatePath: item.updatePath, // "/linkedDocIds"
          updateAtPath: item?.updateAtPath, // "true" or "false"
          removeParentObjIfEmpty: item?.removeParentObjIfEmpty,
          runAfterParentObjRemove: item?.runAfterParentObjRemove,
          runAfterParentObjEmptyRemove: item?.runAfterParentObjEmptyRemove,
          runAfterParentObjAdd: item?.runAfterParentObjAdd,
          addIdPathIfNotExist: item?.addIdPathIfNotExist,
          reduxElementType: item?.reduxElementType,
          reduxCheckPath: item?.reduxCheckPath,
          reduxOperation: item?.reduxOperation,
          reduxCheckValue: item?.reduxCheckValue,
        };
        const getRef = (collection, path) => {
          return firestore().collection(collection).doc(path).get();
        };
        const updateSetRef = (collection, path) => {
          return firestore().collection(collection).doc(path);
        };
        getRef(data.collection, data.path)
          .then((pathData) => {
            const currentData = pathData.data();
            if (data?.addIdPathIfNotExist ? true : !isEmpty(currentData)) {
              const updatePathData = currentData?.[data.updatePath];
              if (data.action === 'ADD') {
                if (
                  data.updateObjType === 'array' &&
                  data.elementType === 'string'
                ) {
                  if (isArray(updatePathData)) {
                    if (!updatePathData.includes(data.element)) {
                      updateSetRef(data.collection, data.path)
                        .set(
                          {
                            [`${data.updatePath}`]: [
                              ...updatePathData,
                              data.element,
                            ],
                          },
                          {merge: true},
                        )
                        .catch((err) => {
                          captureError(err);
                        });
                    }
                  } else {
                    const toAddData = [data.element];
                    updateSetRef(data.collection, data.path)
                      .set({[`${data.updatePath}`]: toAddData}, {merge: true})
                      .catch((err) => {
                        captureError(err);
                      });
                  }
                }
                if (
                  data.updateObjType === 'object' &&
                  data.elementType === 'object'
                ) {
                  let updateObj = data.element;
                  // reduxCheckPath: 'automation.automationCongfig',
                  // reduxElementType: 'object',
                  // reduxCheckValue: {
                  //   type: 'Array<string>',
                  //   value: Object.keys(removedPendingAutomations[rowId] ?? {}),
                  // },
                  // reduxOperation: 'REMOVE',

                  if (data.reduxElementType === 'object') {
                    const currentObject = get(
                      latestReduxState,
                      data.reduxCheckPath,
                    );
                    if (data.reduxCheckValue?.type === 'Array<string>') {
                      const val = data.reduxCheckValue.value; // Array<string>

                      if (data.reduxOperation === 'REMOVE') {
                        val.forEach((str) => {
                          if (!currentObject?.[str]) {
                            updateObj = omit(updateObj, [str]); // Update Object for unwanted values
                          }
                        });
                      }
                    } else if (
                      data.reduxCheckValue?.type === 'object' &&
                      data.reduxOperation === 'REMOVE_NESTED_OBJ'
                    ) {
                      const val = Object.keys(data.reduxCheckValue.value); // {level1 : {nested_to_remove: }}

                      val.forEach((row) => {
                        const ids = Object.keys(updateObj?.[row]);
                        ids.forEach((str) => {
                          if (!currentObject?.[str]) {
                            updateObj[row] = omit(updateObj[row], [str]); // Update Object for unwanted values
                          }
                        });
                      });
                    }
                  }

                  if (!isEmpty(updateObj))
                    updateSetRef(data.collection, data.path)
                      .set(
                        data.updateAtPath // Update/Add at path
                          ? updateObj
                          : {[`${data.updatePath}`]: updateObj},
                        {merge: true},
                      )
                      .catch((err) => {
                        captureError(err);
                      });
                }
                if (isArray(data?.runAfterParentObjAdd)) {
                  extraActionsHandler(data.runAfterParentObjAdd);
                }
              }
              if (data.action === 'UPDATE') {
                if (
                  data.updateObjType === 'object' &&
                  data.elementType === 'object'
                ) {
                  const updateObj = data.element;
                  updateSetRef(data.collection, data.path)
                    .update(
                      data.updateAtPath
                        ? updateObj
                        : {[`${data.updatePath}`]: updateObj},
                    )
                    .catch((err) => {
                      captureError(err);
                    });
                }
              }
              if (data.action === 'REMOVE') {
                if (
                  data.updateObjType === 'object' &&
                  data.elementType === 'object'
                ) {
                  if (data.updateAtPath) {
                    updateSetRef(data.collection, data.path)
                      .update(data.element)
                      .catch((err) => {
                        captureError(err);
                      });
                  } else if (!isEmpty(updatePathData)) {
                    const updateObj = {};
                    let tempObj = cloneDeep(updatePathData);
                    Object.keys(data.element).forEach((removeKey) => {
                      updateObj[`${data.updatePath}.${removeKey}`] =
                        firestore(true).FieldValue.delete();
                      tempObj = omit(tempObj, [removeKey]);
                    });
                    updateSetRef(data.collection, data.path)
                      .update(
                        Object.keys(tempObj).length === 0 &&
                          data.removeParentObjIfEmpty
                          ? {
                              [`${data.updatePath}`]:
                                firestore(true).FieldValue.delete(),
                            }
                          : updateObj,
                      )
                      .catch((err) => {
                        captureError(err);
                      });
                    if (
                      Object.keys(tempObj).length === 0 &&
                      data?.removeParentObjIfEmpty &&
                      isArray(data?.runAfterParentObjEmptyRemove)
                    ) {
                      extraActionsHandler(data.runAfterParentObjEmptyRemove);
                    }
                  }
                }
                if (
                  data.updateObjType === 'array' &&
                  data.elementType === 'string'
                ) {
                  if (isArray(updatePathData)) {
                    if (updatePathData.includes(data.element)) {
                      const updatedData = updatePathData.filter(
                        (item) => item != data.element,
                      );
                      updateSetRef(data.collection, data.path)
                        .update({
                          [`${data.updatePath}`]:
                            updatedData.length === 0 &&
                            data?.removeParentObjIfEmpty
                              ? firestore(true).FieldValue.delete()
                              : updatedData,
                        })
                        .catch((err) => {
                          captureError(err);
                        });
                      if (
                        updatedData.length === 0 &&
                        data.removeParentObjIfEmpty &&
                        isArray(data?.runAfterParentObjEmptyRemove)
                      ) {
                        extraActionsHandler(data.runAfterParentObjEmptyRemove);
                      }
                    }
                  }
                }
                if (isArray(data?.runAfterParentObjRemove)) {
                  extraActionsHandler(data.runAfterParentObjRemove);
                }
              }
            }
          })
          .catch((err) => {
            captureError(err);
          });
      }
    });
  } catch (err) {
    captureError(err);
  }
};

export const performUndoAction = (
  undoObj,
  docId,
  state,
  isShared,
  activeDocumentMeta,
  latestReduxState,
) => {
  const newState = {};
  if (
    !isEmpty(undoObj.redoDashboardUpdateObj) &&
    !isNil(undoObj.dashboardColumnId) &&
    !isEmpty(activeDocumentMeta?.dashboards?.[undoObj.dashboardColumnId])
  ) {
    const newDashboardUpdateObj = {};
    forOwn(undoObj.undoDashboardUpdateObj, (val, key) => {
      Object.assign(newDashboardUpdateObj, {
        [`pages.${docId}.${key}`]: val == undefined ? null : val,
      });
    });
    updateAllDashboardsProperty(
      {
        [undoObj.dashboardColumnId]:
          activeDocumentMeta.dashboards[undoObj.dashboardColumnId],
      },
      newDashboardUpdateObj,
    );
  }
  switch (undoObj.ACTION_TYPE) {
    case TABLE_ACTION.MOVE_ROW: {
      const tableData = state.tableData.slice();
      tableData.splice(undoObj.insertAt, 1);
      tableData.splice(undoObj.removeFrom, 0, undoObj.prevRowObj);
      newState.tableData = tableData;
      DocumentsMethods.addMultipleRows(
        docId,
        [undoObj.prevRowObj],
        null,
        false,
        false,
        true,
      );
      return newState;
    }
    case TABLE_ACTION.ADD_EMPTY_ROW: {
      const tableData = state.tableData.slice();
      const noOfRows = undoObj.newRows.length;
      const deletedRows = tableData.splice(-noOfRows, noOfRows);
      newState.tableData = tableData;
      newState.emptyRowIndexes = undoObj.prevEmptyRowIndexes.slice();
      DocumentsMethods.deleteOrRestoreMultipleRows(
        docId,
        deletedRows.map((obj) => obj.rowId),
      ); //delete
      return newState;
    }
    case TABLE_ACTION.ADD_COLUMN_AT_POS: {
      const headerData = state.headerData.slice();
      const extraColumnsToAdd = undoObj.extraColumnsToAdd ?? [];
      headerData.splice(undoObj.index, 1 + extraColumnsToAdd.length);
      newState.headerData = headerData;
      newState.fileObj = Object.assign({}, undoObj.prevFileObj);
      DocumentsMethods.replaceHeaderData(docId, headerData, {
        fileObj: newState.fileObj,
      });
      checkAndUpdateEntryOnlyData({
        isShared,
        headerData,
        docId,
        fileObj: Object.assign({}, undoObj.prevFileObj),
      });
      return newState;
    }
    case TABLE_ACTION.EDIT_ROW: {
      const updatedRow = state.tableData.slice();
      const rowData = isEmpty(undoObj.prevRowDataObj)
        ? Object.assign(
            {},
            undoObj.newRowDataObj?.rowId
              ? {rowId: undoObj.newRowDataObj?.rowId}
              : {},
          )
        : undoObj.prevRowDataObj;

      const finalRowData = undoEditRowCommentHandler(
        state.headerData.slice(),
        updatedRow?.[undoObj.index] ?? {},
        rowData,
      );

      updatedRow.splice(undoObj.index, 1, finalRowData);
      newState.tableData = updatedRow;
      newState.footerData = undoObj.prevFooterData;

      undoUniqueDataHandler(state, undoObj, docId);
      newState.fileObj = undoObj.prevFileObj;
      DocumentsMethods.updateMultipleRows(docId, [finalRowData], {
        footerData: undoObj.prevFooterData,
      });
      if (isArray(undoObj?.extraUndoActions)) {
        extraActionsHandler(undoObj.extraUndoActions, {latestReduxState});
      }
      handleTaskUndoRedo(docId, undoObj, false);
      return newState;
    }
    case TABLE_ACTION.SORT_COL: {
      newState.tableData = prepareTableDataForFirestore(undoObj.prevTableData);
      DocumentsMethods.updateMultipleRows(
        docId,
        newState.tableData,
        null,
        true,
        false,
        true,
      );
      return newState;
    }
    case TABLE_ACTION.DELETE_COL_OR_FORMULA: {
      const {rowsFirestoreUpdateIndexArr, undoColumnIdForUnique} = undoObj;
      newState.tableData = undoObj.prevData.tableData;
      newState.headerData = undoObj.prevData.headerData;
      newState.footerData = undoObj.prevData.footerData;
      newState.fileObj = undoObj.prevData.fileObj;
      const uniqueColumnData = undoObj.prevData.uniqueColumnData;

      undoColumnIdForUnique.forEach((colId) => {
        if (!isEmpty(uniqueColumnData) && uniqueColumnData[colId]) {
          DocumentsMethods.updateUniqueValuesDataForColumn(docId, colId, {
            isDeleted: false,
          });
        }
      });
      DocumentsMethods.updateMultipleRows(
        docId,
        rowsFirestoreUpdateIndexArr.map(
          (index) => undoObj.prevData.tableData[index],
        ),
        {
          headerData: undoObj.prevData.headerData,
          footerData: undoObj.prevData.footerData,
          fileObj: undoObj.prevData.fileObj,
        },
      );
      checkAndUpdateEntryOnlyData({
        isShared,
        headerData: undoObj.prevData.headerData,
        docId,
        fileObj: undoObj.prevData.fileObj,
      });
      if (isArray(undoObj?.extraUndoActions)) {
        extraActionsHandler(undoObj.extraUndoActions);
      }
      return newState;
    }
    case TABLE_ACTION.COPY_PASTE_DATA: {
      const currTableData = state.tableData;
      newState.tableData = undoObj.prevData.tableData;
      newState.headerData = undoObj.prevData.headerData;
      newState.footerData = undoObj.prevData.footerData;

      undoUniqueDataHandler(
        state,
        {
          ACTION_TYPE: undoObj.ACTION_TYPE,
          oldHeader: state.headerData,
          newHeader: undoObj.prevData.headerData,
          updatedUniqueColObj: undoObj.prevData.uniqueColumnData,
        },
        docId,
      );
      newState.emptyRowIndexes =
        'prevEmptyRowIndexes' in undoObj.extra
          ? undoObj.extra.prevEmptyRowIndexes.slice()
          : state.emptyRowIndexes.slice();
      if (undoObj.changedRowsIndexArr?.length) {
        DocumentsMethods.updateMultipleRows(
          docId,
          undoObj.changedRowsIndexArr.map((index) => newState.tableData[index]),
          null,
          false,
          true,
        );
      }
      DocumentsMethods.deleteOrRestoreMultipleRows(
        docId,
        undoObj.newRowIndexArr.map((index) => currTableData[index].rowId),
        {headerData: newState.headerData, footerData: newState.footerData},
      ); //delete
      checkAndUpdateEntryOnlyData({
        isShared,
        headerData: newState.headerData,
        docId,
      });
      return newState;
    }
    case TABLE_ACTION.EDIT_COL: {
      const updatedHeader = undoObj.prevHeaderData.slice();
      newState.headerData = updatedHeader;
      newState.fileObj = Object.assign({}, undoObj.prevFileObj);
      const colId = undoObj.prevColumnDataObj.id;
      if (
        undoObj.prevColumnDataObj?.columnProperties?.UNIQUE_VALUES !=
        undoObj.newColumnDataObj?.columnProperties?.UNIQUE_VALUES
      ) {
        DocumentsMethods.updateUniqueValuesDataForColumn(docId, colId, {
          isDeleted: undoObj.newColumnDataObj?.columnProperties?.UNIQUE_VALUES,
        });
      }
      DocumentsMethods.replaceHeaderData(docId, updatedHeader, {
        fileObj: newState.fileObj,
      });
      checkAndUpdateEntryOnlyData({
        isShared,
        headerData: updatedHeader,
        docId,
        fileObj: newState.fileObj,
      });
      if (isArray(undoObj?.extraUndoActions)) {
        extraActionsHandler(undoObj.extraUndoActions);
      }
      return newState;
    }
    case TABLE_ACTION.EDIT_FORMULA_HEADER: {
      newState.tableData = undoObj.prevData.tableData;
      newState.headerData = undoObj.prevData.headerData;
      newState.footerData = undoObj.prevData.footerData;
      DocumentsMethods.updateMultipleRows(
        docId,
        undoObj.rowsFirestoreUpdateIndexArr.map(
          (index) => undoObj.prevData.tableData[index],
        ),
        {
          headerData: undoObj.prevData.headerData,
          footerData: undoObj.prevData.footerData,
        },
      );
      checkAndUpdateEntryOnlyData({
        isShared,
        headerData: undoObj.prevData.headerData,
        docId,
      });
      return newState;
    }
    case TABLE_ACTION.DELETE_ROW: {
      newState.emptyRowIndexes = undoObj.prevEmptyRowIndexes.slice();
      newState.tableData = undoObj.prevTableData;
      newState.footerData = undoObj.prevFooterData;
      undoUniqueDataHandler(state, undoObj, docId);
      newState.fileObj = undoObj.prevFileObj;
      DocumentsMethods.deleteOrRestoreMultipleRows(
        docId,
        undoObj.deletedRowIdsArr,
        {footerData: newState.footerData},
        false,
        true,
      ); //restore removed rows
      if (isArray(undoObj?.extraUndoActions)) {
        extraActionsHandler(undoObj.extraUndoActions);
      }
      if (undoObj.deletedTaskIds?.length) {
        undoObj.deletedTaskIds.forEach((taskId) =>
          modifyTaskActiveState(docId, taskId, true),
        );
      }
      return newState;
    }
    case TABLE_ACTION.DELETE_ROW_COL_DATA: {
      newState.tableData = undoObj.prevTableData;
      newState.footerData = undoObj.prevFooterData;
      newState.fileObj = undoObj.prevFileObj;
      undoUniqueDataHandler(state, undoObj, docId);
      DocumentsMethods.updateMultipleRows(
        docId,
        undoObj.rowsFirestoreUpdateUndoObjArr,
        {footerData: newState.footerData},
      );
      if (isArray(undoObj?.extraUndoActions)) {
        extraActionsHandler(undoObj.extraUndoActions);
      }
      if (undoObj.deletedTaskIds?.length) {
        undoObj.deletedTaskIds.forEach((taskId) =>
          modifyTaskActiveState(docId, taskId, true),
        );
      }
      return newState;
    }
    case TABLE_ACTION.ADD_ROW_IN_BETWEEN: {
      const tableData = state.tableData.slice();
      const [deletedRow] = tableData.splice(undoObj.index, 1);
      newState.tableData = tableData;
      newState.emptyRowIndexes = undoObj.prevEmptyRowIndexes.slice();
      DocumentsMethods.deleteOrRestoreMultipleRows(docId, [deletedRow.rowId]); //delete
      return newState;
    }
    case TABLE_ACTION.MOVE_COLUMN: {
      newState.headerData = undoObj.prevHeaderData;
      DocumentsMethods.replaceHeaderData(docId, newState.headerData);
      checkAndUpdateEntryOnlyData({
        isShared,
        headerData: undoObj.prevHeaderData,
        docId,
      });
      return newState;
    }
    default: {
      return newState;
    }
  }
};

export const undoEditRowCommentHandler = (
  headerData,
  currentRowData,
  previousRowData,
) => {
  const commentColumnId = headerData.find(
    (colData) => colData.fieldType === FIELD_TYPE_ID.COMMENT,
  )?.id;

  return (isString(commentColumnId) || isNumber(commentColumnId)) &&
    !isEmpty(currentRowData?.[commentColumnId])
    ? Object.assign({}, previousRowData, {
        [commentColumnId]: currentRowData[commentColumnId],
      })
    : previousRowData;
};

export const undoUniqueDataHandler = (state, undoObj, docId) => {
  const uniqueColumnData = Object.assign({}, state.uniqueColumnData);
  if (!isEmpty(uniqueColumnData)) {
    const uniqueColIdArray = Object.keys(uniqueColumnData);
    if (TABLE_ACTION.COPY_PASTE_DATA === undoObj.ACTION_TYPE) {
      undoObj.oldHeader?.forEach?.((colObj) => {
        if (
          colObj.id in uniqueColumnData &&
          !(colObj.id in undoObj.updatedUniqueColObj)
        ) {
          DocumentsMethods.deleteUniqueValuesDataForColumn(docId, colObj.id);
        }
      });
    } else if (
      [TABLE_ACTION.DELETE_ROW, TABLE_ACTION.DELETE_ROW_COL_DATA].includes(
        undoObj.ACTION_TYPE,
      )
    ) {
      const modifiedArr = undoObj.deletedRowIdsArr ?? undoObj.modifiedRowIdArr;

      uniqueColIdArray.forEach((colId) => {
        const updatedUniqueObj = {};
        const colIndex = !isNil(
          state.headerMappedValues?.headerIdIndexMap?.[colId],
        )
          ? state.headerMappedValues?.headerIdIndexMap?.[colId]
          : null;
        if (
          colId in uniqueColumnData &&
          (undoObj.ACTION_TYPE === TABLE_ACTION.DELETE_ROW ||
            undoObj.modifiedColIdArr.includes(colId))
        ) {
          undoObj.prevTableData.forEach((rowObj) => {
            if (
              modifiedArr?.length &&
              modifiedArr.includes(rowObj.rowId) &&
              rowObj[colId]?.val
            ) {
              let newVal = `${rowObj[colId].val}`.toLowerCase().trim();
              if (
                state?.headerData?.[colIndex]?.fieldType &&
                state.headerData[colIndex].fieldType === FIELD_TYPE_ID.TIME
              ) {
                newVal = `${getPrintTime(newVal)}`.toLowerCase().trim();
              }
              updatedUniqueObj[`data.${newVal}`] =
                !uniqueColumnData[colId][newVal];
            }
          });

          DocumentsMethods.updateUniqueValuesDataForColumn(
            docId,
            colId,
            updatedUniqueObj,
          );
        }
      });
    } else {
      uniqueColIdArray.forEach((colId) => {
        let newVal = undoObj?.newRowDataObj?.[colId]?.val
          ? `${undoObj?.newRowDataObj?.[colId]?.val}`.toLowerCase().trim()
          : undoObj?.prevRowDataObj?.[colId]?.val
          ? `${undoObj?.prevRowDataObj?.[colId]?.val}`.toLowerCase().trim()
          : null;

        if (
          !isNil(newVal) &&
          !isEqual(undoObj.prevRowDataObj[colId], undoObj.newRowDataObj[colId])
        ) {
          const colIndex = !isNil(
            state.headerMappedValues?.headerIdIndexMap?.[colId],
          )
            ? state.headerMappedValues?.headerIdIndexMap?.[colId]
            : null;
          if (
            state?.headerData?.[colIndex]?.fieldType &&
            state.headerData[colIndex].fieldType === FIELD_TYPE_ID.TIME
          ) {
            newVal = `${getPrintTime(newVal)}`.toLowerCase().trim();
          }
          DocumentsMethods.updateUniqueValuesDataForColumn(docId, colId, {
            [`data.${newVal}`]: !uniqueColumnData[colId][newVal],
          });
        }
      });
    }
  }
};
