import {NEW_USER_ACTIONS} from '../actions/actionType';

const initialState = {
  isNewUser: false,
};

const newUserReducer = function (state = initialState, action) {
  switch (action.type) {
    case NEW_USER_ACTIONS.SET_NEW_USER:
      return {isNewUser: action.payload};
    default:
      return state;
  }
};

export default newUserReducer;
