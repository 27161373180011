import {isEmpty} from 'lodash';
import {getReduxState, isProduction} from '../imports';

const generateQueryParams = (rowId, screenId, appId) => {
  const {miniApps} = getReduxState();
  const {activeAppId, activeScreenId, searchFilterData} = miniApps;

  if (!appId) appId = activeAppId;
  if (!screenId) screenId = activeScreenId;

  const searchText = searchFilterData?.[screenId]?.searchedText;
  const filterOptions = searchFilterData?.[screenId]?.filterOptions;

  let queryParamsString = '';
  if (appId) {
    queryParamsString += `?app=${appId}`;
  }

  if (appId && screenId) {
    queryParamsString += `&screen=${screenId}`;

    if (rowId) {
      // sharing a row
      queryParamsString += `&row=${rowId}`;
    } else if (searchText) {
      // sharing a screen with active search
      queryParamsString += `&search=${searchText}`;
    } else if (!isEmpty(filterOptions)) {
      // sharing a screen with filters applied
      queryParamsString += `&filter=${JSON.stringify(filterOptions)}`;
    }
  }
  return queryParamsString;
};

const getSearchParamFromURL = (url, param) => {
  const include = url.includes(param);

  if (!include) return null;

  const params = url.split(/([&?=])/);
  const index = params.indexOf(param);

  return params[index + 2];
};

const parseFilterOptions = (url) => {
  try {
    return JSON.parse(new URLSearchParams(url).get('filter'));
  } catch (error) {
    return null;
  }
};

/**
 *
 * @param {URLSearchParams} queryParams
 * @returns
 */
const parseAndReturnShareURLParams = (queryParams) => {
  const app = queryParams.get('app');
  let params = '?';
  let index = 0;
  queryParams.forEach((val, key) => {
    params += (index !== 0 ? '&' : '') + key + '=' + val;
    index++;
  });
  return app?.length ? params : '';
};

const getShareRowLink = (rowId, screenId, appId) => {
  const PROD = 'https://web.lio.io/app';
  const DEV = 'https://getlio.io/app';

  return (
    (isProduction ? PROD : DEV) + generateQueryParams(rowId, screenId, appId)
  );
};

export {
  parseAndReturnShareURLParams,
  getShareRowLink,
  generateQueryParams,
  parseFilterOptions,
  getSearchParamFromURL,
};
