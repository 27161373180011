import {UNDO_REDO_LIMIT} from '../../../utils/constant';
import {isEmpty} from 'lodash';
import {modifyTaskActiveState, updateTaskDetails} from '../tasksActionHelper';
import {captureError} from '../../../imports';

export const copyData = (property, data) => {
  // copies "data" into property array and returns that property
  if (property.length === UNDO_REDO_LIMIT.LIMIT) {
    property = property.slice(1); //remove 1st element
  }
  property.push(data);
  return property.slice();
};

export const handleTaskUndoRedo = (docId, currObj, isRedo = false) => {
  try {
    if (currObj.extra?.tasks?.length) {
      currObj.extra.tasks.forEach((task) => {
        if (task?.isDelete) {
          //erase/clear cell
          if (task?.deletedTasks?.length) {
            task.deletedTasks.forEach((taskId) => {
              taskId?.length &&
                modifyTaskActiveState(docId, taskId, isRedo ? false : true);
            });
          }
        } else if (task?.taskId) {
          //undo-redo on add/edit task
          if (isEmpty(task.prevTaskDocObj)) {
            modifyTaskActiveState(docId, task.taskId, isRedo ? true : false);
          } else {
            updateTaskDetails(
              task.taskId,
              isRedo ? task.newTaskDocObj : task.prevTaskDocObj,
            );
          }
        }
      });
    }
  } catch (err) {
    captureError(err);
  }
};

export const TABLE_UNDO_REDO_TYPES = {
  MULTIPLE_ROW_COL_DELETE: 'MULTIPLE_ROW_COL_DELETE',
};

export const getTableUndoRedoActions = (type, extra = {}) => {
  const deleteMultipleRowCol = () => {
    let redoActions = [];
    let undoActions = [];
    const activeDocumentId = extra?.activeDocumentId;
    const addedParentRowMetaData = extra?.addedParentRowMetaData;

    if (activeDocumentId && !isEmpty(addedParentRowMetaData)) {
      redoActions = [
        {
          updateObjType: 'object',
          elementType: 'object',
          element: {
            ...addedParentRowMetaData,
          },
          action: 'REMOVE',
          collection: 'userDocuments',
          path: `${activeDocumentId}/parentRowMeta/mapping`,
          updatePath: `meta`,
        },
      ];
      undoActions = [
        {
          updateObjType: 'object',
          elementType: 'object',
          element: {
            ...addedParentRowMetaData,
          },
          action: 'ADD',
          collection: 'userDocuments',
          path: `${activeDocumentId}/parentRowMeta/mapping`,
          updatePath: `meta`,
        },
      ];
    }

    return {
      extraUndoActions: undoActions,
      extraRedoActions: redoActions,
    };
  };
  return type === TABLE_UNDO_REDO_TYPES.MULTIPLE_ROW_COL_DELETE
    ? deleteMultipleRowCol()
    : {extraUndoActions: [], extraRedoActions: []};
};
