import {firestore} from '../../imports';

export default class TemplatesDB {
  templateRef(templateId, firestoreRef = null) {
    return (firestoreRef ?? firestore)()
      .collection('userTemplates_ARCH_V2')
      .doc(templateId);
  }

  templateRowsCollectionRefSorted(templateId) {
    return this.templateRef(templateId)
      .collection('rows')
      .orderBy('index', 'asc');
  }

  templateRowsCollectionRef(templateId) {
    return this.templateRef(templateId).collection('rows');
  }

  templateRowRef(templateId, rowId) {
    return this.templateRowsCollectionRef(templateId).doc(rowId);
  }
}
