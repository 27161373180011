import {REMOTE_CONFIG_ACTION} from '../actions/actionType';

const initialState = {
  showNewOnboardingFlow: true,
  showNewDashboardFlow: false,
  applyUserRestrictions: false,
  showActivityLog: false,
  restrictionConfig: {},
  isOrganisationMode: false,
};

const remoteConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOTE_CONFIG_ACTION.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    case REMOTE_CONFIG_ACTION.SET_ORGANISATION_MODE_VALUE: {
      return {
        ...state,
        isOrganisationMode: Boolean(action.payload),
      };
    }
    case REMOTE_CONFIG_ACTION.SET_SHOW_ACTIVITY_LOG:
      return {
        ...state,
        showActivityLog: action.payload,
      };
    case REMOTE_CONFIG_ACTION.UPDATE_RESTRICTION_STATUS: {
      return {
        ...state,
        applyUserRestrictions: action.payload,
      };
    }
    case REMOTE_CONFIG_ACTION.SET_RESTRICTION_DATA: {
      const payload = Object.assign({}, action.payload);
      return {
        ...state,
        applyUserRestrictions: payload.applyUserRestrictions,
        restrictionConfig: payload.restrictionConfig,
      };
    }
    case REMOTE_CONFIG_ACTION.SET_SHOW_NEW_DASHBOARD_FLOW: {
      return {
        ...state,
        showNewDashboardFlow: action.payload,
      };
    }
    default:
      return state;
  }
};

export default remoteConfigReducer;
