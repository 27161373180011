import FirestoreDB from '../../FirestoreHandlers/FirestoreDB';
import {captureError, functions} from '../../imports';
import {
  CLOUD_FUNCTION_PATHS,
  VERSION_HISTORY_LIMITS,
} from '../../utils/constant';
import {handleCloudError} from '../../utils/utils';

const restoreVersionFileFromCloud = async (dataObj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.RESTORE_VERSION_FILE,
    );
    const response = await functionInstance(dataObj);
    return response.data;
  } catch (error) {
    captureError(error);
    return handleCloudError(error);
  }
};

const versionCollectionRefWithSorting = (docId) =>
  FirestoreDB.documents
    .versionsCollectionRef(docId)
    .orderBy('createdTimestampUTC', 'desc')
    .limit(VERSION_HISTORY_LIMITS.FETCH_DOC_LIMIT);

const versionSnaps = (docId, lastDoc) =>
  lastDoc
    ? versionCollectionRefWithSorting(docId).startAfter(lastDoc).get()
    : versionCollectionRefWithSorting(docId).get();

export {versionSnaps, restoreVersionFileFromCloud};
