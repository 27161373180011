import {firestore, functions, captureError} from '../../imports';
import {backOff} from 'exponential-backoff';
import {handleCloudError} from '../../utils/utils';
import {CLOUD_FUNCTION_PATHS} from '../../utils/constant';

const getFreeTrialCloudFunction = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.GET_FREE_TRIAL_PREMIUM,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const getOfferingLocalisation = async () => {
  try {
    const config = {
      startingDelay: 0,
      maxDelay: 1000,
    };
    let errorLogged = false;
    const docRef = firestore()
      .collection('premiumOfferings')
      .doc('premiumLocalisationJan23');
    return backOff(
      () =>
        docRef
          .get()
          .then((res) => {
            const data = {};
            if (res.exists) {
              Object.assign(data, res.data()?.localisation);
            }
            return Promise.resolve(data);
          })
          .catch((err) => {
            if (!errorLogged) {
              captureError(err);
              errorLogged = true;
            }
            return Promise.reject(err);
          }),
      config,
    );
  } catch (err) {
    captureError(err);
    return Promise.reject(err);
  }
};

const getPremiumFeaturesList = async () => {
  try {
    const version = 'V2';
    const docRef = firestore()
      .collection('premiumFeaturesList')
      .doc(`features${version}`);
    const list = await backOff(
      () =>
        docRef
          .get()
          .then((res) => {
            if (res.exists) {
              return Promise.resolve(res.data().list);
            }
            return Promise.resolve([]);
          })
          .catch(Promise.reject),
      {startingDelay: 0},
    );
    return list || [];
  } catch (err) {
    captureError(err);
    return [];
  }
};

const getPlanSharedWith = async (uid) => {
  try {
    const docRef = firestore()
      .collection('users')
      .doc(uid)
      .collection('userData')
      .doc('premiumSharedWith');
    return backOff(
      () =>
        docRef
          .get()
          .then((res) => {
            if (res.exists) {
              return Promise.resolve(res.data().premiumSharedWith);
            }
            return Promise.resolve(null);
          })
          .catch(Promise.reject),
      {startingDelay: 0},
    );
  } catch (err) {
    captureError(err);
  }
};

const sharePlanWithContactCloudCall = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.SHARE_PLAN_WITH_CONTACT,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const removeContactFromPlanCloudCall = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.REMOVE_CONTACT_FROM_PLAN,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

export {
  getFreeTrialCloudFunction,
  getOfferingLocalisation,
  getPremiumFeaturesList,
  getPlanSharedWith,
  sharePlanWithContactCloudCall,
  removeContactFromPlanCloudCall,
};
