/* eslint-disable no-empty */
import {keys, isNil, isEmpty} from 'lodash';
import moment from 'moment';
import {
  CAN_VIEW_ACCESS_ALLOWED_FIELDTYPES,
  CUSTOM_EDIT_MODES,
  ENTRY_EDIT_MODES,
  FIELD_TYPE_ID,
  PAGE_TYPE,
  SHARE_PERMISSION_TYPE,
  TABLE_VIEW_MODES,
} from './constant';
import {
  checkCellContainsAttachment,
  checkCellContainsAudio,
  checkCellContainsComment,
  checkCellContainsImage,
  checkCellContainsMap,
  checkIfValueExistOnCell,
} from './utils';

/**
 * First add Restrictions to PATH: packages/rb-redux/utils/constant.js => TABLE_INTERACTIONS
 * then add in below RESTRICTIONS
 */
const RESTRICTIONS = {
  HEADER_ADD_NEW_COL: true,
  BLOCK_VIEW_ADD_NEW_COL: true,
  HEADER_PRESS: true,
  FOOTER_PRESS: true,
  ADD_NEW_ROW: true,
  CELL_PRESS: true,
  CAN_ENABLE_QUICK_ENTRY: true,
  ROW_DELETE: true,
  CELL_LONG_PRESS: true,
  DOCUMENT_LOCK: true,
  UNDO_REDO: true,
  CELL_FORMATTING_PRESS: true,
  MULTIPLE_SELECTABLE_ROW_COL_DELETE: true,
  ADD_PAGES: true,
  CHANGE_PAGE: true,
  RESTORE_PAGES: true,
  ROW_SELECTABLE: true,
  CAN_VIEW: false, // true - for Only view data permission
};

export class TABLE_PRESS_RESTRICTIONS {
  static PREVIEW_CHECK({viewModes}) {
    return (
      !viewModes[TABLE_VIEW_MODES.PREVIEW] &&
      !viewModes[TABLE_VIEW_MODES.VERSION_PREVIEW]
    );
  }

  static IS_NETWORK_AVAILABLE({netInfoState}) {
    return (
      !isEmpty(netInfoState) &&
      netInfoState.isConnected &&
      netInfoState.isInternetReachable
    );
  }

  static SHARED_PERMISSIONS({collab}) {
    const {
      permission = '',
      isOwner,
      restrictions = {},
      properties = {},
    } = collab ?? {};

    return {
      OWNER: isOwner && permission === SHARE_PERMISSION_TYPE.OWNER,
      ADMIN: permission === SHARE_PERMISSION_TYPE.ADMIN,
      CAN_VIEW: permission === SHARE_PERMISSION_TYPE.CAN_VIEW,
      CAN_EDIT: permission === SHARE_PERMISSION_TYPE.CAN_EDIT,
      CUSTOM: permission === SHARE_PERMISSION_TYPE.CUSTOM,
      ENTRY_ONLY: permission === SHARE_PERMISSION_TYPE.ENTRY_ONLY,
      CUSTOM_RESTRICTIONS: Object.assign({}, restrictions),
      EDIT_MODE: properties?.editMode ?? null,
      IS_SHARED: !isEmpty(collab),
    };
  }

  static CUSTOM_SHARED_CAN_VIEW_ONLY_COLUMN_DATA({
    CUSTOM,
    CUSTOM_RESTRICTIONS,
    colObj,
    rowObj,
  }) {
    return CUSTOM && CUSTOM_RESTRICTIONS?.[colObj?.id]?.isReadOnly
      ? this.CAN_VIEW_ONLY_ROW_DATA(colObj, rowObj)
      : false;
  }

  static CAN_VIEW_ONLY_ROW_DATA(colObj, rowObj) {
    const column_id = colObj?.id;
    const value = rowObj?.[column_id]?.val;

    return !isNil(value) &&
      CAN_VIEW_ACCESS_ALLOWED_FIELDTYPES.includes(colObj.fieldType) &&
      (checkCellContainsAudio(value) ||
        checkCellContainsImage(value) ||
        checkCellContainsMap(value, rowObj[column_id] ?? {}) ||
        checkCellContainsAttachment(value) ||
        checkCellContainsComment(value, rowObj[column_id] ?? {}))
      ? true // IF CAN_VIEW permission then allow these fields.
      : false;
  }

  static CUSTOM_SHARED_ALL_VIEW_ONLY({
    CUSTOM,
    CUSTOM_RESTRICTIONS,
    headerDataTypeMapping,
  }) {
    const restrictionsKeys = keys(CUSTOM_RESTRICTIONS);

    const headerDataTypeMappingKeys = keys(headerDataTypeMapping);

    return CUSTOM
      ? restrictionsKeys.length !== 0 &&
        restrictionsKeys.length === headerDataTypeMappingKeys.length
        ? headerDataTypeMappingKeys.every(
            (val) =>
              (val in CUSTOM_RESTRICTIONS &&
                CUSTOM_RESTRICTIONS?.[val]?.isReadOnly) ||
              headerDataTypeMapping?.[val]?.fieldType ===
                FIELD_TYPE_ID.CREATED_INFO || // If every column has read only
              headerDataTypeMapping?.[val]?.fieldType ===
                FIELD_TYPE_ID.USER_COLUMN, // If every column has read only
          )
        : restrictionsKeys.length > headerDataTypeMappingKeys.length
        ? headerDataTypeMappingKeys.every(
            (val) =>
              val in CUSTOM_RESTRICTIONS &&
              CUSTOM_RESTRICTIONS?.[val]?.isReadOnly, // If every column has read only
          )
        : false
      : false;
  }

  static CUSTOM_SHARED_CAN_EDIT_COLUMN({
    CUSTOM,
    CUSTOM_RESTRICTIONS,
    columnId,
    rowObj,
    EDIT_MODE,
    uid,
  }) {
    const {rowProperties} = rowObj;
    const {firstAddedByUID} = rowProperties ?? {};
    const IS_EDIT = !CUSTOM_RESTRICTIONS?.[columnId]?.isReadOnly;

    return CUSTOM
      ? IS_EDIT &&
          (EDIT_MODE === CUSTOM_EDIT_MODES.OWN_ROWS
            ? !firstAddedByUID || firstAddedByUID === uid
            : true)
      : true;
  }

  static entryOnlyEditModePressCheck({EDIT_MODE, rowObj, colObj}) {
    const {rowProperties} = rowObj;
    const {createdByUID, createdTimestamp} = rowProperties ?? {};
    const createTime = moment(createdTimestamp).utc(true).format('DD-MM-YYYY');
    const endDayTime = moment().format('DD-MM-YYYY');
    return EDIT_MODE === ENTRY_EDIT_MODES.ANYTIME
      ? true
      : EDIT_MODE === ENTRY_EDIT_MODES.END_OF_DAY && createTime === endDayTime
      ? true
      : EDIT_MODE === ENTRY_EDIT_MODES.ONLY_EMPTY_CELLS
      ? !checkIfValueExistOnCell(rowObj?.[colObj?.id] ?? {}, colObj, rowObj)
      : false;
  }

  static CHECK({
    viewModes,
    collab,
    netInfoState,
    isRowUnLocked,
    isRowBeingEditedCollab,
    colObj,
    rowObj,
    fromTable,
    pageObj,
    uid,
  }) {
    const PERMISSION_OBJECT = Object.assign({}, RESTRICTIONS);

    const DOCUMENT_LOCKED = viewModes[TABLE_VIEW_MODES.DOCUMENT_LOCK];

    const IS_ROW_UNLOCKED = isRowUnLocked;

    if (this.PREVIEW_CHECK({viewModes})) {
      // IS NOT TEMPLATE PREVIEW/VERSION PREVIEW
      const {
        OWNER,
        ADMIN,
        CAN_VIEW,
        CAN_EDIT,
        CUSTOM,
        ENTRY_ONLY,
        CUSTOM_RESTRICTIONS,
        IS_SHARED,
        EDIT_MODE,
      } = this.SHARED_PERMISSIONS({collab});

      if (IS_SHARED) {
        if (!this.IS_NETWORK_AVAILABLE({netInfoState})) {
          Object.keys(PERMISSION_OBJECT).forEach((key) => {
            PERMISSION_OBJECT[key] = false;
          });

          return PERMISSION_OBJECT;
        }

        const OTHER_USER_EDITING_ROW = isRowBeingEditedCollab;

        if (OWNER) {
          if (DOCUMENT_LOCKED) {
            if (this.CAN_VIEW_ONLY_ROW_DATA(colObj, rowObj)) {
              PERMISSION_OBJECT.CAN_VIEW = true;
              PERMISSION_OBJECT.CELL_PRESS = false;
              PERMISSION_OBJECT.CELL_LONG_PRESS = false;
              PERMISSION_OBJECT.MULTIPLE_SELECTABLE_ROW_COL_DELETE = false;
              PERMISSION_OBJECT.FOOTER_PRESS = false;
              PERMISSION_OBJECT.HEADER_PRESS = false;
              PERMISSION_OBJECT.ADD_NEW_ROW = false;
              PERMISSION_OBJECT.HEADER_ADD_NEW_COL = false;
            } else if (IS_ROW_UNLOCKED) {
              if (OTHER_USER_EDITING_ROW) {
                PERMISSION_OBJECT.CELL_PRESS = false;
                PERMISSION_OBJECT.CELL_LONG_PRESS = false;
              } else {
                // LET EDIT HERE;
              }
            }
          } else {
            if (OTHER_USER_EDITING_ROW) {
              PERMISSION_OBJECT.CELL_PRESS = false;
              PERMISSION_OBJECT.CELL_LONG_PRESS = false;
            } else {
              PERMISSION_OBJECT.CAN_VIEW = false;
            }
          }
        } else if (ADMIN) {
          if (DOCUMENT_LOCKED) {
            if (this.CAN_VIEW_ONLY_ROW_DATA(colObj, rowObj)) {
              PERMISSION_OBJECT.CAN_VIEW = true;
              PERMISSION_OBJECT.CELL_PRESS = false;
              PERMISSION_OBJECT.CELL_LONG_PRESS = false;
              PERMISSION_OBJECT.MULTIPLE_SELECTABLE_ROW_COL_DELETE = false;
              PERMISSION_OBJECT.FOOTER_PRESS = false;
              PERMISSION_OBJECT.HEADER_PRESS = false;
              PERMISSION_OBJECT.ADD_NEW_ROW = false;
              PERMISSION_OBJECT.HEADER_ADD_NEW_COL = false;
            } else if (IS_ROW_UNLOCKED) {
              if (OTHER_USER_EDITING_ROW) {
                PERMISSION_OBJECT.CELL_PRESS = false;
                PERMISSION_OBJECT.CELL_LONG_PRESS = false;
              } else {
                // LET EDIT HERE;
              }
            }
          } else {
            if (OTHER_USER_EDITING_ROW) {
              PERMISSION_OBJECT.CELL_PRESS = false;
              PERMISSION_OBJECT.CELL_LONG_PRESS = false;
            } else {
              // LET EDIT HERE;
            }
          }
        } else if (CAN_EDIT) {
          PERMISSION_OBJECT.RESTORE_PAGES = false;
          if (DOCUMENT_LOCKED) {
            if (this.CAN_VIEW_ONLY_ROW_DATA(colObj, rowObj)) {
              PERMISSION_OBJECT.CAN_VIEW = true;
              PERMISSION_OBJECT.CELL_PRESS = false;
              PERMISSION_OBJECT.CELL_LONG_PRESS = false;
              PERMISSION_OBJECT.MULTIPLE_SELECTABLE_ROW_COL_DELETE = false;
              PERMISSION_OBJECT.FOOTER_PRESS = false;
              PERMISSION_OBJECT.HEADER_PRESS = false;
              PERMISSION_OBJECT.ADD_NEW_ROW = false;
              PERMISSION_OBJECT.HEADER_ADD_NEW_COL = false;
              // CAN VIEW DATA BUT NOT EDIT.
            } else if (IS_ROW_UNLOCKED) {
              if (OTHER_USER_EDITING_ROW) {
                PERMISSION_OBJECT.CELL_PRESS = false;
                PERMISSION_OBJECT.CELL_LONG_PRESS = false;
              } else {
                // LET EDIT HERE;
              }
            }
          } else {
            if (OTHER_USER_EDITING_ROW) {
              PERMISSION_OBJECT.CELL_PRESS = false;
              PERMISSION_OBJECT.CELL_LONG_PRESS = false;
            } else {
              // LET EDIT HERE;
            }
          }
        } else if (CAN_VIEW) {
          PERMISSION_OBJECT.RESTORE_PAGES = false;
          PERMISSION_OBJECT.ADD_NEW_ROW = false;
          PERMISSION_OBJECT.CELL_PRESS = false;
          PERMISSION_OBJECT.ADD_PAGES = false;
          PERMISSION_OBJECT.CELL_LONG_PRESS = false;
          PERMISSION_OBJECT.CAN_ENABLE_QUICK_ENTRY = false;
          PERMISSION_OBJECT.DOCUMENT_LOCK = false;
          PERMISSION_OBJECT.FOOTER_PRESS = false;
          PERMISSION_OBJECT.HEADER_PRESS = false;
          PERMISSION_OBJECT.HEADER_ADD_NEW_COL = false;
          PERMISSION_OBJECT.MULTIPLE_SELECTABLE_ROW_COL_DELETE = false;
          PERMISSION_OBJECT.ROW_DELETE = false;
          PERMISSION_OBJECT.UNDO_REDO = false;
          if (this.CAN_VIEW_ONLY_ROW_DATA(colObj, rowObj)) {
            PERMISSION_OBJECT.CAN_VIEW = true;
          }
        } else if (CUSTOM) {
          PERMISSION_OBJECT.BLOCK_VIEW_ADD_NEW_COL = false;
          PERMISSION_OBJECT.RESTORE_PAGES = false;
          PERMISSION_OBJECT.CELL_PRESS = false;
          PERMISSION_OBJECT.CELL_LONG_PRESS = false;
          PERMISSION_OBJECT.MULTIPLE_SELECTABLE_ROW_COL_DELETE = false;
          PERMISSION_OBJECT.FOOTER_PRESS = false;
          PERMISSION_OBJECT.HEADER_PRESS = false;
          PERMISSION_OBJECT.ADD_NEW_ROW = false;
          PERMISSION_OBJECT.HEADER_ADD_NEW_COL = false;
          PERMISSION_OBJECT.DOCUMENT_LOCK = false;
          PERMISSION_OBJECT.ROW_DELETE = false;
          if (DOCUMENT_LOCKED) {
            if (
              this.CUSTOM_SHARED_CAN_VIEW_ONLY_COLUMN_DATA({
                CUSTOM,
                CUSTOM_RESTRICTIONS,
                colObj,
                rowObj,
              })
            ) {
              PERMISSION_OBJECT.CAN_VIEW = true;
              // LET OPEN IMAGE/AUDIO/LOCATION VIEW HERE;
            } else if (
              this.CUSTOM_SHARED_CAN_EDIT_COLUMN({
                CUSTOM,
                CUSTOM_RESTRICTIONS,
                columnId: colObj?.id,
                rowObj,
                EDIT_MODE,
                uid,
              }) &&
              IS_ROW_UNLOCKED
            ) {
              if (OTHER_USER_EDITING_ROW) {
              } else {
                PERMISSION_OBJECT.CELL_PRESS = true;
                PERMISSION_OBJECT.CELL_LONG_PRESS = true;
                // LET EDIT HERE;
              }
            }
          } else {
            if (
              this.CUSTOM_SHARED_CAN_VIEW_ONLY_COLUMN_DATA({
                CUSTOM,
                CUSTOM_RESTRICTIONS,
                colObj,
                rowObj,
              })
            ) {
              PERMISSION_OBJECT.CAN_VIEW = true;
              // LET OPEN VIEW HERE;
            } else if (
              this.CUSTOM_SHARED_CAN_EDIT_COLUMN({
                CUSTOM,
                CUSTOM_RESTRICTIONS,
                columnId: colObj?.id,
                rowObj,
                EDIT_MODE,
                uid,
              })
            ) {
              if (OTHER_USER_EDITING_ROW) {
              } else {
                PERMISSION_OBJECT.CELL_PRESS = true;
                PERMISSION_OBJECT.CELL_LONG_PRESS = true;
              }
            }
          }
        } else if (ENTRY_ONLY) {
          const canEdit =
            rowObj &&
            colObj &&
            this.entryOnlyEditModePressCheck({EDIT_MODE, rowObj, colObj});

          if (
            this.CAN_VIEW_ONLY_ROW_DATA(colObj, rowObj) &&
            fromTable &&
            !canEdit
          ) {
            PERMISSION_OBJECT.CAN_VIEW = true;
          }
          const isPageTypeClient =
            pageObj?.enabled && pageObj?.type === PAGE_TYPE.CLIENT
              ? true
              : false;

          PERMISSION_OBJECT.BLOCK_VIEW_ADD_NEW_COL = false;
          PERMISSION_OBJECT.RESTORE_PAGES = false;
          PERMISSION_OBJECT.ADD_PAGES = false;
          PERMISSION_OBJECT.CHANGE_PAGE = true;
          PERMISSION_OBJECT.CELL_PRESS = fromTable
            ? !EDIT_MODE
              ? false
              : canEdit
            : !fromTable
            ? EDIT_MODE
              ? canEdit
              : true
            : true;

          PERMISSION_OBJECT.CELL_LONG_PRESS = false;
          PERMISSION_OBJECT.MULTIPLE_SELECTABLE_ROW_COL_DELETE = false;
          PERMISSION_OBJECT.FOOTER_PRESS = false;
          PERMISSION_OBJECT.HEADER_PRESS = false;
          PERMISSION_OBJECT.ADD_NEW_ROW = false;
          PERMISSION_OBJECT.HEADER_ADD_NEW_COL = false;
          PERMISSION_OBJECT.DOCUMENT_LOCK = false;
          PERMISSION_OBJECT.ROW_DELETE = false;
        }
      } else if (!IS_SHARED) {
        // NOT SHARED

        if (DOCUMENT_LOCKED) {
          if (IS_ROW_UNLOCKED) {
          } else if (this.CAN_VIEW_ONLY_ROW_DATA(colObj, rowObj)) {
            PERMISSION_OBJECT.CAN_VIEW = true;
          }
        } else {
        }
      }
    } else {
      Object.keys(PERMISSION_OBJECT).forEach((key) => {
        PERMISSION_OBJECT[key] = false;
      });
    }
    return PERMISSION_OBJECT;
  }
}
