import {DOCS_FILTER_ACTIONS} from './actionType';

export const DOCUMENT_FILTER_TYPE = {
  ALL: 'All',
  MY_FILES: 'My Files',
  SHARED_WITH_ME: 'Shared with me',
  TRASH: 'Trash',
};

export const setDocumentFilterType = (filterType) => ({
  type: DOCS_FILTER_ACTIONS.SET_DOCS_FILTER_TYPE,
  payload: filterType,
});
export const setDocumentFilterTeam = (teamId) => ({
  type: DOCS_FILTER_ACTIONS.SET_DOCS_FILTER_TEAM,
  payload: teamId,
});
