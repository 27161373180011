const QuickFilterIcon = ({color}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.0775 14.49L16.275 13.875C16.29 13.7475 16.305 13.6275 16.305 13.5C16.305 13.3725 16.2975 13.2525 16.275 13.125L17.07 12.51C17.1375 12.45 17.16 12.3525 17.115 12.27L16.365 10.9725C16.32 10.875 16.2225 10.875 16.125 10.875L15.2025 11.25C15 11.115 14.7975 10.9875 14.565 10.8975L14.4225 9.9075C14.415 9.8175 14.3325 9.75 14.25 9.75H12.75C12.6525 9.75 12.57 9.8175 12.555 9.9075L12.4125 10.8975C12.1875 10.995 11.97 11.115 11.775 11.25L10.845 10.875C10.7625 10.875 10.665 10.875 10.6125 10.9725L9.86252 12.27C9.81752 12.3525 9.83252 12.45 9.90752 12.51L10.7025 13.125C10.6875 13.2525 10.68 13.3725 10.68 13.5C10.68 13.6275 10.6875 13.7475 10.7025 13.875L9.90752 14.49C9.84002 14.55 9.81752 14.6475 9.86252 14.73L10.6125 16.0275C10.6575 16.125 10.755 16.125 10.845 16.125L11.775 15.75C11.97 15.885 12.18 16.0125 12.4125 16.1025L12.555 17.0925C12.57 17.1825 12.645 17.25 12.75 17.25H14.25C14.3325 17.25 14.415 17.1825 14.43 17.0925L14.5725 16.1025C14.7975 16.005 15 15.885 15.2025 15.75L16.125 16.125C16.2225 16.125 16.32 16.125 16.3725 16.0275L17.1225 14.73C17.1675 14.6475 17.145 14.55 17.0775 14.49ZM13.5 14.625C12.87 14.625 12.375 14.1225 12.375 13.5C12.375 12.8775 12.8775 12.375 13.5 12.375C14.1225 12.375 14.625 12.8775 14.625 13.5C14.625 14.1225 14.1225 14.625 13.5 14.625ZM13.215 2.415C13.0725 2.31 12.915 2.25 12.75 2.25H2.25002C2.08502 2.25 1.92752 2.31 1.78502 2.415C1.46252 2.67 1.40252 3.1425 1.65752 3.465L5.25002 8.0625V11.9025C5.22002 12.12 5.29502 12.3525 5.46752 12.525L8.47502 15.5325C8.55002 15.6075 8.62502 15.66 8.73752 15.6975C8.41502 15 8.25002 14.25 8.25002 13.5C8.25002 12.1275 8.79002 10.8075 9.75002 9.825V8.0625L13.3425 3.465C13.5975 3.1425 13.5375 2.67 13.215 2.415ZM8.25002 7.5375V13.185L6.75002 11.685V7.545L3.78002 3.75H11.22L8.25002 7.5375Z"
        fill={color ? color : '#495A74'}
      />
    </svg>
  );
};
export default QuickFilterIcon;
