import {TABLE_LIMITS, IMPORT_EXCEL_LIMITS, FIELD_TYPE_ID} from './constant';
import moment from 'moment';
import {captureError, ENV, getReduxState, isProduction} from '../imports';
import {isNumber} from 'lodash';
import {dateParser, timeParser} from './dateTimeParser.util';
const xlsx = require('./xlsx');
// used for creating new file from imported excel
const getColumns = (refstr) =>
  Array.from({length: xlsx.utils.decode_range(refstr).e.c + 1}, (_, i) =>
    xlsx.utils.encode_col(i),
  );

// used for creating new file from imported excel
const getColHeaderAsExcel = (length = TABLE_LIMITS.MAX_COLUMNS) =>
  Array.from({length}, (_, i) => xlsx.utils.encode_col(i));

// used for creating new file from imported excel
const countKeysInObj = (obj) =>
  Object.keys(obj).reduce((acc, curr) => {
    if (typeof obj[curr] === 'object') return ++acc + countKeysInObj(obj[curr]);
    else return ++acc;
  }, 0);

const importFromExcel = async ({
  workbook,
  selectedSheet,
  includeEmptyRows = false,
  firstRowAsHeader = true,
  selectedFile,
  availableSheets,
}) =>
  new Promise((resolve, reject) => {
    if (!workbook) reject('workbook not found');
    try {
      const worksheet = workbook.Sheets?.[selectedSheet];
      const worksheetHasNoData = worksheet && worksheet['!ref'] ? false : true;
      const isWorksheetPasswordProtected = workbook['Preamble']?.protected
        ? true
        : false;

      if (worksheetHasNoData || isWorksheetPasswordProtected) {
        throw worksheetHasNoData
          ? 'This worksheet has no column. Try importing from some other worksheet.'
          : 'File is Password Protected. Please remove password from the file and try again.';
      }

      const options = {
        blankrows: includeEmptyRows,
      };
      let headerArr = [];
      let data = [];

      // setting headerArr(rawTableHeader) and data(rawTableData) in this if-else block
      if (firstRowAsHeader) {
        //first row as header
        const columnsArr = xlsx.utils
          .sheet_to_json(worksheet, {
            header: 1,
          })[0]
          ?.slice(0, TABLE_LIMITS.MAX_COLUMNS); //LIMIT columns to max allowed column length
        columnsArr.forEach((element) => {
          if (element) {
            headerArr.push(element);
          }
        });
        data = xlsx.utils.sheet_to_json(worksheet, options);
      } else {
        //first row not as header
        const columnsArr = getColumns(worksheet['!ref'])?.slice(
          0,
          TABLE_LIMITS.MAX_COLUMNS,
        ); //LIMIT columns to max allowed column length
        options.header = columnsArr;
        headerArr = columnsArr;
        data = xlsx.utils.sheet_to_json(worksheet, options);
      }

      // setting processed headerData which will be used to create a file
      const headerAsExcel = getColHeaderAsExcel(); // A,B,C,...
      const headerNameIDMap = {}; // headerName->headerID

      const headerData = headerArr.map((col, index) => {
        const headerId = moment().utc().valueOf() + headerAsExcel[index];
        headerNameIDMap[col] = headerId;
        return {
          fieldType: FIELD_TYPE_ID.TEXT,
          id: headerId,
          val: '' + col,
        };
      });

      if (headerData.length < 1) {
        throw 'This worksheet has no column. Try importing from some other worksheet.';
      }

      // setting processed tableData which will be used to create a file
      const tableData = [];
      let keysCount = 0;

      for (let index = 0; index < data.length; index++) {
        const row = data[index];
        const rowObj = {};
        for (const key in row) {
          const headerId = headerNameIDMap[key];
          if (headerId) {
            rowObj[headerId] = {val: row[key]};
          }
        }
        keysCount += countKeysInObj(rowObj);
        if (keysCount > IMPORT_EXCEL_LIMITS.MAX_TABLE_ROWS_COUNT) {
          break;
        }
        tableData.push(rowObj);
      }

      // deciding file name for new document
      let documentName = '',
        fileName = selectedFile?.name || '';
      if (fileName.endsWith('.xls') || fileName.endsWith('.csv')) {
        documentName = fileName.slice(0, -4);
      } else if (fileName.endsWith('.xlsx')) {
        documentName = fileName.slice(0, -5);
      }
      if (availableSheets > 1) {
        documentName += '(' + selectedSheet + ')';
      }

      resolve({documentName, headerData, tableData});
    } catch (err) {
      captureError(err);
      reject(err);
    }
  });

export default importFromExcel;

function string_to_number(tableData, colId) {
  tableData.forEach((data) => {
    if (data[colId]) {
      if (isNumber(data[colId].val)) return;
      const num = Number(data[colId].val?.replace?.(/[^0-9]/g, ''));
      if (num) data[colId].val = num;
      else delete data[colId];
    }
  });
}

function string_to_bool(tableData, colId) {
  tableData.forEach((data) => {
    if (!data[colId]) delete data[colId];
    else if (
      data[colId].val?.toLowerCase() === 'yes' ||
      data[colId].val?.toLowerCase() === 'true' ||
      data[colId].val === true
    )
      data[colId].val = true;
    else delete data[colId];
  });
}

function string_to_date(tableData, colId) {
  tableData.forEach((data) => {
    if (data[colId]) {
      let val = data[colId].val;
      val = dateParser(val);
      if (val) data[colId] = {val};
      else delete data[colId];
    }
  });
}

function string_to_time(tableData, colId) {
  tableData.forEach((data) => {
    if (data[colId]) {
      let val = data[colId].val;
      val = timeParser(val);
      if (val) data[colId] = {val};
      else delete data[colId];
    }
  });
}

function string_to_contact(tableData, colId) {
  tableData.forEach((data) => {
    if (data[colId]) {
      const [val, contactName] = `${data[colId].val}`.split(' ');
      data[colId] = {
        val: val?.trim()?.replace(/[(),]/g, ''),
        contactName: contactName?.trim()?.replace(/[(),]/g, ''),
      };
    }
  });
}

function string_to_url(tableData, colId) {
  tableData.forEach((data) => {
    if (data[colId]) {
      const [customText, val] = `${data[colId].val}`.split(' ');
      if (val && customText)
        data[colId].val = {
          val: val?.trim()?.replace(/[(),]/g, ''),
          customText: customText?.trim()?.replace(/[(),]/g, ''),
        };
    }
  });
}

function string_to_select(tableData, columnObj) {
  const options = {};
  columnObj.selectElements.forEach((item) => (options[item.val] = item));
  const colId = columnObj.id;
  tableData.forEach((data) => {
    if (data[colId]) {
      if (!options[data[colId]?.val]) {
        const newSelectObj = {
          val: data[colId].val,
          displayName: {EN: data[colId].val},
          valStyleObj: {
            color: 'rgb(0,0,0)',
            backgroundColor: 'rgb(255,255,255)',
          },
        };

        // adding new options in options obj
        options[data[colId].val] = newSelectObj;

        // adding new options in headerData
        columnObj.selectElements.push(newSelectObj);
      }
      data[colId] = options[data[colId].val];
    }
  });
}

function string_to_label(tableData, columnObj) {
  const options = {};
  columnObj.selectElements.forEach((item) => (options[item.val] = item));
  const colId = columnObj.id;
  tableData.forEach((data) => {
    if (data[colId]) {
      const vals = `${data[colId].val}`.split(',').map((item) => item.trim());
      const newLabelsArray = vals.map((value) => {
        if (!options[value]) {
          const newSelectObj = {
            val: value,
            displayName: {EN: value},
            valStyleObj: {
              color: 'rgb(0,0,0)',
              backgroundColor: 'rgb(255,255,255)',
            },
          };

          // adding new options in options obj
          options[value] = newSelectObj;

          // adding new options in headerData
          columnObj.selectElements.push(newSelectObj);
        }

        return options[value];
      });
      data[colId] = {val: newLabelsArray};
    }
  });
}

const convertNewlyAppendedData = ({headerData, tableData}) => {
  headerData.forEach((columnObj) => {
    if (columnObj.fieldType === FIELD_TYPE_ID.TEXT) return;

    if (
      columnObj.fieldType === FIELD_TYPE_ID.NUMBER ||
      columnObj.fieldType === FIELD_TYPE_ID.RUPEE ||
      columnObj.fieldType === FIELD_TYPE_ID.UNIT
    ) {
      string_to_number(tableData, columnObj.id);
      return;
    }

    if (
      columnObj.fieldType === FIELD_TYPE_ID.CHECKBOX ||
      columnObj.fieldType === FIELD_TYPE_ID.SWITCH
    ) {
      string_to_bool(tableData, columnObj.id);
      return;
    }

    if (columnObj.fieldType === FIELD_TYPE_ID.DATE) {
      string_to_date(tableData, columnObj.id);
      return;
    }

    if (columnObj.fieldType === FIELD_TYPE_ID.TIME) {
      string_to_time(tableData, columnObj.id);
      return;
    }

    if (columnObj.fieldType === FIELD_TYPE_ID.CONTACT) {
      string_to_contact(tableData, columnObj.id);
      return;
    }

    if (columnObj.fieldType === FIELD_TYPE_ID.REMINDER) {
      return;
    }

    if (columnObj.fieldType === FIELD_TYPE_ID.URL) {
      string_to_url(tableData, columnObj.id);
      return;
    }

    if (columnObj.fieldType === FIELD_TYPE_ID.SELECT_POP_UP) {
      string_to_select(tableData, columnObj);
      return;
    }

    if (columnObj.fieldType === FIELD_TYPE_ID.LABEL) {
      string_to_label(tableData, columnObj);
      return;
    }
  });
};

const uploadImportingExcelFile = (
  pickedFileName,
  fileURI,
  onUploadProgressChange,
  storageFirebaseInstance = null,
) => {
  const {
    auth: {user},
    miniApps: {activeAppId},
  } = getReduxState();
  return new Promise((resolve, reject) => {
    const handleError = (error) => reject(error);
    try {
      const storageBucket = isProduction
        ? 'import-mini-app-screen-prod'
        : 'import-mini-app-screen';
      const {bucketStorage} = require('../imports');
      const filename = `${new Date().toISOString()}_${encodeURIComponent(
        pickedFileName,
      )}`;
      const docRef = `${activeAppId}/${user.uid}/${filename}`;
      const reference = bucketStorage(
        storageBucket,
        storageFirebaseInstance,
      ).ref(docRef);
      const task = ENV ? reference.putFile(fileURI) : reference.put(fileURI);
      typeof onUploadProgressChange === 'function' &&
        task.on('state_changed', (taskSnapshot) => {
          const percentage = parseInt(
            (taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 100,
            10,
          );
          onUploadProgressChange?.(`${percentage}`);
        });
      return task.then(() => resolve(filename)).catch(handleError);
    } catch (e) {
      return handleError(e);
    }
  });
};

export {convertNewlyAppendedData, uploadImportingExcelFile};
