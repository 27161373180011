import {MINI_APPS_ACTION} from '../actions/actionType';
import {forOwn, isEmpty, merge, omit} from 'lodash';

const initialState = {
  miniApps: {}, //all the miniApps owned by user and shared with user
  FUNCTION_REFS: {}, //function refs of app/web-app folder to be called from redux side
  isLoading: false, //fetching miniApps
  activeAppId: '', //miniAppId of currently opened miniApp
  activeScreenId: '', //screenId of currently opened miniApp's screen
  appListeners: {}, //all the listeners linked with currently opened miniApp
  docsData: {}, //docId-docData mapping with all the docIds data
  searchFilterData: {}, //local search-filter data for currently opened miniApp
  customRoles: [], //custom roles related with currently opened miniApp
  activeCustomRoleInfo: {}, //currently opened miniApp custom role based actual restriction meta(if app is shared with user with a custom role)
  isAppsLoaded: false, //flag to check if miniApps are loaded
  ACCESS_MANAGER_INSTANCE: {
    isAllowed: () => false,
  }, //object which is used to check Feature access for the currently opened app
  businessCategories: [],
  usecaseCategories: [],
  endpointsList: [],
  excelExportsList: [],
  excelImportsList: [],
  importTemplateList: [],
  checkedRecords: [],
  isSelectRecords: false,
  screenLocalConfig: {},
  actionBtnsStatus: {},
  isMiniAppsDataFetched: false, // flag to check if fetch for miniApps data is fetched
  navigation: {},
  localImportJobIdArr: [],
  // different from isAppsLoaded ->  isAppsLoaded will not be true if number of miniApps for the user is 0
};

const clearAppListeners = (state) => {
  forOwn(state?.appListeners, (listener) => {
    if (typeof listener === 'function') {
      listener();
    }
  });
};

const miniAppsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MINI_APPS_ACTION.LOAD_MINI_APPS: {
      return {
        ...state,
        isMiniAppsDataFetched: true,
        ...(!state.isAppsLoaded
          ? {
              isAppsLoaded: Boolean(
                isEmpty(state.miniApps) && !isEmpty(action.payload),
              ),
            }
          : {}),
        miniApps: Object.assign({}, action.payload),
      };
    }
    case MINI_APPS_ACTION.UPDATE_LOADING_MINI_APPS: {
      return {
        ...state,
        isLoading: Boolean(action.payload),
      };
    }
    case MINI_APPS_ACTION.OPEN_APP: {
      return {
        ...state,
        activeAppId: action.payload.appId ?? state.activeAppId,
        activeScreenId: action.payload.screenId,
      };
    }
    case MINI_APPS_ACTION.SET_MINI_APP_ACCESS_INSTANCE: {
      return {
        ...state,
        ACCESS_MANAGER_INSTANCE: action.payload,
      };
    }
    case MINI_APPS_ACTION.UPDATE_SCREEN_LOCAL_CONFIG: {
      return {
        ...state,
        screenLocalConfig: Object.assign(
          {},
          state.screenLocalConfig,
          action.payload?.screenId
            ? {
                [action.payload.screenId]: Object.assign(
                  {},
                  state.screenLocalConfig[action.payload.screenId],
                  action.payload.updatedData,
                ),
              }
            : null,
        ),
      };
    }
    case MINI_APPS_ACTION.UPDATE_ACTION_BUTTONS_STATUS: {
      const {appId, screenId, rowId, actionBtnId, updatedObj} =
        action.payload ?? {};
      const updatedState = {
        ...state,
      };
      if (appId && screenId && rowId && actionBtnId) {
        updatedState.actionBtnsStatus = merge({}, state.actionBtnsStatus, {
          [appId]: {[screenId]: {[rowId]: {[actionBtnId]: null}}},
        });
        updatedState.actionBtnsStatus[appId][screenId][rowId][actionBtnId] =
          updatedObj;
      }
      return updatedState;
    }
    case MINI_APPS_ACTION.INITIAL_SCREEN_LOCAL_CONFIG: {
      return {
        ...state,
        screenLocalConfig: Object.assign({}, action.payload),
      };
    }
    case MINI_APPS_ACTION.CHANGE_SCREEN: {
      return {
        ...state,
        activeScreenId: action.payload.screenId,
      };
    }
    case MINI_APPS_ACTION.UPDATE_CUSTOM_ROLES_STATE: {
      return {
        ...state,
        customRoles: action.payload.customRoles,
      };
    }
    case MINI_APPS_ACTION.UPDATE_ACTIVE_CUSTOM_ROLE_INFO: {
      return {
        ...state,
        activeCustomRoleInfo: Object.assign({}, action.payload),
      };
    }
    case MINI_APPS_ACTION.UPDATE_EXPORTS_DATA_LIST: {
      return {
        ...state,
        excelExportsList: action.payload,
      };
    }
    case MINI_APPS_ACTION.UPDATE_IMPORTS_DATA_LIST: {
      return {
        ...state,
        excelImportsList: action.payload,
      };
    }
    case MINI_APPS_ACTION.SET_ALL_TEMPLATE_DATA: {
      return {
        ...state,
        importTemplateList: action.payload,
      };
    }
    case MINI_APPS_ACTION.UPDATE_MINI_APPS_DOCS_DATA: {
      if (state.activeAppId && action.payload.data) {
        return {
          ...state,
          docsData: Object.assign(
            {},
            state.docsData,
            action.payload.isOverwrite
              ? action.payload.data
              : {
                  [action.payload.docId]: Object.assign(
                    {},
                    state.docsData[action.payload.docId],
                    action.payload.data,
                    'rowIdDataMap' in action.payload.data
                      ? {
                          rowIdDataMap: Object.assign(
                            {},
                            state.docsData[action.payload.docId]?.rowIdDataMap,
                            action.payload.data.rowIdDataMap,
                          ),
                        }
                      : null,
                  ),
                },
          ),
        };
      }
      return state;
    }
    case MINI_APPS_ACTION.UPDATE_MINI_APPS_FILTERED_DOC_DATA: {
      if (
        state.activeAppId &&
        action.payload.docId &&
        action.payload.screenId &&
        action.payload.data
      ) {
        return {
          ...state,
          docsData: Object.assign({}, state.docsData, {
            [action.payload.docId]: Object.assign(
              {},
              state.docsData?.[action.payload.docId],
              {
                filterData: Object.assign(
                  {},
                  state.docsData?.[action.payload.docId]?.filterData,
                  {
                    [action.payload.screenId]: action.payload.isOverwrite
                      ? action.payload.data
                      : Object.assign(
                          {},
                          action.payload.isOverwrite === true
                            ? null
                            : state.docsData?.[action.payload.docId]
                                ?.filterData?.[action.payload.screenId],
                          action.payload.data,
                        ),
                  },
                ),
              },
            ),
          }),
        };
      }
      return state;
    }
    case MINI_APPS_ACTION.REMOVE_MINI_APPS_FILTERED_DOC_DATA: {
      if (
        state.activeAppId &&
        action.payload.docId &&
        action.payload.screenId
      ) {
        return {
          ...state,
          docsData: omit(
            state.docsData,
            `${action.payload.docId}.filterData.${action.payload.screenId}`,
          ),
        };
      }
      return state;
    }
    case MINI_APPS_ACTION.UPDATE_MINI_APPS_SEARCH_FILTER_DATA: {
      if (state.activeAppId) {
        const isRemove = action.payload.isRemove;
        return {
          ...state,
          searchFilterData: Object.assign(
            {},
            isRemove
              ? omit(state.searchFilterData, action.payload.screenId)
              : state.searchFilterData,
            isRemove
              ? null
              : {
                  [action.payload.screenId]: Object.assign(
                    {},
                    state.searchFilterData[action.payload.screenId],
                    action.payload.data,
                  ),
                },
          ),
        };
      }
      return state;
    }
    case MINI_APPS_ACTION.UPDATE_MINI_APPS_LISTENERS: {
      return {
        ...state,
        appListeners: Object.assign({}, state.appListeners, action.payload),
      };
    }
    case MINI_APPS_ACTION.CLOSE_APP: {
      clearAppListeners(state);
      const isReopenApp = action.payload?.isReopenApp;
      const commonOmittingStates = [
        'miniApps',
        'FUNCTION_REFS',
        'actionBtnsStatus',
        'isAppsLoaded',
        'isMiniAppsDataFetched',
      ];
      return {
        ...state,
        ...omit(
          initialState,
          isReopenApp
            ? ['activeAppId', 'activeScreenId', ...commonOmittingStates]
            : commonOmittingStates,
        ),
      };
    }
    case MINI_APPS_ACTION.RESET_STATE: {
      clearAppListeners(state);
      return initialState;
    }
    case MINI_APPS_ACTION.UPDATE_FUNCTION_REFS: {
      return {
        ...state,
        FUNCTION_REFS: Object.assign({}, state.FUNCTION_REFS, action.payload),
      };
    }
    case MINI_APPS_ACTION.LOAD_MINI_APPS_BUSINESS_CATEGORY: {
      return {
        ...state,
        businessCategories: action.payload.miniAppsBusinessCategories,
      };
    }
    case MINI_APPS_ACTION.LOAD_MINI_APPS_USECASE_CATEGORY: {
      return {
        ...state,
        usecaseCategories: action.payload.miniAppsUsecaseCategories,
      };
    }
    case MINI_APPS_ACTION.UPDATE_CHECKED_VALUES: {
      return {
        ...state,
        checkedRecords: [...action.payload],
      };
    }
    case MINI_APPS_ACTION.UPDATE_IS_SELECT_RECORDS: {
      return {
        ...state,
        isSelectRecords: action.payload,
      };
    }
    case MINI_APPS_ACTION.UPDATE_QUICK_FILTER_COLUMNS: {
      return {
        ...state,
        miniApps: Object.assign({}, state.miniApps, {
          [state.activeAppId]: action.payload.updatedMiniApp,
        }),
      };
    }
    case MINI_APPS_ACTION.LOAD_MINI_APPS_ENDPOINTS: {
      return {
        ...state,
        endpointsList: action.payload,
      };
    }
    case MINI_APPS_ACTION.UPDATE_AUTOMATION_RUNNING_STATUS: {
      return {
        ...state,
        appListeners: Object.assign({}, state.appListeners, action.payload),
      };
    }

    case MINI_APPS_ACTION.UPDATE_NAVIGATION_STATE: {
      const finalNavigationState = {
        path: action.payload?.path ?? state.navigation?.path,
        routeState: {
          ...(state.navigation?.routeState ?? {}),
          ...(action.payload?.routeState ?? {}),
        },
      };
      return {
        ...state,
        navigation: finalNavigationState,
      };
    }

    case MINI_APPS_ACTION.UPDATE_ENTRY_CONFIG: {
      return {
        ...state,
        screenEntryConfig: Object.assign({}, action.payload),
      };
    }

    default: {
      return state;
    }
  }
};
export default miniAppsReducer;
