import {isEmpty, isNil, omit} from 'lodash';
import {
  CREATED_INFO_COLUMN,
  FIELD_TYPE_ID,
  USER_INFO_COLUMN,
} from '../utils/constant';
import {solveEqnNew} from '../utils/equationHelper';

/**
 * Handle Table Data (Loop)
 * Note:
 * - To be used only once in any action, as to remove extra loops.
 * - Only use more than once if necessary.
 * @param {Array} tableData - Table Data for which the loop will be called
 * @param {object} options
 *     - Options for operations to be done on table data
 *     - Or data to be deduced/fetched from table for other operations
 * @returns
 */
const tableDataHandler = (tableData, options) => {
  const {evaluateEqnAndDependentColsOptions, childLinksOptions} = options ?? {};
  const childLinkRemoveArr = [];

  let updateTableData = [...tableData];

  for (let i = 0; i < tableData.length; i++) {
    const rowObj = tableData[i];
    const prevRow = i > 0 ? tableData[i - 1] : null;

    /**
     * Handle Delete Column
     */
    if (childLinksOptions?.columnRemoveArray?.length) {
      const removeRowObj = {};
      childLinksOptions.columnRemoveArray.forEach((item) => {
        const {id, fieldType} = item ?? {};
        const parentRowMeta = rowObj?.[id]?.parentRowMeta;

        if (id && fieldType === FIELD_TYPE_ID.TABLE && parentRowMeta) {
          removeRowObj[id] = {
            parentRowMeta: parentRowMeta,
          };
        }
      });
      if (!isEmpty(removeRowObj)) {
        removeRowObj.rowId = rowObj.rowId;
        childLinkRemoveArr.push(removeRowObj);
      }
    }

    /**
     * Evaluate Equation and Dependent Coulums
     */
    if (!isEmpty(evaluateEqnAndDependentColsOptions)) {
      updateTableData = evaluateEqnAndDependentCols(
        Object.assign({}, rowObj),
        prevRow,
        evaluateEqnAndDependentColsOptions.obj,
        {
          lastId: evaluateEqnAndDependentColsOptions.lastId,
          eqnArrs: evaluateEqnAndDependentColsOptions.eqnArrs,
          updatedTableData: updateTableData,
          index: i,
        },
      );
    }
  }
  return {updateTableData, childLinkRemoveArr};
};

const evaluateEqnAndDependentCols = (rowObj, prevRow, obj, options) => {
  const {lastId, eqnArrs, updatedTableData, index} = options ?? {};
  const tableData = [...updatedTableData];

  if (obj.eqn) {
    const val = solveEqnNew({
      eqn: obj.eqn,
      rowObj,
      prevRow,
    });
    const currentCellObj = rowObj[lastId] ?? {};
    const newObj = {};
    newObj[lastId] = Object.assign({}, currentCellObj, {
      val: val != null ? val : '',
    });
    rowObj = Object.assign({}, rowObj, newObj);
    tableData.splice(index, 1, rowObj);
  } else {
    // Persist data of Create Info Column
    rowObj = [CREATED_INFO_COLUMN.id, USER_INFO_COLUMN.id].includes(lastId)
      ? rowObj
      : omit(rowObj, [lastId]);
    tableData.splice(index, 1, rowObj);
  }

  const includeIds = [lastId];
  if (eqnArrs.length > 0) {
    for (let j = 0; j < includeIds.length; j++) {
      eqnArrs.forEach((eqnObj) => {
        if (isNil(eqnObj.eqnStr)) {
          return true;
        } else if (eqnObj.eqnStr.includes(includeIds[j])) {
          //solve for this eqn again
          const result = solveEqnNew({
            eqn: eqnObj.eqn,
            rowObj,
            prevRow,
          });
          const colId = eqnObj.colId;
          const currentCellObj = rowObj[colId] ?? {};
          const newObj = {};
          newObj[colId] = Object.assign({}, currentCellObj, {
            val: result != null ? result : '',
          });
          rowObj = Object.assign({}, rowObj, newObj);
          tableData.splice(index, 1, rowObj);
          if (result != null && includeIds.indexOf(colId) === -1) {
            includeIds.push(colId);
          }
        }
      });
    }
  }
  return tableData;
};

export {tableDataHandler};
