import {TABLE_ACTION} from '../../../actions/actionType';
import {checkAndUpdateEntryOnlyData} from '../tableActionHelper';
import {updateAllDashboardsProperty} from '../dashboardActionHelper';
import {
  forOwn,
  isArray,
  isEmpty,
  isNil,
  isNumber,
  isString,
  isEqual,
} from 'lodash';
import {extraActionsHandler} from './undoActions';
import {handleTaskUndoRedo} from './undoRedoHelper';
import {modifyTaskActiveState} from '../tasksActionHelper';
import {FIELD_TYPE_ID} from '../../../utils/constant';
import DocumentsMethods from '../../../FirestoreHandlers/Documents/DocumentsMethods';
import {getPrintTime} from '../../../utils/utils';

export const performRedoAction = (
  redoObj,
  docId,
  state,
  isShared,
  activeDocumentMeta,
  latestReduxState,
) => {
  const newState = {};
  if (
    !isEmpty(redoObj.redoDashboardUpdateObj) &&
    !isNil(redoObj.dashboardColumnId) &&
    !isEmpty(activeDocumentMeta?.dashboards?.[redoObj.dashboardColumnId])
  ) {
    const newDashboardUpdateObj = {};
    forOwn(redoObj.redoDashboardUpdateObj, (val, key) => {
      Object.assign(newDashboardUpdateObj, {
        [`pages.${docId}.${key}`]: val == undefined ? null : val,
      });
    });
    updateAllDashboardsProperty(
      {
        [redoObj.dashboardColumnId]:
          activeDocumentMeta.dashboards[redoObj.dashboardColumnId],
      },
      newDashboardUpdateObj,
    );
  }
  switch (redoObj.ACTION_TYPE) {
    case TABLE_ACTION.MOVE_ROW: {
      const tableData = state.tableData.slice();
      tableData.splice(redoObj.removeFrom, 1);
      tableData.splice(redoObj.insertAt, 0, redoObj.newRowObj);
      newState.tableData = tableData;
      DocumentsMethods.addMultipleRows(
        docId,
        [redoObj.newRowObj],
        null,
        false,
        false,
        true,
      );
      return newState;
    }
    case TABLE_ACTION.ADD_EMPTY_ROW: {
      const newRows = redoObj.newRows.slice();
      newState.tableData = [...state.tableData, ...newRows];
      newState.emptyRowIndexes = redoObj.newEmptyRowIndexes;
      DocumentsMethods.deleteOrRestoreMultipleRows(
        docId,
        newRows.map((obj) => obj.rowId),
        null,
        false,
        true,
      ); //restore
      return newState;
    }
    case TABLE_ACTION.ADD_COLUMN_AT_POS: {
      const headerData = state.headerData.slice();
      const extraColumnsToAdd = redoObj.extraColumnsToAdd ?? [];
      headerData.splice(
        redoObj.index,
        0,
        redoObj.headerDataObj,
        ...extraColumnsToAdd,
      );
      newState.headerData = headerData;
      const fileObj = Object.assign({}, redoObj.newFileObj);
      newState.fileObj = fileObj;
      DocumentsMethods.replaceHeaderData(docId, headerData, {
        fileObj: newState.fileObj,
      });
      checkAndUpdateEntryOnlyData({
        isShared,
        headerData,
        docId,
        fileObj,
      });
      return newState;
    }
    case TABLE_ACTION.EDIT_ROW: {
      const updatedRow = state.tableData.slice();

      const finalRowData = redoEditRowCommentHandler(
        state.headerData.slice(),
        updatedRow[redoObj.index],
        redoObj.newRowDataObj,
      );

      updatedRow.splice(redoObj.index, 1, finalRowData);
      newState.tableData = updatedRow;
      newState.footerData = redoObj.newFooterData;
      redoUniqueDataHandler(state, redoObj, docId);
      newState.fileObj = redoObj.newFileObj;
      DocumentsMethods.updateMultipleRows(docId, [finalRowData], {
        footerData: redoObj.newFooterData,
      });
      if (isArray(redoObj?.extraRedoActions)) {
        extraActionsHandler(redoObj.extraRedoActions, {latestReduxState});
      }
      handleTaskUndoRedo(docId, redoObj, true);
      return newState;
    }
    case TABLE_ACTION.SORT_COL: {
      newState.tableData = redoObj.newTableData;
      DocumentsMethods.updateMultipleRows(
        docId,
        newState.tableData,
        null,
        true,
        false,
        true,
      );
      return newState;
    }
    case TABLE_ACTION.DELETE_COL_OR_FORMULA: {
      const {rowsFirestoreUpdateIndexArr, undoColumnIdForUnique} = redoObj;
      newState.tableData = redoObj.newData.tableData;
      newState.headerData = redoObj.newData.headerData;
      newState.footerData = redoObj.newData.footerData;
      newState.fileObj = redoObj.newData.fileObj;

      const uniqueColumnData = redoObj.prevData.uniqueColumnData;
      undoColumnIdForUnique.forEach((colId) => {
        if (!isEmpty(uniqueColumnData) && uniqueColumnData[colId]) {
          DocumentsMethods.updateUniqueValuesDataForColumn(docId, colId, {
            isDeleted: true,
          });
        }
      });
      DocumentsMethods.updateMultipleRows(
        docId,
        rowsFirestoreUpdateIndexArr.map(
          (index) => redoObj.newData.tableData[index],
        ),
        {
          headerData: redoObj.newData.headerData,
          footerData: redoObj.newData.footerData,
          fileObj: redoObj.newData.fileObj,
        },
      );
      checkAndUpdateEntryOnlyData({
        isShared,
        headerData: redoObj.newData.headerData,
        docId,
        fileObj: redoObj.newData.fileObj,
      });
      if (isArray(redoObj?.extraRedoActions)) {
        extraActionsHandler(redoObj.extraRedoActions);
      }
      return newState;
    }
    case TABLE_ACTION.COPY_PASTE_DATA: {
      newState.tableData = redoObj.newData.tableData;
      newState.headerData = redoObj.newData.headerData;
      newState.footerData = redoObj.newData.footerData;

      redoUniqueDataHandler(
        state,
        {
          ACTION_TYPE: redoObj.ACTION_TYPE,
          oldHeader: state.headerData,
          newHeader: redoObj.newData.headerData,
          updatedUniqueColObj: redoObj.newData.uniqueColumnData,
        },
        docId,
      );
      newState.emptyRowIndexes =
        'emptyRowIndexes' in redoObj.extra
          ? redoObj.extra.emptyRowIndexes.slice()
          : state.emptyRowIndexes.slice();

      if (redoObj.changedRowsIndexArr?.length) {
        DocumentsMethods.updateMultipleRows(
          docId,
          redoObj.changedRowsIndexArr.map((index) => newState.tableData[index]),
          null,
          false,
          true,
        );
      }
      DocumentsMethods.deleteOrRestoreMultipleRows(
        docId,
        redoObj.newRowIndexArr.map((index) => newState.tableData[index].rowId),
        {headerData: newState.headerData, footerData: newState.footerData},
        false,
        true,
      ); //restore
      checkAndUpdateEntryOnlyData({
        isShared,
        headerData: newState.headerData,
        docId,
      });
      return newState;
    }
    case TABLE_ACTION.EDIT_COL: {
      const updatedHeader = redoObj.newHeaderData.slice();
      newState.headerData = updatedHeader;
      const fileObj = Object.assign({}, redoObj.newFileObj);
      newState.fileObj = fileObj;
      const colId = redoObj.prevColumnDataObj.id;
      if (
        redoObj.prevColumnDataObj?.columnProperties?.UNIQUE_VALUES !=
        redoObj.newColumnDataObj?.columnProperties?.UNIQUE_VALUES
      ) {
        DocumentsMethods.updateUniqueValuesDataForColumn(docId, colId, {
          isDeleted: redoObj.prevColumnDataObj?.columnProperties?.UNIQUE_VALUES,
        });
      }
      DocumentsMethods.replaceHeaderData(docId, updatedHeader, {
        fileObj: newState.fileObj,
      });
      checkAndUpdateEntryOnlyData({
        isShared,
        headerData: updatedHeader,
        docId,
        fileObj,
      });
      if (isArray(redoObj?.extraRedoActions)) {
        extraActionsHandler(redoObj.extraRedoActions);
      }
      return newState;
    }
    case TABLE_ACTION.EDIT_FORMULA_HEADER: {
      newState.tableData = redoObj.newData.tableData;
      newState.headerData = redoObj.newData.headerData;
      newState.footerData = redoObj.newData.footerData;
      DocumentsMethods.updateMultipleRows(
        docId,
        redoObj.rowsFirestoreUpdateIndexArr.map(
          (index) => redoObj.newData.tableData[index],
        ),
        {
          headerData: redoObj.newData.headerData,
          footerData: redoObj.newData.footerData,
        },
      );
      checkAndUpdateEntryOnlyData({
        isShared,
        headerData: redoObj.newData.headerData,
        docId,
      });
      return newState;
    }
    case TABLE_ACTION.DELETE_ROW: {
      newState.emptyRowIndexes = redoObj.currEmptyRowIndexes.slice();
      newState.tableData = redoObj.newTableData;
      newState.footerData = redoObj.newFooterData;
      redoUniqueDataHandler(state, redoObj, docId);
      newState.fileObj = redoObj.newFileObj;
      DocumentsMethods.deleteOrRestoreMultipleRows(
        docId,
        redoObj.deletedRowIdsArr,
        {footerData: newState.footerData},
      ); //delete
      if (isArray(redoObj?.extraRedoActions)) {
        extraActionsHandler(redoObj.extraRedoActions);
      }
      if (redoObj.deletedTaskIds?.length) {
        redoObj.deletedTaskIds.forEach((taskId) =>
          modifyTaskActiveState(docId, taskId, false),
        );
      }
      return newState;
    }
    case TABLE_ACTION.DELETE_ROW_COL_DATA: {
      newState.tableData = redoObj.newTableData;
      newState.footerData = redoObj.newFooterData;
      newState.fileObj = redoObj.newFileObj;
      redoUniqueDataHandler(state, redoObj, docId);
      DocumentsMethods.updateMultipleRows(
        docId,
        redoObj.rowsFirestoreUpdateObjArr,
        {footerData: newState.footerData},
      );
      if (isArray(redoObj?.extraRedoActions)) {
        extraActionsHandler(redoObj.extraRedoActions);
      }
      if (redoObj.deletedTaskIds?.length) {
        redoObj.deletedTaskIds.forEach((taskId) =>
          modifyTaskActiveState(docId, taskId, false),
        );
      }
      return newState;
    }
    case TABLE_ACTION.ADD_ROW_IN_BETWEEN: {
      newState.tableData = redoObj.newTableData.slice();
      newState.emptyRowIndexes = redoObj.currEmptyRowIndexes.slice();
      DocumentsMethods.deleteOrRestoreMultipleRows(
        docId,
        [newState.tableData[redoObj.index].rowId],
        null,
        false,
        true,
      ); //restore
      return newState;
    }
    case TABLE_ACTION.MOVE_COLUMN: {
      newState.headerData = redoObj.newHeaderData;
      DocumentsMethods.replaceHeaderData(docId, newState.headerData);
      checkAndUpdateEntryOnlyData({
        isShared,
        headerData: redoObj.newHeaderData,
        docId,
      });
      return newState;
    }
    default:
      return newState;
  }
};

export const redoEditRowCommentHandler = (
  headerData,
  currentRowData,
  newRowData,
) => {
  const commentColumnId = headerData.find(
    (colData) => colData.fieldType === FIELD_TYPE_ID.COMMENT,
  )?.id;

  return (isString(commentColumnId) || isNumber(commentColumnId)) &&
    !isEmpty(currentRowData?.[commentColumnId])
    ? Object.assign({}, newRowData, {
        [commentColumnId]: currentRowData[commentColumnId],
      })
    : newRowData;
};

export const redoUniqueDataHandler = (state, redoObj, docId) => {
  const uniqueColumnData = Object.assign({}, state.uniqueColumnData);
  if (!isEmpty(uniqueColumnData)) {
    const uniqueColIdArray = Object.keys(uniqueColumnData);
    if (TABLE_ACTION.COPY_PASTE_DATA === redoObj.ACTION_TYPE) {
      redoObj.newHeader?.forEach?.((colObj) => {
        if (colObj.id in redoObj.updatedUniqueColObj) {
          DocumentsMethods.updateUniqueValuesDataForColumn(docId, colObj.id, {
            isDeleted: false,
          });
        }
      });
    } else if (
      [TABLE_ACTION.DELETE_ROW, TABLE_ACTION.DELETE_ROW_COL_DATA].includes(
        redoObj.ACTION_TYPE,
      )
    ) {
      const modifiedArr = redoObj.deletedRowIdsArr ?? redoObj.modifiedRowIdArr;
      uniqueColIdArray.forEach((colId) => {
        const updatedUniqueObj = {};
        const colIndex = !isNil(
          state.headerMappedValues?.headerIdIndexMap?.[colId],
        )
          ? state.headerMappedValues?.headerIdIndexMap?.[colId]
          : null;

        if (
          colId in uniqueColumnData &&
          (redoObj.ACTION_TYPE === TABLE_ACTION.DELETE_ROW ||
            redoObj.modifiedColIdArr.includes(colId))
        ) {
          redoObj.prevTableData.forEach((rowObj) => {
            if (
              modifiedArr?.length &&
              modifiedArr.includes(rowObj.rowId) &&
              rowObj[colId]?.val
            ) {
              let newVal = `${rowObj[colId].val}`.toLowerCase().trim();
              if (
                state?.headerData?.[colIndex]?.fieldType &&
                state.headerData[colIndex].fieldType === FIELD_TYPE_ID.TIME
              ) {
                newVal = `${getPrintTime(newVal)}`.toLowerCase().trim();
              }
              updatedUniqueObj[`data.${newVal}`] =
                !uniqueColumnData[colId][newVal];
            }
          });

          DocumentsMethods.updateUniqueValuesDataForColumn(
            docId,
            colId,
            updatedUniqueObj,
          );
        }
      });
    } else {
      uniqueColIdArray.forEach((colId) => {
        let newVal = redoObj?.newRowDataObj?.[colId]?.val
          ? `${redoObj?.newRowDataObj?.[colId]?.val}`.toLowerCase()
          : redoObj?.prevRowDataObj?.[colId]?.val
          ? `${redoObj?.prevRowDataObj?.[colId]?.val}`.toLowerCase()
          : null;
        if (
          !isNil(newVal) &&
          !isEqual(redoObj.prevRowDataObj[colId], redoObj.newRowDataObj[colId])
        ) {
          const colIndex = !isNil(
            state.headerMappedValues?.headerIdIndexMap?.[colId],
          )
            ? state.headerMappedValues?.headerIdIndexMap?.[colId]
            : null;
          if (
            state?.headerData?.[colIndex]?.fieldType &&
            state.headerData[colIndex].fieldType === FIELD_TYPE_ID.TIME
          ) {
            newVal = `${getPrintTime(newVal)}`.toLowerCase().trim();
          }
          DocumentsMethods.updateUniqueValuesDataForColumn(docId, colId, {
            [`data.${newVal}`]: !uniqueColumnData[colId][newVal],
          });
        }
      });
    }
  }
};
