import {TABLE_VIEW_ACTIONS} from '../actions/actionType';
import {TABLE_VIEW_MODES} from '../utils/constant';

const initialState = {
  modes: {
    /**
     * Version, Template - Preview
     */
    [TABLE_VIEW_MODES.PREVIEW]: false,
    /**
     * User having CAN VIEW Permission
     */
    [TABLE_VIEW_MODES.COLLAB_CAN_VIEW]: false,
    /**
     * Opened shared document when no internet connection
     */
    [TABLE_VIEW_MODES.COLLAB_OFFLINE]: false,
    /**
     * Multiple Rows or Columns selected
     */
    [TABLE_VIEW_MODES.SELECTABLE_PREVIEW]: false,
    /**
     * Document Grid Lock Mode
     */
    [TABLE_VIEW_MODES.DOCUMENT_LOCK]: false,
    /**
     * User having custom permission
     */
    [TABLE_VIEW_MODES.CUSTOM_SHARED]: false,
  },
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case TABLE_VIEW_ACTIONS.UPDATE_VIEW_MODES: {
      return {
        ...state,
        modes: Object.assign({}, state.modes, payload),
      };
    }

    case TABLE_VIEW_ACTIONS.RESET_STATE:
    case TABLE_VIEW_ACTIONS.RESET_VIEW_MODES: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
