import {BUSINESS_ACTION} from '../actions/actionType';

const initialState = {
  companyInfo: {},
  businessProfileId: null,
  availableCredits: 0,
  isTestMode: false,
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUSINESS_ACTION.SET_BUSINESS_PROFILE: {
      return {
        ...state,
        companyInfo: action.payload.companyInfo,
        businessProfileId: action.payload.businessProfileId,
        isTestMode: action.payload.isTestMode,
      };
    }
    case BUSINESS_ACTION.GET_LIO_CREDITS: {
      return {
        ...state,
        availableCredits: action.payload,
      };
    }
    case BUSINESS_ACTION.RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default businessReducer;
