import React from 'react';
import {useSelector} from 'react-redux';
import LocalText from '../common/localText';
import useStyles from './TextStyle';
import '../Common.css';
import {getLocalText} from 'rb-redux/utils/utils';

function TextComponent(value, className) {
  const textKeys = Object.keys(value);
  const active = value['active'];
  const color = value['color'];
  const keys = textKeys.filter((text) => text !== 'active' && text !== 'color');
  const classes = useStyles();
  const userPref = useSelector((state) => state.auth.usePref);
  return (
    <>
      {keys &&
        keys.length > 0 &&
        keys.map((key) => (
          <p
            id={`${key}`}
            className={`${className} ${active ? key + ' active' : key}`}
            style={color ? {color: `${color}`} : null}>
            {value[key] &&
              (typeof value[key] === 'string' ||
                typeof value[key] === 'number') && (
                <LocalText>{getLocalText(userPref, value[key])}</LocalText>
              )}
          </p>
        ))}
    </>
  );
}

export default TextComponent;
