import {forEach, isEmpty} from 'lodash';
import {SEARCH_ACTION} from './actionType';
import {getTotalObject, calculateTotal} from '../utils/equationHelper';
import {captureError} from '../imports';
import {ROOT_FOLDER} from '../utils/constant';
import {isSearchValueExistsByType} from '../utils/utils';

const searchInRowObj = (
  rowObj,
  searchableStr,
  headerData,
  fileObj,
  language,
  userCountry,
) => {
  //also used in MyTasks container
  for (let i = 0; i < headerData.length; i++) {
    const rowData = rowObj?.[headerData[i]?.id] || {};
    const exist = isSearchValueExistsByType({
      searchText: searchableStr,
      rowData,
      colData: headerData[i],
      fileObj,
      userCountry,
      language,
      rowObj,
    });
    if (exist) {
      return true;
    }
  }
  return false;
};

const setSearchState = (isActive) => (dispatch) => {
  dispatch({
    type: SEARCH_ACTION.SET_ACTIVE_STATE,
    payload: isActive,
  });
};

const searchInTable =
  (searchStr, isTableSearch = false) =>
  (dispatch, getState) => {
    try {
      dispatch({
        type: SEARCH_ACTION.BEGIN_SEARCH,
        payload: {isTableSearch},
      });
      const {
        table: {tableData, headerData, footerData, fileObj},
        auth: {userPref},
      } = getState();
      const searchableStr = `${searchStr}`.toLowerCase();
      const textToSearchAsArr = searchableStr.split(';').filter((x) => x != '');
      const searchRows = [];
      const rowsAsTableData = [];
      return new Promise((resolve) => {
        for (let i = 0; i < tableData.length; i++) {
          let exist = false,
            filterSucceeded = true;
          for (
            let j = 0;
            j < textToSearchAsArr.length && filterSucceeded;
            ++j
          ) {
            filterSucceeded = searchInRowObj(
              tableData[i],
              textToSearchAsArr[j],
              headerData,
              fileObj,
              userPref.lang ?? 'EN',
              userPref.country ?? 'IN',
            );
            if (j === textToSearchAsArr.length - 1 && filterSucceeded) {
              exist = true;
            }
          }
          if (exist) {
            searchRows.push({
              tableIndex: i,
              data: tableData[i],
            });
            rowsAsTableData.push(tableData[i]);
          }
        }
        const searchableFooterObject = {};
        if (searchRows.length > 0 && !isEmpty(footerData)) {
          for (const key in footerData) {
            try {
              searchableFooterObject[key] = getTotalObject(
                footerData[key],
                calculateTotal(rowsAsTableData, key, headerData),
              );
            } catch (e) {}
          }
        }
        dispatch({
          type: SEARCH_ACTION.SEARCH_RESULT,
          payload: {
            tableDataWithRowIndex: searchRows,
            footerData: searchableFooterObject,
          },
        });
        return resolve();
      });
    } catch (error) {
      captureError(error);
    }
  };

const clearSearch = () => (dispatch) => {
  dispatch({
    type: SEARCH_ACTION.CLEAR_SEARCH,
  });
};

const createFoldersParentMap = () => (dispatch, getState) => {
  try {
    const {
      home: {files},
    } = getState();
    const foldersParentMap = {};
    files.forEach((doc) => {
      if (doc.documentMeta?.isFolder) {
        foldersParentMap[doc.documentId] =
          doc.documentMeta?.parent ?? ROOT_FOLDER;
      }
    });
    dispatch({
      type: SEARCH_ACTION.SET_FOLDERS_PARENT_MAP,
      payload: foldersParentMap,
    });
  } catch (error) {
    captureError(error);
  }
};

const searchFiles = (searchStr) => (dispatch, getState) => {
  try {
    const {
      home: {files: filesArr},
      search: {foldersParentMap},
    } = getState();
    const searchableStr = `${searchStr ?? ''}`.toLowerCase();
    const searchedFiles = [];
    const checkParentExist = (parent) => {
      if (isEmpty(foldersParentMap)) {
        return true;
      }
      while (parent in foldersParentMap) {
        parent = foldersParentMap[parent];
      }
      return parent === ROOT_FOLDER;
    };
    forEach(filesArr, (file, index) => {
      if (
        file.documentMeta &&
        (file.documentMeta.name || '').toLowerCase().includes(searchableStr) &&
        checkParentExist(file.documentMeta.parent || ROOT_FOLDER)
      ) {
        searchedFiles.push({...file, index});
      }
    });
    dispatch({
      type: SEARCH_ACTION.FILE_SEARCH_RESULT,
      payload: {
        searchedFiles,
      },
    });
  } catch (error) {
    captureError(error);
  }
};

export {
  setSearchState,
  searchInTable,
  clearSearch,
  searchFiles,
  createFoldersParentMap,
  searchInRowObj,
};
