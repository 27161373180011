import React, {useEffect, useMemo} from 'react';
//import firebase from "firebase";
import {Switch, Redirect, Route, useLocation} from 'react-router-dom';
import firebase from 'firebase';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import PropTypes from 'prop-types';
import {loadTime} from '../appConstants';
import {CircularProgress} from '@material-ui/core';
import PrivateRoute from './PrivateRoute';
import {routes} from './routes';
import {useSelector, useDispatch} from 'react-redux';
import {clearContactList} from 'rb-redux/actions/contactAction';
import {logOut} from 'rb-redux/actions/authAction';
import {getUserPref, convertURLHashToObject} from 'rb-redux/utils/utils';
import NotFound from '../containers/notFound';
import {
  LIO_AUTH_SCHEME,
  CLOUD_FUNCTION_PATHS,
  LIO_APP,
  LIO_WEB_APP,
} from 'rb-redux/utils/constant';
import {ModalButton} from '../components/modalButton/ModalButtonComponent';

import {useState} from 'react';
import {parseAndReturnShareURLParams} from 'rb-redux/utils/rowShareUtils';

const Login = loadable(
  () => pMinDelay(import('../containers/Login'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const GridContainer = loadable(
  () =>
    pMinDelay(import('../containers/GridContainer/GridContainer'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);
const Templates = loadable(
  () => pMinDelay(import('../containers/Template'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);
const Documents = loadable(
  () => pMinDelay(import('../containers/Documents'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);
const Template = loadable(
  () => pMinDelay(import('../pages/templates'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const Document = loadable(
  () => pMinDelay(import('../pages/documents'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const EntryOnly = loadable(
  () => pMinDelay(import('../containers/EntryOnly'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const Trash = loadable(
  () => pMinDelay(import('../containers/Trash'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const Profile = loadable(
  () => pMinDelay(import('../containers/Profile'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const Signup = loadable(
  () => pMinDelay(import('../containers/Auth/Signup'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const LoginMobile = loadable(
  () => pMinDelay(import('../containers/Auth/LoginMobile'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const SelectLanguage = loadable(
  () => pMinDelay(import('../containers/Auth/SelectLanguage'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const Videos = loadable(
  () => pMinDelay(import('../containers/videos'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const Dashboard = loadable(
  () => pMinDelay(import('../pages/dashboard'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const MyTasks = loadable(() => pMinDelay(import('../pages/tasks'), loadTime), {
  fallback: (
    <div
      style={{
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <CircularProgress />
    </div>
  ),
});

const ConnectWhatsApp = loadable(
  () => pMinDelay(import('../pages/ConnectWhatsApp'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const WhatsAppRedirect = loadable(
  () => pMinDelay(import('../pages/WhatsAppRedirect'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const MiniApps = loadable(
  () => pMinDelay(import('../pages/MiniAppsPage'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const MiniApp = loadable(
  () =>
    pMinDelay(
      import('../containers/MiniAppViewContainer/miniAppViewContainerWrapper'),
      loadTime,
    ),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const Onboarding = loadable(
  () => pMinDelay(import('../pages/onboarding'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const LioStore = loadable(
  () => pMinDelay(import('../pages/MiniAppStore'), loadTime),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const MiniAppAllNotifications = loadable(
  () =>
    pMinDelay(
      import(
        '../containers/miniAppsNotificationContainer/allNotifications/AllNotifications'
      ),
      loadTime,
    ),
  {
    fallback: (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    ),
  },
);

const MINI_APPS_ROUTE_MAP = {
  title: 'Apps',
  path: routes.APPS,
  component: MiniApps,
  allowOrgModeOnly: true,
};

const DOCUMENT_ROUTE_MAP = {
  title: 'My Documents',
  path: routes.DOCUMENT,
  component: Document,
};

const routeMapper = [
  {
    title: 'Grid',
    path: routes.GRID,
    component: GridContainer,
  },
  DOCUMENT_ROUTE_MAP,
  {
    title: 'Templates',
    path: routes.TEMPLATE,
    component: Template,
    allowNonOrgModeOnly: true,
  },

  {
    title: 'Videos',
    path: routes.VIDEOS,
    component: Videos,
  },

  {
    title: 'My Profile',
    path: routes.PROFILE,
    component: Profile,
  },
  // {
  //   title: 'Trash',
  //   path: routes.TRASH,
  //   component: Trash,
  // },
  {
    title: 'Select Language',
    path: routes.LANGUAGE,
    component: SelectLanguage,
  },
  {
    title: 'Entry Only',
    path: routes.ENTRY_ONLY,
    component: EntryOnly,
  },
  {
    title: 'Dashboard',
    path: routes.DASHBOARDS,
    component: Dashboard,
    allowNonOrgModeOnly: true,
  },
  {
    title: 'My Tasks',
    path: routes.MY_TASKS,
    component: MyTasks,
    allowNonOrgModeOnly: true,
  },
  {
    title: 'Connect WhatsApp',
    path: routes.CONNECT_WHATSAPP,
    component: ConnectWhatsApp,
  },
  MINI_APPS_ROUTE_MAP,
  {
    title: 'Apps',
    path: routes.APP,
    component: MiniApp,
    allowOrgModeOnly: true,
  },
  {
    title: 'Onboarding',
    path: routes.ONBOARDING,
    component: Onboarding,
  },
  {
    title: 'Lio Store',
    path: routes.LIOSTORE,
    component: LioStore,
    allowOrgModeOnly: true,
  },
  {
    title: 'Lio Store',
    path: routes.LIOSTORE_APP,
    component: LioStore,
    allowOrgModeOnly: true,
  },
  {
    title: 'notifications',
    path: routes.ALLNOTIFICATIONS,
    component: MiniAppAllNotifications,
  },
];

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const {search} = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

async function checkFromApp(user) {
  try {
    let data = {};
    if (user.phoneNumber) {
      data.phoneNumber = user.phoneNumber;
    } else {
      data = {
        providerId: user.providerData[0].providerId,
        email: user.email,
        user: user.toJSON(),
      };
    }
    const response = await firebase
      .functions()
      .httpsCallable(CLOUD_FUNCTION_PATHS.GET_LOGIN_TOKEN)(data);
    window.location.href = `${LIO_AUTH_SCHEME}?token=${response.data}`;
  } catch (err) {
    alert(err);
  }
}

// async function isUserOnboardingPending(user) {
//   if (user?.uid) {
//     const userDoc = await getUserPref(user.uid);

//     if (userDoc && userDoc.data() && userDoc.data().pref) {
//       const userPref = userDoc.data().pref;
//       return userPref.isOrgOnboardingPending;
//     }
//   }

//   return false;
// }

let isFirstLoad = true;

const AppRoutes = ({
  authenticated,
  user,
  isOrganisationModeFetched,
  setIsLoginSuccessAnalyticsPending,
}) => {
  const isNewUser = useSelector((state) => state.newUser.isNewUser);
  const isOrganisationMode = useSelector(
    (state) => state.remoteConfig.isOrganisationMode,
  );
  const {isOrgOnboardingPending} = useSelector((state) => state.auth.userPref);
  const {isOrgDataFetched, isOrgSubscriptionsFetched} = useSelector(
    (state) => state.organisation,
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const query = useQuery();
  // const [onboardPending, setOnboardPending] = useState(false);

  useEffect(() => {
    const from = query.get('from');
    const token = query.get('token');
    if (from && ['lioapp', 'liowebapp'].includes(from)) {
      if (token) {
        firebase.auth().signInWithCustomToken(token);
      }
    }
  }, [query]);

  // useEffect(() => {
  //   const getOnboardPending = async () => {
  //     const isPending = await isUserOnboardingPending(user);
  //     setOnboardPending(isPending);
  //     setIsLoading(false);
  //   };
  //   if (authenticated) {
  //     getOnboardPending();
  //   }
  // }, [authenticated]);

  const from = query.get('from');
  const forceLogout = query.get('forceLogout');

  if (
    isLoading ||
    !isOrganisationModeFetched ||
    (isOrganisationMode && (!isOrgDataFetched || !isOrgSubscriptionsFetched)) ||
    (!isOrganisationMode &&
      from &&
      from === LIO_APP &&
      !isOrgOnboardingPending &&
      !isNewUser) // check for to-be new non-org user who is logging in for the first time but some doc has already been shared with the user
  ) {
    const shouldShowLoader =
      from && [LIO_APP, LIO_WEB_APP].includes(from) && forceLogout
        ? !isFirstLoad
        : true;
    if (shouldShowLoader && authenticated) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const from = urlParams.get('from');
      const redirect = urlParams.get('redirect');

      const showOpenApp =
        from &&
        from === LIO_APP &&
        !isOrgOnboardingPending &&
        !isNewUser &&
        forceLogout;
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div
            style={{
              display: 'flex',
            }}>
            <CircularProgress />
          </div>
          {showOpenApp ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                alignItems: 'center',
              }}>
              {
                <>
                  <div>Click on the below button to open app</div>
                  <ModalButton
                    text="Open App"
                    height="48px"
                    width="100px"
                    onClick={() => checkFromApp(user)}
                  />
                </>
              }
            </div>
          ) : null}
        </div>
      );
    }
  }

  const defaultRouteMap = isOrganisationMode
    ? MINI_APPS_ROUTE_MAP //TODO: check
    : DOCUMENT_ROUTE_MAP;
  const defaultPath = defaultRouteMap.path;

  return (
    <Switch>
      <Route
        exact
        path={routes.HOME}
        title={''}
        render={() => {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const from = urlParams.get('from');
          const redirect = urlParams.get('redirect');
          const menu = urlParams.get('menu');
          const category = urlParams.get('category');
          const showOrg = urlParams.get('show-org');
          const linkParamsFromApp = urlParams.get('linkParams');

          return (
            <Redirect
              to={{
                pathname: authenticated
                  ? isOrgOnboardingPending === true
                    ? routes.ONBOARDING
                    : isNewUser
                    ? routes.LANGUAGE
                    : defaultPath
                  : routes.SIGNUP,
                search: parseAndReturnShareURLParams(urlParams).length
                  ? parseAndReturnShareURLParams(urlParams)
                  : redirect && redirect !== ''
                  ? `?redirect=${redirect}&menu=${menu}&from=${from}`
                  : showOrg || category || linkParamsFromApp
                  ? `linkParams=${linkParamsFromApp}&show-org=${showOrg}&category=${category}&from=${from}`
                  : from
                  ? `from=${from}`
                  : '',
              }}
            />
          );
        }}
      />
      <Route
        exact
        path={routes.SIGNUP}
        render={(props) => {
          if (authenticated) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const from = urlParams.get('from');
            const redirect = urlParams.get('redirect');
            const menu = urlParams.get('menu');
            const logout = urlParams.get('forceLogout');

            if (from && from === LIO_APP) {
              if (logout === 'y3s' && isFirstLoad) {
                dispatch(clearContactList());
                dispatch(logOut());
                firebase
                  .auth()
                  .signOut()
                  .then(() => setIsLoading(false));
                return;
              } //Replace !redirect with !isNewUser to enable onboarding
              else if (!isOrgOnboardingPending) {
                // false for onboarded org user and all non-org users
                setIsLoading(true);
                checkFromApp(user);
                return;
              }
            }

            return (
              <Redirect
                to={{
                  pathname:
                    redirect && redirect !== ''
                      ? routes.PROFILE
                      : isOrgOnboardingPending === true
                      ? routes.ONBOARDING
                      : isNewUser
                      ? routes.LANGUAGE
                      : isOrganisationMode
                      ? routes.APPS
                      : routes.DOCUMENT,

                  // state: { from: props.location },
                  search: parseAndReturnShareURLParams(urlParams).length
                    ? parseAndReturnShareURLParams(urlParams)
                    : redirect && redirect !== ''
                    ? `?redirect=${redirect}&menu=${menu}&from=${from}`
                    : from
                    ? `from=${from}`
                    : '',
                  // state: { from: props.location },
                }}
              />
            );
          } else {
            isFirstLoad = false;
            return (
              <Signup
                {...props}
                title={'Lio Web Login | Access Your Custom Enterprise Apps'}
                setIsLoginSuccessAnalyticsPending={
                  setIsLoginSuccessAnalyticsPending
                }
              />
            );
          }
        }}
      />
      <Route
        exact
        path={routes.LOGINMOBILE}
        render={(props) => {
          const redirectLinkParams = new URLSearchParams(props.location.search);
          const menu = redirectLinkParams.get('menu');
          const redirect = redirectLinkParams.get('redirect');
          if (authenticated) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const from = urlParams.get('from');
            const logout = urlParams.get('forceLogout');

            if (from && from === LIO_APP) {
              if (logout === 'y3s' && isFirstLoad) {
                dispatch(clearContactList());
                dispatch(logOut());
                firebase
                  .auth()
                  .signOut()
                  .then(() => setIsLoading(false));

                return;
              }
              if (!isOrgOnboardingPending) {
                // false for onboarded org user and all non-org users
                setIsLoading(true);
                checkFromApp(user);
                return;
              }
              // else if (!isNewUser) {
              //   setIsLoading(true);
              //   checkFromApp(user);
              //   return;
              // }
            }

            return (
              <Redirect
                to={{
                  pathname:
                    redirect && redirect !== ''
                      ? routes.PROFILE
                      : isOrgOnboardingPending === true
                      ? routes.ONBOARDING
                      : isNewUser
                      ? routes.LANGUAGE
                      : isOrganisationMode
                      ? routes.APPS
                      : routes.DOCUMENT,
                  // state: { from: props.location },
                  search: parseAndReturnShareURLParams(urlParams).length
                    ? parseAndReturnShareURLParams(urlParams)
                    : redirect && redirect !== ''
                    ? `?redirect=${redirect}&menu=${menu}&from=${from}`
                    : from
                    ? `from=${from}`
                    : '',
                }}
              />
            );
          } else {
            return (
              <LoginMobile
                {...props}
                title={'Login Mobile'}
                setIsLoginSuccessAnalyticsPending={
                  setIsLoginSuccessAnalyticsPending
                }
                isWebView={from === LIO_APP}
              />
            );
          }
        }}
      />
      <Route
        path={`${routes.WHATSAPP_REDIRECT}/:contactNumber`}
        render={(props) => (
          <WhatsAppRedirect {...props} title={'Login Mobile'} />
        )}
      />
      {routeMapper.map((route) => {
        return (
          <PrivateRoute
            exact
            checkFromApp={checkFromApp}
            user={user}
            path={route.path}
            title={route.title}
            component={route.component}
            authenticated={authenticated}
            key={route.title}
            route={route}
            defaultRouteMap={{
              ...defaultRouteMap,
            }}
            isOnboardingPending={isOrgOnboardingPending === true}
          />
        );
      })}
      <Route path={routes.NOT_FOUND} component={NotFound} />
      <Redirect to={routes.NOT_FOUND} />
    </Switch>
  );
};

AppRoutes.propTypes = {
  authenticated: PropTypes.bool,
};

export default AppRoutes;
