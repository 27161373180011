import {combineReducers} from 'redux';

import activityLog from './activityLogReducer';
import aggregation from './aggregationReducer';
import auth from './authReducer';
import automation from './automationReducer';
import business from './businessReducer';
import campaigns from './campaignReducer';
import collab from './collabReducer';
import contacts from './contactsReducer';
import copyPaste from './copyPasteReducer';
import dashboard from './dashboardReducer';
import documentFilter from './documentFilterReducer';
import elasticDashboards from './elasticDashboardsReducer';
import entryStatus from './entryStatusReducer';
import fileFolderSearch from './fileFolderSearchReducer';
import home from './homeReducer';
import integration from './integrationReducer';
import listColumns from './listColumnReducer';
import messageTemplates from './messageTemplatesReducer';
import miniApps from './miniAppsReducer';
import miniAppsReports from './miniAppReportsReducer';
import miniAppsStore from './miniAppsStoreReducer';
import modal from './modalReducer';
import netInfo from './netInfoReducer';
import newUser from './newUserReducer';
import organisation from './organisationsReducer';
import pagination from './paginationReducer';
import persistedData from './persistedDataReducer';
import premium from './premiumReducer';
import pushNotifications from './pushNotificationsReducer';
import remoteConfig from './remoteConfigReducer';
import rowHeight from './rowHeightReducer';
import search from './searchReducer';
import searchFilter from './searchFilterReducer';
import table from './tableReducer';
import tableLinks from './tableLinkReducer';
import tableView from './tableViewReducer';
import tasks from './tasksReducer';
import teams from './teamReducer';
import template from './templateReducer';
import trash from './trashReducer';
import version from './versionReducer';
import webinars from './webinarReducer';
import runningProcesses from './runningProcessesReducer';

export const reducers = {
  activityLog,
  aggregation,
  auth,
  automation,
  business,
  campaigns,
  collab,
  contacts,
  copyPaste,
  dashboard,
  documentFilter,
  elasticDashboards,
  entryStatus,
  fileFolderSearch,
  home,
  integration,
  listColumns,
  messageTemplates,
  miniApps,
  miniAppsReports,
  miniAppsStore,
  modal,
  netInfo,
  newUser,
  organisation,
  pagination,
  persistedData,
  premium,
  pushNotifications,
  remoteConfig,
  rowHeight,
  search,
  searchFilter,
  table,
  tableLinks,
  tableView,
  runningProcesses,
  tasks,
  teams,
  template,
  trash,
  version,
  webinars,
};

export default combineReducers(reducers);
