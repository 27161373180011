import {FILE_FOLDER_SEARCH} from '../actions/actionType';

const initialState = {
  isSearching: false,
  query: '',
  folderParentMapCreated: false,
};

const fileFolderSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILE_FOLDER_SEARCH.START_SEARCH: {
      return {...state, isSearching: true};
    }
    case FILE_FOLDER_SEARCH.STOP_SEARCH: {
      return {...initialState};
    }
    case FILE_FOLDER_SEARCH.SEARCH_INPUT_CHANGE: {
      return {...state, query: action.payload};
    }
    default:
      return state;
  }
};

export default fileFolderSearchReducer;
