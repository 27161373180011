import {MINI_APPS_STORE_ACTION} from '../actions/actionType';

const initialState = {
  isLoading: false,
  appsCategory: {},
  appsList: {},
  selectedCategoryId: null,
  appDetails: {},
  selectedAppId: null,
  appsSectionList: [],
  allAppsCount: 0,
};

const miniAppsStoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case MINI_APPS_STORE_ACTION.LOAD_STORE_APPS_CATEGORY: {
      return {
        ...state,
        appsCategory: action.payload,
      };
    }
    case MINI_APPS_STORE_ACTION.SET_SELECTED_APP_DETAILS: {
      return {
        ...state,
        appDetails: action.payload.appDetails,
        selectedAppId: action.payload.selectedAppId,
      };
    }
    case MINI_APPS_STORE_ACTION.SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case MINI_APPS_STORE_ACTION.LOAD_STORE_APPS_LIST: {
      return {
        ...state,
        appsList: action.payload.appsList,
        ...(action.payload.selectedCategoryId
          ? {selectedCategoryId: action.payload.selectedCategoryId}
          : {}),
      };
    }
    case MINI_APPS_STORE_ACTION.SET_APPS_SECTION_LIST: {
      return {
        ...state,
        appsSectionList: action.payload.appsSectionList,
        allAppsCount: action.payload.allAppsCount,
      };
    }
    case MINI_APPS_STORE_ACTION.CLEAR_SELECTED_APP: {
      return {
        ...state,
        selectedAppId: null,
      };
    }
    case MINI_APPS_STORE_ACTION.CLEAR_SELECTED_APP_CATEGORY: {
      return {
        ...state,
        selectedCategoryId: null,
      };
    }
    case MINI_APPS_STORE_ACTION.RESET_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default miniAppsStoreReducer;
