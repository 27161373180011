const AutomationIcon = ({color}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33333 6.31366V7.33366H5.84L4.66667 9.68699V8.66699H2.16L3.33333 6.31366ZM4.66667 0.666992L0 10.0003H3.33333V15.3337L8 6.00033H4.66667V0.666992Z"
        fill={color ? color : '#495A74'}
      />
    </svg>
  );
};
export default AutomationIcon;
