import {
  ORGANISATION_ACTION,
  HOME_ACTION,
  REMOTE_CONFIG_ACTION,
} from './actionType';
import {
  addOrUpdateProfileDataHelper,
  manageOrganisationAppAccessCloudFunction,
  updateOrganisationSubscriptionCF,
  cancelOrganisationSubscriptionCF,
  getAllAppsInOrganisationHelper,
} from './actionHelpers/organisationActionHelper';
import FirestoreDB from '../FirestoreHandlers/FirestoreDB';
import {firestore, captureError, captureInfo, ENV, ShowToast} from '../imports';
import {isEmpty, isObject, isString, omit} from 'lodash';
import {loadSubscriptions} from './premiumAction';
import {
  getUserCallingCode,
  handleCloudErrorMsgAndLogging,
} from '../utils/utils';
import {
  SUBSCRIPTION_PLAN,
  ORGANISATION_ROLES,
  getOrganisationSubscriptionFormatted,
} from '../utils/organisation';
import {activateMiniAppsListener} from './miniAppsAction';
import {getInitialStoreData} from './miniAppsStoreAction';
import {ORGANISATION_ACCESS_LEVEL} from '../utils/constant';
import {generateRandomId} from '../utils/RandomGenerator';

/**
 *
 * @param {boolean} showModal : show modal if feature is restricted
 * @param {boolean} checkPlan : check if user is on plus plan
 * @param {string} source : for logging
 * @param {boolean} isAddOn : Is Add On Feature
 * @returns {boolean} : true if user has access to feature else false
 */
const checkUserPlanForAccess =
  (showModal = false, checkPlan = false, source, addOn = null) =>
  (_, getState) => {
    try {
      const {
        miniApps: {FUNCTION_REFS},
        organisation: {orgSubscriptions},
      } = getState();

      const {isTrial, plan, isActive} = orgSubscriptions;

      if (isActive && addOn?.length) {
        return orgSubscriptions.addOns?.includes?.(addOn);
      }

      if (isActive) {
        if (checkPlan) {
          if (isTrial || plan.planName === SUBSCRIPTION_PLAN.PRO) {
            return true;
          }
        } else {
          return true;
        }
      }

      if (!showModal) return false;

      if (ENV) {
        FUNCTION_REFS.FEATURE_WEB_REDIRECT_MODAL?.(source);
      }
    } catch (err) {
      captureError(err);
    }
    return false;
  };

/**
 * @param {*} passCustomClaimsCallback : callback which might require custom
 *                                       claims for some processes(if isOrganisationMode=false)
 */
const getUserOrganisationInfo =
  (authInstance, firestoreInstance, passCustomClaimsCallback) =>
  async (dispatch) => {
    let isOrganisationMode = false;
    const customClaimObj = {};
    try {
      const {currentUser} = authInstance();
      const getCustomClaims = (...args) =>
        currentUser
          .getIdTokenResult(...args)
          .then((idTokenResult) => Object.assign({}, idTokenResult?.claims))
          .catch(() => null);
      const response = await Promise.all([
        getCustomClaims(true),
        getCustomClaims(),
      ]);
      if (!Array.isArray(response)) {
        return false;
      }

      Object.assign(
        customClaimObj,
        Object.assign(
          {},
          response.find((res) => !isEmpty(res)),
        ),
      );
      const {orgId, role, elasticIndex} = customClaimObj;
      if (orgId && role) {
        isOrganisationMode = true;
        dispatch({
          type: ORGANISATION_ACTION.SET_USER_ORG_INFO,
          payload: {orgId, role, elasticIndex},
        });
        const userUID = currentUser.uid;
        dispatch(activateOrganisationListener(userUID, firestoreInstance));
        dispatch(activateOrganisationSubscriptionListener(firestoreInstance));
      }
    } catch (error) {
      captureError(error);
    }
    dispatch({
      type: REMOTE_CONFIG_ACTION.SET_ORGANISATION_MODE_VALUE,
      payload: isOrganisationMode,
    });
    if (!isOrganisationMode && typeof passCustomClaimsCallback === 'function') {
      passCustomClaimsCallback(customClaimObj);
    }
    return isOrganisationMode;
  };

/**
 * listener for fetching organisation subscription data
 */

const activateOrganisationSubscriptionListener =
  (firestoreInstance) => (dispatch, getState) => {
    try {
      const dummyOldPremiumObj = {
        entitlements: {
          active: {
            'businessPro.499.monthly.member': {
              expirationDate: '2025-10-02T18:30:00.000Z',
              identifier: 'businessPro.499.monthly',
              isActive: true,
              productIdentifier: 'rc_promo_businessPro.499.monthly_yearly',
            },
          },
        },
      };
      dispatch(loadSubscriptions(dummyOldPremiumObj, {forceSet: true}));

      firestoreInstance = firestoreInstance ?? firestore;
      const {
        organisation: {activeOrganisationId, isUserOrganisationOwner},
      } = getState();
      const onDocumentDataChange = (QuerySnapshot) => {
        try {
          const defaultSchema = getOrganisationSubscriptionFormatted(
            QuerySnapshot.exists ? QuerySnapshot.data() : {},
          );
          dispatch({
            type: ORGANISATION_ACTION.UPDATE_ORGANISATION_SUBSCRIPTION,
            payload: defaultSchema,
          });
          if (!defaultSchema.isActive && !isUserOrganisationOwner) {
            dispatch({
              type: ORGANISATION_ACTION.SET_ACCOUNT_LOCKED,
              payload: true,
            });
          } else if (
            defaultSchema.isActive &&
            getState().organisation.isAccountLocked
          ) {
            dispatch({
              type: ORGANISATION_ACTION.SET_ACCOUNT_LOCKED,
              payload: false,
            });
          }
        } catch (err) {
          captureInfo({
            QuerySnapshot,
            activeOrganisationId,
          });
          captureError(err);
        }
      };
      const listenerInstance = FirestoreDB.FirestoreListener(
        FirestoreDB.organisations.subscription(
          activeOrganisationId,
          firestoreInstance,
        ),
        onDocumentDataChange,
      );
      dispatch({
        type: HOME_ACTION.UPDATE_HOME_LISTENER_OBJ,
        payload: {ORG_SUBSCRIPTION: listenerInstance},
      });
    } catch (error) {
      captureError(error);
    }
  };

// Update organisation profile data
const addOrUpdateProfileData =
  (profileData, isEdit, authInstance) => async (dispatch, getState) => {
    try {
      const {
        organisation: {activeOrganisationId: organisationId},
      } = getState();
      const objToSend = {
        type: isEdit ? 'updateProfile' : 'createProfile',
        payload: {
          profileData: profileData,
          organisationId: isEdit ? organisationId : undefined,
        },
      };
      const response = await addOrUpdateProfileDataHelper(objToSend);
      if (!isEdit) {
        dispatch({
          type: ORGANISATION_ACTION.SET_USER_ORG_INFO,
          payload: {
            orgId: organisationId,
            role: ORGANISATION_ROLES.OWNER_ROLE,
          },
        });
        dispatch({
          type: ORGANISATION_ACTION.CREATE_PROFILE,
          payload: {profileData},
        });
        const isOrganisationMode = await dispatch(
          getUserOrganisationInfo(authInstance),
        );
        if (isOrganisationMode) {
          dispatch(activateMiniAppsListener());
        }
      }
      return {
        success: response?.success,
        data: response?.data,
      };
    } catch (error) {
      captureError(error);
    }
  };

const addTeamMember = (obj) => async () => {
  try {
    const objToSend = {
      type: 'addMembers',
      payload: {
        organisationId: obj?.organisationId,
        contacts: obj.contacts.map((memberToInviteObj) => {
          // typeof memberToInviteObj => {contact, name}
          return Object.assign({}, memberToInviteObj);
        }),
        role: obj.role,
      },
    };
    const response = await addOrUpdateProfileDataHelper(objToSend);
    return {
      success: response?.success,
      data: response?.data,
    };
  } catch (error) {
    captureError(error);
  }
};

const removeTeamMember = (obj) => async () => {
  try {
    const objToSend = {
      type: 'removeMembers',
      payload: {organisationId: obj?.organisationId, uidArray: obj?.uidArray},
    };

    const response = await addOrUpdateProfileDataHelper(objToSend);
    return {
      success: response?.success,
      data: response?.data,
    };
  } catch {}
};

const activateOrganisationListener =
  (userUID, firestoreInstance) => (dispatch, getState) => {
    try {
      const {
        auth: {user},
        organisation: {activeOrganisationId},
      } = getState();
      firestoreInstance = firestoreInstance ?? firestore;
      userUID = userUID ?? user?.uid;
      if (!userUID || !activeOrganisationId) {
        return;
      }

      let isFirstCall = true;
      const handleOrganisationListener = (snaphot) => {
        try {
          if (snaphot.exists) {
            const data = snaphot.data();
            if (!isEmpty(data)) {
              dispatch({
                type: ORGANISATION_ACTION.LOAD_ORGANISATION_DATA,
                payload: data,
              });
            }

            if (isFirstCall) {
              isFirstCall = false;
              dispatch(getInitialStoreData());
            }
          }
        } catch (error) {
          captureError(error);
        }
      };

      const listener = FirestoreDB.FirestoreListener(
        FirestoreDB.organisations.doc(activeOrganisationId, firestoreInstance),
        handleOrganisationListener,
      );

      dispatch({
        type: HOME_ACTION.UPDATE_HOME_LISTENER_OBJ,
        payload: {
          ORGANISATION_LISTENER: listener,
        },
      });
    } catch (error) {
      captureError(error);
    }
  };

const updateMemberAccess = (memberAccessObj) => async (dispatch, getState) => {
  try {
    const {
      auth: {user, userCountry, userPref},
      organisation: {activeOrganisationId: organisationId, profileData},
      organisation,
    } = getState();
    const countryCode = getUserCallingCode(userCountry ?? 'IN');
    const addByContact = user.phoneNumber || user.email;
    const userAcessLevel =
      user?.uid === profileData?.ownerUID
        ? ['OWNER']
        : organisation?.membersList?.[user?.uid]?.access_level || [];
    const dataObj = {
      addByContact,
      countryCode,
      organisationId,
      organisationConfigObj: memberAccessObj,
      userAcessLevel,
    };

    const data = await manageOrganisationAppAccessCloudFunction(dataObj);
    if (!data || !data.success) {
      handleCloudErrorMsgAndLogging(data, dataObj, userPref, true);
    } else {
      return true;
    }
  } catch (error) {
    captureError(error);
  }
  return false;
};

/**
 *
 * @param {Object} memberInfo: profile data for organisation member
 * @returns true if member info updated successfully, else false
 */
const updateMemberInfo =
  (memberInfo, memberUID, oldTeam = []) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userPref},
        organisation: {
          activeOrganisationId: organisationId,
          membersList,
          profileData,
        },
      } = getState();
      if (!isObject(memberInfo) || isEmpty(memberInfo)) {
        ShowToast('Cannot add empty profile data', userPref);
        return false;
      }

      if (
        !isString(memberUID) ||
        isEmpty(memberUID) ||
        (profileData.ownerUID !== memberUID &&
          !Object.keys(membersList).includes(memberUID))
      ) {
        ShowToast('Cannot add profile data for invalid user', userPref);
        return false;
      }

      const payload = {
        profileData: {...memberInfo},
        organisationId,
        memberUID,
        oldTeam,
      };

      const payloadForCF = {
        type: 'updateMemberProfile',
        payload,
      };
      const response = await addOrUpdateProfileDataHelper(payloadForCF);
      if (!response || !response.success) {
        handleCloudErrorMsgAndLogging(response, payloadForCF, userPref, true);
      } else {
        return true;
      }
    } catch (error) {
      captureError(error);
    }
    return false;
  };

/**
 *
 * @param {Array} customFields: array of custom field objects {fieldId, name} (existing + new)
 * @returns true if organisation is updated with custom fields else false
 */
const updateOrganisationCustomFields =
  (customFields) => async (dispatch, getState) => {
    try {
      const {
        auth: {userPref},
        organisation: {activeOrganisationId: organisationId},
      } = getState();

      const payload = {organisationId, customFields: [...customFields]};

      const payloadForCF = {
        type: 'updateMemeberCustomFields',
        organisationId,
        payload,
      };

      const response = await addOrUpdateProfileDataHelper(payloadForCF);
      if (!response || !response.success) {
        handleCloudErrorMsgAndLogging(response, payloadForCF, userPref, true);
      } else {
        return true;
      }
    } catch (error) {
      captureError(error);
    }

    return false;
  };

/**
 * Function to update totalSeats in an organisation
 * @param {Number} seatsToAdd - ADDITIONAL seats needed for organisation
 * @param {String} planId - pass only when plan needs to be changed from PRO -> PLUS or PLUS -> PRO
 * @returns true if update successful, false otherwise
 */
const updateOrganisationSubscription =
  (seatsToAdd = 0, planId = '') =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {
          userPref,
          user: {uid},
        },
        organisation: {isUserOrganisationOwner, orgSubscriptions, membersList},
      } = getState();

      if (
        !isUserOrganisationOwner &&
        !membersList[uid]?.access_level?.includes(
          ORGANISATION_ACCESS_LEVEL.ADMIN,
        ) &&
        !membersList[uid]?.access_level?.includes(
          ORGANISATION_ACCESS_LEVEL.SUBSCRIPTION,
        )
      ) {
        return false;
      }

      const {
        subscriptionId,
        plan: {id},
        totalSeats,
      } = orgSubscriptions;

      let totalSeatsForOrganisation = Number(seatsToAdd);

      // If plan changed (planId not empty), then use the passed seatsToAdd
      // else if only seats increased, then use totalSeats + seatsToAdd
      if (isEmpty(planId)) totalSeatsForOrganisation += Number(totalSeats);

      const payloadForCF = {
        subscriptionId: subscriptionId,
        itemId: planId || id,
        qty: totalSeatsForOrganisation,
      };

      const response = await updateOrganisationSubscriptionCF(payloadForCF);
      if (!response || !response.success) {
        handleCloudErrorMsgAndLogging(response, payloadForCF, userPref, true);
      } else {
        return true;
      }
    } catch (error) {
      captureError(error);
    }

    return false;
  };

/**
 * Function to cancel organisation subscription
 * @returns
 */
const cancelOrganisationSubscription = () => async (dispatch, getState) => {
  try {
    const {
      auth: {
        userPref,
        user: {uid},
      },
      organisation: {isUserOrganisationOwner, orgSubscriptions, membersList},
    } = getState();
    if (
      !isUserOrganisationOwner &&
      !membersList[uid]?.access_level?.includes(
        ORGANISATION_ACCESS_LEVEL.ADMIN,
      ) &&
      !membersList[uid]?.access_level?.includes(
        ORGANISATION_ACCESS_LEVEL.SUBSCRIPTION,
      )
    ) {
      return false;
    }

    const {subscriptionId} = orgSubscriptions;

    const payloadForCF = {
      subscriptionId: subscriptionId,
    };

    const response = await cancelOrganisationSubscriptionCF(payloadForCF);
    if (!response || !response.success) {
      handleCloudErrorMsgAndLogging(response, payloadForCF, userPref, true);
    } else {
      return true;
    }
  } catch (error) {
    captureError(error);
  }
  return false;
};

const hideSubscriptionBanner = () => (dispatch) => {
  dispatch({type: ORGANISATION_ACTION.HIDE_SUBSCRIPTION_BANNER});
};

const organisationTeamUpdate = (data) => async (dispatch, getState) => {
  try {
    const {
      auth: {
        user: {uid},
      },
      organisation: {activeOrganisationId, teams},
    } = getState();
    let objToSend = {};
    const {type, payload} = data;
    const {teamName, allowedApps, id, removedApps, allApps} = payload;

    if (type === 'ADD') {
      const idToSend = `${uid}_${generateRandomId(10, 'organistaionId')}`;
      objToSend = {
        type: 'teamCreation',
        payload: {
          id: idToSend,
          teamName,
          allowedApps,
          removedApps: [],
          sharedWithUsers: [],
          orgId: activeOrganisationId,
          createdByUid: uid,
        },
      };
      const response = await addOrUpdateProfileDataHelper(objToSend);

      if (response?.success) {
        const obj = Object.assign({}, teams);
        obj[idToSend] = {
          id: idToSend,
          teamName,
          allowedApps,
          removedApps: [],
          orgId: activeOrganisationId,
          createdByUid: uid,
          allApps,
          sharedWithUsers: [],
        };
        dispatch({
          type: ORGANISATION_ACTION.ADD_OR_EDIT_TEAM,
          payload: obj,
        });
        return {
          success: true,
          message: 'Team Created Successfully',
        };
      } else {
        return {
          success: false,
          message: 'Team not Created , Something went wrong',
        };
      }
    } else if (type === 'EDIT') {
      objToSend = {
        type: 'teamEdit',
        payload: {
          id,
          teamName,
          allowedApps,
          allApps,
          removedApps,
          orgId: teams[id]?.orgId,
          createdByUid: teams[id]?.createdByUid,
        },
      };
      const response = await addOrUpdateProfileDataHelper(objToSend);
      if (response?.success) {
        const obj = Object.assign({}, teams);
        obj[id] = {
          id,
          teamName,
          allowedApps: allApps,
          removedApps: [],
          orgId: obj[id]?.orgId,
          createdByUid: obj[id]?.createdByUid,
          allApps,
          sharedWithUsers: obj[id]?.sharedWithUsers,
        };
        dispatch({
          type: ORGANISATION_ACTION.ADD_OR_EDIT_TEAM,
          payload: obj,
        });
        return {
          success: true,
          message: 'Team Updated Successfully',
        };
      } else {
        return {
          success: false,
          message: 'Team not Updated , Something went wrong',
        };
      }
    } else if (type === 'DELETE') {
      objToSend = {
        type: 'teamDelete',
        payload: {
          id,
          orgId: activeOrganisationId,
        },
      };
      const response = await addOrUpdateProfileDataHelper(objToSend);
      const obj = omit(teams, id);
      if (response?.success) {
        dispatch({
          type: ORGANISATION_ACTION.ADD_OR_EDIT_TEAM,
          payload: obj,
        });
        return {
          success: true,
          message: 'Team Deleted Successfully',
        };
      } else {
        return {
          success: false,
          message: 'Team not Deleted , Something went wrong',
        };
      }
    }
  } catch (error) {
    captureError(error);
    return {
      success: false,
      message: 'Something went wrong',
    };
  }
};

const getTeamData =
  (data = {}) =>
  async (dispatch, getState) => {
    try {
      const {orgId} = data;
      const {
        auth: {user},
        organisation: {
          activeOrganisationId,
          isUserOrganisationOwner,
          membersList,
        },
      } = getState();
      const orgIdToSend = orgId || activeOrganisationId;
      const userAcessLevel = membersList?.[user?.uid]?.access_level || [];
      const allowedPermission = [
        ORGANISATION_ACCESS_LEVEL.ADMIN,
        ORGANISATION_ACCESS_LEVEL.TEAM_MANAGEMENT,
      ];
      const canAccessTeamData = () => {
        return (
          isUserOrganisationOwner ||
          (Array.isArray(userAcessLevel) &&
            userAcessLevel.some((element) =>
              allowedPermission.includes(element),
            ))
        );
      };

      if (orgIdToSend && orgIdToSend !== '') {
        if (!canAccessTeamData()) {
          return;
        }
        const tempDoc = {};
        const allTeams = await FirestoreDB.organisations.teams(orgIdToSend);
        allTeams
          .get()
          .then((querySnapshot) => {
            querySnapshot.docs.forEach((doc) => {
              tempDoc[doc.id] = {id: doc.id, ...doc.data()};
            });
            dispatch({
              type: ORGANISATION_ACTION.FETCH_ALL_TEAMS,
              payload: tempDoc,
            });
          })
          .catch((err) => console.log(err));
      }
    } catch (error) {
      captureError(error);
    }
  };

const getAllAppsInOrganisation =
  (data = {}) =>
  async (dispatch, getState) => {
    try {
      const {
        organisation: {activeOrganisationId},
        auth: {
          user: {uid},
        },
      } = getState();
      const {orgId} = data;
      const orgIdToSend = orgId || activeOrganisationId;
      if (orgIdToSend && orgIdToSend !== '') {
        const response = await getAllAppsInOrganisationHelper({
          organisationId: orgIdToSend,
          uid,
        });

        const appObj = {};
        response?.miniApps?.length &&
          Array.isArray(response?.miniApps) &&
          response?.miniApps?.forEach((app) => {
            appObj[app?.miniAppId] = app;
          });
        dispatch({
          type: ORGANISATION_ACTION.FETCH_ALL_ORGANISATION_APPS,
          payload: appObj,
        });
        return {success: true, message: 'Apps fetched successfully'};
      }
    } catch (error) {
      captureError(error);
      return {success: false, message: 'Something went wrong'};
    }
  };

const setOrganisationalLanguages =
  ({orgLanguagePreferences, organisationId}) =>
  async () => {
    try {
      await FirestoreDB.organisations
        .doc(organisationId)
        .set({orgLanguagePreferences}, {merge: true});
    } catch (error) {
      captureError(error);
      return {success: false, message: 'Something went wrong'};
    }
  };

export {
  organisationTeamUpdate,
  activateOrganisationListener,
  addOrUpdateProfileData,
  addTeamMember,
  cancelOrganisationSubscription,
  checkUserPlanForAccess,
  getUserOrganisationInfo,
  hideSubscriptionBanner,
  removeTeamMember,
  updateMemberAccess,
  updateMemberInfo,
  updateOrganisationCustomFields,
  updateOrganisationSubscription,
  getTeamData,
  getAllAppsInOrganisation,
  setOrganisationalLanguages,
};
