import {isEmpty, isEqual} from 'lodash';
import {SHARE_PERMISSION_TYPE, TABLE_VIEW_MODES} from '../utils/constant';
import {TABLE_ACTION, TABLE_VIEW_ACTIONS} from './actionType';
import {captureError} from '../imports';
import {restrictHeaderData} from '../utils/restrictionUtils';

const updateTableViewModes = (obj) => (dispatch) => {
  dispatch({
    type: TABLE_VIEW_ACTIONS.UPDATE_VIEW_MODES,
    payload: obj,
  });
};

const resetTableViewModes = () => (dispatch) => {
  dispatch({type: TABLE_VIEW_ACTIONS.RESET_VIEW_MODES});
};

const manageTableViewModesFromMeta =
  (documentMeta, options = {}) =>
  (dispatch) => {
    try {
      const {isPreview = false, isVersionPreview = false} = options ?? {};
      const {isDocumentLock, isGlobalDocumentLock, collab} = documentMeta;

      const isShared = !isEmpty(collab);

      // prettier-ignore
      const isDocLock = isShared &&
            (collab.isOwner || [SHARE_PERMISSION_TYPE.ADMIN, SHARE_PERMISSION_TYPE.OWNER].includes(collab.permission))
            ? isGlobalDocumentLock
              ? true
              : false
            : isDocumentLock || isGlobalDocumentLock
            ? true
            : false;

      const updatedObj = {
        [TABLE_VIEW_MODES.PREVIEW]: Boolean(isPreview),
        [TABLE_VIEW_MODES.VERSION_PREVIEW]: Boolean(isVersionPreview),
        [TABLE_VIEW_MODES.DOCUMENT_LOCK]: Boolean(isDocLock),
        [TABLE_VIEW_MODES.COLLAB_CAN_VIEW]:
          isShared && collab.permission === SHARE_PERMISSION_TYPE.CAN_VIEW
            ? true
            : false,
        [TABLE_VIEW_MODES.CUSTOM_SHARED]:
          isShared && collab.permission === SHARE_PERMISSION_TYPE.CUSTOM
            ? true
            : false,
      };

      dispatch(updateTableViewModes(updatedObj));
    } catch (error) {
      captureError(error);
    }
  };

const tableHeaderDataFilterRestricted =
  (updatedMeta) => (dispatch, getState) => {
    try {
      const {
        table: {originalHeaderData},
        home: {activeDocumentMeta},
      } = getState();

      if (isEmpty(updatedMeta)) {
        return;
      }

      if (
        !isEqual(updatedMeta?.collab, activeDocumentMeta?.collab) &&
        originalHeaderData?.length
      ) {
        /**
         * Filter Restricted Columns when CUSTOM shared Document
         */
        const finalHeaderData = restrictHeaderData(
          updatedMeta,
          originalHeaderData,
        );
        dispatch({
          type: TABLE_ACTION.UPDATE_COLLABORATIVE_TABLE_DATA,
          payload: {headerData: finalHeaderData, originalHeaderData},
        });
      }
    } catch (error) {
      captureError(error);
    }
  };
export {
  updateTableViewModes,
  resetTableViewModes,
  manageTableViewModesFromMeta,
  tableHeaderDataFilterRestricted,
};
