export const ACTIVITY_EVENT_TYPE = {
  ALL: {
    text: 'All',
  },
  FILE: {text: 'File', code: 'FILE', iconName: 'file', color: '#337EED'},
  TEAMS: {
    text: 'Teams',
    code: 'TEAMS',
    iconName: 'account-group',
    color: '#08C9E5',
  },
  DASHBOARD: {
    text: 'Dashboards',
    code: 'DASHBOARD',
    iconName: 'view-dashboard',
    color: '#EE558C',
  },
  LOGIN: {
    text: 'Login Activity',
    code: 'LOGIN',
    iconName: 'login-variant',
    color: '#F1A42F',
  },
  ACCOUNT_SETTINGS: {
    text: 'Account Settings',
    code: 'ACCOUNT_SETTINGS',
    iconName: 'cog',
    color: '#556A89',
  },
  AUTOMATION: {
    text: 'Automation',
    code: 'AUTOMATION',
    iconName: 'lightning-bolt',
    color: '#791BEE',
  },
};

export const ACCOUNT_ACTIVITIES = {
  CREATE_DAILY_AUTOMATION_SUCCESS: {
    text: 'Daily automation added',
    type: ACTIVITY_EVENT_TYPE.AUTOMATION.code,
  },
  FILE_UNSHARED_WITH_TEAM: {
    text: 'File unshared with team',
    type: ACTIVITY_EVENT_TYPE.TEAMS.code,
  },
  PROFILE_EDITED: {
    text: 'Profile edited',
    type: ACTIVITY_EVENT_TYPE.ACCOUNT_SETTINGS.code,
  },
  LANGUAGE_CHANGED: {
    text: 'App Language Changed',
    type: ACTIVITY_EVENT_TYPE.ACCOUNT_SETTINGS.code,
  },
  APP_LOCKED_PHONE_AUTH: {
    text: 'App lock set',
    type: ACTIVITY_EVENT_TYPE.ACCOUNT_SETTINGS.code,
  },
  APP_UNLOCKED_PHONE_AUTH: {
    text: 'App lock removed',
    type: ACTIVITY_EVENT_TYPE.ACCOUNT_SETTINGS.code,
  },
  APP_LOCKED_CUSTOM: {
    text: 'Custom App Lock set',
    type: ACTIVITY_EVENT_TYPE.ACCOUNT_SETTINGS.code,
  },
  APP_UNLOCKED_CUSTOM: {
    text: 'Custom app lock removed',
    type: ACTIVITY_EVENT_TYPE.ACCOUNT_SETTINGS.code,
  },
  ACCOUNT_LINKED_SUCESSFULLY: {
    text: 'Linked account',
    type: ACTIVITY_EVENT_TYPE.ACCOUNT_SETTINGS.code,
  },
  PROFILE_PIC_ADDED: {
    text: 'Profile Picture Added',
    type: ACTIVITY_EVENT_TYPE.ACCOUNT_SETTINGS.code,
  },
  ACCOUNT_RESTORE_SUCCESS: {
    text: 'Account was restored',
    type: ACTIVITY_EVENT_TYPE.ACCOUNT_SETTINGS.code,
    isDetails: true,
  },
  ACCOUNT_DELETE_CONFIRM: {
    text: 'Account was deactivated',
    type: ACTIVITY_EVENT_TYPE.ACCOUNT_SETTINGS.code,
    isDetails: true,
  },
  LOGIN_SUCCESS: {
    text: 'New Login',
    type: ACTIVITY_EVENT_TYPE.LOGIN.code,
    isDetails: true,
  },
  TEAM_DELETE_SUCCESS: {
    text: 'Team deleted',
    type: ACTIVITY_EVENT_TYPE.TEAMS.code,
  },
  TEAM_CREATE_SUCCESS: {
    text: 'New Team Created',
    type: ACTIVITY_EVENT_TYPE.TEAMS.code,
  },
  TEAM_ADD_MEMBERS: {
    text: 'Members added to Team',
    type: ACTIVITY_EVENT_TYPE.TEAMS.code,
  },
  EDIT_TEAM: {text: 'Team Edited', type: ACTIVITY_EVENT_TYPE.TEAMS.code},
  SHARE_WITH_TEAM_SUCCESS: {
    text: 'File Shared with team',
    type: ACTIVITY_EVENT_TYPE.TEAMS.code,
  },
  TEAM_UPDATE_SUCCESS: {
    text: 'Team Edited',
    type: ACTIVITY_EVENT_TYPE.TEAMS.code,
  },
  TEAM_CHANGE_COLOR: {
    text: 'Team Edited',
    type: ACTIVITY_EVENT_TYPE.TEAMS.code,
  },
  DUPLICATE_FILE: {
    text: 'File Duplicated',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
  },
  FILE_PERMANENTLY_DELTED: {
    text: 'File Permanently Deleted',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
  },
  FILE_RESTORE: {
    text: 'File Restored from Trash',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
  },
  FILE_ADDED_IN_WIDGET: {
    text: 'File Added to Shortcut Widget',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
  },
};

export const DOCUMENT_ACTIVITIES = {
  NEW_PAGE_ADDED: {
    text: 'New Page Added to document',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
  },
  FILE_SHARED_TO_TEAM: {
    text: 'File Shared to team',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
  },
  RESTORE_VERSION_SUCCESS: {
    text: 'File restored to previous version',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
  },
  CREATE_AUTOMATION_SUCCESS: {
    text: 'New Automation added',
    type: ACTIVITY_EVENT_TYPE.AUTOMATION.code,
  },
  UPDATE_AUTOMATION_SUCCESS: {
    text: 'Automation modified',
    type: ACTIVITY_EVENT_TYPE.AUTOMATION.code,
  },
  DELETE_AUTOMATION_SUCCESS: {
    text: 'Automation deleted',
    type: ACTIVITY_EVENT_TYPE.AUTOMATION.code,
  },
  CREATE_DASHBOARD_SUCCESS: {
    text: 'New Dasboard created',
    type: ACTIVITY_EVENT_TYPE.DASHBOARD.code,
  },
  DELETE_DASHBOARD: {
    text: ' Dasboard deleted',
    type: ACTIVITY_EVENT_TYPE.DASHBOARD.code,
  },
  PAGES_REMOVED: {
    text: 'Page disabled from document',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
  },
  PAGE_ENABLED_EMPTY: {
    text: 'Page enabled for document',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
  },
  PAGES_RESTORED: {
    text: 'Pages restored',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
  },
  RENAME_FILE: {
    text: 'Page removed from document',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
  },
  COLUMN_RENAME: {
    text: 'Column Renamed',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
    isDetails: true,
  },
  COLUMN_DELETE: {
    text: 'Column Deleted',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
    isDetails: true,
  },
  CHANGE_COLUMN_TYPE: {
    text: 'Column Type changes',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
    isDetails: true,
  },
  MOVE_COL_FINISH: {
    text: 'Column Moved',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
  },
  COL_ADDED: {
    text: 'New Column added',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
    isDetails: true,
  },
  MOVE_ROW: {
    text: 'Row moved',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
  },
  ROW_DELETE: {
    text: 'Row Deleted',
    type: ACTIVITY_EVENT_TYPE.FILE.code,
  },
};

export const FETCH_DOCS_LIMIT = 4;

export const BATCH_UPLOAD_LIMIT = 10; // set oto 10 later

export const detailsKeys = {
  [ACTIVITY_EVENT_TYPE.FILE.code]: {
    colName: 'Column Name',
    prevColName: 'Prev Column Name',
  },
  [ACTIVITY_EVENT_TYPE.LOGIN.code]: {
    device: 'Device',
    appVersion: 'Version',
    lang: 'Language',
    location: 'Location',
  },
};
