import {isEmpty, forEach, isArray} from 'lodash';
import {MARGIN, BORDER, COLOR} from './designValues';
import {FIELD_TYPE_ID, EXTERNAL_LINKS, PROFILE_URL, LIO_LOGO} from './constant';
import {formatFooterAsHeader} from './formatData';
import moment from 'moment';
import {
  checkCellContainsAudio,
  getLabelValueArray,
  getLocalText,
  getPrintAudio,
  getSelectBoxCell,
} from './utils';

const MIN_COL_WIDTH = 200;

const isProduction = false;

const getHtmlString = (obj) => {
  let str = '';
  forEach(obj, (value, key) => {
    if (key === 'width') {
      str += `width:${value}px;`;
    } else {
      str += `${key}:${value};`;
    }
  });
  return str;
};

const waterMark = {
  EN: 'Powered by:',
  HN: 'द्वारा संचालित:',
  MA: 'द्वारे समर्थित:',
  BE: 'দ্বারা চালিত:',
  TE: 'వీరిచే ఆధారితం:',
  TA: 'இயக்கப்படுகிறது:',
  GU: 'દ્વારા સંચાલિત:',
  KN: 'ಇವರಿಂದ ನಡೆಸಲ್ಪಡುತ್ತಿದೆ:',
  ML: 'പ്രായോജകർ:',
  UR: 'تقویت یافتہ:',
  ID: 'Dipersembahkan oleh:',
  PT: 'Distribuído por:',
  VN: 'Được cung cấp bởi:',
};
const nameLang = {
  EN: 'Name',
  HN: 'नाम',
  MA: 'नाव',
  BE: 'নাম',
  TE: 'పేరు',
  TA: 'பெயர்',
  GU: 'નામ',
  KN: 'ಹೆಸರು',
  ML: 'പേര്',
  UR: 'نام',
  ID: 'Nama',
  PT: 'Nome',
  VN: 'Tên',
};

const addressLang = {
  EN: 'Address',
  HN: 'पता',
  MA: 'पत्ता',
  BE: 'ঠিকানা',
  TE: 'చిరునామా',
  TA: 'முகவரி',
  GU: 'સરનામું',
  KN: 'ವಿಳಾಸ',
  ML: 'വിലാസം',
  UR: 'پتہ',
  ID: 'Alamat',
  PT: 'Endereço',
  VN: 'Địa chỉ nhà',
};

const clientNameLang = {
  EN: 'Client Name',
  HN: 'ग्राहक नाम',
  MA: 'ग्राहकांचे नाव',
  BE: 'গ্রাহকের নাম',
  TE: 'క్లయింట్ పేరు',
  TA: 'நுகர்வி பெயர்',
  GU: 'ગ્રાહકનું નામ',
  KN: 'ಕಕ್ಷಿದಾರನ ಹೆಸರು',
  ML: 'ഇടപാടുകാരന്റെ പേര്',
  UR: 'مؤکل کا نام',
};

const clientAddressLang = {
  EN: 'Client Address',
  HN: 'ग्राहक का पता',
  MA: 'ग्राहकाचा पत्ता',
  BE: 'ক্লায়েন্ট ঠিকানা',
  TE: 'క్లయింట్ చిరునామా',
  TA: 'வாடிக்கையாளர் முகவரி',
  GU: 'ગ્રાહકનું સરનામું',
  KN: 'ಗ್ರಾಹಕ ವಿಳಾಸ',
  ML: 'ക്ലയൻറ് വിലാസം',
  UR: 'مؤکل کا پتہ',
};
const appHyperlink = ` <a href="${EXTERNAL_LINKS.PLAYSTORE_LINK}">Lio</a> `;

const GetWebHtml = async (
  {
    headerData,
    tableData,
    footerData,
    fileObj,
    metaData,
    name,
    address,
    header,
    footer,
    base64ImageData,
    image,
    profilePicture,
    clientName,
    clientAddress,
    allColumnInOnePage,
    isPremiumUser,
  },
  {yesText, noText},
  {
    getPrintDate,
    formatCurrency,
    formatDateDiff,
    formatTimeDiff,
    getPrintTime,
    getPrintAttachment,
    userPref,
    userCountry,
    formatUnit,
    getAssignTaskAsPlainText,
    getUrlAsPlainText,
  },
) => {
  const justify = 'text-align:center;';
  const defaultWidth = MIN_COL_WIDTH;
  const commonStyle = `padding:5px 17px;width:${defaultWidth}px;word-wrap: break-word;font-size:12pt;`;

  const headerStyle =
    commonStyle +
    `border: 1px solid ${COLOR.BORDER_DARK_RGB};background-color:${COLOR.BORDER_RGB};` +
    justify;
  let tableHeading = '';
  const cellStyle = commonStyle + `border: 1px solid ${COLOR.BORDER_RGB};`;

  const labelStyle = 'display:flex;';

  const footerStyle =
    commonStyle +
    `border: 1px solid ${COLOR.BORDER_RGB};background-color:${COLOR.BORDER_LIGHT};${justify}`;

  const cellNumberStyle = cellStyle + justify;

  const numberStyle =
    `padding:5px 17px;min-width:30px;word-wrap:break-word;font-size:12pt;border: 1px solid ${COLOR.BORDER_DARK_RGB};background-color:${COLOR.BORDER_RGB};` +
    justify;

  let headerRow = '';
  let totalWidth = 0;
  const pageWidth = allColumnInOnePage === true ? 10000 : 1000;
  let breakColumn = 0;
  let breakRow = 0;
  const totalColumn = headerData.length;
  let currentEXEWidth = 0;
  do {
    headerRow +=
      '<table style="border-collapse: collapse;margin:15px;" cellpadding="0" cellspacing="0">';
    headerRow += `<tr><td style="${numberStyle}"><td>`;
    for (let i = breakColumn; i < headerData.length; i++) {
      let headerStyleFinal = headerStyle;
      if (!isEmpty(headerData[i]) && !isEmpty(headerData[i].styleObj)) {
        if (
          !isEmpty(headerData[i].styleObj) &&
          !isEmpty(headerData[i].styleObj.style) &&
          !isEmpty(headerData[i].styleObj.style.htmlObj) &&
          !isEmpty(headerData[i].styleObj.style.htmlObj.header)
        ) {
          if (headerData[i].styleObj.style.htmlObj.header.width) {
            totalWidth += headerData[i].styleObj.style.htmlObj.header.width;
            currentEXEWidth +=
              headerData[i].styleObj.style.htmlObj.header.width;
          } else {
            totalWidth += defaultWidth;
            currentEXEWidth += defaultWidth;
          }
          const htmlStr = getHtmlString(
            headerData[i].styleObj.style.htmlObj.header,
          );
          headerStyleFinal += htmlStr;
        }
      } else {
        totalWidth += defaultWidth;
        currentEXEWidth += defaultWidth;
      }

      headerRow += `<td style="${headerStyleFinal}">${headerData[i].val}</td>`;
      if (currentEXEWidth >= pageWidth || i >= headerData.length - 1) {
        currentEXEWidth = 0;
        breakColumn = i + 1;
        break;
      }
    }
    headerRow += '</tr>';
    let emptyArray = [];

    for (let i = 0; i < tableData.length; i++) {
      const rowObj = tableData[i];
      let row = `<tr><td style="${numberStyle}">${i + 1}<td>`;
      let isEmptyRow = true;
      for (let j = breakRow; j < breakColumn; j++) {
        const labelStyleFinal =
          headerData[j].fieldType === FIELD_TYPE_ID.LABEL ? labelStyle : '';
        const cellStyleFinal =
          headerData[j].fieldType === FIELD_TYPE_ID.TEXT
            ? cellStyle
            : cellNumberStyle;
        let rowValue =
          rowObj[headerData[j].id] && rowObj[headerData[j].id].val != null
            ? rowObj[headerData[j].id].val
            : '';

        let cellColorStyle = '';
        // Show cell colors as red and green if column properties are set
        if (
          [
            FIELD_TYPE_ID.NUMBER,
            FIELD_TYPE_ID.RUPEE,
            FIELD_TYPE_ID.FORMULA,
          ].includes(headerData[j].fieldType)
        ) {
          if (rowValue !== '') {
            if (
              rowValue < 0 &&
              headerData[j]?.columnProperties?.NEGATIVE_VALUE_RED
            ) {
              cellColorStyle += `background-color:${COLOR.DELETE};`;
            } else if (
              rowValue >= 0 &&
              headerData[j]?.columnProperties?.POSITIVE_VALUE_GREEN
            ) {
              cellColorStyle += `background-color:${COLOR.POSITIVE};`;
            }
          }
        }

        if (
          headerData[j].fieldType === FIELD_TYPE_ID.TEXT &&
          rowValue &&
          (typeof rowValue === 'object' || typeof rowValue === 'undefined')
        ) {
          rowValue = '';
        }

        if (
          [FIELD_TYPE_ID.TEXT, FIELD_TYPE_ID.BARCODE].includes(
            headerData[j].fieldType,
          ) &&
          rowValue &&
          (typeof rowValue === 'object' || typeof rowValue === 'undefined')
        ) {
          rowValue = '';
        }

        if (!isEmpty(headerData[j]) && !isEmpty(headerData[j].styleObj)) {
          if (
            (headerData[j].fieldType === FIELD_TYPE_ID.NUMBER ||
              headerData[j].fieldType === FIELD_TYPE_ID.RUPEE) &&
            !isEmpty(headerData[j].styleObj.postiveStyle) &&
            !isEmpty(headerData[j].styleObj.negativeStyle)
          ) {
            if (rowValue !== '') {
              if (rowValue < 0) {
                cellColorStyle += headerData[j].styleObj.negativeStyle.html;
              } else if (rowValue >= 0) {
                cellColorStyle += headerData[j].styleObj.postiveStyle.html;
              }
            }
          }

          if (
            !isEmpty(headerData[j].styleObj) &&
            !isEmpty(headerData[j].styleObj.style)
          ) {
            if (headerData[j].styleObj.style.html) {
              cellColorStyle += headerData[j].styleObj.style.html;
            }

            if (
              !isEmpty(headerData[j]) &&
              !isEmpty(headerData[j].styleObj) &&
              !isEmpty(headerData[j].styleObj.style) &&
              !isEmpty(headerData[j].styleObj.style.htmlObj)
            ) {
              if (!isEmpty(headerData[j].styleObj.style.htmlObj.cell)) {
                const htmlStr = getHtmlString(
                  headerData[j].styleObj.style.htmlObj.cell,
                );
                cellColorStyle += htmlStr;
              }

              if (!isEmpty(headerData[j].styleObj.style.htmlObj.text)) {
                const htmlStr = getHtmlString(
                  headerData[j].styleObj.style.htmlObj.text,
                );
                cellColorStyle += htmlStr;
              }
            }
          }
        }

        if (
          !isEmpty(rowObj) &&
          !isEmpty(rowObj[headerData[j].id]) &&
          !isEmpty(rowObj[headerData[j].id].styleObj) &&
          !isEmpty(rowObj[headerData[j].id].styleObj.style) &&
          !isEmpty(rowObj[headerData[j].id].styleObj.style.htmlObj)
        ) {
          if (!isEmpty(rowObj[headerData[j].id].styleObj.style.htmlObj.text)) {
            const htmlStr = getHtmlString(
              rowObj[headerData[j].id].styleObj.style.htmlObj.text,
            );
            cellColorStyle += htmlStr;
          }

          if (!isEmpty(rowObj[headerData[j].id].styleObj.style.htmlObj.cell)) {
            const htmlStr = getHtmlString(
              rowObj[headerData[j].id].styleObj.style.htmlObj.cell,
            );
            cellColorStyle += htmlStr;
          }
        }

        // if (
        //   rowValue !== '' &&
        //   headerData[j].fieldType === FIELD_TYPE_ID.PERCENT
        // ) {
        //   rowValue = `${rowValue} %`;
        // } else
        if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.RUPEE
        ) {
          rowValue = formatCurrency(rowValue, userCountry, headerData[j]);
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.DATE
        ) {
          rowValue = getPrintDate(rowValue);
        } else if (
          headerData[j].fieldType === FIELD_TYPE_ID.MAPS &&
          !isEmpty(rowValue) &&
          !isEmpty(rowObj[headerData[j].id].coordinate)
        ) {
          const coordinate = rowObj[headerData[j].id].coordinate;
          const latLng = `${coordinate.latitude},${coordinate.longitude}`;
          const webURL = 'http://maps.google.com/?daddr=' + latLng;
          rowValue = `<a href="${webURL}">${rowValue}</a>`;
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.TIME
        ) {
          rowValue = getPrintTime(rowValue);
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.CONTACT &&
          rowObj[headerData[j].id].contactName
        ) {
          rowValue += '<br>';
          rowValue += `(${rowObj[headerData[j].id].contactName})`;
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.UNIT
        ) {
          rowValue = formatUnit(
            rowValue,
            fileObj[headerData[j].id],
            userCountry,
          );
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.IMAGE
        ) {
          let imgData = '';
          const imageObjData = base64ImageData;
          if (isArray(rowObj[headerData[j].id].val))
            rowObj[headerData[j].id].val.forEach((item) => {
              imgData += `<img src="${item.uri}" alt="Image" style="padding:10px;object-fit:contain" width="200" height="200">`;
            });
          rowValue = imgData;
        } else if (
          rowValue !== '' &&
          (headerData[j].fieldType === FIELD_TYPE_ID.SWITCH ||
            headerData[j].fieldType === FIELD_TYPE_ID.CHECKBOX)
        ) {
          rowValue = rowValue === true ? yesText : noText;
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.FORMULA
        ) {
          if (headerData[j].subType === FIELD_TYPE_ID.RUPEE) {
            rowValue = formatCurrency(rowValue, userCountry, headerData[j]);
          } else if (headerData[j].subType === FIELD_TYPE_ID.DATE) {
            rowValue = formatDateDiff(rowValue, userPref);
          } else if (headerData[j].subType === FIELD_TYPE_ID.TIME) {
            rowValue = formatTimeDiff(rowValue, userPref);
          } else if (headerData[j].subType === FIELD_TYPE_ID.UNIT) {
            rowValue = formatUnit(
              rowValue,
              headerData[j].unitDependentColumn
                ? fileObj[headerData[j].unitDependentColumn]
                : {},
              userCountry,
            );
          }
        } else if (
          headerData[j].fieldType === FIELD_TYPE_ID.SELECT_POP_UP &&
          !isEmpty(rowObj[headerData[j].id])
        ) {
          const thisStyle = {
            ...(rowObj[headerData[j].id].valStyleObj
              ? {
                  background:
                    rowObj[headerData[j].id].valStyleObj.backgroundColor,
                  color: rowObj[headerData[j].id].valStyleObj.color,
                }
              : {background: COLOR.WHITE}),
          };
          const clearPad =
            'padding:0px !important; background:red; margin:0px !important;padding-left: 10px !important;padding-top: 0px !important;padding-bottom: 0px !important;padding-right: 10px !important;margin-left: 0px !important;margin-top: 0px !important;margin-bottom: 0px !important;margin-right: 0px !important;' +
            Object.keys(thisStyle)
              .map((item) => item + ':' + thisStyle[item] + ';')
              .join('');
          let lang = 'EN';
          if (!isEmpty(userPref) && userPref.lang) {
            lang = userPref.lang;
          }
          rowValue = getSelectBoxCell(
            rowValue,
            rowObj[headerData[j].id],
            headerData[j],
            lang,
            true,
          );

          if (rowObj[headerData[j].id].htmlStyle) {
            cellColorStyle += rowObj[headerData[j].id].htmlStyle;
          }
          cellColorStyle += clearPad;
        } else if (
          (headerData[j].fieldType === FIELD_TYPE_ID.LABEL &&
            !isEmpty(rowObj[headerData[j].id])) ||
          (headerData[j].fieldType === FIELD_TYPE_ID.TABLE &&
            headerData[j].subType === FIELD_TYPE_ID.LABEL)
        ) {
          rowValue = `<div style="display: flex; justify-content: center; padding: 10px; flex-wrap:wrap; ${
            'margin-bottom:' + MARGIN.SMALL.toString() + 'px'
          }">${getLabelValueArray(rowValue, headerData[j])
            .map((item) => {
              const thisStyle = {
                ...(item.valStyleObj
                  ? {
                      background: item.valStyleObj.backgroundColor,
                      color: item.valStyleObj.color,
                    }
                  : {background: COLOR.WHITE}),
                'box-shadow': '1px 1px 1.5px #ddd',
                padding: MARGIN.SMALL.toString() + 'px',
                'padding-left': MARGIN.MEDIUM.toString() + 'px',
                'padding-right': MARGIN.MEDIUM.toString() + 'px',
                'border-radius': BORDER.RADIUS_LARGE.toString() + 'px',
                'margin-bottom': MARGIN.SMALL.toString() + 'px',
                'margin-right': MARGIN.SMALL.toString() + 'px',
              };
              return `<div style="display:inline;${Object.keys(thisStyle)
                .map((item) => item + ':' + thisStyle[item] + ';')
                .join('')}">${item.val}</div>`;
            })
            .join('')}</div>`;

          if (rowObj[headerData[j].id]?.htmlStyle) {
            cellColorStyle += rowObj[headerData[j].id].htmlStyle;
          }
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.REMINDER
        ) {
          const unixTime = rowValue;
          rowValue = getPrintTime(unixTime);
          rowValue = rowValue + '<br>';
          rowValue = rowValue + getPrintDate(unixTime);
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.DOCUMENT
        ) {
          rowValue =
            isArray(rowValue) && rowValue.length > 0
              ? getPrintAttachment(userPref, rowValue.length)
              : '';
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.AUDIO
        ) {
          rowValue = checkCellContainsAudio(rowValue)
            ? getPrintAudio(userPref, rowValue.length)
            : '';
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.ASSIGN_TASK
        ) {
          rowValue = getAssignTaskAsPlainText(rowValue, userPref, true);
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.URL
        ) {
          rowValue = getUrlAsPlainText(rowObj[headerData[j].id], true);
        }

        if (rowValue !== '') {
          isEmptyRow = false;
        }
        if (headerData[j].fieldType === FIELD_TYPE_ID.CREATED_INFO) {
          rowValue =
            (rowObj?.rowProperties?.firstAddedByContactName || '') +
              '<br>' +
              moment(rowObj?.rowProperties?.firstAddedTimestamp).format(
                'MMMM Do YYYY, h:mm a',
              ) || '';
        }
        // TODO : Handle USER COLUMN
        if (headerData[j].fieldType === FIELD_TYPE_ID.USER_COLUMN) {
          rowValue =
            (rowObj?.rowProperties?.firstAddedByContactName || '') +
              '<br>' +
              moment(rowObj?.rowProperties?.firstAddedTimestamp).format(
                'MMMM Do YYYY, h:mm a',
              ) || '';
        }
        row += `<td style="${cellStyleFinal}${cellColorStyle}overflow: hidden;width:150px;word-break: break-all;">${rowValue}</td>`;
      }
      row += '</tr>';
      if (isEmptyRow) {
        emptyArray.push(row);
      } else {
        if (emptyArray.length > 0) {
          headerRow += emptyArray.join('');
          emptyArray = [];
        }
        headerRow += row;
      }
    }

    if (
      !isEmpty(metaData) &&
      !isEmpty(metaData.fileData) &&
      !isEmpty(metaData.fileData.footerAsHeader) &&
      footerData[metaData.fileData.footerAsHeader.colId]
    ) {
      let val = footerData[metaData.fileData.footerAsHeader.colId].val;
      val = formatFooterAsHeader(
        val,
        {
          ...metaData.fileData.footerAsHeader,
          id: metaData.fileData.footerAsHeader.colId,
        },
        fileObj,
        userCountry,
      );
      delete footerData[metaData.fileData.footerAsHeader.colId];
      let lang = 'EN';
      if (!isEmpty(userPref) && userPref.lang) {
        lang = userPref.lang;
      }
      const headerTextObj = metaData.fileData.footerAsHeader.headerText;
      const headingText =
        headerTextObj[lang] ||
        headerTextObj[Object.keys(headerTextObj)[0]] ||
        headerTextObj['EN'];
      tableHeading = `<tr style="background-color:rgb(224,231,236);">
        <td colspan="26" style="font-size:12pt;padding:5px 17px">
            <b style="margin:15px">${headingText}: ${val}</b>
          </td>
      </tr>`;
    }

    if (!isEmpty(footerData)) {
      let showFooter = false;
      let row = `<tr><td style="${numberStyle}"><td>`;
      for (let j = breakRow; j < breakColumn; j++) {
        let val =
          footerData[headerData[j].id] && footerData[headerData[j].id].val
            ? footerData[headerData[j].id].val
            : '';
        if (
          val !== '' &&
          (headerData[j].fieldType === FIELD_TYPE_ID.RUPEE ||
            (headerData[j].fieldType === FIELD_TYPE_ID.FORMULA &&
              headerData[j].subType === FIELD_TYPE_ID.RUPEE))
        ) {
          val = formatCurrency(val, userCountry, headerData[j]);
          showFooter = true;
        } else if (
          val !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.FORMULA &&
          (headerData[j].subType === FIELD_TYPE_ID.TIME ||
            headerData[j].subType === FIELD_TYPE_ID.DATE)
        ) {
          val = formatDateDiff(
            val,
            userPref,
            headerData[j].subType === FIELD_TYPE_ID.TIME,
            true,
          );
          showFooter = true;
        } else if (
          val !== '' &&
          (headerData[j].fieldType === FIELD_TYPE_ID.UNIT ||
            (headerData[j].fieldType === FIELD_TYPE_ID.FORMULA &&
              headerData[j].subType === FIELD_TYPE_ID.UNIT))
        ) {
          val = formatUnit(
            val,
            headerData[j].subType === FIELD_TYPE_ID.UNIT
              ? headerData[j].unitDependentColumn
                ? fileObj[headerData[j].unitDependentColumn]
                : {}
              : fileObj[headerData[j].id],
            userCountry,
          );
          showFooter = true;
        }
        let finalFooterStyle = footerStyle;
        if (
          !isEmpty(headerData[j]) &&
          !isEmpty(headerData[j].styleObj) &&
          !isEmpty(headerData[j].styleObj.style) &&
          !isEmpty(headerData[j].styleObj.style.htmlObj)
        ) {
          if (
            !isEmpty(headerData[j].styleObj.style.htmlObj.cell) &&
            headerData[j].styleObj.style.htmlObj.cell.width
          ) {
            finalFooterStyle += `width:${headerData[j].styleObj.style.htmlObj.cell.width}px;`;
          }
        }

        if (val !== '') {
          showFooter = true;
        }
        row += `<td style="${finalFooterStyle}">${val}</td>`;
      }
      if (showFooter) {
        headerRow += row;
      }
    }

    if (breakColumn < totalColumn) {
      headerRow += '</table><p style="page-break-after: always;"></p>';
    } else {
      headerRow += '</table>';
    }
    breakRow = breakColumn;
  } while (breakColumn < totalColumn);

  let lang = 'EN';
  if (!isEmpty(userPref) && userPref.lang) {
    lang = userPref.lang;
  }
  let nameHtml = '';
  let addressHtml = '';
  let nameAddressDiv = '';
  let clientNameHtml = '';
  let clientAddressHtml = '';
  const pdfHeaderDiv = await getPdfHeaderDiv(
    name,
    image,
    address,
    profilePicture,
    lang,
    isPremiumUser,
  );
  if (name) {
    nameHtml = `<span style="font-size:20pt;margin-bottom:5px;">${name}</span>`;
    addressHtml = `<span style="font-size:12pt;">${
      address ? address : ''
    }</span>`;
    nameAddressDiv =
      image !== '' && image !== null && image !== undefined
        ? `<div style="display:flex;flex:1;flex-direction:column;align-items:center;justify-content:center;margin-right:75px">
    ${nameHtml}
    ${addressHtml}
    </div>`
        : `<div style="display:flex;flex:1;flex-direction:column;align-items:center;justify-content:center">
    ${nameHtml}
    ${addressHtml}
    </div>`;
  }
  if (clientName) {
    clientNameHtml = `<div style="padding-left:15px;padding-right: 15px;"><p>${clientNameLang[lang]}: ${clientName}</p></div>`;
    clientAddressHtml = clientAddress
      ? `<div style="padding-left:15px;padding-right: 15px;"><p>${clientAddressLang[lang]}: ${clientAddress}</p></div>`
      : '';
  }
  const headerHtml = header
    ? `<div style="padding-left: 15px;padding-right: 15px;"><p>${header}</p></div>`
    : '';
  const footerHtml = footer
    ? `<div  style="padding-left: 15px;padding-right: 15px;padding-top:10px;"><p>${footer}</p></div>`
    : '';
  const imageHtml =
    image !== '' && image !== null && image !== undefined
      ? `<img src="${
          profilePicture ? profilePicture : PROFILE_URL.PROD
        }" alt="Image" style="object-fit:contain;border-radius:50%" width="75" height="75">`
      : '';

  return {
    html: `<!DOCTYPE html>
  <html>
  <meta charset="UTF-8" />
  <body>
  ${pdfHeaderDiv}
  ${clientNameHtml}
  ${clientAddressHtml}
  ${headerHtml}
  ${tableHeading}
    ${headerRow}
  ${footerHtml}
  </body>
  </html>`,
    totalWidth,
    allColumnInOnePage,
  };
};
const getHtmlBody = (docsData, functions, userData) => {
  const {
    formatCurrency,
    formatDateDiff,
    formatTimeDiff,
    getPrintDate,
    getPrintTime,
    getPrintAttachment,
    formatUnit,
    setLoaderText,
    getAssignTaskAsPlainText,
    getUrlAsPlainText,
  } = functions;
  const {
    yesText,
    noText,
    userCountry,
    userPref,
    metaData,
    header,
    footer,
    allColumnInOnePage,
  } = userData;

  const justify = 'text-align:center;';
  let defaultWidth = MIN_COL_WIDTH,
    htmlFullCode = '';
  let lang = 'EN';
  if (!isEmpty(userPref) && userPref.lang) {
    lang = userPref.lang;
  }

  const commonStyle = `padding:5px 17px;width:${defaultWidth}px;word-wrap: break-word;font-size:12pt;`;

  const headerStyle =
    commonStyle +
    `border: 1px solid ${COLOR.BORDER_DARK_RGB};background-color:${COLOR.BORDER_RGB};` +
    justify;
  let tableHeading = '';
  const cellStyle = commonStyle + `border: 1px solid ${COLOR.BORDER_RGB};`;

  const footerStyle =
    commonStyle +
    `border: 1px solid ${COLOR.BORDER_RGB};background-color:${COLOR.BORDER_LIGHT};${justify}`;

  const cellNumberStyle = cellStyle + justify;

  const numberStyle =
    `padding:5px 17px;min-width:30px;word-wrap:break-word;font-size:12pt;border: 1px solid ${COLOR.BORDER_DARK_RGB};background-color:${COLOR.BORDER_RGB};` +
    justify;

  let headerRow = '';
  let tableRow,
    totalWidthArray = [];
  let currentTableWidth = 0;
  const pageWidth = allColumnInOnePage === true ? 10000 : 1000;
  let breakColumn = 0;
  let breakRow = 0;
  for (let p = 0; p < docsData.length; p++) {
    const loaderText = `${getLocalText(userPref, 'Generating PDF for Page')} (${
      p + 1
    }/${docsData.length})`;
    if (setLoaderText) {
      setLoaderText(loaderText);
    }
    let totalWidth = 0;
    headerRow = '';
    currentTableWidth = 0;
    breakColumn = 0;
    breakRow = 0;
    const {headerData, tableData, footerData, base64ImageData, name, fileObj} =
      docsData[p];
    do {
      headerRow +=
        '<table style="border-collapse: collapse;margin:15px;" cellpadding="0" cellspacing="0">';
      headerRow += `<tr><td style="${numberStyle}"><td>`;
      for (let i = breakColumn; i < headerData.length; i++) {
        let headerStyleFinal = headerStyle;
        if (!isEmpty(headerData[i]) && !isEmpty(headerData[i].styleObj)) {
          if (
            !isEmpty(headerData[i].styleObj) &&
            !isEmpty(headerData[i].styleObj.style) &&
            !isEmpty(headerData[i].styleObj.style.htmlObj) &&
            !isEmpty(headerData[i].styleObj.style.htmlObj.header)
          ) {
            if (headerData[i].styleObj.style.htmlObj.header.width) {
              totalWidth += headerData[i].styleObj.style.htmlObj.header.width;
              currentTableWidth +=
                headerData[i].styleObj.style.htmlObj.header.width;
            } else {
              totalWidth += defaultWidth;
              currentTableWidth += defaultWidth;
            }
            const htmlStr = getHtmlString(
              headerData[i].styleObj.style.htmlObj.header,
            );
            headerStyleFinal += htmlStr;
          }
        } else {
          totalWidth += defaultWidth;
          currentTableWidth += defaultWidth;
        }

        headerRow += `<td style="${headerStyleFinal}">${headerData[i].val}</td>`;
        if (currentTableWidth >= pageWidth || i >= headerData.length - 1) {
          currentTableWidth = 0;
          breakColumn = i + 1;
          break;
        }
      }

      if (totalWidthArray.length > 0) {
        const newTotalWidth =
          totalWidth > totalWidthArray[0] ? totalWidth : totalWidthArray[0];
        if (newTotalWidth !== totalWidthArray[0]) {
          totalWidthArray.splice(0, 1, newTotalWidth);
        }
      } else {
        totalWidthArray.push(totalWidth);
      }

      headerRow += '</tr>';
      tableRow = '';
      let emptyArray = [];

      for (let i = 0; i < tableData.length; i++) {
        const rowObj = tableData[i];
        let row = `<tr><td style="${numberStyle}">${i + 1}<td>`;
        let isEmptyRow = true;
        for (let j = breakRow; j < breakColumn; j++) {
          const cellStyleFinal =
            headerData[j].fieldType === FIELD_TYPE_ID.TEXT
              ? cellStyle
              : cellNumberStyle;
          let rowValue =
            rowObj[headerData[j].id] && rowObj[headerData[j].id].val != null
              ? rowObj[headerData[j].id].val
              : '';

          let cellColorStyle = '';
          // Show cell colors as red and green if column properties are set
          if (
            [
              FIELD_TYPE_ID.NUMBER,
              FIELD_TYPE_ID.RUPEE,
              FIELD_TYPE_ID.FORMULA,
            ].includes(headerData[j].fieldType)
          ) {
            if (rowValue !== '') {
              if (
                rowValue < 0 &&
                headerData[j]?.columnProperties?.NEGATIVE_VALUE_RED
              ) {
                cellColorStyle += `background-color:${COLOR.DELETE};`;
              } else if (
                rowValue >= 0 &&
                headerData[j]?.columnProperties?.POSITIVE_VALUE_GREEN
              ) {
                cellColorStyle += `background-color:${COLOR.POSITIVE};`;
              }
            }
          }

          if (
            headerData[j].fieldType === FIELD_TYPE_ID.TEXT &&
            rowValue &&
            (typeof rowValue === 'object' || typeof rowValue === 'undefined')
          ) {
            rowValue = '';
          }

          if (
            [FIELD_TYPE_ID.TEXT, FIELD_TYPE_ID.BARCODE].includes(
              headerData[j].fieldType,
            ) &&
            rowValue &&
            (typeof rowValue === 'object' || typeof rowValue === 'undefined')
          ) {
            rowValue = '';
          }

          if (!isEmpty(headerData[j]) && !isEmpty(headerData[j].styleObj)) {
            if (
              (headerData[j].fieldType === FIELD_TYPE_ID.NUMBER ||
                headerData[j].fieldType === FIELD_TYPE_ID.RUPEE) &&
              !isEmpty(headerData[j].styleObj.postiveStyle) &&
              !isEmpty(headerData[j].styleObj.negativeStyle)
            ) {
              if (rowValue !== '') {
                if (rowValue < 0) {
                  cellColorStyle += headerData[j].styleObj.negativeStyle.html;
                } else if (rowValue >= 0) {
                  cellColorStyle += headerData[j].styleObj.postiveStyle.html;
                }
              }
            }

            if (
              !isEmpty(headerData[j].styleObj) &&
              !isEmpty(headerData[j].styleObj.style)
            ) {
              if (headerData[j].styleObj.style.html) {
                cellColorStyle += headerData[j].styleObj.style.html;
              }

              if (
                !isEmpty(headerData[j]) &&
                !isEmpty(headerData[j].styleObj) &&
                !isEmpty(headerData[j].styleObj.style) &&
                !isEmpty(headerData[j].styleObj.style.htmlObj)
              ) {
                if (!isEmpty(headerData[j].styleObj.style.htmlObj.cell)) {
                  const htmlStr = getHtmlString(
                    headerData[j].styleObj.style.htmlObj.cell,
                  );
                  cellColorStyle += htmlStr;
                }

                if (!isEmpty(headerData[j].styleObj.style.htmlObj.text)) {
                  const htmlStr = getHtmlString(
                    headerData[j].styleObj.style.htmlObj.text,
                  );
                  cellColorStyle += htmlStr;
                }
              }
            }
          }

          if (
            !isEmpty(rowObj) &&
            !isEmpty(rowObj[headerData[j].id]) &&
            !isEmpty(rowObj[headerData[j].id].styleObj) &&
            !isEmpty(rowObj[headerData[j].id].styleObj.style) &&
            !isEmpty(rowObj[headerData[j].id].styleObj.style.htmlObj)
          ) {
            if (
              !isEmpty(rowObj[headerData[j].id].styleObj.style.htmlObj.text)
            ) {
              const htmlStr = getHtmlString(
                rowObj[headerData[j].id].styleObj.style.htmlObj.text,
              );
              cellColorStyle += htmlStr;
            }

            if (
              !isEmpty(rowObj[headerData[j].id].styleObj.style.htmlObj.cell)
            ) {
              const htmlStr = getHtmlString(
                rowObj[headerData[j].id].styleObj.style.htmlObj.cell,
              );
              cellColorStyle += htmlStr;
            }
          }

          if (
            rowValue !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.PERCENT
          ) {
            rowValue = `${rowValue} %`;
          } else if (
            rowValue !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.RUPEE
          ) {
            rowValue = formatCurrency(rowValue, userCountry, headerData[j]);
          } else if (
            rowValue !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.DATE
          ) {
            rowValue = getPrintDate(rowValue);
          } else if (
            headerData[j].fieldType === FIELD_TYPE_ID.MAPS &&
            !isEmpty(rowValue) &&
            !isEmpty(rowObj[headerData[j].id].coordinate)
          ) {
            const coordinate = rowObj[headerData[j].id].coordinate;
            const latLng = `${coordinate.latitude},${coordinate.longitude}`;
            const webURL = 'http://maps.google.com/?daddr=' + latLng;
            rowValue = `<a href="${webURL}">${rowValue}</a>`;
          } else if (
            rowValue !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.TIME
          ) {
            rowValue = getPrintTime(rowValue);
          } else if (
            rowValue !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.CONTACT &&
            rowObj[headerData[j].id].contactName
          ) {
            rowValue += '<br>';
            rowValue += `(${rowObj[headerData[j].id].contactName})`;
          } else if (
            rowValue !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.UNIT
          ) {
            rowValue = formatUnit(
              rowValue,
              fileObj[headerData[j].id],
              userCountry,
            );
          } else if (
            rowValue !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.IMAGE
          ) {
            let imgData = '';
            const imageObjData = base64ImageData;

            if (isArray(rowObj[headerData[j].id].val))
              rowObj[headerData[j].id].val.forEach((item) => {
                imgData += `<img src="${item.uri}" alt="Image" style="padding:10px;object-fit:contain" width="200" height="200">`;
              });

            rowValue = imgData;
          } else if (
            rowValue !== '' &&
            (headerData[j].fieldType === FIELD_TYPE_ID.SWITCH ||
              headerData[j].fieldType === FIELD_TYPE_ID.CHECKBOX)
          ) {
            rowValue = rowValue === true ? yesText : noText;
          } else if (
            rowValue !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.FORMULA
          ) {
            if (headerData[j].subType === FIELD_TYPE_ID.RUPEE) {
              rowValue = formatCurrency(rowValue, userCountry, headerData[j]);
            } else if (headerData[j].subType === FIELD_TYPE_ID.DATE) {
              rowValue = formatDateDiff(rowValue, userPref);
            } else if (headerData[j].subType === FIELD_TYPE_ID.TIME) {
              rowValue = formatTimeDiff(rowValue, userPref);
            } else if (headerData[j].subType === FIELD_TYPE_ID.UNIT) {
              rowValue = formatUnit(
                rowValue,
                headerData[j].unitDependentColumn
                  ? fileObj[headerData[j].unitDependentColumn]
                  : {},
                userCountry,
              );
            }
          } else if (
            headerData[j].fieldType === FIELD_TYPE_ID.SELECT_POP_UP &&
            !isEmpty(rowObj[headerData[j].id])
          ) {
            if (!isEmpty(rowObj[headerData[j].id].displayName)) {
              let lang = 'EN';
              if (!isEmpty(userPref) && userPref.lang) {
                lang = userPref.lang;
              }
              rowValue =
                rowObj[headerData[j].id].displayName[lang] != null
                  ? rowObj[headerData[j].id].displayName[lang]
                  : rowObj[headerData[j].id].displayName['EN'];
            }

            if (rowObj[headerData[j].id].htmlStyle) {
              cellColorStyle += rowObj[headerData[j].id].htmlStyle;
            }
          } else if (
            (headerData[j].fieldType === FIELD_TYPE_ID.LABEL &&
              !isEmpty(rowObj[headerData[j].id])) ||
            (headerData[j].fieldType === FIELD_TYPE_ID.TABLE &&
              headerData[j].subType === FIELD_TYPE_ID.LABEL)
          ) {
            if (rowObj[headerData[j].id].val) {
              rowValue = `<div style="display: flex; justify-content: center; padding: 10px; flex-wrap:wrap; ${
                'margin-bottom:' + MARGIN.SMALL.toString() + 'px'
              }">${getLabelValueArray(rowValue, headerData[j])
                .map((item) => {
                  const thisStyle = {
                    ...(item.valStyleObj
                      ? {
                          background: item.valStyleObj.backgroundColor,
                          color: item.valStyleObj.color,
                        }
                      : {background: COLOR.WHITE}),
                    'box-shadow': '1px 1px 1.5px #ddd',
                    padding: MARGIN.SMALL.toString() + 'px',
                    'padding-left': MARGIN.MEDIUM.toString() + 'px',
                    'padding-right': MARGIN.MEDIUM.toString() + 'px',
                    'border-radius': BORDER.RADIUS_LARGE.toString() + 'px',
                    'margin-bottom': MARGIN.SMALL.toString() + 'px',
                    'margin-right': MARGIN.SMALL.toString() + 'px',
                  };
                  return `<div style="display:inline;${Object.keys(thisStyle)
                    .map((item) => item + ':' + thisStyle[item] + ';')
                    .join('')}">${item.val}</div>`;
                })
                .join('')}</div>`;
            } else {
              rowValue = '';
            }
            if (rowObj[headerData[j].id]?.htmlStyle) {
              cellColorStyle += rowObj[headerData[j].id].htmlStyle;
            }
          } else if (
            rowValue !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.REMINDER
          ) {
            const unixTime = rowValue;
            rowValue = getPrintTime(unixTime);
            rowValue = rowValue + '<br>';
            rowValue = rowValue + getPrintDate(unixTime);
          } else if (
            rowValue !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.DOCUMENT
          ) {
            rowValue =
              isArray(rowValue) && rowValue.length > 0
                ? getPrintAttachment(userPref, rowValue.length)
                : '';
          } else if (
            rowValue !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.AUDIO
          ) {
            rowValue = checkCellContainsAudio(rowValue)
              ? getPrintAudio(userPref, rowValue.length)
              : '';
          } else if (
            rowValue !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.ASSIGN_TASK
          ) {
            rowValue = getAssignTaskAsPlainText(rowValue, userPref, true);
          } else if (
            rowValue !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.URL
          ) {
            rowValue = getUrlAsPlainText(rowObj[headerData[j].id], true);
          }

          if (rowValue !== '') {
            isEmptyRow = false;
          }
          row += `<td style="${cellStyleFinal}${cellColorStyle}overflow: hidden;width:150px;word-break: break-all;">${rowValue}</td>`;
        }
        row += '</tr>';
        if (isEmptyRow) {
          emptyArray.push(row);
        } else {
          if (emptyArray.length > 0) {
            tableRow += emptyArray.join('');
            emptyArray = [];
          }
          tableRow += row;
        }
        headerRow += row;
      }
      if (
        !isEmpty(metaData) &&
        !isEmpty(metaData.fileData) &&
        !isEmpty(metaData.fileData.footerAsHeader) &&
        footerData[metaData.fileData.footerAsHeader.colId]
      ) {
        let val = footerData[metaData.fileData.footerAsHeader.colId].val || '';
        delete footerData[metaData.fileData.footerAsHeader.colId];
        if (
          metaData.fileData.footerAsHeader.fieldType === FIELD_TYPE_ID.RUPEE
        ) {
          val = formatCurrency(val, userCountry);
        }

        const headingText = metaData.fileData.footerAsHeader.headerText[lang];
        tableHeading = `<tr style="background-color:rgb(224,231,236);">
        <td colspan="26" style="font-size:12pt;padding:5px 17px">
            <b style="margin:15px">${headingText}: ${val}</b>
          </td>
      </tr>`;
      }

      if (!isEmpty(footerData)) {
        let showFooter = false;
        let row = `<tr><td style="${numberStyle}"><td>`;
        for (let j = breakRow; j < breakColumn; j++) {
          let val =
            footerData[headerData[j].id] &&
            footerData[headerData[j].id].val !== ''
              ? footerData[headerData[j].id].val || ''
              : '';
          if (
            val !== '' &&
            (headerData[j].fieldType === FIELD_TYPE_ID.RUPEE ||
              (headerData[j].fieldType === FIELD_TYPE_ID.FORMULA &&
                headerData[j].subType === FIELD_TYPE_ID.RUPEE))
          ) {
            val = formatCurrency(val, userCountry, headerData[j]);
            showFooter = true;
          } else if (
            val !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.FORMULA &&
            (headerData[j].subType === FIELD_TYPE_ID.TIME ||
              headerData[j].subType === FIELD_TYPE_ID.DATE)
          ) {
            val = formatDateDiff(
              val,
              userPref,
              headerData[j].subType === FIELD_TYPE_ID.TIME,
              true,
            );
            showFooter = true;
          } else if (
            val !== '' &&
            (headerData[j].fieldType === FIELD_TYPE_ID.UNIT ||
              (headerData[j].fieldType === FIELD_TYPE_ID.FORMULA &&
                headerData[j].subType === FIELD_TYPE_ID.UNIT))
          ) {
            val = formatUnit(
              val,
              headerData[j].subType === FIELD_TYPE_ID.UNIT
                ? headerData[j].unitDependentColumn
                  ? fileObj[headerData[j].unitDependentColumn]
                  : {}
                : fileObj[headerData[j].id],
              userCountry,
            );
            showFooter = true;
          }
          let finalFooterStyle = footerStyle;
          if (
            !isEmpty(headerData[j]) &&
            !isEmpty(headerData[j].styleObj) &&
            !isEmpty(headerData[j].styleObj.style) &&
            !isEmpty(headerData[j].styleObj.style.htmlObj)
          ) {
            if (
              !isEmpty(headerData[j].styleObj.style.htmlObj.cell) &&
              headerData[j].styleObj.style.htmlObj.cell.width
            ) {
              finalFooterStyle += `width:${headerData[j].styleObj.style.htmlObj.cell.width}px;`;
            }
          }

          if (val !== '') {
            showFooter = true;
          }
          row += `<td style="${finalFooterStyle}">${val}</td>`;
        }
        if (showFooter) {
          headerRow += row;
        }
      }
      if (breakColumn < headerData.length) {
        headerRow += '</table><p style="page-break-after: always;"></p>';
      } else {
        headerRow += '</table>';
      }
      breakRow = breakColumn;
    } while (breakColumn < headerData.length);
    const headerHtml = header
      ? `<div style="padding-left: 15px;padding-right: 15px;"><p>${header}</p></div>`
      : '';
    const footerHtml = footer
      ? `<div  style="padding-left: 15px;padding-right: 15px;padding-top:10px;"><p>${footer}</p></div>`
      : '';

    htmlFullCode =
      htmlFullCode +
      `
   <p style="text-align:center;margin-bottom:10px;">${name}</p> 
   ${headerHtml}
   ${tableHeading}
     ${headerRow}
   ${footerHtml}
   <p style='page-break-after: always;'></p>
   `;
  }

  return {htmlBody: htmlFullCode, totalWidth: totalWidthArray[0]};
};
const getPagesHtml = async (
  {
    docsData,
    metaData,
    name,
    address,
    header,
    footer,
    image,
    profilePicture,
    clientName,
    clientAddress,
    allColumnInOnePage,
    isPremiumUser,
  },
  {yesText, noText},
  {
    getPrintDate,
    formatCurrency,
    formatDateDiff,
    formatTimeDiff,
    getPrintTime,
    getPrintAttachment,
    userPref,
    userCountry,
    formatUnit,
    setLoaderText = null,
    getAssignTaskAsPlainText,
    getUrlAsPlainText,
  },
) => {
  const functions = {
    formatCurrency,
    formatDateDiff,
    formatTimeDiff,
    getPrintDate,
    getPrintTime,
    getPrintAttachment,
    formatUnit,
    setLoaderText,
    getAssignTaskAsPlainText,
    getUrlAsPlainText,
  };

  const userData = {
    yesText,
    noText,
    userCountry,
    userPref,
    metaData,
    header,
    footer,
    allColumnInOnePage,
  };
  let htmlFullCode = '';
  const {htmlBody, totalWidth} = getHtmlBody(docsData, functions, userData);

  let lang = 'EN';
  if (!isEmpty(userPref) && userPref.lang) {
    lang = userPref.lang;
  }
  let nameHtml = '';
  let addressHtml = '';
  let nameAddressDiv = '';
  let clientNameHtml = '';
  let clientAddressHtml = '';
  const pdfHeaderDiv = await getPdfHeaderDiv(
    name,
    image,
    address,
    profilePicture,
    lang,
    isPremiumUser,
  );
  if (name) {
    nameHtml = `<span style="font-size:20pt;margin-bottom:5px;">${name}</span>`;
    addressHtml = `<span style="font-size:12pt;">${
      address ? address : ''
    }</span>`;
    nameAddressDiv =
      image !== '' && image !== null && image !== undefined
        ? `<div style="display:flex;flex:1;flex-direction:column;align-items:center;justify-content:center;margin-right:75px">
    ${nameHtml}
    ${addressHtml}
    </div>`
        : `<div style="display:flex;flex:1;flex-direction:column;align-items:center;justify-content:center">
    ${nameHtml}
    ${addressHtml}
    </div>`;
  }
  if (clientName) {
    clientNameHtml = `<div style="padding-left:15px;padding-right: 15px;"><p>${clientNameLang[lang]}: ${clientName}</p></div>`;
    clientAddressHtml = clientAddress
      ? `<div style="padding-left:15px;padding-right: 15px;"><p>${clientAddressLang[lang]}: ${clientAddress}</p></div>`
      : '';
  }

  const imageHtml =
    image !== '' && image !== null && image !== undefined
      ? `<img src="${
          profilePicture ? profilePicture : PROFILE_URL.PROD
        }" alt="Image" style="object-fit:contain;border-radius:50%" width="75" height="75">`
      : '';
  htmlFullCode = `<!DOCTYPE html>
      <meta charset="UTF-8" />
      <html> <body>
      ${pdfHeaderDiv}
      ${clientNameHtml}
      ${clientAddressHtml}
       ${htmlBody} 
       
       </body>
      </html>`;

  return {
    html: htmlFullCode,
    totalWidth,
    allColumnInOnePage,
  };
};

const getPagesHtmlBlockView = async (
  {
    docsData,
    metaData,
    name,
    address,
    header,
    footer,
    base64ImageData,
    image,
    profilePicture,
    clientName,
    clientAddress,
  },
  {recordText, totalText, yesText, noText},
  {
    getPrintDate,
    formatCurrency,
    formatDateDiff,
    formatTimeDiff,
    getPrintTime,
    getPrintAttachment,
    userPref,
    userCountry,
    formatUnit,
    getAssignTaskAsPlainText,
    getUrlAsPlainText,
  },
) => {
  const functions = {
    formatCurrency,
    formatDateDiff,
    formatTimeDiff,
    getPrintDate,
    getPrintTime,
    getPrintAttachment,
    formatUnit,
    getAssignTaskAsPlainText,
    getUrlAsPlainText,
  };
  const userData = {
    recordText,
    totalText,
    yesText,
    noText,
    userCountry,
    userPref,
    metaData,
    header,
    footer,
  };

  const htmlFullCode = getHtmlBlockViewBody(docsData, functions, userData);

  let lang = 'EN';
  if (!isEmpty(userPref) && userPref.lang) {
    lang = userPref.lang;
  }

  let nameHtml = '';
  let addressHtml = '';
  let nameAddressDiv = '';
  let clientNameHtml = '';
  let clientAddressHtml = '';
  if (name) {
    nameHtml = `<span style="font-size:20pt;margin-bottom:5px;">${name}</span>`;
    addressHtml = `<span style="font-size:12pt;">${
      address ? address : ''
    }</span>`;
    nameAddressDiv =
      image !== '' && image !== null && image !== undefined
        ? `<div style="display:flex;flex:1;flex-direction:column;align-items:center;justify-content:center;margin-right:75px">
    ${nameHtml}
    ${addressHtml}
    </div>`
        : `<div style="display:flex;flex:1;flex-direction:column;align-items:center;justify-content:center">
    ${nameHtml}
    ${addressHtml}
    </div>`;
  }
  if (clientName) {
    clientNameHtml = `<div style="padding-left:15px;padding-right: 15px;"><p>${clientNameLang[lang]}: ${clientName}</p></div>`;
    clientAddressHtml = clientAddress
      ? `<div style="padding-left:15px;padding-right: 15px;"><p>${clientAddressLang[lang]}: ${clientAddress}</p></div>`
      : '';
  }
  const imageHtml =
    image !== '' && image !== null && image !== undefined
      ? `<img src="${
          profilePicture ? profilePicture : PROFILE_URL.PROD
        }" alt="Image" style="object-fit:contain;border-radius:50%" width="75" height="75">`
      : '';

  return `<!DOCTYPE html>
  <html>
  <body>
  <p style="text-align: right;margin-right:20px;font-size:9pt;color:rgb(63,63,63);">
  </p>
  <div style="display:flex; align-items:center;justify-content:center;margin-bottom:50px;margin-left:15px">
  ${imageHtml}
  ${nameAddressDiv}
  </div>
  ${clientNameHtml}
  ${clientAddressHtml}
  ${htmlFullCode}
  </body>
  </html>`;
};

const getHtmlBlockView = async (
  {
    headerData,
    tableData,
    footerData,
    fileObj,
    metaData,
    name,
    address,
    header,
    footer,
    base64ImageData,
    image,
    profilePicture,
    clientName,
    clientAddress,
  },
  {recordText, totalText, yesText, noText},
  {
    getPrintDate,
    formatCurrency,
    formatDateDiff,
    formatTimeDiff,
    getPrintTime,
    getPrintAttachment,
    userPref,
    userCountry,
    formatUnit,
    getAssignTaskAsPlainText,
    getUrlAsPlainText,
  },
) => {
  const commonStyle = 'padding:10px;font-size:12pt;word-wrap: break-word;';
  const borderLight = `border: 1px solid ${COLOR.BORDER_RGB};`;
  const numberStyle = 'text-align:center;font-size:12pt;padding-top:10px;';
  let tableHeading = '';
  const headerStyle =
    commonStyle +
    `border: 1px solid ${COLOR.BORDER_DARK_RGB};background-color:${COLOR.BORDER_RGB};max-width:200px;min-width:100px;`;
  let tableRow = '';
  let emptyArray = [];
  for (let i = 0; i < tableData.length; i++) {
    let newRow = `<tr><td colspan="2" style="${numberStyle}">${recordText} ${
      i + 1
    }</td></tr>`;

    const rowObj = tableData[i];
    let isEmptyRow = true;

    for (let j = 0; j < headerData.length; j++) {
      // if (headerData[j].fieldType === FIELD_TYPE_ID.REMINDER) {
      //   continue;
      // }
      let rowValue =
        rowObj[headerData[j].id] && rowObj[headerData[j].id].val != null
          ? rowObj[headerData[j].id].val
          : '';

      let cellColorStyle = '';

      if (
        headerData[j].fieldType === FIELD_TYPE_ID.TEXT &&
        rowValue &&
        (typeof rowValue === 'object' || typeof rowValue === 'undefined')
      ) {
        rowValue = '';
      }

      if (
        [FIELD_TYPE_ID.TEXT, FIELD_TYPE_ID.BARCODE].includes(
          headerData[j].fieldType,
        ) &&
        rowValue &&
        (typeof rowValue === 'object' || typeof rowValue === 'undefined')
      ) {
        rowValue = '';
      }

      if (
        !isEmpty(headerData[j]) &&
        !isEmpty(headerData[j].styleObj) &&
        rowValue !== ''
      ) {
        if (
          (headerData[j].fieldType === FIELD_TYPE_ID.NUMBER ||
            headerData[j].fieldType === FIELD_TYPE_ID.RUPEE) &&
          !isEmpty(headerData[j].styleObj.postiveStyle) &&
          !isEmpty(headerData[j].styleObj.negativeStyle)
        ) {
          if (rowValue < 0) {
            cellColorStyle += headerData[j].styleObj.negativeStyle.html;
          } else if (rowValue >= 0) {
            cellColorStyle += headerData[j].styleObj.postiveStyle.html;
          }
        }
        if (
          !isEmpty(headerData[j].styleObj) &&
          !isEmpty(headerData[j].styleObj.style)
        ) {
          if (headerData[j].styleObj.style.html) {
            cellColorStyle += headerData[j].styleObj.style.html;
          }
          if (
            !isEmpty(headerData[j]) &&
            !isEmpty(headerData[j].styleObj) &&
            !isEmpty(headerData[j].styleObj.style) &&
            !isEmpty(headerData[j].styleObj.style.htmlObj)
          ) {
            if (!isEmpty(headerData[j].styleObj.style.htmlObj.cell)) {
              if (headerData[j].styleObj.style.htmlObj.cell.width) {
                delete headerData[j].styleObj.style.htmlObj.cell.width;
              }
              const htmlStr = getHtmlString(
                headerData[j].styleObj.style.htmlObj.cell,
              );
              cellColorStyle += htmlStr;
            }

            if (!isEmpty(headerData[j].styleObj.style.htmlObj.text)) {
              const htmlStr = getHtmlString(
                headerData[j].styleObj.style.htmlObj.text,
              );
              cellColorStyle += htmlStr;
            }
          }
        }
      }

      if (
        !isEmpty(rowObj) &&
        !isEmpty(rowObj[headerData[j].id]) &&
        !isEmpty(rowObj[headerData[j].id].styleObj) &&
        !isEmpty(rowObj[headerData[j].id].styleObj.style) &&
        !isEmpty(rowObj[headerData[j].id].styleObj.style.htmlObj)
      ) {
        if (!isEmpty(rowObj[headerData[j].id].styleObj.style.htmlObj.text)) {
          const htmlStr = getHtmlString(
            rowObj[headerData[j].id].styleObj.style.htmlObj.text,
          );
          cellColorStyle += htmlStr;
        }

        if (!isEmpty(rowObj[headerData[j].id].styleObj.style.htmlObj.cell)) {
          const htmlStr = getHtmlString(
            rowObj[headerData[j].id].styleObj.style.htmlObj.cell,
          );
          cellColorStyle += htmlStr;
        }
      }

      // if (
      //   rowValue !== '' &&
      //   headerData[j].fieldType === FIELD_TYPE_ID.PERCENT
      // ) {
      //   rowValue = `${rowValue} %`;
      // } else
      if (rowValue !== '' && headerData[j].fieldType === FIELD_TYPE_ID.RUPEE) {
        rowValue = formatCurrency(rowValue, userCountry, headerData[j]);
      } else if (
        rowValue !== '' &&
        headerData[j].fieldType === FIELD_TYPE_ID.DATE
      ) {
        rowValue = getPrintDate(rowValue);
      } else if (
        headerData[j].fieldType === FIELD_TYPE_ID.MAPS &&
        !isEmpty(rowValue) &&
        !isEmpty(rowObj[headerData[j].id].coordinate)
      ) {
        const coordinate = rowObj[headerData[j].id].coordinate;
        const latLng = `${coordinate.latitude},${coordinate.longitude}`;
        const webURL = 'http://maps.google.com/?daddr=' + latLng;
        rowValue = `<a href="${webURL}">${rowValue}</a>`;
      } else if (
        rowValue !== '' &&
        headerData[j].fieldType === FIELD_TYPE_ID.TIME
      ) {
        rowValue = getPrintTime(rowValue);
      } else if (
        rowValue !== '' &&
        headerData[j].fieldType === FIELD_TYPE_ID.CONTACT &&
        rowObj[headerData[j].id].contactName
      ) {
        rowValue += '<br>';
        rowValue += `(${rowObj[headerData[j].id].contactName})`;
      } else if (
        rowValue !== '' &&
        headerData[j].fieldType === FIELD_TYPE_ID.UNIT
      ) {
        rowValue = formatUnit(rowValue, fileObj[headerData[j].id], userCountry);
      } else if (
        rowValue !== '' &&
        headerData[j].fieldType === FIELD_TYPE_ID.IMAGE
      ) {
        let imgData = '';
        const imageObjData = base64ImageData;
        if (isArray(rowObj[headerData[j].id].val))
          rowObj[headerData[j].id].val.forEach((item) => {
            imgData += `<img src="${item.uri}" alt="Image" style="padding:10px;object-fit:contain" width="200" height="200">`;
          });
        rowValue = imgData;
      } else if (
        rowValue !== '' &&
        (headerData[j].fieldType === FIELD_TYPE_ID.SWITCH ||
          headerData[j].fieldType === FIELD_TYPE_ID.CHECKBOX)
      ) {
        rowValue = rowValue === true ? yesText : noText;
      } else if (
        rowValue !== '' &&
        headerData[j].fieldType === FIELD_TYPE_ID.FORMULA
      ) {
        if (headerData[j].subType === FIELD_TYPE_ID.RUPEE) {
          rowValue = formatCurrency(rowValue, userCountry, headerData[j]);
        } else if (headerData[j].subType === FIELD_TYPE_ID.DATE) {
          rowValue = formatDateDiff(rowValue, userPref);
        } else if (headerData[j].subType === FIELD_TYPE_ID.TIME) {
          rowValue = formatTimeDiff(rowValue, userPref);
        } else if (headerData[j].subType === FIELD_TYPE_ID.UNIT) {
          rowValue = formatUnit(
            rowValue,
            headerData[j].unitDependentColumn
              ? fileObj[headerData[j].unitDependentColumn]
              : {},
            userCountry,
          );
        }
      } else if (
        headerData[j].fieldType === FIELD_TYPE_ID.SELECT_POP_UP &&
        !isEmpty(rowObj[headerData[j].id])
      ) {
        if (!isEmpty(rowObj[headerData[j].id].displayName)) {
          let lang = 'EN';
          if (!isEmpty(userPref) && userPref.lang) {
            lang = userPref.lang;
          }
          rowValue =
            rowObj[headerData[j].id].displayName[lang] != null
              ? rowObj[headerData[j].id].displayName[lang]
              : rowObj[headerData[j].id].displayName['EN'];
        }

        if (rowObj[headerData[j].id].htmlStyle) {
          cellColorStyle += rowObj[headerData[j].id].htmlStyle;
        }
      } else if (
        headerData[j].fieldType === FIELD_TYPE_ID.LABEL &&
        !isEmpty(rowObj[headerData[j].id])
      ) {
        rowValue = `<div style="display: flex;padding: 10px; flex-wrap:wrap; ${
          'margin-bottom:' + MARGIN.SMALL.toString() + 'px'
        }">${getLabelValueArray(rowValue, headerData[j])
          .map((item) => {
            const thisStyle = {
              ...(item.valStyleObj
                ? {
                    background: item.valStyleObj.backgroundColor,
                    color: item.valStyleObj.color,
                  }
                : {background: COLOR.WHITE}),
              'box-shadow': '1px 1px 1.5px #ddd',
              padding: MARGIN.SMALL.toString() + 'px',
              'padding-left': MARGIN.MEDIUM.toString() + 'px',
              'padding-right': MARGIN.MEDIUM.toString() + 'px',
              'border-radius': BORDER.RADIUS_LARGE.toString() + 'px',
              'margin-bottom': MARGIN.SMALL.toString() + 'px',
              'margin-right': MARGIN.SMALL.toString() + 'px',
            };
            return `<div style="display:inline;${Object.keys(thisStyle)
              .map((item) => item + ':' + thisStyle[item] + ';')
              .join('')}">${item.val}</div>`;
          })
          .join('')}</div>`;
        if (rowObj[headerData[j].id].htmlStyle) {
          cellColorStyle += rowObj[headerData[j].id].htmlStyle;
        }
      } else if (
        rowValue !== '' &&
        headerData[j].fieldType === FIELD_TYPE_ID.DOCUMENT
      ) {
        rowValue =
          isArray(rowValue) && rowValue.length > 0
            ? getPrintAttachment(userPref, rowValue.length)
            : '';
      } else if (
        rowValue !== '' &&
        headerData[j].fieldType === FIELD_TYPE_ID.REMINDER
      ) {
        const unixTime = rowValue;
        rowValue = getPrintTime(unixTime);
        rowValue = rowValue + '<br>';
        rowValue = rowValue + getPrintDate(unixTime);
      } else if (
        rowValue !== '' &&
        headerData[j].fieldType === FIELD_TYPE_ID.AUDIO
      ) {
        rowValue = checkCellContainsAudio(rowValue)
          ? getPrintAudio(userPref, rowValue.length)
          : '';
      } else if (
        rowValue !== '' &&
        headerData[j].fieldType === FIELD_TYPE_ID.ASSIGN_TASK
      ) {
        rowValue = getAssignTaskAsPlainText(rowValue, userPref, true);
      } else if (
        rowValue !== '' &&
        headerData[j].fieldType === FIELD_TYPE_ID.URL
      ) {
        rowValue = getUrlAsPlainText(rowObj[headerData[j].id], true);
      }

      if (rowValue !== '') {
        isEmptyRow = false;
      }
      newRow += `
        <tr>
        <td style="${headerStyle}">${headerData[j].val}</td>
        <td style="${commonStyle}${borderLight}${cellColorStyle}">${rowValue}</td>
        </tr>
      `;
    }
    if (isEmptyRow) {
      emptyArray.push(newRow);
    } else {
      if (emptyArray.length > 0) {
        tableRow += emptyArray.join('');
        emptyArray = [];
      }
      tableRow += newRow;
    }
  }

  if (
    !isEmpty(metaData) &&
    !isEmpty(metaData.fileData) &&
    !isEmpty(metaData.fileData.footerAsHeader) &&
    footerData[metaData.fileData.footerAsHeader.colId]
  ) {
    let val = footerData[metaData.fileData.footerAsHeader.colId].val;
    val = formatFooterAsHeader(
      val,
      {
        ...metaData.fileData.footerAsHeader,
        id: metaData.fileData.footerAsHeader.colId,
      },
      fileObj,
      userCountry,
    );
    delete footerData[metaData.fileData.footerAsHeader.colId];
    let lang = 'EN';
    if (!isEmpty(userPref) && userPref.lang) {
      lang = userPref.lang;
    }
    const headerTextObj = metaData.fileData.footerAsHeader.headerText;
    const headingText =
      headerTextObj[lang] ||
      headerTextObj[Object.keys(headerTextObj)[0]] ||
      headerTextObj['EN'];
    tableHeading = `<tr style="background-color:rgb(224,231,236);">
        <td colspan="2" style="font-size:12pt;padding:10px">
            <b>${headingText}: ${val}</b>
          </td>
      </tr>`;
  }

  if (!isEmpty(footerData)) {
    tableRow += `<tr><td colspan="2" style="${numberStyle}">${totalText}</td></tr>`;
    for (let j = 0; j < headerData.length; j++) {
      if (footerData[headerData[j].id]) {
        let val =
          footerData[headerData[j].id] &&
          footerData[headerData[j].id].val !== ''
            ? footerData[headerData[j].id].val
            : '';
        if (
          val !== '' &&
          (headerData[j].fieldType === FIELD_TYPE_ID.RUPEE ||
            (headerData[j].fieldType === FIELD_TYPE_ID.FORMULA &&
              headerData[j].subType === FIELD_TYPE_ID.RUPEE))
        ) {
          val = formatCurrency(val, userCountry, headerData[j]);
        } else if (
          val !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.FORMULA &&
          (headerData[j].subType === FIELD_TYPE_ID.TIME ||
            headerData[j].subType === FIELD_TYPE_ID.DATE)
        ) {
          val = formatDateDiff(
            val,
            userPref,
            headerData[j].subType === FIELD_TYPE_ID.TIME,
            true,
          );
        } else if (
          val !== '' &&
          (headerData[j].fieldType === FIELD_TYPE_ID.UNIT ||
            (headerData[j].fieldType === FIELD_TYPE_ID.FORMULA &&
              headerData[j].subType === FIELD_TYPE_ID.UNIT))
        ) {
          val = formatUnit(
            val,
            headerData[j].subType === FIELD_TYPE_ID.UNIT
              ? headerData[j].unitDependentColumn
                ? fileObj[headerData[j].unitDependentColumn]
                : {}
              : fileObj[headerData[j].id],
            userCountry,
          );
        }
        tableRow += `
        <tr>
        <td style="${headerStyle}">${totalText}: (${headerData[j].val})</td>
        <td style="${commonStyle}${borderLight}">${val}</td>
        </tr>
      `;
      }
    }
  }

  let lang = 'EN';
  if (!isEmpty(userPref) && userPref.lang) {
    lang = userPref.lang;
  }

  let nameHtml = '';
  let addressHtml = '';
  let nameAddressDiv = '';
  let clientNameHtml = '';
  let clientAddressHtml = '';
  if (name) {
    nameHtml = `<span style="font-size:20pt;margin-bottom:5px;">${name}</span>`;
    addressHtml = `<span style="font-size:12pt;">${
      address ? address : ''
    }</span>`;
    nameAddressDiv =
      image !== '' && image !== null && image !== undefined
        ? `<div style="display:flex;flex:1;flex-direction:column;align-items:center;justify-content:center;margin-right:75px">
    ${nameHtml}
    ${addressHtml}
    </div>`
        : `<div style="display:flex;flex:1;flex-direction:column;align-items:center;justify-content:center">
    ${nameHtml}
    ${addressHtml}
    </div>`;
  }
  if (clientName) {
    clientNameHtml = `<div style="padding-left:15px;padding-right: 15px;"><p>${clientNameLang[lang]}: ${clientName}</p></div>`;
    clientAddressHtml = clientAddress
      ? `<div style="padding-left:15px;padding-right: 15px;"><p>${clientAddressLang[lang]}: ${clientAddress}</p></div>`
      : '';
  }
  const headerHtml = header ? `<p style="padding:15px;">${header}</p>` : '';
  const footerHtml = footer ? `<p style="padding:15px;">${footer}</p>` : '';
  const imageHtml =
    image !== '' && image !== null && image !== undefined
      ? `<img src="${
          profilePicture ? profilePicture : PROFILE_URL.PROD
        }" alt="Image" style="object-fit:contain;border-radius:50%" width="75" height="75">`
      : '';

  return `<!DOCTYPE html>
  <html>
  <body>
  <p style="text-align: right;margin-right:20px;font-size:9pt;color:rgb(63,63,63);">
  </p>
  <div style="display:flex; align-items:center;justify-content:center;margin-bottom:50px;margin-left:15px">
  ${imageHtml}
  ${nameAddressDiv}
  </div>
  ${clientNameHtml}
  ${clientAddressHtml}
  ${headerHtml}
 
  <table style="border-collapse: collapse;margin:15px;" cellpadding="0" cellspacing="0">
  ${tableHeading}
    ${tableRow}
  </table>
 
  ${footerHtml}
  </body>
  </html>`;
};

const getHtmlBlockViewBody = (docsData, functions, userData) => {
  const {
    formatCurrency,
    formatDateDiff,
    formatTimeDiff,
    getPrintDate,
    getPrintTime,
    getPrintAttachment,
    formatUnit,
    getAssignTaskAsPlainText,
    getUrlAsPlainText,
  } = functions;
  const {
    recordText,
    totalText,
    yesText,
    noText,
    userCountry,
    userPref,
    metaData,
    header,
    footer,
  } = userData;
  const commonStyle = 'padding:10px;font-size:12pt;word-wrap: break-word;';
  const borderLight = `border: 1px solid ${COLOR.BORDER_RGB};`;
  const numberStyle = 'text-align:center;font-size:12pt;padding-top:10px;';
  let tableHeading = '';
  const headerStyle =
    commonStyle +
    `border: 1px solid ${COLOR.BORDER_DARK_RGB};background-color:${COLOR.BORDER_RGB};max-width:200px;min-width:100px;`;

  let htmlFullCode = '';

  for (let p = 0; p < docsData.length; p++) {
    const {headerData, tableData, footerData, base64ImageData, name, fileObj} =
      docsData[p];
    let tableRow = '';
    let emptyArray = [];
    for (let i = 0; i < tableData.length; i++) {
      let newRow = `<tr><td colspan="2" style="${numberStyle}">${recordText} ${
        i + 1
      }</td></tr>`;

      const rowObj = tableData[i];
      let isEmptyRow = true;

      for (let j = 0; j < headerData.length; j++) {
        let rowValue =
          rowObj[headerData[j].id] && rowObj[headerData[j].id].val != null
            ? rowObj[headerData[j].id].val
            : '';

        let cellColorStyle = '';

        if (
          headerData[j].fieldType === FIELD_TYPE_ID.TEXT &&
          rowValue &&
          (typeof rowValue === 'object' || typeof rowValue === 'undefined')
        ) {
          rowValue = '';
        }

        if (
          [FIELD_TYPE_ID.TEXT, FIELD_TYPE_ID.BARCODE].includes(
            headerData[j].fieldType,
          ) &&
          rowValue &&
          (typeof rowValue === 'object' || typeof rowValue === 'undefined')
        ) {
          rowValue = '';
        }

        if (
          !isEmpty(headerData[j]) &&
          !isEmpty(headerData[j].styleObj) &&
          rowValue !== ''
        ) {
          if (
            (headerData[j].fieldType === FIELD_TYPE_ID.NUMBER ||
              headerData[j].fieldType === FIELD_TYPE_ID.RUPEE) &&
            !isEmpty(headerData[j].styleObj.postiveStyle) &&
            !isEmpty(headerData[j].styleObj.negativeStyle)
          ) {
            if (rowValue < 0) {
              cellColorStyle += headerData[j].styleObj.negativeStyle.html;
            } else if (rowValue >= 0) {
              cellColorStyle += headerData[j].styleObj.postiveStyle.html;
            }
          }
          if (
            !isEmpty(headerData[j].styleObj) &&
            !isEmpty(headerData[j].styleObj.style)
          ) {
            if (headerData[j].styleObj.style.html) {
              cellColorStyle += headerData[j].styleObj.style.html;
            }
            if (
              !isEmpty(headerData[j]) &&
              !isEmpty(headerData[j].styleObj) &&
              !isEmpty(headerData[j].styleObj.style) &&
              !isEmpty(headerData[j].styleObj.style.htmlObj)
            ) {
              if (!isEmpty(headerData[j].styleObj.style.htmlObj.cell)) {
                if (headerData[j].styleObj.style.htmlObj.cell.width) {
                  delete headerData[j].styleObj.style.htmlObj.cell.width;
                }
                const htmlStr = getHtmlString(
                  headerData[j].styleObj.style.htmlObj.cell,
                );
                cellColorStyle += htmlStr;
              }

              if (!isEmpty(headerData[j].styleObj.style.htmlObj.text)) {
                const htmlStr = getHtmlString(
                  headerData[j].styleObj.style.htmlObj.text,
                );
                cellColorStyle += htmlStr;
              }
            }
          }
        }

        if (
          !isEmpty(rowObj) &&
          !isEmpty(rowObj[headerData[j].id]) &&
          !isEmpty(rowObj[headerData[j].id].styleObj) &&
          !isEmpty(rowObj[headerData[j].id].styleObj.style) &&
          !isEmpty(rowObj[headerData[j].id].styleObj.style.htmlObj)
        ) {
          if (!isEmpty(rowObj[headerData[j].id].styleObj.style.htmlObj.text)) {
            const htmlStr = getHtmlString(
              rowObj[headerData[j].id].styleObj.style.htmlObj.text,
            );
            cellColorStyle += htmlStr;
          }

          if (!isEmpty(rowObj[headerData[j].id].styleObj.style.htmlObj.cell)) {
            const htmlStr = getHtmlString(
              rowObj[headerData[j].id].styleObj.style.htmlObj.cell,
            );
            cellColorStyle += htmlStr;
          }
        }

        if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.PERCENT
        ) {
          rowValue = `${rowValue} %`;
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.RUPEE
        ) {
          rowValue = formatCurrency(rowValue, userCountry, headerData[j]);
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.DATE
        ) {
          rowValue = getPrintDate(rowValue);
        } else if (
          headerData[j].fieldType === FIELD_TYPE_ID.MAPS &&
          !isEmpty(rowValue) &&
          !isEmpty(rowObj[headerData[j].id].coordinate)
        ) {
          const coordinate = rowObj[headerData[j].id].coordinate;
          const latLng = `${coordinate.latitude},${coordinate.longitude}`;
          const webURL = 'http://maps.google.com/?daddr=' + latLng;
          rowValue = `<a href="${webURL}">${rowValue}</a>`;
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.TIME
        ) {
          rowValue = getPrintTime(rowValue);
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.CONTACT &&
          rowObj[headerData[j].id].contactName
        ) {
          rowValue += '<br>';
          rowValue += `(${rowObj[headerData[j].id].contactName})`;
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.UNIT
        ) {
          rowValue = formatUnit(
            rowValue,
            fileObj[headerData[j].id],
            userCountry,
          );
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.IMAGE
        ) {
          let imgData = '';
          const imageObjData = base64ImageData;
          if (isArray(rowObj[headerData[j].id].val))
            rowObj[headerData[j].id].val.forEach((item) => {
              imgData += `<img src="${item.uri}" alt="Image" style="padding:10px;object-fit:contain" width="200" height="200">`;
            });
          rowValue = imgData;
        } else if (
          rowValue !== '' &&
          (headerData[j].fieldType === FIELD_TYPE_ID.SWITCH ||
            headerData[j].fieldType === FIELD_TYPE_ID.CHECKBOX)
        ) {
          rowValue = rowValue === true ? yesText : noText;
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.FORMULA
        ) {
          if (headerData[j].subType === FIELD_TYPE_ID.RUPEE) {
            rowValue = formatCurrency(rowValue, userCountry, headerData[j]);
          } else if (headerData[j].subType === FIELD_TYPE_ID.DATE) {
            rowValue = formatDateDiff(rowValue, userPref);
          } else if (headerData[j].subType === FIELD_TYPE_ID.TIME) {
            rowValue = formatTimeDiff(rowValue, userPref);
          } else if (headerData[j].subType === FIELD_TYPE_ID.UNIT) {
            rowValue = formatUnit(
              rowValue,
              headerData[j].unitDependentColumn
                ? fileObj[headerData[j].unitDependentColumn]
                : {},
              userCountry,
            );
          }
        } else if (
          headerData[j].fieldType === FIELD_TYPE_ID.SELECT_POP_UP &&
          !isEmpty(rowObj[headerData[j].id])
        ) {
          if (!isEmpty(rowObj[headerData[j].id].displayName)) {
            let lang = 'EN';
            if (!isEmpty(userPref) && userPref.lang) {
              lang = userPref.lang;
            }
            rowValue =
              rowObj[headerData[j].id].displayName[lang] != null
                ? rowObj[headerData[j].id].displayName[lang]
                : rowObj[headerData[j].id].displayName['EN'];
          }

          if (rowObj[headerData[j].id].htmlStyle) {
            cellColorStyle += rowObj[headerData[j].id].htmlStyle;
          }
        } else if (
          headerData[j].fieldType === FIELD_TYPE_ID.LABEL &&
          !isEmpty(rowObj[headerData[j].id])
        ) {
          if (rowObj[headerData[j].id].val) {
            rowValue = `<div style="display: flex; justify-content: center; padding: 10px; flex-wrap:wrap; ${
              'margin-bottom:' + MARGIN.SMALL.toString() + 'px'
            }">${getLabelValueArray(rowValue, headerData[j])
              .map((item) => {
                const thisStyle = {
                  ...(item.valStyleObj
                    ? {
                        background: item.valStyleObj.backgroundColor,
                        color: item.valStyleObj.color,
                      }
                    : {background: COLOR.WHITE}),
                  'box-shadow': '1px 1px 1.5px #ddd',
                  padding: MARGIN.SMALL.toString() + 'px',
                  'padding-left': MARGIN.MEDIUM.toString() + 'px',
                  'padding-right': MARGIN.MEDIUM.toString() + 'px',
                  'border-radius': BORDER.RADIUS_LARGE.toString() + 'px',
                  'margin-bottom': MARGIN.SMALL.toString() + 'px',
                  'margin-right': MARGIN.SMALL.toString() + 'px',
                };
                return `<div style="display:inline;${Object.keys(thisStyle)
                  .map((item) => item + ':' + thisStyle[item] + ';')
                  .join('')}">${item.val}</div>`;
              })
              .join('')}</div>`;
          } else {
            rowValue = '';
          }
          if (rowObj[headerData[j].id].htmlStyle) {
            cellColorStyle += rowObj[headerData[j].id].htmlStyle;
          }
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.DOCUMENT
        ) {
          rowValue =
            isArray(rowValue) && rowValue.length > 0
              ? getPrintAttachment(userPref, rowValue.length)
              : '';
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.REMINDER
        ) {
          const unixTime = rowValue;
          rowValue = getPrintTime(unixTime);
          rowValue = rowValue + '<br>';
          rowValue = rowValue + getPrintDate(unixTime);
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.AUDIO
        ) {
          rowValue = checkCellContainsAudio(rowValue)
            ? getPrintAudio(userPref, rowValue.length)
            : '';
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.ASSIGN_TASK
        ) {
          rowValue = getAssignTaskAsPlainText(rowValue, userPref, true);
        } else if (
          rowValue !== '' &&
          headerData[j].fieldType === FIELD_TYPE_ID.URL
        ) {
          rowValue = getUrlAsPlainText(rowObj[headerData[j].id], true);
        }

        if (rowValue !== '') {
          isEmptyRow = false;
        }
        newRow += `
        <tr>
        <td style="${headerStyle}">${headerData[j].val}</td>
        <td style="${commonStyle}${borderLight}${cellColorStyle}">${rowValue}</td>
        </tr>
      `;
      }
      if (isEmptyRow) {
        emptyArray.push(newRow);
      } else {
        if (emptyArray.length > 0) {
          tableRow += emptyArray.join('');
          emptyArray = [];
        }
        tableRow += newRow;
      }
    }

    if (
      !isEmpty(metaData) &&
      !isEmpty(metaData.fileData) &&
      !isEmpty(metaData.fileData.footerAsHeader) &&
      footerData[metaData.fileData.footerAsHeader.colId]
    ) {
      let val = footerData[metaData.fileData.footerAsHeader.colId].val;
      delete footerData[metaData.fileData.footerAsHeader.colId];
      if (metaData.fileData.footerAsHeader.fieldType === FIELD_TYPE_ID.RUPEE) {
        val = formatCurrency(val, userCountry);
      }
      let lang = 'EN';
      if (!isEmpty(userPref) && userPref.lang) {
        lang = userPref.lang;
      }
      const headingText = metaData.fileData.footerAsHeader.headerText[lang];
      tableHeading = `<tr style="background-color:rgb(224,231,236);">
        <td colspan="2" style="font-size:12pt;padding:10px">
            <b>${headingText}: ${val}</b>
          </td>
      </tr>`;
    }

    if (!isEmpty(footerData)) {
      tableRow += `<tr><td colspan="2" style="${numberStyle}">${totalText}</td></tr>`;
      for (let j = 0; j < headerData.length; j++) {
        if (footerData[headerData[j].id]) {
          let val =
            footerData[headerData[j].id] && footerData[headerData[j].id].val
              ? footerData[headerData[j].id].val
              : '';
          if (
            val !== '' &&
            (headerData[j].fieldType === FIELD_TYPE_ID.RUPEE ||
              (headerData[j].fieldType === FIELD_TYPE_ID.FORMULA &&
                headerData[j].subType === FIELD_TYPE_ID.RUPEE))
          ) {
            val = formatCurrency(val, userCountry, headerData[j]);
          } else if (
            val !== '' &&
            headerData[j].fieldType === FIELD_TYPE_ID.FORMULA &&
            (headerData[j].subType === FIELD_TYPE_ID.TIME ||
              headerData[j].subType === FIELD_TYPE_ID.DATE)
          ) {
            val = formatDateDiff(
              val,
              userPref,
              headerData[j].subType === FIELD_TYPE_ID.TIME,
              true,
            );
          } else if (
            val !== '' &&
            (headerData[j].fieldType === FIELD_TYPE_ID.UNIT ||
              (headerData[j].fieldType === FIELD_TYPE_ID.FORMULA &&
                headerData[j].subType === FIELD_TYPE_ID.UNIT))
          ) {
            val = formatUnit(
              val,
              headerData[j].subType === FIELD_TYPE_ID.UNIT
                ? headerData[j].unitDependentColumn
                  ? fileObj[headerData[j].unitDependentColumn]
                  : {}
                : fileObj[headerData[j].id],
              userCountry,
            );
          }
          tableRow += `
        <tr>
        <td style="${headerStyle}">${totalText}: (${headerData[j].val})</td>
        <td style="${commonStyle}${borderLight}">${val}</td>
        </tr>
      `;
        }
      }
    }
    const headerHtml = header ? `<p style="padding:15px;">${header}</p>` : '';
    const footerHtml = footer ? `<p style="padding:15px;">${footer}</p>` : '';
    htmlFullCode =
      htmlFullCode +
      `
  <p style="text-align:center;margin-bottom:10px;">${name}</p> 
  ${headerHtml}
  <table style="border-collapse: collapse;margin:15px;" cellpadding="0" cellspacing="0">
  ${tableHeading}
    ${tableRow}
  </table>
  ${footerHtml}
  <p style='page-break-after: always;'></p>
  `;
  }
  return htmlFullCode;
};
const getPdfHeaderDiv = async (
  name,
  image,
  address,
  profilePicture,
  lang,
  isPremiumUser,
) => {
  const justifyContentVal =
    !image && !name && !address ? 'flex-end' : 'space-between';
  let nameString = '';
  let addressString = '';
  let logoString = '';
  let nameAddressLogoDiv = '';
  const lioLogoUri = isProduction ? LIO_LOGO.PROD : LIO_LOGO.STAGE;
  const lioLogoDiv = !isPremiumUser
    ? `<div style="display: flex;flex-direction: row;align-items: center;">
    <p style="font-size: 12px;font-weight: 400;color: #5F5F5F;">${
      waterMark[lang]
    }</p>
    <img src="${
      lioLogoUri ? lioLogoUri : LIO_LOGO.PROD
    }" alt="Lio" style="width:71px;height:26px;margin-left:20px;">
  </div>`
    : '';
  if (image) {
    const imageStyle =
      image === 'USER'
        ? 'width:50px;height:50px'
        : 'width:60px;height:60px;object-fit:contain';
    logoString = `<img src="${
      profilePicture ? profilePicture.base64Str : PROFILE_URL.PROD
    }" alt="Company logo" style="${imageStyle};margin-right:20px;">`;
  }
  if (name) {
    nameString = `<p style="font-size: 12px;font-weight: 400;"> ${nameLang[lang]}: ${name}</p>`;
  }
  if (address) {
    addressString = `<p style="font-size: 12px;font-weight: 400;color: #5F5F5F;"> ${addressLang[lang]}: ${address}</p>`;
  }
  if (image || name || address) {
    nameAddressLogoDiv = `<div style="display: flex;flex-direction: row;align-items: center;">
      ${logoString}
      <div>${nameString}
      ${addressString}</div>
      </div>`;
  }

  let pdfHeaderDiv = '';

  if (nameAddressLogoDiv || lioLogoDiv) {
    pdfHeaderDiv = `<div style="display: flex;flex-direction: row;align-items: center;
      background-color:#F7FAFF;padding: 10px 15px;margin: 0px 10px;justify-content: ${justifyContentVal};">
      ${nameAddressLogoDiv}
      ${lioLogoDiv}
      </div>`;
  }
  return pdfHeaderDiv;
};
export {GetWebHtml, getPagesHtml, getHtmlBlockView, getPagesHtmlBlockView};
