import moment from 'moment';

class PushNotificationArray {
  constructor(x) {
    const elements = Array.isArray(x) ? x : [];

    this.addForward = this.addForward.bind(this);

    this.items = elements.reduce(this.reduceItems, {});

    this.dates = this.handleElements({}, elements);
    this.sections = this.prepareSections(this.dates, this.items);
    // this.push(...this.getNotifIds(elements));
  }

  getNotifIds(elements) {
    return elements.map((item) => item.notificationId);
  }

  handleElements(datesInit, elements) {
    const dates = {...datesInit};
    const handledIds = {};
    elements.forEach((item) => {
      if (handledIds[item.notificationId]) return;

      const createdAt = moment(item.createdAt?.toDate?.());

      // Format the date as "today", "yesterday", or "16 March 2023"
      const formattedDate = createdAt.calendar(null, {
        nextDay: 'D MMMM YYYY',
        nextWeek: 'D MMMM YYYY',
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: 'D MMMM YYYY',
        sameElse: 'D MMMM YYYY',
      });

      if (!dates[formattedDate]) {
        dates[formattedDate] = [item.notificationId];
        handledIds[item.notificationId] = true;
        return;
      }

      dates[formattedDate].push(item.notificationId);
      handledIds[item.notificationId] = true;
    });
    return dates;
  }

  reduceItems(acc, cur) {
    return {...acc, [cur.notificationId]: cur};
  }

  addForward(arr) {
    if (!Array.isArray(arr)) return this;
    arr = arr.filter((item) => !this.items[item.notificationId]);
    const init = this.items;
    this.items = arr.reduce(this.reduceItems, init);
    // this.push(...this.getNotifIds(elements));
    this.dates = this.handleElements(this.dates, arr);
    this.sections = this.prepareSections(this.dates, this.items);
    return this;
  }

  sortDates(a, b) {
    return moment(b, 'D MMMM YYYY') - moment(a, 'D MMMM YYYY');
  }

  getDates(dates) {
    // Sort the items by their createdAt date
    return Object.keys(dates).sort(this.sortDates);
  }

  sortSections(a, b) {
    return b.createdAt.toDate() - a.createdAt.toDate();
  }

  mapSections(dates, items, date) {
    return {
      title: date,
      data: dates[date].map((notif) => items[notif]).sort(this.sortSections),
    };
  }

  prepareSections(dates, items) {
    const thisDates = this.getDates(dates);
    return thisDates.map((date) => this.mapSections(dates, items, date));
  }
}

export default PushNotificationArray;
