// import {generateRandomId} from '../utils/RandomGenerator';
import {ENTRY_STATUS_TYPES} from '../utils/constant';
import {ENTRY_STATUS_ACTION} from './actionType';

const toggleWebActivityMenu = (valueToSet) => (dispatch, getState) => {
  const currentAppId = getState()?.miniApps?.activeAppId;
  dispatch({
    type: ENTRY_STATUS_ACTION.TOGGLE_WEB_ACTIVITY_MENU_OPEN_STATUS,
    payload: {valueToSet, currentAppId},
  });
  getState().entryStatus.updatesSinceLastOpen > 0 &&
    dispatch({
      type: ENTRY_STATUS_ACTION.RESET_LAST_OPENED_NOTIFY_COUNT,
    });
};

const addStatusRequest =
  ({requestId, screenId, rowMeta, onBGOperationStatusChange, entryText}) =>
  (dispatch) => {
    const payload = {
      requestId,
      rowMeta,
      screenId,
      status: ENTRY_STATUS_TYPES.PENDING,
      message: {
        heading: 'In Progress',
        subtext: `${entryText ?? 'Entry'} save in progress`,
      },
      onWebToastClick: () => dispatch(toggleWebActivityMenu(true)),
    };
    dispatch({
      type: ENTRY_STATUS_ACTION.UPDATE_REQUEST_STATUS_OBJECT,
      payload,
    });
    onBGOperationStatusChange?.(payload);
  };

const updateStatusRequestForSuccess =
  ({requestId, screenId, rowMeta, messageObj, onBGOperationStatusChange}) =>
  (dispatch) => {
    const payload = {
      requestId,
      rowMeta,
      status: ENTRY_STATUS_TYPES.SUCCESS,
      message: messageObj,
      screenId,
      onWebToastClick: () => dispatch(toggleWebActivityMenu(true)),
    };
    dispatch({
      type: ENTRY_STATUS_ACTION.UPDATE_REQUEST_STATUS_OBJECT,
      payload,
    });
    onBGOperationStatusChange?.(payload);
  };

const updateStatusRequestForFailure =
  ({
    requestId,
    screenId,
    rowMeta,
    messageObj = {},
    errorObj = {},
    paramsToPreserve,
    onBGOperationStatusChange,
  }) =>
  (dispatch) => {
    const payload = {
      requestId,
      screenId,
      rowMeta,
      status: ENTRY_STATUS_TYPES.FAILED,
      message: messageObj,
      errorObj,
      paramsToPreserve,
      onWebToastClick: () => dispatch(toggleWebActivityMenu(true)),
    };
    dispatch({
      type: ENTRY_STATUS_ACTION.UPDATE_REQUEST_STATUS_OBJECT,
      payload,
    });
    onBGOperationStatusChange?.(payload);
  };

const resetLastOpenerNotifyCount = () => (dispatch, getState) =>
  getState().entryStatus.updatesSinceLastOpen > 0 &&
  dispatch({
    type: ENTRY_STATUS_ACTION.RESET_LAST_OPENED_NOTIFY_COUNT,
  });

const updateStatusRequestForEditRowOpInKanban =
  ({docId, rowMeta, isUnderProcess}) =>
  (dispatch) => {
    const payload = {
      docId,
      rowMeta,
      isUnderProcess,
    };
    dispatch({
      type: ENTRY_STATUS_ACTION.UPDATE_REQUEST_STATUS_OBJECT_FOR_KANBAN,
      payload,
    });
  };

export {
  addStatusRequest,
  resetLastOpenerNotifyCount,
  updateStatusRequestForFailure,
  updateStatusRequestForSuccess,
  toggleWebActivityMenu,
  updateStatusRequestForEditRowOpInKanban,
};
