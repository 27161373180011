import React, {useState, useEffect, useRef} from 'react';
import LocalText from './localText';
import Snackbar from '@material-ui/core/Snackbar';
const defaultTimeout = 3000;

let ToastAction = () => {};

const ToastComp = () => {
  const [message, setMessage] = useState('');
  let timout = useRef(defaultTimeout);

  ToastAction = (msg, duration) => {
    if (msg.length) {
      if (!isNaN(duration)) {
        timout.current = duration;
      }
      setMessage(msg);
    }
  };

  useEffect(() => {
    if (message?.length) {
      setTimeout(() => {
        setMessage('');
        timout.current = defaultTimeout;
      }, timout.current ?? defaultTimeout);
    }
  }, [message]);

  return (
    <Snackbar
      open={Boolean(message?.length)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      message={<LocalText>{message}</LocalText>}
      style={{textAlign: 'center'}}
    />
  );
};

const Toast = React.memo(ToastComp);
const ShowToast = (...args) => {
  typeof ToastAction === 'function' && ToastAction(...args);
};

export {Toast, ShowToast};
