import {firestore} from '../../imports';

/**
 * Firestore rules:
 *
 * match /miniAppsUserWhatsAppTemplates/{documentId=**} {
 *   allow read: if request.auth != null
 *   allow write: if request.auth != null
 *   allow update: if request.auth != null
 * }
 *
 * */

export default class MiniAppsUserWhatsAppTemplatesDB {
  collection(firestoreInstance) {
    return (firestoreInstance ?? firestore)().collection(
      'miniAppsUserWhatsAppTemplates',
    );
  }

  doc(docId, firestoreInstance) {
    return this.collection(firestoreInstance).doc(docId);
  }
}
