import {makeStyles} from '@material-ui/core/styles';
import {invert} from 'lodash';

export const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    minWidth: (props) =>
      props.minWidth
        ? props.minWidth
        : props.width === 'auto'
        ? 'auto'
        : '100px',
    width: (props) => props.width || 'auto',
    background: (props) =>
      props.cancelButtonBackground
        ? props.cancelButtonBackground
        : props.deleteButtonBackground &&
          !props.invertWithColor &&
          !props.invert
        ? theme.palette.red
        : props.invert || props.invertWithColor
        ? 'none'
        : theme.palette.brandBlue,
    cursor: 'pointer',
    height: (props) => props.height || '36px',
    color: (props) =>
      props.cancelButtonBackground
        ? 'white'
        : props.deleteButtonText && props.invertWithColor
        ? theme.palette.red
        : props.invertWithColor
        ? theme.palette.brandBlue
        : props.invert
        ? theme.palette.textNav
        : 'white',
    borderRadius: '4px',
    opacity: (props) => (props.disabled ? '.3' : '1'),
    fontSize: theme.fontSize.font13,
    lineHeight: theme.lineHeight.lineHeight20,
    marginLeft: (props) => props.marginLeft && '10px',
    margin: (props) => (props.margin ? props.margin : ''),
    border: (props) =>
      props.noborder
        ? 'none'
        : props.invert || props.invertWithColor
        ? `1px solid ${theme.palette.utilsBorder}`
        : 'none',
    padding: (props) => props.padding || '7px 16px',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      gap: '9px',
      justifyContent: 'center',
      fontWeight: '500',
      fontFamily: '"Inter"',
    },
    '&:hover': {
      cursor: 'pointer',
      background: (props) =>
        props.cancelButtonBackground
          ? props.cancelButtonBackground
          : props.deleteButtonBackground &&
            !props.invertWithColor &&
            !props.invert
          ? theme.palette.darkRed
          : props.invert || props.invertWithColor
          ? theme.palette.gray100
          : theme.palette.darkPrimary,
    },
  },
}));
