import {TEAMS_ACTION, HOME_ACTION} from './actionType';
import {SHARE_PERMISSION_TYPE} from '../utils/constant';
import {
  getLocalText,
  getUserCallingCode,
  serializeError,
  JSONStringifier,
} from '../utils/utils';
import {isEmpty, omit, forOwn, isFunction} from 'lodash';
import {
  ENV,
  captureError,
  ShowToast,
  firestore,
  captureInfo,
  logAnalyticsEvent,
} from '../imports';
import {loadCollabMeta} from './collabAction';
import * as teamActionsHelper from './actionHelpers/teamActionsHelper';
import {setUserPref} from './authAction';
import FirestoreDB from '../FirestoreHandlers/FirestoreDB';

//get teams (listener)
const activateTeamsListener =
  (firestoreInstance) => async (dispatch, getState) => {
    try {
      const {
        auth: {user, userPref},
      } = getState();
      firestoreInstance = firestoreInstance ?? firestore;
      if (!user?.uid) {
        return;
      }

      dispatch({
        type: TEAMS_ACTION.START_LOADING_TEAMS,
      });

      const queries = [
        ['teamOwnerUID', '==', user.uid], //teams owned by user
        [`teamMembers.${user.uid}.timestamp`, '>', 0], //teams user is a member of
      ];

      const initialFetchSuccessArr = queries.map(() => false);

      const listenersArr = queries.map((query, queryIndex) => {
        let isFirstFetch = true;
        const isTeamOwner = queryIndex === 0;

        const handleListener = (snapshot) => {
          try {
            const changedDocuments = snapshot.docChanges();
            const updatedTeamsObj = {};
            const removedTeamIds = [];

            changedDocuments.forEach((change) => {
              try {
                switch (change.type) {
                  case 'added':
                  case 'modified': {
                    const teamId = change.doc.id;
                    updatedTeamsObj[teamId] =
                      teamActionsHelper.getFormattedTeamObj(
                        change.doc.data(),
                        teamId,
                        user.uid,
                        userPref,
                      );
                    break;
                  }
                  case 'removed': {
                    removedTeamIds.push(change.doc.id);
                    break;
                  }
                }
              } catch (err) {
                captureInfo({
                  err: serializeError(err),
                  changedDocuments: JSONStringifier(changedDocuments),
                });
                captureError(
                  new Error(
                    'Error teams listener (change-doc) : isTeamOwner : ' +
                      isTeamOwner,
                  ),
                  true,
                );
              }
            });

            const {teams} = getState().teams;
            const updatedTeams = omit(
              Object.assign({}, teams, updatedTeamsObj),
              removedTeamIds,
            );

            dispatch({
              type: TEAMS_ACTION.SET_TEAMS,
              payload: {teams: updatedTeams},
            });
          } catch (err) {
            captureInfo({
              err: serializeError(err),
              snapshot,
            });
            captureError(
              new Error('Error teams listener (processing-snapshot)'),
              true,
            );
          }

          if (isFirstFetch) {
            isFirstFetch = false;
            initialFetchSuccessArr[queryIndex] = true;
            if (initialFetchSuccessArr.every((val) => val)) {
              //all the teams are fetched
              dispatch({
                type: TEAMS_ACTION.STOP_LOADING_TEAMS,
              });
            }
          }
        };

        return FirestoreDB.FirestoreListener(
          firestoreInstance()
            .collection('teams')
            .where(...query),
          handleListener,
        );
      });

      dispatch({
        type: HOME_ACTION.UPDATE_HOME_LISTENER_OBJ,
        payload: {
          TEAMS_LISTENER: () => {
            listenersArr.forEach((listener) => {
              //deactivate
              if (isFunction(listener)) {
                listener();
              }
            });
          },
        },
      });
    } catch (error) {
      captureError(error);
    }
  };

//create/edit/modify or delete team or any of team info
//teamIdToEdit : only required for edit!
const createEditTeam =
  (
    teamName,
    teamColor,
    members,
    teamIdToEdit = null,
    isDelete = false,
    logObj = {},
  ) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {userCountry, userPref, user},
        remoteConfig: {restrictionConfig},
      } = getState();
      if (!user?.uid) {
        return;
      }
      const countryDiallingCode = getUserCallingCode(userCountry) ?? '+91';
      const isEdit = isDelete ? true : Boolean(teamIdToEdit?.length);
      const userRestrictionConfig = Object.assign({}, restrictionConfig);

      const dataObj = Object.assign(
        {},
        {
          teamName,
          teamColor,
          members: isDelete ? [] : members,
          isEdit,
          isDelete,
          teamId: teamIdToEdit,
          sharerContact: user.phoneNumber ?? user.email,
        },
        {
          countryCode: countryDiallingCode,
          restrictionConfig: userRestrictionConfig,
        },
      );

      const data = await teamActionsHelper.createEditTeamCloud(dataObj);

      if (!data || !data.success) {
        let message;
        if (data?.message) {
          message = data.message;
        } else {
          message = 'Something went wrong, please try again';
        }
        if (data?.error) {
          captureInfo({message, dataObj, log: data.log});
          captureError(new Error(data.error), true);
        }
        message = getLocalText(userPref, message);
        if (ENV && data?.isAlert && typeof alert === 'function') {
          alert(message);
        } else {
          ShowToast(message, null, false, true);
        }
        return {
          success: false,
        };
      } else {
        const defaultMsg = isDelete
          ? 'Team deleted successfully.'
          : isEdit
          ? 'Team modified successfully.'
          : 'Team created successfully.';
        let message = getLocalText(userPref, data.message ?? defaultMsg);
        if (data.failedIndexArr?.length) {
          const failedContacts = data.failedIndexArr
            .map((index) => members[index].contact)
            .join(' ,');
          message +=
            ' ' + getLocalText(userPref, 'Failed to add : ') + failedContacts;
        }
        const teamId = data.teamId;
        if (teamId) {
          if (!isEmpty(data.teamData)) {
            //create-Edit Team
            const updatedTeamData = {
              [teamId]: teamActionsHelper.getFormattedTeamObj(
                data.teamData,
                teamId,
                user.uid,
                userPref,
              ),
            };
            if (!isDelete && isEdit) {
              const {
                newMembersCount,
                permissionChangedCount,
                removedMembersCount,
              } = data.analyticsObj ?? {};

              logAnalyticsEvent('TEAM_UPDATE_SUCCESS', {
                ...logObj,
                teamId,
                numMembers: updatedTeamData[teamId]?.teamMembers?.length,
                permissionsChanged: permissionChangedCount > 0,
                membersAdded: newMembersCount > 0,
                membersRemoved: removedMembersCount > 0,
              });
            }

            dispatch({
              type: TEAMS_ACTION.SET_TEAMS,
              payload: {
                teams: Object.assign(
                  {},
                  getState().teams.teams,
                  updatedTeamData,
                ),
              },
            });
          } else if (isDelete) {
            //delete team
            dispatch({
              type: TEAMS_ACTION.SET_TEAMS,
              payload: {
                teams: omit(getState().teams.teams, [teamId]),
              },
            });
          }
        }
        if (!isEdit && !isDelete) {
          logAnalyticsEvent('TEAM_CREATE_SUCCESS', {
            ...logObj,
            teamId,
          });
        }
        ShowToast(message, null, false, true);
        return {
          success: true,
        };
      }
    } catch (error) {
      captureError(error);
      return {
        success: false,
      };
    }
  };

/**
 * @description : Share multiple docs with multiple teams @ once
 * !IMP currently handled for only one doc at a time with multiple teams
 *                                !or one team at a time with multiple docs
 * @param {*} teamId : team id of the team, docs are to be shred with
 * @param {*} docIdNameMapping : docIds as keys and docName as value (to be shared)
 * @param {*} isShareFromFile : true if shaing single file after opening the file
 */
const shareDocsWithTeams =
  (teamIdArr, docIdNameMapping, isShareFromFile = false) =>
  async (dispatch, getState) => {
    try {
      if (!teamIdArr?.length || isEmpty(docIdNameMapping)) {
        return Promise.resolve({success: false});
      }
      const {
        auth: {userPref, user},
        teams,
        remoteConfig: {restrictionConfig},
      } = getState();
      const currTeamsObj = teams.teams;
      const userRestrictionConfig = Object.assign({}, restrictionConfig);

      const dataObj = {
        teamIdArr,
        docIdArr: Object.keys(docIdNameMapping),
        sharerContact: user.phoneNumber ?? user.email,
        restrictionConfig: userRestrictionConfig,
      };
      const data =
        await teamActionsHelper.shareMultipleDocsWithMultipleTeamsCloud(
          dataObj,
        );

      const localText = (msg) => getLocalText(userPref, msg);

      const generateFailedMsg = (isFailed = false) => {
        let message = '';
        let teamIndex = 97;
        try {
          forOwn(
            Object.assign({}, data?.responseForTeams),
            (response, teamId) => {
              const teamName = currTeamsObj[teamId]?.teamName ?? '';
              if (!response?.success && response?.message) {
                message +=
                  message.length || isFailed
                    ? ''
                    : localText('Failed for below') + ' : \n';
                message +=
                  teamIdArr.length === 1
                    ? localText(response.message)
                    : `${String.fromCharCode(
                        teamIndex++,
                      )}) ${teamName} (${localText(response.message)})\n`;
              } else {
                if (response.failedArr?.length) {
                  let failedDocsNames = '';
                  let docFailIndex = 0;
                  response.failedArr.forEach((obj) => {
                    const name = docIdNameMapping[obj?.docId];
                    if (name?.length) {
                      failedDocsNames += `${
                        failedDocsNames.length ? '\n' : ''
                      }${++docFailIndex}. ${name}${
                        obj.message ? ` (${localText(obj.message)})` : ''
                      }`;
                    }
                  });
                  if (failedDocsNames.length) {
                    message +=
                      message.length || isFailed
                        ? ''
                        : localText('Failed for below') + ' : \n';
                    message += `${
                      !isShareFromFile
                        ? `${failedDocsNames}\n`
                        : `${String.fromCharCode(teamIndex++)}) ${teamName}\n`
                    }`;
                  }
                }
              }
            },
          );
          if (message.length) {
            data.isAlert = true;
          }
        } catch (err) {
          captureError(err);
        }
        return message ?? '';
      };

      if (!data || !data.success) {
        let message;
        if (data?.message) {
          message = data.message;
        } else {
          const failedMsg = generateFailedMsg(true);
          message = failedMsg.length
            ? `${localText('Sharing Failed.')}\n${failedMsg}`
            : 'Something went wrong, please try again';
        }
        if (data?.error) {
          captureInfo({message, dataObj, log: data.log});
          captureError(new Error(data.error), true);
        }
        message = localText(message);
        if (ENV && data?.isAlert && typeof alert === 'function') {
          alert(message);
        } else {
          ShowToast(message, null, false, true);
        }
        return Promise.resolve({
          success: false,
        });
      }
      logAnalyticsEvent('FILE_SHARED_TO_TEAM', {
        noOfTeams: teamIdArr.length,
        docIdArr: dataObj.docIdArr,
      });
      //success
      if (isShareFromFile) {
        dispatch(loadCollabMeta(true, false));
      }
      const teamsUpdateObj = {};
      const defaultMsg = localText('Shared Successfully.') + '\n';
      const message =
        localText(data.message) ?? defaultMsg + generateFailedMsg();

      forOwn(data.responseForTeams, (response, teamId) => {
        if (response?.success) {
          //shared with `teamId`
          if (!isEmpty(response.teamData)) {
            teamsUpdateObj[teamId] = teamActionsHelper.getFormattedTeamObj(
              response.teamData,
              teamId,
              user.uid,
              userPref,
            );
          }
        }
      });

      if (!isEmpty(teamsUpdateObj)) {
        dispatch({
          type: TEAMS_ACTION.SET_TEAMS,
          payload: {
            teams: Object.assign({}, getState().teams.teams, teamsUpdateObj),
          },
        });
      }

      if (ENV && data.isAlert && typeof alert === 'function') {
        alert(message);
      } else {
        ShowToast(
          message.endsWith('\n') ? message.slice(0, -1) : message,
          null,
          false,
          true,
        );
      }

      return Promise.resolve({
        success: true,
      });
    } catch (error) {
      captureError(error);
      return Promise.resolve({success: false});
    }
  };

//unshare a single doc shared with teamId
const unshareDocWithTeam = (teamId, docId) => async (dispatch, getState) => {
  try {
    const {
      auth: {userPref},
    } = getState();

    const dataObj = {
      teamId,
      docId,
    };

    logAnalyticsEvent('FILE_UNSHARED_WITH_TEAM', dataObj);

    const data = await teamActionsHelper.unshareFileWithTeamCloud(dataObj);

    if (!data || !data.success) {
      let message;
      if (data?.message) {
        message = data.message;
      } else {
        message = 'Something went wrong, please try again';
      }
      if (data?.error) {
        captureInfo({message, dataObj, log: data.log});
        captureError(new Error(data.error), true);
      }
      message = getLocalText(userPref, message);
      if (ENV && data?.isAlert && typeof alert === 'function') {
        alert(message);
      } else {
        ShowToast(message, null, false, true);
      }
      return {
        success: false,
      };
    } else {
      ShowToast(data?.message ?? 'Document unshared successfully.', userPref);
      return {
        success: true,
      };
    }
  } catch (error) {
    captureError(error);
    return {
      success: false,
    };
  }
};

const addDeleteSelectedList =
  (obj, isAdd = true) =>
  (dispatch, getState) => {
    try {
      const {
        auth: {user, userPref},
        teams: {selectedMembers},
      } = getState();

      const members = Object.assign({}, selectedMembers);
      let updatedMembers = {};
      if (isAdd) {
        if (obj.contact === user.email || obj.contact === user.phoneNumber) {
          return ShowToast(
            `You cannot select your own contact to add in team.`,
            userPref,
          );
        }
        Object.assign({});
        updatedMembers = Object.assign({}, members, {
          [obj.contact]: Object.assign({}, obj, {
            permission: SHARE_PERMISSION_TYPE.CAN_EDIT,
          }),
        });
      } else if (obj.contact in members) {
        updatedMembers = omit(members, obj.contact);
      }
      dispatch({
        type: TEAMS_ACTION.UPDATE_SELECTED_MEMBER,
        payload: updatedMembers,
      });
    } catch (error) {
      captureError(error);
    }
  };

const editPermission =
  ({contact, updatedPermission}) =>
  (dispatch, getState) => {
    try {
      const {
        teams: {selectedMembers},
      } = getState();
      const updatedSelectMembers = {};
      if (contact && updatedPermission && contact in selectedMembers) {
        updatedSelectMembers[contact] = Object.assign(
          {},
          selectedMembers[contact],
          {permission: updatedPermission},
        );
      }
      dispatch({
        type: TEAMS_ACTION.UPDATE_SELECTED_MEMBER,
        payload: Object.assign({}, selectedMembers, updatedSelectMembers),
      });
    } catch (error) {
      captureError(error);
    }
  };

const clearSelectedMembers = () => (dispatch) => {
  try {
    dispatch({
      type: TEAMS_ACTION.EMPTY_SELECT_MEMBERS,
    });
  } catch (error) {
    captureError(error);
  }
};

const setEditMembers = (obj) => (dispatch) => {
  dispatch({
    type: TEAMS_ACTION.UPDATE_SELECTED_MEMBER,
    payload: obj,
  });
};

const checkAndMigrateUserOlderTeams = () => async (dispatch, getState) => {
  try {
    const {
      auth: {userPref, user, userCountry},
      remoteConfig: {restrictionConfig},
    } = getState();

    if (userPref.areOlderTeamsMigrated !== true) {
      const proceedWithMigration = async () => {
        const countryDiallingCode = getUserCallingCode(userCountry) ?? '+91';

        dispatch({
          type: TEAMS_ACTION.UPDATE_TEAMS_MIGRATION_LOADER,
          payload: true,
        });

        const userRestrictionConfig = Object.assign({}, restrictionConfig);

        const response = await teamActionsHelper.migrateUserOlderTeamsCloud({
          countryDiallingCode,
          sharerContact: user.phoneNumber ?? user.email,
          restrictionConfig: userRestrictionConfig,
        });

        if (response?.success) {
          dispatch(setUserPref({areOlderTeamsMigrated: true}));
        }

        dispatch({
          type: TEAMS_ACTION.UPDATE_TEAMS_MIGRATION_LOADER,
          payload: false,
        });
      };

      teamActionsHelper
        .getUserOlderTeams(user.uid)
        .then((res) => {
          const docExists = res?.docs?.length ? true : false;
          if (docExists) {
            //older teams exist
            proceedWithMigration();
          } else {
            //older teams doesn't exist
            dispatch(setUserPref({areOlderTeamsMigrated: true}));
          }
        })
        .catch();
    }
  } catch (error) {
    captureError(error);
  }
};

export {
  setEditMembers,
  createEditTeam,
  shareDocsWithTeams,
  unshareDocWithTeam,
  activateTeamsListener,
  addDeleteSelectedList,
  editPermission,
  clearSelectedMembers,
  checkAndMigrateUserOlderTeams,
};
