import {firestore} from '../../imports';

/**---------------- Document Refs ----------------*/
export default class DocumentsDB {
  documentRef(documentId, firestoreRef = null) {
    return (firestoreRef ?? firestore)()
      .collection('userDocuments')
      .doc(documentId);
  }

  rowsCollectionRef(docId, firestoreRef = null) {
    return this.documentRef(docId, firestoreRef).collection('rows');
  }

  rowsRef(docId, rowId) {
    return this.rowsCollectionRef(docId).doc(rowId);
  }

  documentParentRowMetaRef(documentId, firestoreInstance) {
    return this.documentRef(documentId, firestoreInstance)
      .collection('parentRowMeta')
      .doc('mapping');
  }

  documentAutoFillLinkedFilesRef(documentId, firestoreInstance) {
    return this.documentRef(documentId, firestoreInstance)
      .collection('autoFillLinkedFiles')
      .doc('mapping');
  }

  commentsCollectionRef(docId, rowId, firestoreRef) {
    return this.documentRef(docId, firestoreRef)
      .collection('rows')
      .doc(rowId)
      .collection('rowComments');
  }

  commentsRef(docId, commentDocId) {
    return this.commentsCollectionRef(docId).doc(commentDocId);
  }

  footerPropertiesRef(docId, firestoreRef = null) {
    return this.documentRef(docId, firestoreRef)
      .collection('properties')
      .doc('footerProperties');
  }

  uniqueColCollectionRef(documentId, firestoreRef = null) {
    return this.documentRef(documentId, firestoreRef).collection(
      'columnUniqueValues',
    );
  }

  uniqueColCollectionDocRef(documentId, columnId) {
    return this.uniqueColCollectionRef(documentId).doc(`${columnId}`);
  }

  activeUniqueColDocRef(documentId, firestoreRef = null) {
    return this.uniqueColCollectionRef(documentId, firestoreRef).where(
      'isDeleted',
      '==',
      false,
    );
  }

  versionsCollectionRef(docId, firestoreRef = null) {
    return this.documentRef(docId, firestoreRef).collection('version');
  }

  versionRef(docId, versionId) {
    return this.versionsCollectionRef(docId).doc(versionId);
  }

  versionRowsCollectionRef(docId, versionId) {
    return this.versionRef(docId, versionId).collection('rows');
  }

  queriedUserDocumentsCollectionRef(...args) {
    return firestore()
      .collection('userDocuments')
      .where(...args);
  }

  autoIncrementValsRef(documentId) {
    return firestore().collection('autoIncrementVals').doc(documentId);
  }
}
