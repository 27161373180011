import {TASKS_ACTION} from '../actions/actionType';
import {isEmpty, forOwn} from 'lodash';
import {
  captureError,
  captureInfo,
  SharedPreferenceStorage,
  ShowToast,
  logAnalyticsEvent,
  ENV,
} from '../imports';
import {editRow} from './tableAction';
import {
  assignTaskCloudFunction,
  modifyTaskActiveState,
  getAllTaskCloudFunction,
  getTasksFromLocalStorage,
  alterTaskCompletionState,
} from './actionHelpers/tasksActionHelper';
import {
  ASYNC_STORAGE_KEY,
  FIELD_TYPE_ID,
  TABLE_LIMITS,
  ASSIGN_TASK_DETAILS,
} from '../utils/constant';
import {AVAILABLE_ENTITLEMENTS} from '../utils/premium';
import {
  checkCellContainsAssignTask,
  getAssignTaskCellData,
  getTimezone,
} from '../utils/utils';
import {searchInRowObj} from './searchAction';
import moment from 'moment';

const getAllTasks = () => async (dispatch, getState) => {
  let isFromLocalStorage = false;
  try {
    dispatch(setTaskLoader(true));
    dispatch({
      type: TASKS_ACTION.SET_DOC_NAME_MAPPED_STATE,
      payload: false,
    });
    const {
      auth: {user},
    } = getState();
    const uid = user?.uid;
    if (!uid) {
      return;
    }
    let isFailed = false;
    const allTasks = {};

    const result = await getAllTaskCloudFunction({callerUID: uid});

    if (result?.success) {
      //fetched successfully from server
      const tasks = Object.assign({}, result.tasks);
      Object.assign(allTasks, tasks);
      if (ENV) {
        SharedPreferenceStorage().set(
          ASYNC_STORAGE_KEY.ALL_TASKS_DATA,
          JSON.stringify({uid, tasks: allTasks}),
        );
      }
      isFromLocalStorage = false;
      isFailed = false;
    } else {
      const locallyStoredTasks = ENV ? await getTasksFromLocalStorage(uid) : {};
      if (locallyStoredTasks?.success) {
        //fetched successfully from local storage
        Object.assign(allTasks, Object.assign({}, locallyStoredTasks.tasks));
        isFromLocalStorage = true;
        isFailed = false;
      } else {
        isFromLocalStorage = false;
        isFailed = true;
      }
    }
    if (!isFailed) {
      dispatch({
        type: TASKS_ACTION.GET_ALL_TASKS,
        payload: {allTasks},
      });
      dispatch(resetSearchAndFilter());
    }
    dispatch({
      type: TASKS_ACTION.SET_FETCH_META,
      payload: {isFromLocalStorage, isFailed},
    });
    dispatch(setTaskLoader(false));
    dispatch(setDocMetaInTask());
  } catch (error) {
    dispatch(setTaskLoader(false));
    dispatch({
      type: TASKS_ACTION.SET_FETCH_META,
      payload: {isFromLocalStorage: false, isFailed: true},
    });
    captureError(error);
  }
  return isFromLocalStorage;
};

const setDocMetaInTask = () => (dispatch, getState) => {
  try {
    const {
      tasks: {isFetched: tasksFetched, isDocNameMapped, allTask},
      home: {allFilesFetched, files},
    } = getState();
    let isChanged = false;
    const newTaskObj = {};
    if (!isDocNameMapped && allFilesFetched && tasksFetched) {
      const fileIdNameMapping = {};
      files.forEach((file) => {
        if (!file.documentMeta?.isFolder) {
          fileIdNameMapping[file.documentId] = file.documentMeta?.name || '';
        }
      });
      forOwn(allTask, (taskData, taskId) => {
        const docId = taskData.originalDocumentId;
        const nameObj = {isDocPresent: false};
        if (docId in fileIdNameMapping) {
          isChanged = true;
          Object.assign(nameObj, {
            docName: fileIdNameMapping[docId],
            isDocPresent: true,
          });
        }
        Object.assign(newTaskObj, {
          [taskId]: Object.assign({}, taskData, nameObj),
        });
      });
      if (isChanged) {
        dispatch({
          type: TASKS_ACTION.GET_ALL_TASKS,
          payload: {allTasks: newTaskObj},
        });
        dispatch(searchAndFilterTask());
      }
      dispatch({
        type: TASKS_ACTION.SET_DOC_NAME_MAPPED_STATE,
        payload: true,
      });
    }
  } catch (err) {
    captureError(err);
  }
};

const assignTaskToUser =
  ({
    taskObj,
    rowId,
    columnId,
    rowIndex,
    isEdit = false,
    oldTaskObj = null,
    isQuickEntry = false,
  }) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: {user},
        premium: {subscriptions},
        home,
        table,
        searchFilter,
      } = getState();
      if (!user?.uid) {
        return;
      }
      const isSelf = [user.phoneNumber, user.email].includes(
        taskObj.assignee.contact,
      );
      const shouldSendWhatsappReminder = subscriptions.includes(
        AVAILABLE_ENTITLEMENTS.ASSIGN_TASK_COLUMN,
      )
        ? 'YES'
        : 'NO';
      const dataObj = {
        docId: home.activeDocumentId,
        originalDocumentId: home.originalDocumentId,
        assignedByContact: user.phoneNumber || user.email,
        fileOwnerUID:
          (!isEmpty(home.activeDocumentMeta.collab)
            ? home.originalDocumentId?.split?.('_')?.[0]
            : user.uid) || user.uid,
        columnId,
        rowId,
        taskObj,
        isEdit,
        ...(isEdit ? {oldTaskObj} : {}),
        timezone: getTimezone(),
        shouldSendWhatsappReminder,
      };
      const data = await assignTaskCloudFunction(dataObj);

      if (!data || !data.success || isEmpty(data.updateObj)) {
        let message;
        if (data?.message) {
          message = data.message;
        } else {
          message = 'Something went wrong, please try again';
        }
        if (data?.error) {
          captureInfo({log: data.log});
          captureError(new Error(data.error), true);
        }
        return {
          success: false,
          message,
        };
      }
      const currRowObj = Object.assign(
        {},
        searchFilter.isActive
          ? searchFilter.searchFilterRowIdDataMap[
              searchFilter.searchFilterTableData[rowIndex]
            ]
          : table.tableData[rowIndex],
      );
      const updatedCellObj = Object.assign({}, currRowObj[columnId], {
        val: data.updateObj,
      });
      const updatedRowObj = Object.assign({}, currRowObj, {
        [columnId]: updatedCellObj,
      });
      const undoRedoObj = {
        prevTaskDocObj: data.prevTaskDocObj,
        newTaskDocObj: Object.assign({}, data.newTaskDocObj, {
          isActive: true,
        }),
        taskId: data.updateObj.taskId,
      };
      if (!isQuickEntry) {
        dispatch(
          editRow(updatedRowObj, rowIndex, {
            tasks: [undoRedoObj],
          }),
        ); //edit row with new data
        modifyTaskActiveState(home.activeDocumentId, data.updateObj.taskId); //activate this task
      }
      dispatch(addToVisibleTask(data.updateObj.taskId));
      logAnalyticsEvent(
        isEdit ? 'ASSIGN_VALUE_UPDATED' : 'ASSIGN_VALUE_ADDED',
        {
          docId: home.activeDocumentId,
          dueDate: taskObj.dueDate ? 1 : 0,
          priority: taskObj.priority ? 1 : 0,
          notesFlag: taskObj.note?.length ? 1 : 0,
          status: taskObj.isCompleted ? 'COMPLETED' : 'OPEN',
          isSelf: isSelf ? 1 : 0,
          assignUserId: data.updateObj.assignee?.uid,
        },
      );
      return {
        success: true,
        updatedCellObj,
        taskId: data.updateObj.taskId,
        undoRedoObj,
      };
    } catch (error) {
      captureError(error);
      return {
        success: false,
        message: 'Something went wrong, please try again',
      };
    }
  };

const setTaskLoader = (payload) => (dispatch) => {
  dispatch({
    type: TASKS_ACTION.SET_LOADING,
    payload,
  });
};

const setVisibleTaskLoader = (payload) => (dispatch) => {
  dispatch({
    type: TASKS_ACTION.SET_VISIBLE_TASKS,
    payload,
  });
};

const setVisibleTasks = () => (dispatch, getState) => {
  try {
    const {
      home: {activeDocumentId},
      table: {headerData, tableData},
      premium: {subscriptions},
    } = getState();
    if (
      subscriptions.includes(AVAILABLE_ENTITLEMENTS.ASSIGN_TASK_COLUMN) ||
      subscriptions.includes(
        AVAILABLE_ENTITLEMENTS.ASSIGN_TASK_WITHOUT_WHATSAPP,
      )
    ) {
      //not required
      return;
    }
    const headerList = headerData.filter(
      (obj) => obj.fieldType === FIELD_TYPE_ID.ASSIGN_TASK,
    );
    if (!headerList?.length) {
      //not required
      return;
    }
    const taskList = [];
    for (let i = 0; i < tableData.length; i++) {
      const rowObj = tableData[i];
      for (let j = 0; j < headerList.length; j++) {
        const id = headerList[j]?.id;
        if (checkCellContainsAssignTask(rowObj?.[id]?.val)) {
          taskList.push(rowObj[id].val.taskId);
          if (taskList.length >= TABLE_LIMITS.MAX_ASSIGNED_TASK) {
            break;
          }
        }
      }
      if (taskList.length >= TABLE_LIMITS.MAX_ASSIGNED_TASK) {
        break;
      }
    }
    dispatch({
      type: TASKS_ACTION.SET_VISIBLE_DOCUMENT_IDS,
      payload: Object.assign({}, {[activeDocumentId]: taskList}),
    });
  } catch (error) {
    captureError(error);
  }
};

const addToVisibleTask = (taskId) => (dispatch, getState) => {
  try {
    const {
      tasks: {currentVisibleTasks},
      home: {activeDocumentId},
      premium: {subscriptions},
    } = getState();
    const currentTasks =
      currentVisibleTasks?.[activeDocumentId]?.length > 0
        ? currentVisibleTasks[activeDocumentId].slice()
        : [];
    if (
      subscriptions.includes(AVAILABLE_ENTITLEMENTS.ASSIGN_TASK_COLUMN) ||
      currentTasks.length >= TABLE_LIMITS.MAX_ASSIGNED_TASK ||
      currentTasks.includes(taskId)
    ) {
      //not required
      return;
    }
    dispatch({
      type: TASKS_ACTION.SET_VISIBLE_DOCUMENT_IDS,
      payload: Object.assign({}, currentVisibleTasks, {
        [activeDocumentId]: [...currentTasks, taskId],
      }),
    });
  } catch (error) {
    captureError(error);
  }
};

const resetVisibleTaskId = () => (dispatch) => {
  dispatch({
    type: TASKS_ACTION.SET_VISIBLE_DOCUMENT_IDS,
    payload: {},
  });
};

const updateLoadingTasks =
  (taskId, isAdd = true) =>
  (dispatch, getState) => {
    try {
      const {
        tasks: {loadingTasks},
      } = getState();
      const updatedList = isAdd
        ? [...loadingTasks, taskId]
        : loadingTasks.filter((id) => id != taskId);
      dispatch({
        type: TASKS_ACTION.UPDATE_LOADING_TASKS,
        payload: updatedList,
      });
    } catch (err) {
      captureError(err);
    }
  };

const changeTaskCompleteState =
  (isCompleted, taskId, source) => async (dispatch, getState) => {
    try {
      const {
        auth: {user, userPref},
        premium: {subscriptions},
        tasks: {currentFilter, allTask: allTasksBeforeUpdate},
      } = getState();

      if (!user?.uid) {
        return;
      }

      const shouldSendWhatsappReminder = subscriptions.includes(
        AVAILABLE_ENTITLEMENTS.ASSIGN_TASK_COLUMN,
      )
        ? 'YES'
        : 'NO';

      const dataObj = {
        callerContact: user.phoneNumber ? user.phoneNumber : user.email,
        taskId,
        isCompleted,
        timezone: getTimezone(),
        shouldSendWhatsappReminder,
      };
      const analyticsObj = (isCompleted) => {
        return {
          taskFilterSelected: currentFilter,
          taskId,
          docId: allTasksBeforeUpdate[taskId]?.docId,
          source,
          status: isCompleted ? 'COMPLETED' : 'OPEN',
        };
      };
      logAnalyticsEvent('TASK_MARK_COMPLETE_INIT', analyticsObj(!isCompleted));

      dispatch(updateLoadingTasks(taskId, true));

      const data = await alterTaskCompletionState(dataObj);

      dispatch(updateLoadingTasks(taskId, false));

      if (!data || !data.success) {
        let message;
        if (data?.message) {
          message = data.message;
        } else {
          message = 'Something went wrong, please try again';
        }
        if (data?.error) {
          captureInfo({log: data.log});
          captureError(new Error(data.error), true);
        }
        ShowToast(message, userPref);
        return {success: false, message};
      }
      //success
      const {
        tasks: {allTask},
      } = getState();
      const updatedTasks = Object.assign({}, allTask, {
        [taskId]: Object.assign({}, allTask[taskId], data.updatedTaskObj),
      });
      dispatch({
        type: TASKS_ACTION.UPADATE_TASK_DETAILS,
        payload: {allTask: updatedTasks},
      });
      dispatch(searchAndFilterTask());
      if (ENV) {
        SharedPreferenceStorage().set(
          ASYNC_STORAGE_KEY.ALL_TASKS_DATA,
          JSON.stringify({uid: user.uid, tasks: updatedTasks}),
        );
      }
      ShowToast('Task status changed successfully.', userPref);
      logAnalyticsEvent(
        'TASK_MARK_COMPLETE_SUCCESS',
        analyticsObj(isCompleted),
      );
      return {success: true};
    } catch (error) {
      dispatch(updateLoadingTasks(taskId, true));
      captureError(error);
    }
  };

const updateSearchText = (searchText) => (dispatch) => {
  dispatch({
    type: TASKS_ACTION.UPDATE_TASK_SEARCH_TEXT,
    payload: searchText,
  });
  dispatch(searchAndFilterTask());
};

const updateCurrentFilter = (currentFilter) => (dispatch) => {
  dispatch({
    type: TASKS_ACTION.UPDATE_TASK_FILTER,
    payload: currentFilter,
  });
  dispatch(searchAndFilterTask());
};

const resetSearchAndFilter = () => (dispatch) => {
  dispatch({
    type: TASKS_ACTION.UPDATE_TASK_SEARCH_TEXT,
    payload: '',
  });
  dispatch({
    type: TASKS_ACTION.UPDATE_TASK_FILTER,
    payload: ASSIGN_TASK_DETAILS.FILTERS[0],
  });
  dispatch(searchAndFilterTask());
};

const searchAndFilterTask = () => (dispatch, getState) => {
  try {
    const {
      tasks: {allTask, searchText, currentFilter},
      auth: {userPref, userCountry},
    } = getState();
    const isEligibleForFilter = (obj) => {
      if (ASSIGN_TASK_DETAILS.FILTERS.includes(currentFilter)) {
        const {priority, isCompleted, isOverdue, dueDate} =
          getAssignTaskCellData(obj, true);
        switch (currentFilter) {
          case ASSIGN_TASK_DETAILS.FILTERS[0]: {
            return !isCompleted;
          }
          case ASSIGN_TASK_DETAILS.FILTERS[1]: {
            return isOverdue;
          }
          case ASSIGN_TASK_DETAILS.FILTERS[2]: {
            return isCompleted;
          }
          case ASSIGN_TASK_DETAILS.FILTERS[3]: {
            return (
              !isCompleted && priority === ASSIGN_TASK_DETAILS.PRIORITY.HIGH
            );
          }
          case ASSIGN_TASK_DETAILS.FILTERS[4]: {
            return (
              !isCompleted && priority === ASSIGN_TASK_DETAILS.PRIORITY.MEDIUM
            );
          }
          case ASSIGN_TASK_DETAILS.FILTERS[5]: {
            return (
              !isCompleted && priority === ASSIGN_TASK_DETAILS.PRIORITY.LOW
            );
          }
          case ASSIGN_TASK_DETAILS.FILTERS[6]: {
            return (
              !isCompleted &&
              moment().isSame(moment(dueDate, 'DD MMM YYYY'), 'day')
            );
          }
          case ASSIGN_TASK_DETAILS.FILTERS[7]: {
            return (
              !isCompleted &&
              moment(dueDate, 'DD MMM YYYY').isSame(
                moment().add(1, 'day'),
                'day',
              )
            );
          }
        }
      }
      return true;
    };
    const searchByFileName = (taskObj, searchText) => {
      return taskObj?.docName?.length
        ? taskObj.docName.toLowerCase().includes(searchText)
        : false;
    };
    const searchTextLowercase = `${searchText}`.toLowerCase();
    const updatedValues = Object.values(allTask).filter(
      (data) =>
        isEligibleForFilter(data) &&
        (searchTextLowercase?.length > 0
          ? searchByFileName(data, searchTextLowercase) ||
            searchInRowObj(
              Object.assign({}, data.rowObj),
              searchTextLowercase,
              data.headerData || [],
              data.fileObj || {},
              userPref.lang ?? 'EN',
              userCountry ?? 'IN',
            )
          : true),
    );
    dispatch(setVisibleTaskLoader(updatedValues));
  } catch (err) {
    captureError(err);
  }
};

export {
  changeTaskCompleteState,
  getAllTasks,
  assignTaskToUser,
  setTaskLoader,
  setVisibleTaskLoader,
  setDocMetaInTask,
  setVisibleTasks,
  resetVisibleTaskId,
  resetSearchAndFilter,
  updateCurrentFilter,
  updateSearchText,
};
