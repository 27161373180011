import {cloneDeep} from 'lodash';
import {ELASTIC_DASHBOARDS_ACTION} from '../actions/actionType';

const initialState = {
  activeDashboardId: null,
  allDashboards: {},
  allDashboardsQuickFilterValues: {},
};

const elasticDashboardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ELASTIC_DASHBOARDS_ACTION.START_LOADING_DASHBOARD: {
      const updatedDashboards = cloneDeep(state.allDashboards);
      if (
        updatedDashboards?.[action.payload.activeScreenId]?.dashboards?.[
          action.payload.dashboardId
        ]
      ) {
        updatedDashboards[action.payload.activeScreenId].dashboards[
          action.payload.dashboardId
        ].isLoading = action.payload.isLoading;
        const index = Object.values(
          updatedDashboards[action.payload.activeScreenId].dashboards,
        ).findIndex?.((obj) => obj?.isLoading);
        if (index === -1) {
          updatedDashboards[
            action.payload.activeScreenId
          ].areDashboardsLoading = false;
        }
      }

      return {
        ...state,
        allDashboards: updatedDashboards,
      };
    }
    case ELASTIC_DASHBOARDS_ACTION.SET_DASHBOARD_VALUE: {
      const updatedDashboards = cloneDeep(state.allDashboards);
      if (
        updatedDashboards?.[action.payload.activeScreenId]?.dashboards?.[
          action.payload.dashboardId
        ]
      ) {
        updatedDashboards[action.payload.activeScreenId].dashboards[
          action.payload.dashboardId
        ].dashboardValue = action.payload.dashboardValue;
      }

      return {
        ...state,
        allDashboards: updatedDashboards,
      };
    }
    case ELASTIC_DASHBOARDS_ACTION.SET_ALL_DASHBOARDS: {
      const updatedDashboards = cloneDeep(state.allDashboards);
      if (!updatedDashboards[action.payload.activeScreenId]) {
        updatedDashboards[action.payload.activeScreenId] = {};
      }
      updatedDashboards[action.payload.activeScreenId].dashboards =
        action.payload.dashboards;
      return {
        ...state,
        allDashboards: updatedDashboards,
      };
    }
    case ELASTIC_DASHBOARDS_ACTION.SET_EDIT_DASHBOARD_ID: {
      return {
        ...state,
        activeDashboardId: action.payload.dashboardId,
      };
    }
    case ELASTIC_DASHBOARDS_ACTION.UPDATE_LOADING_ELASTIC_DASHBOARD: {
      const updatedDashboards = cloneDeep(state.allDashboards);
      if (!updatedDashboards[action.payload.activeScreenId]) {
        updatedDashboards[action.payload.activeScreenId] = {};
      }
      updatedDashboards[action.payload.activeScreenId].areDashboardsLoading =
        action.payload.areDashboardsLoading;
      return {
        ...state,
        allDashboards: updatedDashboards,
      };
    }
    case ELASTIC_DASHBOARDS_ACTION.RESET_ELASTIC_DASHBOARDS_STATE: {
      return initialState;
    }
    case ELASTIC_DASHBOARDS_ACTION.SET_DASHBOARD_TIME_FILTER: {
      const updatedDashboards = cloneDeep(state.allDashboards);
      if (!updatedDashboards[action.payload.activeScreenId]) {
        updatedDashboards[action.payload.activeScreenId] = {};
      }
      updatedDashboards[action.payload.activeScreenId].timeFilter =
        action.payload.timeFilter;
      return {
        ...state,
        allDashboards: updatedDashboards,
      };
    }
    case ELASTIC_DASHBOARDS_ACTION.UPDATE_DASHBOARD_CONFIG: {
      const updatedDashboards = cloneDeep(state.allDashboards);
      if (!updatedDashboards[action.payload.activeScreenId]) {
        updatedDashboards[action.payload.activeScreenId] = {};
      }
      updatedDashboards[action.payload.activeScreenId].allFilterData =
        action.payload.allFilterData;
      return {
        ...state,
        allDashboards: updatedDashboards,
      };
    }
    case ELASTIC_DASHBOARDS_ACTION.SET_DASHBOARD_QUICK_FILTER_VALUE: {
      const allDashboardsQuickFilterValues = cloneDeep(
        state.allDashboardsQuickFilterValues,
      );
      allDashboardsQuickFilterValues[action.payload.dashboardId] =
        action.payload.valueArr ?? [];
      return {
        ...state,
        allDashboardsQuickFilterValues,
      };
    }
    default: {
      return state;
    }
  }
};

export default elasticDashboardsReducer;
