import {isEqual} from 'lodash';
import {solvePrevRowRefEqnForRow} from './equationHelper';

/**
 * Helper Class for calculating the previous row reference equations
 * at the runtime(rendering phase)
 */
class PrevRowRefEqnHelperClass {
  constructor() {
    this.reset();
  }

  reset() {
    this.indexWiseRowData = {}; //prev row ref data at the rendered index
    this.lastRenderedIndex = -1; //last row which got rendered
  }

  setValue(index, value) {
    this.lastRenderedIndex = isEqual(value, this.indexWiseRowData[index])
      ? null
      : index;
    this.indexWiseRowData[index] = value;
  }

  solvePrevRowRef(index, rowValues, prevRowRefEqnArrs) {
    const prevRow = Object.assign({}, this.indexWiseRowData[index - 1]);
    const [updatedData, prevRowRefDataObj] = solvePrevRowRefEqnForRow(
      rowValues,
      prevRowRefEqnArrs,
      prevRow,
    );
    this.setValue(index, prevRowRefDataObj);
    return updatedData;
  }

  wasLastRowRerendered(index) {
    return this.lastRenderedIndex !== index - 1;
  }
}

export default new PrevRowRefEqnHelperClass();
