import LocalText from '../common/localText';
import {useStyles} from '../modalButton/ModalButtonStyle';
import TextComponent from '../textComponent/TextComponent';
import {CircularProgress} from '@material-ui/core';
export const ModalButton = ({
  buttonRef,
  onClick,
  invert,
  marginLeft,
  text,
  invertWithColor,
  deleteButtonText,
  deleteButtonBackground,
  cancelButtonBackground,
  width,
  height,
  padding,
  disabled,
  loader,
  loaderColor,
  className,
  margin,
  icon,
  endIcon,
  noborder,
  minWidth = 'auto',
}) => {
  const classes = useStyles({
    invert,
    marginLeft,
    height,
    width,
    padding,
    invertWithColor,
    deleteButtonText,
    deleteButtonBackground,
    cancelButtonBackground,
    disabled,
    margin,
    noborder,
    minWidth,
  });

  return (
    <button
      ref={buttonRef}
      className={`${classes.buttonContainer} ${className}`}
      onClick={onClick}
      disabled={disabled}>
      {loader ? (
        <div style={{width: '15px', margin: 'auto'}}>
          <CircularProgress
            style={{
              height: '15px',
              width: '15px',
              color: `${loaderColor || 'white'}`,
            }}
          />
        </div>
      ) : (
        <div>
          {icon}
          <LocalText>{text}</LocalText>
          {endIcon}
        </div>
      )}
    </button>
  );
};
