import React from 'react';
import {ButtonBase} from '@material-ui/core';
import TextComponent from '../../../components/textComponent/TextComponent';
import IconComponent from '../../../components/dropDownMenu/iconComponents/IconComponent';
import TeamsIcon from '../../../assets/img/teamsIcon.svg';
import useStyles from '../menuComponents/menuComponentStyles';

export const EmptyPlaceholderComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.emptyPlaceholderComponent}>
      <IconComponent option={TeamsIcon} />
      <TextComponent
        placeholder={
          'No teams were found. Teams are great for sharing documents to group of users.'
        }
      />
    </div>
  );
};
