import {createStore, applyMiddleware, compose} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import rootReducer from 'rb-redux/reducers/index';
import mapHeaderData from 'rb-redux/middlewares/mapHeaderData';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'tableLinks',
    'tasks',
    'dashboard',
    'table',
    'home',
    'miniApps',
    'integration',
    'miniAppsStore',
    'elasticDashboards',
    'organisation',
    'listColumns',
    'pushNotifications',
    'automation',
    'entryStatus',
  ],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(preloadedState) {
  const extraComposer = [];
  const middleware = [thunkMiddleware, mapHeaderData];

  if (process.env.NODE_ENV === 'development') {
    // const loggerMiddleware = require('redux-logger').createLogger();
    // middleware.push(loggerMiddleware);

    const Reactotron = require('../ReactotronConfig').default;
    extraComposer.push(Reactotron.createEnhancer());
  }

  const store = createStore(
    persistedReducer,
    preloadedState,
    compose(applyMiddleware(...middleware), ...extraComposer),
  );
  return {
    store,
    persistor: persistStore(store),
  };
}
