import React from 'react';
import Button from '@material-ui/core/Button';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import Logo from '../assets/img/header-logo.svg';
import {captureError} from 'rb-redux/imports';
import {parseAndReturnShareURLParams} from 'rb-redux/utils/rowShareUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 24,
  },
  text: {
    textAlign: 'center',
  },
  img: {
    width: 'clamp(100px,50%, 200px)',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

/**
 * Determine if Operating System is IOS.
 * @returns {Boolean}
 */
function checkIfIOS() {
  try {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }
  } catch (error) {
    captureError(error);
  }

  return false;
}

function SmallScreen() {
  const classes = useStyles();

  const isIOS = checkIfIOS();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const allQueryParams = parseAndReturnShareURLParams(urlParams).length
    ? parseAndReturnShareURLParams(urlParams)
    : '?' + urlParams.toString();
  const isProd = ['web.lio.io', 'pre-release'].some((item) =>
    window.location.host.includes(item),
  );
  const url = isIOS
    ? `lioapp://app/${allQueryParams}`
    : `intent://app/${allQueryParams}#Intent;scheme=lioapp;package=com.swific.registerbook${
        isProd ? '' : '.debug'
      };end;`;

  return (
    <div className={classes.container}>
      <img src={Logo} alt="app logo" className={classes.img} />
      <Typography variant="h4" color="textSecondary" className={classes.text}>
        This page is best viewed in desktop
      </Typography>
      <Button color="primary" variant="contained">
        <a className={classes.link} href={url}>
          {' '}
          open app{' '}
        </a>
      </Button>
    </div>
  );
}

export default SmallScreen;
