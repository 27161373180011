import {isEmpty} from 'lodash';
import {TABLE_LINKS_ACTION} from '../actions/actionType';

const initialState = {
  search: {
    isActive: false,
    isLoading: false,
    tableData: [],
  },
  parentFileDocData: {},
  areAllParentDocumentRowsFetched: {},
  autoFillLinkedFiles: {},
  parentRowMeta: {},
  isFetchingParentData: false,
  activeParentDoc: {
    docId: '',
    fileName: '',
    docData: {},
  },
  columnDependencyMapping: {},
  columnDependencyArrObj: {},
  previousParentData: {},
  activeChildData: {
    colData: {},
    rowId: '',
    parentRowMeta: '',
    linkedData: {},
  },
  prevState: {},
  forceStopDependencyArrUpdate: false, // Flag to force stop dependency arr/obj update even if the action has been called
  isParentDataFetched: false,
};

const tableLinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case TABLE_LINKS_ACTION.COPY_PREVIOUS_TABLE_LINK_STATE: {
      return {
        ...state,
        prevState: Object.assign({}, state, {prevState: {}}),
      };
    }
    case TABLE_LINKS_ACTION.SET_ROW_FETCH_FLAG: {
      return {
        ...state,
        areAllParentDocumentRowsFetched: Object.assign(
          {},
          state.areAllParentDocumentRowsFetched,
          action.payload,
        ),
      };
    }
    case TABLE_LINKS_ACTION.UPDATE_COLUMN_DEPENDENCY_MAPPING: {
      return {
        ...state,
        columnDependencyMapping: action.payload?.dependencyObj ?? {},
        columnDependencyArrObj: action.payload?.dependencyArrObj ?? {},
      };
    }
    case TABLE_LINKS_ACTION.SHOW_LOADER_ON_DATA_FETCH: {
      return {
        ...state,
        isFetchingParentData: action.payload,
      };
    }
    case TABLE_LINKS_ACTION.REAPPLY_PREV_STATE: {
      if (!isEmpty(state.prevState)) {
        return state.prevState;
      }
      return {
        ...state,
      };
    }
    case TABLE_LINKS_ACTION.CLEAR_TABLE_LINK_STATE: {
      return {
        ...initialState,
      };
    }
    case TABLE_LINKS_ACTION.UPDATE_PARENT_ROW_META: {
      return {
        ...state,
        parentRowMeta: {...action.payload},
      };
    }
    case TABLE_LINKS_ACTION.FETCH_PARENT_DOC_DATA: {
      return {
        ...state,
        parentFileDocData: {...state.parentFileDocData, ...action.payload},
      };
    }
    case TABLE_LINKS_ACTION.FETCH_AUTOLINKED_DATA: {
      return {
        ...state,
        autoFillLinkedFiles: {...action.payload},
      };
    }

    case TABLE_LINKS_ACTION.UPDATE_ACTIVE_STATE: {
      return {
        ...state,
        search: {
          isActive: false,
          isLoading: false,
          tableData: [],
        },
        activeChildData: Object.assign(
          {},
          state.activeChildData,
          action.payload.activeChildData,
        ),
        activeParentDoc: Object.assign(
          {},
          state.activeParentDoc,
          action.payload.activeParentDoc,
        ),
      };
    }

    case TABLE_LINKS_ACTION.UPDATE_PREVIOUS_PARENT_DATA: {
      return {
        ...state,
        previousParentData: action.payload,
      };
    }

    case TABLE_LINKS_ACTION.UPDATE_PARENT_TABLE_DATA: {
      const payload = action.payload;
      const tableData = payload.tableData;
      const parentDocId = payload.parentDocId;
      return {
        ...state,
        parentFileDocData: {
          ...state.parentFileDocData,
          [parentDocId]: {...state.parentFileDocData[parentDocId], tableData},
        },
      };
    }
    case TABLE_LINKS_ACTION.SET_SEARCH_LOADING: {
      return {
        ...state,
        search: Object.assign({}, state.search, action.payload),
      };
    }
    case TABLE_LINKS_ACTION.SET_SEARCH_ACTIVE: {
      return {
        ...state,
        search: Object.assign({}, state.search, {isActive: true}),
      };
    }
    case TABLE_LINKS_ACTION.SET_SEARCH_RESULT: {
      return {
        ...state,
        search: Object.assign({}, state.search, action.payload),
      };
    }
    case TABLE_LINKS_ACTION.CLEAR_SEARCH: {
      return {
        ...state,
        search: {
          isActive: false,
          isLoading: false,
          tableData: [],
        },
      };
    }
    case TABLE_LINKS_ACTION.CLEAR_COLUMN_DEPENDENCY_MAPPING: {
      return {
        ...state,
        columnDependencyMapping: {},
        columnDependencyArrObj: {},
      };
    }
    case TABLE_LINKS_ACTION.UPDATE_FORCE_STOP_DEPENDENCY_ARR_UPDATE: {
      return {
        ...state,
        forceStopDependencyArrUpdate: action.payload,
      };
    }
    case TABLE_LINKS_ACTION.UPDATE_PARENT_DATA_FETCHED_FLAG: {
      return {
        ...state,
        isParentDataFetched: action.payload,
      };
    }
    default:
      return state;
  }
};

export default tableLinkReducer;
