import {
  isEqual,
  isArray,
  isEmpty,
  isObject,
  isNil,
  isString,
  capitalize,
} from 'lodash';
import {shareRowOnWeb} from 'rb-redux/actions/tableAction';
import ColorValues from 'values.js';
import moment from 'moment';
import {Flip, toast} from 'react-toastify';
import {DATE_FORMAT, REMINDER_FORMAT, TIME_FORMAT} from '../appConstants';
import firebase from 'firebase';
import {getHeaderDataAsObj} from 'rb-redux/actions/actionHelpers/listColumnsActionHelper';
import {
  formatCurrency,
  formatUnit,
  getAssignTaskAsPlainText,
  getColumnFieldType,
  getCreatedInfoCellValue,
  getLocalText,
  getUrlAsPlainText,
} from 'rb-redux/utils/utils';
import {captureError, openLink, ShowToast} from 'rb-redux/imports';
import {WHATSAPP_SUPPORT_NUMBER} from './contentProvider';
import {
  CLOUD_FUNCTION_PATHS,
  FIELD_TYPE_ID,
  ORG_MEMBER_PROFILE_FIELDS,
} from 'rb-redux/utils/constant';
import {routes} from '../routes/routes';
import {
  CALENDAR_VIEW_EVENT_COLOR,
  CALENDAR_VIEW_TYPE,
  MINIAPP_VIEW_STATES_SUBTYPE,
} from './constant';
import {logAnalyticsEvent} from 'rb-redux/imports';
import {showCustomToast} from '../components/CustomToast/CustomToastWrapper';
import {AUTOMATION_COLUMN_VALUE_TYPES} from 'rb-redux/utils/automationConstants';
import {ReactComponent as ImportIcon} from '../assets/img/importIcon.svg';
import {ReactComponent as ExportIcon} from '../assets/img/exportIcon.svg';
import {ReactComponent as GreenArrowIcon} from '../assets/img/greenArrowIcon.svg';
import {ReactComponent as RunningAutomationIcon} from '../assets/img/runningAutomationIcon.svg';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {SERVICE_TYPE} from 'rb-redux/utils/notifyUsers/notificationConstant';
import JSZip from 'jszip';
import {getAllFilesContentAsZip} from 'rb-redux/actions/actionHelpers/miniAppsActionHelper';

export const findColumnIndex = (headerData, colId) => {
  return headerData.findIndex((colObj) => colId == colObj.id);
};

export const processFilterObj = (filterObj, headerData) => {
  return Object.entries(filterObj).map((item) => {
    const fieldType = getColumnFieldType(
      headerData.find((obj) => obj.id === item[0]),
    );
    return {
      colId: item[0],
      selectedOptions: item[1],
      fieldType: fieldType,
      ...([FIELD_TYPE_ID.ASSIGN_TASK, FIELD_TYPE_ID.CREATED_INFO].includes(
        fieldType,
      )
        ? {isCustom: true}
        : {}),
    };
  });
};

export const isValEmpty = (val) => {
  return (
    val === '' ||
    isEqual(val, []) ||
    isEqual(val, {}) ||
    isEqual(val, {
      reminderMessage: '',
      reminderId: '',
      val: '',
      notifyWhatsapp: false,
    }) ||
    isEqual(val, {val: '', customText: undefined}) ||
    isEqual(val, {val: ''}) ||
    isEqual(val, {coordinate: {latitude: '', longitude: ''}, val: ''}) ||
    typeof val === 'undefined' ||
    val === null
  );
};

export const getShortenedString = (text, shortenedLength = 30) => {
  try {
    return isNil(text)
      ? ''
      : `${text.slice(0, shortenedLength)}${
          text.length > shortenedLength ? '...' : ''
        }`;
  } catch (error) {
    captureError(error);
  }
};

export const capitalizeString = (text) => {
  try {
    if (!text) return '';
    const lower = text.toLowerCase();
    return text.charAt(0).toUpperCase() + lower.slice(1);
  } catch (error) {
    captureError(error);
  }
};

export const isOverdue = (dueDate) => {
  try {
    return moment.unix(dueDate).diff(moment(), 'days') < 0 ? true : false;
  } catch (error) {
    captureError(error);
  }
};

export const getDateInFormat = (unixDate, format) => {
  return moment(moment.unix(unixDate)).format(format);
};

export const updateTextColor = (colorHexValue) => {
  let lightColor = new ColorValues(colorHexValue);
  let textColor =
    lightColor.getBrightness() < 50
      ? lightColor.tint(85).rgbString()
      : lightColor.shade(85).rgbString();
  return textColor;
};
export const getNoOfLines = (rowNode, colId, maxLettersInALine, colType) => {
  let contactValue;

  if (colType === 'CONTACT') {
    if (rowNode.data[colId]?.contactName) {
      contactValue =
        rowNode.data[colId]?.val +
        rowNode.data[colId]?.contactName +
        'some extra text';
    } else {
      contactValue = rowNode.data[colId]?.val;
    }
  }
  if (colType === 'URL') {
    const value = rowNode && rowNode.data[colId] ? rowNode.data[colId] : '';
    if (value?.customText != '') {
      contactValue = isString(value?.val)
        ? value?.val?.slice(0, parseInt(value?.val?.length / 3)) +
          value?.customText +
          'some sameple text'
        : value?.val + value?.customText + 'some sameple text';
    } else {
      contactValue = value?.val;
    }
  }
  if (colType === 'ASSIGN_TASK') {
    if (rowNode.data[colId]?.val) {
      contactValue =
        rowNode.data[colId]?.val.assignee?.name +
        (rowNode.data[colId]?.val.dueDate !== null ||
        rowNode.data[colId]?.val.priority !== null ||
        rowNode.data[colId]?.val.note !== null
          ? 'DD MMM YYYY'
          : 0);
    } else if (rowNode.data[colId]?.assignee) {
      contactValue =
        rowNode.data[colId]?.assignee?.name +
        (rowNode.data[colId]?.dueDate !== null ||
        rowNode.data[colId]?.priority !== null ||
        rowNode.data[colId]?.note !== null
          ? 'DD MMM YYYY'
          : 0);
    } else {
      contactValue = rowNode.data[colId]?.val;
    }
  }
  if (colType === 'REMINDER') {
    if (rowNode.data[colId]?.reminderMessage) {
      contactValue =
        moment.unix(rowNode.data[colId]?.val).format(REMINDER_FORMAT) +
        rowNode.data[colId]?.reminderMessage?.slice(0, 25);
    } else {
      contactValue = moment
        .unix(rowNode.data[colId]?.val)
        .format(REMINDER_FORMAT);
    }
  }
  if (colType === 'CREATED_INFO') {
    contactValue =
      rowNode.rowProperties?.createdInfoContactName +
      moment(rowNode.rowProperties?.createdTimestamp).format(
        'MMMM Do YYYY, h:mm:ss a',
      );
  }

  let noOfLines = 0;
  let value =
    colType === 'CONTACT' ||
    colType === 'REMINDER' ||
    colType === 'ASSIGN_TASK' ||
    colType === 'URL' ||
    colType === 'CREATED_INFO'
      ? contactValue
      : rowNode.data[colId]?.val;
  let bufferValue = '';
  let extraSpace = 22;
  let standardInputBufferSpace = Math.floor(
    maxLettersInALine * 10 - extraSpace,
  );
  let lineSpaceRemaining = standardInputBufferSpace;

  if (value && value.length > 0) {
    for (const i of value) {
      bufferValue += i;

      if (
        /[A-Z]/g.test(i) ||
        /[0-9]/g.test(i) ||
        /[^\w\s]/.test(i) ||
        i === ' '
      ) {
        lineSpaceRemaining -= 13.398;
      } else {
        lineSpaceRemaining -= 10.7;
      }
      if (lineSpaceRemaining < 13.398) {
        lineSpaceRemaining = standardInputBufferSpace;
        noOfLines += 1;
      }
      if (bufferValue.slice(-1) === '\n') {
        noOfLines += 1;
      }
    }
  }
  if (colType === 'REMINDER' && rowNode.data[colId]?.reminderMessage < 25) {
    return noOfLines + 1;
  }
  return noOfLines;
};

export const getTextHeight = (rowNode, colId, maxLettersInALine, colType) => {
  let totalHeightRequired = 21;
  let noOfLines = getNoOfLines(rowNode, colId, maxLettersInALine, colType);
  let heighBufferOne = noOfLines * 21;
  totalHeightRequired = heighBufferOne;
  return totalHeightRequired;
};

export const getLabelHeight = (rowNode, colId, maxLettersInALine) => {
  let labelFlairArray =
    (isArray(rowNode?.data[colId]?.val) &&
      rowNode?.data[colId]?.val?.slice(0, 3)) ||
    [];
  let totalLabelHeightRequired = 0;
  let extraBoxBufferLetters = 5;
  let extraBoxHeight = 35;
  let lineSpaceRemaining = maxLettersInALine;

  if (isArray(labelFlairArray) && labelFlairArray.length > 0) {
    labelFlairArray?.reduce((total, label) => {
      let noOfLines = 1;

      let labelHeightBuffer = 0;

      let labelLength = label?.val?.length + extraBoxBufferLetters;

      if (labelLength > maxLettersInALine) {
        noOfLines =
          Math.floor(labelLength / maxLettersInALine) +
          (labelLength % maxLettersInALine ? 1 : 0);

        totalLabelHeightRequired += noOfLines * 14 + extraBoxHeight;
      } else {
        if (lineSpaceRemaining < labelLength) {
          lineSpaceRemaining = maxLettersInALine;

          totalLabelHeightRequired += extraBoxHeight + 14;
        }

        if (labelLength < lineSpaceRemaining) {
          lineSpaceRemaining -= labelLength;
        }
      }

      return total;
    }, 0);
  }

  return totalLabelHeightRequired;
};

// export const dynamicRowHeightGetter = (rowNode, columnsData) => rowNode && rowNode.data && columnsData && Object.keys(rowNode?.data).reduce((rowHeightArray, colId) => {

//
//   let column = columnsData?.displayedColumns?.find((col) => col?.colId == colId);
//   let columnWidth = column?.actualWidth || column?.colDef?.styleObj?.style?.htmlObj?.cell?.width || 248;
//   let maxLettersInALine = columnWidth * (0.1)

//   const col_type = columnsData?.columnDefs?.find(({ id }) => id == colId);

//   const colType = col_type?.fieldType === 'TABLE' ? col_type?.subType :  col_type?.fieldType;

//   if (!isEmpty(rowNode.data[colId]) && rowNode.data[colId]?.val) {

//     if ((colType === "TEXT" ) && typeof (rowNode.data[colId]?.val) === 'string') {

//       let totalHeightRequired = getTextHeight(rowNode, colId, maxLettersInALine)
//       rowHeightArray = [...rowHeightArray, totalHeightRequired ? totalHeightRequired + 30 : 42]
//     }
//     else if (colType === "SELECT_POP_UP") {

//       let totalHeightRequired = getTextHeight(rowNode, colId, maxLettersInALine)
//       rowHeightArray = [...rowHeightArray, totalHeightRequired ? totalHeightRequired + 30 : 42]
//     }
//     else if (colType === "MAPS") {
//       let totalHeightRequired = getTextHeight(rowNode, colId, maxLettersInALine)
//       rowHeightArray = [...rowHeightArray, totalHeightRequired ? totalHeightRequired + 30 : 42]
//     }
//     else if (colType === "CONTACT") {
//       let totalHeightRequired = getTextHeight(rowNode, colId, maxLettersInALine, colType)
//       // console.log(totalHeightRequired)
//       rowHeightArray = [...rowHeightArray, totalHeightRequired ? totalHeightRequired + 30 : 42]
//     }
//     else if (colType === "BARCODE") {
//       rowHeightArray = [...rowHeightArray, Math.ceil(rowNode.data[colId]?.val?.length / maxLettersInALine) * 22 || 42]
//     }
//     else if (colType === "REMINDER") {

//       let totalHeightRequired = getTextHeight(rowNode, colId, maxLettersInALine)
//       rowHeightArray = [...rowHeightArray, totalHeightRequired ? totalHeightRequired + 30 : 42]
//     }
//     else if (colType === "LABEL") {

//       let totalLabelHeightRequired = getLabelHeight(rowNode, colId, maxLettersInALine);

//       rowHeightArray = [...rowHeightArray, totalLabelHeightRequired ? totalLabelHeightRequired + 35 : 42]

export const dynamicRowHeightGetter = (rowNode, columnsData) =>
  rowNode &&
  rowNode.data &&
  columnsData &&
  Object.keys(rowNode?.data).reduce((rowHeightArray, colId) => {
    let column = columnsData?.displayedColumns?.find(
      (col) => col?.colId == colId,
    );
    let columnWidth =
      column?.actualWidth ||
      column?.colDef?.styleObj?.style?.htmlObj?.cell?.width ||
      248;
    let maxLettersInALine = columnWidth * 0.1;

    const col_type = columnsData?.columnDefs?.find(({id}) => id == colId);

    const colType =
      col_type?.fieldType === 'TABLE' ? col_type?.subType : col_type?.fieldType;

    if (
      (!isEmpty(rowNode.data[colId]) && rowNode.data[colId]?.val) ||
      (colType === 'ASSIGN_TASK' &&
        !rowNode.data[colId]?.val &&
        rowNode.data[colId]?.assignee)
    ) {
      if (colType === 'TEXT' && typeof rowNode.data[colId]?.val === 'string') {
        let totalHeightRequired = getTextHeight(
          rowNode,
          colId,
          maxLettersInALine,
        );
        rowHeightArray = [
          ...rowHeightArray,
          totalHeightRequired ? totalHeightRequired + 30 : 42,
        ];
      } else if (colType === 'SELECT_POP_UP') {
        let totalHeightRequired = getTextHeight(
          rowNode,
          colId,
          maxLettersInALine,
        );
        rowHeightArray = [
          ...rowHeightArray,
          totalHeightRequired ? totalHeightRequired + 30 : 42,
        ];
      } else if (colType === 'MAPS') {
        let totalHeightRequired = getTextHeight(
          rowNode,
          colId,
          maxLettersInALine,
        );
        rowHeightArray = [
          ...rowHeightArray,
          totalHeightRequired ? totalHeightRequired + 30 : 42,
        ];
      } else if (colType === 'CONTACT' || colType === 'URL') {
        let totalHeightRequired = getTextHeight(
          rowNode,
          colId,
          maxLettersInALine,
          colType,
        );

        rowHeightArray = [
          ...rowHeightArray,
          totalHeightRequired ? totalHeightRequired + 30 : 42,
        ];
      } else if (colType === 'ASSIGN_TASK') {
        rowHeightArray = [...rowHeightArray, 72];
      } else if (colType === 'BARCODE') {
        rowHeightArray = [
          ...rowHeightArray,
          Math.ceil(rowNode.data[colId]?.val?.length / maxLettersInALine) *
            22 || 42,
        ];
      } else if (colType === 'CREATED_INFO') {
        let totalHeightRequired = getTextHeight(
          rowNode,
          colId,
          maxLettersInALine,
          colType,
        );
        rowHeightArray = [
          ...rowHeightArray,
          totalHeightRequired ? totalHeightRequired + 15 : 42,
        ];
      } else if (colType === 'REMINDER') {
        let totalHeightRequired = getTextHeight(
          rowNode,
          colId,
          maxLettersInALine,
          colType,
        );
        rowHeightArray = [
          ...rowHeightArray,
          totalHeightRequired ? totalHeightRequired + 30 : 42,
        ];
      } else if (colType === 'LABEL') {
        let totalLabelHeightRequired = getLabelHeight(
          rowNode,
          colId,
          maxLettersInALine,
        );

        rowHeightArray = [
          ...rowHeightArray,
          totalLabelHeightRequired ? totalLabelHeightRequired + 35 : 42,
        ];
      } else {
        rowHeightArray = [...rowHeightArray, 42];
      }
    } else {
      rowHeightArray = [...rowHeightArray, 42];
    }
    return rowHeightArray;
  }, []);

export const dynamicRowHeightSetter = ({rowHeightArray}) => {
  let maximumHeight = Math.max(...rowHeightArray) || 0;
  if (maximumHeight <= 42) return 42;
  const extraHeightRequired = maximumHeight + 15;
  const finalHeight = extraHeightRequired < 42 ? 42 : extraHeightRequired;
  return finalHeight;
};

export const getFooterData = (state) => {
  // just returns {[colId]:val},
  // returning '' to avoid flickering on every data change
  let footerRowVals = {};
  state.table.headerData.forEach((item) => {
    let colId = item.id;
    footerRowVals[colId] = {
      val: '',
    };
  });
  return footerRowVals;
};

export const generatePdfObject = ({
  table,
  isFiltered,
  filter,
  gridApi,
  activeDocumentMeta,
  isPremiumUser,
  tableSearch = {},
}) => {
  const {headerData, footerData, fileObj} = table;

  const bufferValue = isFiltered
    ? filter.originalTableData
    : tableSearch.isTableSearchActive
    ? tableSearch.tableData
    : table.tableData;

  const tableData = getSelectedDataToPrintOrShare(bufferValue, gridApi);

  return {
    headerData,
    footerData,
    tableData,
    fileObj,
    metaData: activeDocumentMeta,
    isBlockViewActive: activeDocumentMeta.viewType === 'block' ? true : false,
    isPremiumUser,
  };
};
export const getSelectedDataToPrintOrShare = (tableData, gridApi) => {
  /*
    if any row is selected , filter out unselected rows
    else continue with full table data
  */
  const selectedNodes = gridApi?.getSelectedNodes();
  if (selectedNodes?.length > 0) {
    const selectedTableData = [];
    selectedNodes.forEach((node) => {
      const index = node.rowIndex;
      selectedTableData.push(node.data);
    });
    return selectedTableData;
  }
  return tableData;
};

export const shareRowOnWhatsapp = async (userDataObj) => {
  const {
    tableData,
    userPref,
    gridApi,
    setNotification,
    shareRowOnWeb,
    isFiltered,
    filter,
  } = userDataObj;

  const bufferValue = isFiltered ? filter?.tableData : tableData;

  const isNoDataAvailable = checkIfNoDataInRow(
    getSelectedDataToPrintOrShare(bufferValue, gridApi),
  );

  if (isNoDataAvailable) {
    setNotification({show: true, msg: 'No data present to share.'});
    setTimeout(() => {
      setNotification({show: false, msg: ''});
    }, 3000);
  } else {
    await shareRowOnWeb({
      userCountry: userPref?.country,
      userPref,
      processedtableData: getSelectedDataToPrintOrShare(tableData, gridApi),
    });
  }
};

export const isRowEmpty = (obj) => {
  const {data} = obj;
  let isempty = true;
  let x =
    data &&
    !isEmpty(data) &&
    Object.keys(data)?.reduce((total, col) => {
      if (
        isObject(data?.[col]) &&
        !isEmpty(data?.[col]) &&
        'val' in data?.[col]
      ) {
        if (isArray(data?.[col]?.val)) {
          isempty = data?.[col]?.val?.length !== 0 ? false : true;
        } else if (data?.[col]?.val !== '') {
          isempty = false;
        }
      }
      return (total &= isempty);
    }, true);
  return x;
};

export const checkIfNoDataInRow = (data) =>
  data?.reduce((total, row) => (total &= isRowEmpty({data: row})), true);

export const getFieldValueText = (
  fieldType,
  fieldObj,
  unitObj = {},
  userPref = {},
  rowObj,
) => {
  try {
    let value = fieldObj?.val;
    if (
      (value === '' || isNil(value)) &&
      fieldType !== FIELD_TYPE_ID.CREATED_INFO
    )
      return '';
    switch (fieldType) {
      case 'IMAGE': {
        if (!isArray(value)) return '';
        const text =
          value?.length === 1
            ? getLocalText(userPref, 'Image')
            : getLocalText(userPref, 'Images');
        return value?.length > 0 ? `${value.length} ${text}` : ``;
      }
      case 'DOCUMENT': {
        if (!isArray(value)) return '';
        const text =
          value?.length === 1
            ? getLocalText(userPref, 'Attachment')
            : getLocalText(userPref, 'Attachments');
        return value?.length > 0 ? `${value.length} ${text}` : ``;
      }
      case 'AUDIO':
        {
          if (!isArray(value)) return '';
          const text =
            value?.length === 1
              ? getLocalText(userPref, 'Audio File')
              : getLocalText(userPref, 'Audio Files');
          return value?.length > 0 ? `${value.length} ${text}` : ``;
        }
        break;
      case 'FORMULA': {
        if (Object?.keys(value)?.includes('minutes')) {
          return `${value?.hours > 0 ? `${value?.hours} hours` : ''} ${
            value?.minutes > 0 ? `${value?.minutes} minutes` : ''
          }`;
        }
        if (Object?.keys(value)?.includes('days')) {
          return `${value?.years > 0 ? `${value?.years} years` : ''} ${
            value?.months > 0 ? `${value?.months} months` : ''
          } ${value?.days > 0 ? `${value?.days} days` : ''}`;
        }

        if (Object?.keys(value)?.includes('val')) {
          return '';
        }
        return value;
      }
      case 'CONTACT': {
        return fieldObj?.contactName
          ? `${value} (${fieldObj.contactName})`
          : `${value}`;
      }
      case 'SELECT_POP_UP': {
        return value;
      }
      case 'DATE': {
        if (isArray(value)) {
          return '';
        }
        const formattedDate = moment(moment.unix(value)).format(DATE_FORMAT);
        if (formattedDate == 'Invalid date') {
          return '';
        }
        return formattedDate;
      }
      case 'TIME': {
        if (isArray(value)) {
          return '';
        }
        const formattedTime = moment(moment.unix(value)).format(TIME_FORMAT);
        if (formattedTime == 'Invalid date') {
          return '';
        }
        return formattedTime;
      }
      case 'RUPEE': {
        if (isArray(value)) {
          return '';
        }
        return formatCurrency(value);
      }
      case 'UNIT': {
        if (isArray(value)) {
          return '';
        }

        return `${formatUnit(value, unitObj)}`;
      }
      case 'REMINDER': {
        const message = fieldObj?.reminderMessage || '';
        const timeStamp = value && moment.unix(value);
        if (moment(timeStamp).format(REMINDER_FORMAT) == 'Invalid date') {
          return '';
        }
        return message !== ''
          ? moment(timeStamp).format(REMINDER_FORMAT) + ': "' + message + '"'
          : moment(timeStamp).format(REMINDER_FORMAT);
      }
      case 'LABEL': {
        if (!isArray(value)) {
          return '';
        }
        if (
          isArray(value) &&
          value &&
          value[0] &&
          !Object.keys(value[0]).includes('displayName')
        ) {
          return '';
        }
        return value?.map((label) => label.val).join(', ');
      }
      case 'ASSIGN_TASK': {
        if (isArray(value)) {
          return '';
        }
        return getAssignTaskAsPlainText(value, userPref);
      }
      case 'URL': {
        if (isArray(value)) {
          return '';
        }
        return getUrlAsPlainText(fieldObj);
      }
      case 'SWITCH': {
        if (isArray(value)) {
          return '';
        }
        return value
          ? getLocalText(userPref, 'Yes')
          : getLocalText(userPref, 'No');
      }
      case 'CHECKBOX': {
        if (isArray(value)) {
          return '';
        }
        return value
          ? getLocalText(userPref, 'True')
          : getLocalText(userPref, 'False');
      }
      case 'CREATED_INFO': {
        return getCreatedInfoCellValue(
          value,
          rowObj.rowProperties,
          false,
          false,
          ', ',
        );
      }
      default:
        if (isObject(value) && isEmpty(value)) return '';
        return value;
    }
  } catch (error) {
    captureError(error);
  }
};

export const sendWhatsappMessageWithText = (helperText, userPref = {}) => {
  try {
    const number = WHATSAPP_SUPPORT_NUMBER;
    const whatsappText = getLocalText(userPref, helperText);
    openLink(`https://wa.me/${number}?text=${whatsappText}`);
  } catch (error) {
    captureError(error);
  }
};

export const getOriginalRowIndex = (
  displayRowIndex,
  tableSearch = null,
  filter = null,
) => {
  let rowIndex = displayRowIndex;
  if (tableSearch?.isTableSearchActive) {
    // when user is searching
    rowIndex = tableSearch.tableData[rowIndex]?.tableIndex;
  } else if (filter?.isFilterEnabled) {
    rowIndex = filter.tableData[rowIndex]?.tableIndex;
  }
  return rowIndex;
};

export const navigateToSubscriptions = (changePlan = true) => {
  if (changePlan) {
    // Navigate to subscriptions and open 'Change Plan' modal
    window.location.replace(
      `${routes.PROFILE}/?menu=SUBSCRIPTION&action=CHANGE_PLAN`,
    );
  } else {
    window.location.replace(`${routes.PROFILE}/?menu=SUBSCRIPTION`);
  }
};
export function encodeString(decodedStr = '') {
  let encodedStr = '';
  for (let i = 0; i < decodedStr.length; i++) {
    const charCode = decodedStr.charCodeAt(i);
    const encodedCharCode = charCode + 10;
    encodedStr += String.fromCharCode(encodedCharCode);
  }
  return encodedStr;
}

export function decodeString(encodedStr = '') {
  let decodedStr = '';
  for (let i = 0; i < encodedStr?.length; i++) {
    const encodedCharCode = encodedStr.charCodeAt(i);
    const charCode = encodedCharCode - 10;
    decodedStr += String.fromCharCode(charCode);
  }
  return decodedStr;
}

export function encodeStringNew(decodedStr = '') {
  let encodedStr = '';
  for (let i = 0; i < decodedStr.length; i++) {
    const charCode = decodedStr.charCodeAt(i);
    const encodedCharCode = charCode + 4;
    encodedStr += String.fromCharCode(encodedCharCode);
  }
  return encodedStr;
}

export function decodeStringNew(encodedStr = '') {
  let decodedStr = '';
  for (let i = 0; i < encodedStr?.length; i++) {
    const encodedCharCode = encodedStr.charCodeAt(i);
    const charCode = encodedCharCode - 4;
    decodedStr += String.fromCharCode(charCode);
  }
  return decodedStr;
}
export const PDF_TEXT_STYLE = {
  BOLD: 'bold',
  ITALICS: 'italics',
};

export const PDF_PREFIX = {
  CUSTOM: 'CUSTOM',
  NONE: 'NONE',
  COLUMN_NAME: 'COLUMN_NAME',
};

export const PDF_IMAGE_SIZE = {
  EXTRA_SMALL: 'EXTRA_SMALL',
  SMALL: 'SMALL',
  REGULAR: 'REGULAR',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
  EXTRA_LARGE: 'EXTRA_LARGE',
};

export const getMapDirection = async ({val, coordinate, userPref, source}) => {
  try {
    logAnalyticsEvent('GET_DIRECTIONS', {source});
    const latLng = `${coordinate.latitude},${coordinate.longitude}`;
    const label = val || '';
    const webURL = 'https://www.google.de/maps/@' + latLng + '?q=' + label;
    try {
      await openLink(webURL);
    } catch (error) {
      captureError(error);
    }
  } catch (error) {
    ShowToast('Something went wrong, please try again', userPref);
    captureError(error);
  }
};
/**
 *
 * @param {string} givenDateTime a utc timestamp
 * @returns string
 */
export function displayDateTimeToCurrentTimezone(givenDateTime) {
  const givenDate = new Date(givenDateTime);
  const currentTimezoneOffset = new Date().getTimezoneOffset();
  givenDate.setMinutes(givenDate?.getMinutes() - currentTimezoneOffset);
  const adjustedDateTime = givenDate.toLocaleString('en-IN', {
    timeZone: 'UTC',
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });

  return adjustedDateTime === 'Invalid Date' ? '' : adjustedDateTime;
}

export const getPdfConfigIdFromHeaderData = (headerData = []) => {
  const pdfObject = headerData?.find(
    (colObj) => colObj.fieldType === FIELD_TYPE_ID.PDF,
  );
  return pdfObject?.pdfConfigId;
};

export const COLUMNS_NOT_ALLOWED_VIEW_ROW_DETAILS = [FIELD_TYPE_ID.PDF];

export function getBrowserType(userAgent) {
  const test = (regexp) => {
    return regexp.test(userAgent);
  };

  if (test(/opr\//i)) {
    return 'Opera';
  } else if (test(/edg/i)) {
    return 'Microsoft Edge';
  } else if (test(/chrome|chromium|crios/i)) {
    return 'Google Chrome';
  } else if (test(/firefox|fxios/i)) {
    return 'Mozilla Firefox';
  } else if (test(/safari/i)) {
    return 'Apple Safari';
  } else if (test(/trident/i)) {
    return 'Microsoft Internet Explorer';
  } else if (test(/ucbrowser/i)) {
    return 'UC Browser';
  } else if (test(/samsungbrowser/i)) {
    return 'Samsung Browser';
  } else {
    return 'Unknown browser';
  }
}
export const getFilteredSectionHeaderData = (
  headerData = [],
  sectionHeader = [],
) => {
  const headerDataObj = getHeaderDataAsObj(headerData);
  const filteredSectionHeader = sectionHeader.map((item) => {
    const filteredColIds = item?.colIds?.filter(
      (colId) => headerDataObj?.[colId],
    );
    return {...item, colIds: filteredColIds};
  });
  return filteredSectionHeader ?? [];
};

export const shouldAlertModalVisible = (
  newRowData,
  oldRowData,
  headerData = [],
  listColumnsData = {},
) => {
  let showAlertModal = false;
  headerData.forEach((colObj) => {
    const {id: colId, fieldType} = colObj;

    if (fieldType === FIELD_TYPE_ID.LIST) {
      // Currently handled for only normal add/edit entry flow
      if (
        !isEmpty(listColumnsData?.listColumnMapping?.[colId]?.rowIdOpsMapping)
      ) {
        showAlertModal = true;
      }
    } else if (fieldType === FIELD_TYPE_ID.TABLE) {
      if (newRowData?.[colId]?.val !== oldRowData?.[colId]?.val) {
        showAlertModal = true;
      }
    } else if (fieldType === FIELD_TYPE_ID.ASSIGN_TASK) {
      if (
        newRowData?.[colId]?.val?.taskObj?.assignee?.uid !==
        oldRowData?.[colId]?.val?.assignee?.uid
      ) {
        showAlertModal = true;
      }
    } else if (oldRowData?.[colId]) {
      if (!isEqual(oldRowData?.[colId], newRowData?.[colId])) {
        showAlertModal = true;
      }
    } else {
      switch (true) {
        case Array.isArray(newRowData?.[colId]?.val) &&
          newRowData?.[colId]?.val.length > 0:
          return (showAlertModal = true);
        case newRowData?.[colId]?.val === true:
          return (showAlertModal = true);
        case typeof newRowData?.[colId]?.val === 'string' &&
          newRowData?.[colId]?.val.trim() !== '':
          return (showAlertModal = true);
        case typeof newRowData?.[colId]?.val === 'number' &&
          !isNaN(newRowData?.[colId]?.val):
          return (showAlertModal = true);
        default:
          break;
      }
    }
  });
  return showAlertModal;
};

export function getUsersWithAppAccessArr(
  currentUserUID,
  orgOwnerObj,
  usersWithAppAccessArr = [],
  membersListObj = {},
) {
  const usersArr = [];

  if (orgOwnerObj.m_uid && orgOwnerObj.m_uid !== currentUserUID) {
    usersArr.push({
      displayName:
        orgOwnerObj.m_name ||
        orgOwnerObj.phoneNumber ||
        orgOwnerObj.email ||
        '',
      phoneNumbers: orgOwnerObj.phoneNumber ? [orgOwnerObj.phoneNumber] : null,
      emailAddresses: orgOwnerObj.email ? [orgOwnerObj.email] : null,
      m_uid: orgOwnerObj.m_uid,
      contact: orgOwnerObj.phoneNumber || orgOwnerObj.email,
    });
  }

  usersArr.push(
    ...usersWithAppAccessArr.reduce((prev, id) => {
      const user = membersListObj?.[id];
      // logged in user is handled seperately:
      if (
        id === currentUserUID ||
        id === orgOwnerObj.m_uid ||
        !user ||
        isEmpty(user)
      )
        return prev;
      const userObj = {
        displayName: user.m_name || user.phoneNumber || user.email || '',
        phoneNumbers: user.phoneNumber ? [user.phoneNumber] : null,
        emailAddresses: user.email ? [user.email] : null,
        m_uid: id,
        contact: user.phoneNumber || user.email,
      };
      return [...prev, userObj];
    }, []),
  );

  return usersArr;
}

export const getProcessDateFilterRangeOption = (
  dateRangeFilterOptions = {},
) => {
  const processedDateRangeFilterOptions = {};
  Object.keys(dateRangeFilterOptions).forEach((filterOption) => {
    Object.assign(processedDateRangeFilterOptions, {
      [filterOption]: dateRangeFilterOptions[filterOption].map((types) => ({
        id: types?.toUpperCase(),
        title: types,
      })),
    });
  });
  return processedDateRangeFilterOptions;
};

export const getProcessedDateFilterQuickOptions = (
  dateFiltersQuickOptions = {},
) => {
  const processedDateQuickFilterOptions = {};

  Object.keys(dateFiltersQuickOptions).forEach((dateFormat) => {
    Object.assign(processedDateQuickFilterOptions, {
      [dateFormat]: dateFiltersQuickOptions[dateFormat].map(
        (types) => types.id,
      ),
    });
  });
  return processedDateQuickFilterOptions;
};

export const showAutomationRunningToast = (
  toastId = 'toast',
  automationMsg,
  isUpdate = false,
) => {
  showCustomToast({
    toastId,
    toastType: isUpdate ? 'UPDATE' : 'LOADING',
    message: automationMsg,
    position: 'bottom-right',
  });
};

export const showDeleteRowToast = (
  toastId = null,
  rowStatus = {},
  showInitialToast = false,
) => {
  if (showInitialToast) {
    toast.loading(<div>{`Delete Initiated`}</div>, {
      toastId,
      position: 'bottom-right',
      closeOnClick: false,
      draggable: false,
      isLoading: true,
      hideProgressBar: true,
    });
    return;
  }

  const isToastActive = (toastId) => toast?.isActive?.(toastId);
  const {isLastChunk, isProcessingRows} = Object.assign({}, rowStatus);
  const isSingleRowDelete = rowStatus?.totalRows === 1;

  !isToastActive(toastId)
    ? toast.info(
        <div>
          {isProcessingRows
            ? 'Processing Records for Delete'
            : `Deleting ${rowStatus.totalRows} ${
                isSingleRowDelete ? 'Record' : 'Records'
              } (${(
                (rowStatus.deletedRowsLenSoFar / rowStatus.totalRows) *
                100
              ).toFixed(2)}%)`}
        </div>,
        {
          toastId,
          position: 'bottom-right',
          closeOnClick: false,
          draggable: false,
          isLoading: isProcessingRows ? true : false,
          progress: rowStatus.deletedRowsLenSoFar / rowStatus.totalRows,
        },
      )
    : toast.update(toastId, {
        render: (
          <div>
            {isProcessingRows
              ? 'Processing Records for Delete'
              : isLastChunk
              ? `Deleted ${rowStatus.deletedRowsLenSoFar} ${
                  isSingleRowDelete ? 'Row' : 'Rows'
                } Successfully`
              : `Deleting ${rowStatus.totalRows} ${
                  isSingleRowDelete ? 'Record' : 'Records'
                } (${(
                  (rowStatus.deletedRowsLenSoFar / rowStatus.totalRows) *
                  100
                ).toFixed(2)}%)`}
          </div>
        ),
        toastId,
        position: 'bottom-right',
        closeOnClick: false,
        draggable: false,
        isLoading: isProcessingRows ? true : false,
        progress: rowStatus.deletedRowsLenSoFar / rowStatus.totalRows,
        type: isLastChunk ? toast.TYPE.SUCCESS : toast.TYPE.INFO,
        transition: isLastChunk ? Flip : null,
      });
};

export const closeToast = (toastId = null) => {
  toast.dismiss(toastId);
};
export const addExtraObjectsForSameDay = (
  dateRange,
  activeScreenDetails = {},
) => {
  const dateMap = new Map();
  const filterDateRange = []; // Declare filterDateRange using const

  for (let i = 0; i < dateRange.length; i++) {
    const {startDate, endDate} = dateRange[i];
    const dateKey = moment(startDate).format('DD/MM/YYYY').toString();
    const count = dateMap.get(dateKey) || 0;
    if (count < 2) {
      filterDateRange.push(dateRange[i]);
    }
    dateMap.set(dateKey, count + 1);
  }
  for (let i = 0; i < dateRange.length; i++) {
    const {startDate, endDate} = dateRange[i];
    const dateKey = moment(startDate).format('DD/MM/YYYY').toString();
    const count = dateMap.get(dateKey) || 0;
    if (count > 6) {
      dateMap.set(dateKey, 0);
      filterDateRange.push({
        start: moment(startDate)
          .set({hour: 23, minute: 58})
          .format('YYYY-MM-DDTHH:mm'),
        end: moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm'),
        title: `+  ${count - 6} More ${
          activeScreenDetails?.addItemText?.trim() ?? 'Event'
        }`,
        height: '22px',
        color: '#A9A9A9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        id: 'IS_MORE_EVENT_SHOW',
      });
    }
  }
  return filterDateRange;
};

export const generateDateRangeObjects = (
  colObj,
  title,
  startDate,
  endDate,
  index,
  viewState,
) => {
  const dateObjects = [];
  let currentDate = moment(startDate);
  const endTimeOnly =
    moment(endDate).format('HH:mm:ss') === '00:00:00'
      ? '23:59:00'
      : moment(endDate).format('HH:mm:ss');

  while (currentDate.isSameOrBefore(endDate, 'day')) {
    const startOfDay = currentDate
      .clone()
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm');
    const endOfDay = currentDate
      .clone()
      .endOf('day')
      .format('YYYY-MM-DDTHH:mm');

    const startDateFormatted = currentDate.isSame(startDate, 'day')
      ? moment(startDate).format('YYYY-MM-DD') +
        'T' +
        moment(startDate).format('HH:mm')
      : startOfDay;
    const endDateFormatted = currentDate.isSame(endDate, 'day')
      ? moment(endDate).format('YYYY-MM-DD') + 'T' + endTimeOnly
      : endOfDay;

    dateObjects.push({
      colValue: colObj?.val,
      title,
      start: new Date(startDateFormatted),
      end: new Date(endDateFormatted),
      index: index,

      color:
        CALENDAR_VIEW_EVENT_COLOR?.[index % CALENDAR_VIEW_EVENT_COLOR?.length],
      height: viewState === CALENDAR_VIEW_TYPE[0].text ? '22px' : '100%',
      display: 'flex',
      alignItems:
        viewState === CALENDAR_VIEW_TYPE[0].text ? 'center' : 'flex-start',
    });

    currentDate = currentDate.add(1, 'day');
  }

  return dateObjects;
};

export const IsPrimaryColumnFilledForListColumn = (
  listConfig,
  rowData,
  headerDataAsObj,
) => {
  if (isEmpty(listConfig) || isEmpty(rowData)) {
    return false;
  }
  const {primaryColId} = listConfig;
  const isAutoIncrementType =
    getColumnFieldType(headerDataAsObj?.[primaryColId]) ===
    FIELD_TYPE_ID.AUTO_INCREMENT_ID;
  if (isAutoIncrementType) {
    return true;
  }
  const primaryColumnValue = rowData?.[primaryColId];
  return !isNil(primaryColumnValue?.val) && primaryColumnValue?.val !== '';
};

export const isPreviousStateNonListColumnType = (stateObj) => {
  return ![
    MINIAPP_VIEW_STATES_SUBTYPE.LIST_ROWS,
    MINIAPP_VIEW_STATES_SUBTYPE.LIST_ADD_EDIT,
    MINIAPP_VIEW_STATES_SUBTYPE.LIST_ROW_DETAILS,
  ].includes(stateObj?.subType);
};

export const linkUserWithProvider = (error, history = {}) => {
  if (error?.code && error?.credential?.providerId && error?.email) {
    firebase
      .functions()
      .httpsCallable(CLOUD_FUNCTION_PATHS.GET_LOGIN_TOKEN)({
        providerId: error.credential.providerId,
        email: error.email,
        linkWithProvider: true, // link with the provider when no provider available
      })
      .then((response) => {
        response &&
          response.data &&
          (history
            ? history.replace(`/signup?from=liowebapp&token=${response.data}`)
            : (window.location.href += `?from=liowebapp&token=${response.data}`));
      })
      .catch((error) => {
        console.log('error in generating custom token', error);
      });
  }
};

export const getCustomValOptionArrBasedOnColType = (
  colObj = {},
  noTextColor = false,
) => {
  const fieldType = getColumnFieldType(colObj);

  switch (fieldType) {
    case FIELD_TYPE_ID.DATE: {
      return [
        {
          id: AUTOMATION_COLUMN_VALUE_TYPES.NOW,
          text: `Current ${capitalize(colObj?.dateFormat) ?? 'Date'}`,
          textStyles: {
            color: noTextColor ? '' : '#337EED',
          },
        },
      ];
    }
    case FIELD_TYPE_ID.TIME: {
      return [
        {
          id: AUTOMATION_COLUMN_VALUE_TYPES.NOW,
          text: `Current Time`,
          textStyles: {
            color: noTextColor ? '' : '#337EED',
          },
        },
      ];
    }
    case FIELD_TYPE_ID.DATE_TIME: {
      return [
        {
          id: AUTOMATION_COLUMN_VALUE_TYPES.NOW,
          text: `Current Date Time`,
          textStyles: {
            color: noTextColor ? '' : '#337EED',
          },
        },
      ];
    }
    default:
      return [];
  }
};

export const getToastStyles = (service, type) => {
  if (['UPDATE_FAILURE', 'ERROR'].includes(type)) {
    return {
      className: 'error-toast-text',
      bodyClassName: 'error-toast',
      icon: <ErrorOutlineIcon style={{fill: '#e02a2a'}} />,
    };
  }
  if (service === SERVICE_TYPE.IMPORT) {
    return {
      className: 'import-toast-text',
      bodyClassName: 'import-toast',
      icon: ['UPDATE_SUCCESS'].includes(type) ? (
        <CheckCircleIcon style={{fill: '#996d66'}} />
      ) : (
        <ImportIcon className="import-icon" />
      ),
    };
  } else if (service === SERVICE_TYPE.GENERATE_PDF) {
    return {
      className: 'pdf-toast-container',
      bodyClassName: 'pdf-toast-content',
      icon: ['UPDATE_SUCCESS'].includes(type) ? (
        <CheckCircleIcon style={{fill: '#449f34'}} />
      ) : (
        <GreenArrowIcon className="download-pdf-toast-icon" />
      ),
    };
  } else if (service === SERVICE_TYPE.EXPORT) {
    return {
      className: 'export-toast-text',
      bodyClassName: 'export-toast',
      icon: ['UPDATE_SUCCESS'].includes(type) ? (
        <CheckCircleIcon style={{fill: '#de8700'}} />
      ) : (
        <ExportIcon className="import-icon" />
      ),
    };
  } else if (
    [SERVICE_TYPE.DELETE_ROWS, SERVICE_TYPE.FORMULA_UPDATE].includes(service)
  ) {
    return {
      className: 'common-toast-text',
      bodyClassName: 'common-toast',
      icon: ['UPDATE_SUCCESS'].includes(type) ? (
        <CheckCircleIcon style={{fill: '#0d70ab'}} />
      ) : (
        <InfoOutlinedIcon style={{fill: '#0d70ab'}} />
      ),
    };
  } else if (service === SERVICE_TYPE.AUTOMATION) {
    return {
      className: 'automation-toast',
      bodyClassName: 'automation-list-item',
      icon: ['UPDATE_SUCCESS'].includes(type) ? (
        <CheckCircleIcon style={{fill: '#791BEE'}} />
      ) : (
        <RunningAutomationIcon fill={'#791BEE'} className="automation-icon" />
      ),
    };
  } else {
    return {};
  }
};

export const selectAllDownload = async (
  filePath = [],
  setNewStateLoader = () => {},
  isImage = false,
) => {
  try {
    if ((filePath || []).length === 0) {
      showCustomToast({
        toastType: 'ERROR',
        message: `No Files to Download.`,
        position: 'bottom-right',
        autoClose: 2000,
      });
      return;
    }

    setNewStateLoader(true);
    const zip = new JSZip();
    const uriArray = [];
    (filePath || []).forEach((fileObj) => {
      uriArray.push({
        uri: fileObj.uri,
        fileName: isImage ? fileObj.name : fileObj.fileName,
      });
    });

    const {files, success, message} = await getAllFilesContentAsZip({
      docUriArray: uriArray,
    });

    if (!success) {
      showCustomToast({
        toastType: 'ERROR',
        message: message ? message : `Something went wrong`,
        position: 'bottom-right',
        autoClose: 2000,
      });
      setNewStateLoader(false);
      return;
    }
    files.forEach(({base64, contentType, filename}) => {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {type: contentType});
      zip.file(filename, blob);
    });

    // Generate the zip file
    const zipContent = await zip.generateAsync({type: 'blob'});

    // Create a download link for the zip file
    const url = window.URL.createObjectURL(zipContent);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'files.zip';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    setNewStateLoader(false);
    // const [fileMetadata] = await imageFile.getMetadata();
    // const {base64, filename, contentType} = await getAllFilesContentAsZip({
    //   docUri: uriArray,
    // });
    // const byteCharacters = atob(base64);
    // const byteNumbers = new Array(byteCharacters.length);
    // for (let i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray], {type: contentType});
    // console.log(blob.type, blob.contentType);
    // // Create a new JSZip instance
    // const ext = mimeToExtensionMap[blob.type];
    // // Add the blob to the zip file
    // zip.file(`text.${ext}`, blob);

    // // Generate the zip file
    // const zipContent = await zip.generateAsync({type: 'blob'});

    // // Create a download link for the zip file
    // const url = window.URL.createObjectURL(zipContent);
    // const a = document.createElement('a');
    // a.style.display = 'none';
    // a.href = url;
    // a.download = 'download.zip';
    // document.body.appendChild(a);
    // a.click();
    // window.URL.revokeObjectURL(url);
    // console.log(base64, 'base64', filename, contentType);
    // // D ecode the Base64 data into a binary Blob
  } catch (error) {
    captureError(error);
  }
};
