import React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Logo from '../assets/img/header-logo.png';
import {routes} from '../routes/routes';
import LocalText from '../components/common/localText';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    position: 'relative',
    '&>div': {
      margin: theme.spacing(1),
      textAlign: 'center',
    },
  },
  logoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  logo: {
    height: '50px',
    padding: theme.spacing(2),
    filter: 'drop-shadow(3px 3px 3px #666666)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
  },
  buttonContainer: {
    marginTop: `${theme.spacing(10)}px !important`,
    '&>button': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
}));

const NotFound = function ({history}) {
  const classes = useStyles();

  const goBack = function () {
    history.goBack();
  };

  const goToMyDocuments = function () {
    history.push(routes.DOCUMENT);
  };

  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <img
          src={Logo}
          alt="app logo"
          className={classes.logo}
          onClick={goToMyDocuments}
        />
      </div>
      <div className={classes.message}>
        <Typography
          variant="h1"
          color="textSecondary"
          style={{transform: 'scale(1.2)'}}>
          4 0 4
        </Typography>
        <Typography variant="h4" color="textSecondary">
          <LocalText>The page you're looking for doesn't exist.</LocalText>
        </Typography>
      </div>
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" onClick={goBack}>
          <LocalText>Go Back</LocalText>
        </Button>
        {/* <Button variant='contained' color='primary' onClick={goToMyDocuments}>
                    <LocalText>My Documents
                    </LocalText>
                </Button> */}
      </div>
    </div>
  );
};

export default NotFound;
