import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import appLauncherIcon from '../../src/assets/img/appLauncherIcon.svg';
import appLauchIconUnselected from '../../src/assets/img/appLauchIconUnselected.svg';
import {routes} from '../routes/routes';

export const loadTime = 250;
export const maxMobileLength = 10;
export const resendOTPTimer = 120;
export const WEB_PAGE_TYPE = {
  0: 'DAILY',
  1: 'MONTHLY',
  2: 'CLIENT',
};
export const MAX_ALLOWED_COLUMNS = 52;
export const TIME_FORMAT = 'h:mm A';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const REMINDER_FORMAT = 'Do MMMM YYYY, h:mm A';
export const REMINDER_VALUE_FORMAT = 'MM/DD/YYYY, h:mm A';
export const ITEMS_PER_PAGE = 12;
export const MAX_COL_WIDTH = 400;
export const GRID_ACTIONS = {
  MOVING_ROW: 'MOVING_ROW',
  MOVING_COLUMN: 'MOVING_COLUMN',
  RESIZING_COLUMN: 'RESIZING_COLUMN',
};
// export const MIN_COL_WIDTH = 200;
export const DEFAULT_COL_WIDTH = 200;
export const MIN_COL_WIDTH = 137;
export const NEW_MIN_WIDTH = 201;
export const MIN_WIDTH_SMALL = 115;
export const MIN_WIDTH_MEDIUM = 140;
export const MIN_COL_WIDTH_FIRST_COL = 46;
export const COLOR = {
  // ACTION: '#3C3C55', //'#e76f51', //'#2A9D8F',#457B9D
  // HEADER: '#FFF',
  // BACKGROUND: '#FFFFFF', //'#7da7c1',356488
  // TEXT_DARK: '#0a0f13',
  // TEXT_SECONDARY: '#60676c', //'#939a9f',
  // TEXT_THIRD: '#c6cdd2',
  // TEXT_BUTTON: '#FFFFFF',
  // TEXT_COLOR: '#508991',
  // BORDER: '#e0e7ec',
  // BORDER_LIGHT: 'rgba(224, 231, 236, 0.65)',
  // BORDER_DARK: '#C7CED3',
  // BORDER_GREEN: '#E3E3E3',
  // BORDER_RGB: 'rgb(224,231,236)',
  // BORDER_DARK_RGB: 'rgb(199,206,211)',
  // ERROR_MESSAGE: '#FB3640',
  // SECONDARY_HEADER: '#e0e7ec',
  // MODAL_VIEW: '#FFFFFF',
  // MODAL_BAR: '#ADB4B9',
  // BACKGROUND_LIGHT: 'rgba(210,215,223,0.15)',
  // BORDER_COLOR: 'rgba(231, 111, 81,0.3)',
  // ONLINE: '#2B9B8F',
  // OFFLINE: '#FF0000',
  DELETE: '#e63946',
  // SWITCH_THUMB: '#f4f3f4',
  // SWITCH_BACKGROUND: '#e63946', //only for ios
  POSITIVE: '#2a9d8f',
  // PAGE_TYPE_TEXT: '#858585',
  // LIGHT_TEXT: '#BFBFBF',
  // DARK_TEXT: '#B1B1B1',
  // SELECT_COLOR: '#add8e6CC',
  // BUTTON_SECONDARY: '#3B3B59',
  // BUTTON: '#1990E0',
  // DARK_SCREEN_BACKGROUND: '#F4F4F4',
  // SUB_HEADING: '#7E7E7E',
  // SEPARATOR: '#F0F0F0',
  // INPUT_BOX: '#F9F9F9',
  // FOLDER_ICON: '#FFC10A',
  // WHITE: '#fff',
  // BLACK: '#000',
  // OFF_BLACK: 'rgba(0,0,0,0.4)',
  // HIGHLIGHTED_TEXT: '#008B48',
  // BUTTON_TEXT_COLOR: '#5F5F5F',
  // LIGHTNING_ICON: '#FCD12A',
  // DEFAULT_CIRCULAR_PROGRESS_TRACK_COLOR: '#E0E7ED',
  // PURE_BLUE: '#000AFF',
  // PURE_RED: '#EB3223',
};

export const HEADER_NAV_LIST = (history) => ({
  [routes.APPS]: {
    title: 'Apps',
    // startIcon: <img src={appLauncherIcon} alt="app-launcher" />,
    // startIconUnSelected: (
    //   <img src={appLauchIconUnselected} alt="app-unselected-icon" />
    // ),
    allowOrgModeOnly: true,
    clickHandler: () => history.push(routes.APPS),
  },
  [routes.DOCUMENT]: {
    title: 'Docs',
    clickHandler: () => history.push(routes.DOCUMENT),
  },
  [routes.DASHBOARDS]: {
    title: 'Dashboards',
    allowNonOrgModeOnly: true,
    clickHandler: () => history.push(routes.DASHBOARDS),
  },
  [routes.MY_TASKS]: {
    title: 'My Tasks',
    allowNonOrgModeOnly: true,
    clickHandler: () => history.push(routes.MY_TASKS),
  },
  [routes.TEMPLATE]: {
    title: 'Templates',
    allowNonOrgModeOnly: true,
    clickHandler: () => history.push(routes.TEMPLATE),
  },
});

export const RESPONSIVE_EXCEPTION_ROUTES = [
  '/wa',
  routes.ONBOARDING,
  routes.SIGNUP,
  routes.LOGINMOBILE,
  routes.LANGUAGE,
  routes.PROFILE,
];
