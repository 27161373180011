import {AGGREGATION_ACTION} from '../actions/actionType';

const initialState = {
  aggregationsList: [],
};

const aggregationReducer = (state = initialState, action) => {
  switch (action.type) {
    case AGGREGATION_ACTION.LOAD_AGGREGATIONS: {
      return {
        ...state,
        aggregationsList: action.payload ?? [],
      };
    }
    case AGGREGATION_ACTION.CLEAR_AGGREGATION_STATE:
    case AGGREGATION_ACTION.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default aggregationReducer;
