import {captureError, functions, firestore} from '../../imports';
import {forOwn, isEmpty, some, filter, pickBy} from 'lodash';
import {handleCloudError, getLocalText} from '../../utils/utils';
import {
  CLOUD_FUNCTION_PATHS,
  SHARE_PERMISSION_TYPE,
} from '../../utils/constant';
import {getContactInfo} from './contactActionHelper';

const createEditTeamCloud = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.CREATE_EDIT_TEAM,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const shareMultipleDocsWithMultipleTeamsCloud = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.SHARE_MULTIPLE_DOCS_WITH_MULTIPLE_TEAMS,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const unshareFileWithTeamCloud = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.UNSHARE_DOC_WITH_TEAM,
    );
    const response = await functionInstance(obj);
    return response?.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const migrateUserOlderTeamsCloud = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.MIGRATE_USER_OLDER_TEAMS,
    );
    const response = await functionInstance(obj);
    return response?.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const getFileIdsTeamMapping = (teams) => {
  const fileObj = {};
  try {
    forOwn(teams, (teamObj, teamId) => {
      Object.keys(Object.assign({}, teamObj?.fileIds)).forEach((fileId) => {
        const obj = {
          teamId,
          teamName: teamObj.teamName ?? ' ',
          teamColor: teamObj.teamColor,
        };
        fileObj[fileId] = fileObj[fileId]?.length
          ? [...fileObj[fileId], obj]
          : [obj];
      });
    });
  } catch (error) {
    captureError(error);
  }
  return fileObj;
};

const getFormattedTeamObj = (teamObj, teamId, userUID, userPref) => {
  try {
    const isTeamOwner = teamObj?.teamOwnerUID === userUID ? true : false;
    const docData = Object.assign({}, teamObj, {
      teamId,
      isTeamOwner,
    });
    //convert teamMembers object to array
    const contacts = [];
    const teamMembersArr = Object.keys(
      Object.assign({}, docData.teamMembers),
    ).map((uid) => {
      contacts.push(docData.teamMembers[uid]?.contact);
      return Object.assign({}, docData.teamMembers[uid], {uid});
    });
    const mappedContactArr = getContactInfo(contacts, false);
    const teamMembers = teamMembersArr.map((obj, index) => {
      const isSelf = obj.uid === userUID;
      return Object.assign(
        {},
        obj,
        isSelf ? {name: getLocalText(userPref, 'Me')} : null,
        mappedContactArr[index]?.isPresent &&
          mappedContactArr[index].info?.hasThumbnail
          ? {
              hasThumbnail: true,
              thumbnailPath: mappedContactArr[index].info.thumbnailPath,
            }
          : null,
      );
    });
    return Object.assign({}, docData, {teamMembers});
  } catch (error) {
    captureError(error);
  }
  return Object.assign({}, teamObj);
};

const getTeamOwnerObj = (teamObj, userUID, userPref) => {
  const isSelf = teamObj?.teamOwnerUID === userUID;
  const obj = {
    contact: teamObj?.teamOwnerContact ?? '',
    permission: SHARE_PERMISSION_TYPE.OWNER,
  };
  return Object.assign({}, obj, {
    name: isSelf ? getLocalText(userPref, 'Me') : '',
  });
};

const checkIfTeamCanBeSharedWith = (teamObj, userUid) => {
  try {
    return (
      teamObj &&
      (teamObj.isTeamOwner ||
        teamObj.teamMembers.find((obj) => obj.uid === userUid)?.permission ===
          SHARE_PERMISSION_TYPE.ADMIN)
    );
  } catch {}
  return false;
};

const areTeamsAvailableToShareFile = (teams, userUid) => {
  //returns whether user have any team with which it can share files!
  return (
    !isEmpty(teams) &&
    some(teams, (teamObj) => checkIfTeamCanBeSharedWith(teamObj, userUid))
  );
};

const getTeamsAvailableToShareFile = (
  teams,
  userUid,
  shouldReturnAsArr = false,
) => {
  //returns array of teams with which user can share files!
  return shouldReturnAsArr
    ? filter(teams, (teamObj) => checkIfTeamCanBeSharedWith(teamObj, userUid))
    : pickBy(teams, (teamObj) => checkIfTeamCanBeSharedWith(teamObj, userUid));
};

const getUserOlderTeams = (uid) =>
  firestore().collection('users').doc(uid).collection('teams').limit(1).get();

export {
  getFileIdsTeamMapping,
  createEditTeamCloud,
  shareMultipleDocsWithMultipleTeamsCloud,
  unshareFileWithTeamCloud,
  getFormattedTeamObj,
  checkIfTeamCanBeSharedWith,
  areTeamsAvailableToShareFile,
  getTeamsAvailableToShareFile,
  getUserOlderTeams,
  migrateUserOlderTeamsCloud,
  getTeamOwnerObj,
};
