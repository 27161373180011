const ScreenLayoutIcon = ({color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none">
      <g clip-path="url(#clip0_1208_6017)">
        <path
          d="M15.75 0H2.25C1.425 0 0.75 0.675 0.75 1.5V13.5C0.75 14.325 1.425 15 2.25 15H15.75C16.575 15 17.25 14.325 17.25 13.5V1.5C17.25 0.675 16.575 0 15.75 0ZM15.75 13.5H2.25V3H15.75V13.5ZM5.25 16.5H6.75V18H5.25V16.5ZM8.25 16.5H9.75V18H8.25V16.5ZM11.25 16.5H12.75V18H11.25V16.5Z"
          fill={color ? color : '#495A74'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1208_6017">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ScreenLayoutIcon;
