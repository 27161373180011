import {FOOTER_OPERATION_TYPES} from '../constant';
import DashboardData from './dashboardData.class';
import {forOwn, isEmpty} from 'lodash';

const OPERATIONS = Object.values(FOOTER_OPERATION_TYPES);

export default function getFormattedDashboardData(
  dashboardData,
  allDashboards,
  userPref,
  userCountry,
) {
  if (dashboardData?.display) {
    const childDashboards = {};
    if (!isEmpty(dashboardData.childDashboards)) {
      forOwn(dashboardData.childDashboards, (_, childDashboardId) => {
        if (!isEmpty(allDashboards[childDashboardId])) {
          //childDashboards whose data is available should only be processed
          //additional case added for some uncontrollable cases
          childDashboards[childDashboardId] = allDashboards[childDashboardId];
        }
      });
      if (isEmpty(childDashboards)) {
        return null;
      }
    }
    const dashboardToProcess = Object.assign({}, dashboardData, {
      childDashboards,
    });
    return new DashboardData(dashboardToProcess, userPref, userCountry);
  }
  return null;
}

export function getFormattedColumnName(name) {
  if (!name?.length) {
    return '';
  }
  const prependLength =
    OPERATIONS.find((operation) => name.startsWith(`${operation}_`))?.length ??
    -1; //find and remove operation from starting of name
  return name.slice(prependLength + 1, name.length);
}
