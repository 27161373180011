import React from 'react';

import {Route, Redirect, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import {routes} from './routes';
import {useSelector} from 'react-redux';
import {isNil} from 'lodash';
import {checkURLRestrictionsAndRedirect} from '../utilsWeb/organisationUtils';
import {LIO_APP} from 'rb-redux/utils/constant';
import {parseAndReturnShareURLParams} from 'rb-redux/utils/rowShareUtils';

/**
 * Will render provided component if user is authenticated, otherwise it will redirect user to login page
 * @param {React.Component} Component: name of the component to send user to
 * @param {Boolean} authenticated
 * @param {Array} rest
 */

const PrivateRoute = ({
  component: Component,
  title,
  authenticated,
  checkFromApp,
  isOnboardingPending,
  defaultRouteMap,
  route,
  ...rest
}) => {
  const {search} = useLocation();
  const query = new URLSearchParams(search);
  const redirectLink = query.get('redirect');
  const menu = query.get('menu');
  const from = query.get('from');

  const isOrganisationMode = useSelector(
    (state) => state.remoteConfig.isOrganisationMode,
  );

  const userPrefReduxOnboardPending = useSelector(
    (state) => state.auth.userPref.isOrgOnboardingPending,
  );

  const {isUserOrganisationOwner, orgSubscriptions} = useSelector(
    (state) => state.organisation,
  );

  const {isAccountActivated} = useSelector((state) => state.auth.userPref);

  const isFreePlan = !orgSubscriptions?.isActive;

  const shouldRedirect =
    from == LIO_APP
      ? false
      : ((route.allowOrgModeOnly && !isOrganisationMode) ||
          (route.allowNonOrgModeOnly && isOrganisationMode)) &&
        (userPrefReduxOnboardPending === true ||
          isNil(userPrefReduxOnboardPending));

  // TODO : This can be refactored
  return (
    <Route
      {...rest}
      render={(props) => {
        const redirectObj = checkURLRestrictionsAndRedirect({
          isFreePlan,
          isUserOrganisationOwner,
          isOrganisationMode,
          isOnboardingPending: userPrefReduxOnboardPending,
          query,
          currentRoute: rest.path,
          isAccountActivated,
        });
        return authenticated && !shouldRedirect ? (
          redirectObj.redirect && from !== LIO_APP ? (
            <Redirect
              to={{
                pathname: redirectObj.path,
                // state: {from: props.location},
                // search:
                //   redirectLink && redirectLink !== ''
                //     ? `?redirect=${redirectLink}&menu=${menu}&from=${from}`
                //     : '',
              }}
            />
          ) : (
            <Component {...props} title={title} />
          )
        ) : (
          <Redirect
            to={{
              pathname:
                authenticated && shouldRedirect
                  ? defaultRouteMap.path
                  : routes.SIGNUP,
              state: {from: props.location},
              search: parseAndReturnShareURLParams(query).length
                ? parseAndReturnShareURLParams(query)
                : !shouldRedirect && redirectLink && redirectLink !== ''
                ? `?redirect=${redirectLink}&menu=${menu}&from=${from}`
                : '',
            }}
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  authenticated: PropTypes.bool,
  component: PropTypes.elementType,
  location: PropTypes.object,
};

export default PrivateRoute;
