import {isBoolean, isEmpty, isFunction} from 'lodash';
import {REMOTE_CONFIG_ACTION} from '../actions/actionType';
import {captureError} from '../imports';
import {setRestrictionDataFirestore} from './actionHelpers/remoteConfigActionHelper';

const setShowActivityLog =
  (flag = false) =>
  (dispatch) => {
    dispatch({
      type: REMOTE_CONFIG_ACTION.SET_SHOW_ACTIVITY_LOG,
      payload: flag,
    });
  };

const setRestrictionData =
  (userObj, fetchRestrictionDataRemoteConfigCallback) =>
  (dispatch, getState) => {
    try {
      const {
        auth: {user, userPref},
        premium: {isUserSubscribed, isTrial},
      } = getState();

      if (!isUserSubscribed && !isTrial) {
        if (
          !isEmpty(userObj) &&
          'applyUserRestrictions_v2' in userObj &&
          'restrictionConfig' in userObj
        ) {
          dispatch({
            type: REMOTE_CONFIG_ACTION.SET_RESTRICTION_DATA,
            payload: {
              applyUserRestrictions: userObj.applyUserRestrictions_v2,
              restrictionConfig: userObj.restrictionConfig,
            },
          });
        } else {
          if (isFunction(fetchRestrictionDataRemoteConfigCallback)) {
            const remoteConfigObj = fetchRestrictionDataRemoteConfigCallback();
            if (!isEmpty(remoteConfigObj)) {
              const config = Object.assign(
                {},
                remoteConfigObj.restrictionConfig[userPref.country] ??
                  remoteConfigObj.restrictionConfig['ALL'],
              );
              setRestrictionDataFirestore(
                Object.assign({}, remoteConfigObj, {restrictionConfig: config}),
                user.uid,
              );
              dispatch({
                type: REMOTE_CONFIG_ACTION.SET_RESTRICTION_DATA,
                payload: {
                  applyUserRestrictions: remoteConfigObj.applyUserRestrictions,
                  restrictionConfig: config,
                },
              });
            }
          }
        }
      }
    } catch (error) {
      captureError(error);
    }
  };

const updateRestrictionStatus = (restrictionStatus) => (dispatch) => {
  try {
    if (isBoolean(restrictionStatus)) {
      dispatch({
        type: REMOTE_CONFIG_ACTION.UPDATE_RESTRICTION_STATUS,
        payload: restrictionStatus,
      });
    }
  } catch (error) {
    captureError(error);
  }
};

const setShowNewDashboardFlow =
  (flag = false) =>
  (dispatch) => {
    dispatch({
      type: REMOTE_CONFIG_ACTION.SET_SHOW_NEW_DASHBOARD_FLOW,
      payload: flag,
    });
  };

export {
  setShowNewDashboardFlow,
  setRestrictionData,
  updateRestrictionStatus,
  setShowActivityLog,
};
