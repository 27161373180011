import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  emptyPlaceholderComponent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    gap: '4px',
    width: '206px',
    height: '76px',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },
  createNewTeamFooterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 4px',
    gap: '12px',
    width: '207px',
    height: '32px',
    background: ' #FFFFFF',

    /* Inside auto layout */

    flex: 'none',
    order: 0,
    alignSelf: 'stretch',
    flexGrow: 0,
  },
  footerContainer: {
    display: 'flex',
    width: '206px',
    padding: '12px 16px',
    borderTop: `1px solid ${theme.palette.gray200}`,
    '& p': {
      margin: '0px',
    },
    '&:hover': {
      background: theme.palette.gray100,
      cursor: 'pointer',
    },
  },
  container: {
    width: '100%',
    minWidth: '136px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 16px',
    '&:hover': {
      background: theme.palette.gray100,
      cursor: 'pointer',
    },
    boxSizing: 'border-box',
  },
  seperator: {
    width: '24px',
    height: '0px',

    /* Utils/Border */

    border: '1px solid #E4EAF2',
    transform: 'rotate(90deg)',

    /* Inside auto layout */

    flex: 'none',
    order: 1,
    flexGrow: 0,
  },
  headerSeperator: {
    width: '136px',
    height: '0px',

    /* Utils/Border */

    border: '1px solid #E4EAF2',

    /* Inside auto layout */

    flex: 'none',
    order: 1,
    alignSelf: 'stretch',
    flexGrow: 0,
  },
  ascendingOrder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'space-between',
    justifyContent: 'space-evenly',
    padding: '7px 4px -1px 4px',
    gap: '8px',
    width: '60px',
    height: '30px',
    background: '#FFFFFF',

    /* Inside auto layout */

    flex: 'none',
    order: '0',
    flexGrow: '0',
    '&:hover': {
      backgroundColor: theme.palette.navHover,
      borderRadius: '4px',
      cursor: 'pointer',
    },
  },
  descendingOrder: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'space-between',
    justifyContent: 'space-evenly',
    padding: '7px 4px -1px 4px',
    gap: '8px',
    width: '60px',
    height: '30px',
    background: '#FFFFFF',

    /* Inside auto layout */

    flex: 'none',
    order: '2',
    flexGrow: '0',
    '&:hover': {
      backgroundColor: theme.palette.navHover,
      borderRadius: '4px',
      cursor: 'pointer',
    },
  },
  avatar: {
    height: '35px',
    width: '35px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  details: {
    width: 'auto',
    height: 'auto',
    padding: '0 10px',
    '& .text': {
      width: '117px !important',
      whiteSpace: 'nowrap !important',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'block !important',
      fontSize: `${theme.fontSize.font14} !important`,
      fontFamily: `${'Inter'} !important`,
      fontWeight: `${'500'} !important`,
      lineHeight: `${theme.lineHeight.lineHeight20} !important`,
      color: `${theme.palette.textDark} !important`,
    },
    '& .subText': {
      fontSize: `${theme.fontSize.font12} !important`,
      fontFamily: `${'Inter'} !important`,
      fontWeight: `${'500'} !important`,
      lineHeight: `${theme.lineHeight.lineHeight16} !important`,
      color: `${theme.palette.gray900} !important`,
    },
  },
  bottomBorder: {
    width: '100%',
    margin: '2px 0px',
    borderBottom: `1px solid ${theme.palette.gray200}`,
  },
  logout: {
    color: theme.palette.redText2,
    width: '100%',
    cursor: 'pointer',
    fontSize: theme.fontSize.font14,
    lineHeight: theme.lineHeight.lineHeight20,
    fontWeight: 500,
    fontFamily: '"Inter"',
    // '&:hover': {
    //   background: theme.palette.gray200,
    // },
  },
}));

export default useStyles;
