import moment from 'moment';
import {
  replace,
  isNil,
  isEmpty,
  isNumber,
  isString,
  isPlainObject,
} from 'lodash';
import {
  firestore,
  captureError,
  getReduxState,
  ShowToast,
  ENV,
  storage,
} from '../../imports';
import DocumentsMethods from '../../FirestoreHandlers/Documents/DocumentsMethods';
import {
  getRowId,
  getNewRowPropertiesObject,
  checkIfEmptyRow,
  checkIfFirestoreTimestamp,
  getFirebaseTimestampAsDateObj,
} from '../../utils/utils';
import {convertNewlyAppendedData} from '../../utils/importFromExcel';
import {
  SHARE_PERMISSION_TYPE,
  FOOTER_OPERATION_TYPES,
  INVALID_INPUT,
  TABLE_LIMITS,
} from '../../utils/constant';
import {generateRowId} from '../../utils/RandomGenerator';
import LioArray from '../../utils/CustomClass/LioArray';
import FirestoreDB from '../../FirestoreHandlers/FirestoreDB';

const updateChildLinks = (
  parentMeta,
  childDocId,
  previousParentMetaObj = {},
) => {
  try {
    if (!isEmpty(previousParentMetaObj)) {
      for (const childColId in previousParentMetaObj) {
        const {docId, colId, rowId} = previousParentMetaObj[childColId];
        FirestoreDB.documents.rowsRef(docId, rowId).update({
          [`rowObj.${colId}.childLinks.${childDocId}`]:
            FirestoreDB.FieldValue().delete(),
        });
      }
    }

    if (!isEmpty(parentMeta)) {
      for (const childColId in parentMeta) {
        const {docId, colId, rowId} = parentMeta[childColId];
        FirestoreDB.documents.rowsRef(docId, rowId).update({
          [`rowObj.${colId}.childLinks.${childDocId}`]: childColId,
        });
      }
    }
  } catch (error) {
    captureError(error);
  }
};

const fetchEntryOnlyData = (docId, options = {}) => {
  try {
    const ref = firestore().collection('entryOnlyData').doc(docId);
    return ref.get(options);
  } catch (error) {
    captureError(error);
    return new Promise();
  }
};

const addRefToStorageRemovedRefs = (ref) => {
  try {
    const docId = replace(ref, new RegExp('/', 'g'), '<>');
    const timestamp = moment().utc().valueOf();
    firestore().collection('storageRemovedRefs').doc(docId).set({
      ref: ref,
      deleteTimestamp: timestamp,
    });
  } catch (error) {
    captureError(error);
  }
};

const checkAndUpdateEntryOnlyData = ({
  getState,
  headerData,
  isShared,
  docId,
  fileObj,
  activeDocumentMeta = {},
}) => {
  try {
    if (isNil(isShared) && getState) {
      isShared = !isEmpty(
        (!isEmpty(activeDocumentMeta)
          ? activeDocumentMeta
          : getState().home.activeDocumentMeta
        )?.collab,
      );
    }
    if (isShared) {
      const updateObj = {timestamp: moment().unix(), headerData};
      if (isNil(headerData)) {
        updateObj.headerData = getState().table.headerData.slice();
      }
      if (fileObj) {
        updateObj.fileObj = Object.assign({}, fileObj);
      }
      if (isNil(docId)) {
        docId = getState().home.activeDocumentId;
      }
      firestore()
        .collection('entryOnlyData')
        .doc(docId)
        .set(updateObj, {merge: true});
    }
  } catch (error) {
    captureError(error);
  }
};

const getRowIdRowDataMapping = (tableData) => {
  let tableRowIdArr = [];
  const rowIdDataMap = {};
  try {
    tableRowIdArr = tableData.map((rowObj) => {
      const {rowId} = rowObj;
      rowIdDataMap[rowId] = rowObj;
      return rowId;
    });
  } catch (error) {
    captureError(error);
  }
  return [tableRowIdArr, rowIdDataMap];
};

const processTableSortKey = (sortKey) => {
  let processedKey = sortKey;
  try {
    if (checkIfFirestoreTimestamp(sortKey)) {
      processedKey = getFirebaseTimestampAsDateObj(sortKey).getTime();
    }
    return processedKey;
  } catch (error) {
    captureError(error);
  }
  return sortKey;
};

const makeRowObjFromRowDoc = (doc) => {
  const rowId = doc.id;
  const rowData = doc.data();
  return [
    getRowObjStructured(
      rowData.rowObj,
      rowId,
      rowData.index,
      rowData.rowProperties,
      rowData.sortKey,
    ),
    rowData.isDeleted,
  ];
}; //change on all cloud repo (if used in that repo)

const getRowObjStructured = (rowObj, rowId, index, rowProperties, sortKey) =>
  Object.assign({}, rowObj, {
    rowId,
    index,
    rowProperties,
    sortKey: processTableSortKey(sortKey),
  }); //change on all cloud repo (if used in that repo)

const processRowsData = (
  rowsData = [],
  indexUpperLimit = null,
  prevTableData = null,
  noOfRows = null,
  fileObj = null,
) => {
  try {
    const {
      isCustomSorted,
      sortingKey,
      isDesc: isDescOrderOfIndex,
    } = getTableSortingOrder(fileObj);

    const {docs} = rowsData;
    const isAllRowsData =
      isNumber(noOfRows) &&
      noOfRows === docs.length &&
      !makeRowObjFromRowDoc(docs[0])[1]; //not deleted
    if (
      isAllRowsData ||
      !(isCustomSorted
        ? isNumber(indexUpperLimit) || isString(indexUpperLimit)
        : isNumber(indexUpperLimit))
    ) {
      indexUpperLimit = null;
    }

    const data = new LioArray(
      isAllRowsData
        ? []
        : Array.isArray(prevTableData)
        ? prevTableData
        : require('../../imports').getReduxState().table.tableData.slice(),
    );

    const isIndexOutsideRange = (comparingIndex) =>
      indexUpperLimit != null &&
      (isDescOrderOfIndex
        ? comparingIndex < indexUpperLimit
        : comparingIndex > indexUpperLimit);

    const rowIdIndexMapping = {};
    if (docs.length === 1) {
      //optimisation for performance
      const [rowData] = makeRowObjFromRowDoc(docs[0]);
      if (!isIndexOutsideRange(rowData[sortingKey])) {
        data.some((rowObj) => {
          const isFound = rowObj.rowId === rowData.rowId;
          if (isFound) {
            rowIdIndexMapping[rowObj.rowId] = rowObj[sortingKey];
          }
          return isFound;
        });
      }
    } else {
      data.forEach((rowObj) => {
        rowIdIndexMapping[rowObj.rowId] = rowObj[sortingKey];
      });
    }

    docs.forEach((doc) => {
      const [rowData, isDeleted] = makeRowObjFromRowDoc(doc);

      const insert = () => {
        if (isIndexOutsideRange(rowData[sortingKey]) || isDeleted) {
          return;
        }
        data.binaryInsert(
          rowData,
          [sortingKey, 'rowId'],
          'replace',
          isDescOrderOfIndex,
        );
      };
      const remove = () =>
        data.binaryDelete(
          Object.assign({}, rowData, {
            [sortingKey]: rowIdIndexMapping[rowData.rowId],
          }),
          [sortingKey, 'rowId'],
          isDescOrderOfIndex,
        );

      if (rowData.rowId in rowIdIndexMapping) {
        //if already exist
        if (isDeleted) {
          return remove();
        } else {
          //if sortingKey for this rowId has changed
          //then (for this rowId)
          //remove obj with old sortingKey & insert obj with new sortingKey
          if (rowIdIndexMapping[rowData.rowId] !== rowData[sortingKey]) {
            remove();
          }
          return insert();
        }
      } else {
        //if not already exist
        return insert();
      }
    });
    return data;
  } catch (error) {
    captureError(error);
    return [];
  }
};

const processRowsDataForMappedRowId = (
  rowsData = [], //required
  indexUpperLimit = null,
  prevTableData = [], //required
  noOfRows = null,
  rowIdDataMap = {}, //required
  fileObj = null,
  options,
) => {
  const {useDocDataAsObject, enforceDefaultSort, insertOnlyIfExists} =
    Object.assign(
      {},
      {
        useDocDataAsObject: false,
        enforceDefaultSort: false,
        insertOnlyIfExists: false,
      },
      options,
    );
  if (!isPlainObject(rowIdDataMap)) {
    rowIdDataMap = {};
  }
  const {
    isCustomSorted,
    sortingKey,
    isDesc: isDescOrderOfIndex,
  } = getTableSortingOrder(fileObj, enforceDefaultSort);
  let data = [];
  const updatesForRowIdDataMap = {};
  const sortingKeyChangedRows = [];
  try {
    const {docs} = rowsData;
    const isAllRowsData =
      isNumber(noOfRows) &&
      noOfRows === docs.length &&
      !makeRowObjFromRowDoc(docs[0])[1]; //not deleted
    if (
      isAllRowsData ||
      !(isCustomSorted
        ? isNumber(indexUpperLimit) || isString(indexUpperLimit)
        : isNumber(indexUpperLimit))
    ) {
      indexUpperLimit = null;
    }

    data = new LioArray(
      (isAllRowsData ? [] : prevTableData) ?? [],
      (rowId) => updatesForRowIdDataMap[rowId] ?? rowIdDataMap[rowId],
      (rowObj) => rowObj.rowId,
    );

    const isIndexOutsideRange = (comparingIndex) =>
      indexUpperLimit != null &&
      (isDescOrderOfIndex
        ? comparingIndex < indexUpperLimit
        : comparingIndex > indexUpperLimit);

    const comparator = [sortingKey, 'rowId'];
    docs.forEach((doc) => {
      if (useDocDataAsObject) {
        const docData = doc.data;
        doc = Object.assign({}, doc, {data: () => docData});
      }
      const [rowData, isDeleted] = makeRowObjFromRowDoc(doc);
      const {rowId} = rowData;
      if (rowId == null) {
        return;
      }
      const insert = () => {
        if (isIndexOutsideRange(rowData[sortingKey]) || isDeleted) {
          return;
        }
        updatesForRowIdDataMap[rowId] = rowData;
        data.binaryInsert(rowData, comparator, 'replace', isDescOrderOfIndex);
      };
      const remove = () =>
        data.binaryDelete(
          Object.assign({}, rowData, {
            [sortingKey]: rowIdDataMap[rowId][sortingKey],
          }),
          comparator,
          isDescOrderOfIndex,
        );
      if (rowId in rowIdDataMap) {
        //if already exist (it might exist or might not exist)
        if (isDeleted) {
          return remove();
        } else {
          if (insertOnlyIfExists) {
            const currIndex = data.binarySearch(
              Object.assign({}, rowData, {
                [sortingKey]: rowIdDataMap[rowId][sortingKey],
              }),
              comparator,
              isDescOrderOfIndex,
            );
            if (currIndex < 0) {
              return;
            }
          }
          //if sortingKey for this rowId has changed
          //then (for this rowId)
          //remove obj with old sortingKey & insert obj with new sortingKey
          if (rowIdDataMap[rowId][sortingKey] !== rowData[sortingKey]) {
            sortingKeyChangedRows.push(doc);
            remove();
          }
          return insert();
        }
      } else if (!insertOnlyIfExists) {
        //if not already exist
        return insert();
      }
    });
  } catch (error) {
    captureError(error);
  }
  return {
    tableData: data ?? [],
    rowIdDataMap: Object.assign({}, rowIdDataMap, updatesForRowIdDataMap),
    sortingKeyChangedRows,
  };
};

const getTableSortingOrder = (fileObj, enforceDefaultSort = false) => {
  const isCustomSorted =
    enforceDefaultSort !== true &&
    Boolean(fileObj?.customSorting?.colId != null);
  return {
    isCustomSorted,
    sortingKey: isCustomSorted ? 'sortKey' : 'index',
    sortingColId: isCustomSorted ? fileObj.customSorting.colId : null,
    isDesc: Boolean(fileObj?.customSorting?.isDesc),
  };
};

const uploadVideoFirebase = (localVideoPath, videoRef, storageRef) => {
  try {
    const reference = (storageRef ?? storage)().ref(videoRef);
    let task;
    if (ENV) {
      task = reference.putFile(localVideoPath);
    } else {
      task = reference.put(localVideoPath);
    }
    return {task, reference};
  } catch (error) {
    captureError(error);
  }
};

const handleVideoUpload = (
  localVideoPath = null,
  videoFileName,
  setState,
  successCB = () => {},
  uid = null,
  activeDocId = null,
  colId = null,
  videoSizeInMB = '',
  mimeType = '',
  isVideoFromGallery = false,
  clearCache = () => {},
  storageRef,
  videoUploadStatus = {},
) => {
  try {
    if (uid && activeDocId && colId && localVideoPath) {
      if (ENV) {
        const updatedVideoUploadStatus = {
          ...videoUploadStatus,
          [colId]: {
            showUploadLoader: true,
            uploadLoaderText1: 'Uploading Video',
          },
        };
        setState({videoUploadStatus: updatedVideoUploadStatus});
      } else {
        setState({
          showUploadLoader: true,
          uploadLoaderText1: 'Uploading Video',
          uploadLoaderText2: '',
        });
      }

      const fileName =
        videoFileName ??
        localVideoPath?.name ??
        `sampleVideo_${moment().unix()}`;
      const videoRef = `${uid}/${activeDocId}/${colId}/videos/${fileName}_${moment().unix()}`;
      const uploadVideo = uploadVideoFirebase(
        localVideoPath,
        videoRef,
        storageRef,
      );
      const {task, reference} = uploadVideo;

      uploadVideo?.task?.on('state_changed', (taskSnapshot) => {
        const percentage = parseInt(
          (taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 100,
          10,
        );
        if (ENV) {
          const updatedVideoUploadStatus = {
            ...videoUploadStatus,
            [colId]: {
              showUploadLoader: true,
              uploadLoaderText1: 'Uploading Video',
            },
          };
          setState({videoUploadStatus: updatedVideoUploadStatus});
        } else {
          setState({
            showUploadLoader: true,
            uploadLoaderText1: 'Uploading Video',
            uploadLoaderText2: percentage,
          });
        }
      });

      task.then(() => {
        reference.getDownloadURL().then((uri) => {
          if (ENV) {
            clearCache(localVideoPath);
            const updatedVideoUploadStatus = {
              ...videoUploadStatus,
              [colId]: {
                showUploadLoader: false,
                uploadLoaderText1: '',
              },
            };
            setState({videoUploadStatus: updatedVideoUploadStatus});
          } else {
            setState({
              showUploadLoader: false,
              uploadLoaderText1: '',
              uploadLoaderText2: '',
            });
          }
          successCB(
            {
              uri,
              videoRef,
              colId,
              fileName,
              videoSizeInMB,
              mimeType,
            },
            isVideoFromGallery,
          );
        });
      });
    }
  } catch (error) {
    if (ENV) {
      const updatedVideoUploadStatus = {
        ...videoUploadStatus,
        [colId]: {
          showUploadLoader: false,
          uploadLoaderText1: '',
        },
      };
      setState({videoUploadStatus: updatedVideoUploadStatus});
    } else {
      setState({
        showUploadLoader: false,
        uploadLoaderText1: '',
        uploadLoaderText2: '',
      });
    }
    captureError(error);
  }
};

const getTableData = async (docId, fetchAllRows = true) => {
  try {
    const docData = await DocumentsMethods.getUserDocumentData(
      docId,
      fetchAllRows,
    );
    if (!isEmpty(docData)) {
      return docData;
    }
    return null;
  } catch (error) {
    captureError(error);
    return null;
  }
};

const getPaginatedTableDataWrapper = async (
  documentId,
  tableState,
  limit = null,
  userPref,
  isTableDataOfRowIds = false, //if true then tableData is array of rowIds else array of rowObj
  checkForCustomSorting = false, //check for custom column level sorted rows
) => {
  try {
    const {tableData, fileObj, rowIdDataMap, areAllRowsFetched} =
      tableState ?? {};
    if (documentId && !areAllRowsFetched) {
      const fileObjForCustomSort = checkForCustomSorting ? fileObj : {};
      const {
        isCustomSorted,
        sortingKey,
        isDesc: isDescOrderOfIndex,
      } = getTableSortingOrder(fileObjForCustomSort);

      const lastRowData = isTableDataOfRowIds
        ? rowIdDataMap?.[tableData[tableData.length - 1]]
        : tableData[tableData.length - 1];
      const lastFetchedIndex =
        lastRowData?.[sortingKey] ??
        (isCustomSorted ? '' : -99999999999 * (isDescOrderOfIndex ? -1 : 1));
      const lastFetchedDocRef = isCustomSorted ? lastRowData?.rowId : null;

      limit = limit ?? TABLE_LIMITS.INITIAL_FETCH_LIMIT;

      const rowsData = await DocumentsMethods.getRowsData({
        docId: documentId,
        lastFetchedIndex,
        lastFetchedDocRef,
        isLimited: true,
        limit,
        isDescOrder: isDescOrderOfIndex,
        customSortingKey: isCustomSorted ? sortingKey : null,
      });

      if (rowsData == null) {
        ShowToast('Fetching rows failed due to network issue.', userPref);
      } else {
        let updatedTableData = tableData;
        let updatedRowIdDataMap;
        if (rowsData.docs.length) {
          if (isTableDataOfRowIds) {
            const processedData = processRowsDataForMappedRowId(
              rowsData,
              null,
              tableData,
              null,
              rowIdDataMap,
              fileObjForCustomSort,
              {},
            );
            updatedTableData = processedData.tableData;
            updatedRowIdDataMap = processedData.rowIdDataMap;
          } else {
            updatedTableData = processRowsData(
              rowsData,
              null,
              tableData,
              null,
              fileObjForCustomSort,
            );
          }
        }
        return {
          success: true,
          areAllRowsFetched: rowsData.docs.length < limit,
          updatedTableData,
          updatedRowIdDataMap, //only for isTableDataOfRowIds=true
        };
      }
    }
  } catch (error) {
    captureError(error);
  }
  return {
    success: false,
  };
};

const updateEmptyRowIndexes = (editableArray, arr, isAdd) => {
  if (editableArray[0] === -1) {
    editableArray.splice(0, 1);
  }
  let editableIndexArray = editableArray.slice();
  if (isAdd) {
    editableIndexArray = [...editableIndexArray, ...arr];
  } else {
    editableIndexArray = editableIndexArray.filter(
      (rowId) => !arr.includes(rowId),
    );
  }

  if (!isAdd && editableIndexArray.length === 0) {
    return [-1];
  }

  return editableIndexArray;
};

const getEmptyRowIndex = (tableData, headerData) => {
  try {
    if (!tableData?.length) {
      return [];
    }
    const tableLength = tableData.length;
    const emptyRowIndexes = [];
    if (tableLength) {
      for (let i = 0; i < tableLength; i++) {
        if (checkIfEmptyRow(tableData[i], headerData)) {
          //check if row is empty or
          //all the cell values in row are empty
          emptyRowIndexes.push(tableData[i].rowId);
        }
      }

      if (emptyRowIndexes.length === 0) {
        return [-1];
      }
      return emptyRowIndexes;
    }
  } catch (error) {
    captureError(error);
  }
  return [];
};

const mergeDataFromTwoTables = (oldData, newData) =>
  new Promise((resolve, reject) => {
    try {
      // send data as {headerData,tableData}
      // merge newData in oldData

      // removing empty rows from bottom
      const oldTableData = oldData.tableData;
      let i = oldTableData.length;
      while (i > 0) {
        if (Object.keys(oldTableData[i - 1]).length > 1) break;
        i--;
      }

      if (i >= 0) {
        oldData.tableData.splice(i);
      }

      // newHeaderName={name:{oldData:colId,newData:colId}}
      const headerDataIndex = {};
      // colId: index
      const new_old_col_id_map = {};

      oldData.headerData.forEach(
        (data, index) => (headerDataIndex[data.val] = {oldDataIndex: index}),
      );
      newData.headerData.forEach((data, index) => {
        const oldHeaderIndexInfoOfCol = headerDataIndex[data.val];
        if (oldHeaderIndexInfoOfCol) {
          new_old_col_id_map[data.id] = oldHeaderIndexInfoOfCol.oldDataIndex;
          headerDataIndex[data.val] = {
            ...oldHeaderIndexInfoOfCol,
            newDataIndex: index,
          };
        } else headerDataIndex[data.val] = {newDataIndex: index};
      });

      const newHeaderData = [];

      Object.keys(headerDataIndex).forEach((colName) => {
        const data =
          isNumber(headerDataIndex[colName].oldDataIndex) &&
          headerDataIndex[colName].oldDataIndex >= 0
            ? oldData.headerData[headerDataIndex[colName].oldDataIndex]
            : newData.headerData[headerDataIndex[colName].newDataIndex];
        newHeaderData.push(data);
      });

      const oldTableDataLength = oldData.tableData.length;
      const newTableData = newData.tableData.map((row, index) => {
        const newRowObj = {rowId: getRowId(oldTableDataLength + index)};
        Object.keys(row).forEach((colId) => {
          const indexInOldData = new_old_col_id_map[colId];
          if (isNumber(indexInOldData)) {
            const oldColId = oldData.headerData[indexInOldData].id;
            newRowObj[oldColId] = row[colId];
            // updating footer
            const footer = oldData.footerData[oldColId];
            if (footer) {
              const val = Number(row[colId].val);
              if (val) {
                footer.count++;
                if (!isNaN(val)) footer.total += val;
                else footer.total = INVALID_INPUT;
              }
            }
          } else newRowObj[colId] = row[colId];
        });
        return newRowObj;
      });

      // header data and table data merged
      convertNewlyAppendedData({
        headerData: newHeaderData,
        tableData: newTableData,
      });

      //calculating footer data
      for (const colId in oldData.footerData) {
        const data = oldData.footerData[colId];
        if (data.total === INVALID_INPUT) data.val = INVALID_INPUT;
        else if (data.type === FOOTER_OPERATION_TYPES.TOTAL)
          data.val = data.total;
        else if (data.type === FOOTER_OPERATION_TYPES.AVERAGE)
          data.val = data.total / data.count;
      }

      resolve({
        newHeaderData,
        newTableData: [...oldData.tableData, ...newTableData],
        newFooterData: oldData.footerData,
      });
    } catch (err) {
      reject(err);
    }
  });

const shouldUseOriginalHeaderData = () => {
  try {
    const {home, table} = getReduxState();
    const isShared = !isEmpty(home.activeDocumentMeta?.collab);
    return (
      isShared &&
      [SHARE_PERMISSION_TYPE.CUSTOM].includes(
        home.activeDocumentMeta.collab.permission,
      ) &&
      table.originalHeaderData?.length > 0
    );
  } catch (err) {
    captureError(err);
  }
  return false;
};

const getRowObjForFirestore = (rowObj, index, rowId, rowProperties, sortKey) =>
  Object.assign({}, rowObj, {
    rowId: rowId ?? generateRowId(),
    index,
    isDeleted: false,
    rowProperties: rowProperties ?? getNewRowPropertiesObject(rowProperties),
    sortKey,
  });

/**
 * @param {*} startIndex : Use index above this index
 */
const prepareTableDataForFirestore = (tableDataArr, startIndex = null) => {
  const getIncrementalIndexForRowObj = (index) => {
    const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
    const range = 10;
    const minDiff = 2;
    const lowerIndex = index + minDiff;
    const higerIndex = lowerIndex + range;
    return random(lowerIndex, higerIndex);
  };
  try {
    startIndex = isNumber(startIndex) ? startIndex + 1 : 0;
    let lastIndex = startIndex;
    return tableDataArr.map((rowObj) => {
      const index = getIncrementalIndexForRowObj(lastIndex);
      lastIndex = index;
      return getRowObjForFirestore(
        rowObj,
        index,
        rowObj?.rowId,
        rowObj?.rowProperties,
        rowObj?.sortKey,
      );
    });
  } catch (err) {
    captureError(err);
  }
  return [];
};

class GetIncrementalIndexes {
  constructor(lowerBound, upperBound, numberOfIndexes = 1) {
    if (lowerBound > upperBound) {
      [lowerBound, upperBound] = [upperBound, lowerBound];
    }
    this.lowerBound = lowerBound;
    const getPrecisedValue = (val) => parseFloat(val?.toFixed?.(14));
    this.interval = getPrecisedValue(
      (upperBound - lowerBound) / (numberOfIndexes + 1),
    );
    this.possible =
      [this.lowerBound, upperBound, numberOfIndexes].every((val) =>
        isNumber(val),
      ) &&
      isNumber(this.interval) &&
      this.interval > 0;
  }

  areIndexesPossible() {
    return this.possible;
  }

  getNextIndex() {
    if (this.areIndexesPossible()) {
      this.lowerBound += this.interval;
      return this.lowerBound;
    }
    return 0;
  }
}

export {
  addRefToStorageRemovedRefs,
  checkAndUpdateEntryOnlyData,
  getTableData,
  updateEmptyRowIndexes,
  fetchEntryOnlyData,
  mergeDataFromTwoTables,
  shouldUseOriginalHeaderData,
  processRowsData,
  processRowsDataForMappedRowId,
  getRowObjStructured,
  prepareTableDataForFirestore,
  getEmptyRowIndex,
  GetIncrementalIndexes,
  makeRowObjFromRowDoc,
  getRowObjForFirestore,
  getPaginatedTableDataWrapper,
  updateChildLinks,
  getRowIdRowDataMapping,
  handleVideoUpload,
  getTableSortingOrder,
};
