export const COPY_MOVE_SET_EMPTY_MAP = {
  defaultValMap: null,
  defaultValConstantMap: null,
  headerMapping: null,
  aggregationColMap: null,
  endpointColMap: null,
};

export const DOC_OPERATIONS_ADDITIONAL_DATA_SOURCE_EMPTY_MAP = {
  ...COPY_MOVE_SET_EMPTY_MAP,
  additionalDataSource: null,
  aggregationColMap: null,
  endpointColMap: null,
};
