import {firestore} from '../../imports';

/**
 * Firestore rules:
 *
 * match /organisation/{organisationId} {
 *   allow read: if request.auth.uid != null
 * }
 *
 * */

export default class OrganisationsDB {
  collection(firestoreInstance) {
    return (firestoreInstance ?? firestore)().collection('organisation');
  }

  doc(docId, firestoreInstance) {
    return this.collection(firestoreInstance).doc(docId);
  }

  subscription(docId, firestoreInstance) {
    return this.collection(firestoreInstance)
      .doc(docId)
      .collection('subscription')
      .doc('mapping');
  }

  teams(orgId) {
    return this.collection().doc(orgId).collection('teams');
  }

  getOrganistaionTeams(orgId, teamId) {
    return this.teams(orgId).doc(teamId);
  }
}
