import React from 'react';
import {ButtonBase} from '@material-ui/core';
import TextComponent from '../../../textComponent/TextComponent';
import IconComponent from '../../iconComponents/IconComponent';
import CreateNewTeamIcon from '../../../../assets/img/createNewTeamIcon.svg';
import useStyles from '../menuComponentStyles';

export const FooterCreateNewTeamComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.createNewTeamFooterContainer}>
      <IconComponent option={CreateNewTeamIcon} />
      <TextComponent text={'Create new team'} active />
    </div>
  );
};
