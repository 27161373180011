import {TRASH_ACTIONS} from '../actions/actionType';

const initialState = [];

const TrashReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRASH_ACTIONS.LOAD_TRASH_FILES:
      return action.payload;
    default:
      return state;
  }
};

export default TrashReducer;
