import {HOME_ACTION} from '../actions/actionType';
import {ROOT_FOLDER} from '../utils/constant';
import {forOwn} from 'lodash';

const initialState = {
  allFilesFetched: false,
  files: [],
  activeDocumentId: '', //ID of active-activePage(in case of multipage)
  originalDocumentId: '', //ID of active-document
  activeDocumentName: '',
  activeDocumentMeta: {},
  isSharedPagesMetaLoading: false,
  activeFileIndex: 0,
  userTemplates: {},
  showLoader: false,
  visibleFiles: [],
  activeFolder: {
    name: '',
    id: ROOT_FOLDER,
    index: null,
  },
  folderStack: [],
  listenersObj: {},
  businessCategories: {},
  userDefinedUnits: [],
  helpVideos: [],
  communities: [],
  whatsNewVideos: [],
  whatsNewVideosLatestVersion: null,
  businessTypes: [],
  greetingsList: [],
  tutorialVideos: [],
  smartTemplateDocs: [],
  whatsappShare: {isShare: false, isBusiness: null, options: null},
  modalPopUp: {isVisible: false, options: null},
  isLoggingOut: false,
  userStories: [],
  filesBeingDeleted: {
    all: [],
    inProgress: [],
  },
  activePageIdCacheForViewEntry: '',
  globalLoadingState: {
    isLoading: false,
  },
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOME_ACTION.SET_USER_STORIES: {
      return {
        ...state,
        userStories: action.payload,
      };
    }
    case HOME_ACTION.SET_ALL_FILES_FETCHED: {
      return {
        ...state,
        allFilesFetched: true,
      };
    }
    case HOME_ACTION.INVOKE_POP_UP_MODAL: {
      return {
        ...state,
        modalPopUp: action.payload,
      };
    }
    case HOME_ACTION.SET_FILES_BEING_DELETED: {
      return {
        ...state,
        filesBeingDeleted: Object.assign(
          {},
          state.filesBeingDeleted,
          Array.isArray(action.payload.all) ? {all: action.payload.all} : null,
          Array.isArray(action.payload.inProgress)
            ? {inProgress: action.payload.inProgress}
            : null,
        ),
      };
    }
    case HOME_ACTION.CLOSE_POP_UP_MODAL: {
      return {
        ...state,
        modalPopUp: {isVisible: false, options: null},
      };
    }
    case HOME_ACTION.LOAD_FILES: {
      const extra = {};
      if (action.payload.visibleFiles) {
        Object.assign(extra, {visibleFiles: action.payload.visibleFiles});
      }
      if (action.payload.activeFolder) {
        Object.assign(extra, {activeFolder: action.payload.activeFolder});
      }
      return {
        ...state,
        ...extra,
        files: action.payload.files,
      };
    }
    case HOME_ACTION.UPDATE_VISIBLE_FOLDER: {
      return {
        ...state,
        visibleFiles: action.payload.visibleFiles,
        activeFolder: action.payload.activeFolder,
        folderStack: action.payload.folderStack,
        showLoader: false,
      };
    }
    case HOME_ACTION.CREATE_NEW_FILE: {
      const arr = state.files.slice();
      // const documentMeta = {
      //   colorCode: action.payload.colorCode,
      //   timestamp: action.payload.timestamp,
      //   name: action.payload.fileName,
      //   viewType: action.payload.viewType,
      //   fileData: action.payload.fileData,
      //   ...action.payload.pageMetaData,
      // };
      arr.splice(0, 0, {
        documentId: action.payload.activeDocumentId,
        documentMeta: action.payload.documentMeta,
      });
      const activeDoc = action.payload.isFolder
        ? {}
        : {
            activeDocumentId: action.payload.activeDocumentId,
            activeDocumentName: action.payload.documentMeta.name,
            activeDocumentMeta: action.payload.documentMeta,
            originalDocumentId: action.payload.activeDocumentId,
          };
      return {
        ...state,
        files: arr,
        ...activeDoc,
      };
    }
    case HOME_ACTION.LOAD_NEW_CREATED_FILE_DATA: {
      const newData = {
        activeDocumentId: action.payload.activeDocumentId,
        activeDocumentName: action.payload.documentMeta?.name,
        activeDocumentMeta: action.payload.documentMeta,
        originalDocumentId: action.payload.activeDocumentId,
      };
      return {
        ...state,
        ...newData,
      };
    }
    case HOME_ACTION.DUPLICATE_FILE: {
      const filesArr = state.files.slice();
      filesArr.splice(0, 0, {
        documentId: action.payload.docId,
        documentMeta: action.payload.meta,
      });
      return {
        ...state,
        files: filesArr,
      };
    }
    case HOME_ACTION.OPEN_FILE: {
      return {
        ...state,
        activeDocumentId: action.payload.activeDocumentId,
        activeDocumentName: action.payload.activeFileName,
        activeDocumentMeta: action.payload.activeFileMeta,
        activeFileIndex: action.payload.activeFileIndex,
        originalDocumentId: action.payload.originalDocumentId,
      };
    }
    case HOME_ACTION.START_SHARED_PAGES_META_LOADING: {
      return {
        ...state,
        isSharedPagesMetaLoading: true,
      };
    }
    case HOME_ACTION.STOP_SHARED_PAGES_META_LOADING: {
      return {
        ...state,
        isSharedPagesMetaLoading: false,
      };
    }
    case HOME_ACTION.DELETE_FILE: {
      const isFilePresent =
        state.files[action.payload.index]?.documentId === action.payload.docId;
      if (isFilePresent) {
        const updatedFilesArr = state.files.slice();
        updatedFilesArr.splice(action.payload.index, 1);
        return {
          ...state,
          files: updatedFilesArr,
        };
      }
      return state;
    }
    case HOME_ACTION.ADD_TEMPLATE:
      return {
        ...state,
        userTemplates: Object.assign({}, action.payload, state.userTemplates),
      };
    case HOME_ACTION.LOAD_TEMPLATES:
      return {
        ...state,
        userTemplates: action.payload,
      };
    case HOME_ACTION.SHOW_LOADER:
      return {
        ...state,
        showLoader: true,
        isLoggingOut: action?.payload?.isLoggingOut ?? false,
      };
    case HOME_ACTION.HIDE_LOADER:
      return {
        ...state,
        showLoader: false,
        isLoggingOut: false,
      };
    case HOME_ACTION.UPDATE_ACTIVE_FILE_INDEX: {
      return {
        ...state,
        activeFileIndex: action.payload,
      };
    }
    //same action for both cases
    case HOME_ACTION.CLEAR_HOME_STATE:
    case HOME_ACTION.RESET_STATE: {
      try {
        forOwn(state.listenersObj, (listener) => {
          if (typeof listener === 'function') {
            listener();
          }
        });
      } catch (e) {}
      return {
        ...initialState,
      };
    }
    case HOME_ACTION.UPDATE_DOCUMENT_META:
      return {
        ...state,
        activeDocumentMeta: action.payload,
      };
    case HOME_ACTION.CLEAR_LAST_HOME_STATE: {
      return {
        ...state,
        activeDocumentId: '',
        originalDocumentId: '',
        activeDocumentName: '',
        activeDocumentMeta: {},
        activeFileIndex: 0,
        activePageIdCacheForViewEntry: '',
      };
    }
    case HOME_ACTION.UPDATE_SELECTED_PAGE: {
      return {
        ...state,
        activeDocumentId: action.payload.activeDocumentId,
        activeDocumentMeta: action.payload.activeDocumentMeta,
      };
    }
    case HOME_ACTION.SET_PAGEID_CACHE_FOR_VIEW_ENTRY: {
      return {
        ...state,
        activePageIdCacheForViewEntry: action.payload.pageId,
      };
    }
    case HOME_ACTION.DELETE_MY_TEMPLATE: {
      const userTemplates = state.userTemplates;
      delete userTemplates[action.payload];
      return {
        ...state,
        userTemplates: userTemplates,
      };
    }
    case HOME_ACTION.RENAME_MY_TEMPLATE: {
      const userTemplates = {...state.userTemplates};
      userTemplates[action.payload.templateId].name = action.payload.newName;
      return {
        ...state,
        userTemplates: userTemplates,
      };
    }
    case HOME_ACTION.UPDATE_HOME_LISTENER_OBJ: {
      return {
        ...state,
        listenersObj: Object.assign({}, state.listenersObj, action.payload),
      };
    }
    case HOME_ACTION.DELETE_PAGE:
    case HOME_ACTION.ADD_NEW_PAGE: {
      return {
        ...state,
        activeDocumentId: action.payload.activeDocumentId,
        activeDocumentMeta: action.payload.activeDocumentMeta,
      };
    }
    case HOME_ACTION.UPDATE_GLOBAL_LOADING_STATE: {
      return {
        ...state,
        globalLoadingState: Object.assign({}, action.payload, {
          isLoading: Boolean(action.payload?.isLoading),
          text:
            typeof action.payload?.text === 'string'
              ? action.payload?.text
              : '',
        }),
      };
    }
    case HOME_ACTION.TOGGLE_DOCUMENT_PAGES: {
      return {
        ...state,
        activeDocumentId: action.payload.activeDocumentId,
        activeDocumentMeta: action.payload.activeDocumentMeta,
      };
    }
    case HOME_ACTION.UPDATE_HOME_STATES: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case HOME_ACTION.SET_USER_UNITS: {
      return {
        ...state,
        userDefinedUnits: action.payload,
      };
    }
    case HOME_ACTION.SET_GREETINGS: {
      return {
        ...state,
        greetingsList: action.payload,
      };
    }
    case HOME_ACTION.GET_VIDEOS: {
      return {
        ...state,
        helpVideos: action.payload,
      };
    }
    case HOME_ACTION.MODIFY_SMART_TEMPLATE_DOCS: {
      return {
        ...state,
        smartTemplateDocs: action.payload,
      };
    }
    case HOME_ACTION.GET_COMMUNITIES: {
      return {
        ...state,
        communities: (action.payload ?? []).sort(function (a, b) {
          return typeof b === 'object' &&
            typeof a === 'object' &&
            Object.keys(b).includes('priority') &&
            Object.keys(a).includes('priority')
            ? b.priority - a.priority
            : -1;
        }),
      };
    }
    case HOME_ACTION.GET_WHATS_NEW: {
      return {
        ...state,
        whatsNewVideos: action.payload.videos,
        whatsNewVideosLatestVersion: action.payload.version,
      };
    }
    case HOME_ACTION.FETCH_BUSINESS_TYPES: {
      return {
        ...state,
        businessTypes: action.payload,
      };
    }
    case HOME_ACTION.FETCH_BUSINESS_CATEGORIES: {
      return {
        ...state,
        businessCategories: action.payload,
      };
    }
    case HOME_ACTION.UPDATE_FOLDER_STACK: {
      return {
        ...state,
        folderStack: action.payload,
      };
    }
    case HOME_ACTION.SET_TUTORIAL_VIDEOS: {
      return {
        ...state,
        tutorialVideos: action.payload,
      };
    }
    case HOME_ACTION.SHARE_ON_WHATSAPP: {
      return {
        ...state,
        whatsappShare: {...action.payload},
      };
    }
    case HOME_ACTION.UPDATE_TEMPLATES_MIGRATION_LOADER:
      return {
        ...state,
        areMyTemplatesMigrating: action.payload,
      };
    default:
      return state;
  }
};

export default homeReducer;
