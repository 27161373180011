import {PAGINATION_ACTION} from '../actions/actionType';

const initialState = {
  current_document_page: 1,
  current_trash_page: 1,
};

const Pagination = function (state = initialState, action) {
  switch (action.type) {
    case PAGINATION_ACTION.UPDATE_DOCUMENT_CURRENT_PAGE:
      return {...state, current_document_page: action.payload};
    case PAGINATION_ACTION.UPDATE_TRASH_CURRENT_PAGE:
      return {...state, current_trash_page: action.payload};
    default:
      return state;
  }
};

export default Pagination;
