import {MODAL_ACTION} from '../actions/actionType';

const initialState = {
  isModalOpen: false,
};

const modalReducer = function (state = initialState, action) {
  switch (action.type) {
    case MODAL_ACTION.MODAL_OPENED:
      return {isModalOpen: true};
    case MODAL_ACTION.MODAL_CLOSED:
      return {isModalOpen: false};
    default:
      return state;
  }
};
export default modalReducer;
