import {COLLABORATION_ACTION} from '../actions/actionType';

const initialState = {
  isShared: false,
  isLoading: false,
  sharedWith: [],
  ownerDetails: {},
  activeUsers: {},
  activeUsersDetails: {},
  activeUsersCount: 0,
  colorMap: {},
  listenersObj: {},
};

const collab = (state = initialState, action) => {
  switch (action.type) {
    case COLLABORATION_ACTION.SET_COLLAB_DOC_TRUE:
      return {
        ...state,
        isShared: true,
      };
    case COLLABORATION_ACTION.START_COLLAB_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case COLLABORATION_ACTION.STOP_COLLAB_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case COLLABORATION_ACTION.UPDATE_ACTIVE_USERS_DETAILS:
      return {
        ...state,
        activeUsersDetails: Object.assign(
          {},
          action.payload.activeUsersDetails,
        ),
      };
    case COLLABORATION_ACTION.ACTIVATE_DEACTIVATE_LISTENERS:
      return {
        ...state,
        listenersObj: Object.assign({}, action.payload),
      };
    case COLLABORATION_ACTION.LOAD_COLLAB_META:
      return {
        ...state,
        sharedWith: action.payload.sharedWith,
        ownerDetails: action.payload.ownerDetails,
      };
    case COLLABORATION_ACTION.UPDATE_OWNER_DETAILS:
      return {
        ...state,
        ownerDetails: action.payload.ownerDetails,
      };
    case COLLABORATION_ACTION.CLEAR_LAST_COLLAB_STATE:
      return {
        ...initialState,
      };
    case COLLABORATION_ACTION.UPDATE_ACTIVE_USERS: {
      return {
        ...state,
        ...action.payload.extra,
        activeUsers: action.payload.activeUsers,
        activeUsersCount: action.payload.activeUsersCount,
      };
    }
    case COLLABORATION_ACTION.UPDATE_SHARED_WITH:
      return {
        ...state,
        sharedWith: action.payload,
      };
    default:
      return state;
  }
};
export default collab;
