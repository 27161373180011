import moment from 'moment';
import {SUBSCRIPTION_GRACE_PERIOD} from './constant';
import LioArray from './CustomClass/LioArray';
import {SUBSCRIPTION_PLAN} from './organisation';
import {isEmpty} from 'lodash';

const ORGANISATION_PLANS = Object.assign({}, SUBSCRIPTION_PLAN, {FREE: 'FREE'});

const getOrganisationUserInfo = (uid, ownerUID, ownerInfo, membersList) => {
  if (uid === ownerUID) {
    return ownerInfo;
  }
  return membersList?.[uid] ?? {};
};

const getOrganisationUserName = (uid, ownerUID, ownerInfo, membersList) => {
  if (uid === ownerUID) {
    const {displayName, name, m_name, phoneNumber, email} = ownerInfo ?? {};
    return displayName || name || m_name || phoneNumber || email;
  }
  const {displayName, name, m_name, phoneNumber, email} =
    membersList?.[uid] ?? {};
  return displayName || name || m_name || phoneNumber || email;
};

const isPlanCurrentOrganisationPlan = (
  plan,
  billingCycle,
  currentOrganisationPlan,
  existingBillingCycle,
) => {
  return (
    currentOrganisationPlan === plan && billingCycle === existingBillingCycle
  );
};

const getPricePerPersonPerMonth = (price, isYearly) => {
  return (price / (isYearly ? 12 : 1)).toFixed(2);
};

const getTotalPricePerPersonPerYear = (price, isYearly) => {
  return (price * (isYearly ? 1 : 12)).toFixed(2);
};

const getPlanExpiryDate = (activeTill = '') => {
  return Object.prototype.toString.call(activeTill) !== '[object Date]'
    ? activeTill?.toDate?.()
    : activeTill?.toDateString?.();
};

const getDaysRemainingForExpiry = (activeTill = '') => {
  const expiryDateUnix = moment(activeTill).unix();
  const currentUnix = moment().unix();
  const diff = expiryDateUnix - currentUnix;

  return Math.ceil(diff / (60 * 60 * 24.0));
};

const getFreeTrialDaysLeft = (planExpiry) => {
  try {
    const currentDateTime = new Date();
    return Math.ceil((planExpiry - currentDateTime) / (1000 * 60 * 60 * 24));
  } catch (error) {}
  return -1;
};

const getGracePeriodDaysLeft = (activeTillDate) => {
  try {
    const graceEndsDate = moment(activeTillDate).add(
      SUBSCRIPTION_GRACE_PERIOD,
      'days',
    );
    return graceEndsDate.diff(moment(), 'days');
  } catch (error) {}
  return -1;
};

const getCommonSubscriptionParamsForEventLogs = (organisation) => {
  const orgSubscriptionParams = {};
  try {
    if (organisation) {
      const {
        orgSubscriptions: {isActive, isTrial, plan, totalSeats, subscriptionId},
        membersList,
      } = organisation;
      Object.assign(orgSubscriptionParams, {
        freeTrialDaysLeft: isTrial ? getFreeTrialDaysLeft(organisation) : -1,
        userActivePlanType: !isActive
          ? ORGANISATION_PLANS.FREE
          : isTrial
          ? ORGANISATION_PLANS.TRIAL
          : plan?.planName,
        userActivePlanCycle: plan?.frequency,
        p_purchasedSeats: totalSeats,
        p_usedSeats: (membersList?.length ?? 0) + 1,
        p_planExpiry: plan?.activeTill,
        p_subscriptionId: subscriptionId,
      });
    }
  } catch (error) {}
  return orgSubscriptionParams;
};

const getSortedMembersList = (
  membersListObj,
  sortingKeys = ['createdTimestamp'],
  isDesc = false,
) => {
  const sortedMembersArr = new LioArray();
  Object.entries(Object.assign({}, membersListObj)).forEach(
    ([uid, memberObj]) => {
      sortedMembersArr.binaryInsert(
        Object.assign({}, memberObj, {uid}),
        sortingKeys,
        'duplicate',
        isDesc,
      );
    },
  );
  return sortedMembersArr;
};

const getAllMembersObject = (organisationData) => {
  const {ownerUID, owner, members} = organisationData || {};
  return Object.assign({}, members, {[ownerUID]: owner});
};

const getAllMembersWithUIDObject = (organisationData) => {
  const membersAndOwner = getAllMembersObject(organisationData);
  return Object.keys(membersAndOwner).reduce((prev, curr) => {
    prev[curr] = Object.assign({}, membersAndOwner[curr], {uid: curr});
    return prev;
  }, {});
};

const getMemberUsingUID = (organisationData, uid) => {
  return getAllMembersWithUIDObject(organisationData)[uid];
};

const getOrgMobileNumber = (memberObj) => {
  return memberObj?.m_phone?.length
    ? memberObj.m_phone
    : memberObj?.phoneNumber?.length
    ? memberObj.phoneNumber
    : null;
};

const getOrgEmailForMember = (memberObj) => {
  return memberObj?.m_email?.length
    ? memberObj.m_email
    : memberObj?.email
    ? memberObj.email
    : null;
};

const mappingFunction = (memberObj, valueToMap) => {
  const email = memberObj?.email?.length ? memberObj.email : null;
  const m_mail = memberObj?.m_email?.length ? memberObj.m_email : null;
  const phoneNumber = getOrgMobileNumber(memberObj);
  return Object.assign(
    phoneNumber ? {[phoneNumber]: valueToMap} : {},
    email ? {[email]: valueToMap} : {},
    m_mail ? {[m_mail]: valueToMap} : {},
  );
};

const getOrgContactToUIDMapping = (organisationData) => {
  const membersAndOwnerWithUIDs = getAllMembersWithUIDObject(organisationData);

  return !isEmpty(membersAndOwnerWithUIDs)
    ? Object.values(membersAndOwnerWithUIDs).reduce((prev, memberObj) => {
        return Object.assign(prev, mappingFunction(memberObj, memberObj?.uid));
      }, {})
    : {};
};

export {
  getMemberUsingUID,
  getOrgContactToUIDMapping,
  getCommonSubscriptionParamsForEventLogs,
  getFreeTrialDaysLeft,
  getGracePeriodDaysLeft,
  getOrganisationUserInfo,
  getOrganisationUserName,
  getPlanExpiryDate,
  getPricePerPersonPerMonth,
  getTotalPricePerPersonPerYear,
  isPlanCurrentOrganisationPlan,
  getSortedMembersList,
  getDaysRemainingForExpiry,
};
