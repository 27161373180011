import {AUTH_ACTION} from '../actions/actionType';

const initialState = {
  user: null,
  userPref: {},
  isNewUser: false,
  userCountry: 'IN', //default
  profileCompletePercent: 0,
  whatsappNumber: null,
  devices: {},
  flipkartLoginSuccess: false,
  isFlipkartFileCreated: false,
  disabledTemplates: {},
  userAutomationConfig: {},
  isMultiLoginModalVisible: false,
  loginListener: {},
  companyInfo: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_ACTION.LOAD_USER_AUTH:
      return {
        ...state,
        user: action.payload,
      };
    case AUTH_ACTION.LOAD_USER_ACTIVE_DEVICES:
      return {
        ...state,
        devices: action.payload,
      };
    case AUTH_ACTION.SET_LOGIN_LISTENER:
      return {
        ...state,
        loginListener: action.payload,
      };
    case AUTH_ACTION.LOAD_USER_PREF:
      return {
        ...state,
        userPref: Object.assign({}, action.payload),
      };
    case AUTH_ACTION.LOAD_USER_AUTOMATION_CONFIG:
      return {
        ...state,
        userAutomationConfig: Object.assign({}, action.payload),
      };
    case AUTH_ACTION.RESET_STATE:
      return {
        ...state,
        user: null,
        devices: {},
        loginListener: {},
        userPref: {lang: action?.payload?.lang ?? 'EN'},
        isNewUser: false,
      };
    case AUTH_ACTION.SET_NEW_USER_FLAG:
      return {
        ...state,
        isNewUser: action.payload,
      };
    case AUTH_ACTION.SET_USER_COUNTRY: {
      return {
        ...state,
        userCountry: action.payload.country,
      };
    }
    case AUTH_ACTION.SET_PROFILE_PERCENT: {
      return {
        ...state,
        profileCompletePercent: action.payload,
      };
    }
    case AUTH_ACTION.UPDATE_DISABLED_TEMPLATES: {
      return {
        ...state,
        disabledTemplates: action.payload,
      };
    }
    case AUTH_ACTION.UPDATE_WHATSAPP_NUMBER: {
      return {
        ...state,
        whatsappNumber: action.payload,
      };
    }
    case AUTH_ACTION.LOAD_FLIPKART_META: {
      return {
        ...state,
        flipkartLoginSuccess: action.payload.flipkartLoginSuccess,
        isFlipkartFileCreated: action.payload.isFlipkartFileCreated,
      };
    }
    case AUTH_ACTION.SET_MULTI_LOGIN_MODAL: {
      return {
        ...state,
        isMultiLoginModalVisible: action.payload.isVisible ? true : false,
      };
    }
    default:
      return state;
  }
};
export default authReducer;
