import {CLOUD_FUNCTION_PATHS} from '../../utils/constant';
import {isArray} from 'lodash';
import {backOff} from 'exponential-backoff';
import {ENV, firestore, database, functions, captureError} from '../../imports';
import {getDisplayName} from './contactActionHelper';
import {handleCloudError} from '../../utils/utils';

const searchContactInPhone = async (
  contact,
  isEmail = false,
  permissionGranted = true,
  checkPermission = false,
  userPref,
  showPermissionPopUp = true,
) => {
  //mobile only
  const result = {
    phoneNumbers: isEmail ? [] : [contact],
    emailAddresses: isEmail ? [contact] : [],
  };
  try {
    if (checkPermission) {
      if (ENV) {
        const {checkContactsReadPermission} = require('../../imports');
        permissionGranted = await checkContactsReadPermission(
          userPref,
          showPermissionPopUp,
        );
      } else {
        permissionGranted = false;
      }
    }
    if (permissionGranted) {
      let ContactsVar;
      if (ENV) {
        const {Contacts} = require('../../imports');
        ContactsVar = Contacts;
      }
      const searchMethod = isEmail
        ? ContactsVar().getContactsByEmailAddress
        : ContactsVar().getContactsByPhoneNumber;
      const details = await searchMethod(contact);
      if (isArray(details) && details[0]) {
        const {hasThumbnail, thumbnailPath} = details[0];
        Object.assign(result, {
          displayName: getDisplayName(details[0]),
          hasThumbnail,
          thumbnailPath,
        });
      }
    }
  } catch (e) {}
  return result;
};

const formatSharedWith = async (
  sharedWithObj,
  userUID,
  selfText = 'Me',
  userPref,
  showPermissionPopUp = true,
) => {
  /**
   * format sharedWith as obj -> sharedWith as array
   * in required form
   */
  let permissionAlreadyGranted;
  if (ENV) {
    const {checkContactsReadPermission} = require('../../imports');
    permissionAlreadyGranted = await checkContactsReadPermission(
      userPref,
      showPermissionPopUp,
    );
  } else {
    permissionAlreadyGranted = false;
  }
  const sharedWithArr = [];
  try {
    const process = (key, value) =>
      new Promise((resolve) => {
        const isSelf = key === userUID;
        const obj = {
          displayName: isSelf ? selfText : value.name ?? '', //self only for admins
          hasThumbnail: false,
          uid: key,
          addedBy: value.addedBy,
          permission: value.permission,
          isSelf,
          phoneNumbers: value.isEmail ? [] : [value.contact],
          emailAddresses: value.isEmail ? [value.contact] : [],
          restrictions: Object.assign({}, value.restrictions),
          properties: Object.assign({}, value.properties),
        };
        if (!isSelf && permissionAlreadyGranted) {
          searchContactInPhone(
            value.contact,
            value.isEmail,
            true,
            false,
            userPref,
          ).then((res) => {
            Object.assign(obj, res);
            sharedWithArr.push(obj);
            resolve();
          });
        } else {
          sharedWithArr.push(obj);
          resolve();
        }
      });
    await Promise.all(
      Object.entries(Object.assign({}, sharedWithObj)).map(([key, value]) =>
        process(key, value),
      ),
    );
  } catch (error) {
    captureError(error);
  }
  return sharedWithArr;
};

const fetchCollabData = (docId) => {
  return new Promise((resolve, reject) => {
    try {
      let result = {};
      backOff(
        () =>
          firestore()
            .collection('collaborationMeta')
            .doc(docId)
            .get()
            .then((dataSnap) => {
              if (dataSnap?.exists) {
                result = dataSnap.data();
              }
              return resolve(result);
            })
            .catch((error) => {
              captureError(error);
              return reject(error);
            }),
        {numOfAttempts: 3},
      );
    } catch (error) {
      captureError(error);
      reject(error);
    }
  });
};

const updateCollabActiveUsersData = (
  docId,
  uid,
  updateObj,
  isRemove = false,
) => {
  try {
    const dbRef = database().ref(`/collabDocActiveUsers/${docId}/${uid}`);
    if (isRemove) {
      dbRef.remove();
    } else {
      dbRef
        .update(updateObj)
        .then()
        .catch((err) => {
          throw err;
        });
    }
  } catch (error) {
    captureError(error);
  }
};

const getCollabActiveUsersDetails = async (docId) => {
  try {
    const Snapshot = await database()
      .ref(`/collabActiveUsersDetails/${docId}`)
      .once('value');
    if (Snapshot.exists) {
      const val = Snapshot.val();
      if (val) {
        return val;
      }
    }
  } catch (error) {
    captureError(error);
  }
  return false;
};

const shareCollabFile = async (obj) => {
  try {
    const response = await functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.SHARE_DOC_TO_CONTACTS,
    )(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const changeCollabUserPermission = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.CHANGE_SHARED_DOC_PERMISSION,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const removeSharedDocFromUID = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.REMOVE_SHARED_DOC_FROM_UID,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const disablePagesCollabDoc = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.DISABLE_COLLAB_PAGES,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const addEntryOnlyDataFunction = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.ADD_ENTRY_ONLY_DATA,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const manageEntryOnlyHeader = async (obj) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.MANAGE_ENTRY_ONLY_HEADER,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

const unshareWithAllUsersCloud = async (obj) => {
  try {
    const functionInstance = await functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.UNSHARE_FILE_WITH_ALL_USERS,
    );
    const response = await functionInstance(obj);
    return response.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

export {
  formatSharedWith,
  fetchCollabData,
  updateCollabActiveUsersData,
  getCollabActiveUsersDetails,
  shareCollabFile,
  changeCollabUserPermission,
  removeSharedDocFromUID,
  addEntryOnlyDataFunction,
  searchContactInPhone,
  disablePagesCollabDoc,
  manageEntryOnlyHeader,
  unshareWithAllUsersCloud,
};
