export default ({option1, option2, styleInfo, containerStyle}) => {
  return (
    <>
      <div style={containerStyle ? containerStyle : {position: 'relative'}}>
        <img src={option1} style={styleInfo} />
        <img src={option2} />
      </div>
    </>
  );
};
