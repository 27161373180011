import {WEBINARS_ACTIONS} from '../actions/actionType';

const initialState = {
  upcomingWebinars: {},
  registerWebinarIds: {},
  recordedWebinarSession: {},
};

const webinarReducer = (state = initialState, action) => {
  switch (action.type) {
    case WEBINARS_ACTIONS.LOAD_WEBINARS: {
      return {
        ...state,
        upcomingWebinars: action.payload,
      };
    }
    case WEBINARS_ACTIONS.LOAD_RECORDED_WEBINARS: {
      return {
        ...state,
        recordedWebinarSession: action?.payload ?? {},
      };
    }
    case WEBINARS_ACTIONS.LOAD_REGISTERED_WEBINARS: {
      return {
        ...state,
        registerWebinarIds: action.payload,
      };
    }
    case WEBINARS_ACTIONS.REGISTER_FOR_WEBINAR: {
      return {
        ...state,
        registerWebinarIds: Object.assign(
          {},
          state.registerWebinarIds,
          action.payload,
        ),
      };
    }
    case WEBINARS_ACTIONS.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

export default webinarReducer;
