import {createTheme} from '@material-ui/core/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 670,
      md: 860,
      lg: 1024,
      xl: 1440,
    },
  },
  // fontSize: {
  //   font17: '17px',
  //   font16: '16px',
  //   font15: '15px',
  //   font14: '14px',
  //   font13: '13px',
  //   font12: '12px',
  //   font11: '12px',
  //   font10: '12px',
  //   font9: '12px',
  // },
  fontSize: {
    font26: '1.625rem',
    font24: '1.5rem',
    font22: '1.375rem',
    font20: '1.25rem',
    font19: '19px',
    font18: '1.125rem',
    font17: '1.063rem',
    font16: '1rem',
    font15: '.938rem',
    font14: '.875rem',
    font13: '.813rem',
    font12: '.75rem',
    font11: '.75rem',
    font10: '.75rem',
    font9: '.75rem',
  },
  lineHeight: {
    lineHeight32: '32px',
    lineHeight28: '28px',
    lineHeight24: '24px',
    lineHeight22: '22px',
    lineHeight20: '20px',
    lineHeight19: '19px',
    lineHeight18: '18px',
    lineHeight17: '17px',
    lineHeight16: '16px',
    lineHeight15: '15px',
    lineHeight14: '14px',
    lineHeight13: '13px',
    lineHeight12: '12px',
    lineHeight11: '11px',
    lineHeight0: '0px',
  },
  palette: {
    primary: {main: '#337EED'},
    textNav: '#556A89',
    textDark: '#262626',
    textLight: '#8C8C8C',
    iconDark: '#7E99C1',
    iconLight: '#B1C3DF',
    brandBlue: '#337EED',
    blueLight: '#6798E1',
    navHover: '#F2F5F9',
    navHoverDark: '#DDE8F6',
    border: '#E4EAF2',
    utilsBorder: '#E4EAF2',
    navBorder: '#BFCCE0',
    navSearch: '#F2F5F9',
    navSearchBg: '#F2F5F970',
    contextMenuShadow: `0px 4px 12px 0px #7F9AC129`,
    toolTipBg: '#262626',
    navHoverBg: '#EBF2FD',
    red: '#F5222D',
    green: '#6CB424',
    greenText: '#449F35',
    greenBg: '#449f34',
    black: '#000000',
    white: '#FFFFFF',
    noColor: 'none',
    navNotification: '#FF4848',
    yellowBg: '#FFFBE6',
    yellowText: '#AD6800',
    redFade: '#FFF1F0',
    blackBackground: '#131313',
    lightBlackBackground: '#242424',
    messageBarBg: '#FFFBE6',
    textBrown: '#AD6800',
    textBlack: '#49454F',
    lightYellowBg: '#FFFBE6',
    lightGreenBg: '#F6FFED',
    greenText: '#237804',
    lowPriority: '#FF8C22',
    highPriority: '#ED5525',
    noPriority: '#DFDFDF',
    brandPurple: '#791BEE',
    lightPurple: '#F8F2FF',
    yellow: 'rgba(241, 164, 47, 0.1)',
    taskTopbarBg: 'rgba(167, 202, 255, 0.12)',
    success: {main: '#5EAF42'},
    lightBlueBg: '#E8F9FF',
    blueText: '#0079AD',
    redText: '#A8071A',
    redText2: '#E02A2A',
    greyColor: '#556A89',
    darkYellow: '#C17A10',
    lightYellow: '#FEF6EA',
    gray100: '#F2F5F9',
    gray200: '#E4EAF2',
    gray300: '#BFCCE0',
    gray400: '#7E99C1',
    gray500: '#495A74',
    gray900: '#556A89',
    lightRed: '#FCEAEA',
    lightPrimary: '#EBF2FD',
    textBody: '#31435E',
    textWhite: '#FFFFFF',
    textPrimary: '#3E4C60',
    textTitle: '#1C2C44',
    pink: '#FF2E79',
    lightPink: '#F6EFFE',
    yellowPro: '#F1A42F',
    utilDivider: '#F1F2F4',
    liteYellow: '#FEF6EA',
    liteRed: '#FCEAEA',
    colorsRed: '#E02A2A',
    colorsPrimary: '#337EED',
    colorWhite: '#FFF',
    lightRedBg: '#FDF5F5',
    darkGray: '#3B4E6B',
    colorsGreen: '#449F35',
    textLink: '#337EED',
    darkPrimary: '#0B4BAA',
    darkRed: '#B41919',
    liteGreen: '#ECF5EB',
    greenSuccess: '#276B1C',
    lightPurpleIcon: '#F2E8FD',
    darkCyan: '#0DB2CA',
    darkBlue: '#0A1A33',
    purple: '#5D40AF',
    darkBlueHover: '#102445',
    darkBlueHeader: '#0F2548',
    darkBlueSelected: '#1A3764',
    newGray: '#495A74',
    clearButtonRed: '#cc4b4b',
    newDark: '#90ABD3',
    dark900: '#0A1A33',
    dark800: '#0F2548',
    dark700: '#152D54',
    dark600: '#1A3764',
    lightGrayDashboard: '#F5F9FF',
    lightGrayBackground: '#DDE2EB',
    fields: {
      icon: '#7E99C1',
      placeholder: '#7E99C1',
      border: '#BFCCE0',
      borderFocus: '#337EED',
      background: '#FFFFFF',
      value: '#262626',
      label: '#495A74',
    },
    textLink: '#337EED',
    cyan: '#08C9E5',
    newPurple: '#902DFB',
    dashboardHeaderColor: '#f9f9fb',
    dashboardBlueGray: '#7393B3',
    skeleton: '#fafafa',
  },
  contextMenuShadow1: '0px 25px 50px 0px #00000021',
});

export default theme;
