import moment from 'moment';
import {
  isEmpty,
  orderBy,
  cloneDeep,
  sortBy,
  isArray,
  isNil,
  isFunction,
  isPlainObject,
} from 'lodash';
import {
  HOME_ACTION,
  PUSH_NOTIFICATIONS_ACTION,
  TABLE_ACTION,
} from './actionType';
import {
  COLOR_CODE_FOR_FILES,
  DEFAULT_HEADER,
  DEFAULT_ROWS,
  ROOT_FOLDER,
  FILES_SORT_OPTIONS,
  DEFAULT_FILES_SORT,
  ASYNC_STORAGE_KEY,
  SHARE_PERMISSION_TYPE,
  // DEFAULT_SORT_ORDER,
  SMART_TEMPLATE_TYPES,
  AUTO_TEMPLATE_TYPE,
  INITIAL_FETCH_COMPLETED,
  ARCH_V2_FLAGS,
} from '../utils/constant';
import {fetchParentFileData} from './tableLinksActions';
import * as homeActionHelper from './actionHelpers/homeActionHelper';
import {
  getMyTemplateData,
  fetchTemplateCategory,
} from './actionHelpers/templateActionHelper';
import {
  setUserPref,
  setFlipkartMeta,
  updateDisabledTemplateVisibility,
} from './authAction';
import {removeSharedDocFromUID} from './actionHelpers/collabActionHelper';
import {
  handleDocumentMetaListenerChanges,
  updateActiveDocMeta,
  updateInitialFetchObj,
} from './tableAction';
import {
  getLocalText,
  serializeError,
  JSONStringifier,
  getNewRowPropertiesObject,
} from '../utils/utils';
import {
  ENV,
  isAndroid,
  firestore,
  captureError,
  captureInfo,
  logAnalyticsEvent,
  ShowToast,
  versionCompare,
  AsyncStorage,
} from '../imports';
import {prepareTableDataForFirestore} from './actionHelpers/tableActionHelper';
import FirestoreDB from '../FirestoreHandlers/FirestoreDB';
import {updateAllDashboardsProperty} from './actionHelpers/dashboardActionHelper';
import {backOff} from 'exponential-backoff';
import {removeEntryForFlipkartCron} from './actionHelpers/flipkartActions/flipkartActionHelper';
import {setDocMetaInTask} from './tasksAction';
import {manageTableViewModesFromMeta} from './tableViewActions';
import {getAndSetTableLinkData} from './actionHelpers/tableLinksActionHelper';
import UsersMethods from '../FirestoreHandlers/Users/UsersMethods';
import DocumentsMethods from '../FirestoreHandlers/Documents/DocumentsMethods';

const createNewFile =
  (fileName, templateId, filePageType = '') =>
  async (dispatch, getState) => {
    try {
      const {auth: authState, home: homeState} = getState();
      const user = authState.user;
      const parent = homeState.activeFolder?.id;

      const fileMetaObj = homeActionHelper.generateNewFileMeta(
        fileName,
        user.uid,
        parent,
        false,
      );

      let pageEnabled = true,
        pageType = filePageType,
        pages = [
          {
            id: fileMetaObj.docId,
            createdTimestamp: moment().valueOf(),
            displayColObj: {},
            pageName: homeActionHelper.getPageNameStr(filePageType),
          },
        ],
        activePageId = fileMetaObj.docId,
        originalDocumentId = fileMetaObj.docId;

      if (!filePageType) {
        pageEnabled = false;
        pageType = '';
        pages = [];
        activePageId = null;
        //originalDocumentId = null;
      }

      const pageMetaData = {
        pageObj: {
          enabled: pageEnabled,
          type: pageType,
        },
        pages,
        activePageId,
        originalDocumentId,
      };

      const updatedfileMetaObj = {
        ...fileMetaObj.meta,
        ...pageMetaData,
      };

      dispatch({
        type: HOME_ACTION.CREATE_NEW_FILE,
        payload: {
          activeDocumentId: fileMetaObj.docId,
          documentMeta: updatedfileMetaObj,
          isFolder: false,
        },
      });

      const payload = {};
      if (templateId === '') {
        //no template add default col and row
        const colName = getLocalText(authState.userPref, 'Column');
        const headers = DEFAULT_HEADER.map((header) => {
          const colNameValue = header.val.replace('COLUMN', colName);
          return Object.assign({}, header, {val: colNameValue});
        });
        Object.assign(payload, {
          headerData: headers,
          tableData: prepareTableDataForFirestore(
            cloneDeep(DEFAULT_ROWS).map((_) =>
              Object.assign(
                {},
                {
                  rowProperties: getNewRowPropertiesObject({}, true),
                },
              ),
            ),
          ),
          footerData: {},
          fileObj: {},
        });
        logAnalyticsEvent('REGISTER_CREATED_EMPTY', {
          fileNumber: homeState.files.length + 1,
          nameChanged:
            fileName === getLocalText(authState.userPref, 'New Document')
              ? false
              : true,
          docId: fileMetaObj.docId,
        });
      } else {
        //file using template
        const templateData = await getMyTemplateData(templateId);
        Object.assign(payload, {
          headerData: Object.values(templateData.headerData),
          tableData: prepareTableDataForFirestore(templateData.tableData),
          footerData: templateData.footerData,
          fileObj: templateData.fileObj,
        });
        logAnalyticsEvent('REGISTER_CREATED_USER_TEMPLATE', {
          fileNumber: homeState.files.length + 1,
          source: 'new-empty-file',
          docId: originalDocumentId,
        });
      }
      dispatch({
        type: TABLE_ACTION.LOAD_TABLE_DATA,
        payload,
      });
      UsersMethods.createNewFileorFolderWithFileData(
        user.uid,
        fileMetaObj.docId,
        updatedfileMetaObj,
        payload,
      );
      if (isEmpty(updatedfileMetaObj?.collab)) {
        //since this is not a shared file so set shared meta fetched true!
        dispatch(
          updateInitialFetchObj({
            [INITIAL_FETCH_COMPLETED.SHARED_META]: true,
          }),
        );
      }
      reloadVisibleFiles(dispatch, getState);
    } catch (error) {
      captureError(error);
    }
  };

const createNewFolder = (fileName) => (dispatch, getState) => {
  return new Promise((resolve) => {
    try {
      const {home, auth} = getState();
      const user = auth.user;
      const parent = home.activeFolder?.id;
      const fileMetaObj = homeActionHelper.generateNewFolderMeta(
        fileName,
        user.uid,
        parent,
      );
      homeActionHelper.setFileDetails(
        user.uid,
        fileMetaObj.folderId,
        fileMetaObj.meta,
        false,
      );
      dispatch({
        type: HOME_ACTION.CREATE_NEW_FILE,
        payload: {
          activeDocumentId: fileMetaObj.folderId,
          documentMeta: {
            ...fileMetaObj.meta,
          },
          isFolder: true,
        },
      });
      reloadVisibleFiles(dispatch, getState);
      logAnalyticsEvent('FOLDER_CREATED', {
        isNested: parent === ROOT_FOLDER ? false : true,
      });
    } catch (error) {
      captureError(error);
    }
    resolve();
  });
};

const createNewFolderInBackground =
  (fileName, activeFolderId) => (dispatch, getState) => {
    return new Promise((resolve) => {
      try {
        const user = getState().auth.user;
        const parent = activeFolderId || getState().home.activeFolder?.id;
        const fileMetaObj = homeActionHelper.generateNewFolderMeta(
          fileName,
          user.uid,
          parent,
        );
        homeActionHelper.setFileDetails(
          user.uid,
          fileMetaObj.folderId,
          fileMetaObj.meta,
          false,
        );
        dispatch({
          type: HOME_ACTION.CREATE_NEW_FILE,
          payload: {
            activeDocumentId: fileMetaObj.folderId,
            documentMeta: {
              ...fileMetaObj.meta,
            },
            isFolder: true,
          },
        });
        // reloadVisibleFiles(dispatch, getState);
        logAnalyticsEvent('FOLDER_CREATED', {
          isNested: parent === ROOT_FOLDER ? false : true,
        });
      } catch (error) {
        captureError(error);
      }
      resolve();
    });
  };

const updateHomeStates =
  (updatedStates, reloadRequired = true, showFoldersOnly = false) =>
  (dispatch, getState) => {
    dispatch({
      type: HOME_ACTION.UPDATE_HOME_STATES,
      payload: updatedStates,
    });
    if (reloadRequired) {
      reloadVisibleFiles(dispatch, getState, showFoldersOnly);
    }
  };

/**
 * sort visibleArray based on sort type
 */
const sortVisibileFiles = (arr, sortBy) => {
  try {
    if (sortBy === FILES_SORT_OPTIONS.NAME) {
      return orderBy(
        arr,
        [
          (item) => {
            return item?.documentMeta?.name?.toLowerCase?.();
          },
          (item) => {
            return item?.documentMeta?.timestamp;
          },
        ],
        ['asc', 'desc'],
      );
    } else if (sortBy === FILES_SORT_OPTIONS.TYPE) {
      return orderBy(
        arr,
        [
          (item) => {
            return item?.documentMeta?.isFolder ? 0 : 1;
          },
          (item) => {
            return item?.documentMeta?.timestamp;
          },
        ],
        ['asc', 'desc'],
      );
    } else if (sortBy === FILES_SORT_OPTIONS.OPENED_DATE) {
      return orderBy(
        arr,
        [
          (item) => {
            return item?.documentMeta?.lastOpenedTimestamp
              ? item.documentMeta.lastOpenedTimestamp
              : item?.documentMeta?.timestamp;
          },
        ],
        ['desc'],
      );
    } else {
      // date/time
      return orderBy(
        arr,
        [
          (item) => {
            return item?.documentMeta?.timestamp;
          },
        ],
        ['desc'],
      );
    }
  } catch (error) {
    captureError(error);
    return arr;
  }
};

/**
 * reloadVisibleFiles : to be called after changing
 * some visible property of a file/folder
 * << RELOADS ALL FILES/FOLDERS OF CURRENT SCREEN(Folder) >>
 **/
const reloadVisibleFiles = (dispatch, getState, showFoldersOnly = false) => {
  const activeFolderID = getState().home.activeFolder?.id;
  dispatch(openFolder(activeFolderID, false, showFoldersOnly));
};

const openFolder =
  (
    folderId,
    pushToStack = true,
    showFoldersOnly = false,
    shouldUpdateTimeStamp = false,
  ) =>
  (dispatch, getState) => {
    return new Promise((resolve) => {
      try {
        const {home: homeState, auth} = getState();
        const filesArr = homeState.files.slice();
        const folderStack = homeState.folderStack.slice();
        if (pushToStack) {
          //update folderStack with current activeFolder obj
          const activeFolder = homeState.activeFolder;
          folderStack.push({...activeFolder});
        }
        let visibleFiles = [];
        let folderName = '';
        let sortBy = DEFAULT_FILES_SORT;
        // const sortOrder = auth.userPref?.sortOrder ?? DEFAULT_SORT_ORDER;
        let folderIndex = 0;
        if (folderId === ROOT_FOLDER) {
          sortBy = auth.userPref?.sortBy ?? DEFAULT_FILES_SORT;
          filesArr.forEach((docData, index) => {
            const docsParent = docData?.documentMeta?.parent;
            if (showFoldersOnly && !docData?.documentMeta?.isFolder) {
              return;
            }
            if ([null, undefined, ROOT_FOLDER].includes(docsParent)) {
              const newObj = cloneDeep(docData);
              visibleFiles.push({index, ...newObj});
            }
          });
        } else {
          filesArr.forEach((docData, index) => {
            const docsParent = docData?.documentMeta?.parent;
            if (docData?.documentId === folderId) {
              folderName = docData.documentMeta?.name;
              folderIndex = index;
              if (docData.documentMeta?.sortBy) {
                sortBy = docData.documentMeta?.sortBy;
              }
            }
            if (showFoldersOnly && !docData?.documentMeta?.isFolder) {
              return;
            }
            if (docsParent === folderId) {
              const newObj = cloneDeep(docData);
              visibleFiles.push({index, ...newObj});
            }
          });
        }
        if (visibleFiles?.length > 0 && !showFoldersOnly) {
          visibleFiles = sortVisibileFiles(visibleFiles, sortBy);
        }
        dispatch({
          type: HOME_ACTION.UPDATE_VISIBLE_FOLDER,
          payload: {
            visibleFiles,
            activeFolder: {
              name: folderName,
              id: folderId,
              index: folderIndex,
            },
            folderStack,
          },
        });
        if (shouldUpdateTimeStamp) {
          dUpdateLastOpenedTimestampFolder(folderId, dispatch, getState);
        }
      } catch (error) {
        captureError(error);
      }
      resolve();
    });
  };

/**
 * Can be used to come directly to root folder from any folder
 */
const clearFolderStack = () => (dispatch) => {
  dispatch(
    updateHomeStates(
      {
        activeFolder: {
          name: '',
          id: ROOT_FOLDER,
          index: null,
        },
        folderStack: [],
      },
      true, //reload visibleFiles
      false, //show folders only
    ),
  );
};

const openPreviousFolder =
  (showFoldersOnly = false) =>
  (dispatch, getState) => {
    return new Promise((resolve) => {
      try {
        const {home: homeState, auth} = getState();
        const filesArr = homeState.files.slice();
        const folderStack = homeState.folderStack.slice();
        const activeFolder = folderStack.pop();
        let visibleFiles = [];
        let sortBy = DEFAULT_FILES_SORT;
        // const sortOrder = DEFAULT_SORT_ORDER;
        if (activeFolder.id === ROOT_FOLDER) {
          sortBy = auth.userPref?.sortBy ?? DEFAULT_FILES_SORT;
          filesArr.forEach((docData, index) => {
            const docsParent = docData?.documentMeta?.parent;
            if (showFoldersOnly && !docData?.documentMeta?.isFolder) {
              return;
            }
            if ([null, undefined, ROOT_FOLDER].includes(docsParent)) {
              const newObj = cloneDeep(docData);
              visibleFiles.push({index, ...newObj});
            }
          });
        } else {
          filesArr.forEach((docData, index) => {
            if (docData?.documentId === activeFolder.id) {
              activeFolder.index = index;
              sortBy = docData?.documentMeta?.sortBy ?? DEFAULT_FILES_SORT;
            }
            if (showFoldersOnly && !docData?.documentMeta?.isFolder) {
              return;
            }
            if (docData?.documentMeta?.parent === activeFolder.id) {
              const newObj = cloneDeep(docData);
              visibleFiles.push({index, ...newObj});
            }
          });
        }
        if (visibleFiles?.length > 0 && !showFoldersOnly) {
          visibleFiles = sortVisibileFiles(visibleFiles, sortBy);
        }
        dispatch({
          type: HOME_ACTION.UPDATE_VISIBLE_FOLDER,
          payload: {
            visibleFiles,
            activeFolder,
            folderStack,
          },
        });
      } catch (error) {
        captureError(error);
      }
      resolve();
    });
  };

const duplicateFile =
  (newFilename, docId, isOnline) => async (dispatch, getState) => {
    try {
      const {auth, home} = getState();
      const user = auth.user;
      const originalFileMeta = home.visibleFiles.find(
        (f) => f.documentId === docId,
      ).documentMeta;

      const isShared = !isEmpty(originalFileMeta?.collab);

      if (
        isShared &&
        !(
          originalFileMeta.collab.isOwner ||
          [
            SHARE_PERMISSION_TYPE.OWNER,
            SHARE_PERMISSION_TYPE.ADMIN,
            SHARE_PERMISSION_TYPE.CAN_EDIT,
          ].includes(originalFileMeta.collab.permission)
        )
      ) {
        return ShowToast(
          "You don't have permission to perform this action. Please contact file admin or owner.",
          auth.userPref,
        );
      }

      const [fileData, sharedDocSnap] = await Promise.all([
        DocumentsMethods.getUserDocumentData(docId),
        isShared
          ? homeActionHelper.fetchSharedDocsMeta(docId)
          : Promise.resolve([]),
      ]);

      if (isShared && sharedDocSnap?.exists) {
        Object.assign(originalFileMeta, sharedDocSnap?.data?.());
      }

      const parent = home.activeFolder?.id;
      let newFileMeta = homeActionHelper.generateNewFileMeta(
        newFilename,
        user.uid,
        parent,
        true,
        originalFileMeta,
      );
      let headerData;
      if (isEmpty(fileData) || isEmpty(fileData.headerData)) {
        const colName = getLocalText(auth.userPref, 'Column');
        headerData = DEFAULT_HEADER.map((header) => {
          const colNameValue = header.val.replace('COLUMN', colName);
          return Object.assign({}, header, {val: colNameValue});
        });
      } else {
        headerData = fileData.headerData;
      }
      const tableData = prepareTableDataForFirestore(
        Array.isArray(fileData?.tableData) && fileData.tableData.length
          ? fileData.tableData
          : DEFAULT_ROWS,
      );
      const footerData = !isEmpty(fileData?.footerData)
        ? fileData.footerData
        : {};
      const fileObj = !isEmpty(fileData?.fileObj) ? fileData.fileObj : {};

      let newDocPages = [],
        newPagesData = [];

      const newPagesIdMapWithOld = {}; // For getting old page id using new page id;

      if (originalFileMeta?.pageObj?.enabled === true && isOnline) {
        const originalDocPages = originalFileMeta.pages;
        await Promise.all(
          originalDocPages.map((page, index) => {
            return new Promise((resolve, reject) => {
              if (page.id === docId) {
                //original page/docPage
                newDocPages = [
                  ...newDocPages,
                  {...page, id: newFileMeta.docId},
                ];

                newPagesIdMapWithOld[newFileMeta.docId] = page.id;
                resolve();
              } else {
                DocumentsMethods.getUserDocumentData(page.id)
                  .then((fetchTableData) => {
                    if (isEmpty(fetchTableData)) {
                      return resolve();
                    }
                    const {
                      footerData: footerDataCopy,
                      tableData: tableDataCopy,
                      headerData: headerDataCopy,
                      fileObj: fileObjCopy,
                    } = fetchTableData;

                    const newDocId = `${user.uid}_${
                      moment().valueOf() + index
                    }`;

                    newDocPages = [...newDocPages, {...page, id: newDocId}];
                    newPagesData = [
                      ...newPagesData,
                      {
                        docId: newDocId,
                        headerData: headerDataCopy,
                        tableData: tableDataCopy,
                        footerData: footerDataCopy,
                        fileObj: fileObjCopy,
                      },
                    ];

                    newPagesIdMapWithOld[newDocId] = page.id;
                    return resolve();
                  })
                  .catch((e) => {
                    return reject(e);
                  });
              }
            });
          }),
        );
        newFileMeta = {
          ...newFileMeta,
          meta: {
            ...newFileMeta.meta,
            pageObj: originalFileMeta.pageObj,
            activePageId: newFileMeta.docId,
            originalDocumentId: newFileMeta.docId,
            pages: newDocPages,
          },
        };
      }

      /** Cloud function to manage duplicate file. */
      const manageDuplicateFileFunc = () => {
        if (isOnline) {
          homeActionHelper.manageDuplicateFile({
            docId: newFileMeta.docId,
          });
        }
      };

      UsersMethods.createNewFileorFolderWithFileData(
        user.uid,
        newFileMeta.docId,
        newFileMeta.meta,
        {headerData, tableData, footerData, fileObj},
      ).then(() => {
        getAndSetTableLinkData(newFileMeta.docId, docId);
        if (newPagesData.length) {
          Promise.all(
            newPagesData.map((page) => {
              getAndSetTableLinkData(
                page.docId,
                newPagesIdMapWithOld[page.docId],
              );
              return DocumentsMethods.setNewPageData(page.docId, {
                headerData: page.headerData,
                tableData: prepareTableDataForFirestore(page.tableData),
                footerData: page.footerData,
                fileObj: page.fileObj,
              });
            }),
          ).then(() => manageDuplicateFileFunc());
        } else {
          manageDuplicateFileFunc();
        }
      });
      newFileMeta.meta.fileName = newFilename;

      dispatch({
        type: HOME_ACTION.DUPLICATE_FILE,
        payload: {
          meta: {...newFileMeta.meta},
          docId: newFileMeta.docId,
        },
      });
      reloadVisibleFiles(dispatch, getState);
      logAnalyticsEvent('DUPLICATE_FILE', {docId});
    } catch (error) {
      captureError(error);
    }
  };

const loadHomePageCommunities = () => async (dispatch) => {
  try {
    const documents = await homeActionHelper.fetchCommunitiesData();
    if (!isArray(documents)) return;
    const addToArr = (doc) => doc.data();
    dispatch({
      type: HOME_ACTION.GET_COMMUNITIES,
      payload: documents.map(addToArr),
    });
  } catch (error) {
    captureError(error);
  }
};

const loadAllFiles =
  (callback = undefined) =>
  async (dispatch, getState) => {
    /**
     * not used anymore : replaced by `userDocsListener`
     */
    try {
      dispatch({
        type: HOME_ACTION.SHOW_LOADER,
      });
      const {
        auth: {user, userPref},
        home,
      } = getState();

      if (!user?.uid) {
        return;
      }
      dispatch(loadHelpDeskVideos());
      // dispatch(loadHomePageCommunities());
      const parent = home.activeFolder?.id; //ROOT_FOLDER
      const getFileMeta = (collectionName) =>
        new Promise((resolve) => {
          homeActionHelper
            .getUsersSubCollectionDataWithQuery(user?.uid, collectionName)
            .then((snap) => {
              const arr = [];
              snap.forEach((doc) =>
                arr.push({documentId: doc.id, documentMeta: doc.data()}),
              );
              resolve(arr);
            })
            .catch(() => resolve([]));
        });
      const collections = ['documents', 'sharedDocs'];

      const result = await Promise.all(
        collections.map((name) => getFileMeta(name)),
      );

      const filesArr = [...result[0], ...result[1]];

      const visibleFiles = [];

      filesArr.forEach((data, index) => {
        const docsParent = data?.documentMeta?.parent;
        if ([null, undefined, parent].includes(docsParent)) {
          const newObj = cloneDeep(data);
          visibleFiles.push({...newObj, index});
        }
      });
      // const sortBy = getState().auth.userPref?.sortBy ?? DEFAULT_FILES_SORT;
      // const sortOrder =
      //   getState().auth.userPref?.sortOrder ?? DEFAULT_SORT_ORDER;
      // visibleFiles = sortVisibileFiles(visibleFiles, sortBy, sortOrder);

      const sortByType = userPref?.sortBy ?? DEFAULT_FILES_SORT;

      dispatch({
        type: HOME_ACTION.LOAD_FILES,
        payload: {
          visibleFiles: sortVisibileFiles(visibleFiles, sortByType),
          files: filesArr,
        },
      });

      dispatch({
        type: HOME_ACTION.HIDE_LOADER,
      });

      const templatesSnap = await homeActionHelper.getUserTemplatesData(
        user.uid,
      );

      const templatesObj = {};

      templatesSnap.forEach((doc) => {
        templatesObj[doc.id] = doc.data();
      });

      if (!isEmpty(templatesObj)) {
        dispatch({
          type: HOME_ACTION.LOAD_TEMPLATES,
          payload: templatesObj,
        });
      }
      if (callback) callback(filesArr);
    } catch (error) {
      captureError(error);
    }
  };

const getMyTemplatesList = () => (dispatch, getState) => {
  try {
    const uid = getState().auth.user?.uid;
    if (!uid) {
      return;
    }
    homeActionHelper.getUserTemplatesData(uid).then((templatesSnap) => {
      const templatesObj = {};
      templatesSnap.forEach((doc) => {
        templatesObj[doc.id] = doc.data();
      });
      if (!isEmpty(templatesObj)) {
        dispatch({
          type: HOME_ACTION.LOAD_TEMPLATES,
          payload: templatesObj,
        });
      }
    });
  } catch (error) {
    captureError(error);
  }
};

const userDocsListener =
  (firestoreInstance, extras = {}) =>
  async (dispatch, getState) => {
    try {
      firestoreInstance = firestoreInstance ?? firestore;
      const uid = getState().auth.user?.uid;
      if (!uid) {
        return;
      }
      dispatch({
        type: HOME_ACTION.SHOW_LOADER,
      });
      const collections = ['documents', 'sharedDocs'];
      const initialFetchSuccessArr = collections.map(() => false); //to check whether all collections are fetched or not
      let sharedFileCount = 0,
        fileCount = 0;

      const handleDocumentChange = (change, filesArr, home) => {
        const changedDocId = change.doc.id;
        if (home.filesBeingDeleted?.all?.includes(changedDocId)) return;

        const index = filesArr.findIndex(
          (file) => file.documentId === changedDocId,
        );
        const fileObj = {
          documentId: change.doc.id,
          documentMeta: change.doc.data(),
        };
        let hasChanged = false;

        switch (change.type) {
          case 'added':
          case 'modified':
            index > -1
              ? filesArr.splice(index, 1, fileObj)
              : filesArr.push(fileObj);
            hasChanged = true;
            break;
          case 'removed':
            index > -1 && filesArr.splice(index, 1);
            hasChanged = true;
            break;
        }
        return hasChanged;
      };

      const listenersArr = collections.map(
        (collectionName, collectionIndex) => {
          let isFirstFetch = true;
          const handleListener = (snapshot) => {
            try {
              const isLocalChange = snapshot.metadata.hasPendingWrites && true;
              //const isFromCache = snapshot.metadata.fromCache && true;
              let hasFilesArrChanged = false;
              const changedDocuments = snapshot.docChanges();
              const {
                home,
                auth: {userPref},
              } = getState();
              const {originalDocumentId, activeFileIndex, files, activeFolder} =
                home;

              if (
                !isLocalChange &&
                originalDocumentId &&
                changedDocuments.length === 1 &&
                originalDocumentId === changedDocuments[0].doc.id
              ) {
                //change in currently opened doc's meta
                dispatch(
                  handleDocumentMetaListenerChanges(changedDocuments[0]),
                );
              }
              const filesArr = files.slice();
              changedDocuments.forEach((change) => {
                try {
                  const didDocumentChange = handleDocumentChange(
                    change,
                    filesArr,
                    home,
                  );
                  if (didDocumentChange) {
                    hasFilesArrChanged = true;
                  }
                } catch (err) {
                  captureInfo({
                    err: serializeError(err),
                    collectionName,
                    changedDocuments: JSONStringifier(changedDocuments),
                  });
                  captureError(
                    new Error('Error home documents listener (change-doc)'),
                    true,
                  );
                }
              });
              if (hasFilesArrChanged) {
                const visibleFiles = [];
                const updatedFolder = Object.assign({}, activeFolder);
                let updatedFileIndex = 0;
                const parent = activeFolder.id || ROOT_FOLDER; //current folder's id
                let sortByType =
                  (parent === ROOT_FOLDER ? userPref?.sortBy : null) ||
                  DEFAULT_FILES_SORT;

                // const sortOrder =
                //   (parent === ROOT_FOLDER ? userPref?.sortOrder : null) ||
                //   DEFAULT_SORT_ORDER;
                filesArr.forEach((data, index) => {
                  const {documentMeta, documentId} = data || {};
                  const docsParent = documentMeta?.parent;
                  if (parent === ROOT_FOLDER) {
                    if ([null, undefined, parent].includes(docsParent)) {
                      visibleFiles.push({...cloneDeep(data), index});
                    }
                  } else {
                    if (documentId === parent) {
                      Object.assign(updatedFolder, {
                        index,
                        name: documentMeta?.name || '',
                      });
                      if (documentMeta?.sortBy) {
                        sortByType = documentMeta.sortBy;
                      }
                    }
                    if (parent === docsParent) {
                      visibleFiles.push({...cloneDeep(data), index});
                    }
                  }
                  if (originalDocumentId === documentId) {
                    updatedFileIndex = index;
                  }
                });
                if (collectionName === 'documents') {
                  fileCount = filesArr?.length;
                } else if (collectionName === 'sharedDocs') {
                  sharedFileCount = filesArr?.length;
                }

                dispatch({
                  type: HOME_ACTION.LOAD_FILES,
                  payload: {
                    visibleFiles: sortVisibileFiles(visibleFiles, sortByType),
                    files: filesArr,
                    activeFolder: updatedFolder,
                  },
                });
                if (
                  originalDocumentId &&
                  updatedFileIndex !== activeFileIndex
                ) {
                  dispatch({
                    type: HOME_ACTION.UPDATE_ACTIVE_FILE_INDEX,
                    payload: updatedFileIndex,
                  });
                }
              }
            } catch (err) {
              captureInfo({
                err: serializeError(err),
                collectionName,
                snapshot,
              });
              captureError(
                new Error(
                  'Error home documents listener (processing-snapshot)',
                ),
                true,
              );
            }
            if (isFirstFetch) {
              isFirstFetch = false;
              initialFetchSuccessArr[collectionIndex] = true;
              if (initialFetchSuccessArr.every((val) => val)) {
                //all the documents are fetched
                dispatch({
                  type: HOME_ACTION.HIDE_LOADER,
                });
                dispatch({
                  type: HOME_ACTION.SET_ALL_FILES_FETCHED,
                });
                if (ENV) {
                  //mobile only actions
                  const {dashboard, tasks, teams} = getState();
                  logAnalyticsEvent('READY_FOR_IN_APP_NOTIF', {
                    activeOwnedDocs: fileCount,
                    activeDocs: sharedFileCount + fileCount,
                    activeDashboards: dashboard?.dashboards?.length,
                    activeTasks: tasks?.visibleTasks?.length,
                    activeTeams: Object.keys(teams?.teams ?? {})?.length,
                  });
                  extras?.openFileCallback?.();
                }
                //all platform actions
                dispatch(setDocMetaInTask());
              }
            }
          };
          return FirestoreDB.FirestoreListener(
            homeActionHelper.getUsersSubCollectionDataWithQuery(
              uid,
              collectionName,
              {firestoreInstance, returnRef: true},
            ),
            handleListener,
          );
        },
      );
      dispatch({
        type: HOME_ACTION.UPDATE_HOME_LISTENER_OBJ,
        payload: {
          FILES_LISTENER: () => {
            listenersArr.forEach((listener) => {
              //deactivate
              if (isFunction(listener)) {
                listener();
              }
            });
          },
        },
      });
    } catch (error) {
      captureError(error);
    }
  };

const clearHomeStateAndDeactivateListeners = () => (dispatch) => {
  dispatch({
    type: HOME_ACTION.CLEAR_HOME_STATE,
  });
};

const handleUserStories =
  (userStories, isFirstFetch) => (dispatch, getState) => {
    try {
      if (isFirstFetch && !isEmpty(userStories)) {
        const {
          auth: {user},
        } = getState();
        const updateObj = {};
        for (const key in userStories) {
          if (userStories[key]?.isViewed) {
            updateObj[`userStories.${key}`] =
              firestore(true).FieldValue.delete();
          }
        }
        if (!isEmpty(updateObj)) {
          return homeActionHelper.removeUserStoriesFromFirebase(
            updateObj,
            user.uid,
          );
        }
      }
      if (isPlainObject(userStories)) {
        const sortedUserStories = orderBy(userStories, ['priority']);
        const userStoriesRearranged = sortedUserStories
          .filter((item) => !item?.isViewed)
          .concat(sortedUserStories.filter((item) => item?.isViewed));
        dispatch({
          type: HOME_ACTION.SET_USER_STORIES,
          payload: userStoriesRearranged,
        });
      }
    } catch (error) {
      captureError(error);
    }
  };

const getUserMetaDataListener = (firestoreInstance) => (dispatch, getState) => {
  try {
    firestoreInstance = firestoreInstance ?? firestore;
    const {
      auth: {user},
    } = getState();
    if (!user?.uid) {
      return;
    }
    let isFirstFetch = true;

    const handleUserMetaListener = (snapshot) => {
      try {
        if (snapshot.exists) {
          const userMetaData = snapshot.data();
          dispatch({
            type: PUSH_NOTIFICATIONS_ACTION.SET_NOTFICATION_LAST_FETCHED_TIME,
            payload: userMetaData?.lastNotificationFetched,
          });
          // handle data change for UserStories
          dispatch(handleUserStories(userMetaData?.userStories, isFirstFetch));
        }
        if (isFirstFetch) {
          isFirstFetch = false;
        }
      } catch (err) {
        captureInfo({
          err: serializeError(err),
          snapshot,
        });
        captureError(new Error('Error in userMetaDataListener'));
      }
    };

    // TODO : Handle Notifications
    const listener = FirestoreDB.FirestoreListener(
      firestoreInstance().collection('users').doc(user.uid),
      handleUserMetaListener,
    );
    dispatch({
      type: HOME_ACTION.UPDATE_HOME_LISTENER_OBJ,
      payload: {USER_META_LISTENER: listener},
    });
  } catch (error) {
    captureError(error);
  }
};

const updateUserStoryViewStatus = (key) => async (dispatch, getState) => {
  try {
    const {
      auth: {user},
      home: {userStories},
    } = getState();
    let alreadyViewed = false;
    const obj = userStories.find((item) => item.key === key);
    if (obj) {
      alreadyViewed = obj?.isViewed ?? false;
    }
    if (!alreadyViewed) {
      homeActionHelper.markUserStoryAsViewed(user.uid, key);
    }
  } catch (error) {
    captureError(error);
  }
};

const renameFile = (newFilename, fileIndex, docId) => (dispatch, getState) => {
  return new Promise((resolve) => {
    try {
      const {home, auth: authState} = getState();
      const user = authState.user;
      const filesArr = home.files.slice();
      const fileIndexNew = filesArr.findIndex(
        (item) => item.documentId === docId,
      );
      if (fileIndexNew === -1) {
        return;
      }
      const fileObj = filesArr[fileIndexNew];
      const docMeta = Object.assign({}, fileObj.documentMeta);
      const oldName = docMeta.name;
      if (newFilename === oldName) {
        //no change
        return;
      }
      const updateObj = {name: newFilename};
      const updatedMeta = Object.assign({}, docMeta, updateObj);
      filesArr.splice(fileIndexNew, 1, {...fileObj, documentMeta: updatedMeta});
      dispatch({
        type: HOME_ACTION.LOAD_FILES,
        payload: {files: filesArr},
      });
      reloadVisibleFiles(dispatch, getState);
      const defaultName = getLocalText(authState.userPref, 'New Document');
      homeActionHelper.setFileDetails(
        user.uid,
        docId,
        Object.assign({}, {collab: updatedMeta.collab}, updateObj),
      );
      logAnalyticsEvent('RENAME_FILE', {
        nameChangedFromDefault: oldName === defaultName ? true : false,
        docId,
      });
      if (!isEmpty(updatedMeta.dashboards)) {
        updateAllDashboardsProperty(updatedMeta.dashboards, {
          docName: newFilename,
        });
      }
    } catch (error) {
      captureError(error);
    }
    resolve();
  });
};

const openFile =
  (fileName, docId, documentMeta, index, isShareFromHome = false, extra = {}) =>
  (dispatch, getState) => {
    try {
      if (ENV) {
        //mobile only check
        const {
          getDeviceVersion,
          documentIncompatibleAlert,
        } = require('../imports');
        const currentVersion = getDeviceVersion();
        if (
          documentMeta?.minSupportedVersion &&
          currentVersion &&
          versionCompare(
            documentMeta.minSupportedVersion,
            currentVersion,
            false,
          ) //if minSupportedVersion > currentVersion
        ) {
          const {auth} = getState();
          documentIncompatibleAlert(auth.userPref);
          return false;
        }
      }

      /**
       * Update Table View Modes
       */
      dispatch(manageTableViewModesFromMeta(documentMeta));

      let lastAddedPageId = null;
      const isSharedDoc = !isEmpty(documentMeta.collab);

      if (!isShareFromHome) {
        dispatch({type: TABLE_ACTION.START_TABLE_LOADING});
        if (isSharedDoc) {
          //only latest pages data from server should be read for collaboration
          delete documentMeta.pages;
          delete documentMeta.pageObj;

          if (documentMeta.activePageId) {
            lastAddedPageId = documentMeta.activePageId;
          }
        } else if (
          documentMeta.pageObj &&
          !isEmpty(documentMeta.pageObj) &&
          documentMeta.pageObj.enabled
        ) {
          lastAddedPageId = documentMeta.activePageId;
        }
      }

      /**
       * If `isARCH_V2` flag is available but minSupportedVersion not
       * Then update minSupportedVersion for all users.
       */
      if (documentMeta.isARCH_V2 && !documentMeta.minSupportedVersion) {
        homeActionHelper.updateSharedFileMetaKeyValues({
          originalDocId: docId,
          updateKeys: {minSupportedVersion: ARCH_V2_FLAGS.minSupportedVersion},
        });
      }

      dispatch({
        type: HOME_ACTION.OPEN_FILE,
        payload: {
          activeDocumentId: lastAddedPageId || docId,
          activeFileName: fileName,
          activeFileMeta: documentMeta,
          activeFileIndex: index,
          originalDocumentId: docId, //will hold the original document id (helpfull for pages)
        },
      });

      // Update document last opened timestamp on FIRESTORE and REDUX.
      if (!isShareFromHome) {
        // Fetch all parent Docs linked file data on File open.
        dispatch(fetchParentFileData({isInitialFetch: true, extra}));
        if (!isSharedDoc) {
          dispatch(
            updateInitialFetchObj({
              [INITIAL_FETCH_COMPLETED.SHARED_META]: true,
            }),
          );
        }
        // dUpdateLastOpenedTimestamp(docId, dispatch, getState);
        const timestamp = moment().unix();
        dispatch(
          updateActiveDocMeta({
            lastOpenedTimestamp: timestamp,
          }),
        );
      }
    } catch (error) {
      captureError(error);
    }
    return true;
  };

const openFileWithDocumentId =
  (docId, isFromMiniApps = false) =>
  (dispatch, getState) => {
    try {
      const {
        home: {files},
      } = getState();
      const fileIndex = (files || []).findIndex(
        (data) => data?.documentId === docId,
      );

      if (!isNil(fileIndex) && fileIndex > -1) {
        const fileObj = Object.assign({}, files[fileIndex]);
        const isCompatible = dispatch(
          openFile(
            fileObj.documentMeta.name,
            fileObj.documentId,
            fileObj.documentMeta,
            fileIndex,
            false,
            {
              isFromMiniApps,
              docId,
            },
          ),
        );
        if (isCompatible) {
          return fileObj.documentMeta;
        }
      }
    } catch (err) {
      captureError(err);
    }
    return null;
  };

const deleteFile = (docId) => async (dispatch, getState) => {
  try {
    const {auth, home} = getState();
    const index = home.files.findIndex((file) => file.documentId === docId);
    if (index === -1) {
      return ShowToast('Something went wrong, please try again', auth.userPref);
    }
    const docObj = home.files[index];
    const docMetaData = docObj?.documentMeta;
    const uid = auth.user.uid;
    const isShared = !isEmpty(docMetaData?.collab);
    let msg = `${
      docMetaData?.isFolder ? 'Folder' : 'File'
    } has been moved to recycle bin`;
    if (docMetaData.fileData?.autoTemplate === AUTO_TEMPLATE_TYPE.FILP_ORDER) {
      const flag = false;
      dispatch(
        updateDisabledTemplateVisibility(AUTO_TEMPLATE_TYPE.FILP_ORDER, true),
      );
      dispatch(setFlipkartMeta(flag));
      removeEntryForFlipkartCron(uid);
    } else if (
      docMetaData.fileData?.smartTemplate === SMART_TEMPLATE_TYPES.W4B_TEMPLATE
    ) {
      dispatch(
        updateDisabledTemplateVisibility(
          SMART_TEMPLATE_TYPES.W4B_TEMPLATE,
          true,
        ),
      );
    }
    if (isShared) {
      const dataObj = {uidOfFileToRemove: uid, docId, callerUID: uid};
      logAnalyticsEvent('LEAVE_SHARING', {
        mode: docMetaData.collab.permission,
        docId,
      });
      const data = await removeSharedDocFromUID(dataObj);
      if (!data || !data.success) {
        let message;
        if (data?.message) {
          message = data.message;
        } else {
          message =
            'Deleting file failed. Please check your internet connection.';
        }
        if (data?.error) {
          captureInfo({
            log: data.log,
            params: JSON.stringify({docObj, dataObj, data, isSelfRemove: true}),
          });
          captureError(new Error(data.error), true);
        }
        if (
          !(
            docMetaData.fileData?.autoTemplate === AUTO_TEMPLATE_TYPE.FILP_ORDER
          ) &&
          !(
            docMetaData.fileData?.smartTemplate ===
            SMART_TEMPLATE_TYPES.W4B_TEMPLATE
          )
        ) {
          ShowToast(message, auth.userPref);
        }
        return;
      } else {
        const isOwner = docMetaData.collab?.isOwner;
        if (!isOwner) {
          msg = 'You have left the file successfully.';
        } else {
          logAnalyticsEvent('DELETE_FILE', {docId, isShared: true});
        }
      }
    } else {
      const action = () => dispatch(setFilesBeingDeleted(docId));
      if (ENV) {
        action();
      } else {
        await action();
      }
      logAnalyticsEvent('DELETE_FILE', {docId});
    }
    dispatch({
      type: HOME_ACTION.DELETE_FILE,
      payload: {index, docId},
    });
    reloadVisibleFiles(dispatch, getState);
    if (
      !(docMetaData.fileData?.autoTemplate === AUTO_TEMPLATE_TYPE.FILP_ORDER) &&
      !(
        docMetaData.fileData?.smartTemplate ===
        SMART_TEMPLATE_TYPES.W4B_TEMPLATE
      )
    ) {
      ShowToast(msg, getState().auth.userPref);
    }
  } catch (error) {
    captureError(error);
  }
};

const setFilesBeingDeleted = (docId) => async (dispatch, getState) => {
  try {
    if (!ENV) {
      const dataObj = {
        docIdArr: [docId],
      };
      return await homeActionHelper.deleteFileCloud(dataObj);
    }
    const removeValsFromArr = (arr, valuesToRemove) =>
      arr.filter((val) => !valuesToRemove.includes(val));
    const updateAsyncStorage = (all) =>
      AsyncStorage().setItem(
        ASYNC_STORAGE_KEY.FILES_BEING_DELETED,
        JSON.stringify(all),
      );
    const {
      home: {filesBeingDeleted},
    } = getState();
    const all = docId
      ? [...new Set([...filesBeingDeleted.all, docId])]
      : filesBeingDeleted.all;
    const deleteDocIds = removeValsFromArr(all, filesBeingDeleted.inProgress);
    dispatch({
      type: HOME_ACTION.SET_FILES_BEING_DELETED,
      payload: {
        all,
        inProgress: [...filesBeingDeleted.inProgress, ...deleteDocIds],
      },
    });
    if (docId) {
      updateAsyncStorage(all);
    }
    if (deleteDocIds.length) {
      const dataObj = {
        docIdArr: deleteDocIds,
      };

      const res = await homeActionHelper.deleteFileCloud(dataObj);

      const {
        home: {filesBeingDeleted: latestFilesBeingDeleted},
      } = getState();
      if (res?.success) {
        const updatedAllFilesArr = removeValsFromArr(
          latestFilesBeingDeleted.all,
          deleteDocIds,
        );
        dispatch({
          type: HOME_ACTION.SET_FILES_BEING_DELETED,
          payload: {
            all: updatedAllFilesArr,
            inProgress: removeValsFromArr(
              latestFilesBeingDeleted.inProgress,
              deleteDocIds,
            ),
          },
        });
        updateAsyncStorage(updatedAllFilesArr);
      } else {
        dispatch({
          type: HOME_ACTION.SET_FILES_BEING_DELETED,
          payload: {
            inProgress: removeValsFromArr(
              latestFilesBeingDeleted.inProgress,
              deleteDocIds,
            ),
          },
        });
      }
    }
  } catch (error) {
    captureError(error);
  }
};

const getFilesToDeleteLocalStorage = (filesToDelete) => async (dispatch) => {
  try {
    if (ENV) {
      if (typeof filesToDelete === 'string') {
        const all = JSON.parse(filesToDelete);

        if (Array.isArray(all) && all.length) {
          dispatch({
            type: HOME_ACTION.SET_FILES_BEING_DELETED,
            payload: {all},
          });
          dispatch(setFilesBeingDeleted(null));
        }
      }
    }
  } catch (error) {
    captureError(error);
  }
};

const createTemplate =
  (docId, templateName, isWithData) => async (dispatch, getState) => {
    try {
      isWithData = false; //for new arch
      const {auth, home} = getState();
      const user = auth.user;
      const colorIndex = Math.floor(Math.random() * 9);
      const colorCode = COLOR_CODE_FOR_FILES[colorIndex];
      const timestamp = moment().unix();
      const templateId = `${user.uid}_temp_${moment().valueOf()}`;
      const data = isWithData
        ? await DocumentsMethods.getUserDocumentData(docId)
        : await DocumentsMethods.getUserDocumentDataWithoutRows(docId);
      if (!isEmpty(data)) {
        homeActionHelper.setUserTemplateDocumentData(
          user.uid,
          templateId,
          {
            name: templateName,
            colorCode: colorCode ? colorCode : '#caffbf',
            timestamp,
          },
          false,
        );
        let templateSaveData = data.headerData;
        const fileObj = Object.assign({}, data.fileObj);
        if (!isEmpty(data.footerData) || isWithData) {
          if (isWithData) {
            templateSaveData = Object.assign({}, templateSaveData, {
              footerData: isEmpty(data.footerData) ? {} : data.footerData,
              tableData: data.tableData,
              fileObj,
            });
          } else {
            const {
              calculateTotal,
              getTotalObject,
            } = require('../utils/equationHelper');
            const updatedFooter = {};
            Object.keys(data.footerData).forEach((colId) => {
              const totalObj = calculateTotal(
                [{}],
                colId,
                Object.values(data.headerData),
              );
              updatedFooter[colId] = getTotalObject(
                data.footerData[colId],
                totalObj,
              );
            });
            templateSaveData = Object.assign({}, templateSaveData, {
              footerData: updatedFooter,
              fileObj,
            });
          }
        }

        // Upload to main Template Collection
        homeActionHelper.setTemplateDocumentData(
          templateId,
          templateSaveData,
          false,
        );

        const obj = {};
        obj[templateId] = {
          name: templateName,
          colorCode: colorCode ? colorCode : '#caffbf',
          timestamp,
        };
        dispatch({
          type: HOME_ACTION.ADD_TEMPLATE,
          payload: obj,
        });
        const noOfFiles = home.files.length;
        logAnalyticsEvent('TEMPLATE_CREATED', {noOfFiles, isWithData, docId});
        return true;
      }
      return false;
    } catch (error) {
      captureError(error);
      return false;
    }
  };

const restoreFile = (doc) => (dispatch, getState) => {
  return new Promise((resolve) => {
    try {
      const files = getState().home.files.slice();
      const restoredFile = {
        documentId: doc.id,
        documentMeta: doc.data,
      };
      files.push(restoredFile);
      files.sort(
        (a, b) => b.documentMeta?.timestamp - a.documentMeta?.timestamp,
      );
      dispatch({
        type: HOME_ACTION.LOAD_FILES,
        payload: {files},
      });
      reloadVisibleFiles(dispatch, getState);
    } catch (error) {
      captureError(error);
    }
    resolve();
  });
};

const updateSmartBannerVisibility =
  (bannerType = null) =>
  (dispatch, getState) => {
    return new Promise((resolve) => {
      try {
        if (bannerType) {
          const {auth} = getState();
          const disabledSmartBanners = isArray(
            auth.userPref?.disabledSmartBanners,
          )
            ? auth.userPref.disabledSmartBanners
            : [];
          if (!disabledSmartBanners.includes(bannerType)) {
            dispatch(
              setUserPref({
                disabledSmartBanners: [...disabledSmartBanners, bannerType],
              }),
            );
          }
        }
      } catch (error) {
        captureError(error);
      }
      resolve();
    });
  };

const changeFolderSortType =
  (sortBy = DEFAULT_FILES_SORT) =>
  (dispatch, getState) => {
    return new Promise((resolve) => {
      try {
        const {auth, home} = getState();
        let hasSortTypeChanged = false;
        const activeFolderID = home.activeFolder?.id;
        if (activeFolderID === ROOT_FOLDER) {
          const rootSortBy = auth.userPref?.sortBy;
          if (rootSortBy !== sortBy) {
            dispatch(setUserPref({sortBy}));
            hasSortTypeChanged = true;
          }
        } else {
          const index = home.activeFolder?.index;
          const filesArr = home.files.slice();
          const folderMeta = filesArr[index]?.documentMeta;
          if (folderMeta?.sortBy !== sortBy) {
            hasSortTypeChanged = true;
            const updatedMeta = Object.assign({}, folderMeta, {sortBy});
            filesArr.splice(index, 1, {
              documentId: activeFolderID,
              documentMeta: updatedMeta,
            });
            dispatch({
              type: HOME_ACTION.LOAD_FILES,
              payload: {files: filesArr},
            });
            homeActionHelper.setFileDetails(
              auth.user?.uid,
              activeFolderID,
              Object.assign({}, {collab: updatedMeta.collab}, {sortBy}),
            );
          }
        }
        if (hasSortTypeChanged) {
          reloadVisibleFiles(dispatch, getState);
        }
      } catch (error) {
        captureError(error);
      }
      resolve();
    });
  };

const moveFile =
  (index, newParent = ROOT_FOLDER) =>
  (dispatch, getState) => {
    return new Promise((resolve) => {
      try {
        dispatch({
          type: HOME_ACTION.SHOW_LOADER,
        });
        const {auth, home} = getState();
        const filesArr = home.files.slice();
        const folderMeta = filesArr[index].documentMeta;
        const docId = filesArr[index].documentId;
        const updateObj = {parent: newParent};
        const updatedMeta = Object.assign({}, folderMeta, updateObj);
        filesArr.splice(index, 1, {
          documentId: docId,
          documentMeta: updatedMeta,
        });
        dispatch({
          type: HOME_ACTION.LOAD_FILES,
          payload: {files: filesArr},
        });
        homeActionHelper.setFileDetails(
          auth.user.uid,
          docId,
          Object.assign({}, {collab: updatedMeta.collab}, updateObj),
        );
        reloadVisibleFiles(dispatch, getState);
      } catch (error) {
        captureError(error);
      }
      resolve();
    });
  };

const createFileFromImportedExcel =
  (fileName, headerData, tableData, footerData) =>
  async (dispatch, getState) => {
    try {
      const {auth, home} = getState();
      const user = auth.user;
      const parent = home.activeFolder.id || ROOT_FOLDER;
      const fileMetaObj = homeActionHelper.generateNewFileMeta(
        fileName,
        user.uid,
        parent,
        false,
      );
      dispatch({
        type: HOME_ACTION.CREATE_NEW_FILE,
        payload: {
          activeDocumentId: fileMetaObj.docId,
          documentMeta: {...fileMetaObj.meta},
          isFolder: false,
        },
      });
      const docData = {
        headerData: headerData ?? [],
        tableData: prepareTableDataForFirestore(tableData),
        footerData: Object.assign({}, footerData),
        fileObj: {},
      };
      dispatch({
        type: TABLE_ACTION.LOAD_TABLE_DATA,
        payload: docData,
      });
      reloadVisibleFiles(dispatch, getState);
      logAnalyticsEvent('EXCEL_UPLOAD_FINISH', {
        source: 'Mobile',
        docId: fileMetaObj.docId,
      });
      if (isEmpty(fileMetaObj?.meta?.collab)) {
        //since this is not a shared file so set shared meta fetched true!
        dispatch(
          updateInitialFetchObj({
            [INITIAL_FETCH_COMPLETED.SHARED_META]: true,
          }),
        );
      }
      return UsersMethods.createNewFileorFolderWithFileData(
        user.uid,
        fileMetaObj.docId,
        fileMetaObj.meta,
        docData,
      );
    } catch (error) {
      captureError(error);
    }
  };

const clearHomeState = () => (dispatch) => {
  dispatch({
    type: HOME_ACTION.CLEAR_LAST_HOME_STATE,
  });
};

const moveAdminTemplate = (docId, templateName) => async () => {
  try {
    const data = await DocumentsMethods.getUserDocumentData(docId);

    if (!isEmpty(data)) {
      const templateSaveData = Object.assign(
        {},
        {
          footerData: Object.assign({}, data.footerData),
          fileObj: Object.assign({}, data.fileObj),
          headerDataArr: Object.assign({}, data.headerData),
          fileData: {
            templateAnalyticsName: templateName,
          },
          tableDataArr: data.tableData,
        },
      );

      firestore()
        .collection('templatesMaster')
        .doc(templateName)
        .set(templateSaveData);

      return true;
    }

    return false;
  } catch (error) {
    captureError(error);
    return false;
  }
};

const setUserDefinedUnit = (unitObj) => (dispatch, getState) => {
  try {
    const {
      home: {userDefinedUnits},
      auth: {
        user: {uid},
      },
    } = getState();
    Object.assign(unitObj, {id: `${unitObj.symbol}_${moment().valueOf()}`});
    const unitsArr = userDefinedUnits.slice();
    unitsArr.splice(0, 0, unitObj);
    homeActionHelper.addToUserDefinedUnits(uid, unitObj);
    dispatch({
      type: HOME_ACTION.SET_USER_UNITS,
      payload: unitsArr,
    });
  } catch (error) {
    captureError(error);
  }
};

const editUserDefinedUnit = (index, updatedUnitObj) => (dispatch, getState) => {
  try {
    const {
      home: {userDefinedUnits},
      auth: {
        user: {uid},
      },
    } = getState();
    const unitsArr = userDefinedUnits.slice();
    updatedUnitObj = Object.assign({}, unitsArr[index], updatedUnitObj); // using same id
    unitsArr.splice(index, 1, updatedUnitObj);
    homeActionHelper.replaceUserDefinedUnits(uid, unitsArr);
    dispatch({
      type: HOME_ACTION.SET_USER_UNITS,
      payload: unitsArr,
    });
  } catch (error) {
    captureError(error);
  }
};

const deleteUserDefinedUnit = (index) => (dispatch, getState) => {
  try {
    const {
      home: {userDefinedUnits},
      auth: {
        user: {uid},
      },
    } = getState();
    const unitsArr = userDefinedUnits.slice();
    const unitObj = Object.assign({}, unitsArr[index]);
    unitsArr.splice(index, 1);
    homeActionHelper.removeFromUserDefinedUnits(uid, unitObj);
    dispatch({
      type: HOME_ACTION.SET_USER_UNITS,
      payload: unitsArr,
    });
  } catch (error) {
    captureError(error);
  }
};

const dUpdateLastOpenedTimestampFolder = (folderId, dispatch, getState) => {
  try {
    const {home: homeData, auth} = getState();
    const filesArr = homeData.files.slice();
    const folderAvailableMetaIndex = filesArr.findIndex(
      (item) => folderId === item.documentId,
    );
    if (folderAvailableMetaIndex !== -1) {
      const updateObj = {lastOpenedTimestamp: moment().unix()};
      const folderUpdatedMeta = Object.assign(
        {},
        filesArr[folderAvailableMetaIndex].documentMeta,
        updateObj,
      );
      filesArr.splice(folderAvailableMetaIndex, 1, {
        documentId: folderId,
        documentMeta: folderUpdatedMeta,
      });
      dispatch({
        type: HOME_ACTION.LOAD_FILES,
        payload: {files: filesArr},
      });
      homeActionHelper.setFileDetails(
        auth.user.uid,
        folderId,
        Object.assign({}, {collab: folderUpdatedMeta.collab}, updateObj),
      );
    }
  } catch (error) {
    captureError(error);
  }
};

const loadWhatsNewVideo = (currentVersion) => async (dispatch) => {
  try {
    const documents = await homeActionHelper.fetchWhatsNewData();
    const whatsNewItems = [];
    const arr = [];
    let latestVersion = null;
    documents.forEach((doc) => {
      const id = `${doc.id}`;
      const floatId = parseFloat(id.replace('v', ''));
      if (currentVersion >= floatId) {
        whatsNewItems.push({
          version: id.replace('v', ''),
          videos: doc.data().Videos,
        });
      }
    });
    // INDEX DEFINATION
    const LATEST_VERSION_INDEX = 0;
    const PREVIOUS_VERSIONS_INDEX = 1;

    whatsNewItems
      .sort((a, b) => parseFloat(b.version) - parseFloat(a.version))
      .forEach((item, index) => {
        if (index == LATEST_VERSION_INDEX) {
          latestVersion = item.version;
        }
        if (index == LATEST_VERSION_INDEX || index == PREVIOUS_VERSIONS_INDEX) {
          // PUSH FIRST ELEMENT of VIDEOS TO <LATEST_VERSION_INDEX> FOR LATEST VERSION VIDEOS
          // PUSH FIRST ELEMENT of VIDEOS TO <PREVIOUS_VERSION_INDEX> FOR PREVIOUS VERSION'S VIDEOS
          arr.push({
            label:
              index == PREVIOUS_VERSIONS_INDEX
                ? 'Previously Released Features'
                : 'Latest Features',
            videos: item.videos.map((item, sub_index) => {
              const key =
                Date.now().toString(36) +
                ((index + 1) * (sub_index + 1)).toString();
              return {...item, key: 'view' + key};
            }),
          });
        } else {
          // PUSH TO VIDEOS ARRAY of <PREVIOUS_VERSION_INDEX> FOR PREVIOUS VERSION'S VIDEOS
          arr[PREVIOUS_VERSIONS_INDEX]['videos'] = [
            ...arr[PREVIOUS_VERSIONS_INDEX]['videos'],
            ...item.videos,
          ].map((item, sub_index) => {
            const key =
              Date.now().toString(36) +
              ((index + 1) * (sub_index + 2)).toString();
            return {...item, key: 'view' + key};
          });
        }
      });
    dispatch({
      type: HOME_ACTION.GET_WHATS_NEW,
      payload: {
        videos: arr,
        version: latestVersion ? parseFloat(latestVersion) : null,
      },
    });
  } catch (error) {
    captureError(error);
  }
};

const loadHelpDeskVideos = () => async (dispatch, getState) => {
  try {
    const userCountry = getState().auth.userCountry || 'IN';
    const documents = await homeActionHelper.fetchHelpDeskVideosData();
    let arr = [];
    documents.forEach((doc) => {
      const docData = doc.data();
      if (isArray(docData?.countries) && docData.countries.length) {
        if (docData.countries.includes(userCountry)) {
          arr.push(docData);
        }
      } else {
        arr.push(docData);
      }
    });
    arr = sortBy(arr, ['priorityIndex']);
    dispatch({
      type: HOME_ACTION.GET_VIDEOS,
      payload: arr,
    });
  } catch (error) {
    captureError(error);
  }
};

const fetchBusinessType = () => async (dispatch) => {
  try {
    const data = await homeActionHelper.fetchBusinessTypesData();
    if (data.exists) {
      dispatch({
        type: HOME_ACTION.FETCH_BUSINESS_TYPES,
        payload: Object.values(data.data()),
      });
    }
  } catch (error) {
    captureError(error);
  }
};

const fetchBusinessCategories = () => async (dispatch) => {
  const listObj = {};
  try {
    const data = await fetchTemplateCategory(false, true);
    if (data?.length) {
      data.forEach((item) => {
        listObj[item.id] = item.data();
      });
      dispatch({
        type: HOME_ACTION.FETCH_BUSINESS_CATEGORIES,
        payload: listObj,
      });
    }
  } catch (error) {
    captureError(error);
  }
  return listObj;
};

const fetchPersonalCategories = () => async () => {
  try {
    const data = await backOff(
      () => homeActionHelper.fetchPersonalCategoriesCall(),
      {numOfAttempts: 3},
    );

    if (data?.docs?.length > 0 && data.docs[0]?.exists) {
      return {...data.docs[0].data(), categoryId: data.docs[0].id};
    }
  } catch (error) {
    captureError(error);
  }
  return {};
};

const loaderShow =
  (options = {}) =>
  (dispatch) => {
    const {isLoggingOut = false} = options ?? {};
    dispatch({
      type: HOME_ACTION.SHOW_LOADER,
      payload: {isLoggingOut},
    });
  };

const loaderHide = () => (dispatch) => {
  dispatch({
    type: HOME_ACTION.HIDE_LOADER,
  });
};

const fetchGreetingsCategory = (lang) => async (dispatch, getState) => {
  try {
    if (!lang) {
      lang = getState().auth.userPref?.lang || 'EN';
    }
    const docRef = homeActionHelper.fetchGreetingsCategoriesCall(lang);
    const snapshot = await backOff(() => docRef.get(), {numOfAttempts: 2});
    if (snapshot && !snapshot.empty) {
      const greetingsList = [];
      snapshot.forEach((doc) =>
        greetingsList.push(
          Object.assign({}, doc.data(), {
            images: [],
            id: doc.id,
            lang, //lang from which greetingsCategories are fetched
          }),
        ),
      );
      dispatch({
        type: HOME_ACTION.SET_GREETINGS,
        payload: greetingsList,
      });
    } else if (lang !== 'EN') {
      await dispatch(fetchGreetingsCategory('EN'));
    }
  } catch (error) {
    captureError(error);
  }
};

const fetchGreetingsList = (categoryIndex) => async (dispatch, getState) => {
  try {
    const {auth, home} = getState();

    const greetingsList = home.greetingsList.slice();

    const greetingsDataAtIndex = Object.assign(
      {},
      greetingsList[categoryIndex],
    );

    const lang = greetingsDataAtIndex.lang || auth.userPref.lang || 'EN';
    const snapshot = await backOff(
      () =>
        homeActionHelper.fetchGreetingsListWithLanguage(
          greetingsDataAtIndex.id,
          lang,
        ),
      {numOfAttempts: 2},
    );

    if (snapshot && !snapshot.empty) {
      const imagesList = [];
      snapshot.forEach((doc) =>
        imagesList.push(Object.assign({}, doc.data(), {id: doc.id})),
      );
      greetingsList.splice(
        categoryIndex,
        1,
        Object.assign({}, greetingsDataAtIndex, {
          images: imagesList,
        }),
      );
      dispatch({
        type: HOME_ACTION.SET_GREETINGS,
        payload: greetingsList,
      });
    }
  } catch (error) {
    captureError(error);
  }
};

const setTutorialVideos =
  (videos = []) =>
  (dispatch) => {
    dispatch({
      type: HOME_ACTION.SET_TUTORIAL_VIDEOS,
      payload: videos,
    });
  };

const checkAndUpdateSmartTemplateData =
  (show = true) =>
  (dispatch, getState) => {
    try {
      if (!ENV) {
        return;
      }
      if (!isAndroid()) {
        return;
      }
      const {home} = getState();
      const {nativeBridgeFunctions} = require('../imports');
      const smartFiles = [];
      home.files.forEach((item) => {
        const checkPermission = (documentMeta) => {
          return !isEmpty(
            documentMeta &&
              documentMeta.collab &&
              documentMeta.collab.permission,
          )
            ? documentMeta.collab.isOwner ||
                [
                  SHARE_PERMISSION_TYPE.OWNER,
                  SHARE_PERMISSION_TYPE.ADMIN,
                ].includes(documentMeta.collab.permission)
            : true;
        };
        if (
          item?.documentMeta?.fileData?.smartTemplate ===
            SMART_TEMPLATE_TYPES.W4B_TEMPLATE &&
          checkPermission(item?.documentMeta)
        ) {
          const smartTemplateType = item.documentMeta.fileData.smartTemplate;
          const docId = item.documentId;
          smartFiles.push({
            smartTemplateType,
            docId,
          });
        }
      });

      if (smartFiles.length > 0) {
        try {
          // nativeBridgeFunctions('UPDATE_SMART_TEMPLATE_MODAL', [show]);
          // dispatch({
          //   type: HOME_ACTION.MODIFY_SMART_TEMPLATE_DOCS,
          //   payload: smartFiles,
          // });
        } catch (error) {
          captureError(error);
        }
      }
    } catch (error) {
      captureError(error);
    }
  };

const shareWithWhatsapp =
  ({isShare, isBusiness, options}) =>
  (dispatch) => {
    dispatch({
      type: HOME_ACTION.SHARE_ON_WHATSAPP,
      payload: {isShare, isBusiness, options},
    });
  };

const invokePopupModal = (options) => (dispatch) => {
  dispatch({
    type: HOME_ACTION.INVOKE_POP_UP_MODAL,
    payload: {isVisible: true, options},
  });
};

const closePopUp = () => (dispatch) => {
  dispatch({
    type: HOME_ACTION.CLOSE_POP_UP_MODAL,
  });
};

const createNewFileUsingTemplateCloudFun =
  ({docId, meta}) =>
  (dispatch, getState) => {
    const {home} = getState();
    const filesArr = home.files.slice();
    const index = filesArr.findIndex((obj) => obj.documentId === docId);
    dispatch({type: TABLE_ACTION.START_TABLE_LOADING});
    if (index > -1) {
      dispatch({
        type: HOME_ACTION.UPDATE_ACTIVE_FILE_INDEX,
        payload: index,
      });
      dispatch({
        type: HOME_ACTION.LOAD_NEW_CREATED_FILE_DATA,
        payload: {
          activeDocumentId: docId,
          documentMeta: meta,
        },
      });
    } else {
      dispatch({
        type: HOME_ACTION.CREATE_NEW_FILE,
        payload: {
          activeDocumentId: docId,
          documentMeta: meta,
          isFolder: false,
        },
      });
      reloadVisibleFiles(dispatch, getState);
    }
    // dispatch(fetchParentDocData(meta.linkedDocIds));
    dispatch(
      fetchParentFileData({
        isInitialFetch: true,
        extra: {linkedDocIds: meta.linkedDocIds},
      }),
    );
    if (isEmpty(meta?.collab)) {
      //since this is not a shared file so set shared meta fetched true!
      dispatch(
        updateInitialFetchObj({
          [INITIAL_FETCH_COMPLETED.SHARED_META]: true,
        }),
      );
    }
  };

const setBackupPageIdForViewEntry = (backUpPageId) => (dispatch) => {
  if (backUpPageId) {
    dispatch({
      type: HOME_ACTION.SET_PAGEID_CACHE_FOR_VIEW_ENTRY,
      payload: {
        pageId: backUpPageId,
      },
    });
  }
};

const checkAndFetchMyTemplates = () => async (dispatch, getState) => {
  try {
    const {
      auth: {userPref},
    } = getState();

    if (!userPref.areOlderTemplatesMigrated) {
      dispatch({
        type: HOME_ACTION.UPDATE_TEMPLATES_MIGRATION_LOADER,
        payload: true,
      });
      const response = await homeActionHelper.migrateMyTemplatesFunction({});

      if (response?.success) {
        dispatch(setUserPref({areOlderTemplatesMigrated: true}));
      }
      dispatch({
        type: HOME_ACTION.UPDATE_TEMPLATES_MIGRATION_LOADER,
        payload: false,
      });
    }
    return dispatch(getMyTemplatesList());
  } catch (error) {
    captureError(error);
  }
};

/**
 *
 * @param {boolean} isLoading
 * @param {{
 *   text: string;
 * }} moreStates
 * @returns
 */
const updateGlobalLoader =
  (isLoading = false, moreStates = {}) =>
  (dispatch) =>
    dispatch({
      type: HOME_ACTION.UPDATE_GLOBAL_LOADING_STATE,
      payload: Object.assign({}, moreStates, {isLoading: isLoading}),
    });

export {
  changeFolderSortType,
  checkAndUpdateSmartTemplateData,
  clearFolderStack,
  clearHomeState,
  clearHomeStateAndDeactivateListeners,
  closePopUp,
  createFileFromImportedExcel,
  createNewFile,
  createNewFolder,
  createNewFolderInBackground,
  openFolder,
  openPreviousFolder,
  reloadVisibleFiles,
  moveFile,
  updateHomeStates,
  userDocsListener,
  fetchGreetingsCategory,
  fetchGreetingsList,
  setUserDefinedUnit,
  createTemplate,
  deleteFile,
  deleteUserDefinedUnit,
  duplicateFile,
  editUserDefinedUnit,
  fetchBusinessCategories,
  fetchBusinessType,
  moveAdminTemplate,
  getMyTemplatesList,
  invokePopupModal,
  loadAllFiles,
  loaderHide,
  loaderShow,
  loadHelpDeskVideos,
  loadHomePageCommunities,
  loadWhatsNewVideo,
  openFile,
  openFileWithDocumentId,
  renameFile,
  restoreFile,
  setTutorialVideos,
  shareWithWhatsapp,
  sortVisibileFiles,
  updateSmartBannerVisibility,
  fetchPersonalCategories,
  updateUserStoryViewStatus,
  createNewFileUsingTemplateCloudFun,
  getFilesToDeleteLocalStorage,
  setFilesBeingDeleted,
  getUserMetaDataListener,
  setBackupPageIdForViewEntry,
  checkAndFetchMyTemplates,
  updateGlobalLoader,
};
