const ActionBtnIcon = ({color}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.625 8.25L12.41 12.2775L11.75 12.4125L11.27 12.5025C10.7975 12.6 10.5275 13.125 10.7375 13.5525L10.94 13.9875L11.96 16.1925L10.895 16.6875L9.875 14.49L9.68 14.055C9.4775 13.6125 8.915 13.4775 8.54 13.785L8.1575 14.085L7.625 14.5125V8.25ZM7.07 6.5175C6.91883 6.5175 6.77385 6.57755 6.66695 6.68445C6.56005 6.79134 6.5 6.93633 6.5 7.0875V15.675C6.5 15.99 6.755 16.245 7.07 16.245C7.2125 16.245 7.3325 16.2 7.43 16.125L8.8625 14.9625L10.1075 17.6775C10.205 17.88 10.4075 18 10.625 18C10.7075 18 10.79 18 10.8725 17.94L12.9425 16.98C13.2275 16.845 13.3625 16.5 13.2125 16.2225L11.96 13.5L13.7675 13.1625C13.8875 13.125 14 13.0725 14.09 12.9675C14.2925 12.7275 14.2625 12.375 14 12.1575L7.445 6.645L7.4375 6.6525C7.34 6.57 7.2125 6.5175 7.07 6.5175ZM10.25 7.5V6H14V7.5H10.25ZM9.3725 3.57L11.495 1.4475L12.5525 2.505L10.43 4.6275L9.3725 3.57ZM6.5 0H8V3.75H6.5V0ZM1.9475 10.995L4.07 8.8725L5.1275 9.93L3.005 12.0525L1.9475 10.995ZM1.9475 2.505L3.005 1.4475L5.1275 3.57L4.07 4.6275L1.9475 2.505ZM4.25 7.5H0.5V6H4.25V7.5Z"
        fill={color ? color : '#495A74'}
      />
    </svg>
  );
};
export default ActionBtnIcon;
