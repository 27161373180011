import {INTEGRATIONS_ACTION} from '../actions/actionType';

const initialState = {
  newIntegration: {},
  integration: {},
  integrations: [],
  pages: [],
  forms: [],
  documents: [],
  document: {},
  form: {},
  miniAppIntegrations: [],
  showSuccess: {
    state: false,
  },
  interakt: {
    businesses: [],
    whatsAppBusinesses: [],
  },
};

const integrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case INTEGRATIONS_ACTION.SET_INTEGRATIONS: {
      return {
        ...state,
        integrations: action.payload,
      };
    }

    case INTEGRATIONS_ACTION.SET_INTEGRATION: {
      return {
        ...state,
        integration: action.payload,
      };
    }

    case INTEGRATIONS_ACTION.ADD_INTEGRATION: {
      return {
        ...state,
        newIntegration: {
          ...action.payload,
        },
      };
    }

    case INTEGRATIONS_ACTION.SET_FACEBOOK_PAGES: {
      return {
        ...state,
        pages: action.payload,
      };
    }

    case INTEGRATIONS_ACTION.SET_FACEBOOK_PAGE_FORMS: {
      return {
        ...state,
        forms: action.payload,
      };
    }

    case INTEGRATIONS_ACTION.SET_FACEBOOK_PAGE_FORM: {
      return {
        ...state,
        form: action.payload,
      };
    }

    case INTEGRATIONS_ACTION.SET_DOCUMENT: {
      return {
        ...state,
        document: action.payload,
      };
    }

    case INTEGRATIONS_ACTION.SET_MINI_APP_INTEGRATIONS: {
      return {
        ...state,
        miniAppIntegrations: action.payload,
      };
    }

    case INTEGRATIONS_ACTION.SET_DOCUMENTS: {
      return {
        ...state,
        documents: action.payload,
      };
    }

    case INTEGRATIONS_ACTION.SET_SUCCESS: {
      return {
        ...state,
        showSuccess: action.payload,
      };
    }

    case INTEGRATIONS_ACTION.SET_BUSINESSES: {
      const interaktObj = Object.assign({}, state.interakt);
      interaktObj.businesses = action.payload;
      return {
        ...state,
        interakt: interaktObj,
      };
    }

    case INTEGRATIONS_ACTION.SET_WHATSAPP_BUSINESSES: {
      const interaktObj = Object.assign({}, state.interakt);
      interaktObj.whatsAppBusinesses = action.payload;
      return {
        ...state,
        interakt: interaktObj,
      };
    }

    default:
      return state;
  }
};

export default integrationReducer;
