import {firestore, captureError} from '../../imports';
import {getTimezone} from '../../utils/utils';
import {processRowsData} from './tableActionHelper';

import TemplatesMethods from '../../FirestoreHandlers/Templates/TemplatesMethods';
import UsersMethods from '../../FirestoreHandlers/Users/UsersMethods';
import {DEFAULT_ROWS} from '../../utils/constant';

const makeCategoryObjFromSnap = (snap, lang) => {
  if (snap.id === 'my-templates') {
    return snap;
  }
  const snapData = snap.data();
  return {
    name: snapData.name[lang],
    engName: snapData.name.EN,
    id: snap.id,
    icon: snapData.icon,
    numberOfTemplates: snapData.numberOfTemplates,
    isBusinessDepartment: snapData.isBusinessDepartment ? true : false,
    isSmartTemplateCategory: snapData.isSmartTemplateCategory ? true : false,
    isExploreMore: snapData.isExploreMore === true ? true : false,
    nameObj: snapData.name,
  };
};

const getMyTemplateData = async (templateId) => {
  try {
    const templateSnap = await TemplatesMethods.getTemplateData(templateId);
    const templateData = templateSnap.data();
    let tableData = DEFAULT_ROWS;
    let footerData = {};
    if (templateData.footerData) {
      footerData = templateData.footerData;
      delete templateData.footerData;
    }
    const templateTableDataFirebase =
      await TemplatesMethods.getTemplateRowsData(templateId);

    if (!templateTableDataFirebase.empty) {
      const templateTableData = processRowsData(
        templateTableDataFirebase,
        null,
        null,
        templateTableDataFirebase.size,
      );
      tableData = templateTableData;
    }

    const fileObj = Object.assign({}, templateData.fileObj);
    delete templateData.fileObj;
    const headerData = templateData;
    return {
      headerData,
      tableData,
      footerData,
      fileObj,
    };
  } catch (error) {
    captureError(error);
  }
};

const deleteTemplate = async (uid, templateId) => {
  try {
    await TemplatesMethods.deleteTemplate(templateId);
    UsersMethods.deleteUsersTemplate(uid, templateId);
  } catch (error) {
    captureError(error);
  }
};

const renameTemplate = (uid, templateId, newName) => {
  try {
    UsersMethods.renameUserTemplate(uid, templateId, newName);
  } catch (error) {
    captureError(error);
  }
};

const fetchTemplateCategory = async (
  isExploreMore = false,
  showNewOnboardingFlow = false,
) => {
  let appVersion;
  const {getDeviceVersion} = require('../../imports');
  appVersion = getDeviceVersion();

  try {
    if (showNewOnboardingFlow) {
      const categorySnap = await firestore()
        .collection('masterCategories')
        .where('displayCategory', '==', true)
        .where(
          'categoryType',
          '==',
          isExploreMore ? 'EXPLORE_MORE' : 'ONBOARDING',
        )
        .where('minVersion', '<=', appVersion)
        .orderBy('minVersion', 'asc')
        .orderBy('priority', 'asc')
        .get();

      return categorySnap.docs;
    } else {
      const categorySnap = await firestore()
        .collection('defaultTemplateCategory')
        .where('displayCategory', '==', true)
        .orderBy('priority', 'asc')
        .get();
      return categorySnap.docs;
    }
  } catch (error) {
    captureError(error);
  }
};

const setRecentlyUsedTemp = (uid, tempList) => {
  try {
    firestore()
      .collection('users')
      .doc(uid)
      .set({recentlyUsedTemplates: tempList}, {merge: true});
  } catch (error) {
    captureError(error);
  }
};

const setPreferredTempCatgs = (uid, catgList) => {
  try {
    firestore()
      .collection('users')
      .doc(uid)
      .set({preferredTemplateCatgs: catgList}, {merge: true});
  } catch (error) {
    captureError(error);
  }
};

const addToDashboardTrigger = (docId, uid, dashboardsArray) => {
  const data = {docId, uid, timezone: getTimezone(), dashboardsArray};
  firestore().collection('dashboardTemplateTrigger').add(data);
};

const getCategoryFromId = async (categoryId) => {
  try {
    const categorySnap = await firestore()
      .collection('masterCategories')
      .doc(categoryId)
      .get();
    return Object.assign({}, categorySnap.data());
  } catch (error) {
    captureError(error);
    return Promise.resolve({});
  }
};

export {
  makeCategoryObjFromSnap,
  getMyTemplateData,
  deleteTemplate,
  renameTemplate,
  fetchTemplateCategory,
  setRecentlyUsedTemp,
  setPreferredTempCatgs,
  addToDashboardTrigger,
  getCategoryFromId,
};
