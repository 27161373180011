import {MINI_APPS_REPORTS_ACTION} from '../actions/actionType';

const initialState = {
  miniAppsReportsData: {},
};

const miniAppsReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MINI_APPS_REPORTS_ACTION.SET_MINI_APPS_REPORTS_DATA: {
      const screenId = action.payload?.screenId;
      return screenId
        ? {
            ...state,
            miniAppsReportsData: Object.assign({}, state.miniAppsReportsData, {
              [action.payload.screenId]: action.payload.data ?? [],
            }),
          }
        : {
            ...state,
          };
    }
    default: {
      return state;
    }
  }
};
export default miniAppsReportsReducer;
