import FirestoreDB from '../FirestoreDB';
import {BatchWriter} from '../BatchWriter';
import {isEmpty} from 'lodash';
import {captureError} from '../../imports';
import DocumentsMethods from '../Documents/DocumentsMethods';

export default class UsersMethods {
  static createNewFileorFolderWithFileData(
    uid,
    docId,
    docMeta,
    docData = null,
  ) {
    try {
      BatchWriter.set(
        FirestoreDB.users.userDocumentsMetaRef(uid, docId),
        docMeta,
      );
      if (!isEmpty(docData)) {
        const tableData = docData.tableData; //array of rows
        const headerData = Object.assign({}, docData.headerData);
        const footerData = Object.assign({}, docData.footerData);
        const fileObj = Object.assign({}, docData.fileObj);
        DocumentsMethods.addMultipleRows(
          docId,
          tableData,
          {
            headerData,
            footerData,
            fileObj,
          },
          true,
          true,
        );
      }
      return BatchWriter.commit();
    } catch (error) {
      captureError(error);
    }
  }

  static fetchUserTemplatesDocumentData(uid, templateId) {
    try {
      return FirestoreDB.users
        .userTemplateDocumentDataRef(uid, templateId)
        .get();
    } catch (error) {
      captureError(error);
    }
  }

  static setUserTemplateDocumentData(uid, templateId, obj, merge = true) {
    try {
      BatchWriter.set(
        FirestoreDB.users.userTemplateDocumentDataRef(uid, templateId),
        obj,
        merge ? {merge: true} : {},
      );

      return BatchWriter.commit();
    } catch (error) {
      captureError(error);
    }
  }

  static getUserSubCollectionDataWithQuery(uid, collectionName) {
    try {
      return FirestoreDB.users
        .usersSubCollectionDataWithQueryRef(uid, collectionName)
        .get();
    } catch (error) {
      captureError(error);
    }
  }

  static deleteUsersTemplate(uid, templateId) {
    try {
      BatchWriter.delete(
        FirestoreDB.users.userTemplateDocumentDataRef(uid, templateId),
      );

      return BatchWriter.commit();
    } catch (error) {
      captureError(error);
    }
  }

  static renameUserTemplate(uid, templateId, newName) {
    try {
      BatchWriter.update(
        FirestoreDB.users.userTemplateDocumentDataRef(uid, templateId),
        {name: newName},
      );

      return BatchWriter.commit();
    } catch (error) {
      captureError(error);
    }
  }
}
